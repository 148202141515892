import React, { useState, useEffect } from 'react'
import Spinner from '../../../components/Spinner';
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { clearAll, onUpdate, setCustomerErrorMessage, setCustomerLoading, setOrderValues, showOrderModalFunc, showTicketModalFunc } from '../../../../config/redux/slice/customerSlice'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'

export default function DeliveryAddress({ showDbPopup, setShowDbPopup, selectedItem, getPendingList }) {

  const dispatch = useDispatch()


  const {
    token
  } = useSelector((state) => state.authData)

  const {
    customerLoading,
    orderManagement: { singleOrderDetails, orderItemsData }
  } = useSelector((state) => state.customer)

  const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: {
      town: selectedItem?.town || "",
      address: selectedItem?.address || "",
      postCode: selectedItem?.postCode || "",
      phoneNumber: selectedItem?.phoneNumber || "",
    }
  });

  useEffect(() => {
    if (selectedItem) {
      reset({
        town: selectedItem.town || "",
        address: selectedItem.address || "",
        postCode: selectedItem.postCode || "",
        phoneNumber: selectedItem.phoneNumber || "",
      });
    }
  }, [selectedItem, reset]);


  const closeDbPopup = () => {
    setShowDbPopup(false);
    //dispatch(clearAll({}))

  };

  //const { register, handleSubmit, watch, clearErrors, getValues, setError, resetField, setValue, formState: { errors }, reset } = useForm({ mode: 'onTouched' })
  const [showError, setShowError] = useState(false)
  const [errorType, setErrorType] = useState('')


  // Ensure selectedItem is not null or undefined
  const deliveryAddress = selectedItem || {};

  const onSubmit = async (data) => {

    dispatch(clearAll({}))

    setErrorType('')
    setShowError(false)
    data = {
      ...data,
    }

    //console.log("mydata",selectedItem.id)

    const res = await post_put_patch_request(
      "patch",
      token,
      `${app_api.update_delivery_address(selectedItem?.id)}`,
      data,
      dispatch,
      setCustomerLoading,
      "",
      setCustomerErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      getPendingList();
      closeDbPopup()
      let newOrderDetails = [...orderItemsData];
      let filteredOrderDetails = newOrderDetails.map((opt) => {
        if (opt.id === singleOrderDetails.id) {
          return { ...opt, ...res?.data?.data }
        } else {
          return opt
        }
      })
      dispatch(onUpdate({ message: res?.data?.message, type: 'order' }))
      dispatch(setOrderValues({ propertyKey: 'orderItemsData', value: filteredOrderDetails }))
    }

  }

  const formatPhoneNumber = (phoneNumber) => {
    const cleanedNumber_ = phoneNumber.replace(/^0+/, '');
    // Remove any non-digit characters from the phone number
    const cleanedNumber = cleanedNumber_.replace(/\D/g, '');
    // Format the number in groups of 3, 3, and 4 digits
    const formattedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
    return formattedNumber;
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setValue('phoneNumber', formattedPhoneNumber);
  };



  return (

    <div id="demo-modal" className="modal delivery-addresssec" style={{ display: showDbPopup ? 'flex' : 'none' }}>
      {console.log("Selected Item:", selectedItem)}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="modal-body modal-editlistng modal-show-address">
          <div className="px-4 px-md-7">
            <div className="row gy-3">
              <div className="modal__content">
                <h4 className='popup-headdelivery'>Delivery Address</h4>
                <div className="form-group col-md-12 mt-2">
                  <label htmlFor="town" className="form-label">Town</label>
                  <input value={watch('town')} type="text" className="form-control" id="town" placeholder="Town"
                    {...register("town", {
                      required: {
                        value: true,
                        message: '*this field is required'
                      }
                    })
                    }
                  />
                  {
                    errors?.town?.message &&
                    <div className="text-danger p mt-1">
                      {errors?.town?.message}
                    </div>
                  }
                </div>

                <div className="form-group col-md-12 mt-2">
                  <label htmlFor="address" className="form-label">Address</label>
                  <input value={watch('address')} type="text" className="form-control" id="address" placeholder="Address"
                    {...register("address", {
                      required: {
                        value: true,
                        message: '*this field is required'
                      }
                    })
                    }
                  />
                  {
                    errors?.address?.message &&
                    <div className="text-danger p mt-1">
                      {errors?.address?.message}
                    </div>
                  }
                </div>



                <div className="row">
                  <div className="form-group col-md-6 mt-2">
                    <label htmlFor="postCode" className="form-label">Post Code</label>
                    <input value={watch('postCode')} type="text" className="form-control" id="postCode" placeholder="Post Code"
                      {...register("postCode", {
                        required: {
                          value: true,
                          message: '*this field is required'
                        }
                      })
                      }
                    />
                    {
                      errors?.postCode?.message &&
                      <div className="text-danger p mt-1">
                        {errors?.postCode?.message}
                      </div>
                    }
                  </div>

                  <div className="form-group col-md-6 mt-2">
                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                    <input value={watch('phoneNumber')} type="text" className="form-control"
                      id="phoneNumber" placeholder="Phone Number"
                      onKeyUp={handlePhoneNumberChange}
                      {...register("phoneNumber", {
                        required: {
                          value: true,
                          message: '*this field is required'
                        }
                      })
                      }
                    />
                    {
                      errors?.phoneNumber?.message &&
                      <div className="text-danger p mt-1">
                        {errors?.phoneNumber?.message}
                      </div>
                    }
                  </div>
                </div>


                {/* <a onClick={closeDbPopup} className="modal__close">
                  <i className="fa fa-close"></i>
                </a> */}

                <div className="modal-footer border-top-0 d-flex align-items-center px-0 pb-0">
                  <div>
                    {customerLoading ?
                      <Spinner isForm={true} /> :
                      <button type="submit" className="btn btn-primary me-2">Change</button>
                    }
                  <button disabled={customerLoading ? true : false} onClick={closeDbPopup} type="button" className="btn btn-info">Cancel</button>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </form>
    </div>
  );
}
