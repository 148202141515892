import React, { useEffect } from 'react'
import home_jumbotron_img from '../../../assets/images/home_jumbotron_img.jpeg'
import GeneralWrapper from '../../layout/GeneralWrapper'
import right_ellipse from '../../../assets/images/right_ellipse_icon.svg';
import left_ellipse from '../../../assets/images/left_ellipse_icon.svg';
import search_icon from '../../../assets/images/search_icon.svg'
import home_img1 from '../../../assets/images/home_img1.jpg'
import home_img1_small from '../../../assets/images/home_img1_small.png'
import home_jumbotron_img_small from '../../../assets/images/home_jumbotron_img_small.jpeg'
import right_slider_button_icon from '../../../assets/images/right_slider_button_icon.svg'
import { trending_events, trending_events_reversed, why_we_are_different } from '../../../constants/english';
import { ButtonComponent, InfoComponent, PostHighlightComponent } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { setEventValues, showEventModalFunc } from '../../../config/redux/slice/appSlice';
import SearchComponent from '../../components/SearchComponent';
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../../utils/urls/app_urls';

export default function HomePage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const eventData = useSelector((state) => state.app.eventManagement.eventData);
  const topEvents = eventData.slice(0,5);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      dispatch(showEventModalFunc({ modal: false, type: null }))
    }
  }, [])
  const handleRegisterToSell = () => {
    navigate(app_urls.sellers) // Use the appropriate URL
  }
  const handleSearchTickets = () => {
    navigate(app_urls.buyers) // Use the appropriate URL
  }
  return (
    <GeneralWrapper>
      <div className='container-fluid p-0'>

        <div className='position-relative z-2'>
          <img className='w-100 d-none d-md-block jumbotron-on-page' src={home_jumbotron_img} alt='people in a football stadium cheering' />
          <img className='w-100 d-block d-md-none jumbotron-on-page' src={home_jumbotron_img_small} alt='people in a football stadium cheering' />
          <div style={{ background: 'rgba(20, 40, 98, 0.6)' }} className='position-absolute top-50 start-50 translate-middle w-100 h-100'></div>
          <img className='position-absolute top-50 start-0 translate-middle-y' src={right_ellipse} />
          <img className='position-absolute top-50 end-0 translate-middle-y' src={left_ellipse} />
          {/* <div className='position-absolute top-50 start-50 translate-middle'> */}
          {/* <div style={{rowGap: '3rem'}} className='hero-text w-100 px-5 px-lg-56 px-xl-200 row align-items-center position-absolute top-50 start-50 translate-middle'>
                <h1 className='col-12 col-md-6 col-lg-5 fw-bold text-center text-white'>Get tickets. <br/> Make memories.</h1>
                <div className='d-none d-lg-block col-lg-1'></div>
                <div className='col-12 col-md-6 col-lg-6'>
                  <SearchComponent/>
                </div>
              </div> */}
          <div className='w-100 px-5 px-lg-56 px-xl-200 position-absolute top-50 start-50 translate-middle'>
            <div className='fs-5 fw-bold text-white d-none d-sm-block lh-base text-center text-hero mx-auto mb-2'>
              {/* Move the google information "As a marketplace ......" to the top of the homepage. Make the fonts smaller SLNO:22 XillidevSud */}
              {/* <span>As a marketplace Sellers (and not PrimeTic) set the prices for tickets listed. Price may be above or below face value.</span> */}
              {/* Move the google information "As a marketplace ......" to the top of the homepage. Make the fonts smaller SLNO:22 XillidevSud */}

            </div>
            <div className='fs-6 fw-bold text-white d-block d-sm-none lh-base text-center text-hero mx-auto mb-2'>
              {/* <span>As a marketplace Sellers (and not PrimeTic) set the prices for tickets listed. Price may be above or below face value.</span> */}
            </div>
            <div style={{ rowGap: '3rem' }} className='row align-items-center'>
              <h1 className='col-12 col-md-6 col-lg-5 fw-bold text-center text-white'>Get tickets. <br /> Make memories.</h1>
              <div className='d-none d-lg-block col-lg-1'></div>
              <div className='col-12 col-md-6 col-lg-6'>
                <SearchComponent />
              </div>
            </div>
          </div>
        </div>

        {/* {topEvents.length > 0 &&
          <PostHighlightComponent heading_text='Trending Events' dataList={topEvents} mt3={3} />
        } */}


        <div className='position-relative  mt-1 px-1 px-md-5 rounded'>


          <div className='px-3 px-md-5 pt-3 pb-md-5 text-center tic-wrapper-width padd-bottm-sec'>
            <h2 className='fw-bold' style={{fontSize:"22px"}}>What makes us Tic?</h2>
            <h5 className='d-none d-sm-block lh-base mb-0' style={{ fontSize: "15px" }}>
            PrimeTic is your gateway to unforgettable sporting experiences and connections. 
            Your tickets are 100% guaranteed on our simple secured and transparent platform.
              <br />
              Make offers on listed tickets or set alerts for your preferred options.             
            </h5>
            <h6 className='d-block d-sm-none lh-base mb-0'>
            PrimeTic is your gateway to unforgettable sporting experiences and connections. 
            Your tickets are 100% guaranteed on our simple secured and transparent platform.
              
              Make offers on listed tickets or set alerts for your preferred options.   
            </h6>
          </div>


          {topEvents.length > 0 &&
          <PostHighlightComponent heading_text='Trending Events' dataList={topEvents} mt2={2} flexbasis='16.17rem' />
        }

          {/* <img className='w-100 d-none d-lg-block jumbotron-on-page rounded' src={home_img1} />
          <img className='w-100 d-block d-lg-none jumbotron-on-page-small-screen rounded' src={home_img1_small} />
          <div className='px-3 px-md-5 position-absolute top-50 start-50 translate-middle w-100 h-100 rounded'>
            <div className='w-100 h-100 rounded' style={{ background: 'rgba(20, 40, 98, 0.6)' }}></div>
          </div> */}
          {/* <div className='second-container-text px-4 px-md-100 px-xl-312 w-100 position-absolute top-50 start-50 translate-middle text-center'>
            <h2 className='fw-bold'>What makes us Tic?</h2>
            <h5 className='d-none d-sm-block lh-base mb-0'>
              PrimeTic is more than a ticketing platform. We’re a gateway to unforgettable sporting experiences. To connections that transcend the game. To lasting memories. Our selling and buying system is simple, secure and supported, and tickets are 100% guaranteed.
              <br /><br />
              <span>
                Can’t find your ticket? Set an alert to find your ticket and match with sellers within your budget when tickets become available.
              </span>
            </h5>
            <h6 className='d-block d-sm-none lh-base mb-0'>
              PrimeTic is more than a ticketing platform. We’re a gateway to unforgettable sporting experiences. To connections that transcend the game. To lasting memories. Our selling and buying system is simple, secure and supported, and tickets are 100% guaranteed.
              <br /><br />
              <span>
                Can’t find your ticket? Set an alert to find your ticket and match with sellers within your budget when tickets become available.
              </span>
            </h6>
          </div> */}
        </div>

        {/* <div className='mt-10 px-3 px-md-5'>
              <span>As a marketplace, sellers (and not PrimeTic) set the prices for tickets listed on the our site. Listing prices may exceed the face value of the ticket.</span>
            </div> */}

        {/* <div className='mt-11 px-3 px-md-5'>
              <h1 className='text-center fw-bold text-primary'>Trending events</h1>
              <div style={{columnGap: '1.4rem'}} className='position-relative d-flex align-items-center overflow-x-scroll mt-3'>
                {trending_events.map((option)=>{
                  return <div key={option.id} className="card border-0" style={{flexBasis: '21.17rem', flexShrink: 0}}>
                          <div className='position-relative'>
                            <img src={option.image} class="card-img-top" alt="..."/>
                            <img style={{width: '28px', height: '28px', bottom: '-15px'}} className='position-absolute start-0' src={right_ellipse} />
                            <img style={{width: '28px', height: '28px', bottom: '-15px'}} className='position-absolute end-0' src={left_ellipse} />    
                          </div>
                          <div className="card-body bg-primary">
                            <p className="card-text text-white">
                              <span className='text-accent fw-bold'>{option.text1}</span><br/>
                              <span className='fw-bold'>{option.text2}</span><br/>
                              <span>{option.text3}</span>
                            </p>
                          </div>
                        </div>
                })}
                <img style={{right: '-15px', top: '35%'}} className='position-absolute' src={right_slider_button_icon} alt='' />
              </div>
            </div> */}

        <div className='mt-5 px-3 px-md-5'>
          <div style={{ marginBottom: '30px', rowGap: '2.5rem' }} className='row justify-content-center'>
            <div className='col-12 col-md-5 text-center'>
              {/* <h2 className='text-primary fw-bold'>Buy Tickets</h2> */}
              <ButtonComponent btn_text='Buy Tickets' onClick={handleSearchTickets} />
              <p className='line-height-28 mt-3'><span className='fw-bold'>Incredible experiences at your fingertips.</span><br />
              Search and find the right tickets and make offers in a Tic.</p>
              {/* <ButtonComponent btn_text='Search Tickets' onClick={handleSearchTickets} /> */}
              {/* <button className='btn btn-accent rounded-pill fw-bold'>Search Tickets</button> */}
            </div>
            <div className='col-12 col-md-5 text-center'>
              {/* <h2 className='text-primary fw-bold'>Sell Tickets</h2> */}
              <ButtonComponent btn_text='Sell Tickets' onClick={handleRegisterToSell} />
              <p className='line-height-28 mt-3'>
                <span className='fw-bold'>Share your passion. Sell your tickets.</span><br />
                Connect securely to buyers, enjoy reliable support and a 100% guarantee.
              </p>
              {/* <ButtonComponent btn_text='Register to sell' onClick={handleRegisterToSell} /> */}
              {/* <button className='btn btn-accent rounded-pill fw-bold'>Register to sell</button> */}
            </div>
          </div>
        </div>

        <InfoComponent heading_text='Why we’re different' dataList={why_we_are_different} />

        <div className=''>
          {/* <img className='w-100 d-none d-lg-block jumbotron-on-page rounded' src={home_img1} />
          <img className='w-100 d-block d-lg-none jumbotron-on-page-small-screen rounded' src={home_img1_small} /> */}
          {/* <div className='px-3 px-md-5  translate-middle w-100 h-100 rounded'>
            <div className='w-100 h-100 rounded' style={{ background: 'rgba(20, 40, 98, 0.6)' }}></div>
          </div> */}
          {/* <div className='px-3 px-md-5 pt-56 pb-md-5 text-center tic-wrapper-width'>
            <h2 className='fw-bold'>What makes us Tic?</h2>
            <h5 className='d-none d-sm-block lh-base mb-0' style={{fontSize:"18px"}}>
              PrimeTic is more than a ticketing platform. We’re a gateway to unforgettable sporting experiences. To connections that transcend the game. To lasting memories. Our selling and buying system is simple, secure and supported, and tickets are 100% guaranteed.
              <br /><br />
              <span>
                Can’t find your ticket? Set an alert to find your ticket and match with sellers within your budget when tickets become available.
              </span>
            </h5>
            <h6 className='d-block d-sm-none lh-base mb-0'>
              PrimeTic is more than a ticketing platform. We’re a gateway to unforgettable sporting experiences. To connections that transcend the game. To lasting memories. Our selling and buying system is simple, secure and supported, and tickets are 100% guaranteed.
              <br /><br />
              <span>
                Can’t find your ticket? Set an alert to find your ticket and match with sellers within your budget when tickets become available.
              </span>
            </h6>
          </div> */}
        </div>

        {/* <div className='position-relative bg-secondary py-10 px-md-56'>
              <h1 className='text-center text-primary fw-bold'>Why we’re different</h1>
              <div style={{rowGap: '1.7rem'}} className='row mt-4'>
                {why_we_are_different.map((option)=>{
                return <div key={option.id} className='col-12 col-md-6 row align-items-center justify-content-center'>
                  <img style={{flexBasis: '50px'}} className='col-4' src={option.icon} alt=''/>
                  <p className='mb-0 col-8'>{option.text}</p>
                </div>
                })}
              </div>
              <img style={{bottom: '-50px'}} className='position-absolute start-0' src={right_ellipse} alt='' />
              <img style={{bottom: '-50px'}} className='position-absolute end-0' src={left_ellipse} alt='' />
            </div> */}
      </div>
    </GeneralWrapper>
  )
}
