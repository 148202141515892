import React, { useState, useEffect } from 'react';

export default function RestrictionsPage({ showDbPopup, setShowDbPopup,  selectedItem }) {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [checkboxLabels, setCheckboxLabels] = useState([]);
  useEffect(() => {
    if (selectedItem) {
      const features = selectedItem.split('|');
      setSelectedCheckboxes(features);
    }
  }, [selectedItem]);

  const closeDbPopup = () => {
    setShowDbPopup(false);
  };

  
  return (
    <div id="demo-modal" className="modal delivery-addresssec" style={{ display: showDbPopup ? 'flex' : 'none' }}>
      <div className="modal__content modal__content-padd pt-4">
        <div className="modalFields">
        <h6 className="fw-bold me-3 mb-0" style={{ color: "#000", fontSize: "16px" }}>Features & Restrictions</h6>
          <div className="mt-3 p-3 border tickets-listsec">
            <div className="grid-container">
            {selectedCheckboxes.map((label, index) => (
                <div className="form-check" key={index}>
                  {/* <input
                    className="form-check-input"
                    type="checkbox"
                    id={`checkbox-${index}`}
                    checked={selectedCheckboxes.includes(label)}
                  /> */}
                  <i className="fa fa-arrow-right" style={{marginRight:"5px", fontSize:"12px"}}></i>
                  <label className="mb-0" htmlFor={`checkbox-${index}`}>
                    {label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <a onClick={closeDbPopup} className="modal__close btn-close-restrictn btn">
          Close
        </a>      </div>
    </div>
  );
}
                 
