import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { clearAll, onUserLogin, setErrorMessage, setLoading } from '../../../config/redux/slice/authSlice'
import { post_put_patch_request } from '../../../helpers/request'
import { app_api } from '../../../utils/endpoints/app_api'
import { app_urls } from '../../../utils/urls/app_urls'
import AuthAlert from '../../components/AuthAlert'
import PasswordComponent from '../../components/Form/PasswordComponent'
import Spinner from '../../components/Spinner'
import AuthWrapper from '../../layout/AuthWrapper'
import email_verification_background from '../../../assets/images/background_images/email_verification_background.jpeg'

export default function EmailVerificationLinkSentScreen() {
  const dispatch = useDispatch()
  const {register, handleSubmit, formState: {errors}} = useForm({mode: 'onTouched'})

  const [successMessage, setSuccessMessage] = useState(false)

  const {
        loading,
        errorMsg,
        userTemporaryDetails:{email}
    } = useSelector((state) => state.authData)

  useEffect(()=>{
      dispatch(clearAll({}))
    }, [])  

    const resendEmail = async (e) => {
        e.preventDefault()
        dispatch(clearAll({}))
        setSuccessMessage(false)
        const res = await post_put_patch_request(
          "post",
            "",
            `${app_api.resend_verification_email}`,
            {email},
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )
        if(res !== undefined && res.status < 400){
            setSuccessMessage(true)
        }
    }

    const closeErrorAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    const closeSuccessAlert = () => {
        setSuccessMessage(false)
    }

    // const resendEmail = async (data) => {
    //   setIsSending(true)
    //     const res = await post_put_patch_request(
    //       "post",
    //         "",
    //         `${app_api.resend_verification_email}`,
    //         {email},
    //         dispatch,
    //         "",
    //         "",
    //         ""
    //     )
    //     if(res !== undefined){
    //       dispatch(onOnboardUser({email}))
    //       navigate(app_urls.verification_link_sent)
    //     }
    // }

  return (
    <AuthWrapper background_image={email_verification_background}>
      {successMessage && <AuthAlert onClick={closeSuccessAlert} alertStyles={{width: '95%', margin: '0 auto'}} status='success'>
        Email Verification Link Sent
        </AuthAlert>}  
      {errorMsg && <AuthAlert onClick={closeErrorAlert} alertStyles={{width: '95%', margin: '0 auto'}} status='error'>
        {errorMsg}
        </AuthAlert>}
        <div style={{columnGap: '0.8rem'}} className='greeting d-flex justify-content-center mb-2 align-items-center'>
              <i style={{fontSize: '1.2rem'}} className={`mdi mdi-email`}></i>
              <p style={{fontSize: '1rem'}} className={`text-capitalize`}>
                We have Sent You An Email
              </p>
            </div>
        <div className='text-center mb-2' style={{color: '#31343D'}}>
            <p style={{fontSize: '0.8rem', lineHeight: '1.8rem'}}>Your account has been created. 
            A verification email has been sent to your email address (<b>{email}</b>). Click the link 
            to verify your email</p>
        </div>
      <div className="col-md-12">


        <button type="button" className="btn btn-outline-primary mb-4">
            <i className=" mdi mdi-arrow-left-thick mr-1"></i>
            <Link className='text-hover-white' to={app_urls.home}>Visit Site</Link>
        </button>
        {/* <Spinner/> */}

        <div style={{columnGap: '0.4rem'}} className='d-flex'>
            <p>Didn't receive the email yet?
            </p>
            <a onClick={(e)=>{resendEmail(e)}} className="text-blue" role='button'>Send Again</a>
        </div>
      </div>
    </AuthWrapper>
  )
}
