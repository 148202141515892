import React, { useEffect, useState } from 'react'
import CardWrapper from '../../../layout/CardWrapper'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import { transformSellOption } from '../../../../helpers/helpers'

export default function ListingDetails() {
    const navigate = useNavigate()
    // const {
    //   listingManagement: {listingDetails}
    // } = useSelector((state) => state.customer)

    const {
      customerLoading,
    listingManagement: {singleListingDetails, listingDetails}
  } = useSelector((state) => state.customer)

    const [details_list, set_details_list] = useState([])

    useEffect(()=>{
        let details = [
            {
                id: 1,
                name: 'Seller',
                value: singleListingDetails?.user?.name
            },
            {
                id: 2,
                name: 'Event',
                value: singleListingDetails?.event?.name
            },
            {
              id: 13,
              name: 'Event Date',
              value: moment(singleListingDetails?.event?.date).format('ddd, DD MMMM YYYY')
          },
            // {
            //     id: 3,
            //     name: 'Venue',
            //     value: singleListingDetails?.Venue?.name
            // },
            {
                id: 4,
                name: 'Type of Tickets',
                value: singleListingDetails?.ticketsType
            },
            {
                id: 5,
                name: 'No of Tickets',
                value: singleListingDetails?.numberOfTickets
            },
            {
                id: 12,
                name: 'Sell Option',
                value: transformSellOption(singleListingDetails?.sellingOption) 
            },
            {
                id: 6,
                name: 'Section',
                value: singleListingDetails?.section?.name
            },
            {
            id: 7,
            name: 'Block',
            value: singleListingDetails?.subSection?.name
          },
          {
            id: 13,
            name: 'Price Per Ticket',
            value: singleListingDetails?.pricePerTicketWithoutCommission
          },
           {
                id: 9,
                name: 'Reserved Price Per Ticket',
                value: singleListingDetails?.reservedPricePerTicket
            },
            {
                id: 10,
                name: 'Net Price',
                value: singleListingDetails?.numberOfTickets * singleListingDetails?.pricePerTicket
            },
            {
                id: 11,
                name: 'Listed On',
                value: moment(singleListingDetails?.createdAt).format('ddd, DD MMMM YYYY')
            },
        ]
        set_details_list(details)
    }, [])
  return (
    <CustomerWrapper heading={'Listings'}>
          <div className='mb-3'>
        <button
        onClick={()=>{navigate(app_urls.listing_event_dyn)}}
          style={{color: 'rgba(0,0,0,0.9)'}}
         className='btn btn-sm bg-accent fw-bold'
        >
          <i style={{fontSize: '1rem'}} className="mdi mdi-arrow-left me-1"></i>
          Go back to Detail
        </button>
      </div>
        <CardWrapper cardTitle={'Listing Details'} cardButtons={<ActionButton/>}>
            <div style={{rowGap: '1.2rem'}} className='row mt-2'>
                {details_list.map((option)=>{
                    return <div className='col-md-6 col-lg-4'>
                                <span className='fw-bold'>{option.name}: </span>
                                <span>{option.value}</span>
                            </div>
                })}
            </div>
        </CardWrapper>
    </CustomerWrapper>
  )
}

function ActionButton(){
  const dispatch = useDispatch()
  const navigate = useNavigate()

   const {
      listingManagement: {singleListingDetails}
    } = useSelector((state) => state.customer)
  return(
    <div style={{columnGap: '0.5rem', rowGap: '0.6rem'}} className='d-flex flex-wrap'>
      <button onClick={()=> navigate(app_urls.user_tickets_dyn(singleListingDetails?.id))} className='btn btn-sm btn-accent'>
        <i style={{fontSize: '1rem'}} className="mdi mdi-eye me-1"></i>
        View Tickets
      </button>
    </div>
  )
}
