import React, { useEffect, useState } from 'react'
import left_ellipse from '../../assets/images/left_ellipse_icon.svg'
import right_ellipse from '../../assets/images/right_ellipse_icon.svg'
import right_slider_button_icon from '../../assets/images/right_slider_button_icon.svg'
import left_slider_button_icon from '../../assets/images/left_slider_button_icon.svg'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../utils/urls/app_urls'

export default function PostHighlightComponent({
  heading_text,
  dataList,
  page = 'buy',
  containerId = 'myScrollContainer',
  contentId = 'myScrollContent',
  mt2,
  flexbasis
}) {
  const navigate = useNavigate()
  const [scrollContainer, setScrollContainer] = useState(null)
  const [scrollContent, setScrollContent] = useState(null)
  const [scrollPosition] = useState(200)
  const [isScrollEnd, setIsScrollEnd] = useState(false)
  const [isScrollBeginning, setIsScrollBeginning] = useState(true)
  const [showArrows, setShowArrows] = useState(false)

  useEffect(() => {
    const container = document.getElementById(containerId)
    const content = document.getElementById(contentId)
    
    setScrollContainer(container)
    setScrollContent(content)
    
    const updateArrows = () => {
      if (scrollContent && scrollContainer) {
        setIsScrollEnd(isEndXReached())
        setIsScrollBeginning(isBegininngXReached())
      }
    }

    updateArrows() // Initial check
    window.addEventListener('resize', updateArrows)
    
    // Run the arrow update check whenever content changes
    return () => window.removeEventListener('resize', updateArrows)
  }, [containerId, contentId, scrollContent, scrollContainer])

  useEffect(() => {
    // Ensure arrow visibility on content change
    if (scrollContent && scrollContainer) {
      setIsScrollEnd(isEndXReached())
      setIsScrollBeginning(isBegininngXReached())
      setShowArrows(dataList.length > 4)
    }
  }, [dataList]) // Dependency on dataList to ensure arrows are updated if dataList changes

  const scrollXToPositionPositive = () => {
    if (scrollContent && scrollContainer) {
      const isEnd = isEndXReached()
      if (!isEnd) {
        scrollContent.scrollLeft += scrollPosition
        // Update arrow visibility after scrolling
        setIsScrollEnd(isEndXReached())
        setIsScrollBeginning(isBegininngXReached())
      }
    }
  }

  const scrollXToPositionNegative = () => {
    if (scrollContent && scrollContainer) {
      const isBeginning = isBegininngXReached()
      if (!isBeginning) {
        scrollContent.scrollLeft -= scrollPosition
        // Update arrow visibility after scrolling
        setIsScrollEnd(isEndXReached())
        setIsScrollBeginning(isBegininngXReached())
      }
    }
  }

  const isEndXReached = () => {
    // Show right arrow if the content can still be scrolled to the right
    return (
      scrollContent.scrollLeft + scrollContainer.clientWidth + 1 >=
      scrollContent.scrollWidth
    )
  }

  const isBegininngXReached = () => {
    return scrollContent.scrollLeft === 0
  }

  return (
    <div className={`mt-${mt2 === 2 ? 2 : 5} px-3 px-md-5`}>
      <h2 className="text-left fw-bold text-primary head-left-align">{heading_text}</h2>
      <div className="position-relative">
        {showArrows &&(isScrollBeginning || scrollContent?.scrollWidth > scrollContent?.clientWidth) && (
          <img
            role="button"
            onClick={() => scrollXToPositionNegative()}
            style={{ left: '-15px', top: '35%', zIndex: 10 }}
            className="position-absolute"
            src={left_slider_button_icon}
            alt="Left Arrow"
          />
        )}
        <div id={containerId} className="overflow-x-hidden mt-2">
          <div
            style={{ columnGap: '1.4rem' }}
            className="d-flex align-items-center scroll-content"
            id={contentId}
          >
            {dataList.map((option) => (
              <div
                key={option.id}
                className="card border-0 rounded cursor-pointer"
                style={{ flexBasis: flexbasis, flexShrink: 0 }}
                onClick={() => {
                  if (!option?.static) {
                    if (page === 'sell') {
                      navigate(app_urls.sell_ticket_dyn(option.id))
                    } else {
                      navigate(app_urls.single_event_dyn(option.id))
                    }
                  }
                }}
              >
                <div className="position-relative rounded-top">
                  <figure>
                    <img
                      crossOrigin="anonymous"
                      src={option.poster}
                      className="card-img-top latest-event-image rounded-top"
                      alt="Event Poster"
                    />
                  </figure>
                  <img
                    style={{
                      width: '28px',
                      height: '28px',
                      bottom: '-15px',
                      left: '-10px',
                      zIndex: 3,
                    }}
                    alt="ellipse"
                    className="position-absolute"
                    src={right_ellipse}
                  />
                  <img
                    style={{
                      width: '28px',
                      height: '28px',
                      bottom: '-15px',
                      right: '-10px',
                      zIndex: 3,
                    }}
                    alt="ellipse"
                    className="position-absolute"
                    src={left_ellipse}
                  />
                </div>
                <div className="card-body bg-primary rounded-bottom card-body-padd">
                  <p className="card-text text-white">
                    <span className="text-accent fw-bold cardName-height-trending" style={{whiteSpace:"normal"}}>{option.name}</span>
                    {/* <br /> */}
                    <div className="fw-bold card-date-fs">
                      {moment(option.date).format('ddd Do MMM YYYY - h:mma')}
                    </div>
                    {/* <br /> */}
                    <div className='venue-text-name' title={option.Venue.name}>{option.Venue.name}</div>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {showArrows && (isScrollEnd || scrollContent?.scrollWidth > scrollContent?.clientWidth) && (
          <img
            role="button"
            onClick={() => scrollXToPositionPositive()}
            style={{ right: '-15px', top: '35%', zIndex: 10 }}
            className="position-absolute"
            src={right_slider_button_icon}
            alt="Right Arrow"
          />
        )}
      </div>
    </div>
  )
}
