import React from 'react'
import fb_logo from '../../assets/images/FB Logo.svg'
import insta_logo from '../../assets/images/Insta Logo.svg'
import x_logo from '../../assets/images/X Logo.svg'
import linkedIn_logo from '../../assets/images/LinkedIn Logo.svg'
import { ButtonComponent } from '../components'
import { Link, useNavigate } from 'react-router-dom'
import { app_urls } from '../../utils/urls/app_urls'

export default function FooterLayout() {
  const navigate = useNavigate()

  const navigateToPage = (url) => {
    navigate(url)
  }
  const handleJoinCommunity= () => {
    navigate(app_urls.login) // Use the appropriate URL
  }
  const currentYear = new Date().getFullYear();

  return (
    <div className='bg-primary px-3 px-md-5 pt-56 pb-md-5 text-white'>
      <div style={{rowGap: '1.5rem'}} className='row'>
        <div className='col-12 col-md-6 col-lg-4'>
          <h2 className='fw-bold'>Let's Connect</h2>
          <p style={{lineHeight: '24px'}}>We’re passionate about sports. 
            And we’re betting you are, too. Join the PrimeTic community to stay up 
            to date with what you care about most.</p>
            <ButtonComponent color='bg-white' onClick={handleJoinCommunity} btn_text='Join the Community'/>
            {/* <button className='btn rounded-pill bg-white fw-bold'>Join the Community</button> */}
            {/* <div style={{width: '15rem'}} className='d-flex justify-content-between mt-5'>
              <img src={fb_logo} alt=''/>
              <img src={insta_logo} alt=''/>
              <img src={x_logo} alt=''/>
              <img src={linkedIn_logo} alt=''/>
            </div> */}
        </div>
        <div className='col-12 d-none d-lg-block col-lg-4'></div>
        <div className='row col-12 col-md-6 col-lg-4'>
          <ul className='col-6 list-style-type-none line-height-36'>
            {/* <li>Company</li> */}
            <li class="cursor-pointer" onClick={() => navigate(app_urls.contact_us)}>Contact</li>

            <li role='button' onClick={()=>navigate(app_urls.about_us)}>About us</li>
            <li role='button' onClick={()=>navigate(app_urls.sellers)}>Sellers</li>
            <li role='button' onClick={()=>navigate(app_urls.buyers)}>Buyers</li>
            <li>FAQ</li>
          </ul>
          <ul className='col-6 list-style-type-none line-height-36'>
            {/* <li>Support</li> */}
            <li role='button' onClick={()=>{navigate(app_urls.register)}}>Register</li>
            <li role='button' onClick={()=>{navigate(app_urls.login)}}>Log in</li>
            <li class="cursor-pointer" onClick={()=>{navigate(app_urls.refund_policy)}}>Refund Policy</li>
            <li class="cursor-pointer" onClick={() => navigate(app_urls.contact_us)}>Report an issue</li>
          </ul>
        </div>
      </div>
      <div style={{rowGap: '1.5rem'}} className='row mt-5'>
        <div className='col-12 col-md-6 col-lg-4 order-2 order-md-1'>
          <small>&copy; Copyright {currentYear}. All Rights Reserved Prime Events</small>
        </div>
        <div className='col-12 d-none d-lg-block col-lg-4 order-3 order-md-2'>
        </div>
        <div className='col-12 col-md-6 col-lg-4 order-1 order-md-3'>
          <div style={{columnGap: '2rem', rowGap: '0.7rem'}} className="d-flex flex-wrap">
            <Link className="nav-link text-decoration-underline active flex-shrink-0" aria-current="page" to={app_urls.terms_and_condition}>Terms & Conditions</Link>
            <Link className="nav-link text-decoration-underline flex-shrink-0" to={app_urls.privacy_policy}>Privacy Policy</Link>
            {/* <a className="nav-link text-decoration-underline flex-shrink-0" href="#">Support</a> */}
      </div>
        </div>
      </div>
    </div>
  )
}
