import React, { useEffect } from 'react'
import AppWrapper from './AppWrapper'
import { clearAll, setData } from '../../config/redux/slice/appSlice';
import { get_delete_request } from '../../helpers/request';
import { useDispatch, useSelector } from 'react-redux';
import { app_api } from '../../utils/endpoints/app_api';
import SearchModal from '../components/SearchModal';

export default function GeneralWrapper({children}) {
const dispatch = useDispatch()

const {
      eventManagement: {showEventModal: {show,type}}
    } = useSelector((state) => state.app)
    const {token, userData } = useSelector((state) => state.authData)
  useEffect(()=>{
    getAllEvents()
  },[userData])

  const getAllEvents = async () =>{

    const isAuthenticated = userData && userData.email;
    const tokenValue = isAuthenticated ? token : '';
    const endpoint = isAuthenticated ? `${app_api.get_trending_events_auth}` : `${app_api.get_trending_events}`;

    console.log("userData",userData)
    console.log("userData",userData)

      dispatch(clearAll({}))
      // const res = await get_delete_request(
      //   'get',
      //   tokenValue,
      //   `${app_api.get_trending_events}`,
      //   {},
      //   dispatch,
      //   "",
      //   "",
      //   ""
      // )
      // console.log('res', res);
      // if(res !== undefined && res.status < 400){
      //   dispatch(setData({data: res?.data?.data, type: 'event'}))
      // }
      try {
        const res = await get_delete_request(
          'get',
          tokenValue,
          endpoint,
          {},
          dispatch,
          "",
          "",
          ""
        );
        if (res !== undefined && res.status < 400) {
          dispatch(setData({ data: res?.data?.data, type: 'event' }));
        } else {
          console.error('Error fetching events:', res?.data ?? 'No response data');
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

  return (
    <AppWrapper>
      {(show && type == 'search') && <SearchModal/>}
      <div style={{paddingTop: '66px'}}>
        {children}
      </div>
    </AppWrapper>
  )
}
