import React from 'react'

export default function CardWrapper({children, cardTitle, cardButtons}) {
  return (
      <div className="card">
        {cardTitle &&
        <div style={{rowGap: '0.9rem'}} className="card-header py-2 border-bottom d-flex align-items-center justify-content-between flex-wrap">
          <h6 className='fw-bold mb-0'>{cardTitle}</h6>
          {cardButtons}
        </div>}
        <div className="card-body pt-3">
            {children}
        </div>
      </div>
  )
}
