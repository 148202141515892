import React, { useEffect,useState } from 'react'
import UserTable from './userTableCopy'
import AdminWrapper from '../../../layout/AdminWrapper'

import { useDispatch, useSelector } from 'react-redux'
import { clearAll } from '../../../../config/redux/slice/adminSlice'
//import DeleteUser from './DeleteUser'
//import SuspendUser from './SuspendUser'
import ChangeUserType from './ChangeUserType'
import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request } from '../../../../helpers/request'
import UserManagementTable from './UserMangementTable'

export default function UserManagement({payoutObj}) {

  const dispatch = useDispatch()
  const {
    userManagement: {
      showAddUserForm,
      userDetails,
      showUserModal: {show, type}
    }
    } = useSelector((state) => state.admin)

    useEffect(() => {
      dispatch(clearAll({}))
      getAllUserTypes() //////xdsd

    },[])

    const {
      token
  } = useSelector((state) => state.authData)

    const [userTypes, setUserTypes] = useState([]) ///////xdsd


    const getAllUserTypes = async () =>{
      dispatch(clearAll({}))
      const res = await get_delete_request(
        'get',
        token,
        `${app_api.get_auth_user_type}`,
        {},
        dispatch,
        "",
        "",
        ""
      )
      if(res != undefined && res.status < 400){
        console.log("usertypeeeeeeeeeeee",res)
        setUserTypes(res?.data?.data)
      }
    }


  return (
    <AdminWrapper>
     
      {(show && type == 'changeusertype') &&
      <ChangeUserType user={userDetails}/>}
      <div>
        {/* {showAddUserForm &&
          <CreateUser userTypes={userTypes}/>
        }   */}
        {console.log("xxxxxxxxxxxxxxxxxxxxxxxxx",userTypes.length)}
        {userTypes.length > 0 && (
          <UserManagementTable userTypes={userTypes}/>
        )}        
      </div>
    </AdminWrapper>
  )
}
