// AdSenseComponent.jsx
import React, { useEffect } from 'react';
import { google_adsense_client_id } from '../../config';

const AdSenseComponent = () => {
  useEffect(() => {
    // Load the advertising script asynchronously
    const script = document.createElement('script');
    script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${google_adsense_client_id}`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize ads
    if (window.adsbygoogle && !window.adsbygoogle.loaded){
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <div>
      {/* Your component's content */}
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="your-ad-client-id"
        data-ad-slot="your-ad-slot-id"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default AdSenseComponent;
