import React from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import Spinner from '../../../components/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setAdminErrorMessageUser, setAdminLoadingUser, showCountryModalFunc } from '../../../../config/redux/slice/adminSlice'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useForm } from 'react-hook-form'

export default function EditCountry({countryObj}) {
    const dispatch = useDispatch()
    const {register, handleSubmit, watch, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
  
    const {
            adminLoading,
            adminErrorMsg,
            adminSuccessMsg
    } = useSelector((state) => state.admin)

    const {
        token
    } = useSelector((state) => state.authData)

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        const res = await post_put_patch_request(
          "patch",
            token,
            `${app_api.update_country(countryObj?.id)}`,
            data,
            dispatch,
            setAdminLoadingUser,
            "",
            setAdminErrorMessageUser
        )
        if(res !== undefined && res.status < 400){
          dispatch(onUpdate({message: res.data.message, type: 'country'}))
        }
    }

    const closeModal = () => {
      dispatch(showCountryModalFunc({modal: false, type: null}))
    }

  return (
    <ModalLayout classStyles={'modal-lg'} onClick={closeModal} heading={'Edit Country'}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="modal-body">
                <div className="px-4 px-md-7">
                    <div style={{rowGap: '1.2rem'}} className="row mb-2">

                    <div className="col-md-5">
                        <div className="form-group">
                        <label htmlFor='name'>Name <span className="text-danger">*</span></label>
                        <input 
                        type="text" 
                        defaultValue={countryObj?.name}
                        className="form-control input-lg" 
                        id="name"
                        {...register("name", {
                            required: {
                            value: true,
                            message: '*this field is required'
                            }
                        })
                        } 
                        />
                        {
                        errors?.name?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.name?.message}
                        </div>
                        }
                    </div> 
                    </div>

                    <div className='col-md-7'>
                        <div className="form-group">
                        <label htmlFor="flag">Flag <span className="text-danger">*</span></label>
                        <textarea 
                        defaultValue={countryObj?.flag}
                        style={{resize: 'none'}}
                            className="form-control" 
                            id="flag" 
                            rows="5"
                            {...register("flag", {
                            required: {
                            value: true,
                            message: '*this field is required'
                            },
                            })} 
                        ></textarea>
                        {
                        errors?.flag?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.flag?.message}
                        </div>
                        }
                    </div>
                    </div>
                    </div>

                </div>
            </div>
            <div className="modal-footer border-top-0 d-flex align-items-center">
                <div>
                {adminLoading ? 
                    <Spinner isForm={true}/> :
                    <button type="submit" className="btn btn-primary me-3">Submit Form</button>
                }
                </div>
                <button disabled={adminLoading ? true : false} onClick={closeModal} type="button" className="btn btn-info">Cancel</button>
            </div>
        </form>
    </ModalLayout>
  )
}
