import React from 'react'
import Spinner from './Spinner'
import { useForm } from 'react-hook-form'

export default function FilterWrapper({children, heading_title, onClick, resetFunc, loading, onSubmit}) {
  return (
      <div className="card py-4 mb-4">
        <div className="px-4 px-md-7 mb-3">
            <div className='d-flex justify-content-between align-items-center'>
                <h6 className="card-title fw-bold text-uppercase">Filter {heading_title}</h6>
                {/* <button 
                    type="button" 
                    className="btn btn-sm" 
                    onClick={onClick}
                >
                <i style={{fontSize: '1rem'}} className="mdi mdi-close"></i>
                </button> */}
            </div>
        </div>
        <form onSubmit={onSubmit} noValidate>
            <div className="px-4 px-md-7">
                <div style={{rowGap: '1.2rem'}} className="row mb-2">
                {children}
                </div>
            </div>
            <div className="mt-4 px-4 d-flex align-items-center">
                <div>
                {loading ? 
                    <Spinner isForm={true}/> :
                    <button type="submit" className="btn btn-primary me-3">Submit</button>
                }  
                </div>
                <button type="button" onClick={resetFunc} className="btn btn-info">Reset</button>
            </div>
      </form>
    </div>  
  )
}
