import React from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import { useDispatch } from 'react-redux'
import { showTicketModalFunc, showSalesModalFunc } from '../../../../config/redux/slice/customerSlice'

export default function ViewTicket({ticketObj, type}) {
  console.log('111', ticketObj);
    const dispatch = useDispatch()

    const closeModal = () => {
      if(type == 'viewTicket') {
        dispatch(showSalesModalFunc({modal: false, type: null}))

      }
      else {
      dispatch(showTicketModalFunc({modal: false, type: null}))
      }
    }

    const renderFile = (file) => {
      console.log('file', file);
      const fileExtension = file?.split('.').pop().toLowerCase();
      console.log('fileExtension', fileExtension);

      if (fileExtension === 'pdf') {
          return (
            <iframe
            src={file}
            width="100%"
            height="500px"
            title="Electronic Ticket"
        >
            <p>Your browser does not support iframes. <a href={file}>Download the PDF</a>.</p>
        </iframe>
              
              
          )
      } else {
        console.log('inside else');
          return (
              <img
                  crossOrigin='anonymous'
                  src={file}
                  alt="Electronic Ticket"
              />
          )
      }
  }

  return (
    <ModalLayout onClick={closeModal} heading={'Electronic Ticket'}>
        {renderFile(ticketObj?.file)}
    </ModalLayout>
  )
}
