import React, { useEffect, useRef, useState } from 'react'
import Sidebar from './Sidebar'
import ProfileView from './ProfileView'
import EditProfile from './EditProfile'
import ChangePassword from './ChangePassword'
import { useLocation } from 'react-router-dom'
import AdminWrapper from '../../../layout/AdminWrapper'

export default function Profile() {
  const location = useLocation()
  const sidebarRef = useRef(null)
  const profileRef = useRef(null)
  const [currentTab, setCurrentTab] = useState('')

  const params = new URLSearchParams(location.search)

  useEffect(() => {
    const tab = params.get('tab')
    setCurrentTab(tab)
  }, [params.get('tab')])
  return (
    <AdminWrapper>
      {/* <div className='bg-white'>
        hello
      </div> */}
      {/* <div className='bg-white'> */}
      <div style={{ minHeight: '400px' }} className="ms-0 row w-100">
        <div ref={sidebarRef} className="col-sm-5 col-md-4 px-0 py-2 bg-white">
          <Sidebar
            onClick={() => {
              if (profileRef.current) {
                profileRef.current.classList.remove('hide-profile')
              }
              if (sidebarRef.current) {
                sidebarRef.current.classList.add('hide-profile-list')
              }
            }}
          />
        </div>
        <div
          ref={profileRef}
          className="hide-profile col-sm-7 col-md-8 px-0 py-2 bg-white border border-start-1"
        >
          <div className="p-2 d-flex align-items-center">
            <button
              onClick={() => {
                if (profileRef.current) {
                  profileRef.current.classList.add('hide-profile')
                }
                if (sidebarRef.current) {
                  sidebarRef.current.classList.remove('hide-profile-list')
                }
              }}
              style={{
                backgroundColor: 'rgba(0,0,0,0.1)',
                color: 'rgba(0,0,0,0.9)',
              }}
              className="btn btn-sm d-block d-sm-none  fw-bold"
            >
              <i
                style={{ fontSize: '1rem' }}
                className="mdi mdi-arrow-left me-1"
              ></i>
              Back
            </button>
            <h6 className="fw-bold mb-0 ms-2 ms-sm-0">
              {currentTab == 'change-password'
                ? 'Change Password'
                : currentTab == 'edit-profile'
                ? 'Edit Profile'
                : 'Profile'}
            </h6>
          </div>
          {currentTab == 'change-password' ? (
            <ChangePassword />
          ) : currentTab == 'edit-profile' ? (
            <EditProfile />
          ) : (
            <ProfileView />
          )}
        </div>
      </div>
      {/* </div> */}
    </AdminWrapper>
  )
}
