import React, { useEffect, useState } from 'react';

export default function Loader() {
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setShowLoader(true);
    }, []);

    return (
        // <div className="loaderSection">
        <>
            {showLoader && (
                <>
               <div id="loader"></div>
                </>
            )}
            </>
        // </div>
    );
}
