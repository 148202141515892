import React from 'react';
import left_ellipse from '../../assets/images/left_ellipse_icon.svg';
import right_ellipse from '../../assets/images/right_ellipse_icon.svg';
import search_icon from '../../assets/images/search_icon.svg'
import { useDispatch } from 'react-redux';
import { showEventModalFunc } from '../../config/redux/slice/appSlice';
import SearchComponent from './SearchComponent';

export default function JumbotronComponent({hero_text, placeholder='Search For tickets', img_src, img_src_small, landingPage='buy', hasSearch=false}) {
  const dispatch = useDispatch()
  return (
    <div className='position-relative'>
        <img style={{height: '640px'}} className='w-100 d-none d-md-block jumbotron-on-page' src={img_src} alt='people in a football stadium cheering'/>
        <img style={{height: '416px'}} className='w-100 d-block d-md-none' src={img_src_small} alt='people in a football stadium cheering'/>
        <div style={{background: 'rgba(20, 40, 98, 0.5)'}} className='position-absolute top-50 start-50 translate-middle w-100 h-100'></div>
        <img className='position-absolute top-50 start-0 translate-middle-y' src={right_ellipse} />
        <img className='position-absolute top-50 end-0 translate-middle-y' src={left_ellipse} />
        {/* <div className='position-absolute top-50 start-50 translate-middle'> */}
        <div style={{rowGap: '3rem'}} className='hero-text px-3 px-lg-56 px-xl-200 position-absolute top-50 start-50 translate-middle'>
        <h1 className='fw-bold d-block d-md-none fs-44 text-center text-white'>{hero_text}</h1>
        <h1 className='fw-bold d-none d-md-block d-lg-none fs-52 text-center text-white'>{hero_text}</h1>
        <h1 className='fw-bold d-none d-lg-block fs-8 text-center text-white'>{hero_text}</h1>
        {hasSearch &&
          <>
            {/* <div className="input-group">
              <button className="btn bg-white rounded-start-pill" type="button" id="button-addon1">
                <img src={search_icon} alt='' />
              </button>
              <input 
              onClick={()=>{
                dispatch(showEventModalFunc({modal: true, type: 'search', page}))
              }}
                type="text" 
                className="form-control rounded-end-pill fs-6" 
                placeholder={placeholder} 
                aria-label="Example text with button addon" 
                aria-describedby="button-addon1"
              />
            </div> */}
            <SearchComponent landingPage={landingPage}/>
          </>
        }
        {/* </div> */}
        </div>
    </div>
  )
}
