import React, { useEffect } from 'react'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { payoutDueAdminDatatable } from '../../../../helpers/dataTables'
import { clearAll, clearResetBot, onGetPayoutsDue, setAdminErrorMessageUser, setAdminLoadingUser, setDetails, setPayoutValues, showListingModalFunc } from '../../../../config/redux/slice/adminSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useDispatch, useSelector } from 'react-redux'
import { get_delete_request } from '../../../../helpers/request'
import { useNavigate } from 'react-router-dom'
import { admin_urls } from '../../../../utils/urls/admin_urls'
import { setBreadCrumbName } from '../../../../config/redux/slice/appSlice'
import { app_urls } from '../../../../utils/urls/app_urls'


export default function PayoutDueTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const errorFunc = (msg) => {
    dispatch(setAdminErrorMessageUser({ message: msg }))
  }

  const {
    token
  } = useSelector((state) => state.authData)

  

  const {
    payoutManagement: { dueNewData }
  } = useSelector((state) => state.admin)

  const { breadCrumbName, currentNavDetails } = useSelector((state) => state.app)

  // // useEffect(() => {
  // //   dispatch(clearAll({}))
  // //     payoutDueAdminDatatable(
  // //       payoutDueData,
  // //     'payoutDueAdminTable',
  // //     setItem
  // //     )
  // // getAllPayoutDue()
  // // },[])

  useEffect(() => {
    payoutDueAdminDatatable(
      errorFunc,
      'payoutDueAdminTable',
      `${app_api.get_payouts}`,
      `${app_api.search_payouts}`,
      token,
      setItem,
    )

  }, [])

  // // useEffect(()=>{
  // //   if(dueNewData){
  // //     payoutDueAdminDatatable(
  // //     payoutDueData,
  // //     'payoutDueAdminTable',
  // //     setItem
  // //     )
  // //     dispatch(clearResetBot())
  // //   }      
  // // }, [dueNewData])

  useEffect(() => {
    if (dueNewData) {
      payoutDueAdminDatatable(
        errorFunc,
        'payoutDueAdminTable',
        `${app_api.get_payouts}`,
        `${app_api.search_user}`,
        token,
        setItem,
      )
      dispatch(clearResetBot())
    }
  }, [dueNewData])

  // // const getAllPayoutDue = async () =>{
  // //   dispatch(clearAll({}))
  // //   const res = await get_delete_request(
  // //     'get',
  // //     token,
  // //     `${app_api.get_payouts}`,
  // //     {},
  // //     dispatch,
  // //     setAdminLoadingUser,
  // //     onGetPayoutsDue,
  // //     setAdminErrorMessageUser
  // //   )
  // // }

  const setItem = (data, type) => {
    dispatch(setDetails({ details: data, type: 'payoutDue' }))
    dispatch(setPayoutValues({ propertyKey: 'payoutEventId', value: data.id }))
    if (type == 'manage') {
      let payouts = currentNavDetails?.subs?.filter((option) => app_urls.payouts_admin == option.url)
      dispatch(setBreadCrumbName([...breadCrumbName, { id: breadCrumbName.length + 1, name: payouts[0].name, url: payouts[0].url }]))
      navigate(admin_urls.payouts_admin)
    }
  }

  

  return (
    <DataTableWrapper table_id='payoutDueAdminTable' table_heading='Payouts Due'>
      <thead>
        <tr>
          {/* <th className="wd-5p">S/N</th> */}
          <th className="wd-20p">Date</th>
          <th className="wd-25p">Poster</th>
          <th className="wd-15p">Event</th>
          <th className="wd-25p">Amount due</th>
          <th className="wd-25p">Payout Status</th>
          {/* <th className="wd-20p">Time</th> */}
          <th className="wd-15p">Actions</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </DataTableWrapper>
  )
}
