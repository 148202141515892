import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setBreadCrumbName } from '../../../config/redux/slice/appSlice'

export default function BreadCrumb({breadcrumb_name}) {
  const dispatch = useDispatch()

  const {breadCrumbName} = useSelector((state) => state.app)

  return (
    <nav style={{backgroundColor: '#e9ecef'}} className="py-3 px-4" aria-label="breadcrumb">
        <ol className="breadcrumb mb-0">
          {breadCrumbName.map((option)=>{
            return <React.Fragment key={option.id}>
              {breadCrumbName.length == option.id ? 
              <li className="breadcrumb-item active fs-6" aria-current="page">{option.name}</li>
              :
              <li className="breadcrumb-item fs-6">
              <Link 
              to={option.url}
              onClick={()=>{
                let filteredBreadcrumb = breadCrumbName.filter((item)=>item.id  <= option.id)
                dispatch(setBreadCrumbName(filteredBreadcrumb))
              }}
              >
                {option.name}
              </Link></li>
            }
            </React.Fragment>
          })}
        </ol>
    </nav>
  )
}
