import React, { useEffect, useState } from 'react'
import '../../assets/css/error_page_styles.css'
import { Link, useNavigate } from 'react-router-dom'
import { app_urls } from '../../utils/urls/app_urls'
import { clearAll, logoutUser, onOnboardUser, setErrorMessage, setLoading } from '../../config/redux/slice/authSlice'
import { logoutCustomerUser } from '../../config/redux/slice/customerSlice'
import { logoutAppUser } from '../../config/redux/slice/appSlice'
import { logoutAdminUser } from '../../config/redux/slice/adminSlice'
import { useDispatch, useSelector } from 'react-redux'
import { post_put_patch_request } from '../../helpers/request'
import { app_api } from '../../utils/endpoints/app_api'

export default function ErrorPage({status, message, type, resetErrorBoundary}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isSending, setIsSending] = useState(false)

  const {
        loading,
        userTemporaryDetails: {email}
    } = useSelector((state) => state.authData)

    useEffect(()=>{
      setLoading(false)
    }, [])


  const logout = () => {
        dispatch(logoutUser())
        dispatch(logoutCustomerUser())
        dispatch(logoutAppUser())
        dispatch(logoutAdminUser())
    }

     const onSubmit = async (data) => {
      setIsSending(true)
        const res = await post_put_patch_request(
          "post",
            "",
            `${app_api.resend_verification_email}`,
            {email},
            dispatch,
            "",
            "",
            ""
        )
        if(res !== undefined){
          dispatch(onOnboardUser({email}))
          navigate(app_urls.verification_link_sent)
        }
    }
  return (
    <div id="notfound">
		<div className="notfound">
			<div className="notfound-404"></div>
      {status != undefined &&
			<h1>{status}</h1>}
			<h2>Oops! {' '}
                {
                status == 401 ? 'You do not have access to this page'
                : status == 404 ? 'PAGE NOT BE FOUND' 
                : status == 403 ? 'You do not have permission to access this resource' 
                : status == 400 ? 'Bad Request' 
                : status == undefined ? 'Something went wrong' :
                'Access Denied'
                }
            </h2>
            {(status == 403 && type == 'email-verification') &&
              <>
                {isSending ?
                  <p className='mt-2'>
                  Sending email ...
                  </p>
                  :
                <p className='mt-2'>
                  Sorry, {message }. Click 
                  <a style={{cursor: 'pointer'}} className='text-decoration-underline poin' onClick={()=>{
                      onSubmit()
                    }}>&nbsp;here&nbsp;</a>
                  to send verification email
                  </p>}
              </>
            }
      {status == 403 ? 
        <Link onClick={()=>{
          logout()
        }} className='mt-2 link' to={app_urls.home}>Back to homepage</Link>
        : status == undefined ?
        <a onClick={resetErrorBoundary} className='mt-2 link'>Back to homepage</a>
        :
        <Link className='mt-2 link' to={app_urls.home}>Back to homepage</Link>
      }
		</div>
	</div>
  )
}