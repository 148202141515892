import React, { memo, useEffect, useState } from 'react';
import AdminWrapper from "../../../layout/AdminWrapper";
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, clearResetBot, onGetProfileListings, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading, setOrderValues, showOrderModalFunc, setSalesValues, showSalesModalFunc } from '../../../../config/redux/slice/customerSlice'
import { onGetCountries, setAdminErrorMessageUser, showAddEventFormFunc, setDetails, showPromocodeModalFunc } from '../../../../config/redux/slice/adminSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { Link, useNavigate } from 'react-router-dom'
import { get_delete_request } from '../../../../helpers/request'
import Pagination from "react-js-pagination";
import { useForm } from 'react-hook-form'
import DatePicker from "react-datepicker";
import moment from 'moment'
import EditPromocode from './EditPromoCode'
import ToggleStatusPromocode from './ToggleStatusPromocode';
import { format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
export default function PromoCode({ types ,activeList,activeList1}) {
    const dispatch = useDispatch()
    const {
        promocodeManagement: { showAddEventForm, promocodeDetails, showPromocodeModal: { show, type } }
      } = useSelector((state) => state.admin)
    const { register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: { errors }, reset } = useForm({ mode: 'onTouched' })
    const { token } = useSelector((state) => state.authData)
    const [active_data, getActive_data] = useState([]);
    const [editData, setEditData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [addTrending, setAddTrending] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [eventDate, setEventDate] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);
    useEffect(() => {
        getActiveList()
    }, [])
    useEffect(() => {
        console.log('Active List in AddPromocode:', activeList);
        getActive_data(activeList);
        setLoading(false);
      }, [activeList]);
    const getActiveList = async () => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_promocode}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        console.log("🚀 ~ getActiveList ~ res:", res)
        if (res !== undefined && res.status < 400) {
            let active_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal
            getActive_data(active_list);
            setRecordsTotal(totalCount)
            setSearchTerm('')
        }
        setLoading(false);
    }

    const handlePageChange = async (pageNumber) => {
        setLoading(true);
        dispatch(clearAll({}));
        const pageSize = 10;
        setActivePage(pageNumber);

        const formattedEventDate = eventDate ? formatDate(eventDate) : '';
        const formattedCreatedDate = createdDate ? formatDate(createdDate) : '';
        const queryParams = new URLSearchParams({
            eventStatus: 'ActiveEvents',
            addTrending: addTrending ? 'true' : 'false',
            page: pageNumber,
            pageSize: pageSize,
            draw: 1,
            q: searchTerm,
        });

        if (formattedEventDate) {
            queryParams.set('date', formattedEventDate);
        }

        if (formattedCreatedDate) {
            queryParams.set('createdAt', formattedCreatedDate);
        }
        // Conditionally add the type if selected
        if (selectedType) {
            queryParams.append('type', selectedType);
        }

        const url = `${app_api.get_events}?${queryParams.toString()}`;
        const res = await get_delete_request(
            'get',
            token,
            url,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        if (res !== undefined && res.status < 400) {
            let active_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;
            getActive_data(active_list);
            setRecordsTotal(totalCount);
        }
        setLoading(false);
    };

    const onEdit = (data) => {
        setItem(data, 'edit')
    };
    const onDeleteClick = (data) => {
        setItem(data, 'delete')
    };
    const setItem = (data, type) => {
        setEditData(data)
         dispatch(setDetails({ details: data, type: 'promocode' }))
        if (type == 'edit') {
            dispatch(showPromocodeModalFunc({ modal: true, type }))
        } else if (type == 'toggle_status') {
            dispatch(showPromocodeModalFunc({ modal: true, type }))
        }
    }
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const formatDate = (date) => {
        return format(date, 'yyyy-MM-dd'); // Format as YYYY-MM-DD
    };
    const handleSearchChange = async (event) => {
        setLoading(true);
        dispatch(clearAll({}));
        const pageSize = 10;
        const formattedEventDate = eventDate ? formatDate(eventDate) : '';
        const formattedCreatedDate = createdDate ? formatDate(createdDate) : '';
        const queryParams = new URLSearchParams({
            eventStatus: 'ActiveEvents',
            addTrending: addTrending ? 'true' : 'false',
            page: 1,
            pageSize: pageSize,
            draw: 1,
            q: searchTerm,
        });

        if (formattedEventDate) {
            queryParams.set('date', formattedEventDate);
        }
        if (formattedCreatedDate) {
            queryParams.set('createdAt', formattedCreatedDate);
        }
        // Conditionally add the type if selected
        if (selectedType) {
            queryParams.append('type', selectedType);
        }
        const url = `${app_api.get_events}?${queryParams.toString()}`;
        const res = await get_delete_request(
            'get',
            token,
            url,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        if (res !== undefined && res.status < 400) {
            let active_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal
            getActive_data(active_list);
            setRecordsTotal(totalCount)
        }
        setLoading(false);
        setActivePage(1);
    }
    const handleaddTrendingChange = () => {
        setAddTrending(!addTrending); // Toggle checkbox value
    }
    const resetForm = () => {
        reset();
        setSearchTerm('');
        setSelectedType('');
        setEventDate(null);
        setCreatedDate(null);
        setAddTrending(false);
        getActiveList();
    };
    const getPromoCodeTypeName = (id) => {
        const type = types.find((type) => type.id === id);
        return type ? type.name : "Unknown Type"; // Return "Unknown Type" if id not found
      };
    return (
        // <AdminWrapper>
            
        <>
          <ActionButton/>

            <div className="table-responsive web-show-tbl">
                <table className="table table-striped mt-3 tbl-pending">
                    <thead>
                        <tr>
                            <th scope="col" className='no-wrap'>PromoCode Type</th>
                            <th scope="col" className='no-wrap'>Code & Percentage</th>
                            <th scope="col" className='no-wrap'>Date</th>
                            <th scope="col" className='no-wrap'>Description</th>
                            <th scope="col" className='no-wrap'>UserApplied</th>
                            <th scope="col" className='no-wrap'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                            </tr>
                        ) : active_data.length > 0 ? (
                            active_data.map((item, index) => (
                                <>
                                    <tr key={index}>
                                        <td><div class="fw-bold">{getPromoCodeTypeName(item?.promoCodeTypeId)}</div></td>
                                        <td>Code : <b>{item?.code}</b><br/>Percentage : <b>{item.percentage}</b></td>
                                        <td>Start Date : {item?.startDate !== null ? (moment(item?.startDate).format('ddd Do MMM YYYY')):"N/A"} <br/>End Date : {item?.endDate !== null ? (moment(item?.endDate).format('ddd Do MMM YYYY')):"N/A"}<br/>Created On : {item?.createdAt !== null ? (moment(item?.createdAt).format('ddd Do MMM YYYY')):"N/A"}<br/></td>
                                        <td>{item?.Description}</td>
                                        <td>{item?.userApplied}</td>
                                        
                                        <td>
                                            <a
                                                title="Edit Event"
                                                id={item?.id}
                                                // onClick={() => handleEditClick(item)}
                                                onClick={() => onEdit(item)}
                                                class="btn btn-sm bg-grey btn-icon text-white edit-event btn-m-right">
                                                <i class="fa fa-pencil"></i>
                                            </a>
                                            <a onClick={() => setItem(item, 'toggle_status')} title={item?.isActive == true ? 'Deactivate ' : 'Activate '}
                                                                                class={item?.isActive == true ? "btn btn-sm bg-primary text-white btn-icon toggle-listing btn-m-right" : "btn btn-sm bg-success text-white btn-icon toggle-listing btn-m-right"}>
                                                                                <i class={item?.isActive == true ? 'fa fa-pause' : 'fa fa-play'}></i>
                                                                            </a>
                                           
                                        </td>
                                    </tr>
                                </>
                            ))

                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center py-4">No Data Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="mob-table-section">
                {/* {loading ? (
                    <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>

                ) : active_data.length > 0 ? (
                    active_data.map((item, index) => (
                        <>
                            <div className="mob-table-sec-outer" key={index}>
                                <div className="position-relative">
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event</h3>
                                        <div className="mob-tbl-value">{item?.name}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Description</h3>
                                        <div className="mob-tbl-value" title={item?.description}>{item?.description}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Venue</h3>
                                        <div className="mob-tbl-value">{item?.Venue.name}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Poster</h3>
                                        <div className="mob-tbl-value"><img
                                            src={item?.poster}
                                            crossorigin="anonymous"
                                            width="80"
                                            height="80"
                                            alt="poster"
                                        /></div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">No of Listings</h3>
                                        <div className="mob-tbl-value">{item?.listingCount}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">No of Orders</h3>
                                        <div className="mob-tbl-value">{item?.orderCount}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event Type</h3>
                                        <div className="mob-tbl-value">{item?.userType}</div>
                                    </div>
                                    <div className="mob-table-sec-expand">
                                        {item?.addTrending === true ?
                                            <div class="tag">Trending</div>
                                            : ""}
                                        <a
                                            title="Edit Event"
                                            id={item?.id}
                                            onClick={() => onEdit(item)}
                                            class="btn btn-sm bg-grey btn-icon text-white edit-event btn-m-right">
                                            <i class="fa fa-pencil"></i>
                                        </a>
                                        <a title="Delete Event" id={item?.id}
                                            onClick={() => onDeleteClick(item)}
                                            class="btn btn-sm bg-danger btn-icon text-white delete-event btn-m-right">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                ) : (
                    <div className="text-center py-4">No Data Found</div>
                )} */}
            </div>
            {recordsTotal > 0 ?
                <div className='pagination-wrapper'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={recordsTotal}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
                : ""}
                 <div>
                 {(show && type == 'toggle_status') &&
                    <ToggleStatusPromocode listingObj={promocodeDetails}  getActiveList={getActiveList}/>}
                {(show && type == 'edit') &&
                    <EditPromocode  eventObj={promocodeDetails} types={types} getActiveList={getActiveList}/>}
            </div>
        </>
        
        
    );
}
function ActionButton() {
    const dispatch = useDispatch()
    return (
      <div style={{"text-align": "right"}}>
        <button onClick={() => dispatch(showAddEventFormFunc({ modal: true }))} className='btn btn-sm btn-outline-primary'>
          <i style={{ fontSize: '1rem' }} className="mdi mdi-plus-circle me-1"></i>
          Add PromoCode
        </button>
      </div>
    )
  }
  