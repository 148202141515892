import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery' // Import jQuery
import 'maphilight'
import parse from 'html-react-parser'

export default function StadiumMap({ stadiumObj, onClick }) {
  const imgRef = useRef()
  const [areas, setAreas] = useState([])
  const [cardContainer, setCardContainer] = useState('')
  const [svgContainer, setSvgContainer] = useState(null)
  const [subtraction] = useState(40)

  useEffect(() => {
    // Attach click events to all areas
    if (areas) {
      areas.forEach((area) => {
        area.addEventListener('click', (event) => handleAreaClick(event, area))
      })
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (areas) {
        areas.forEach((area) => {
          area.removeEventListener('click', (event) =>
            handleAreaClick(event, area)
          )
        })
      }
    }
  }, [areas])

  const initializeMaphilight = () => {
    $('.maparea').maphilight()
  }

  useEffect(() => {
    if (cardContainer === '') {
      setCardContainer(document.getElementById('stadium-map-card'))
    }

    if (cardContainer) {
      setSvgContainer(document.getElementById('stadium-svg'))
    }

    if (areas.length === 0) {
      setAreas(document.querySelectorAll('area'))
    }

    // Attach click events to all areas
    if (svgContainer) {
      svgContainer.addEventListener('load', (event) => {
        // setImgHeight(svgContainer.clientHeight)
        resizeImageMap()
        initializeMaphilight()
      })
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (svgContainer) {
        svgContainer.removeEventListener('load', (event) => {
          resizeImageMap()
        })
        $('.maparea').data('maphilight', false)
      }
    }
  }, [svgContainer, cardContainer])

  function resizeImageMap() {
    // Get the current SVG dimensions
    const newWidth = svgContainer.clientWidth
    const newHeight = svgContainer.clientHeight

    // Calculate the scaling factor
    const scaleX = newWidth / stadiumObj.svgWidth
    const scaleY = newHeight / stadiumObj.svgHeight

    // Update the coordinates of each <area> based on the scaling factor
    for (let i = 0; i < areas.length; i++) {
      const coords = areas[i].getAttribute('coords').split(',')

      // Apply the scaling factor to each coordinate pair
      const newCoords = coords.map((coord, index) => {
        return index % 2 === 0
          ? Math.round(coord * scaleX)
          : Math.round(coord * scaleY)
      })

      // Update the coordinates attribute with the new coordinates
      areas[i].setAttribute('coords', newCoords.join(','))
      // areas[i].setAttribute("id", "democlassName");
    }
    // setAreas(areas)
  }

  const handleAreaClick = (event, area) => {
    // Prevent the default behavior (e.g., scrolling)
    event.preventDefault()
    // Get the title attribute of the clicked area
    const areaTitle = area.getAttribute('title')

    // Copy the areaTitle to clipboard
    copyToClipboard(areaTitle.toUpperCase())
  }

  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('Text copied to clipboard: ' + text)
      })
      .catch((err) => {
        console.error('Unable to copy text: ', err)
      })
  }

  return (
    <div className="card mb-4">
      <div id="stadium-map-card" className="card-body px-2 px-sm-3">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="card-title text-uppercase fw-bold">
            {stadiumObj.name} Map
          </h6>
          <button type="button" className="btn btn-sm" onClick={onClick}>
            <i style={{ fontSize: '1rem' }} className="mdi mdi-close"></i>
          </button>
        </div>
        {cardContainer !== '' && cardContainer && (
          <>
            <img
              width={cardContainer.clientWidth - subtraction}
              // width={1176}
              crossOrigin="anonymous"
              id="stadium-svg"
              ref={imgRef}
              src={stadiumObj?.svg}
              alt="stadium map"
              useMap="#image-map"
              className="maparea"
            />
          </>
        )}
        {parse(stadiumObj.hoverCode)}
        {/* </> */}
        {/* } */}
      </div>
    </div>
  )
}
