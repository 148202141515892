import React from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  onUpdate,
  setAdminErrorMessageUser,
  setAdminLoadingUser,
  showListingModalFunc,
  showPayoutModalFunc,
} from '../../../../config/redux/slice/adminSlice'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'

export default function InitiatePayout({ payoutObj }) {
  const dispatch = useDispatch()

  const { adminLoading, adminErrorMsg, adminSuccessMsg } = useSelector(
    (state) => state.admin
  )

  const { token } = useSelector((state) => state.authData)

  const {
    payoutManagement: { payoutEventId },
  } = useSelector((state) => state.admin)

  const onSubmit = async () => {
    dispatch(clearAll({}))
    const res = await post_put_patch_request(
      'post',
      token,
      `${app_api.initiate_payout(payoutEventId)}`,
      {},
      dispatch,
      setAdminLoadingUser,
      '',
      setAdminErrorMessageUser
    )
    if (res !== undefined && res.status < 400) {
      dispatch(onUpdate({ message: res.data.message, type: 'payout' }))
    }
  }

  const closeModal = () => {
    dispatch(showPayoutModalFunc({ modal: false, type: null }))
  }

  return (
    <ModalLayout onClick={closeModal} heading={'Initiate Payout'}>
      <div className="modal-body">
        <p>Are you sure you want to initiate Payout for this event?</p>
      </div>
      <div className="modal-footer border-top-0 d-flex align-items-center">
        <div>
          {adminLoading ? (
            <Spinner isForm={true} />
          ) : (
            <button
              onClick={onSubmit}
              type="button"
              className="btn btn-danger me-3"
            >
              Payout
            </button>
          )}
        </div>
        <button
          disabled={adminLoading ? true : false}
          type="button"
          onClick={closeModal}
          className="btn btn-info"
        >
          Cancel
        </button>
      </div>
    </ModalLayout>
  )
}
