import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearAll, clearResetBot, setCustomerErrorMessage, setDetails, showListingModalFunc } from '../../../../config/redux/slice/customerSlice'
import { listingsCustomerDatatableExpand } from '../../../../helpers/customer_dataTables'
import { app_api } from '../../../../utils/endpoints/app_api'
import { app_urls } from '../../../../utils/urls/app_urls'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import DeleteListing from './DeleteListing'
import EditListing from './EditListing'
import DuplicateListing from './DuplicateListing'

export default function ListingExpandTable() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {listingId} = useNavigate()

  const {
        token
    } = useSelector((state) => state.authData)

    const {
      listingManagement:{newData,listingDetails, showListingModal: {show, type}}
      
    } = useSelector((state) => state.customer)

    // const {
    //     listingManagement: {newData,listingDetails,show, type}
    //   } = useSelector((state) => state.customer)

    console.log("listingDetailslatest",listingDetails);

    useEffect(() => {
        dispatch(clearAll({}));
        //if (listingDetails.eventId) {
          //const apiUrl = `${app_api.get_auth_user_listings_expand(listingDetails.eventId)}`;
          listingsCustomerDatatableExpand(
            listingDetails.listings,
            //errorFunc,
            'listingsCustomerTableExpand',
            //apiUrl,
            //`${app_api.search_listing}`,
            //token,
            setItem
          );
       // }
      }, []);

      useEffect(()=>{
        // if(newData){
          listingsCustomerDatatableExpand(
            listingDetails.listings,
          'listingsCustomerTableExpand',
          setItem
          )
          // dispatch(clearResetBot())
        // }   
    
      }, [listingDetails.listings])


    const setItem = (data, type) => {
      dispatch(setDetails({details: data, type: 'listing-items'}))
      
      if(type == 'details'){
        navigate(app_urls.user_listings_details)
      }else if(type == 'view-tickets'){
        navigate(app_urls.user_tickets_dyn(data.id))
      }else if(type == 'expand'){
      navigate(app_urls.listing_event_dyn)
    }
      else if(type == 'delete' || type == 'edit' || type == 'duplicate'){
        dispatch(showListingModalFunc({modal: true, type}))
      }
      
      
    }

    const errorFunc = (msg) => {
      dispatch(setCustomerErrorMessage({message: msg}))
    }
  return (
    <CustomerWrapper heading={'Listings'}>

        <div className='mb-3'>
        <button
        onClick={()=>{navigate(app_urls.user_listings)}}
          style={{color: 'rgba(0,0,0,0.9)'}}
         className='btn btn-sm bg-accent fw-bold'
        >
          <i style={{fontSize: '1rem'}} className="mdi mdi-arrow-left me-1"></i>
          Go back to listings
        </button>
      </div>

      {(show && type == 'delete') &&
      <DeleteListing/>
      }
      {(show && type == 'duplicate') &&
      <DuplicateListing/>
      }
       {(show && type == 'edit') &&
      <EditListing/>
      }
       <DataTableWrapper
      table_id='listingsCustomerTableExpand' 
    >
        <thead>
            <tr>
                <th className="wd-5p">Listing Id</th>
                <th className="wd-10p">Section & Block</th>
               
                <th className="wd-10p">No of Tickets</th>
                <th className="wd-10p">Price/Ticket</th>
                {/* <th className="wd-10p">Reserved Price/Ticket</th> */}
                <th className="wd-10p">Ticket Type</th>
                {/* <th>Status</th> */}
              
                {/* <th className="wd-10p">Block</th> */}
                {/* <th className="wd-10p">Listed On</th> */}
                <th className="wd-15p">Actions</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </DataTableWrapper>
    </CustomerWrapper>
  )
}
