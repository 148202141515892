import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setAdminErrorMessageUser, setAdminLoadingUser, showPromocodeModalFunc, setAdminPromoCodeErrorMessageUser } from '../../../../config/redux/slice/adminSlice'
import { convertToDateTime, truncateMiddle } from '../../../../helpers/helpers'
import { post_put_request_promoCode } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import SelectComponent from '../../../components/Form/SelectComponent'
import Spinner from '../../../components/Spinner'
import ModalLayout from '../../../layout/ModalLayout'


export default function EditEvent({ eventObj, types, getActiveList }) {
  const dispatch = useDispatch()
  const { register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: { errors }, reset } = useForm({ mode: 'onTouched' })
  const [formData, setFormData] = useState(new FormData());
  const [startDate, setStartDate] = useState("");
  const {
    adminLoading,
    adminErrorMsg,
    adminSuccessMsg
  } = useSelector((state) => state.admin)

  const {
    token
  } = useSelector((state) => state.authData)

  useEffect(() => {

  }, [])
  const onSubmit = async (data) => {
    console.log('formData1111', data);
    dispatch(clearAll({}))

    const convertToDateTime = (date, time = "00:00") => {
      const isoString = new Date(`${date}T${time}`).toISOString();
      return isoString;
    };
    dispatch(clearAll({}));
    let dateTime = null;
    let endDateTime = null;
    if (data?.startDate) {
      dateTime = convertToDateTime(data.startDate, "00:00");
    }
    if (data?.endDate) {
      endDateTime = convertToDateTime(data.endDate, "23:59");
    }
    data = {
      ...data,
      promoCodeTypeId: String(data.promoCodeTypeId),
      Description: data.Description || "",
      startDate: dateTime,
      endDate: endDateTime,
      percentage: parseFloat(data.percentage),
      isActive: true,
      userApplied: parseInt(0),

    };
    // for (let key in data) {
    //   if (typeof data[key] == "object") {
    //     if (data[key].length != 0) {
    //       formData.append(key, data[key][0])
    //     }
    //   } else {
    //     formData.append(key, data[key])
    //   }
    // }
    console.log('Formatted formData:', data);
    const res = await post_put_request_promoCode(
      "patch",
      token,
      `${app_api.update_promocode(eventObj?.id)}`,
      data,
      dispatch,
      setAdminLoadingUser,
      "",
      setAdminErrorMessageUser,
      true
    )
    if (res !== undefined && res.status < 400) {
      getActiveList()
      dispatch(onUpdate({ message: res.data.message, type: 'promocode' }))
      setFormData(new FormData())
    } else {
      closeModal()
      setFormData(new FormData())

    }
  }

  const closeModal = () => {
    dispatch(showPromocodeModalFunc({ modal: false, type: null }))
  }

  return (
    <ModalLayout classStyles={'modal-lg'} onClick={closeModal} heading={'Edit PromoCode'}>
      <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data' noValidate>
        <div className="modal-body">
          <div className="px-4 px-md-7">
            <div style={{ rowGap: '1.2rem' }} className="row mb-2">

              <div className='col-md-6 col-lg-4'>
                <div className="form-group">
                  <label htmlFor='promoCodeTypeId'>PromoCode Type <span className="text-danger">*</span></label>
                  <select
                    className="form-control"
                    id="promoCodeTypeId"
                    {...register("promoCodeTypeId", {
                      required: {
                        value: true,
                        message: '*this field is required'
                      }
                    })
                    }
                    defaultValue={eventObj.promoCodeTypeId}
                  >
                    <option value={''}>Select type</option>
                    {types.map((type, indx) => {
                      return <React.Fragment key={indx}>
                        <option className='text-capitalize' key={indx} value={type.id}>{type.name}</option>
                      </React.Fragment>
                    })}
                  </select>
                  {
                    errors?.promoCodeTypeId?.message &&
                    <div className="text-danger small mt-1">
                      {errors?.promoCodeTypeId?.message}
                    </div>
                  }
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='code'>Code <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    defaultValue={eventObj?.code}
                    className="form-control input-lg"
                    id="code"
                    {...register("code", {
                      required: {
                        value: true,
                        message: '*this field is required'
                      }
                    })
                    }
                  />
                  {
                    errors?.code?.message &&
                    <div className="text-danger small mt-1">
                      {errors?.code?.message}
                    </div>
                  }
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='percentage'>Percentage <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    defaultValue={eventObj?.percentage}
                    className="form-control input-lg"
                    id="percentage"
                    {...register("percentage", {
                      required: {
                        value: true,
                        message: '*this field is required'
                      },
                      validate: {
                        isPositive: (value) =>
                          parseFloat(value) > 0 || 'Percentage must be greater than 0',
                      },
                    })
                    }
                  />
                  {
                    errors?.percentage?.message &&
                    <div className="text-danger small mt-1">
                      {errors?.percentage?.message}
                    </div>
                  }
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='description'>Description</label>
                  <input
                    type="text"
                    defaultValue={eventObj?.Description}
                    className="form-control input-lg"
                    id="Description"
                    {...register("Description", {

                    })
                    }
                  />
                  {
                    errors?.description?.message &&
                    <div className="text-danger small mt-1">
                      {errors?.description?.message}
                    </div>
                  }
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='startdate'>Start Date </label>
                  <input
                    type="date"
                    defaultValue={moment(eventObj?.startDate).format('YYYY-MM-DD')}
                    min={moment().format('YYYY-MM-DD')}
                    style={{ cursor: "pointer" }}
                    onFocus={(e) => e.currentTarget.showPicker()}
                    className="form-control input-lg"
                    id="startDate"
                    {...register("startDate", {
                      onChange: (e) => setStartDate(e.target.value),
                    })
                    }
                  />
                  {
                    errors?.date?.message &&
                    <div className="text-danger small mt-1">
                      {errors?.date?.message}
                    </div>
                  }
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='enddate'>End Date</label>
                  <input
                    type="date"
                    defaultValue={moment(eventObj?.endDate).format('YYYY-MM-DD')}
                    min={startDate || moment().format("YYYY-MM-DD")}
                    style={{ cursor: "pointer" }}
                    className="form-control input-lg"
                    onFocus={(e) => e.currentTarget.showPicker()}
                    id="endDate"
                    {...register("endDate", {

                    })
                    }
                  />
                  {
                    errors?.date?.message &&
                    <div className="text-danger small mt-1">
                      {errors?.date?.message}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer border-top-0 d-flex align-items-center">
          <div>
            {adminLoading ?
              <Spinner isForm={true} /> :
              <button type="submit" className="btn btn-primary me-3">Submit Form</button>
            }
          </div>
          <button disabled={adminLoading ? true : false} onClick={closeModal} type="button" className="btn btn-info">Cancel</button>
        </div>
      </form>
    </ModalLayout>
  )
}
