import React, { useEffect } from 'react'
import GeneralWrapper from '../../../layout/GeneralWrapper'

export default function TermsAndCondition() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <GeneralWrapper>
        <div className='px-3 px-lg-100 px-xl-200 mt-3 mb-3'>
  <div className="WordSection1">
    <h1 style={{ marginLeft: "-.25pt", textIndent: "0in" }}>
    Global User Agreement
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      Prime Events Limited (Trading as PrimeTic) provides an online platform and related services (“<b>Services</b>”) that connects sellers and buyers seeking to respectively sell and buy tickets, related passes and merchandise or other related goods and/or services for events (“<b>Tickets</b>”) and we may make such Services available internationally via one or more of our websites, our mobile applications and/or associated services (collectively, the “<b>Site</b>”). By accessing or using our Site, you agree to be bound by this PrimeTic Marketplace Global User Agreement (“<b>User Agreement</b>”). Our Payment Services Agreement (applicable if you sell tickets via our Site to an event located within Europe), User Privacy Notice, Mobile Privacy Notice, Seller Policies, Cookie Notice, FanProtect Guarantee, (collectively, the “<b>Additional Policies</b>”) are incorporated by reference into this User Agreement. We may periodically make changes to this User Agreement and shall notify you by posting a revised version on our Site. The revised User Agreement will become effective upon publication and your continued use of our Site and Services will constitute acceptance of the revised User Agreement.

    </p>
    
   
  
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.5pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "23.25pt", textIndent: "-24.0pt" }}>
      <span style={{ lineHeight: "103%" }}>
        2.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Contracting Entity
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      2.1 If you purchase or sell on our Site Tickets anywhere in the world, you are contracting with Prime Events Limited., a company registered in the Ireland ; Reg no 753108
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: ".05in",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      Accordingly, if you sell on our Site Tickets, you are contracting Services subject to the terms of the Payment Services Agreement, in addition to contracting with Prime Events (Ireland) Limited
    </p>
   
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.5pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "23.25pt", textIndent: "-24.0pt" }}>
      <span style={{ lineHeight: "103%" }}>
        3.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Ticket Marketplace
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     3.1 PrimeTic is a ticket marketplace that allows registered users to buy (in this capacity, "<b>Buyer</b>") and sell (in this capacity, “<b>Seller</b>”) Tickets. The Seller (and not PrimeTic) sets the prices for the Tickets. As a marketplace, PrimeTic does not own the Tickets sold on the Site. Ticket prices may exceed the face value of the Ticket.
    </p>
    
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      3.2 While PrimeTic may provide pricing, listing and other guidance on the Site, such guidance is solely informational (without any warranty as to accuracy). Also, while we may help facilitate the resolution of disputes between Buyer and Seller and provide the FanProtect Guarantee, PrimeTic has no control over and does not guarantee the existence, quality, safety or legality of the Tickets; the truth or accuracy of the user’s content or listings; the ability of Sellers to sell tickets or Buyers to pay for them; or that a Buyer or Seller will complete a transaction.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.5pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "23.25pt", textIndent: "-24.0pt" }}>
      <span style={{ lineHeight: "103%" }}>
        4.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Your Account
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      4.1 In order to list, sell or purchase Tickets you must register an account ("<b>Account</b>") on PrimeTic portal. You must be at least 18 years old and must be able to enter legally binding contracts. If you are registering with PrimeTic for a business entity, you represent that you have the authority to legally bind that entity.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      4.2 When opening an Account, you must provide complete and accurate information and provide us with a valid credit card, debit card or PayPal account ("Payment Method"). If your registration or payment information changes at any time, you must promptly update your details in your Account. You acknowledge that PrimeTic may charge any one of your payment methods on file for amounts you owe us, costs we incur or other losses arising out of your violation of this Agreement (including the Additional Policies).

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     4.3 PrimeTic reserves the right to temporarily or permanently suspend Accounts with incomplete or inaccurate information.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      4.4 You are solely responsible for maintaining the confidentiality of your Account information and password. You are solely responsible for any activity relating to your Account. Your Account is not transferable to another party
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      4.5 In order to check the data in the system and public databases to detect suspicious behavior, PrimeTic  may ask you for additional identifying documentation. These documents must be original copies of your identity document and may not have been altered. This is in compliance with applicable privacy policies
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.5pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "23.25pt", textIndent: "-24.0pt" }}>
      <span style={{ lineHeight: "103%" }}>
        5.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Privacy and Communications
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      All communications between you and PrimeTic (including our service providers, or our affiliates) are subject to our User Privacy Notice
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.5pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "23.25pt", textIndent: "-24.0pt" }}>
      <span style={{ lineHeight: "103%" }}>
        6.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Fees and Other Charges
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     6.1 PrimeTic  may charge fees for selling and/or buying Tickets through our Site and/or otherwise using our Services, as well as delivery or fulfilment fees (collectively referred to as "Service Fees"). Service Fees may vary depending on event type, Ticket type and location. PrimeTic may in its sole and absolute discretion change its Service Fees at any time, including after you list your Tickets. Any applicable Service Fees including any taxes if applicable will be disclosed to you prior to listing or buying a Ticket. PrimeTic   may charge and/or retain Service Fees if you do not fulfil your contractual obligations under this User Agreement.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      6.2 If you as a Seller do not fulfil your contractual obligations under this User Agreement and the Seller Policies, PrimeTic may charge you certain fees and/or costs in accordance with the Seller Policies. 

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      6.3 If you as a Buyer change your postal address after you have purchased your Ticket(s) and require PrimeTic to deliver your Ticket(s) to this new postal address, or if you have specified an incorrect postal address, PrimeTic may charge you for any <b>additional delivery costs</b> which arise out of re-arranging the delivery of your Ticket(s) to the new or correct postal address. The additional delivery costs may be charged to your Payment Method
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     6.4 We may collect amounts owed using a collection agency or other collection mechanism and you may be charged fees associated with collecting the delinquent payments. Prime Events Limited, or the collection agency it retains, may also report information about your account to credit bureaus, and as a result, defaults in your account may be reflected in your credit report. If you wish to dispute the information PrimeTic reported to a credit bureau, please contact Prime Events. If you wish to dispute the information a collection agency reported, contact the collection agency directly.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.5pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "23.25pt", textIndent: "-24.0pt" }}>
      <span style={{ lineHeight: "103%" }}>
        7.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Selling Tickets
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      7.1 You must comply with all applicable laws and regulations and the terms of this User Agreement and the Seller Policies when listing, selling and delivering your Tickets.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      7.2 When listing a Ticket you must set a price for which you are willing to sell your Ticket ("<b>Sales Price</b>"). You may modify (e.g., raise or lower the Sales Price) or delete your listing at any time until the Tickets have been sold.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     7.3 As a seller you may also set a reserve price below the listing price which is known to only you "<b>Reserve Price</b>". The reserve price is the minimum price you are willing to accept for your tickets from buyers
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      7.4 PrimeTic does not guarantee that your tickets or related passes will sell or that your listing will appear on the Site within a certain time after it is posted or in a particular order on the event page or through search results. PrimeTic will not, for any reason, provide compensation for tickets that do not sell, even if it is due to Site unavailability from an outage or maintenance or listing delays.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      7.5 The Seller determines the selling price of each Ticket. However, in some countries maximum price restrictions may apply to the offer, depending on the location of the event. Therefore, PRIMETIC is not responsible for Seller’s compliance with any legal restrictions placed on the sale of the Ticket, whether in the Seller’s home city or country or any applicable Laws.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "1.3pt",
        marginLeft: "-.25pt"
      }}
    >
      7.6 Ticket delivery- You are responsible for selecting the correct ticket type as this will determine the delivery deadline. Not all delivery methods are available for all events and the available delivery methods can change over time depending on the remaining time left for the events. The delivery method determines the last available date to send tickets that are Not In Hand and the date and time the listing will expire (i.e., no longer be visible to buyers). This allows you adequate time to get tickets to buyers before the event.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "12.85pt",
        marginLeft: "-.25pt"
      }}
    >
      For all events, when you start listing your tickets on the 'Enter your ticket details' page, you'll select a delivery method based on the type of tickets you have - electronic tickets (including instant download), mobile tickets and paper printouts/ 'hard' tickets (such as tickets printed on card stock). Once your tickets sell, you can't change the delivery method and you must deliver the tickets using the delivery method you selected.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "1.3pt",
        marginLeft: "-.25pt"
      }}
    >
7.7 When you upload your electronic tickets (Instant Download), you have agreed to 'pre-deliver' your tickets for sale, instead of waiting to receive a buyer's delivery details. That way, within minutes of their sale the tickets are delivered to the buyer.This will enable your listing last up to 30 minutes before the event. If you choose to list your tickets using Instant Download, you have agreed to list them for sale exclusively on PrimeTic and must delete the listing immediately if you decide to use them

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "16.05pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      <span style={{ fontSize: "12.0pt", lineHeight: "107%", color: "black" }}>
        &nbsp;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.55pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "23.25pt", textIndent: "-24.0pt" }}>
      <span style={{ lineHeight: "103%" }}>
        8.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Taxes
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      You as a Seller are responsible for determining whether any taxes are due on your sale of a Ticket, and for collecting and remitting such taxes. Any applicable taxes must be included in the Sales Price of your Ticket. You agree to provide PrimeTic any tax identification number if necessary for PrimeTic to provide (without notice to You) information to the relevant tax authorities related to payments you receive from us and further authorize PrimeTic to release that information to the relevant tax authorities. The Service Fees include any applicable sales, use, excise, value added, service and other indirect taxes.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.5pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "23.25pt", textIndent: "-24.0pt" }}>
      <span style={{ lineHeight: "103%" }}>
        9.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Buying Tickets
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      9.1 You are responsible for reading the complete listing before making a commitment to buy Tickets. When placing an order, you are entering into a <b>binding contract</b> with the Seller to purchase those Tickets. Additional terms communicated to you by the Seller may apply. Payment is remitted to PrimeTic and disbursed to the seller according to our Payment Services Agreement for Sellers or the User Agreement. <b>All Orders are final. You cannot change or cancel any orders after the sale is confirmed.</b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     9.2 PrimeTic platform features a large volume of listed tickets and Prime Tic takes significant measures to prevent pricing errors. However, in the rare event of a pricing error, PrimeTic cannot be held liable for the error. In such cases, if a ticket is affected by a pricing error, PrimeTic reserves the right to offer the tickets to you at the corrected price. If you find the corrected price unacceptable, you have the option to cancel your order.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     9.3 PrimeTic reserves the right to change the delivery method, at its sole discretion. Tickets can be delivered up to the event start time indicated on the tickets. In the event that the delivery method undergoes a change prior to delivery, PrimeTic will not charge any additional delivery fees to the buyer. However, despite our commitment to delivering the tickets as soon as possible, there may be external factors beyond our control or the seller's control that could prevent orders from being delivered in a timely manner.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
    9.4 Upon placing your order, you (i) acknowledge that sales are subject to our final sale terms, including the refund, postponement and cancellation policies as stated throughout these terms; (ii) you acknowledge that PrimeTic does not offer “buyer’s remorse” refunds; and (iii) you authorize PrimeTic to charge your method of payment for the total amount, which includes the ticket price, service and delivery fees, and any applicable taxes.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      By accepting these conditions, you are acknowledging and agreeing not to engage in any actions aimed at evading, avoiding, or bypassing the limitations placed on your right to receive a full or partial refund as specified in these terms. Furthermore, you agree not to initiate a "chargeback" with your payment card issuer for valid charges, meaning that if our decision to deny a refund or credit for a ticket purchase is in accordance with the sale terms provided, or in any other case, you will refrain from seeking a chargeback directly from your payment card issuer
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      In the event that you dispute a charge with the card issuer and it is determined that the charge in question is valid and not the result of credit card or payment fraud, PrimeTic retains the following rights:
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      (i) The company has the right to pursue payment, along with any applicable fees, through legal means as necessary, which may include employing collection agencies and initiating legal proceedings.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "7.4pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      (ii) PrimeTic reserves the right to decline pending and future ticket purchases made using credit card accounts or online accounts associated with previous chargebacks. Moreover, the company may prohibit individuals named on the credit card accounts, those who access related online accounts or credit cards, or anyone who breaches this provision from making future purchases.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.5pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "35.25pt", textIndent: "-.5in" }}>
      <span style={{ lineHeight: "103%" }}>
        10.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Payments
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "3.05pt",
        marginLeft: "-.25pt"
      }}
    >
      10.1 Payments received from Buyers for Tickets purchased via the Site are processed by PrimeTic on behalf of the Seller and credited to the Seller in accordance with the Seller Policies and Payment Services Agreement (as applicable). Without prejudice or limitation to the Payment Services Agreement, Seller appoints the relevant PRIMETIC entity as its limited payment collection agent solely for the purpose of accepting funds from Buyers on behalf of Seller. Seller agrees that any payment made by Buyer to PrimeTic shall be considered the same as a payment made directly to Seller, and Seller will make the Tickets available to Buyer in the agreed-upon manner as if Seller had received the payment directly from Buyer. Except as otherwise specified in the FanProtect Guarantee, Seller understands that StubHub’s International obligation to pay Seller is subject to and conditional upon successful receipt of the associated payments from Buyer. In accepting appointment as the limited payment collection agent of the seller, PrimeTic assumes no liability for any acts or omissions of the Seller.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      10.2 Payments from Buyers are due and payable immediately. A Buyer can pay the full order amount which includes any applicable Service Fees and taxes using one of the accepted payment methods.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      10.3 A Buyer’s obligation to pay for a Ticket is satisfied when PrimeTic has received the Payment in full.

    </p>
    
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.55pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: "-.25pt"
      }}
    >
      <span style={{ lineHeight: "103%" }}>
        11.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Refund Policy and Event Changes
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      11.1 <b>Cancellation:</b> If an event is cancelled and not rescheduled, we will remove the event and any listings related to the event from our Site and inform both Buyer and Seller about the cancellation with further instructions. If the Seller requires his Ticket(s) back to obtain a refund of the original purchase price from the promoter or organizer, he must contact Customer Service immediately after the cancellation. The Buyer is required to retain the Ticket(s) and upon request from PrimeTic to send them back to either the Seller or to PrimeTic. The Buyer will receive a full refund or credit for use on a future purchase, as determined by PrimeTic’s sole discretion (unless a refund is required by law) once he has sent back the Ticket(s) (if applicable) and the Seller will not be paid. If the Seller has already been paid, the payment for the sale may be recovered by charging the Seller's Payment Method or by setting off this amount against pending payments for other Tickets the Seller has sold or will sell in the future
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     11.2 <b>Postponement:</b> If an event is postponed, PrimeTic will work with Buyers and Sellers on a case-by-case basis attempting to resolve any Ticket issues. Sellers are prohibited from reselling, invalidating or changing Tickets for postponed events. Refunds will not be issued for postponed events, unless they are ultimately cancelled as stated above.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      11.3 <b>Other event changes:</b> PrimeTic is not responsible for partial performances, venue, line-up or time changes. No refunds will be issued in these instances.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.55pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "35.25pt", textIndent: "-.5in" }}>
      <span style={{ lineHeight: "103%" }}>
        12.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Term and Termination
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      12.1 This User Agreement shall commence on the date that you register an Account with PrimeTic and will continue for an indefinite period unless terminated in accordance with these provisions.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      12.2 You may terminate this User Agreement at any time by contacting Customer Service. In the event that you request that we close your Account, we will treat your request as termination of this User Agreement. Without limiting our rights according to Clause 19, we may terminate this User Agreement at any time by giving at least thirty (30) days’ notice via email to your registered email address. We will then close your Account.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      12.3 If you terminate this User Agreement as a Seller you are also terminating the Payment Services Agreement (to the extent applicable) as of the time of the termination of the User Agreement taking effect.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      12.4 Termination of this User Agreement shall not affect the rights or liabilities of either party accrued prior to and including the date of termination or expiry and/or any terms intended expressly or by implication to survive termination or expiry.

    </p>
    
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.5pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "35.25pt", textIndent: "-.5in" }}>
      <span style={{ lineHeight: "103%" }}>
        13.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Site Changes and Availability &nbsp;
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      PrimeTic reserves the right at any time to modify or discontinue, temporarily or permanently, the Site, the Services or any part of the Site or Services with or without notice for any reason. PrimeTic performs regularly-scheduled maintenance. While we do our best to avoid customer impact, the Site or our Services may be temporarily unavailable during maintenance periods.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.5pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "35.25pt", textIndent: "-.5in" }}>
      <span style={{ lineHeight: "103%" }}>
        14.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Other Terms
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "12.25pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      When using our Site and Services, you agree that you will not do any of the following: &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "1.6pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      contact or invite contact with other PrimeTic users for any reason other than the purpose for which you received the PrimeTic user’s contact information or solicit sales outside of PrimeTic 
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "12.4pt",
        marginLeft: "51.5pt",
        lineHeight: "107%"
      }}
    >
      ;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "8.0pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      use the Buyer's personal data for any reason other than the delivery of Tickets unless otherwise agreed to by the Buyer;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "11.45pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in",
        lineHeight: "107%"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "107%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      behave in an abusive manner to any PrimeTic employee or other user;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "7.95pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      behave in an abusive manner to any PrimeTic employee or other user;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "8.1pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      violate any venue or event promoter rules at events or violate any applicable third party terms of service (for instance, when using our mobile applications);
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "8.1pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "107%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      breach or circumvent any laws (including, where Tickets are sold internationally, the laws of the destination country), third party rights or our Additional Policies;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "11.45pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in",
        lineHeight: "107%"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "107%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      post false, inaccurate, misleading, defamatory or libelous content;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "11.3pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in",
        lineHeight: "107%"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "107%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      use PrimeTic's trademarks without our prior written permission;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "8.1pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      copy, reproduce, reverse engineer, modify, create derivative works from, distribute or publicly display any content (except for your information) or software from our Site or Services without the prior express written permission of PrimeTic and the appropriate third party, as applicable;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "7.95pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      use any robot, spider, scraper or other automated means to access our Site or Services for any purpose without our express written permission;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      take any action that imposes or may impose (to be determined in our sole discretion) an unreasonable or disproportionately large load on our infrastructure;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "7.95pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      interfere or attempt to interfere with the proper working of our Site or Services or any activities conducted on or with our Site or Services;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "8.1pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      bypass our robot exclusion headers, robots.txt rules or any other measures we may use to prevent or restrict access to our Site or Services;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "7.95pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      commercialize any PrimeTic application or any information or software associated with such application;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "8.1pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      export or re-export any PrimeTic application or tool except in compliance with the export control laws of any relevant jurisdiction and in accordance with posted rules and restrictions; or

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "51.0pt",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      do anything else that PrimeTic determines, in its sole reasonable discretion, misuses the Site or Services or otherwise negatively impacts our marketplace.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     In our effort to protect our Buyers and Sellers we may screen ticket sales or listings for signs of fraud using algorithms or automated systems, which may result in automatic cancellation of listings or sales and temporary or permanent suspension of accounts..
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.5pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "35.25pt", textIndent: "-.5in" }}>
      <span style={{ lineHeight: "103%" }}>
        15.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Mobile Device Terms
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "5.6pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     If you are accessing the Site or Services through a mobile application <b>(“App”)</b>, the following additional terms apply
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      15.1 App Use. PrimeTic grants you the right to use the App only for your personal use. You must comply with all applicable laws and third party terms of agreement when using the App (e.g. your wireless data service agreement). The App may not contain the same functionality available on the applicable PrimeTic website.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      15.2 Intellectual Property – Apps. PrimeTic owns, or is the licensee to, all right, title, and interest in and to its Apps, including all rights under patent, copyright, trade secret, trademark, or unfair competition law, and any and all other proprietary rights, including all applications, renewals, extensions, and restorations thereof. You will not modify, adapt, translate, prepare derivative works from, decompile, reverse-engineer, disassemble, or otherwise attempt to derive source code from any App and you will not remove, obscure, or alter PRIMETIC's copyright notice, trademarks or other proprietary rights notices affixed to, contained within, or accessed in conjunction with or by the App.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      15.3 Prohibited Countries Policy and Foreign Trade Regulation - Applications. The App or its underlying technology may not be downloaded to or exported or re-exported: (a) into (or to a resident or national of) Burma (Myanmar), Cuba, Iraq, Iran, Libya, North Korea, Sudan, Syria, or any other country subject to United States embargo; (b) to anyone on the US Treasury Department's list of Specially Designated Nationals or on the US Commerce Department's Denied Party or Entity List; and (c) to any prohibited country, person, end-user, or entity specified by US Export Laws. When using the App, you are responsible for complying with trade regulations and both foreign and domestic laws (e.g., you are not located in a country that is subject to a US Government embargo, or that has been designated by the US Government as a "terrorist supporting" country, and you are not listed on any US Government list of prohibited or restricted parties).

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".05in",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      15.4 Device Specific Additional Terms
    </p>
  
    <h2 style={{ marginLeft: "-.25pt" }}>iOS – Apple</h2>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      These Mobile Device Terms are an agreement between you and Prime Events Limited, and not with Apple. Apple is not responsible for the App and the content thereof.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      PrimeTic grants you the right to use the App only on an iOS product that you own or control and as permitted by the App Store Terms of Service.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     Apple is not responsible for the investigation, defense, settlement, and discharge of any third-party intellectual property infringement claim.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      Apple is not responsible for addressing any claims by you or any third party relating to the App or your possession and/or use of the App, including but not limited to: (a) product liability claims; (b) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (c) claims arising under consumer protection or similar legislation.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      In the event of any failure of the App to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price, if applicable, for the App to you; and to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     Apple and Apple's subsidiaries are third party beneficiaries of these Mobile Device Terms, and, upon your acceptance, Apple as a third-party beneficiary thereof will have the right (and will be deemed to have accepted the right) to enforce these Mobile Device Terms against you.

    </p>
   

    <h2 style={{ marginLeft: "-.25pt" }}>Android – Google</h2>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      These Mobile Device Terms are an agreement between you and PrimeTic , and not with Google. Google is not responsible for the App and the content thereof.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      PrimeTic grants you the right to use the App only on an Android product that you own or control and as permitted by the Google Play Terms of Service.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      Google has no obligation whatsoever to furnish any maintenance and support services with respect to the App.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     Google is not responsible for the investigation, defense, settlement, and discharge of any third-party intellectual property infringement claim.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      Google is not responsible for addressing any claims by you or any third party relating to the App or your possession and/or use of the App, including but not limited to: (a) product liability claims; (b) any claim that the Application fails to conform to any applicable legal or regulatory requirement; and (c) claims arising under consumer protection or similar legislation.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      In the event of any failure of the App to conform to any applicable warranty, you may notify Google, and Google will refund the purchase price, if applicable, for the App to you; and to the maximum extent permitted by applicable law, Google will have no other warranty obligation whatsoever with respect to the App.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "13.15pt",
        marginLeft: "-.25pt"
      }}
    >
      Google and Google's subsidiaries are third party beneficiaries of these Mobile Device Terms, and, upon your acceptance, Google as a third-party beneficiary thereof will have the right (and will be deemed to have accepted the right) to enforce these Mobile Device Terms against you.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "24.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      <span style={{ fontSize: "12.0pt", lineHeight: "107%", color: "black" }}>
        &nbsp;
      </span>
    </p>
    <h1 style={{ marginLeft: "35.25pt", textIndent: "-.5in" }}>
      <span style={{ lineHeight: "103%" }}>
        16.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Content
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: ".05in",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      You own and are solely responsible for any content including images, text, audio or other materials that you submit (“<b>User Content</b>”). By submitting User Content, you grant us a non-exclusive, worldwide, perpetual (or for the duration of any copyright or other rights in such content), irrevocable, royalty-free, transferable, sub-licensable (through multiple tiers) right to use, reproduce, distribute, create derivative works from, publicly perform, display, store, or publish in any way, in any media known now or in the future. You grant PrimeTic the right to use your name or image in association with your User Content, if we so choose. Further, to the fullest extent permitted under applicable law, you waive your moral rights in the content and promise not to assert such rights against us, our sub-licensees or assignees.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      16.2 You represent and warrant that the User Content: (a) is owned by you or you control all necessary rights to it; (b) does not violate, misappropriate or infringe on the rights of any third party including intellectual property rights, privacy rights or publicity rights; (c) is accurate, not obscene, false, libelous or defamatory; and (d) will not cause injury to any person, entity or system. You will indemnify PrimeTicfor any and all claims resulting from your User Content. PrimeTic has the right but not the obligation to monitor, edit or remove your User Content. The User Content is non-confidential and PrimeTic will not be liable for its use or disclosure.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      16.3 We may offer catalogues of stock images, descriptions and product specifications, which are provided by third-parties (including PrimeTic users). You may use catalogue content solely in connection with your PrimeTic listings during the time your listings are on our Site. While we try to offer reliable data, we cannot promise that the catalogues will always be accurate and up-to-date, and you agree that you will not hold our catalogue providers or us responsible for inaccuracies in their catalogues. The catalogue may include copyrighted, trademarked or other proprietary materials. You agree not to remove any copyright, proprietary or identification markings included within the catalogues or create any derivative works based on catalogue content (other than by including them in your listings).
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      16.4 If you submit ideas, suggestions, documents, and/or proposals (“Ideas”) to PrimeTic , you do so voluntarily and with no expectation of confidentiality. By submitting Ideas, you grant us ownership of the Ideas to use for any purpose, in any way, in any media now known or in the future and without any obligation or payment. You understand that PrimeTic may have something similar to the Ideas under consideration or in development stage.
    </p>
    <p
      className="MsoNormal"
      style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "35.25pt", textIndent: "-.5in" }}>
      <span style={{ lineHeight: "103%" }}>
        17.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Protecting Intellectual Property Rights
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "4.8pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      PrimeTic places high value on intellectual property rights and promptly addresses notices of alleged infringement
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      If you come across material on our Site or Services that you genuinely believe violates your copyright or other intellectual property rights, please get in touch with our Customer Service team and we will conduct a thorough investigation
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "18.9pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      You may submit a Notice of Claimed Infringement to PRIMETIC, providing the following information:

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: ".5in",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      The physical or electronic signature of a person authorized to act on behalf of the owner of the exclusive right allegedly infringed.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: ".5in",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Identification or description of the copyrighted work or other intellectual property you claim has been infringed. If it pertains to an intellectual property right other than copyright (e.g., trademark or patent), please specify.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "4.8pt",
        marginLeft: ".5in",
        textIndent: "-.25in",
        lineHeight: "107%"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Identification or description of the location of the material you assert is infringing on PrimeTic . Provide sufficient detail for us to locate it on the Site, including, wherever possible, the URL.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: ".5in",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      A concise description of how the contested content infringes on the owner’s intellectual property rights.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: ".5in",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Your address, telephone number, and email address
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "4.95pt",
        marginLeft: ".5in",
        textIndent: "-.25in",
        lineHeight: "107%"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      A statement by you affirming that you hold a good faith belief that the use of the material in question, as complained of, is not authorized by the copyright or intellectual property owner, its agent, or the law.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: ".5in",
        textIndent: "-.25in"
      }}
    >
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "142%",
          fontFamily: '"Arial",sans-serif'
        }}
      >
        •
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      A statement, made under penalty of perjury, confirming the accuracy of the information in the notification and asserting that you are either the copyright or intellectual property owner or are authorized to act on the owner’s behalf.

    </p>

    <h1 style={{ marginLeft: "35.25pt", textIndent: "-.5in" }}>
      <span style={{ lineHeight: "103%" }}>
        18.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Violating the Agreement
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "4.8pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      18.1 We may investigate any potential or suspected violations of the User Agreement, Additional Policies, security protocols or best practices, third-party rights or applicable law, any actions or any other misconduct or potential abuse on or through the Services or attempts. When assessing whether and which measure(s) to take against a user, we will take the legitimate interests of the user into account and shall in particular consider if the user is responsible for the misconduct.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      18.2 We may take any actions we deem appropriate in our reasonable discretion for the conduct described in 18.1. Without limiting other remedies, these actions may include: limit, or temporarily or permanently suspend, or terminate our services and Accounts, restrict or prohibit access to, and your activities on, our Site and/or Services, remove listings, require you to edit listings, cancel sales, require you to send Tickets to a Buyer within a specified time, delay or remove hosted content, remove any special status associated with an Account, reduce or eliminate any discounts, withhold payment, charge the Payment Method on file for amounts you owe or costs we incur due to your misconduct (including, without limitation, any costs associated with collection of delinquent Accounts or chargebacks and any replacement costs), and take technical and legal steps to keep you from using our Site and/or Services.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "7.25pt",
        marginLeft: "-.25pt"
      }}
    >
      18.3 We reserve the right to report any activity that we believe to be illegal or otherwise in contravention of this User Agreement and we will respond to any verified requests relating to a criminal investigation (i.e. a subpoena, court order or substantially similar legal procedure) from local and foreign law enforcement or regulatory agencies, other government officials or authorized third-parties.

    </p>
    <h1
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: "-.25pt"
      }}
    >
      <span style={{ lineHeight: "103%" }}>
        19.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Disclaimer of Warranties; Limitation of Liability
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "4.8pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      19.1 YOU UNDERSTAND AND AGREE THAT YOU ARE MAKING USE OF THE SITE AND SERVICES ON AN ‘AS IS’ AND ‘AS AVAILABLE’ BASIS. PRIMETICMAKES NO WARRANTY WITH RESPECT TO ITS SOFTWARE, SERVICES, SITE, ANY TICKETS, ANY EVENT, ANY USER CONTENT, OR THAT SELLERS OR BUYERS WILL PERFORM AS PROMISED. ACCORDINGLY, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT AS SPECIFICALLY PROVIDED IN THIS USER AGREEMENT AND IN OUR FANPROTECT GUARANTEE, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT. 

    </p>
   
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      19.2 IN ADDITION, TO THE MAXIMUM EXTENT PERMITTED BY LAW, PRIMETIC (INCLUSIVE OF ANY OF ITS SERVICE PROVIDERS AND LICENSORS) IS NOT LIABLE FOR: (A) ANY SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES; (B) LOSS OF: PROFITS, GOODWILL OR REPUTATION, OR OTHER INTANGIBLE LOSSES; (C) DAMAGES RELATING TO: (I) YOUR ACCESS TO, USE OF, OR INABILITY TO ACCESS OR USE THE SITE OR SERVICES; (II) VIRUSES OR OTHER MALICIOUS SOFTWARE OBTAINED BY ACCESSING OUR SITE OR SERVICES OR THOSE OF ANY SITE, SERVICES, OR TOOLS LINKED TO OUR SITE OR SERVICES; (III) ANY USER CONTENT OR CONTENT OF THIRD PARTIES, OR (IV) THE DURATION OR MANNER IN WHICH TICKETS YOU LIST APPEAR ON THE SITE OR SERVICES.

    </p>
    
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     19.3 SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF WARRANTIES OR EXCLUSION OF DAMAGES, SO SUCH DISCLAIMERS AND EXCLUSIONS MAY NOT APPLY TO YOU AND YOU MAY HAVE RIGHTS IN ADDITION TO THOSE CONTAINED IN THIS USER AGREEMENT. THIS IS ESPECIALLY SO IF YOU ARE A CONSUMER.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
19.4 REGARDLESS OF THE PREVIOUS CLAUSES, OUR LIABILITY TO YOU OR TO ANY THIRD PARTY IS LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES IN DISPUTE NOT TO EXCEED THE TOTAL FEES WHICH YOU PAID TO US IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO THE LIABILITY, OR (B) €200.
</p>
    
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "35.25pt", textIndent: "-.5in" }}>
      <span style={{ lineHeight: "103%" }}>
        20.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Applicable Laws and Jurisdiction
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "4.8pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      20.1 Any claim, dispute or matter arising under or in connection with this User Agreement shall be governed and construed 
      <b>
      by the consumer protection provisions of the law of the member state in which you reside
      </b>
      .
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     20.2 If you are a consumer residing in the European Union, please note that we do not (and are not legally obliged to) participate in any alternative dispute resolution (<b>ADR</b>) procedures or services, unless otherwise (i) specified in the country-specific additional provisions below, and/or (ii) provided by law.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      20.3 Any claims or disputes arising under or relating to these Terms and Conditions shall be settled through amicable negotiations between the parties involved. In the event that a dispute cannot be resolved through such negotiations, it shall be subject to the exclusive jurisdiction of Irish law.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "11.5pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <h1 style={{ marginLeft: "35.25pt", textIndent: "-.5in" }}>
      <span style={{ lineHeight: "103%" }}>
        21.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      General Provisions
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "4.8pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
     21.1 This User Agreement (and all documents incorporated by reference) constitutes the entire agreement between the parties pertaining to the subject matter hereof and supersedes all prior agreements and understandings between the parties hereto with respect to the subject matter hereof, whether written or oral. No amendment, modification or supplement of any provision of this User Agreement will be valid or effective unless made in accordance with the express terms of this User Agreement.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
   21.2 If any provision of this User Agreement is held to be invalid or unenforceable under any circumstances, its application in any other circumstances and the remaining provisions of this User Agreement shall not be affected.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      21.3 We may assign or transfer our rights and obligations under this User Agreement at any time, provided that we assign the User Agreement on the same terms or terms that are no less advantageous to you. You may not assign or transfer this User Agreement, or any of its rights or obligations, without our prior written consent, which we can refuse in our sole discretion.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      21.4 No agency, partnership, joint venture, employer-employee or franchisor-franchisee relation to PrimeTic is intended or created by this User Agreement, your use of the Site, or the provision of our Services. A person who is not a party to this User Agreement has no right to enforce any term of this User Agreement.
    </p>
  
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      21.5 Nothing in this User Agreement is intended to confer benefits, rights or remedies unto any person or entity other than the parties hereto and their permitted successors and assigns.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      21.6 The subject heading at the beginning of each paragraph of this User Agreement is for reference purposes only and in no way defines, limits, construes or describes the scope or extent of such paragraph.

    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      21.7 Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. We do not guarantee we will take action against all breaches of this User Agreement.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: "7.25pt",
        marginLeft: "-.25pt"
      }}
    >
      21.8 Legal notices to us shall be served by registered post to your relevant entity listed in Clause 2.1. We shall send notices to you by email to the email address you provide to us during the registration process. Notice to you shall be deemed given 24 hours after the email is sent, unless we are notified that the email address is invalid. Alternatively, we may give you legal notice by registered post to the postal address provided during registration. Notices sent to either party by registered post shall be deemed to have been received by that party three days after the date of mailing.

    </p>
    <h1 style={{ marginLeft: "35.25pt", textIndent: "-.5in" }}>
      <span style={{ lineHeight: "103%" }}>
        22.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Force Majeure
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "12.85pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
      PrimeTic shall not be in default or otherwise liable under this User Agreement due to its inability to perform its obligations by reason of any fire, earthquake, flood, substantial snowstorm, epidemic, pandemic, accident, explosion, casualty, strike, lockout, labor controversy, riot, civil disturbance, act of public enemy, terrorism, cyber-terrorism, embargo, war, act of God, natural disaster, or any municipal, county, state or national ordinance or law, or any executive, administrative or judicial order, or any failure or delay of any transportation, power, or communications system.
    </p>
  </div>
  
</div>

    </GeneralWrapper>
  )
}
