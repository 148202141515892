import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { clearAll, setErrorMessage, setLoading } from '../../../config/redux/slice/authSlice'
import { post_put_patch_request } from '../../../helpers/request'
import { app_api } from '../../../utils/endpoints/app_api'
import { app_urls } from '../../../utils/urls/app_urls'
import AuthAlert from '../../components/AuthAlert'
import PasswordComponent from '../../components/Form/PasswordComponent'
import Spinner from '../../components/Spinner'
import AuthWrapper from '../../layout/AuthWrapper'
import account_activated_background from '../../../assets/images/background_images/account_activated_background.jpg'

export default function PasswordReset() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {register, handleSubmit, watch, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
  const {
        loading,
        errorMsg,
        userTemporaryDetails:{email}
    } = useSelector((state) => state.authData)

    const [successMessage, setSuccessMessage] = useState(false)
    const [isResending, setIsResending] = useState(false)

  const passwordValue = watch('newPassword')

    useEffect(()=>{
      dispatch(clearAll({}))
    }, [])

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        const res = await post_put_patch_request(
          "patch",
            "",
            `${app_api.reset_password}`,
            {...data, email},
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )
        if(res !== undefined && res.status < 400){
          navigate(app_urls.password_reset_success)
        }
    }

    const resendEmail = async (e) => {
        e.preventDefault()
        setIsResending(true)
        dispatch(clearAll({}))
        setSuccessMessage(false)
        const res = await post_put_patch_request(
          "post",
            "",
            `${app_api.forgot_password}`,
            {email},
            dispatch,
            "",
            "",
            setErrorMessage
        )
        if(res !== undefined && res.status < 400){
          setSuccessMessage(true)
          setIsResending(false)
        }else{
          setIsResending(false)
        }
    }

  const closeAlert = () => {
        dispatch(setErrorMessage({message: null}))
  }

  const closeSuccessAlert = () => {
        dispatch(setErrorMessage({message: null}))
  }

  return (
    <AuthWrapper background_image={account_activated_background} onHandleSubmit={handleSubmit(onSubmit)}>
      {/* <h6 style={{fontSize: '0.86rem'}}>Fill in details to reset you password</h6> */}
      {successMessage && <AuthAlert onClick={closeSuccessAlert} alertStyles={{width: '95%', margin: '0 auto'}} status='success'>
        Password Reset Link Sent
        </AuthAlert>}  
      {errorMsg && <AuthAlert onClick={closeAlert} alertStyles={{width: '95%', margin: '0 auto'}} status='error'>
        {errorMsg}
      </AuthAlert>}
      <h6 style={{fontSize: '0.86rem'}}>Fill in details to reset you password</h6>
      <div className="form-group col-md-12 mb-4">
        <label htmlFor='token'>One Time Password (OTP)</label>
        <input 
          type="text" 
          className="form-control input-lg" 
          id="token"
          {...register("token", {
            required: {
              value: true,
              message: '*this field is required'
            }
          })
          } 
        />
        {
          errors?.token?.message &&
          <div className="text-danger small mt-1">
            {errors?.token?.message}
          </div>
        }
      </div> 
      <PasswordComponent
        fieldName={'newPassword'}
        error_msg={errors.newPassword?.message}
        label={'New Password'}
        isReq={true}
        register={register}
        patternRules={'password'}
        hasHelperText={true}
      />
      <PasswordComponent
        fieldName={'confirm_password'}
        error_msg={errors.confirm_password?.message}
        label={'Confirm Password'}
        isReq={true}
        register={register}
        patternRules={'password'}
        isSameAs={passwordValue}
      />
      <div className="col-md-12">

        <div>
          {loading ? 
            <Spinner class_styles={'mb-3'}/> :
            <button type="submit" className="btn btn-primary mb-4 text-uppercase">Reset password</button>
          }
        </div>

        <div style={{columnGap: '0.4rem'}} className='d-flex mb-2'>
            <p>Didn't receive the email yet?
            </p>
            {isResending ? 
            <Spinner spinner_styles={{width: '1rem', height: '1rem'}}/> :
            <a onClick={(e)=>{resendEmail(e)}} className="text-blue" role='button'>
              Send OTP Again
            </a>
          }
        </div>

        <p>
          <Link className="text-blue" to={app_urls.login}>Back to Login</Link>
          {/* <a className="text-blue" href="sign-up.html">Register Here</a> */}
        </p>
      </div>
    </AuthWrapper>
  )
}
