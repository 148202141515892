import React, { useEffect } from 'react'
import AdminWrapper from '../../../layout/AdminWrapper'
import AddStadium from './AddStadium'
import StadiumTable from './StadiumTable'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll } from '../../../../config/redux/slice/adminSlice'
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import DeleteVenue from './DeleteStadium'
import EditVenue from './EditStadium'
import FilterStadium from './FilterStadium'

export default function StadiumManagement() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
      venueManagement:{showAddVenueForm, venueDetails, showVenueModal: {show, type}}
    } = useSelector((state) => state.admin)

    useEffect(() => {
      dispatch(clearAll({}))
    },[])

  return (
    <AdminWrapper>
      <div>
        {(show && type == 'delete') &&
        <DeleteVenue venueObj={venueDetails}/>}
        {(show && type == 'edit') &&
        <EditVenue venueObj={venueDetails}/>}
        {showAddVenueForm && <AddStadium/>}
        <FilterStadium/>
        <StadiumTable/>
      </div>
    </AdminWrapper>
  )
}
