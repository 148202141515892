import React from 'react'
import primetic_logo from '../../../../assets/images/primetic_logo.png'
import default_img from '../../../../assets/images/default-img.jpeg'
import { navigationStructure } from '../../../../utils/nav_structure'
import { Link, useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../../../config/redux/slice/authSlice'
import { logoutCustomerUser } from '../../../../config/redux/slice/customerSlice'
import {
  logoutAppUser,
  setBreadCrumbName,
  showCartModalFunc,
} from '../../../../config/redux/slice/appSlice'
import { system_user_roles } from '../../../../constants/english'
import { logoutAdminUser } from '../../../../config/redux/slice/adminSlice'
import EventHeaderInfo from './EventHeaderInfo'

export default function NavbarLayout({ eventObj }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { userData, token } = useSelector((state) => state.authData)

  // const {
  //   eventManagement:{eventDetails}
  // } = useSelector((state) => state.app)

  const {
    isActive,
    cartManagement: { cartData, cartNumber },
  } = useSelector((state) => state.app)

  const logout = (e) => {
    e.preventDefault()
    dispatch(logoutUser())
    dispatch(logoutCustomerUser())
    dispatch(logoutAppUser())
    dispatch(logoutAdminUser())
  }

  return (
    <nav className="navbar customer navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand order-1 order-lg-1" to={app_urls.home}>
          <img className="app-logo" src={primetic_logo} alt="primetic logo" />
        </Link>
        <button
          className="navbar-toggler border-0 order-3 order-lg-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse order-5 order-lg-1"
          id="navbarNav"
        >
          <div className="w-100 d-flex align-items-center">
            <div className="mx-lg-auto d-none d-lg-block">
              <EventHeaderInfo eventObj={eventObj} />
            </div>
            <ul className="navbar-nav align-items-lg-center">
              {/* cart icon hide .............................*/}
              {/* <li className="nav-item">
                <a
                  onClick={() => {
                    dispatch(showCartModalFunc({ modal: true, type: 'cart' }))
                  }}
                  className={`nav-link fw-bold text-primary position-relative`}
                >
                  <i className="mdi mdi-cart fs-4"></i>
                  <span
                    style={{ top: '10px', left: '80%', fontSize: '0.65rem' }}
                    className="position-absolute translate-middle badge rounded-pill bg-danger"
                  >
                    {cartNumber}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </a>
              </li> */}
               {/* cart icon hide .............................*/}
              {navigationStructure.event_navigation.map((option) => {
                return (
                  <li key={option.id} className="nav-item">
                    {option.isFunc ? (
                      <Link
                        to={option.url(eventObj?.id)}
                        className={`${
                          option.url == isActive ? 'active' : ''
                        } nav-link fw-bold text-primary`}
                        aria-current="page"
                      >
                        {option.name}
                      </Link>
                    ) : (
                      <Link
                        to={option.url}
                        className={`${
                          option.url == isActive ? 'active' : ''
                        } nav-link fw-bold text-primary`}
                        aria-current="page"
                      >
                        {option.name}
                      </Link>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>

          {/* <ul className='p-0 m-0'>
    
                  <li className="dropdown user-menu">
                    <button className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                      <img src={default_img} className="user-image rounded-circle" alt="User" />            
                    </button>
                    <ul style={{fontSize: '0.8rem'}} className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to={app_urls.profile}>
                          <i className="mdi mdi-account-outline"></i>
                          <span className="nav-text">My Profile</span>
                        </Link>
                      </li>
  

                      <li className="dropdown-footer">
                        <a onClick={(e)=>{logout(e)}} className="dropdown-footer-item" href="#"> <i className="mdi mdi-logout"></i> Log Out </a>
                      </li>
                    </ul>
                  </li>
                </ul> */}
        </div>
        {/* {system_user_roles.includes((userData.user_role).toLowerCase()) == false && 
            <> */}
        {token && token != '' && userData?.email != '' ? (
          <ul
            style={{ width: '60px', flexBasis: 'auto' }}
            className="ms-auto ms-lg-0 p-0 m-0 order-2 order-lg-3"
          >
            <li className="dropstart user-menu">
              <button
                className="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
              >
                <img
                  crossOrigin="anonymous"
                  src={
                    !userData?.avatar || userData.avatar == ''
                      ? default_img
                      : userData?.avatar
                  }
                  className="user-image rounded-circle"
                  alt="User"
                />
              </button>
              <ul style={{ fontSize: '0.8rem' }} className="dropdown-menu">
                {system_user_roles.includes(userData.user_role.toLowerCase()) ==
                false ? (
                  <>
                    {/* <li>
                          <Link className="dropdown-item" to={app_urls.dashboard}>
                            <i className="mdi mdi-view-dashboard"></i>
                            <span className="nav-text">Dashboard</span>
                          </Link>
                        </li> */}

                    <li>
                      <Link className="dropdown-item" to={app_urls.dashboard}>
                        <i className="mdi mdi-account-outline"></i>
                        <span className="nav-text">Dashboard</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link
                      onClick={() => {
                        dispatch(
                          setBreadCrumbName([
                            {
                              id: 1,
                              name: 'Dashboard',
                              url: app_urls.admin_dashboard,
                            },
                          ])
                        )
                      }}
                      className="dropdown-item"
                      to={app_urls.admin_dashboard}
                    >
                      <i className="mdi mdi-account-outline"></i>
                      <span className="nav-text">Dashboard</span>
                    </Link>
                  </li>
                )}
                <li className="dropdown-footer">
                  <a
                    onClick={(e) => {
                      logout(e)
                    }}
                    className="dropdown-footer-item"
                    href="#"
                  >
                    {' '}
                    <i className="mdi mdi-logout"></i> Log Out{' '}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        ) : (
          <div
            style={{ flexBasis: 'auto' }}
            className="ms-auto ms-lg-0 order-2 order-lg-3"
          >
            <button
              onClick={() => {
                navigate(app_urls.login)
              }}
              style={{ fontSize: '0.9rem' }}
              className="btn btn-accent rounded-pill mt-lg-0 ms-lg-3 fw-medium px-4"
            >
              Log in
            </button>
          </div>
        )}
        {/* </>
              } */}
        <div className="order-4 w-100 d-block d-lg-none">
          <EventHeaderInfo eventObj={eventObj} classNameStyles={'mx-auto'} />
        </div>
      </div>
    </nav>
  )
}
