import React, { useEffect } from 'react'
import GeneralWrapper from './GeneralWrapper';
export default function ContactPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <GeneralWrapper>
      <div className="contactus-wrapper">
        <h3 className="contactus-head">How to get in touch with us</h3>
        <div className="contact-us-div">
          <div className="contactus-sec">
            <div className="icon-contactus-bg"><i class="fa fa-building-o icon-contactus"></i></div>
            <h2 className='contactus-label'>Company</h2>
            <div className='contactus-text'>Prime Events Ltd.</div>
            <div>Registered in Ireland; Reg no 753108</div>
          </div>
          <div className="contactus-sec">
          <div className="icon-contactus-bg"><i class="fa fa-map-marker icon-contactus icon-contactus-address"></i></div>
            <h2 className='contactus-label'>Address</h2>
            <div className='contactus-text'>North Point House, North Point Business Park,</div>
            <div className='contactus-text'>New Mallow Road, Cock, Ireland, T23 AT2P</div>
            
          </div>

          <div className="contactus-sec">
          <div className="icon-contactus-bg"><i class="fa fa-envelope icon-contactus"></i></div>
          <h2 className='contactus-label'>Email</h2>
          <div className='contactus-text'><b>General Inquiries: </b><a href="mailto:contact@primeevents.live">contact@primeevents.live</a></div>
            <div  className='contactus-text'><b>Buyers: </b><a href="mailto:fansupport@primeevents.live">fansupport@primeevents.live</a></div>
            <div  className='contactus-text'><b>Sellers: </b><a href="mailto:support@primeevents.live">support@primeevents.live</a></div>
          </div>
        </div>

      </div>
    </GeneralWrapper>
  )
}
