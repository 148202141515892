import React, { memo, useEffect, useState } from 'react';
import { get_delete_request } from '../../../../helpers/request';
import { useDispatch, useSelector } from 'react-redux';
import { app_api } from '../../../../utils/endpoints/app_api';
import { clearAll, setAdminErrorMessageUser } from '../../../../config/redux/slice/adminSlice';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import Loader from './Loader';
import ApexCharts from 'react-apexcharts';
import { formatNumber } from '../../../../helpers/helpers'


function BarChart() {
  const dispatch = useDispatch();
  const [selectedPeriod, setSelectedPeriod] = useState('month');
  const [selectedEvent, setSelectedEvent] = useState("null");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sales_data, setSales_data] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useSelector((state) => state.authData);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [series, setSeries] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    revenueTicketDetails();
    getEvents();
  }, [isChecked]);

  const getCurrentDate = () => {
    return { month: selectedDate.getMonth() + 1, year: selectedDate.getFullYear() };
  };

  const getEvents = async () => {
    const res = await get_delete_request(
      "get",
      token,
      `${app_api.get_events}`,
      {},
      dispatch,
      "",
      "",
      setAdminErrorMessageUser
    );
    if (res !== undefined && res.status < 400) {
      let events_list = res?.data?.data || [];
      console.log(events_list, "eventsssssssssssss", res);
      setEventsList(events_list);
    }
  };


  const revenueTicketDetails = async (period = selectedPeriod, event_id = selectedEvent) => {
    const { month, year } = getCurrentDate();
    const revenuType = isChecked ? 'commission' : 'sold';
    console.log("....................", revenuType);
    const formattedToDate = toDate ? formatDate(toDate) : null;
    const url = app_api.revenue_tickets_sold(period, month, year, event_id, fromDate, formattedToDate, revenuType);
    console.log("🚀 ~ revenueTicketDetails ~ fromDate:", fromDate)
    try {
      const res = await get_delete_request(
        "get",
        token,
        url,
        {},
        dispatch,
        "",
        "",
        setAdminErrorMessageUser
      );
      if (res && res.status < 400) {
        const revenueTicketList = res?.data?.data?.data || [];
        console.log("revenueTicketttttttttttttttt", res);
        setSales_data(revenueTicketList);
        const updatedSeries = [
          {
            name: isChecked ? 'Commission Revenue' : 'Sales Revenue',
            data: revenueTicketList.map(item => item.total ?? 0)
          }
        ];
        setSeries(updatedSeries);
        setIsLoading(false);
        const updatedOptions = {
          chart: {
            type: 'bar',
            height: 350
          },
          xaxis: {
            categories: period === 'week'
              ? revenueTicketList.map(item => `Week ${item.week}`)
              : period === 'month'
                ? revenueTicketList.map(item => `${item.month}`)
                : revenueTicketList.map(item => `${item.day}`)
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return `€${formatNumber(val)}`;
              }
            }
          },
          dataLabels: {
            enabled: false,
            formatter: function (val) {
              return `${formatNumber(val)}`; // Format the labels inside the bars
            }
          }
        };
        setOptions(updatedOptions);

      }
    } catch (error) {
      console.error("Error fetching revenue ticket details:", error);
    }
  };

  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 350
    },
    xaxis: {
      categories: []
    }, tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return "€" + formatNumber(val); // Example of customizing tooltip format
        }
      }
    }
  });

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
    setFromDate(null);
    setToDate(null);
  };

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date)
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleFromDateChange = (date) => {
    const newToDate = addDays(date, 30);
    setToDate(newToDate);
    setSelectedPeriod("0")
    setSelectedEvent("null")
    const formattedFromDate = formatDate(date);
    console.log("Formatted fromDate:", formattedFromDate);
    setFromDate(formattedFromDate);
    // if (toDate && date && addDays(date, 30) < toDate) {
    //   setToDate(null);  // Reset toDate if it is beyond the 30-day limit from the new fromDate
    // }
  }

  const handleToDateChange = (date) => {
    setSelectedPeriod("0")
    setSelectedEvent("null")
    const formattedToDate = formatDate(date);
    console.log("Formatted toDate:", formattedToDate);
    setToDate(formattedToDate);
  }

  const searchClick = () => {
    setIsLoading(true);
    revenueTicketDetails();
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    revenueTicketDetails();
    setIsLoading(true);
  };

  const datePickerProps = selectedPeriod === "month" ? { dateFormat: "yyyy", showYearPicker: true } : { dateFormat: "MM/yyyy", showMonthYearPicker: true };

  return (
    <div className="card p-4 mt-4 card-loader-sec">

      <div className="d-flex justify-content-between">
        <h6 className="card-body-title text-uppercase fw-bold tx-13">SALES REVENUE/COMMISSION BY EVENT/MONTH/WEEK/DAY</h6>
        <div className="d-flex align-items-center" >
          <label class="form-check-label" for="flexSwitchCheckDefault" style={{ marginRight: "12px" }}><h6 className='card-body-title text-uppercase fw-bold tx-13'>SALES</h6></label>
          <div class="form-check form-switch">
            <input class="form-check-input mt-1" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={isChecked} onChange={handleCheckboxChange}/>
            <label class="form-check-label" for="flexSwitchCheckDefault" style={{ marginLeft: "5px" }}><h6 className='card-body-title text-uppercase fw-bold tx-13'>COMMISSION</h6></label>
          </div>
        </div>

      </div>
      <div className="row w-100 align-items-center mb-3">
        <div className="col-md-2">
          <select value={selectedEvent} onChange={handleEventChange} className="form-select">
            <option value="null">All Events</option>
            {eventsList.map((event) => (
              <option key={event.id} value={event.id}>{event.name}</option>
            ))}
          </select>
        </div>
        {/* <div className="col-md-9">
          <div className="row w-100 align-items-center "> */}


        <div className="col-md-2 px-2">
          <select value={selectedPeriod} onChange={handlePeriodChange} className="form-select">
            <option value="0">Select Type</option>
            <option value="month">Month</option>
            <option value="week">Week</option>
            <option value="day">Day</option>
          </select>
        </div>

        <div className="col-md-2 px-2">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            placeholderText='Select'
            // dateFormat="MM/yyyy"
            {...datePickerProps}
            className="form-select"
          />
        </div>
        <span className="col-md-1 px-2 text-center"><b>OR</b></span>
        <div className="col-md-2 px-2 datepick-input">
          <DatePicker
            selected={fromDate}
            onChange={handleFromDateChange}
            dateFormat="dd/MM/yyyy"
            placeholderText='From'
            className="form-select"
          />
        </div>
        <div className="col-md-2 px-2 datepick-input">
          <DatePicker
            selected={toDate}
            onChange={handleToDateChange}
            placeholderText='To'
            dateFormat="dd/MM/yyyy"
            className="form-select"
            minDate={fromDate}
            maxDate={fromDate ? addDays(fromDate, 30) : null}
          />
        </div>
        <div className="col-md-1 px-2">
          <button type="button" className="btn btn-primary" onClick={searchClick}>Search</button>
        </div>
      </div>


      {/* </div>
      </div> */}



      <div className={isLoading === true && "loader-outer-bg"}>
        {isLoading === true && <Loader />}
        <ApexCharts options={options} series={series} type="bar" height={350} />
      </div>
    </div>
  );
}

export default memo(BarChart);
