import React, {useEffect } from 'react'
import about_us_jumbotron_img from '../../../assets/images/about_us_jumbotron_img.jpeg'
import GeneralWrapper from '../../layout/GeneralWrapper'
import right_ellipse from '../../../assets/images/right_ellipse_icon.svg';
import left_ellipse from '../../../assets/images/left_ellipse_icon.svg';
import { sport_leagues, why_we_are_different } from '../../../constants/english';
import premier_league_img from '../../../assets/images/premier_league_img.png'
import ButtonComponent from '../../components/ButtonComponent';
import for_fans_img from '../../../assets/images/for_fans_img.png'
import about_us_jumbotron_img_small from '../../../assets/images/about_us_jumbotron_img_small.jpeg'
import premier_league_img_small from '../../../assets/images/premier_league_img_small.png'
import { InfoComponent, JumbotronComponent, MiddleSectionComponent } from '../../components';
import { useNavigate } from 'react-router-dom';
import { app_urls } from '../../../utils/urls/app_urls';

export default function AboutUsPage() {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleGetTickets = () => {
    navigate(app_urls.buyers) // Use the appropriate URL
  }
  const handleTermsAndConditions= () => {
    navigate(app_urls.terms_and_condition) // Use the appropriate URL
  }
  return (
    <GeneralWrapper>
        <div className='container-fluid p-0'>
            <JumbotronComponent hero_text='Connecting fans to unforgettable moments' img_src={about_us_jumbotron_img} img_src_small={about_us_jumbotron_img_small}/>

            {/* <div className='position-relative text-center mt-3 mb-2 mb-md-3 mb-lg-5 py-3 py-md-5 px-3 px-sm-5 px-md-100 px-lg-240'>
              <h1 className='text-primary fw-bold fs-5 fs-sm-3 fs-md-2 fs-lg-1'>Turning ordinary occasions into extraordinary memories. 
              Getting you to the games you love. Bringing people together. It’s what we’re here for.</h1>
              <p className='fs-6 fs-sm-4 fs-md-3 fs-lg-2 my-2 my-md-4'>The excitement of live events make memories that last a lifetime, and friendships that 
                enrich every day of your life. A simpler, fairer and faster ticketing platform that brings people together.</p>
                <p className='fs-5 fs-sm-3 fs-md-2 fs-lg-1 text-primary fw-bold'>So let’s cheer. Together.</p>
            </div> */}

            <MiddleSectionComponent 
              heading_text='Turning ordinary occasions into extraordinary memories. 
              Getting you to the sporting events you love. Bringing people together. It’s what we’re here for.'
              middle_text='The excitement of live events make memories that last a lifetime, and friendships that 
                enrich every day of your life. A simpler, fairer and faster ticketing platform that brings people together.'
              bottom_text='So let’s cheer. Together.'  
            />
            
            <InfoComponent heading_text='Why you can trust PrimeTic' dataList={why_we_are_different}/>
            
            <div className='mt-5 mt-md-11 rounded'>
              <h2 className='text-primary mx-3 mx-md-0 fw-bold text-center'>PrimeTic sports and leagues</h2>
              <div className='position-relative text-white mt-10 mb-4 px-3 px-md-5 rounded'>
                <img className='w-100 d-none d-md-block premier-league-img-md rounded' src={premier_league_img} />
                <img className='w-100 d-block d-md-none premier-league-img-md rounded' src={premier_league_img_small} />
                <div className='px-3 px-md-5 position-absolute top-50 start-50 translate-middle w-100 h-100 rounded'>
                  <div className='w-100 h-100 rounded' style={{background: 'rgba(20, 40, 98, 0.5)'}}></div>
                </div>
                <div className='second-container-text row gy-4 gy-lg-56 px-4 px-md-100 px-lg-156 w-100 position-absolute top-50 start-50 translate-middle text-center league-section-box-wrpr'>
                  {sport_leagues.map((option)=>{
                    return <React.Fragment key={option.id}>
                      <p className='d-block d-md-none col-md-6 col-lg-3 fs-5 fw-bold league-section-box'>{option.text}</p>
                      {/* <p className='col-md-6 col-lg-3 fs-2 fs-md-6 fs-lg-2 fw-bold'>{option.text}</p> */}
                      <p className='d-none d-md-block d-lg-none col-md-6 col-lg-3 fs-4 fw-bold league-section-box'>{option.text}</p>
                      <p className='d-none d-lg-block col-md-6 col-lg-3 fs-3 fw-bold league-section-box'>{option.text}</p>
                    </React.Fragment>
                  })}
                </div>
              </div>
              <div className='text-center'>
                <ButtonComponent btn_styles={'px-5'} onClick={handleGetTickets} btn_text='Get Tickets'/>
              </div>
            </div>

            <div className='mt-5 mb-5 px-3 px-md-56'>
              <div className="card mt-5 border-0 mb-3">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-5">
                    <img src={for_fans_img} className="w-100 img-fluid rounded-start" alt="..."/>
                  </div>
                  <div className="d-none d-lg-block col-lg-1"></div>
                  <div className="col-lg-5">
                    <div className="card-body">
                      <h2 className="card-title text-primary fw-bold">For fans</h2>
                      <p className="d-block d-lg-none card-text fs-6">Search, filter and find the perfect seats. Set your price 
                      for an event and get alerts when the right ticket goes on sale. Secure, fast delivery via email, 
                      mobile transfer or post.</p>
                      <p className="d-none d-lg-block card-text fs-5">Search, filter and find the perfect seats. Set your price 
                      for an event and get alerts when the right ticket goes on sale. Secure, fast delivery via email, 
                      mobile transfer or post.</p>
                     <div className='row'>
                        <div className='col-md-5 col-lg-6'><ButtonComponent onClick={()=>{navigate(app_urls.buyers)}} btn_text='How it Works for Buyers'/></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-5 border-0 mb-3">
                <div className="row flex-row-reverse">
                  <div className="col-lg-5">
                    <img src={for_fans_img} className="w-100 img-fluid rounded-start" alt="..."/>
                  </div>
                  <div className="d-none d-lg-block col-lg-1"></div>
                  <div className="col-lg-5">
                    <div className="card-body">
                      <h2 className="card-title text-primary fw-bold">For Sellers</h2>
                      <p className="d-block d-lg-none card-text fs-6">Register as an approved ticket vendor for complete security. 
                      Choose your event, share ticket details, and set your price. Guaranteed payment 4-7 days after the 
                      event ends.</p>
                      <p className="d-none d-lg-block card-text fs-5">Register as an approved ticket vendor for complete security. 
                      Choose your event, share ticket details, and set your price. Guaranteed payment 4-7 days after the 
                      event ends.</p>
                      <div style={{columnGap: '', rowGap: '1rem'}} className='row gx-1'>
                        <div className='col-md-5 col-lg-6'><ButtonComponent onClick={()=>{navigate(app_urls.sellers)}} btn_text='How it Works for Sellers'/></div>
                        <div className='col-md-5 col-lg-6'><ButtonComponent color='bg-white' btn_styles='border border-2 border-primary' btn_text='Terms and Conditions' onClick={handleTermsAndConditions}/></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </GeneralWrapper> 
  )
}
