import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setBreadCrumbName } from '../../../config/redux/slice/appSlice'

export default function BreadCrumb({breadcrumb_name}) {
  const dispatch = useDispatch()

  const {breadCrumbName} = useSelector((state) => state.app)

  return (
    <nav style={{backgroundColor: '#e9ecef'}} className="py-3 pt-5 px-4" aria-label="breadcrumb">
        <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item active fs-5" aria-current="page">{breadcrumb_name}</li>
        </ol>
    </nav>
  )
}
