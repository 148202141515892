import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, clearResetBot, onGetProfileListings, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading, setDetails } from '../../../../config/redux/slice/customerSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { Link, useNavigate } from 'react-router-dom'
import { get_delete_request } from '../../../../helpers/request'
import { formatNumber } from '../../../../helpers/helpers'
import { format } from 'date-fns';
import { strText } from '../../../../constants/english'
export default function PaidPayoutTable() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { token } = useSelector((state) => state.authData)
    const [paidPayout_data, setPaidPayout_data] = useState([]);
    const [loading, setLoading] = useState(true);
    const [nestedExpandedRows, setNestedExpandedRows] = useState({});
    const [expandedRows, setExpandedRows] = useState([]);


    useEffect(() => {
        getpaidPayoutList()
    }, [])


    const getpaidPayoutList = async () => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_paidPayout_tbl}?page=1&pageSize=5`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        if (res !== undefined && res.status < 400) {
            let paidPayout_list = res?.data?.data || [];
            console.log("🚀 ~ getpaidPayoutList ~ res:", paidPayout_list)
            setPaidPayout_data(paidPayout_list.data);
        }
        setLoading(false);
    }

    const toggleRow = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows(prevExpandedRows => ({
            ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
            [index]: !prevExpandedRows[index]
        }));
        setNestedExpandedRows({});
    };

    const toggleNestedRow = (mainIndex, nestedIndex, event) => {
        event.preventDefault();
        event.stopPropagation();
        setNestedExpandedRows(prevState => ({
            ...prevState,
            [mainIndex]: {
                ...prevState[mainIndex],
                [nestedIndex]: !prevState[mainIndex]?.[nestedIndex]
            }
        }));
    };
    return (
        <>
            <div className="table-responsive web-show-tbl">
                <table class="table table-striped mt-3">
                    <thead>
                        <tr>

                            <th scope="col">No. of Sales</th>
                            <th scope="col">Event Name</th>
                            <th scope="col">Event Date</th>
                            <th scope="col">Total Amount</th>
                            <th scope="col">Date of Payout</th>
                            <th scope="col">Payout Status</th>
                            <th scope="col" className='no-wrap'>Expand</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="7" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                            </tr>) :
                            paidPayout_data.length > 0 ? (

                                paidPayout_data.map((item, index) => (
                                    <>
                                        <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                                            <td>{item.orders.length}</td>
                                            <td>{item.eventDetails.name}</td>
                                            <td>{format(new Date(item.eventDetails.date), 'EEE dd MMM yyyy').toUpperCase()}</td>
                                            <td>£{formatNumber(item.totalAmount)}</td>
                                            <td>{format(new Date(item.paidAt), 'EEE dd MMM yyyy').toUpperCase()}</td>
                                            <td>{item.payoutStatus}</td>
                                            <td> <a title='Details Payout' className="btn btn-sm bg-primary text-white btn-icon view-details btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                                {expandedRows[index] ? (
                                                    <i className="fa fa-angle-up fa-sizeset"></i>
                                                ) : (
                                                    <i className="fa fa-angle-down fa-sizeset"></i>
                                                )} </a></td>
                                        </tr>
                                        {(expandedRows[index] && item.orders.length > 0) && (
                                            <tr>
                                                <td colSpan="8">
                                                    <table className="table table-striped tbl-inner-width">
                                                        <thead>
                                                            <tr>
                                                                <th className='no-wrap'>Order Id</th>
                                                                <th className='no-wrap'>No. Of Ticket</th>
                                                                <th className='no-wrap'>Ticket Price</th>
                                                                <th className='no-wrap'>Total Amount</th>
                                                                <th className='no-wrap'>Sale Date </th>
                                                                {/* <th className='no-wrap'>Delivery Status</th> */}
                                                                <th className='no-wrap'>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        {item.orders.map((item, orderIndex) => (
                                                            <tbody>
                                                                <tr key={orderIndex}>
                                                                    <td className={nestedExpandedRows[index]?.[orderIndex] ? 'fontWeight' : ""}>{item?.orderRef}</td>
                                                                    <td>{item?.OrderItems?.length || 0}</td>
                                                                    <td>{item?.pricePerTicket ? `${strText.currency_symbol}${formatNumber(item?.pricePerTicket)}` : 'N/A'}</td>
                                                                    <td>{item?.totalAmountWithoutCommission ? `${strText.currency_symbol}${formatNumber(item?.totalAmountWithoutCommission)}` : 'N/A'}</td>
                                                                    <td>{item?.createdAt ? (format(new Date(item?.createdAt), 'EEE dd MMM yyyy').toUpperCase()) : 'N/A'}</td>
                                                                    {/* <td>{item?.deliveryStatus}</td> */}
                                                                    <td>
                                                                        <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, orderIndex, event)}>
                                                                            {nestedExpandedRows[index]?.[orderIndex] ? (
                                                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                                                            ) : (
                                                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                                                            )}
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                                {nestedExpandedRows[index]?.[orderIndex] && (
                                                                    <tr>
                                                                        <td colSpan="7">
                                                                            <table className="table table-bordered tbl-subinner-width">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className='no-wrap'>Ticket Type</th>
                                                                                        <th className='no-wrap'>Section</th>
                                                                                        <th className='no-wrap'>Block</th>
                                                                                        <th className='no-wrap'>Row</th>
                                                                                        {/* <th className='no-wrap'>Seat Number</th> */}

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr key={orderIndex}>
                                                                                        <td>{item?.ticketsType}</td>
                                                                                        <td>
                                                                                            {item?.sectionName}
                                                                                        </td>
                                                                                        <td>
                                                                                            {item?.subSectionName !== null ? item?.subSectionName : ""}
                                                                                        </td>
                                                                                        <td>{item?.row}</td>
                                                                                        {/* <td>{item?.seats}</td> */}
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>))}
                                                    </table>
                                                </td>
                                            </tr>
                                        )}

                                    </>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className='text-center py-4'>No Data Found</td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>

            <div className="mob-table-section">
                {loading ? (
                    <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>

                ) : paidPayout_data.length > 0 ? (
                    paidPayout_data.map((item, index) => (
                        <>

                            <div className={`mob-table-sec-outer ${expandedRows[index] ? 'fontWeight' : ''}`} key={index}>
                                <div className="position-relative">
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">No. of sales</h3>
                                        <div className="mob-tbl-value">{item.orders.length}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event Name</h3>
                                        <div className="mob-tbl-value" title={item.eventDetails.name}>{item.eventDetails.name}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event Date</h3>
                                        <div className="mob-tbl-value">{format(new Date(item.eventDetails.date), 'EEE dd MMM yyyy').toUpperCase()}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Total Amount</h3>
                                        <div className="mob-tbl-value">£{formatNumber(item.totalAmount)}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Date of Payout</h3>
                                        <div className="mob-tbl-value">{item.paidAt !== null ? format(new Date(item.paidAt), 'EEE dd MMM yyyy').toUpperCase() : "N/A"}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Payout Status</h3>
                                        <div className="mob-tbl-value">{item.payoutStatus}</div>
                                    </div>
                                    <div className="mob-table-sec-expand">
                                        <a title='Details Payout' className="btn btn-sm bg-primary text-white btn-icon view-details btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )} </a>
                                    </div>

                                </div>


                                {(expandedRows[index] && item.orders.length > 0) && (
                                    <>

                                        {item.orders.map((item, orderIndex) => (
                                            <div className="mob-tbl-inner-sec" key={orderIndex}>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Order Id</h3>
                                                    <div className={`mob-tbl-value ${nestedExpandedRows[index]?.[orderIndex] ? 'fontWeight' : ''}`}>{item?.orderRef}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">No of Ticket</h3>
                                                    <div className="mob-tbl-value">{item?.OrderItems?.length || 0}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Ticket Price</h3>
                                                    <div className="mob-tbl-value ">{item?.pricePerTicket ? `${strText.currency_symbol}${formatNumber(item?.pricePerTicket)}` : 'N/A'}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Total Amount</h3>
                                                    <div className="mob-tbl-value ">{item?.totalAmountWithoutCommission ? `${strText.currency_symbol}${formatNumber(item?.totalAmountWithoutCommission)}` : 'N/A'}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Sale Date</h3>
                                                    <div className="mob-tbl-value ">{item?.createdAt ? (format(new Date(item?.createdAt), 'EEE dd MMM yyyy').toUpperCase()) : 'N/A'}</div>
                                                </div>
                                                <div className="mob-table-sec-action">
                                                    <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, orderIndex, event)}>
                                                        {nestedExpandedRows[index]?.[orderIndex] ? (
                                                            <i className="fa fa-angle-up fa-sizeset"></i>
                                                        ) : (
                                                            <i className="fa fa-angle-down fa-sizeset"></i>
                                                        )}
                                                    </a>

                                                </div>

                                                {nestedExpandedRows[index]?.[orderIndex] && (
                                                    <div className="mob-tb-subinner" key={orderIndex}>
                                                        <div className="mob-table-sec">
                                                            <h3 className="mob-tbl-head">Ticket Type</h3>
                                                            <div className="mob-tbl-value">{item?.ticketType}</div>
                                                        </div>
                                                        <div className="mob-table-sec">
                                                            <h3 className="mob-tbl-head">Section</h3>
                                                            <div className="mob-tbl-value" title={item?.sectionName}>{item?.sectionName}</div>
                                                        </div>
                                                        <div className="mob-table-sec">
                                                            <h3 className="mob-tbl-head">Block</h3>
                                                            <div className="mob-tbl-value" >{item?.subSectionName !== null ? item?.subSectionName : ""}</div>
                                                        </div>
                                                        <div className="mob-table-sec">
                                                            <h3 className="mob-tbl-head">Row</h3>
                                                            <div className="mob-tbl-value">{item?.row}</div>
                                                        </div>
                                                        {/* <div className="mob-table-sec">
                                                            <h3 className="mob-tbl-head">Seat Number</h3>
                                                            <div className="mob-tbl-value">{item?.seats}</div>
                                                        </div> */}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </>)}
                            </div>


                        </>

                    ))

                ) : (
                    <div className="text-center py-4">No Data Found</div>

                )}

            </div>

        </>
    )

}
