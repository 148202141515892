import React, { useEffect } from 'react'
import NavbarLayout from './NavbarLayout'
import FooterLayout from './FooterLayout'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveSidebar } from '../../config/redux/slice/appSlice'
import CookieNotice from '../components/CookieNotice'

export default function AppWrapper({children}) {
  const dispatch = useDispatch()

  const {
        adminErrorMsg,
        adminSuccessMsg
    } = useSelector((state) => state.admin)

  useEffect(() => {
        let mainPath = ''

        let path = window.location.pathname

        // let fullPathArray = path.split("/")
        // if(fullPathArray.length <= 2){
        //   mainPath = `/${fullPathArray[1]}`
        // }else{
        //   mainPath = user_role.toLowerCase() == 'TRANSFEREE_USER' ? `/${fullPathArray[1]}` : `/${fullPathArray[1]}/${fullPathArray[2]}` 
        // }
        dispatch(setActiveSidebar({
            mainPath: path
        }))
    }, [])
  return (
    <div>
      <NavbarLayout/>
      {children}
      <FooterLayout/>
      <CookieNotice/>
    </div>
  )
}
