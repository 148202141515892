import React, { useEffect, useState } from 'react'
import PasswordComponent from '../../../components/Form/PasswordComponent'
import $ from 'jquery';
// import 'bootstrap-select/dist/css/bootstrap-select.min.css';
// import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { clearAll, onAddSection, onAddStadium, setAdminErrorMessageUser, setAdminLoadingUser, setLoadingMoreSelectOptions, showAddSectionFormFunc, showAddVenueFormFunc } from '../../../../config/redux/slice/adminSlice'
import AddFormLayout from '../../../layout/admin/AddFormLayout'
import SelectComponent from '../../../components/Form/SelectComponent';
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request';
import { app_api } from '../../../../utils/endpoints/app_api';
import { truncateMiddle } from '../../../../helpers/helpers';
import Spinner from '../../../components/Spinner';

export default function AddSection({stadiumObj}) {
  const dispatch = useDispatch()
    const navigate = useNavigate()
    const {register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})

    const [cityList, setCityList] = useState([])
    const [isSearch, setIsSearch] = useState(false)
    const [searchVal, setSearchVal] = useState(null)
    const [totalCityRecords, setTotalCityRecords] = useState(0)
    const [pageNo, setPageNo] = useState(1)

    const {
        token
    } = useSelector((state) => state.authData)

    const {
            adminLoading,
            adminErrorMsg,
            adminSuccessMsg
    } = useSelector((state) => state.admin)

    const closeForm = () => {
      dispatch(showAddSectionFormFunc({modal: false}))
    }

    const onSubmit = async (data) => {
        dispatch(clearAll({}))

        const res = await post_put_patch_request(
          "post",
            token,
            `${app_api.add_section(stadiumObj.id)}`,
            data,
            dispatch,
            setAdminLoadingUser,
            onAddSection,
            setAdminErrorMessageUser
        )
        if(res !== undefined && res.status < 400){
          reset()
        }
    }
  return (
     <AddFormLayout onClick={closeForm} heading_title='Add New Section' sub_text='Fill in the fields below'>
        <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data' noValidate>
        <div className="px-4 px-md-7">
            <div style={{rowGap: '1.2rem'}} className="row mb-2">

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='name'>Name <span className="text-danger">*</span></label>
                  <input 
                  type="text" 
                  className="form-control input-lg" 
                  id="name"
                  {...register("name", {
                      required: {
                      value: true,
                      message: '*this field is required'
                      }
                  })
                  } 
                  />
                  {
                  errors?.name?.message &&
                  <div className="text-danger small mt-1">
                      {errors?.name?.message}
                  </div>
                  }
              </div> 
              </div>
            </div>

        </div>
        <div className="mt-4 px-4 d-flex align-items-center">
        <div>
          {adminLoading ? 
            <Spinner isForm={true}/> :
            <button type="submit" className="btn btn-primary me-3">Submit Form</button>
          }  
        </div>
          <button disabled={adminLoading ? true : false} type="button" onClick={closeForm} className="btn btn-info">Cancel</button>
        </div>
      </form>
   </AddFormLayout>
  )
}
