import React, { useState, useEffect } from 'react';
import { app_api } from '../../../../utils/endpoints/app_api'
import { post_put_patch_request } from '../../../../helpers/request'
import { useDispatch, useSelector } from 'react-redux'
import { onUpdate } from '../../../../config/redux/slice/adminSlice'


export default function AddComplaint({ showComplaintPopup, setShowComplaintPopup, selectedItem }) {
  const [description, setDescription] = useState('');
  const [reportedBy, setReportedBy] = useState('buyer'); // Default to 'Reported by buyer'
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.authData)
  const [submitting, setSubmitting] = useState(false);

  const closeComplaintPopup = () => {
    setShowComplaintPopup(false);

  };

  const handleSubmit = async () => {
    if (submitting) return; // Prevent further submission if already submitting

    setSubmitting(true);    const complaintData = {
      description,
    };

    if (reportedBy === 'buyer') {
      complaintData.hasBuyer = true;
      complaintData.userId = selectedItem.OrderItems[0]?.order?.userId;
    } else {
      complaintData.hasBuyer = false;
      complaintData.userId = selectedItem.OrderItems[0]?.ticket?.listing?.user?.id;
    }

    // TODO: Send `complaintData` to the backend using your preferred method (e.g., fetch, axios)
    const orderId = selectedItem.id;
    const baseURL = `${app_api.complaints}`;
    const endpoint = "/complaint";

    // Construct the full URL
    const url = `${baseURL}/${orderId}${endpoint}`;
    try {
      const res = await post_put_patch_request(
        "post",
        token,
        url,
        complaintData,
        dispatch,
        "",
        "",
        ""
      );
  
      if (res !== undefined && res.status < 400) {
        // Close the popup after submitting
        dispatch(onUpdate({ message: res.data.message, type: 'refund' }));
        closeComplaintPopup();
      }
    } catch (error) {
      console.error('Error submitting complaint:', error);
    } finally {
      setSubmitting(false); // Re-enable the button after the request is completed
    }
  };

  useEffect(() => {
    if (showComplaintPopup) {
      // Reset the form fields when the popup is opened
      setDescription('');
      setReportedBy('buyer');
    }
  }, [showComplaintPopup]);

  if (!selectedItem || !selectedItem.OrderItems || selectedItem.OrderItems.length === 0) {
    return null; // Or handle this case differently based on your UI requirements
  }

  const orderItem = selectedItem.OrderItems[0];

  return (
    <div id="demo-modal" className="modal delivery-addresssec" style={{ display: showComplaintPopup ? 'flex' : 'none' }}>
      <div className="modal__content">
        <div className="modalFields">
          <div className='add-complaint-wrapper'>
            <div className="form-group mb-2">
              <label htmlFor="description" className='lbl-text-font'>Comment</label>
              <textarea
                id="description"
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group mb-2">
              <label className='lbl-text-font'>Reported by:</label>
              <div className='d-flex'>
                <span className="reportby"><input
                  type="radio"
                  name="reportedBy"
                  value="buyer"
                  checked={reportedBy === 'buyer'}
                  onChange={() => setReportedBy('buyer')}
                /> <span className="">Buyer</span></span>
                <span className="reportby">
                  <input
                    type="radio"
                    name="reportedBy"
                    value="seller"
                    checked={reportedBy === 'seller'}
                    onChange={() => setReportedBy('seller')}
                  /> <span className="">Seller</span>
                </span>
              </div>
            </div>
            <div className="text-right-btn">
              <button className="btn btn-primary" onClick={handleSubmit} disabled={submitting}>Submit</button>
            </div>
          </div>
        </div>
        <a onClick={closeComplaintPopup} className="modal__close" style={{ cursor: 'pointer' }}>
          <i className="fa fa-close"></i>
        </a>
      </div>
    </div>
  );
}
