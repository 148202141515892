import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { formatNumber } from '../../../../helpers/helpers'
const CommissionDetails = ({ show, handleClose, event, commision_details }) => {

    useEffect(() => {

    }, [commision_details]);


    return (
        <Modal show={show} onHide={handleClose} className='modal-popup-commission'>
            <Modal.Header closeButton>
                <Modal.Title>Commission By Events</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card widget-messages mt-4">
                    <div className="card-body-scroll">
                        <div className="list-group list-group-flush">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" className='no-wrap'>Seller Details</th>
                                        <th scope="col" className='no-wrap'>Tickets Listed</th>
                                        <th scope="col" className='no-wrap'>Tickets sold</th>
                                        <th scope="col" className='no-wrap'>Total Amount</th>
                                        <th scope="col" className='no-wrap'>Commission Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {commision_details && commision_details.details && commision_details.details.length > 0 ? (
                                        commision_details.details.map((item) => (
                                            <tr key={item.userId}>
                                                <td>
                                                    <div className="text-ellipse-single" title={item.userName}><b>{item.userName}</b></div>
                                                    <div className="text-ellipse-single" title={item.email}>{item.email}</div>
                                                    <div>{item.phone}</div>
                                                </td>
                                                <td>{item.totalTickets}</td>
                                                <td>{item.totalTicketsSold}</td>
                                                <td>{item.currency + formatNumber(item.totalAmount)}</td>
                                                <td>{item.currency + formatNumber(item.totalCommission)}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4">No event details available</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CommissionDetails;
