import React, { useState } from 'react';

export default function DeliveryAddress({ showDbPopup, setShowDbPopup, selectedItem }) {

  const closeDbPopup = () => {
    setShowDbPopup(false);
  };

  if (!selectedItem || !selectedItem.BankDetails || selectedItem.BankDetails.length === 0) {
    return null; // Or handle this case differently based on your UI requirements
  }

//   const orderItem = selectedItem.OrderItems[0];
//   const user = orderItem.order.user;
//   const ticket = orderItem.ticket;
//   const deliveryAddress = orderItem.deliveryAddress;

  return (
    <div id="demo-modal" className="modal delivery-addresssec" style={{ display: showDbPopup ? 'flex' : 'none' }}>
      {console.log("8888888888888888888888888888.................................",selectedItem)}
      <div className="modal__content">
        <div className="modalFields">
          <div className='model-sec' style={{ background: "transparent" }}>
            <ul className="list-group">
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>Bank Name</div><span className='mx-2'>:</span> <div> {selectedItem?.BankDetails && selectedItem.BankDetails?.length > 0 ? selectedItem.BankDetails[0]?.bankName : ''} </div></li>
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>Account Holder Name</div><span className='mx-2'>:</span> <div>{selectedItem?.BankDetails && selectedItem.BankDetails?.length > 0 ? selectedItem.BankDetails[0]?.accountName : ''}</div></li>
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>IBAN </div> <span className='mx-2'>:</span>  <div> {selectedItem?.BankDetails && selectedItem.BankDetails?.length > 0 ? selectedItem.BankDetails[0]?.IBAN : ''} </div></li>
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>Swift Code </div><span className='mx-2'>:</span> <div>{selectedItem?.BankDetails && selectedItem.BankDetails?.length > 0 ? selectedItem.BankDetails[0]?.accountNo : ''}</div></li>
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>BIC</div><span className='mx-2'>:</span> <div>{selectedItem?.BankDetails && selectedItem.BankDetails?.length > 0 ? selectedItem.BankDetails[0]?.sortCode : ''}</div></li>
            </ul> 
          </div>
        </div>
        <a onClick={closeDbPopup} className="modal__close">
          <i className="fa fa-close"></i>
        </a>
      </div>
    </div>
  );
}
