import React, { useEffect, useState } from 'react'
import AdminWrapper from '../../../layout/AdminWrapper'
import AddPromocode from "./AddPromocode"
import PromoCode from "./PromoCodeMangement"
import { useDispatch, useSelector } from 'react-redux'
import { clearAll } from '../../../../config/redux/slice/adminSlice'

import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'

export default function EventManagement() {

  const dispatch = useDispatch()

  const {
    eventManagement: { showAddEventForm, eventDetails, showEventModal: { show, type } }
  } = useSelector((state) => state.admin)

  const {
    token
  } = useSelector((state) => state.authData)

  const [types, setTypes] = useState([]);
  const [usertypes, setUserTypes] = useState([]) ///////xdsd
  const [activeList, setActiveList] = useState([]);

  useEffect(() => {
    dispatch(clearAll({}))
    getAllPromoCodeTypes()
    getAllUserTypes() //////xdsd
    // getActiveList()
  }, [])

  const getAllPromoCodeTypes = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_promocode_type}`,
      {},
      dispatch,
      "",
      "",
      ""
    )
    if (res != undefined && res.status < 400) {
      setTypes(res?.data?.data)
    }
  }
  //////////XDSD////////
  const getAllUserTypes = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_user_type}`,
      {},
      dispatch,
      "",
      "",
      ""
    )
    if (res != undefined && res.status < 400) {

      setUserTypes(res?.data?.data)
    }
  }
  const getActiveList = async () => {
    const res = await get_delete_request('get', token, `${app_api.get_promocode}`, {}, dispatch, "", "", "", true);
    if (res !== undefined && res.status < 400) {
      setActiveList(res?.data?.data || []);
    }
  };
  return (//xskr
    <AdminWrapper>
      <div>
        {showAddEventForm &&
          <AddPromocode types={types} usertypes={usertypes} activeList={getActiveList} />
        }
        {/* <AddEvent/> */}
        {/* <FilterEvent types={types} usertypes={usertypes}/> */}
        <PromoCode types={types} usertypes={usertypes} activeList={activeList}/>
      </div>
    </AdminWrapper>
  )
}
