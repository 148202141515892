import React, { useEffect } from 'react'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { app_urls } from '../../../../utils/urls/app_urls'
import { Link } from 'react-router-dom'
import { clearOnReload } from '../../../../config/redux/slice/customerSlice'
import { useDispatch } from 'react-redux'

export default function SuccessPage() {
const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(clearOnReload({type: 'payment', clearAll: true}))
    }, [])

  return (
    <CustomerWrapper isSidebarReq={false}>
        <div style={{height: '100vh'}} className='d-flex justify-content-center align-items-center'>
            <div style={{minWidth: '270px', width: '400px', maxWidth: '400px'}} className='card'>
                <div style={{rowGap: '1rem'}} className='card-body greeting flex-column d-flex justify-content-center mb-2 align-items-center'>
                <i style={{fontSize: '2rem'}} className={`mdi mdi-checkbox-marked-circle text-success`}></i>
                    <div className='text-center mb-2'>
                        <p style={{fontSize: '1.2rem', color: 'rgba(0,0,0,0.9)'}} className={`text-capitalize fw-bold`}>
                            Payment Successful!
                        </p>
                        <p style={{fontSize: '1rem', lineHeight: '1.8rem', color: '#31343D'}} className='text-capitalize'>Thank you for your payment</p>
                    </div>

                    <button className='btn btn-success'>
                        <Link style={{textDecoration: 'none', color: 'white'}} to={app_urls.home}>Continue to home page</Link>
                    </button>
                </div>
            </div>
        </div>
    </CustomerWrapper>
  )
}
