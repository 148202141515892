import React from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import { useDispatch } from 'react-redux'
import { setCustomerSuccessModal } from '../../../../config/redux/slice/customerSlice'

export default function SuccessModal({successMsg}) {
    const dispatch = useDispatch()
    const closeModal = () => {
      dispatch(setCustomerSuccessModal({message: null}))
    }
  return (
    <ModalLayout classStyles={'modal-dialog-centered modal-sm'} onClick={closeModal}>
        <div className='modal-body'>
            <div style={{columnGap: '0.8rem'}} className='greeting d-flex justify-content-center mb-2 align-items-center'>
              <i style={{fontSize: '1.2rem'}} className={`mdi mdi-checkbox-marked-circle text-success`}></i>
              <p style={{fontSize: '1rem'}} className={`text-capitalize text-success`}>
                Successful!
              </p>
            </div>
        <div className='text-center mb-2' style={{color: '#31343D'}}>
            <p style={{fontSize: '1rem', lineHeight: '1.8rem'}} className='text-capitalize'>{successMsg}</p>
        </div>
        </div>
    </ModalLayout>
  )
}
