import React from 'react'
import AddFormLayout from '../../../layout/admin/AddFormLayout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { clearAll, onAddCity, onAddCountry, setAdminErrorMessageUser, setAdminLoadingUser, showAddCityFormFunc, showAddCountryFormFunc } from '../../../../config/redux/slice/adminSlice'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'
import SelectComponent from '../../../components/Form/SelectComponent'

export default function AddCity() {
  const dispatch = useDispatch()
    const navigate = useNavigate()
    const {register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})

    const {
            adminLoading,
            adminErrorMsg,
            adminSuccessMsg
    } = useSelector((state) => state.admin)

    const {
        token
    } = useSelector((state) => state.authData)

  const closeForm = () => {
      dispatch(showAddCityFormFunc({modal: false}))
    }

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        const res = await post_put_patch_request(
          "post",
            token,
            `${app_api.add_city}`,
            data,
            dispatch,
            setAdminLoadingUser,
            onAddCity,
            setAdminErrorMessageUser
        )
        if(res !== undefined && res.status < 400){
          reset()
        }
    }

  return (
    <AddFormLayout onClick={closeForm} heading_title='Add New Admin' sub_text='Fill in the fields below'>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="px-4 px-md-7">
            <div style={{rowGap: '1.2rem'}} className="row mb-2">

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor='name'>Name <span className="text-danger">*</span></label>
                  <input 
                  type="text" 
                  className="form-control input-lg" 
                  id="name"
                  {...register("name", {
                      required: {
                      value: true,
                      message: '*this field is required'
                      }
                  })
                  } 
                  />
                  {
                  errors?.name?.message &&
                  <div className="text-danger small mt-1">
                      {errors?.name?.message}
                  </div>
                  }
              </div> 
              </div>

            <div className="col-md-6">
                <div className="form-group">
                      <label htmlFor='city'>Country <span className="text-danger">*</span></label>
                      
                      <SelectComponent
                        // searchValue={search_cities}
                        register={register}
                        isReq={true}
                        fieldName={'countryId'}
                        fieldName2={'country'}
                        text='Select Country'
                        setValue={setValue}
                        setErrorFunc={setError}
                        getValueFunc={getValues}
                        errors={errors?.city?.message}
                        clearErrorsFunc={clearErrors}
                        getEndpoint={app_api.get_countries}
                        searchEndpoint={app_api.search_countries}
                      />
                      {/* {
                        errors?.country?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.country?.message}
                        </div>
                        } */}
                  </div> 
              </div>
            </div>

        </div>
        <div className="mt-4 px-4 d-flex align-items-center">
        <div>
          {adminLoading ? 
            <Spinner isForm={true}/> :
            <button type="submit" className="btn btn-primary me-3">Submit Form</button>
          }
        </div>
          <button disabled={adminLoading ? true : false} onClick={closeForm} type="button" className="btn btn-info">Cancel</button>
        </div>
      </form>
    </AddFormLayout>
  )
}
