import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearAll, clearResetBot, onGetSections, setAdminErrorMessageUser, setAdminLoadingUser, setDetails, showAddSectionFormFunc, showSectionModalFunc, showSectionStadiumMapFunc } from '../../../../config/redux/slice/adminSlice'
import { sectionAdminDatatable } from '../../../../helpers/dataTables'
import { app_api } from '../../../../utils/endpoints/app_api'
import { app_urls } from '../../../../utils/urls/app_urls'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { get_delete_request } from '../../../../helpers/request'
import { setBreadCrumbName } from '../../../../config/redux/slice/appSlice'

export default function SectionTable({stadiumObj}) {
   const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
            adminSuccessMsg
    } = useSelector((state) => state.admin)

    const {breadCrumbName, currentNavDetails} = useSelector((state) => state.app)

    const {
        token
    } = useSelector((state) => state.authData)

    const {
      sectionManagement:{newData, sectionsData}
    } = useSelector((state) => state.admin)

    useEffect(() => {
      dispatch(clearAll({}))
        sectionAdminDatatable(
          sectionsData,
        'sectionAdminTable',
         setItem
        )
    getAllSections()
    },[])

    useEffect(()=>{
      if(newData){
        sectionAdminDatatable(
          sectionsData,
        'sectionAdminTable',
         setItem
        )
        dispatch(clearResetBot())
      }   
      if(adminSuccessMsg ==='Section Added Successfully!'){
            getAllSections()
      }    
    }, [newData, adminSuccessMsg])

    const getAllSections = async () =>{
      dispatch(clearAll({}))
      const res = await get_delete_request(
        'get',
        token,
        `${app_api.get_sections(stadiumObj.id)}`,
        {},
        dispatch,
        setAdminLoadingUser,
        onGetSections,
        setAdminErrorMessageUser
      )
    }

    const setItem = (data, type) => {
      if(type == 'manage-subsections'){
        let subsection = currentNavDetails?.subs?.filter((option)=>app_urls.subsections_admin == option.url)
         dispatch(setDetails({details: data, type: 'section'}))
         dispatch(setBreadCrumbName([...breadCrumbName, {id:breadCrumbName.length + 1, name:subsection[0].name, url:subsection[0].url}]))
          navigate(app_urls.subsections_admin)
      }else if(type == 'delete' || type == 'edit'){
        dispatch(setDetails({details: data, type: 'section'}))
      dispatch(showSectionModalFunc({modal: true, type}))
      }
    }

  return (
    <DataTableWrapper 
        table_id='sectionAdminTable' 
        table_heading='Sections'
        table_buttons={<ActionButton/>}
    >
        <thead>
            <tr>
                {/* <th className="wd-5p">S/N</th> */}
                <th className="wd-15p">Name</th>
                <th className="wd-20p">Created At</th>
                <th className="wd-15p">Actions</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </DataTableWrapper>
  )
}

function ActionButton(){
  const dispatch = useDispatch()

  const {
      sectionManagement:{showAddSectionForm, showSectionStadiumMap}
    } = useSelector((state) => state.admin)
  return(
    <div style={{columnGap: '0.5rem', rowGap: '0.6rem'}} className='d-flex flex-wrap'>
      {!showAddSectionForm &&
      <button onClick={()=> dispatch(showAddSectionFormFunc({modal:true}))} className='btn btn-sm btn-outline-primary'>
        <i style={{fontSize: '1rem'}} className="mdi mdi-plus-circle me-1"></i>
        Add Section
      </button>}
      {!showSectionStadiumMap &&
      <button onClick={()=> dispatch(showSectionStadiumMapFunc({modal:true}))} className='btn btn-sm btn-outline-primary'>
        <i style={{fontSize: '1rem'}} className="mdi mdi-eye me-1"></i>
        View Stadium Map
      </button>}
    </div>
  )
}
