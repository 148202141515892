import React from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setCustomerErrorMessage, setCustomerLoading, setOrderValues, showOrderModalFunc } from '../../../../config/redux/slice/customerSlice'
import { useForm } from 'react-hook-form'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'

export default function RefundForm() {

    const {
        token
    } = useSelector((state) => state.authData)

    const {
            customerLoading,
            orderManagement:{orderDetails}
    } = useSelector((state) => state.customer)

const dispatch =  useDispatch()
const {register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})

    const closeModal = () => {
      dispatch(showOrderModalFunc({modal: false, type: null}))
    }

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
console.log('orderDetails.....#####.......',orderDetails);
//console.log('data.....#####.......',...data);
        const res = await post_put_patch_request(
          "post",
            token,
            `${app_api.request_refund}`,
            {orderId: orderDetails?.id, ...data},
            dispatch,
            setCustomerLoading,
            "",
            setCustomerErrorMessage
        )
        if(res !== undefined && res.status < 400){
          dispatch(onUpdate({message: 'Refund Requested Successfully!', type: 'order'}))
          dispatch(setOrderValues({propertyKey: 'newData', value: true}))
        }
    }

  return (
    <ModalLayout onClick={closeModal} heading={'Request Refund'}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="modal-body">
                <div className="px-4 px-md-7">
            <div style={{rowGap: '1.2rem'}} className="row mb-2">

            <div className="col-12">
                <div className="form-group">
                      <label htmlFor='reason'>Reason <span className="text-danger">*</span></label>
                      
                      <textarea 
                style={{resize: 'none'}}
                    className="form-control" 
                    id="reason" 
                    rows="6"
                    {...register("reason", {
                      required: {
                      value: true,
                      message: '*this field is required'
                      },
                    })} 
                ></textarea>
                 {
                      errors?.reason?.message &&
                      <div className="text-danger small mt-1">
                          {errors?.reason?.message}
                      </div>
                      }
                  </div> 
              </div>
            </div>

        </div>
            </div>
            <div className="modal-footer border-top-0 d-flex align-items-center">
                <div>
                {customerLoading ? 
                    <Spinner isForm={true}/> :
                    <button type="submit" className="btn btn-primary me-3">Request</button>
                }
                </div>
                <button onClick={closeModal} disabled={customerLoading ? true : false} type="button" className="btn btn-info">Reset Form</button>
            </div>
        </form>
    </ModalLayout>
  )
}
