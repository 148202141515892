import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    adminLoading: false,
    adminErrorMsg: null,
    adminSuccessMsg: null,
    isSidebarOpen: false,
    hasSelectListReachedBottom: false,
    loadingMoreSelectOptions: false,
    tableFilterQuery: null,
    adminManagement: {
        showAddAdminForm: false,
        newData: false,
    },
    userManagement: {
        showAddUserForm: false,
        newData: false,
        userDetails: {},
        showUserModal:{
            show: false,
            type: null 
        } 
    },
    countryManagement: {
        countriesData: [],
        newData: false,
        showAddCountryForm: false,
        countryDetails: {},
        showCountryModal: {
            show: false,
            type: null 
        } 
    },
    teamManagement: {
        newData: false,
        showAddTeamForm: false,
        teamDetails: {},
        showTeamModal: {
            show: false,
            type: null 
        }
    },
    listingManagement: {
        newData: false,
        listingDetails: {},
        showListingModal: {
            show: false,
            type: null 
        }
    },
    refundManagement: {
        newData: false,
        refundDetails: {},
        showRefundModal: {
            show: false,
            type: null 
        }
    },
    salesManagement: {
        newData: false,
        salesDetails: {},
        salesItemsData: [],
        singleSalesDetails: {},
        showSalesModal: {
          show: false,
          type: null,
        },
      },
    payoutManagement: {
        dueNewData: false,
        newData: false,
        payoutDueData: [],
        payoutData: [],
        payoutDueDetails: {},
        payoutDetails: {},
        updatedInfo:{},
        payoutEventId: '',
        showPayoutModal: {
            show: false,
            type: null 
        }
    },
    competitionManagement: {
        newData: false,
        showAddCompetitionForm: false,
        competitionDetails: {},
        showCompetitionModal: {
            show: false,
            type: null 
        }
    },
    cityManagement: {
        newData: false,
        showAddCityForm: false,
        cityDetails: {},
        showCityModal: {
            show: false,
            type: null 
        }
    },
    promocodeManagement: {
        newData: false,
        showAddPromocodeForm: false,
        promocodeDetails: {},
        showPromocodeModal: {
            show: false,
            type: null 
        }
    },
    venueManagement: {
        newData: false,
        showAddVenueForm: false,
        venueDetails: {},
        showVenueModal: {
            show: false,
            type: null 
        }
    },
    sectionManagement: {
        newData: false,
        showAddSectionForm: false,
        showSectionStadiumMap: false,
        sectionDetails: {},
        sectionsData: [],
        showSectionModal: {
            show: false,
            type: null 
        }
    },
    subSectionManagement: {
        newData: false,
        showSubStadiumMap: false,
        showAddSubSectionForm: false,
        subSectionDetails: {},
        subSectionsData: [],
        showSubsectionModal: {
            show: false,
            type: null 
        }
    },
    eventManagement: {
        newData: false,
        showAddEventForm: false,
        eventDetails: {},
        selectedMapSection: '',
        showEventModal: {
            show: false,
            type: null 
        }
    },
    orderManagement: {
        newData: false,
        orderDetails: {}
    },
}

export const adminSlice = createSlice({
    name: 'adminApp',
    initialState,
    reducers: {
        logoutAdminUser: (state, action) => {
              return initialState
        },
        clearAll: (state, action) => {
            state.adminLoading = false
            state.adminErrorMsg = null
            state.adminSuccessMsg = null
        },
        clearResetBot: (state) => {
            state.countryManagement.newData = false
            state.cityManagement.newData = false
            state.promocodeManagement.newData = false
            state.userManagement.newData = false
            state.adminManagement.newData = false
            state.venueManagement.newData = false
            state.eventManagement.newData = false
            state.teamManagement.newData = false
            state.competitionManagement.newData = false
            state.sectionManagement.newData = false
            state.subSectionManagement.newData = false
            state.payoutManagement.newData = false
            state.payoutManagement.dueNewData = false
            state.listingManagement.newData = false
            state.refundManagement.newData = false
            state.salesManagement.newData = false

        },
        setAdminLoadingUser: (state, action) => {
            state.adminLoading = action?.payload?.loading
        },
        setAdminErrorMessageUser: (state, action) => {
            let msg = action.payload.message
            state.adminLoading = false
            state.adminErrorMsg = msg
        },
        setAdminPromoCodeErrorMessageUser: (state, action) => {
            let msg = action.payload.message
            state.adminLoading = false
            state.adminErrorMsg = msg
        },
        setAdminSuccessMessageUser: (state, action) => {
            let msg = action.payload.message
            state.adminLoading = false
            state.adminSuccessMsg = msg
        },
        setToggleSidebar: (state, action) => {
            state.isSidebarOpen = !state.isSidebarOpen
        },
        onAddUser: (state, action) => {
            state.adminLoading = false
            state.adminSuccessMsg = 'User Added Successfully!'
            state.adminErrorMsg = null
            state.userManagement.newData = true
        },
        onAddAdmin: (state, action) => {
            state.adminLoading = false
            state.adminSuccessMsg = 'Admin Added Successfully!'
            state.adminErrorMsg = null
            state.adminManagement.newData = true
        },
        onAddCountry: (state, action) => {
            state.adminLoading = false
            state.adminSuccessMsg = 'Country Added Successfully!'
            state.adminErrorMsg = null
            state.countryManagement.newData = true
        },
        onAddCity: (state, action) => {
            state.adminLoading = false
            state.adminSuccessMsg = 'City Added Successfully!'
            state.adminErrorMsg = null
            state.cityManagement.newData = true
        },
        onAddPromocode: (state, action) => {
            state.adminLoading = false
            state.adminSuccessMsg = action.payload.message
            state.adminErrorMsg = null
            state.promocodeManagement.newData = true
        },
        onAddTeam: (state, action) => {
            state.adminLoading = false
            state.adminSuccessMsg = 'Team Added Successfully!'
            state.adminErrorMsg = null
            state.teamManagement.newData = true
        },
        onAddCompetition: (state, action) => {
            state.adminLoading = false
            state.adminSuccessMsg = 'Competition Added Successfully!'
            state.adminErrorMsg = null
            state.competitionManagement.newData = true
        },
        onAddEvent: (state, action) => {
            state.adminLoading = false
            state.adminSuccessMsg = 'Event Added Successfully!'
            state.adminErrorMsg = null
            state.eventManagement.newData = true
        },
        onAddVenue: (state, action) => {
            state.adminLoading = false
            state.adminSuccessMsg = 'Venue Added Successfully!'
            state.adminErrorMsg = null
            state.venueManagement.newData = true
        },
        onAddStadium: (state, action) => {
            state.adminLoading = false
            state.adminSuccessMsg = 'Stadium Added Successfully!'
            state.adminErrorMsg = null
            state.venueManagement.newData = true
        },
        onAddSection: (state, action) => {
            state.adminLoading = false
            state.adminSuccessMsg = 'Section Added Successfully!'
            state.adminErrorMsg = null
        },
        onAddSubSection: (state, action) => {
            state.adminLoading = false
            state.adminSuccessMsg = 'SubSection Added Successfully!'
            state.adminErrorMsg = null
        },
        showAddAdminFormFunc: (state, action) => {
            state.adminManagement.showAddAdminForm = action.payload.modal
        },
        showAddUserFormFunc: (state, action) => {
            state.userManagement.showAddUserForm = action.payload.modal
        },
        showAddCountryFormFunc: (state, action) => {
            state.countryManagement.showAddCountryForm= action.payload.modal
        },
        showAddCityFormFunc: (state, action) => {
            state.cityManagement.showAddCityForm = action.payload.modal
        },
        showAddPromocodeFormFunc: (state, action) => {
            state.promocodeManagement.showAddCityForm = action.payload.modal
        },
        showAddVenueFormFunc: (state, action) => {
            state.venueManagement.showAddVenueForm= action.payload.modal
        },
        showAddSectionFormFunc: (state, action) => {
            state.sectionManagement.showAddSectionForm= action.payload.modal
        },
        showSectionStadiumMapFunc: (state, action) => {
            state.sectionManagement.showSectionStadiumMap= action.payload.modal
        },
        showAddSubSectionFormFunc: (state, action) => {
            state.subSectionManagement.showAddSubSectionForm= action.payload.modal
        },
        showSubStadiumMapFunc: (state, action) => {
            state.subSectionManagement.showSubStadiumMap= action.payload.modal
        },
        showAddEventFormFunc: (state, action) => {
            state.eventManagement.showAddEventForm= action.payload.modal
        },
        showAddTeamFormFunc: (state, action) => {
            state.teamManagement.showAddTeamForm = action.payload.modal
        },
        showAddCompetitionFormFunc: (state, action) => {
            state.competitionManagement.showAddCompetitionForm = action.payload.modal
        },
        onGetCountries: (state, action) => {
            state.adminSuccessMsg = ''
            state.countryManagement.countriesData = action.payload
            state.countryManagement.newData = true
        },
        onGetSections: (state, action) => {
            state.adminSuccessMsg = ''
            state.sectionManagement.sectionsData = action.payload
            state.sectionManagement.newData = true
        },
        onGetPayoutsDue: (state, action) => {
            state.adminSuccessMsg = ''
            state.payoutManagement.payoutDueData = action.payload
            state.payoutManagement.dueNewData = true
        },
        onGetPayouts: (state, action) => {
            state.adminSuccessMsg = ''
            state.payoutManagement.payoutData = action.payload
            state.payoutManagement.newData = true
        },
        onGetSubSections: (state, action) => {
            state.adminSuccessMsg = ''
            state.subSectionManagement.subSectionsData = action.payload
            state.subSectionManagement.newData = true
        },
        onGetProfileSales: (state, action) => {
            state.customerSuccessMsg = ''
            state.profileSaleManagement.profileSaleData = action.payload
            state.profileSaleManagement.newData = true
          },
        setHasSelectListReachedBottom: (state, action) => {
            state.hasSelectListReachedBottom = action.payload
        },
        setLoadingMoreSelectOptions: (state, action) => {
            state.loadingMoreSelectOptions = action.payload
        },
        setCustomerLoading: (state, action) => {
            state.customerLoading = action?.payload?.loading
          },
          setCustomerErrorMessage: (state, action) => {
            let msg = action.payload.message
            state.customerLoading = false
            state.customerErrorMsg = msg
          },
          setCustomerSuccessMessage: (state, action) => {
            let msg = action.payload.message
            state.customerLoading = false
            state.customerSuccessMsg = msg
          },
        setDetails: (state, action) => {
            // debugger
            {console.log("sssssssssss",action.payload.details)}
            if(action.payload.type == 'venue'){
                state.venueManagement.venueDetails = action.payload.details
            }else if(action.payload.type == 'user'){
                state.userManagement.userDetails = action.payload.details
            }else if(action.payload.type == 'section'){
                state.sectionManagement.sectionDetails = action.payload.details
            }else if(action.payload.type == 'subsection'){
                state.subSectionManagement.subSectionDetails = action.payload.details
            }else if(action.payload.type == 'competition'){
                state.competitionManagement.competitionDetails = action.payload.details
            }else if(action.payload.type == 'country'){
                state.countryManagement.countryDetails = action.payload.details
            }else if(action.payload.type == 'city'){
                state.cityManagement.cityDetails = action.payload.details
            }else if(action.payload.type == 'promocode'){
                state.promocodeManagement.promocodeDetails = action.payload.details
            }
            else if(action.payload.type == 'event'){
                state.eventManagement.eventDetails = action.payload.details
            }else if(action.payload.type == 'team'){
                state.teamManagement.teamDetails = action.payload.details
            }else if(action.payload.type == 'listing'){
                state.listingManagement.listingDetails = action.payload.details
            }else if(action.payload.type == 'order'){
                state.orderManagement.orderDetails = action.payload.details
            }else if(action.payload.type == 'payoutDue'){
                state.payoutManagement.payoutDueDetails = action.payload.details
            }
            else if(action.payload.type == 'refund'){
                state.refundManagement.refundDetails = action.payload.details
            }
            else if (action.payload.type === 'sales') {
                state.salesManagement.salesDetails = action.payload.details
              }
              else if (action.payload.type === 'sales-items') {
                state.salesManagement.singleSalesDetails = action.payload.details
              }
            
        },
        onUpdate: (state, action) => {
            state.adminSuccessMsg = action.payload.message
            if(action.payload.type == 'venue'){
                state.venueManagement.newData = true
                state.venueManagement.showVenueModal = {
                    show: false,
                    type: null
                }
            }else if(action.payload.type == 'user'){
                state.userManagement.newData = true
                state.userManagement.showUserModal = {
                    show: false,
                    type: null
                }
            }else if(action.payload.type == 'section'){
                state.sectionManagement.showSectionModal = {
                    show: false,
                    type: null
                }
            }else if(action.payload.type == 'subsection'){
                state.subSectionManagement.showSubsectionModal = {
                    show: false,
                    type: null
                }
            }else if(action.payload.type == 'competition'){
                state.competitionManagement.newData = true
                state.competitionManagement.showCompetitionModal = {
                    show: false,
                    type: null
                }
            }else if(action.payload.type == 'country'){
                state.countryManagement.newData = true
                state.countryManagement.showCountryModal = {
                    show: false,
                    type: null
                }
            }else if(action.payload.type == 'city'){
                state.cityManagement.newData = true
                state.cityManagement.showCityModal = {
                    show: false,
                    type: null
                }
            }else if(action.payload.type == 'promocode'){
                state.promocodeManagement.newData = true
                state.promocodeManagement.showPromocodeModal = {
                    show: false,
                    type: null
                }
            }else if(action.payload.type == 'event'){
                state.eventManagement.newData = true
                state.eventManagement.showEventModal = {
                    show: false,
                    type: null
                }
            }else if(action.payload.type == 'team'){
                state.teamManagement.newData = true
                state.teamManagement.showTeamModal = {
                    show: false,
                    type: null
                }
            }
            else if(action.payload.type == 'payout'){
                state.payoutManagement.newData = true
                state.payoutManagement.showPayoutModal = {
                    show: false,
                    type: null
                }
            }
            else if(action.payload.type == 'listing'){
                state.listingManagement.newData = true
                state.listingManagement.showListingModal = {
                    show: false,
                    type: null
                }
            }
            else if(action.payload.type == 'refund'){
                state.refundManagement.newData = true
                state.refundManagement.showRefundModal = {
                    show: false,
                    type: null
                }
            }
            else if (action?.payload?.type == 'sales') {
                state.salesManagement.newData = true
                state.salesManagement.showSalesModal = {
                  show: false,
                  type: null,
                }
              }
        },
        showUserModalFunc: (state, action) =>{
            state.userManagement.showUserModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showCountryModalFunc: (state, action) =>{
            state.countryManagement.showCountryModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showPayoutModalFunc: (state, action) =>{
            state.payoutManagement.showPayoutModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showPayoutModalFunc1: (state, action) =>{
            state.payoutManagement.showPayoutModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showPayoutModalFunc2: (state, action) =>{
            state.payoutManagement.showPayoutModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showCityModalFunc: (state, action) =>{
            state.cityManagement.showCityModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },showPromocodeModalFunc: (state, action) =>{
            state.promocodeManagement.showPromocodeModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showListingModalFunc: (state, action) =>{
            state.listingManagement.showListingModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showRefundModalFunc: (state, action) =>{
            state.refundManagement.showRefundModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showVenueModalFunc: (state, action) =>{
            state.venueManagement.showVenueModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showEventModalFunc: (state, action) =>{
            state.eventManagement.showEventModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showCompetitionModalFunc: (state, action) =>{
            state.competitionManagement.showCompetitionModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showSectionModalFunc: (state, action) =>{
            state.sectionManagement.showSectionModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showSubsectionModalFunc: (state, action) =>{
            state.subSectionManagement.showSubsectionModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showTeamModalFunc: (state, action) =>{
            state.teamManagement.showTeamModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showSalesModalFunc: (state, action) => {
            state.salesManagement.showSalesModal = {
              show: action.payload.modal,
              type: action.payload.type,
            }
            console.log('show', state.salesManagement.showSalesModal);
          },
        setPayoutValues: (state, action) =>{
            state.payoutManagement[action.payload.propertyKey] = action.payload.value
        },
        setPayoutValues1: (state, action) =>{
            state.payoutManagement[action.payload.propertyKey] = action.payload.value
        },
        setPayoutValues2: (state, action) =>{
            state.payoutManagement[action.payload.propertyKey] = action.payload.value
        },
        setFilterQuery: (state, action) =>{
            state.tableFilterQuery = action.payload
        },
        setSalesValues: (state, action) => {
            state.salesManagement[action.payload.propertyKey] = action.payload.value
          },
         
    }

})

// Action creators are generated for each case reducer function
export const {
    clearAll,
    clearResetBot,
    setAdminErrorMessageUser,
    setAdminPromoCodeErrorMessageUser,
    setAdminLoadingUser,
    setCustomerErrorMessage,
  setCustomerLoading,
    setAdminSuccessMessageUser,
    logoutAdminUser,
    setToggleSidebar,
    onAddUser,
    onAddAdmin,
    onAddCountry,
    onAddCity,
    onAddStadium,
    onAddSection,
    onAddSubSection,
    onAddVenue,
    onAddTeam,
    onAddCompetition,
    onAddEvent,
    onAddPromocode,
    showAddAdminFormFunc,
    showAddUserFormFunc,
    showAddCountryFormFunc,
    showAddCityFormFunc,
    showAddVenueFormFunc,
    showAddSectionFormFunc,
    showSectionStadiumMapFunc,
    showAddSubSectionFormFunc,
    showSubStadiumMapFunc,
    showAddTeamFormFunc,
    showAddCompetitionFormFunc,
    showAddEventFormFunc,
    onGetCountries,
    onGetSections,
    onGetSubSections,
    setHasSelectListReachedBottom,
    setLoadingMoreSelectOptions,
    setDetails,
    onUpdate,
    showUserModalFunc,
    showCityModalFunc,
    showPromocodeModalFunc,
    showCompetitionModalFunc,
    showCountryModalFunc,
    showSectionModalFunc,
    showSubsectionModalFunc,
    showVenueModalFunc,
    showEventModalFunc,
    showTeamModalFunc,
    showListingModalFunc,
    showRefundModalFunc,
    setPayoutValues,
    setPayoutValues1,
    setPayoutValues2,
    onGetPayouts,
    setSalesValues,
    onGetPayoutsDue,
    showPayoutModalFunc,
    showPayoutModalFunc1,
    showPayoutModalFunc2,
    showSalesModalFunc,
    onGetProfileSales,
    setFilterQuery
} = adminSlice.actions

export default adminSlice.reducer