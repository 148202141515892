import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, clearResetBot, onGetProfileListings, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading, setDetails, setOrderValues, showOrderModalFunc, setSalesValues, showSalesModalFunc } from '../../../../config/redux/slice/customerSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { app_urls } from '../../../../utils/urls/app_urls'
import { Link, useNavigate } from 'react-router-dom'
import { get_delete_request } from '../../../../helpers/request'
import { formatNumber } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
import ConfirmDelivery from './ConfirmDelivery'
import DeliveryAddress from './deliveryAdress'
import moment from 'moment'
import Pagination from "react-js-pagination";
import { onUpdate } from '../../../../config/redux/slice/customerSlice'
import ViewTicket from '../ticket_management/ViewTicket';

// import RefundForm from './RefundForm';
import { format } from 'date-fns';
export default function CompletedTable() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { token } = useSelector((state) => state.authData)
    const [pending_data, setPending_data] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [refun, setRefun] = useState('');
    const [nestedExpandedRows, setNestedExpandedRows] = useState({});
    const [showDbPopup, setShowDbPopup] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTicket, setSelectedTicket] = useState(null);
    const {salesManagement: { salesItemsSubData, showSalesModal: { show, type }, newData }} = useSelector((state) => state.customer)


    useEffect(() => {
        getPendingList()
    }, [])


    const handleViewTicket = (ticket) => {
      setSelectedTicket(ticket);
      dispatch(showSalesModalFunc({ modal: true, type: 'view' }))

    };

    const getPendingList = async () => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_user_sales}?deliveryStatus=DELIVERED&page=1&pageSize=10&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        console.log("🚀 ~ getPendingList ~ res:", res)
        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal
            console.log("🚀 ~ getPendingList ~ res:...................", pending_list)
            console.log("🚀 ~ getPendingList ~ res:...................", totalCount)
            setPending_data(pending_list);
            setRecordsTotal(totalCount)
        }
        setLoading(false);
    }

    // const toggleRow = (index) => {
    //     setExpandedRows(prevExpandedRows => ({
    //         ...prevExpandedRows,
    //         [index]: !prevExpandedRows[index]
    //     }));
    //     setNestedExpandedRows({});
    // };



    const confirmClick = (index, data) => {
        console.log("🚀 ~ confirmClick ~ data:", data)
        setItem(data, 'confirm')
    }
    

    const toggleRow = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows(prevExpandedRows => ({
            ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
            [index]: !prevExpandedRows[index]
        }));
        setNestedExpandedRows({});
    };

    const toggleNestedRow = (mainIndex, nestedIndex, event) => {
        event.preventDefault();
        event.stopPropagation();
        setNestedExpandedRows(prevState => ({
            ...prevState,
            [mainIndex]: {
                ...prevState[mainIndex],
                [nestedIndex]: !prevState[mainIndex]?.[nestedIndex]
            }
        }));
    };



    // const toggleNestedRow = (mainIndex, nestedIndex) => {
    //     setNestedExpandedRows(prevState => ({
    //         ...prevState,
    //         [mainIndex]: {
    //             ...prevState[mainIndex],
    //             [nestedIndex]: !prevState[mainIndex]?.[nestedIndex]
    //         }
    //     }));
    // };

    const setItem = (data, type) => {
        console.log(data)
        dispatch(setDetails({ details: data, type: 'sales-items' }))
        if (type == 'confirm') {
            dispatch(showSalesModalFunc({ modal: true, type }))
        }
    }
    const handleDeliveryaddressClick = (item) => {
        setShowDbPopup(true)
        setSelectedItem(item);
    }


    const handlePageChange = async (pageNumber) => {
        setLoading(true);
        dispatch(clearAll({}));

        // Calculate the page offset
        const pageSize = 10; // Adjust this value as per your requirements

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_user_sales}?deliveryStatus=DELIVERED&page=${pageNumber}&pageSize=${pageSize}&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        console.log("🚀 ~ handlePageChange ~ res:", res);

        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            console.log("🚀 ~ handlePageChange ~ pending_list:...................", pending_list);
            console.log("🚀 ~ handlePageChange ~ totalCount:...................", totalCount);

            setPending_data(pending_list);
            setRecordsTotal(totalCount);
        }

        setLoading(false);
        setActivePage(pageNumber);
    };
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const getFileNameFromUrl = (url) => {
        return url.substring(url.lastIndexOf('/') + 1);
    };
    const handleDownloadClick = async (fileUrl, fileName) => {
        if (fileUrl) {
            try {
                const response = await fetch(fileUrl);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = url;
                const fileName = getFileNameFromUrl(fileUrl);
                link.setAttribute('download', fileName); // Set the file name
                document.body.appendChild(link); // Append to the body
                link.click(); // Trigger the download
                document.body.removeChild(link); // Remove the link after the download

                // Release the object URL
                window.URL.revokeObjectURL(url);
                dispatch(onUpdate({ message: 'File downloaded successfully', type: 'file' }))
            } catch (error) {
                console.error('Error downloading the file:', error);
            }
        } else {
            console.error('No file URL provided.');
        }
    };

    const handleSearchChange = async (event) => {
        // const value = event.target.value;
        // setSearchTerm(value);
        setLoading(true);
        dispatch(clearAll({}));

        // Calculate the page offset
        const pageSize = 10; // Adjust this value as per your requirements

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_user_sales}?deliveryStatus=DELIVERED&search=${searchTerm}&page=1&pageSize=${pageSize}&draw=1&q=&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        console.log("🚀 ~ handleSearchChange ~ res:", res);

        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            console.log("🚀 ~ handleSearchChange ~ pending_list:...................", pending_list);
            console.log("🚀 ~ handleSearchChange ~ totalCount:...................", totalCount);

            setPending_data(pending_list);
            setRecordsTotal(totalCount);
        }

        setLoading(false);
        setActivePage(1);
    }

    return (

        <>
            <div className="form-group search-input-top">
                <input type="text" className="form-control" id="" placeholder="Search..." onChange={handleInputChange} />
                <button className="btn btn-primary me-3 ml-2" style={{ marginLeft: "10px" }} onClick={handleSearchChange}>Search</button>
            </div>
            <div className="table-responsive web-show-tbl">
                <table className="table table-striped mt-3 tbl-pending">
                    <thead>
                        <tr>
                            <th scope="col" className='no-wrap'>No of Sales</th>
                            <th scope="col" className='no-wrap'>Event</th>
                            <th scope="col" className='no-wrap'>Event Date</th>
                            <th scope="col" className='no-wrap'>Total Ticket No.</th>
                            <th scope="col" className='no-wrap'>Total Amount</th>
                            <th scope="col" className='no-wrap'>Expand</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                            </tr>
                        ) : pending_data.length > 0 ? (
                            pending_data.map((item, index) => (
                                <>
                                    <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                                        <td>{item?.orders?.length || 0}</td>
                                        <td>{item?.eventDetails?.name}</td>
                                        <td>{moment(item?.eventDetails?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                        <td>{item?.totalNumberOfTickets}</td>
                                        <td>{item?.totalAmount ? `${strText.currency_symbol}${formatNumber(item?.totalAmount)}` : 'N/A'}</td>
                                        <td> <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )}</a>

                                        </td>
                                    </tr>
                                    {console.log("expanded", expandedRows)}
                                    {(expandedRows[index] && item.orders.length > 0) && (
                                        <tr>
                                            <td colSpan="8">
                                                <table className="table table-striped tbl-inner-width">
                                                    <thead>
                                                        <tr>
                                                            <th className='no-wrap'>Order Id</th>
                                                            <th className='no-wrap'>No. Of Ticket</th>
                                                            <th className='no-wrap'>Ticket Price</th>
                                                            <th className='no-wrap'>Total Amount</th>
                                                            <th className='no-wrap'>Sale Date </th>
                                                            <th className='no-wrap'>Delivery Status</th>
                                                            <th className='no-wrap'>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    {item.orders.map((item, orderIndex) => (
                                                        <tbody>
                                                            <tr key={orderIndex}>
                                                                <td className={nestedExpandedRows[index]?.[orderIndex] ? 'fontWeight' : ""}>{item?.orderRef}</td>
                                                                <td>{item?.OrderItems?.length || 0}</td>
                                                                <td>{item?.pricePerTicket ? `${strText.currency_symbol}${formatNumber(item?.pricePerTicket)}` : 'N/A'}</td>
                                                                <td>{item?.totalAmountWithoutCommission ? `${strText.currency_symbol}${formatNumber(item?.totalAmountWithoutCommission)}` : 'N/A'}</td>
                                                                <td>{item?.createdAt ? (format(new Date(item?.createdAt), 'EEE dd MMM yyyy').toUpperCase()) : 'N/A'}</td>
                                                                <td>{item?.deliveryStatus}</td>
                                                                <td>
                                                                    <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, orderIndex, event)}>
                                                                        {nestedExpandedRows[index]?.[orderIndex] ? (
                                                                            <i className="fa fa-angle-up fa-sizeset"></i>
                                                                        ) : (
                                                                            <i className="fa fa-angle-down fa-sizeset"></i>
                                                                        )}
                                                                    </a>
                                                                    {/* <span className='delivery-add-link' onClick={() => handleDeliveryaddressClick(item)}>Delivery Address</span> */}
                                                                </td>
                                                            </tr>

                                                            {nestedExpandedRows[index]?.[orderIndex] && (
                                                                <tr>
                                                                    <td colSpan="8">
                                                                        <table className="table table-bordered tbl-subinner-width">
                                                                            <thead>
                                                                                <tr>
                                                                                    {/* <th className='no-wrap'>Ticket ID</th> */}
                                                                                    <th className='no-wrap'>Ticket Type</th>
                                                                                    <th className='no-wrap'>Section</th>
                                                                                    <th className='no-wrap'>Block</th>
                                                                                    <th className='no-wrap'>Row</th>
                                                                                    {/* <th className='no-wrap'>Seat Number</th> */}
                                                                                    {/* <th className='no-wrap'>Delivery Status</th> */}
                                                                                    <th className='no-wrap'>Delivery Date</th>
                                                                                    <th className='no-wrap'>
                                                                                        {(item?.ticketType === 'ELECTRONIC') ? 'Download' : 'Track Id'}
                                                                                    </th>                                                                                    {/* <th className='no-wrap'>Action</th> */}
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {/* {item.OrderItems.map((item, itemIndex) => ( */}
                                                                                <tr key={orderIndex}>
                                                                                    {/* <td>{item?.ticket.ref}</td> */}
                                                                                    <td>{item?.ticketsType}</td>
                                                                                    <td>
                                                                                        {item?.sectionName}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item?.subSectionName !== null ? item?.subSectionName : ""}

                                                                                    </td>
                                                                                    <td>{item?.row}</td>
                                                                                    {/* <td>{item?.seats}</td> */}
                                                                                    {/* <td>{item?.deliveryStatus}</td> */}
                                                                                    <td>{item?.deliveryDate ? format(new Date(item?.deliveryDate), 'EEE dd MMM yyyy').toUpperCase() : 'N/A'}</td>
                                                                                    {/* <td><a title='Confirm Delivery' class="btn btn-sm bg-success text-white btn-icon confirm-delivery" onClick={() => confirmClick(index, item)}>
                                                                                        <i class="fa fa-check"></i>
                                                                                    </a></td> */}
                                                                                    {/* <td>{item?.trackId}</td> */}
                                                                                    {console.log('file', item?.ticket)}
                                                                                    <td>
                                                                                        {item?.ticketType === 'ELECTRONIC' ? (
                                                                                            item.OrderItems.map((orderItem, index) => (
                                                                                                <div key={orderItem.id}>
                                                                                                    {/* <a
                                                                                                        href={orderItem.ticket?.file} // Ensure this URL is correctly set in your data
                                                                                                        download
                                                                                                        className="btn btn-sm bg-primary text-white btn-icon"
                                                                                                        title={`Download Ticket ${index + 1}`}
                                                                                                    >
                                                                                                        <i className="fa fa-download"></i> Download Ticket {index + 1}
                                                                                                    </a> */}
                                                                                                    <span className='delivery-add-link' onClick={() => 
                                                                                                    handleViewTicket(orderItem.ticket)
                                                                                                       }>View Ticket {index + 1}
                                                                                                    </span>

                                                                                                    {/* Add a line break between links if there are multiple */}
                                                                                                </div>
                                                                                            ))
                                                                                        ) : (
                                                                                            item?.trackId
                                                                                        )}
                                                                                    </td>

                                                                                </tr>
                                                                                {/* ))} */}
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>))}
                                                </table>
                                            </td>
                                        </tr>
                                    )}


                                </>

                            ))

                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center py-4">No Data Found</td>
                            </tr>
                        )}
                    </tbody>
                    {(show && type == 'confirm') &&
                        <ConfirmDelivery />
                    }
                    {(show && type == 'view')  &&
                
                    <ViewTicket ticketObj={selectedTicket} type={'viewTicket'} />
                }

                </table>
            </div>


            <div className="mob-table-section">
                {loading ? (
                    <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>

                ) : pending_data.length > 0 ? (
                    pending_data.map((item, index) => (
                        <>

                            <div className={`mob-table-sec-outer ${expandedRows[index] ? 'fontWeight' : ''}`} key={index}>
                                <div className="position-relative">
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">No of Sales</h3>
                                        <div className="mob-tbl-value">{item?.orders?.length || 0}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event</h3>
                                        <div className="mob-tbl-value" title={item?.eventDetails?.name}>{item?.eventDetails?.name}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event Date</h3>
                                        <div className="mob-tbl-value">{moment(item?.eventDetails?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Total Ticket No.</h3>
                                        <div className="mob-tbl-value">{item?.totalNumberOfTickets}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head"> Total Amount</h3>
                                        <div className="mob-tbl-value">{item?.totalAmount ? `${strText.currency_symbol}${formatNumber(item?.totalAmount)}` : 'N/A'}</div>
                                    </div>
                                    <div className="mob-table-sec-expand">
                                        <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )}
                                        </a>
                                    </div>

                                </div>


                                {console.log("expanded", expandedRows)}
                                {(expandedRows[index] && item.orders.length > 0) && (
                                    <>

                                        {item.orders.map((item, orderIndex) => (
                                            <div className="mob-tbl-inner-sec" key={orderIndex}>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Order Id</h3>
                                                    <div className={`mob-tbl-value ${nestedExpandedRows[index]?.[orderIndex] ? 'fontWeight' : ''}`}>{item?.orderRef}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">No. Of Ticket</h3>
                                                    <div className="mob-tbl-value">{item?.OrderItems?.length || 0}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Ticket Price</h3>
                                                    <div className="mob-tbl-value ">{item?.pricePerTicket ? `${strText.currency_symbol}${formatNumber(item?.pricePerTicket)}` : 'N/A'}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Total Amount</h3>
                                                    <div className="mob-tbl-value ">{item?.totalAmountWithoutCommission ? `${strText.currency_symbol}${formatNumber(item?.totalAmountWithoutCommission)}` : 'N/A'}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Sale Date</h3>
                                                    <div className="mob-tbl-value">{item?.createdAt ? (format(new Date(item?.createdAt), 'EEE dd MMM yyyy').toUpperCase()) : 'N/A'}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Delivery Status</h3>
                                                    <div className="mob-tbl-value">{item?.deliveryStatus}</div>
                                                </div>
                                                <div className="mob-table-sec-action">
                                                    <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, orderIndex, event)}>
                                                        {nestedExpandedRows[index]?.[orderIndex] ? (
                                                            <i className="fa fa-angle-up fa-sizeset"></i>
                                                        ) : (
                                                            <i className="fa fa-angle-down fa-sizeset"></i>
                                                        )}
                                                    </a>
                                                    {/* <span className='delivery-add-link delivery-fontsize' onClick={() => handleDeliveryaddressClick(item)}>Delivery Address</span> */}

                                                </div>

                                                {nestedExpandedRows[index]?.[orderIndex] && (
                                                    <div className="mob-tb-subinner" key={orderIndex}>
                                                        <div className="mob-table-sec">
                                                            <h3 className="mob-tbl-head">Ticket Type</h3>
                                                            <div className="mob-tbl-value">{item?.ticketsType}</div>
                                                        </div>
                                                        <div className="mob-table-sec">
                                                            <h3 className="mob-tbl-head">Section</h3>
                                                            <div className="mob-tbl-value" title={item?.sectionName}>{item?.sectionName}</div>
                                                        </div>
                                                        <div className="mob-table-sec">
                                                            <h3 className="mob-tbl-head">Block</h3>
                                                            <div className="mob-tbl-value" >{item?.subSectionName !== null ? item?.subSectionName : ""}</div>
                                                        </div>
                                                        <div className="mob-table-sec">
                                                            <h3 className="mob-tbl-head">Row</h3>
                                                            <div className="mob-tbl-value">{item?.row}</div>
                                                        </div>
                                                        {/* <div className="mob-table-sec">
                                                            <h3 className="mob-tbl-head">Seat Number</h3>
                                                            <div className="mob-tbl-value">{item?.seats}</div>
                                                        </div> */}
                                                        <div className="mob-table-sec">
                                                            <h3 className="mob-tbl-head">Delivery Date</h3>
                                                            <div className="mob-tbl-value">{item?.deliveryDate ? format(new Date(item?.deliveryDate), 'EEE dd MMM yyyy').toUpperCase() : 'N/A'}</div>
                                                        </div>
                                                        <div className="mob-table-sec">
                                                            <h3 className="mob-tbl-head">Track Id</h3>
                                                            <div className="mob-tbl-value">{item?.trackId}</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </>)}
                            </div>


                        </>

                    ))

                ) : (
                    <div className="text-center py-4">No Data Found</div>

                )}

                {(show && type == 'confirm') &&
                    <ConfirmDelivery />
                }
                {console.log("......................XDJN",show,type)}
                
            </div>
            <DeliveryAddress setShowDbPopup={setShowDbPopup} showDbPopup={showDbPopup} pending_data={pending_data} selectedItem={selectedItem} />
            {recordsTotal > 0 ?
                <div className='pagination-wrapper'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={recordsTotal}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
                : ""}
        </>
    );
}
