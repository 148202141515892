import React, { useEffect } from 'react'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  clearResetBot,
  onGetProfileOrders,
  setCustomerErrorMessage,
  setCustomerLoading,
  setDetails,
} from '../../../../config/redux/slice/customerSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { ordersProfileDatatable } from '../../../../helpers/customer_dataTables'
import { Link, useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import { get_delete_request } from '../../../../helpers/request'

export default function OrdersTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { listingId } = useNavigate()

  const { token } = useSelector((state) => state.authData)

  const {
    customerSuccessMsg,
    profileOrderManagement: { newData, profileOrderDetails, profileOrderData },
  } = useSelector((state) => state.customer)

  useEffect(() => {
    dispatch(clearAll({}))
    ordersProfileDatatable(profileOrderData, 'ordersProfileTable', setItem)
    getOrders()
  }, [])

  useEffect(() => {
    if (newData) {
      ordersProfileDatatable(profileOrderData, 'ordersProfileTable', setItem)
      dispatch(clearResetBot())
    }
  }, [newData])

  const setItem = (data, type) => {
    dispatch(setDetails({ details: data, type: 'order' }))
    if (type == 'details') {
      navigate(app_urls.user_listings_details)
    } else if (type == 'view-items') {
      navigate(app_urls.order_items)
    }
  }

  const getOrders = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_user_orders}?page=1&pageSize=5`,
      {},
      dispatch,
      setCustomerLoading,
      onGetProfileOrders,
      setCustomerErrorMessage,
      true
    )
  }
  return (
    <DataTableWrapper
      table_id="ordersProfileTable"
      table_heading="Orders"
      table_buttons={
        <Link to={app_urls.orders} className="text-decoration-none">
          View all orders
        </Link>
      }
    >
      <thead>
        <tr>
          {/* <th className="wd-5p">S/N</th> */}
          <th className="wd-15p">No of Orders</th>
          <th className="wd-10p">Event Name</th>
          <th className="wd-10p">Event Date </th>
          <th className="wd-10p">No of Tickets</th>
          <th className="wd-10p">Total Amount</th>
          {/* <th className="wd-15p">Actions</th> */}
        </tr>
      </thead>
      <tbody></tbody>
    </DataTableWrapper>
  )
}
