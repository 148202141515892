import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { itemsCustomerDatatable } from '../../../../helpers/customer_dataTables'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { app_urls } from '../../../../utils/urls/app_urls'
import { clearAll, clearResetBot, setDetails, showOrderModalFunc } from '../../../../config/redux/slice/customerSlice'
import ConfirmDelivery from './ConfirmDelivery'

export default function SingleOrderTable() {
   const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        token
    } = useSelector((state) => state.authData)

    const {
      orderManagement:{orderItemsData, showOrderModal: {show, type}, newData}
    } = useSelector((state) => state.customer)

    useEffect(() => {
      dispatch(clearAll({}))
        itemsCustomerDatatable(
          orderItemsData,
        'itemsCustomerTable',
        setItem
        )
    },[])

    useEffect(()=>{
      // if(newData){
        itemsCustomerDatatable(
          orderItemsData,
        'itemsCustomerTable',
        setItem
        )
        // dispatch(clearResetBot())
      // }   
  
    }, [orderItemsData])

    const setItem = (data, type) => {
       dispatch(setDetails({details: data, type: 'order-items'}))
      if(type == 'confirm'){
        dispatch(showOrderModalFunc({modal: true, type}))
      }
    }

  return (
    <CustomerWrapper heading={'Order Items'}>
            <div className='mb-3'>
        <button
        onClick={()=>{navigate(app_urls.orders)}}
          style={{color: 'rgba(0,0,0,0.9)'}}
         className='btn btn-sm bg-accent fw-bold'
        >
          <i style={{fontSize: '1rem'}} className="mdi mdi-arrow-left me-1"></i>
          Go back to orders
        </button>
      </div>

      {(show && type == 'confirm') &&
      <ConfirmDelivery/>
      }
    <DataTableWrapper 
        table_id='itemsCustomerTable' 
    >
        <thead>
            <tr>
                {/* <th className="wd-5p">S/N</th> */}
                <th className="wd-15p">Name</th>
                <th className="wd-10p">Ticket Price</th>
                <th className="wd-10p">Commission Amount</th>
                <th className="wd-10p">Total Amount</th>
                <th className="wd-10p">Event Type</th>
                <th className="wd-10p">Ticket Type</th>
                <th className="wd-10p">Delivery Status</th>
                {/* <th className="wd-15p">Actions</th> */}
            </tr>
        </thead>
        <tbody>
        </tbody>
    </DataTableWrapper>
    </CustomerWrapper>
  )
}

