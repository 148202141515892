export const country_list = [
    {
        "code": "AF",
        "phoneCode": "+93",
        "flag": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
        "name": "Afghanistan"
    },
    {
        "code": "AX",
        "phoneCode": "+358",
        "flag": "https://flagcdn.com/ax.svg",
        "name": "Åland Islands"
    },
    {
        "code": "AL",
        "phoneCode": "+355",
        "flag": "https://flagcdn.com/al.svg",
        "name": "Albania"
    },
    {
        "code": "DZ",
        "phoneCode": "+213",
        "flag": "https://flagcdn.com/dz.svg",
        "name": "Algeria"
    },
    {
        "code": "AS",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/as.svg",
        "name": "American Samoa"
    },
    {
        "code": "AD",
        "phoneCode": "+376",
        "flag": "https://flagcdn.com/ad.svg",
        "name": "Andorra"
    },
    {
        "code": "AO",
        "phoneCode": "+244",
        "flag": "https://flagcdn.com/ao.svg",
        "name": "Angola"
    },
    {
        "code": "AI",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/ai.svg",
        "name": "Anguilla"
    },
    {
        "code": "AQ",
        "phoneCode": "+672",
        "flag": "https://flagcdn.com/aq.svg",
        "name": "Antarctica"
    },
    {
        "code": "AG",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/ag.svg",
        "name": "Antigua and Barbuda"
    },
    {
        "code": "AR",
        "phoneCode": "+54",
        "flag": "https://flagcdn.com/ar.svg",
        "name": "Argentina"
    },
    {
        "code": "AM",
        "phoneCode": "+374",
        "flag": "https://flagcdn.com/am.svg",
        "name": "Armenia"
    },
    {
        "code": "AW",
        "phoneCode": "+297",
        "flag": "https://flagcdn.com/aw.svg",
        "name": "Aruba"
    },
    {
        "code": "AU",
        "phoneCode": "+61",
        "flag": "https://flagcdn.com/au.svg",
        "name": "Australia"
    },
    {
        "code": "AT",
        "phoneCode": "+43",
        "flag": "https://flagcdn.com/at.svg",
        "name": "Austria"
    },
    {
        "code": "AZ",
        "phoneCode": "+994",
        "flag": "https://flagcdn.com/az.svg",
        "name": "Azerbaijan"
    },
    {
        "code": "BS",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/bs.svg",
        "name": "Bahamas"
    },
    {
        "code": "BH",
        "phoneCode": "+973",
        "flag": "https://flagcdn.com/bh.svg",
        "name": "Bahrain"
    },
    {
        "code": "BD",
        "phoneCode": "+880",
        "flag": "https://flagcdn.com/bd.svg",
        "name": "Bangladesh"
    },
    {
        "code": "BB",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/bb.svg",
        "name": "Barbados"
    },
    {
        "code": "BY",
        "phoneCode": "+375",
        "flag": "https://flagcdn.com/by.svg",
        "name": "Belarus"
    },
    {
        "code": "BE",
        "phoneCode": "+32",
        "flag": "https://flagcdn.com/be.svg",
        "name": "Belgium"
    },
    {
        "code": "BZ",
        "phoneCode": "+501",
        "flag": "https://flagcdn.com/bz.svg",
        "name": "Belize"
    },
    {
        "code": "BJ",
        "phoneCode": "+229",
        "flag": "https://flagcdn.com/bj.svg",
        "name": "Benin"
    },
    {
        "code": "BM",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/bm.svg",
        "name": "Bermuda"
    },
    {
        "code": "BT",
        "phoneCode": "+975",
        "flag": "https://flagcdn.com/bt.svg",
        "name": "Bhutan"
    },
    {
        "code": "BO",
        "phoneCode": "+591",
        "flag": "https://flagcdn.com/bo.svg",
        "name": "Bolivia (Plurinational State of)"
    },
    {
        "code": "BQ",
        "phoneCode": "+599",
        "flag": "https://flagcdn.com/bq.svg",
        "name": "Bonaire, Sint Eustatius and Saba"
    },
    {
        "code": "BA",
        "phoneCode": "+387",
        "flag": "https://flagcdn.com/ba.svg",
        "name": "Bosnia and Herzegovina"
    },
    {
        "code": "BW",
        "phoneCode": "+267",
        "flag": "https://flagcdn.com/bw.svg",
        "name": "Botswana"
    },
    {
        "code": "BV",
        "phoneCode": "+47",
        "flag": "https://flagcdn.com/bv.svg",
        "name": "Bouvet Island"
    },
    {
        "code": "BR",
        "phoneCode": "+55",
        "flag": "https://flagcdn.com/br.svg",
        "name": "Brazil"
    },
    {
        "code": "IO",
        "phoneCode": "+246",
        "flag": "https://flagcdn.com/io.svg",
        "name": "British Indian Ocean Territory"
    },
    {
        "code": "BN",
        "phoneCode": "+673",
        "flag": "https://flagcdn.com/bn.svg",
        "name": "Brunei Darussalam"
    },
    {
        "code": "BG",
        "phoneCode": "+359",
        "flag": "https://flagcdn.com/bg.svg",
        "name": "Bulgaria"
    },
    {
        "code": "BF",
        "phoneCode": "+226",
        "flag": "https://flagcdn.com/bf.svg",
        "name": "Burkina Faso"
    },
    {
        "code": "BI",
        "phoneCode": "+257",
        "flag": "https://flagcdn.com/bi.svg",
        "name": "Burundi"
    },
    {
        "code": "CV",
        "phoneCode": "+238",
        "flag": "https://flagcdn.com/cv.svg",
        "name": "Cabo Verde"
    },
    {
        "code": "KH",
        "phoneCode": "+855",
        "flag": "https://flagcdn.com/kh.svg",
        "name": "Cambodia"
    },
    {
        "code": "CM",
        "phoneCode": "+237",
        "flag": "https://flagcdn.com/cm.svg",
        "name": "Cameroon"
    },
    {
        "code": "CA",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/ca.svg",
        "name": "Canada"
    },
    {
        "code": "KY",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/ky.svg",
        "name": "Cayman Islands"
    },
    {
        "code": "CF",
        "phoneCode": "+236",
        "flag": "https://flagcdn.com/cf.svg",
        "name": "Central African Republic"
    },
    {
        "code": "TD",
        "phoneCode": "+235",
        "flag": "https://flagcdn.com/td.svg",
        "name": "Chad"
    },
    {
        "code": "CL",
        "phoneCode": "+56",
        "flag": "https://flagcdn.com/cl.svg",
        "name": "Chile"
    },
    {
        "code": "CN",
        "phoneCode": "+86",
        "flag": "https://flagcdn.com/cn.svg",
        "name": "China"
    },
    {
        "code": "CX",
        "phoneCode": "+61",
        "flag": "https://flagcdn.com/cx.svg",
        "name": "Christmas Island"
    },
    {
        "code": "CC",
        "phoneCode": "+61",
        "flag": "https://flagcdn.com/cc.svg",
        "name": "Cocos (Keeling) Islands"
    },
    {
        "code": "CO",
        "phoneCode": "+57",
        "flag": "https://flagcdn.com/co.svg",
        "name": "Colombia"
    },
    {
        "code": "KM",
        "phoneCode": "+269",
        "flag": "https://flagcdn.com/km.svg",
        "name": "Comoros"
    },
    {
        "code": "CG",
        "phoneCode": "+242",
        "flag": "https://flagcdn.com/cg.svg",
        "name": "Congo"
    },
    {
        "code": "CD",
        "phoneCode": "+243",
        "flag": "https://flagcdn.com/cd.svg",
        "name": "Congo (Democratic Republic of the)"
    },
    {
        "code": "CK",
        "phoneCode": "+682",
        "flag": "https://flagcdn.com/ck.svg",
        "name": "Cook Islands"
    },
    {
        "code": "CR",
        "phoneCode": "+506",
        "flag": "https://flagcdn.com/cr.svg",
        "name": "Costa Rica"
    },
    {
        "code": "HR",
        "phoneCode": "+385",
        "flag": "https://flagcdn.com/hr.svg",
        "name": "Croatia"
    },
    {
        "code": "CU",
        "phoneCode": "+53",
        "flag": "https://flagcdn.com/cu.svg",
        "name": "Cuba"
    },
    {
        "code": "CW",
        "phoneCode": "+599",
        "flag": "https://flagcdn.com/cw.svg",
        "name": "Curaçao"
    },
    {
        "code": "CY",
        "phoneCode": "+357",
        "flag": "https://flagcdn.com/cy.svg",
        "name": "Cyprus"
    },
    {
        "code": "CZ",
        "phoneCode": "+420",
        "flag": "https://flagcdn.com/cz.svg",
        "name": "Czech Republic"
    },
    {
        "code": "DK",
        "phoneCode": "+45",
        "flag": "https://flagcdn.com/dk.svg",
        "name": "Denmark"
    },
    {
        "code": "DJ",
        "phoneCode": "+253",
        "flag": "https://flagcdn.com/dj.svg",
        "name": "Djibouti"
    },
    {
        "code": "DM",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/dm.svg",
        "name": "Dominica"
    },
    {
        "code": "DO",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/do.svg",
        "name": "Dominican Republic"
    },
    {
        "code": "EC",
        "phoneCode": "+593",
        "flag": "https://flagcdn.com/ec.svg",
        "name": "Ecuador"
    },
    {
        "code": "EG",
        "phoneCode": "+20",
        "flag": "https://flagcdn.com/eg.svg",
        "name": "Egypt"
    },
    {
        "code": "SV",
        "phoneCode": "+503",
        "flag": "https://flagcdn.com/sv.svg",
        "name": "El Salvador"
    },
    {
        "code": "GQ",
        "phoneCode": "+240",
        "flag": "https://flagcdn.com/gq.svg",
        "name": "Equatorial Guinea"
    },
    {
        "code": "ER",
        "phoneCode": "+291",
        "flag": "https://flagcdn.com/er.svg",
        "name": "Eritrea"
    },
    {
        "code": "EE",
        "phoneCode": "+372",
        "flag": "https://flagcdn.com/ee.svg",
        "name": "Estonia"
    },
    {
        "code": "ET",
        "phoneCode": "+251",
        "flag": "https://flagcdn.com/et.svg",
        "name": "Ethiopia"
    },
    {
        "code": "FK",
        "phoneCode": "+500",
        "flag": "https://flagcdn.com/fk.svg",
        "name": "Falkland Islands (Malvinas)"
    },
    {
        "code": "FO",
        "phoneCode": "+298",
        "flag": "https://flagcdn.com/fo.svg",
        "name": "Faroe Islands"
    },
    {
        "code": "FJ",
        "phoneCode": "+679",
        "flag": "https://flagcdn.com/fj.svg",
        "name": "Fiji"
    },
    {
        "code": "FI",
        "phoneCode": "+358",
        "flag": "https://flagcdn.com/fi.svg",
        "name": "Finland"
    },
    {
        "code": "FR",
        "phoneCode": "+33",
        "flag": "https://flagcdn.com/fr.svg",
        "name": "France"
    },
    {
        "code": "GF",
        "phoneCode": "+594",
        "flag": "https://flagcdn.com/gf.svg",
        "name": "French Guiana"
    },
    {
        "code": "PF",
        "phoneCode": "+689",
        "flag": "https://flagcdn.com/pf.svg",
        "name": "French Polynesia"
    },
    {
        "code": "TF",
        "phoneCode": "+262",
        "flag": "https://flagcdn.com/tf.svg",
        "name": "French Southern Territories"
    },
    {
        "code": "GA",
        "phoneCode": "+241",
        "flag": "https://flagcdn.com/ga.svg",
        "name": "Gabon"
    },
    {
        "code": "GM",
        "phoneCode": "+220",
        "flag": "https://flagcdn.com/gm.svg",
        "name": "Gambia"
    },
    {
        "code": "GE",
        "phoneCode": "+995",
        "flag": "https://flagcdn.com/ge.svg",
        "name": "Georgia"
    },
    {
        "code": "DE",
        "phoneCode": "+49",
        "flag": "https://flagcdn.com/de.svg",
        "name": "Germany"
    },
    {
        "code": "GH",
        "phoneCode": "+233",
        "flag": "https://flagcdn.com/gh.svg",
        "name": "Ghana"
    },
    {
        "code": "GI",
        "phoneCode": "+350",
        "flag": "https://flagcdn.com/gi.svg",
        "name": "Gibraltar"
    },
    {
        "code": "GR",
        "phoneCode": "+30",
        "flag": "https://flagcdn.com/gr.svg",
        "name": "Greece"
    },
    {
        "code": "GL",
        "phoneCode": "+299",
        "flag": "https://flagcdn.com/gl.svg",
        "name": "Greenland"
    },
    {
        "code": "GD",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/gd.svg",
        "name": "Grenada"
    },
    {
        "code": "GP",
        "phoneCode": "+590",
        "flag": "https://flagcdn.com/gp.svg",
        "name": "Guadeloupe"
    },
    {
        "code": "GU",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/gu.svg",
        "name": "Guam"
    },
    {
        "code": "GT",
        "phoneCode": "+502",
        "flag": "https://flagcdn.com/gt.svg",
        "name": "Guatemala"
    },
    {
        "code": "GG",
        "phoneCode": "+44",
        "flag": "https://flagcdn.com/gg.svg",
        "name": "Guernsey"
    },
    {
        "code": "GN",
        "phoneCode": "+224",
        "flag": "https://flagcdn.com/gn.svg",
        "name": "Guinea"
    },
    {
        "code": "GW",
        "phoneCode": "+245",
        "flag": "https://flagcdn.com/gw.svg",
        "name": "Guinea-Bissau"
    },
    {
        "code": "GY",
        "phoneCode": "+592",
        "flag": "https://flagcdn.com/gy.svg",
        "name": "Guyana"
    },
    {
        "code": "HT",
        "phoneCode": "+509",
        "flag": "https://flagcdn.com/ht.svg",
        "name": "Haiti"
    },
    {
        "code": "HM",
        "phoneCode": "+672",
        "flag": "https://flagcdn.com/hm.svg",
        "name": "Heard Island and McDonald Islands"
    },
    {
        "code": "HN",
        "phoneCode": "+504",
        "flag": "https://flagcdn.com/hn.svg",
        "name": "Honduras"
    },
    {
        "code": "HK",
        "phoneCode": "+852",
        "flag": "https://flagcdn.com/hk.svg",
        "name": "Hong Kong"
    },
    {
        "code": "HU",
        "phoneCode": "+36",
        "flag": "https://flagcdn.com/hu.svg",
        "name": "Hungary"
    },
    {
        "code": "IS",
        "phoneCode": "+354",
        "flag": "https://flagcdn.com/is.svg",
        "name": "Iceland"
    },
    {
        "code": "IN",
        "phoneCode": "+91",
        "flag": "https://flagcdn.com/in.svg",
        "name": "India"
    },
    {
        "code": "ID",
        "phoneCode": "+62",
        "flag": "https://flagcdn.com/id.svg",
        "name": "Indonesia"
    },
    {
        "code": "IR",
        "phoneCode": "+98",
        "flag": "https://flagcdn.com/ir.svg",
        "name": "Iran (Islamic Republic of)"
    },
    {
        "code": "IQ",
        "phoneCode": "+964",
        "flag": "https://flagcdn.com/iq.svg",
        "name": "Iraq"
    },
    {
        "code": "IE",
        "phoneCode": "+353",
        "flag": "https://flagcdn.com/ie.svg",
        "name": "Ireland"
    },
    {
        "code": "IM",
        "phoneCode": "+44",
        "flag": "https://flagcdn.com/im.svg",
        "name": "Isle of Man"
    },
    {
        "code": "IL",
        "phoneCode": "+972",
        "flag": "https://flagcdn.com/il.svg",
        "name": "Israel"
    },
    {
        "code": "IT",
        "phoneCode": "+39",
        "flag": "https://flagcdn.com/it.svg",
        "name": "Italy"
    },
    {
        "code": "CI",
        "phoneCode": "+225",
        "flag": "https://flagcdn.com/ci.svg",
        "name": "Ivory Coast"
    },
    {
        "code": "JM",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/jm.svg",
        "name": "Jamaica"
    },
    {
        "code": "JP",
        "phoneCode": "+81",
        "flag": "https://flagcdn.com/jp.svg",
        "name": "Japan"
    },
    {
        "code": "JE",
        "phoneCode": "+44",
        "flag": "https://flagcdn.com/je.svg",
        "name": "Jersey"
    },
    {
        "code": "JO",
        "phoneCode": "+962",
        "flag": "https://flagcdn.com/jo.svg",
        "name": "Jordan"
    },
    {
        "code": "KZ",
        "phoneCode": "+76",
        "flag": "https://flagcdn.com/kz.svg",
        "name": "Kazakhstan"
    },
    {
        "code": "KE",
        "phoneCode": "+254",
        "flag": "https://flagcdn.com/ke.svg",
        "name": "Kenya"
    },
    {
        "code": "KI",
        "phoneCode": "+686",
        "flag": "https://flagcdn.com/ki.svg",
        "name": "Kiribati"
    },
    {
        "code": "KP",
        "phoneCode": "+850",
        "flag": "https://flagcdn.com/kp.svg",
        "name": "Korea (Democratic People's Republic of)"
    },
    {
        "code": "KR",
        "phoneCode": "+82",
        "flag": "https://flagcdn.com/kr.svg",
        "name": "Korea (Republic of)"
    },
    {
        "code": "KW",
        "phoneCode": "+965",
        "flag": "https://flagcdn.com/kw.svg",
        "name": "Kuwait"
    },
    {
        "code": "KG",
        "phoneCode": "+996",
        "flag": "https://flagcdn.com/kg.svg",
        "name": "Kyrgyzstan"
    },
    {
        "code": "LA",
        "phoneCode": "+856",
        "flag": "https://flagcdn.com/la.svg",
        "name": "Lao People's Democratic Republic"
    },
    {
        "code": "LV",
        "phoneCode": "+371",
        "flag": "https://flagcdn.com/lv.svg",
        "name": "Latvia"
    },
    {
        "code": "LB",
        "phoneCode": "+961",
        "flag": "https://flagcdn.com/lb.svg",
        "name": "Lebanon"
    },
    {
        "code": "LS",
        "phoneCode": "+266",
        "flag": "https://flagcdn.com/ls.svg",
        "name": "Lesotho"
    },
    {
        "code": "LR",
        "phoneCode": "+231",
        "flag": "https://flagcdn.com/lr.svg",
        "name": "Liberia"
    },
    {
        "code": "LY",
        "phoneCode": "+218",
        "flag": "https://flagcdn.com/ly.svg",
        "name": "Libya"
    },
    {
        "code": "LI",
        "phoneCode": "+423",
        "flag": "https://flagcdn.com/li.svg",
        "name": "Liechtenstein"
    },
    {
        "code": "LT",
        "phoneCode": "+370",
        "flag": "https://flagcdn.com/lt.svg",
        "name": "Lithuania"
    },
    {
        "code": "LU",
        "phoneCode": "+352",
        "flag": "https://flagcdn.com/lu.svg",
        "name": "Luxembourg"
    },
    {
        "code": "MO",
        "phoneCode": "+853",
        "flag": "https://flagcdn.com/mo.svg",
        "name": "Macao"
    },
    {
        "code": "MG",
        "phoneCode": "+261",
        "flag": "https://flagcdn.com/mg.svg",
        "name": "Madagascar"
    },
    {
        "code": "MW",
        "phoneCode": "+265",
        "flag": "https://flagcdn.com/mw.svg",
        "name": "Malawi"
    },
    {
        "code": "MY",
        "phoneCode": "+60",
        "flag": "https://flagcdn.com/my.svg",
        "name": "Malaysia"
    },
    {
        "code": "MV",
        "phoneCode": "+960",
        "flag": "https://flagcdn.com/mv.svg",
        "name": "Maldives"
    },
    {
        "code": "ML",
        "phoneCode": "+223",
        "flag": "https://flagcdn.com/ml.svg",
        "name": "Mali"
    },
    {
        "code": "MT",
        "phoneCode": "+356",
        "flag": "https://flagcdn.com/mt.svg",
        "name": "Malta"
    },
    {
        "code": "MH",
        "phoneCode": "+692",
        "flag": "https://flagcdn.com/mh.svg",
        "name": "Marshall Islands"
    },
    {
        "code": "MQ",
        "phoneCode": "+596",
        "flag": "https://flagcdn.com/mq.svg",
        "name": "Martinique"
    },
    {
        "code": "MR",
        "phoneCode": "+222",
        "flag": "https://flagcdn.com/mr.svg",
        "name": "Mauritania"
    },
    {
        "code": "MU",
        "phoneCode": "+230",
        "flag": "https://flagcdn.com/mu.svg",
        "name": "Mauritius"
    },
    {
        "code": "YT",
        "phoneCode": "+262",
        "flag": "https://flagcdn.com/yt.svg",
        "name": "Mayotte"
    },
    {
        "code": "MX",
        "phoneCode": "+52",
        "flag": "https://flagcdn.com/mx.svg",
        "name": "Mexico"
    },
    {
        "code": "FM",
        "phoneCode": "+691",
        "flag": "https://flagcdn.com/fm.svg",
        "name": "Micronesia (Federated States of)"
    },
    {
        "code": "MD",
        "phoneCode": "+373",
        "flag": "https://flagcdn.com/md.svg",
        "name": "Moldova (Republic of)"
    },
    {
        "code": "MC",
        "phoneCode": "+377",
        "flag": "https://flagcdn.com/mc.svg",
        "name": "Monaco"
    },
    {
        "code": "MN",
        "phoneCode": "+976",
        "flag": "https://flagcdn.com/mn.svg",
        "name": "Mongolia"
    },
    {
        "code": "ME",
        "phoneCode": "+382",
        "flag": "https://flagcdn.com/me.svg",
        "name": "Montenegro"
    },
    {
        "code": "MS",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/ms.svg",
        "name": "Montserrat"
    },
    {
        "code": "MA",
        "phoneCode": "+212",
        "flag": "https://flagcdn.com/ma.svg",
        "name": "Morocco"
    },
    {
        "code": "MZ",
        "phoneCode": "+258",
        "flag": "https://flagcdn.com/mz.svg",
        "name": "Mozambique"
    },
    {
        "code": "MM",
        "phoneCode": "+95",
        "flag": "https://flagcdn.com/mm.svg",
        "name": "Myanmar"
    },
    {
        "code": "NA",
        "phoneCode": "+264",
        "flag": "https://flagcdn.com/na.svg",
        "name": "Namibia"
    },
    {
        "code": "NR",
        "phoneCode": "+674",
        "flag": "https://flagcdn.com/nr.svg",
        "name": "Nauru"
    },
    {
        "code": "NP",
        "phoneCode": "+977",
        "flag": "https://flagcdn.com/np.svg",
        "name": "Nepal"
    },
    {
        "code": "NL",
        "phoneCode": "+31",
        "flag": "https://flagcdn.com/nl.svg",
        "name": "Netherlands"
    },
    {
        "code": "NC",
        "phoneCode": "+687",
        "flag": "https://flagcdn.com/nc.svg",
        "name": "New Caledonia"
    },
    {
        "code": "NZ",
        "phoneCode": "+64",
        "flag": "https://flagcdn.com/nz.svg",
        "name": "New Zealand"
    },
    {
        "code": "NI",
        "phoneCode": "+505",
        "flag": "https://flagcdn.com/ni.svg",
        "name": "Nicaragua"
    },
    {
        "code": "NE",
        "phoneCode": "+227",
        "flag": "https://flagcdn.com/ne.svg",
        "name": "Niger"
    },
    {
        "code": "NG",
        "phoneCode": "+234",
        "flag": "https://flagcdn.com/ng.svg",
        "name": "Nigeria"
    },
    {
        "code": "NU",
        "phoneCode": "+683",
        "flag": "https://flagcdn.com/nu.svg",
        "name": "Niue"
    },
    {
        "code": "NF",
        "phoneCode": "+672",
        "flag": "https://flagcdn.com/nf.svg",
        "name": "Norfolk Island"
    },
    {
        "code": "MK",
        "phoneCode": "+389",
        "flag": "https://flagcdn.com/mk.svg",
        "name": "North Macedonia"
    },
    {
        "code": "MP",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/mp.svg",
        "name": "Northern Mariana Islands"
    },
    {
        "code": "NO",
        "phoneCode": "+47",
        "flag": "https://flagcdn.com/no.svg",
        "name": "Norway"
    },
    {
        "code": "OM",
        "phoneCode": "+968",
        "flag": "https://flagcdn.com/om.svg",
        "name": "Oman"
    },
    {
        "code": "PK",
        "phoneCode": "+92",
        "flag": "https://flagcdn.com/pk.svg",
        "name": "Pakistan"
    },
    {
        "code": "PW",
        "phoneCode": "+680",
        "flag": "https://flagcdn.com/pw.svg",
        "name": "Palau"
    },
    {
        "code": "PS",
        "phoneCode": "+970",
        "flag": "https://flagcdn.com/ps.svg",
        "name": "Palestine, State of"
    },
    {
        "code": "PA",
        "phoneCode": "+507",
        "flag": "https://flagcdn.com/pa.svg",
        "name": "Panama"
    },
    {
        "code": "PG",
        "phoneCode": "+675",
        "flag": "https://flagcdn.com/pg.svg",
        "name": "Papua New Guinea"
    },
    {
        "code": "PY",
        "phoneCode": "+595",
        "flag": "https://flagcdn.com/py.svg",
        "name": "Paraguay"
    },
    {
        "code": "PE",
        "phoneCode": "+51",
        "flag": "https://flagcdn.com/pe.svg",
        "name": "Peru"
    },
    {
        "code": "PH",
        "phoneCode": "+63",
        "flag": "https://flagcdn.com/ph.svg",
        "name": "Philippines"
    },
    {
        "code": "PN",
        "phoneCode": "+64",
        "flag": "https://flagcdn.com/pn.svg",
        "name": "Pitcairn"
    },
    {
        "code": "PL",
        "phoneCode": "+48",
        "flag": "https://flagcdn.com/pl.svg",
        "name": "Poland"
    },
    {
        "code": "PT",
        "phoneCode": "+351",
        "flag": "https://flagcdn.com/pt.svg",
        "name": "Portugal"
    },
    {
        "code": "PR",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/pr.svg",
        "name": "Puerto Rico"
    },
    {
        "code": "QA",
        "phoneCode": "+974",
        "flag": "https://flagcdn.com/qa.svg",
        "name": "Qatar"
    },
    {
        "code": "XK",
        "phoneCode": "+383",
        "flag": "https://flagcdn.com/xk.svg",
        "name": "Republic of Kosovo"
    },
    {
        "code": "RE",
        "phoneCode": "+262",
        "flag": "https://flagcdn.com/re.svg",
        "name": "Réunion"
    },
    {
        "code": "RO",
        "phoneCode": "+40",
        "flag": "https://flagcdn.com/ro.svg",
        "name": "Romania"
    },
    {
        "code": "RU",
        "phoneCode": "+7",
        "flag": "https://flagcdn.com/ru.svg",
        "name": "Russian Federation"
    },
    {
        "code": "RW",
        "phoneCode": "+250",
        "flag": "https://flagcdn.com/rw.svg",
        "name": "Rwanda"
    },
    {
        "code": "BL",
        "phoneCode": "+590",
        "flag": "https://flagcdn.com/bl.svg",
        "name": "Saint Barthélemy"
    },
    {
        "code": "SH",
        "phoneCode": "+290",
        "flag": "https://flagcdn.com/sh.svg",
        "name": "Saint Helena, Ascension and Tristan da Cunha"
    },
    {
        "code": "KN",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/kn.svg",
        "name": "Saint Kitts and Nevis"
    },
    {
        "code": "LC",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/lc.svg",
        "name": "Saint Lucia"
    },
    {
        "code": "MF",
        "phoneCode": "+590",
        "flag": "https://flagcdn.com/mf.svg",
        "name": "Saint Martin (French part)"
    },
    {
        "code": "PM",
        "phoneCode": "+508",
        "flag": "https://flagcdn.com/pm.svg",
        "name": "Saint Pierre and Miquelon"
    },
    {
        "code": "VC",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/vc.svg",
        "name": "Saint Vincent and the Grenadines"
    },
    {
        "code": "WS",
        "phoneCode": "+685",
        "flag": "https://flagcdn.com/ws.svg",
        "name": "Samoa"
    },
    {
        "code": "SM",
        "phoneCode": "+378",
        "flag": "https://flagcdn.com/sm.svg",
        "name": "San Marino"
    },
    {
        "code": "ST",
        "phoneCode": "+239",
        "flag": "https://flagcdn.com/st.svg",
        "name": "Sao Tome and Principe"
    },
    {
        "code": "SA",
        "phoneCode": "+966",
        "flag": "https://flagcdn.com/sa.svg",
        "name": "Saudi Arabia"
    },
    {
        "code": "SN",
        "phoneCode": "+221",
        "flag": "https://flagcdn.com/sn.svg",
        "name": "Senegal"
    },
    {
        "code": "RS",
        "phoneCode": "+381",
        "flag": "https://flagcdn.com/rs.svg",
        "name": "Serbia"
    },
    {
        "code": "SC",
        "phoneCode": "+248",
        "flag": "https://flagcdn.com/sc.svg",
        "name": "Seychelles"
    },
    {
        "code": "SL",
        "phoneCode": "+232",
        "flag": "https://flagcdn.com/sl.svg",
        "name": "Sierra Leone"
    },
    {
        "code": "SG",
        "phoneCode": "+65",
        "flag": "https://flagcdn.com/sg.svg",
        "name": "Singapore"
    },
    {
        "code": "SX",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/sx.svg",
        "name": "Sint Maarten (Dutch part)"
    },
    {
        "code": "SK",
        "phoneCode": "+421",
        "flag": "https://flagcdn.com/sk.svg",
        "name": "Slovakia"
    },
    {
        "code": "SI",
        "phoneCode": "+386",
        "flag": "https://flagcdn.com/si.svg",
        "name": "Slovenia"
    },
    {
        "code": "SB",
        "phoneCode": "+677",
        "flag": "https://flagcdn.com/sb.svg",
        "name": "Solomon Islands"
    },
    {
        "code": "SO",
        "phoneCode": "+252",
        "flag": "https://flagcdn.com/so.svg",
        "name": "Somalia"
    },
    {
        "code": "ZA",
        "phoneCode": "+27",
        "flag": "https://flagcdn.com/za.svg",
        "name": "South Africa"
    },
    {
        "code": "GS",
        "phoneCode": "+500",
        "flag": "https://flagcdn.com/gs.svg",
        "name": "South Georgia and the South Sandwich Islands"
    },
    {
        "code": "SS",
        "phoneCode": "+211",
        "flag": "https://flagcdn.com/ss.svg",
        "name": "South Sudan"
    },
    {
        "code": "ES",
        "phoneCode": "+34",
        "flag": "https://flagcdn.com/es.svg",
        "name": "Spain"
    },
    {
        "code": "LK",
        "phoneCode": "+94",
        "flag": "https://flagcdn.com/lk.svg",
        "name": "Sri Lanka"
    },
    {
        "code": "SD",
        "phoneCode": "+249",
        "flag": "https://flagcdn.com/sd.svg",
        "name": "Sudan"
    },
    {
        "code": "SR",
        "phoneCode": "+597",
        "flag": "https://flagcdn.com/sr.svg",
        "name": "Suriname"
    },
    {
        "code": "SJ",
        "phoneCode": "+47",
        "flag": "https://flagcdn.com/sj.svg",
        "name": "Svalbard and Jan Mayen"
    },
    {
        "code": "SZ",
        "phoneCode": "+268",
        "flag": "https://flagcdn.com/sz.svg",
        "name": "Swaziland"
    },
    {
        "code": "SE",
        "phoneCode": "+46",
        "flag": "https://flagcdn.com/se.svg",
        "name": "Sweden"
    },
    {
        "code": "CH",
        "phoneCode": "+41",
        "flag": "https://flagcdn.com/ch.svg",
        "name": "Switzerland"
    },
    {
        "code": "SY",
        "phoneCode": "+963",
        "flag": "https://flagcdn.com/sy.svg",
        "name": "Syrian Arab Republic"
    },
    {
        "code": "TW",
        "phoneCode": "+886",
        "flag": "https://flagcdn.com/tw.svg",
        "name": "Taiwan"
    },
    {
        "code": "TJ",
        "phoneCode": "+992",
        "flag": "https://flagcdn.com/tj.svg",
        "name": "Tajikistan"
    },
    {
        "code": "TZ",
        "phoneCode": "+255",
        "flag": "https://flagcdn.com/tz.svg",
        "name": "Tanzania, United Republic of"
    },
    {
        "code": "TH",
        "phoneCode": "+66",
        "flag": "https://flagcdn.com/th.svg",
        "name": "Thailand"
    },
    {
        "code": "TL",
        "phoneCode": "+670",
        "flag": "https://flagcdn.com/tl.svg",
        "name": "Timor-Leste"
    },
    {
        "code": "TG",
        "phoneCode": "+228",
        "flag": "https://flagcdn.com/tg.svg",
        "name": "Togo"
    },
    {
        "code": "TK",
        "phoneCode": "+690",
        "flag": "https://flagcdn.com/tk.svg",
        "name": "Tokelau"
    },
    {
        "code": "TO",
        "phoneCode": "+676",
        "flag": "https://flagcdn.com/to.svg",
        "name": "Tonga"
    },
    {
        "code": "TT",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/tt.svg",
        "name": "Trinidad and Tobago"
    },
    {
        "code": "TN",
        "phoneCode": "+216",
        "flag": "https://flagcdn.com/tn.svg",
        "name": "Tunisia"
    },
    {
        "code": "TR",
        "phoneCode": "+90",
        "flag": "https://flagcdn.com/tr.svg",
        "name": "Turkey"
    },
    {
        "code": "TM",
        "phoneCode": "+993",
        "flag": "https://flagcdn.com/tm.svg",
        "name": "Turkmenistan"
    },
    {
        "code": "TC",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/tc.svg",
        "name": "Turks and Caicos Islands"
    },
    {
        "code": "TV",
        "phoneCode": "+688",
        "flag": "https://flagcdn.com/tv.svg",
        "name": "Tuvalu"
    },
    {
        "code": "UG",
        "phoneCode": "+256",
        "flag": "https://flagcdn.com/ug.svg",
        "name": "Uganda"
    },
    {
        "code": "UA",
        "phoneCode": "+380",
        "flag": "https://flagcdn.com/ua.svg",
        "name": "Ukraine"
    },
    {
        "code": "AE",
        "phoneCode": "+971",
        "flag": "https://flagcdn.com/ae.svg",
        "name": "United Arab Emirates"
    },
    {
        "code": "GB",
        "phoneCode": "+44",
        "flag": "https://flagcdn.com/gb.svg",
        "name": "United Kingdom of Great Britain and Northern Ireland"
    },
    {
        "code": "UM",
        "phoneCode": "+246",
        "flag": "https://flagcdn.com/um.svg",
        "name": "United States Minor Outlying Islands"
    },
    {
        "code": "US",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/us.svg",
        "name": "United States of America"
    },
    {
        "code": "UY",
        "phoneCode": "+598",
        "flag": "https://flagcdn.com/uy.svg",
        "name": "Uruguay"
    },
    {
        "code": "UZ",
        "phoneCode": "+998",
        "flag": "https://flagcdn.com/uz.svg",
        "name": "Uzbekistan"
    },
    {
        "code": "VU",
        "phoneCode": "+678",
        "flag": "https://flagcdn.com/vu.svg",
        "name": "Vanuatu"
    },
    {
        "code": "VA",
        "phoneCode": "+379",
        "flag": "https://flagcdn.com/va.svg",
        "name": "Vatican City"
    },
    {
        "code": "VE",
        "phoneCode": "+58",
        "flag": "https://flagcdn.com/ve.svg",
        "name": "Venezuela (Bolivarian Republic of)"
    },
    {
        "code": "VN",
        "phoneCode": "+84",
        "flag": "https://flagcdn.com/vn.svg",
        "name": "Vietnam"
    },
    {
        "code": "VG",
        "phoneCode": "+1",
        "flag": "https://flagcdn.com/vg.svg",
        "name": "Virgin Islands (British)"
    },
    {
        "code": "VI",
        "phoneCode": "+1 340",
        "flag": "https://flagcdn.com/vi.svg",
        "name": "Virgin Islands (U.S.)"
    },
    {
        "code": "WF",
        "phoneCode": "+681",
        "flag": "https://flagcdn.com/wf.svg",
        "name": "Wallis and Futuna"
    },
    {
        "code": "EH",
        "phoneCode": "+212",
        "flag": "https://flagcdn.com/eh.svg",
        "name": "Western Sahara"
    },
    {
        "code": "YE",
        "phoneCode": "+967",
        "flag": "https://flagcdn.com/ye.svg",
        "name": "Yemen"
    },
    {
        "code": "ZM",
        "phoneCode": "+260",
        "flag": "https://flagcdn.com/zm.svg",
        "name": "Zambia"
    },
    {
        "code": "ZW",
        "phoneCode": "+263",
        "flag": "https://flagcdn.com/zw.svg",
        "name": "Zimbabwe"
    }
]