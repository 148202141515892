import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { clearAll, onOnboardUser, setErrorMessage, setLoading } from '../../../config/redux/slice/authSlice'
import { post_put_patch_request } from '../../../helpers/request'
import { app_api } from '../../../utils/endpoints/app_api'
import { app_urls } from '../../../utils/urls/app_urls'
import AuthAlert from '../../components/AuthAlert'
import Spinner from '../../components/Spinner'
import AuthWrapper from '../../layout/AuthWrapper'
import account_activated_background from '../../../assets/images/background_images/account_activated_background.jpg'

export default function ForgotPassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {register, handleSubmit, watch, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
  const {
        loading,
        errorMsg,
    } = useSelector((state) => state.authData)

     useEffect(()=>{
      dispatch(clearAll({}))
    }, [])

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        const res = await post_put_patch_request(
          "post",
            "",
            `${app_api.forgot_password}`,
            data,
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )
        if(res !== undefined && res.status < 400){
          dispatch(onOnboardUser({email: data.email}))
          navigate(app_urls.reset_password)
        }
    }

  const closeAlert = () => {
        dispatch(setErrorMessage({message: null}))
  }
  return (
    <AuthWrapper background_image={account_activated_background} onHandleSubmit={handleSubmit(onSubmit)}>
      <h6 style={{fontSize: '0.86rem'}}>Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one</h6>
      {errorMsg && <AuthAlert onClick={closeAlert} alertStyles={{width: '95%', margin: '0 auto'}} status='error'>
        {errorMsg}
      </AuthAlert>}
      <div className="form-group col-md-12 mb-4"> 
        <label htmlFor='email'>Email</label>
        <input 
          type="email" 
          className="form-control input-lg" 
          id="email" 
          aria-describedby="emailHelp"
          {...register("email", {
            required: {
              value: true,
              message: '*this field is required'
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: '*invalid email pattern'
            }
          })
          }
        />
        {
          errors?.email?.message &&
          <div className="text-danger small mt-1">
            {errors?.email?.message}
          </div>
        }
      </div>
      <div className="col-md-12">

        <div>
          {loading ? 
            <Spinner class_styles={'mb-3'}/> :
            <button type="submit" className="btn btn-primary text-uppercase mb-4">Email password reset link</button>
          }
        </div>

        <p>
          <Link className="text-blue" to={app_urls.login}>Back to Login</Link>
          {/* <a className="text-blue" href="sign-up.html">Register Here</a> */}
        </p>
      </div>
    </AuthWrapper>
  )
}
