import { Login, Registration } from "../../app/pages/authentication";
import AccountActivation from "../../app/pages/authentication/AccountActivation";
import EmailVerificationLinkSentScreen from "../../app/pages/authentication/EmailVerificationLinkSentScreen";
import ForgotPassword from "../../app/pages/authentication/ForgotPassword";
import PasswordReset from "../../app/pages/authentication/PasswordReset";
import PasswordResetSuccess from "../../app/pages/authentication/PasswordResetSuccess";
import { app_urls } from "../../utils/urls/app_urls";

export const onboard_routes = [ 
    {
		url: app_urls.login,
		component: <Login />,
		routeType: 'public'
	},
	{
		url: app_urls.register,
		component: <Registration />,
		routeType: 'public'
	},
	{
		url: app_urls.forgot_password,
		component: <ForgotPassword />,
		routeType: 'public'
	},
	{
		url: app_urls.reset_password,
		component: <PasswordReset />,
		routeType: 'public'
	},
	{
		url: app_urls.password_reset_success,
		component: <PasswordResetSuccess />,
		routeType: 'public'
	},
	{
		url: app_urls.verifyEmail,
		component: <AccountActivation />,
		routeType: 'public'
	},
	{
		url: app_urls.verification_link_sent,
		component: <EmailVerificationLinkSentScreen />,
		routeType: 'public'
	}
]