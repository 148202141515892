import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StadiumMap from '../../../components/StadiumMap'
import AdminWrapper from '../../../layout/AdminWrapper'
import AddSubsection from './AddSubsection'
import SubsectionTable from './SubsectionTable'
import { showSubStadiumMapFunc } from '../../../../config/redux/slice/adminSlice'
import EditSubsection from './EditSubsection'
import DeleteSubsection from './DeleteSubsection'

export default function SubsectionManagement() {
  const dispatch = useDispatch()
    const {
      venueManagement:{venueDetails},
      sectionManagement:{sectionDetails},
      subSectionManagement:{showAddSubSectionForm, showSubStadiumMap, subSectionDetails, showSubsectionModal: {show, type}}
    } = useSelector((state) => state.admin)

    const closeMap = () => {
      dispatch(showSubStadiumMapFunc({modal: false}))
    } 

  return (
    <AdminWrapper>
      <div>
        {(show && type == 'edit') &&
          <EditSubsection subSectionObj={subSectionDetails}/>}
        {(show && type == 'delete') &&  
          <DeleteSubsection subSectionObj={subSectionDetails}/>}
        {showSubStadiumMap && <StadiumMap onClick={closeMap} stadiumObj={venueDetails}/>}
        {/* <StadiumMap stadiumObj={venueDetails}/> */}
        {showAddSubSectionForm &&
        <AddSubsection sectionObj={sectionDetails}/>
        }
        <SubsectionTable sectionObj={sectionDetails}/>
      </div>
    </AdminWrapper>
  )
}
