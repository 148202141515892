import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearAll, onUpdate, setAdminErrorMessageUser, setAdminLoadingUser, showUserModalFunc } from '../../../../config/redux/slice/adminSlice'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'
import ModalLayout from '../../../layout/ModalLayout'

export default function DeleteUser({user,getListingDetails}) {
    const dispatch = useDispatch()
  
    const {
            adminLoading,
            adminErrorMsg,
            adminSuccessMsg
    } = useSelector((state) => state.admin)

    const {
        token
    } = useSelector((state) => state.authData)

    const onSubmit = async () => {
        dispatch(clearAll({}))
        const res = await get_delete_request(
          "delete",
            token,
            `${app_api.delete_user(user?.id)}`,
            {},
            dispatch,
            setAdminLoadingUser,
            "",
            setAdminErrorMessageUser
        )
        if(res !== undefined && res.status < 400){
          dispatch(onUpdate({message: res.data.message, type: 'user'}))
          getListingDetails()
        }
    }

    const closeModal = () => {
      dispatch(showUserModalFunc({modal: false, type: null}))
    }

  return (
    <ModalLayout onClick={closeModal} heading={'Delete User'}>
            <div className="modal-body">
                <p>Are you sure you want to delete {user?.name}?</p>
            </div>
            <div className="modal-footer border-top-0 d-flex align-items-center">
                <div>
                {adminLoading ? 
                    <Spinner isForm={true}/> :
                    <button onClick={onSubmit} type="button" className="btn btn-primary me-3">Submit Form</button>
                }
                </div>
                <button disabled={adminLoading ? true : false} onClick={closeModal} type="button" className="btn btn-info">Cancel</button>
            </div>
        </ModalLayout>
  )
}
