import moment from 'moment'
import { strText } from '../constants/english'
import { formatNumber } from './helpers'
import { format } from 'date-fns';
// import Popper from "popper.js";

const $ = require('jquery') // 1
$.DataTable = require('datatables.net-dt') //2
$.DataTable = require('datatables.net-responsive-dt') //3
$.Database = require('datatables.net-responsive-dt/css/responsive.dataTables.css') //3
//
$.DataTable = require('datatables.net-buttons/js/buttons.html5.js') // 4:: loads the copy,pdf,csv buttons and makes it works
$.DataTable = require('datatables.net-buttons/js/buttons.print.js') // 4::loads the print btn and makes it work
//
$.DataTable = require('datatables.net-bs5') //5

export const listingsCustomerDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItem
) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    return formatter.format(date);
  };
  const cols = [
    {
      data: (a) => {
        return a?.listings?.length || 0;
      },
    },

    {
      data: (a) => {
        //return a?.event?.name
        return a?.eventName
      },
    },
    {
      data: (a) => {
        return formatDate(a?.eventDate)
      }
    },

    // { data: 'numberOfTickets' },
    // { data: 'pricePerTicket' },
    // { data: 'reservedPricePerTicket' },
    {
      data: (a) => {
        //return a?.event?.name
        return a?.totalNoTickets
      },
    },
    // {
    //   data: (a) => {
    //     return `
    //                         <span class='capitalize'>${a?.ticketsType}</span>
    //                     `
    //   },
    // },
    {
      data: (a) => {
        return a?.deactivatedAt == null
          ? `
                            <span class="badge bg-success">active</span>
                        `
          : `
                            <span class="badge bg-danger">inactive</span>
                        `
      },
    },
    // {
    //   data: (a) => {
    //     return a?.section?.name
    //   },
    // },
    // {
    //   data: (a) => {
    //     return a?.subSection?.name ?? 'N/A'
    //   },
    // },
    // {
    //   data: (a) => {
    //     return moment(a?.createdAt).format(strText.full_date)
    //   },
    // },
    // {
    //   data: (a) => {
    //     return `
    //                         <a title="Clone Listing"
    //                             data-id="${a?.id}"
    //                             class="btn btn-sm bg-success btn-icon text-white duplicate-listings">
    //                             <i class="fa fa-copy"></i>
    //                         </a>
    //                         <a title="View Listing Details"
    //                             data-id="${a?.id}"
    //                             class="btn btn-sm bg-info btn-icon text-white view-listing">
    //                             <i class="fa fa-eye"></i>
    //                         </a>
    //                         <a
    //                             title="View Tickets"
    //                             data-id="${a?.id}"
    //                             class="btn btn-sm bg-success btn-icon text-white view-tickets">
    //                             <i class="fa fa-ticket"></i>
    //                         </a>
    //                     <a
    //                         title="Edit Listing"
    //                         data-id="${a?.id}"
    //                         class="btn btn-sm bg-warning btn-icon text-white edit-listing">
    //                         <i class="fa fa-pencil"></i>
    //                     </a>
    //                         <a title="Delete Listing" data-id="${a?.id}"
    //                             class="btn btn-sm bg-danger btn-icon text-white delete-listing">
    //                             <i class="fa fa-trash"></i>
    //                         </a> 
    //                     `
    //   },
    //   responsivePriority: 2,
    // },
    {
      data: (a) => {
        return `<a title="Expand Listing"
                    data-id="${a?.eventId}"
                    class="btn btn-sm bg-warning btn-icon text-white expand-listings">
                    <i class="fa fa-bars"></i>
                </a>`
      },
      responsivePriority: 2,
    },
  ]

  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Listings',
    [0, 1, 2, 3, 4, 5, 6, 7],
    [0, 'desc'],
    'Listings',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    // $(document).on('click', '.view-listing', function () {
    //   const r = $(this).attr('data-id')
    //   let x = data.filter((item) => item?.id === r)
    //   if (x.length > 0) {
    //     setItem(x[0], 'details')
    //   }
    // })
    // $(document).on('click', '.view-tickets', function () {
    //   const r = $(this).attr('data-id')
    //   let x = data.filter((item) => item?.id === r)
    //   if (x.length > 0) {
    //     setItem(x[0], 'view-tickets')
    //   }
    // })
    // $(document).on('click', '.edit-listing', function () {
    //   const r = $(this).attr('data-id')
    //   let x = data.filter((item) => item?.id === r)
    //   if (x.length > 0) {
    //     setItem(x[0], 'edit')
    //   }
    // })
    // $(document).on('click', '.delete-listing', function () {
    //   const r = $(this).attr('data-id')
    //   let x = data.filter((item) => item?.id === r)
    //   if (x.length > 0) {
    //     setItem(x[0], 'delete')
    //   }
    // })
    // $(document).on('click', '.duplicate-listings', function () {
    //   const r = $(this).attr('data-id')
    //   let x = data.filter((item) => item?.id === r)
    //   console.log("duplicate",x)
    //   if (x.length > 0) {
    //     setItem(x[0], 'duplicate')
    //   }
    // })


    $(document).on('click', '.expand-listings', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.eventId === r)
      if (x.length > 0) {
        setItem(x[0], 'expand')
      }
    })
  }
}
export const listingsCustomerDatatableExpand = (
  // errorFunc,
  // table_id,
  // endpoint,
  // searchEndPoint,
  // token,
  // setItem
  data, table_id, setItems
) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    return formatter.format(date);
  };
  const cols = [
    {
      data: (a) => {
        return a?.listingRef
      },
    },

    {
      data: (a) => {
        const sectionName = a?.section?.name || '';  // Fallback to an empty string if section or name is undefined
        const subSectionName = a?.subSection?.name ?? 'N/A';  // Fallback to an empty string if subsection or name is undefined

        return `<span title="${sectionName}" class="text-ellipsis">${sectionName}</span><br>${subSectionName}`;
      },
    },
    // {
    //   data: (a) => {
    //     return formatDate(a?.event?.date)
    //   },
    // },

    { data: 'numberOfTickets' },
    {
      data: (a) => {
        return formatNumber(a?.pricePerTicketWithoutCommission)
      },
    },

    {
      data: (a) => {
        return `
                            <span class='capitalize'>${a?.ticketsType}</span>
                        `
      },
    },
    // {
    //   data: (a) => {
    //     return a?.deactivatedAt == null
    //       ? `
    //                         <span class="badge bg-success">active</span>
    //                     `
    //       : `
    //                         <span class="badge bg-danger">inactive</span>
    //                     `
    //   },
    // },
    // {
    //   data: (a) => {
    //     return a?.section?.name
    //   },
    // },
    // {
    //   data: (a) => {
    //     return a?.subSection?.name ?? 'N/A'
    //   },
    // },
    //listed on 
    // {
    //   data: (a) => {
    //     return moment(a?.createdAt).format('ddd DD MMM YYYY').toUpperCase();
    //   },
    // },
    {
      data: (a) => {
        return `
                            <a title="Clone Listing"
                                data-id="${a?.id}"
                                class="btn btn-sm bg-success btn-icon text-white duplicate-listings">
                                <i class="fa fa-copy"></i>
                            </a>
                            <!--
                            <a title="View Listing Details"
                                data-id="${a?.id}"
                                class="btn btn-sm bg-info btn-icon text-white view-listing">
                                <i class="fa fa-eye"></i>
                            </a>
                            -->
                            <a
                                title="View Tickets"
                                data-id="${a?.id}"
                                class="btn btn-sm bg-success btn-icon text-white view-tickets">
                                <i class="fa fa-ticket"></i>
                            </a>
                        <a
                            title="Edit Listing"
                            data-id="${a?.id}"
                            class="btn btn-sm bg-warning btn-icon text-white edit-listing">
                            <i class="fa fa-pencil"></i>
                        </a>
                            <a title="Delete Listing" data-id="${a?.id}"
                                class="btn btn-sm bg-danger btn-icon text-white delete-listing">
                                <i class="fa fa-trash"></i>
                            </a> 
                        `
      },
      responsivePriority: 2,
    },

  ]

  // serverSideDatatableConfig(
  //   callbackFunc,
  //   errorFunc,
  //   table_id,
  //   cols,
  //   'Listings',
  //   [0, 1, 2, 3, 4, 5, 6, 7],
  //   [0, 'desc'],
  //   'Listings',
  //   endpoint,
  //   searchEndPoint,
  //   token
  // )
  generalDatatableConfig(
    data,
    table_id,
    cols,
    'Listing Items',
    [0, 1, 2, 3, 4, 5, 6, 7],
    [0, 'desc'],
    'Listing Items'
  )

  //function callbackFunc(data) {
  $(document).on('click', '.view-listing', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'details')
    }
  })
  $(document).on('click', '.view-tickets', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'view-tickets')
    }
  })
  $(document).on('click', '.edit-listing', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'edit')
    }
  })
  $(document).on('click', '.delete-listing', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'delete')
    }
  })
  $(document).on('click', '.duplicate-listings', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    console.log("duplicate", x)
    if (x.length > 0) {
      setItems(x[0], 'duplicate')
    }
  })

  //}
}
export const listingsProfileDatatable = (data, table_id, setItem) => {
  const cols = [
    {
      data: (a) => {
        return a?.listings?.length || 0;
      },
    },

    {
      data: (a) => {
        return a?.eventName
      },
    },

    // { data: 'numberOfTickets' },
    // { data: 'pricePerTicket' },
    // { data: 'reservedPricePerTicket' },
    // {
    //   data: (a) => {
    //     return `
    //                         <span class='capitalize'>${a?.ticketsType}</span>
    //                     `
    //   },
    // },
    {
      data: (a) => {
        return a?.totalNoTickets
      },
    },
    {
      data: (a) => {
        return a?.deactivatedAt == null
          ? `
                            <span class="badge bg-success">active</span>
                        `
          : `
                            <span class="badge bg-danger">inactive</span>
                        `
      },
    },
    // {
    //   data: (a) => {
    //     return a?.section?.name
    //   },
    // },
    // {
    //   data: (a) => {
    //     return a?.subSection?.name ?? 'N/A'
    //   },
    // },
    // {
    //   data: (a) => {
    //     return moment(a?.createdAt).format(strText.full_date)
    //   },
    // },

    // {
    //   data: (a) => {
    //     return `
    //                  <a
    //                             title="View Listing Details"
    //                             data-id="${a?.eventId}"
    //                             class="btn btn-sm bg-warning btn-icon text-white expand-listings">
    //                             <i class="fa fa-bars"></i>
    //                         </a>
                           
    //                     `
    //   },
    //   responsivePriority: 2,
    // },
  ]

  NoPaginationDatatableConfig(
    data,
    table_id,
    cols,
    'Listings',
    [0, 1, 2, 3, 4, 5, 6, 7],
    [0, 'desc'],
    'Listings',
    false
  )

  $(document).on('click', '.expand-listings', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.eventId === r)
    if (x.length > 0) {
      setItem(x[0], 'expand')
    }
  })
}

export const salesProfileDatatable = (data, table_id, setItem) => {

  console.log('data..........$$%$%$%...',data);
  const cols = [
    {
      data: (a) => {
        return a?.orders?.length || 0;
      },
    },
    {
      data: (a) => {
        return a?.eventDetails?.name
      },
    },
    // {
    //   data: (a) => {
    //     return a?.numberOfTickets
    //   },
    // },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(a?.totalAmount)}`
      },
    },
    // {
    //     data: (a) => {
    //         return a?.price ? `${strText.currency_symbol}${formatNumber(a?.price - a?.commissionAmount)}` : 'N/A'
    //     }
    // },
    {
      data: (a) => {
        return a?.deliveryStatus ?? 'N/A'
      },
    },
    // {
    //     data: (a) => {
    //         return a?.createdAt ? moment(a?.createdAt).format(strText.full_date) : 'N/A'
    //     }
    // },
    // {
    //     data: (a) => {
    //         return a?.deliveryDate ? moment(a?.deliveryDate).format(strText.full_date) : 'N/A'
    //     }
    // },
    // {
    //     data: (a) => {
    //         return a?.sellerDeliveryConfirmation !== null  ?
    //         `
    //              <a title='View Details' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon view-details">
    //                 <i class="fa fa-eye"></i>
    //             </a>
    //         `
    //         :

    //         `
    //              <a title='View Details' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon view-details">
    //                 <i class="fa fa-eye"></i>
    //             </a>
    //             <a title='Confirm Delivery' data-id=${a?.id} class="btn btn-sm bg-success text-white btn-icon confirm-delivery">
    //                 <i class="fa fa-check"></i>
    //             </a>
    //         `
    //     },
    //     responsivePriority: 2
    // }
    // {
    //   data: (a) => {
    //     return `<a title='View Items' data-id=${a?.eventId} class="btn btn-sm bg-warning text-white btn-icon view-sales-items">
    //                     <i class="fa fa-bars"></i>
    //                 </a>`
    //   },
    // },
  ]

  NoPaginationDatatableConfig(
    data,
    table_id,
    cols,
    'Sales',
    [0, 1, 2, 3],
    [0, 'desc'],
    'Sales',
    false
  )

  $(document).on('click', '.view-sales-items', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.eventId === r)
    if (x.length > 0) {
      setItem(x[0], 'view-event-items')
    }
  })

}

export const ordersProfileDatatable = (data, table_id, setItem) => {
  const cols = [
    {
      data: (a) => {
        return a?.orders?.length == 0 ? 'N/A' : a?.orders?.length
      },
    },
    {
      data: (a) => {
        return a?.event.name
      },
    },
    {
      data: (a) => {
        return a?.event?.date ? (format(new Date(a?.event?.date), 'EEE dd MMM yyyy').toUpperCase()) : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.totalTickets
      },
    },
    {
      data: (a) => {
        return a?.totalAmount ? `${strText.currency_symbol}${a?.totalAmount}` : 'N/A'
      },
    },


    // {
    //   data: (a) => {
    //     return a?.deliveryDate
    //       ? moment(a?.deliveryDate).format(strText.full_date)
    //       : 'N/A'
    //   },
    // },
    // {
    //   data: (a) => {
    //     return a?.OrderItems.length == 0
    //       ? 'N/A'
    //       : `
    //                  <a title='View Items' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon view-items">
    //                     <i class="fa fa-bars"></i>
    //                 </a>
    //             `
    //   },
    //   responsivePriority: 2,
    // },
  ]

  NoPaginationDatatableConfig(
    data,
    table_id,
    cols,
    'Order Items',
    [0, 1, 2],
    [0, 'desc'],
    'Order Items',
    false
  )

  $(document).on('click', '.view-items', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItem(x[0], 'view-items')
    }
  })
}

export const paymentProfileDatatable = (data, table_id, setItem) => {
  const cols = [
    {
      data: (a) => {
        return a?.event?.name
      },
    },

    { data: 'numberOfTickets' },
    { data: 'pricePerTicket' },
    { data: 'reservedPricePerTicket' },
    {
      data: (a) => {
        return a?.section?.name
      },
    },
    {
      data: (a) => {
        return a?.subSection?.name
      },
    },
    {
      data: (a) => {
        return moment(a?.createdAt).format(strText.full_date)
      },
    },
    {
      data: (a) => {
        return `
                     <a
                                title="View Listing Details"
                                data-id="${a?.id}"
                                class="btn btn-sm bg-info btn-icon text-white view-listing">
                                <i class="fa fa-eye"></i>
                            </a>
                            <a
                                title="View Tickets"
                                data-id="${a?.id}"
                                class="btn btn-sm bg-success btn-icon text-white view-tickets">
                                <i class="fa fa-ticket"></i>
                            </a>
                        `
      },
      responsivePriority: 2,
    },
  ]

  NoPaginationDatatableConfig(
    data,
    table_id,
    cols,
    'Payments',
    [0, 1, 2, 3, 4, 5, 6],
    [0, 'desc'],
    'Payments',
    false
  )

  $(document).on('click', '.view-listing', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItem(x[0], 'details')
    }
  })
  $(document).on('click', '.view-tickets', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItem(x[0], 'view-tickets')
    }
  })
}

export const ticketsAdminDatatable = (data, table_id, setItems, ticketType) => {
  const cols = [
    {
      data: 'row',
    },
    {
      data: 'seat',
    },
    {
      data: (a) => {
        return a?.soldAt
          ? `
                <span class="badge badge-pill text-white text-capitalize bg-success">
                    Sold
                </span>`
          : `<span class="badge badge-pill text-white text-capitalize bg-danger">
                        Not Sold
                    </span>`
      },
    },
    {
      data: (a) => {
        return a?.soldAt ? moment(a?.soldAt).format(strText.full_date) : 'N/A'
      },
    },
    {
      data: (a) => {
        return ticketType.toLowerCase() == 'electronic' && a?.soldAt != null
          ? `
                     <a
                        title="View Electronic Ticket"
                        data-id="${a?.id}"
                        class="btn btn-sm bg-info btn-icon text-white view-electronic-ticket">
                        <i class="mdi mdi-file-image"></i>
                    </a>
                        `
          : ticketType.toLowerCase() == 'electronic'
            ? `
                     <a
                                title="View Electronic Ticket"
                                data-id="${a?.id}"
                                class="btn btn-sm bg-info btn-icon text-white view-electronic-ticket">
                                <i class="mdi mdi-file-image"></i>
                            </a>
                        <a
                            title=${ticketType.toLowerCase() == 'electronic' &&
              a?.file
              ? 'Edit Ticket'
              : 'Upload Ticket'
            }
                            data-id="${a?.id}"
                            class="btn btn-sm ${ticketType.toLowerCase() == 'electronic' &&
              a?.file
              ? 'bg-warning'
              : 'bg-danger'
            } btn-icon text-white edit-ticket">
                            <i class="fa ${ticketType.toLowerCase() == 'electronic' &&
              a?.file
              ? 'fa-pencil'
              : 'fa-upload'
            }"></i> ${ticketType.toLowerCase() == 'electronic' &&
              a?.file ? "Change" : "Upload"}
                        </a>
                        `
            : `
                     N/A
                        `
      },
      responsivePriority: 2,
    },
  ]

  generalDatatableConfig(
    data,
    table_id,
    cols,
    'Tickets',
    [0, 1, 2, 3],
    [0, 'desc'],
    'Tickets'
  )

  $(document).on('click', '.view-electronic-ticket', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'view')
    }
  })
  $(document).on('click', '.edit-ticket', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'edit')
    }
  })
}

const checkStatus = (a) => {
  let statusMode = 'DELIVERED'
  let status = a?.OrderItems.filter((obj) => (obj.deliveryStatus === 'PENDING' || obj.deliveryStatus === 'FAILED'))
  if (status.length > 0) {
    statusMode = status[0].deliveryStatus
  }
  return statusMode;
}

export const ordersCustomerDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItem
) => {
  const cols = [
    { data: 'orderRef' },
    {
      data: (a) => {
        return a?.OrderItems.length == 0 ? 'N/A' : a?.OrderItems.length
      },
    },
    {
      data: (a) => {
        return a?.commissionAmount
          ? `${strText.currency_symbol}${formatNumber(
            a?.price - a?.commissionAmount
          )}`
          : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.price ? `${strText.currency_symbol}${a?.price}` : 'N/A'
      },
    },
    {
      data: (a) => {
        return checkStatus(a) //(a?.OrderItems[0].deliveryStatus) ?? 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.paidAt ? moment(a?.paidAt).format('ddd DD MMM YYYY').toUpperCase() : 'N/A';
      },
    },
    {
      data: (a) => {
        return a?.deliveryDate
          ? moment(a?.deliveryDate).format(strText.full_date)
          : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.OrderItems.length == 0
          ? 'N/A'
          : `
                     <a title='View Items' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon view-items">
                        <i class="fa fa-bars"></i>
                    </a>
                    <a title='Request refund' data-id=${a?.id} class="btn btn-sm bg-danger text-white btn-icon request-refund">
                        <i class="fa fa-undo"></i>
                    </a>
                `
      },
      responsivePriority: 2,
    },
  ]
  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Orders',
    [0, 1, 2, 3, 4],
    [0, 'desc'],
    'Orders',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    $(document).on('click', '.view-items', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItem(x[0], 'view-items')
      }
    })
    $(document).on('click', '.request-refund', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItem(x[0], 'refund')
      }
    })
  }
}

export const payoutsCustomerDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItem
) => {
  const cols = [
    {
      data: (a) => {
        return a?.id ? a.id : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.amount ? `${strText.currency_symbol}${a?.amount}` : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.eventPayout?.event?.name ?? 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.eventPayout?.event?.type ?? 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.paidAt ? moment(a?.paidAt).format(strText.full_date) : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.status ?? 'N/A'
      },
    },
    // {
    //   data: (a) => {
    //     return a
    //       ? `
    //         <a title='View Items' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon view-items">
    //             <i class="fa fa-bars"></i>
    //         </a>
    //     `
    //       : 'N/A'
    //   },
    //   responsivePriority: 2,
    // },
  ]
  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Payouts',
    [0, 1, 2, 3, 4],
    [0, 'desc'],
    'Payouts',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    $(document).on('click', '.view-items', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItem(x[0], 'view-items')
      }
    })
  }
}

export const payoutsProfileDatatable = (data, table_id, setItem) => {
  console.log("🚀 ~ payoutsProfileDatatable ~ data:", data)
  const cols = [
    {
      data: (a) => {
        return a?.id ? a.id : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.amount ? `${strText.currency_symbol}${a?.amount}` : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.eventPayout?.event?.name ?? 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.eventPayout?.event?.type ?? 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.paidAt ? moment(a?.paidAt).format(strText.full_date) : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.status ?? 'N/A'
      },
    },
    // {
    //   data: (a) => {
    //     return a
    //       ? `
    //         <a title='View Items' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon view-items">
    //             <i class="fa fa-bars"></i>
    //         </a>
    //     `
    //       : 'N/A'
    //   },
    //   responsivePriority: 2,
    // },
  ]

  NoPaginationDatatableConfig(
    data,
    table_id,
    cols,
    'Order Items',
    [0, 1, 2],
    [0, 'desc'],
    'Order Items',
    false
  )

  $(document).on('click', '.view-items', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItem(x[0], 'view-items')
    }
  })
}

export const salesCustomerDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItem
) => {
  {/* XD_J */ }
  const checkCondition = (a) => {
    return a?.data?.[0]?.ticket?.listing?.ticketsType === 'MOBILE' ||
      a?.data?.[0]?.ticket?.listing?.ticketsType === 'ELECTRONIC';
  };
  {/* XD_J */ }
  const cols = [
    {
      // data: null,
      // title: "Serial No.",
      // render: (data, type, row, meta) => {
      //   // Render the serial number based on row index + 1
      //   return meta.row + 1;
      // },
      data: (a) => {
        console.log('a12', a);
        return a?.orders?.length || 0;
      },
    },
    {
      data: (a) => {
        return a?.eventDetails?.name
      },
    },
    {
      data: (a) => {
        //return a?.eventDetails?.date ? moment(a?.deliveryDate?.date).format(strText.full_date) : 'N/A'
        return moment(a?.eventDetails?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A';
      },
    },
    {
      data: (a) => {
        return a?.numberOfTickets
      },
    },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(a?.totalAmount)}`
      },
    },
    // {
    //     data: (a) => {
    //         return a?.price ? `${strText.currency_symbol}${formatNumber(a?.price - a?.commissionAmount)}` : 'N/A'
    //     }
    // },
    // {
    //   data: (a) => {
    //     return a?.deliveryStatus ?? 'N/A'
    //   },
    // },
    // {
    //     data: (a) => {
    //         return a?.createdAt ? moment(a?.createdAt).format(strText.full_date) : 'N/A'
    //     }
    // },
    // {
    //     data: (a) => {
    //         return a?.deliveryDate ? moment(a?.deliveryDate).format(strText.full_date) : 'N/A'
    //     }
    // },
    // {
    //     data: (a) => {
    //         return a?.sellerDeliveryConfirmation !== null  ?
    //         `
    //              <a title='View Details' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon view-details">
    //                 <i class="fa fa-eye"></i>
    //             </a>
    //         `
    //         :

    //         `
    //              <a title='View Details' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon view-details">
    //                 <i class="fa fa-eye"></i>
    //             </a>
    //             <a title='Confirm Delivery' data-id=${a?.id} class="btn btn-sm bg-success text-white btn-icon confirm-delivery">
    //                 <i class="fa fa-check"></i>
    //             </a>
    //         `
    //     },
    //     responsivePriority: 2
    // }

    // {
    //   data: (a) => {
    //      return a?.data?.[0]?.order?.user?.name ?? '';
    //   },
    //  },
    // {
    //   data: (a) => {
    //    return a?.data?.[0]?.order?.user?.email ?? '';
    //   },
    // },
    // {
    //   data: (a) => {
    //     if (checkCondition(a)) {
    //       return a?.data?.[0]?.order?.user?.firstLineAddress ?? '';
    //     } else {
    //       return a?.deliveryAddress?.address ?? '';
    //     }
    //   },
    // },
    // {
    //   data: (a) => {
    //     if (checkCondition(a)) {
    //       return a?.data?.[0]?.order?.user?.town ?? '';
    //     } else {
    //       return a?.deliveryAddress?.town ?? '';
    //     }
    //   },
    // },
    // {
    //   data: (a) => {
    //     if (checkCondition(a)) {
    //       return a?.data?.[0]?.order?.user?.postCode ?? '';
    //     } else {
    //       return a?.deliveryAddress?.postCode ?? '';
    //     }
    //   },
    // },
    // {
    //   data: (a) => {
    //     if (checkCondition(a)) {
    //       return a?.data?.[0]?.order?.user?.phone ?? '';
    //     } else {
    //       return a?.deliveryAddress?.phone ?? '';
    //     }
    //   },
    // },
    {
      data: (a) => {
        // return `<a title='View Items' data-id=${a?.orderRef} class="btn btn-sm bg-warning text-white btn-icon view-sales-items">
        //                 <i class="fa fa-bars"></i>
        //             </a>`

        return `  <a title='View Items' data-id=${a?.eventId} class="btn btn-sm bg-warning text-white btn-icon view-event-items">
        <i class="fa fa-bars"></i>
    </a>`
      },
      responsivePriority: 1,
    },
  ]
  console.log('Columns:', cols);
  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Sales',
    [0, 1, 2, 3],
    [0, 'desc'],
    'Sales',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {

    $(document).on('click', '.view-event-items', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.eventId === r)
      if (x.length > 0) {
        setItem(x[0], 'view-event-items')
      }
    })
    // $(document).on('click', '.view-details', function () {
    //   const r = $(this).attr('data-id')
    //   let x = data.filter((item) => item?.id === r)
    //   if (x.length > 0) {
    //     setItem(x[0], 'details')
    //   }
    // })
    // $(document).on('click', '.view-sales-items', function () {
    //   const r = $(this).attr('data-id')
    //   let x = data.filter((item) => item?.orderRef === r)
    //   if (x.length > 0) {
    //     setItem(x[0], 'view-items')
    //   }
    // })
    //  $(document).on("click", ".confirm-delivery", function () {
    //     const r = $(this).attr("data-id")
    //     let x = data.filter((item) => item?.id === r)
    //     if (x.length > 0) {
    //         setItem(x[0], 'confirm')
    //     }
    // })
  }
}

export const itemsEventSalesCustomerDatatable = (
  data, table_id, setItem
) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    return formatter.format(date);
  };
  const cols = [
    {
      data: (a) => {
        return a?.orderRef
      },
    },
    {
      data: (a) => {
        return a?.OrderItems?.length || 0;
      },
    },
    // {
    //   data: (a) => {
    //     return a?.price - a?.commissionAmount
    //   },
    // },
    // {
    //   data: (a) => {
    //     return a?.price ? `${strText.currency_symbol}${formatNumber(a?.price - a?.commissionAmount)}` : 'N/A'
    //   }
    // },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(a?.price)}`
      },
    },


    // {
    //   data: (a) => {
    //     return a?.OrderItems?.deliveryStatus ?? 'N/A'
    //   },
    // },
    {
      data: (a) => {
        return a?.createdAt ? formatDate(a?.createdAt) : 'N/A'
      }
    },
    {
      data: (a) => {
        return a?.deliveryStatus
      }
    },
    {
      data: (a) => {
        return a?.OrderItems[0]?.order?.user?.name
      }
    },
    {
      data: (a) => {
        return a?.OrderItems[0]?.order?.user?.email
      }
    },
    {
      data: (a) => {
        return a?.OrderItems[0]?.order?.user?.firstLineAddress
      }
    },
    {
      data: (a) => {
        return a?.OrderItems[0]?.order?.user?.town
      }
    },
    {
      data: (a) => {
        return a?.OrderItems[0]?.order?.user?.postCode
      }
    },
    {
      data: (a) => {
        return a?.OrderItems[0]?.order?.user?.phone
      }
    },
    // {
    //     data: (a) => {
    //         return a?.deliveryDate ? moment(a?.deliveryDate).format(strText.full_date) : 'N/A'
    //     }
    // },
    // {
    //     data: (a) => {
    //         return a?.sellerDeliveryConfirmation !== null  ?
    //         `
    //              <a title='View Details' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon view-details">
    //                 <i class="fa fa-eye"></i>
    //             </a>
    //         `
    //         :

    //         `
    //              <a title='View Details' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon view-details">
    //                 <i class="fa fa-eye"></i>
    //             </a>
    //             <a title='Confirm Delivery' data-id=${a?.id} class="btn btn-sm bg-success text-white btn-icon confirm-delivery">
    //                 <i class="fa fa-check"></i>
    //             </a>
    //         `
    //     },
    //     responsivePriority: 2
    // }


    {
      data: (a) => {
        return `<a title='View Items' data-id=${a?.orderRef} class="btn btn-sm bg-warning text-white btn-icon view-sales-items">
                        <i class="fa fa-bars"></i>
                    </a>`

      },
      responsivePriority: 1,
    },
  ]
  // serverSideDatatableConfig(
  //   callbackFunc,
  //   errorFunc,
  //   table_id,
  //   cols,
  //   'Sales',
  //   [0, 1, 2, 3],
  //   [0, 'desc'],
  //   'Sales',
  //   endpoint,
  //   searchEndPoint,
  //   token
  // )
  generalDatatableConfig(
    data,
    table_id,
    cols,
    'Sales',
    [0, 1, 2, 3],
    [0, 'desc'],
    'Sales'
  )

  //function callbackFunc(data) {

  // $(document).on('click', '.view-event-items', function () {
  //   const r = $(this).attr('data-id')
  //   alert(r)
  //   let x = data.filter((item) => item?.eventId === r)
  //   if (x.length > 0) {
  //     setItem(x[0], 'view-event-items')
  //   }
  // })
  // $(document).on('click', '.view-details', function () {
  //   const r = $(this).attr('data-id')
  //   let x = data.filter((item) => item?.id === r)
  //   if (x.length > 0) {
  //     setItem(x[0], 'details')
  //   }
  // })
  $(document).on('click', '.view-sales-items', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.orderRef === r)
    if (x.length > 0) {
      setItem(x[0], 'view-items')
    }
  })
  //  $(document).on("click", ".confirm-delivery", function () {
  //     const r = $(this).attr("data-id")
  //     let x = data.filter((item) => item?.id === r)
  //     if (x.length > 0) {
  //         setItem(x[0], 'confirm')
  //     }
  // })
  //}
}
export const itemsCustomerDatatable = (data, table_id, setItems) => {
  const cols = [
    {
      data: (a) => {
        return a?.ticket?.listing?.event?.name
      },
    },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(
          a?.price - a?.commissionAmount
        )}`
      },
    },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(a?.commissionAmount)}`
      },
    },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(a?.price)}`
      },
    },
    {
      data: (a) => {
        return a?.ticket?.listing?.event?.type
      },
    },
    {
      data: (a) => {
        return a?.ticket?.listing?.ticketsType
      },
    },
    {
      data: (a) => {
        return a?.deliveryStatus
      },
    },
    // {
    //     data: (a) => {
    //             return moment(a?.createdAt).format(strText.full_date)
    //     }
    // },
    // {
    //   data: (a) => {
    //     return a?.buyerDeliveryConfirmation !== null
    //       ? 'N/A'
    //       : `
    //                  <a title='Confirm Delivery' data-id=${a?.id} class="btn btn-sm bg-success text-white btn-icon confirm-delivery">
    //                     <i class="fa fa-check"></i>
    //                 </a>
    //                     `
    //   },
    //   responsivePriority: 2,
    // },
  ]

  generalDatatableConfig(
    data,
    table_id,
    cols,
    'Order Items',
    [0, 1, 2, 3, 4, 5, 6],
    [0, 'desc'],
    'Order Items'
  )

  $(document).on('click', '.confirm-delivery', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'confirm')
    }
  })
}

export const itemsSalesCustomerDatatable = (data, table_id, setItems) => {
  {/* XD_J */ }
  const checkCondition = (a) => {
    return a?.ticket?.listing?.ticketsType === 'MOBILE' ||
      a?.ticket?.listing?.ticketsType === 'ELECTRONIC';
  };
  {/* XD_J */ }
  const cols = [
    {
      data: (a) => {
        return a?.ticket?.listing?.numberOfTickets
      },
    },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(
          a?.price - a?.commissionAmount
        )}`
      },
    },

    {
      data: (a) => {
        return a?.ticket?.listing?.event?.type
      },
    },
    {
      data: (a) => {

        return `${strText.currency_symbol}${formatNumber(a?.commissionAmount)}`
      },
    },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(a?.price)}`
      },
    },
    {
      data: (a) => {
        return a?.ticket?.listing?.ticketsType
      },
    },
    {
      data: (a) => {
        return a?.deliveryStatus
      },
    },
    // {
    //     data: (a) => {
    //             return moment(a?.createdAt).format(strText.full_date)
    //     }
    // },
    // {
    //     data: (a) => {
    //         return (
    //              `
    //              <a
    //                     title="Edit Section"
    //                     data-id="${a?.id}"
    //                     class="btn btn-sm bg-warning btn-icon text-white edit-section">
    //                     <i class="fa fa-pencil"></i>
    //                 </a>
    //             <a title="Delete Section" data-id="${a?.id}"
    //                     class="btn btn-sm bg-danger btn-icon text-white delete-section">
    //                     <i class="fa fa-trash"></i>
    //                 </a>
    //                     <a title='Manage Subsections' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon section-manage-subsections">
    //                         <i class="fa fa-bars"></i>
    //                     </a>
    //                 `
    //             )
    //     },
    //     responsivePriority: 2
    // }
    {
      data: (a) => {
        return a?.buyerDeliveryConfirmation
          ? moment(a?.buyerDeliveryConfirmation).format(strText.full_date)
          : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.ticket?.listing?.section?.name ?? "No Section"
      },
    },
    {
      data: (a) => {
        return a?.ticket?.listing?.subSection?.name ?? "No Block"
      },
    },
    {
      data: (a) => {
        return a?.ticket?.row ?? "No Row"
      },
    },
    {
      data: (a) => {
        return a?.order?.user?.name ?? '';
      },
    },
    {
      data: (a) => {
        return a?.order?.user?.email ?? '';
      },
    },
    {
      data: (a) => {
        if (checkCondition(a)) {
          return a?.order?.user?.firstLineAddress ?? '';
        }
        else {
          return a?.deliveryAddress?.address ?? '';
        }
      },
    },
    {
      data: (a) => {
        if (checkCondition(a)) {
          return a.order?.user?.town ?? '';
        }
        else {
          return a?.deliveryAddress?.town ?? '';
        }
      },
    },
    {
      data: (a) => {
        if (checkCondition(a)) {
          return a.order?.user?.postCode ?? '';
        }
        else {
          return a?.deliveryAddress?.postCode ?? '';
        }
      },
    },
    {
      data: (a) => {
        if (checkCondition(a)) {
          return a.order?.user?.phone ?? '';
        }
        else {
          return a?.deliveryAddress?.phoneNumber ?? '';
        }
      },
    },
    {
      data: (a) => {
        {/* XD_J */ }
        if (a?.deliveryStatus === 'DELIVERED') {
          // If the delivery status is 'DELIVERED', hide the action field by returning an empty string
          return 'No Action';
        } else if (a?.buyerDeliveryConfirmation !== null) {
          // If buyer delivery confirmation is not null, return 'N/A'
          return `
              N/A
          `;
        } else {
          // Otherwise, show the confirm delivery button
          return `
              <a title='Confirm Delivery' data-id=${a?.id} class="btn btn-sm bg-success text-white btn-icon confirm-delivery">
                  <i class="fa fa-check"></i>
              </a>
          `;
        }
        {/* XD_J */ }
      },

      responsivePriority: 2,
    },

  ]

  generalDatatableConfig(
    data,
    table_id,
    cols,
    'Sales Items',
    [0, 1, 2, 3, 4, 5, 6],
    [0, 'desc'],
    'Sales Items'
  )
  $(document).on('click', '.confirm-delivery', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'confirm')
    }
  })

  // $(document).on("click", ".section-manage-subsections", function () {
  //     const r = $(this).attr("data-id")
  //     let x = data.filter((item) => item?.id === r)
  //     if (x.length > 0) {
  //         setItems(x[0], 'manage-subsections')
  //     }
  // })
  // $(document).on("click", ".edit-section", function () {
  //     const r = $(this).attr("data-id")
  //     let x = data.filter((item) => item?.id === r)
  //     if (x.length > 0) {
  //         setItems(x[0], 'edit')
  //     }
  // })
  // $(document).on("click", ".delete-section", function () {
  //     const r = $(this).attr("data-id")
  //     let x = data.filter((item) => item?.id === r)
  //     if (x.length > 0) {
  //         setItems(x[0], 'delete')
  //     }
  // })
}


const generalDatatableConfig = (
  data,
  table_id,
  cols,
  table_name,
  exportRow = [0, 1, 2],
  ordering = [0, 'desc'],
  pageTitle,
  hasPrintBtn = true,
  btnName = `${strText.admin_export_text}`
) => {
  console.log('data..&&&.....', data);
  let isLandScape = true
  let exportTitle = `<img src="${strText.app_logo}" alt="" height="90"/>`
  let exportOptionsIndex = exportRow
  let dom = hasPrintBtn
    ? "<'row'<'col-sm-12'B>>" +
    "<'row align-items-center'<'col-12 col-lg-6'l><'pt-2 pt-md-0 col-12 col-lg-6'f>>" +
    "<'row dt-row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"
    : "<'row align-items-center'<'col-12 col-lg-6'l><'pt-2 pt-md-0 col-12 col-lg-6'f>>" +
    "<'row dt-row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"

  let fromDate = ''
  let toDate = ''
  let total = 0
  let other_title = table_name ?? 'primetic'

  $(document).ready(function () {
    let idInit = $(`#${table_id}`)
    idInit.DataTable().clear().destroy()
    idInit.DataTable({
      data: data,
      info: true,
      searching: false,
      dom: dom,
      lengthChange: true,
      lengthMenu: [
        [5, 10, 15, -1],
        [5, 10, 15, 'All'],
      ],
      language: {
        paginate: {
          previous: `
                                <span aria-hidden="true" class="mdi mdi-chevron-left mr-1"></span> Prev
                                <span class="sr-only">Previous</span>`,
          next: `Next
                            <span aria-hidden="true" class="mdi mdi-chevron-right ml-1"></span>
                            <span class="sr-only">Next</span>`,
        },
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
      },
      columns: cols,
      responsivePriority: 1,
      responsive: true,
      // order: [ordering],
      ordering: false,
      pageLength: 10,
      buttons: [
        {
          extend: 'csv',
          text: btnName,
          exportOptions: {
            columns: exportOptionsIndex,
          },
          title: exportTitle,
          className: 'btn btn-primary btn-sm mx-1',
          header: true,
          messageTop: `<table style="border-bottom:1px solid black;width:100% !important;text-align:center;margin-bottom: 30px;margin-top: 30px;">
                                        <tr>
                                            <th colspan="3" style="align-content: center; align-items:center;font-size: 2rem;"> ${pageTitle}</th>
                                            <th>  </th>
                                            <th>   </th>
                                        </tr>
                                        
                                    </table>`,
          filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
        },
      ],
      initComplete: function (settings, json) {
        if (data && data.length > 0 && data[0]?.event) {
          idInit.before(`
            <div class="table-heading" style="text-align: right; margin-bottom: 20px;">
              <h2 class="event-sec-text"><b>Event<span class="evnt-text-pl">:</span>${data[0].event.name}</b></h2>
              <h4 class="event-sec-text"><b>Event Date<span class="evnt-text-pl">:</span>${format(new Date(data[0].event.date), 'EEE dd MMM yyyy').toUpperCase()}</b></h4>
            </div>
          `);
        }
      }
    })
  })
}

const serverSideDatatableConfig = (
  callback,
  errorFunc,
  table_id,
  cols,
  table_name,
  exportRow = [0, 1, 2],
  ordering = [0, 'desc'],
  pageTitle,
  endpoint,
  searchEndPoint,
  token,
  hasPrintBtn = true,
  btnName = `${strText.admin_export_text}`
) => {
  let isLandScape = true
  let exportTitle = `<img src="${strText.app_logo}" alt="" height="90"/>`
  let exportOptionsIndex = exportRow
  let dom = hasPrintBtn
    ? "<'row'<'col-sm-12'B>>" +
    "<'row align-items-center'<'col-12 col-lg-6'l><'pt-2 pt-md-0 col-12 col-lg-6'f>>" +
    "<'row dt-row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"
    : "<'row align-items-center'<'col-12 col-lg-6'l><'pt-2 pt-md-0 col-12 col-lg-6'f>>" +
    "<'row dt-row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"

  let fromDate = ''
  let toDate = ''
  let total = 0
  let other_title = table_name ?? 'primetic'

  $(document).ready(function () {
    let idInit = $(`#${table_id}`)
    idInit.DataTable().clear().destroy()

    let dataTable = idInit.DataTable({
      searching: searchEndPoint ? true : false,
      serverSide: true,
      processing: true,
      ajax: {
        url: endpoint,
        type: 'GET',
        headers: {
          'Content-type': 'application/json',
          'X-API-Version': 0,
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: function (d) {
          d.page = Math.floor(d.start / d.length) + 1 // Calculate current page
          d.pageSize = d.length // Records per page
          return $.param({
            page: d.page,
            pageSize: d.pageSize,
            draw: d.draw,
            q: d.search.value,
          })
          // Add any additional parameters you need to send to the server
        },
        dataSrc: function (response) {
          if (callback && typeof callback === 'function') {
            callback(response.data) // Pass the data to the callback function
          }
          return response.data
        },
        error: function (xhr, error, thrown) {
          // Handle the error here
          if (thrown && thrown?.toLowerCase() == 'unauthorized') {
            errorFunc(thrown)
          }
        },
      },
      info: true,
      dom: dom,
      lengthChange: true,
      lengthMenu: [
        [5, 10, -1],
        [5, 10, 'All'],
      ],
      language: {
        paginate: {
          previous: `
                                <span aria-hidden="true" class="mdi mdi-chevron-left mr-1"></span> Prev
                                <span class="sr-only">Previous</span>`,
          next: `Next
                            <span aria-hidden="true" class="mdi mdi-chevron-right ml-1"></span>
                            <span class="sr-only">Next</span>`,
        },
        processing: "<i class='fa fa-refresh fa-spin text-center'></i>",
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
      },
      columns: cols,
      responsivePriority: 1,
      responsive: true,
      ordering: false,
      pageLength: 10,
      buttons: [
        {
          extend: 'csv',
          text: btnName,
          exportOptions: {
            columns: exportOptionsIndex,
          },
          title: exportTitle,
          className: 'btn btn-primary btn-sm mx-1',
          header: true,
          messageTop: `<table style="border-bottom:1px solid black;width:100% !important;text-align:center;margin-bottom: 30px;margin-top: 30px;">
                                        <tr>
                                            <th colspan="3" style="align-content: center; align-items:center;font-size: 2rem;"> ${pageTitle}</th>
                                            <th>  </th>
                                            <th>   </th>
                                        </tr>
                                        
                                    </table>`,
          filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
        },
      ],
    })

    if (searchEndPoint) {
      let searchInput = $('div.dataTables_filter input')

      searchInput.off()
      // Bind keypress event
      searchInput.on('keydown', function (e) {
        // Check if the pressed key is Enter (key code 13)
        if (e.which === 13) {
          // Prevent the default search behavior
          e.preventDefault()

          dataTable.ajax.url(searchEndPoint)

          // Perform the search manually
          dataTable.search($(this).val()).draw()
        }
      })
    }
  })
}

const NoPaginationDatatableConfig = (
  data,
  table_id,
  cols,
  table_name,
  exportRow = [0, 1, 2],
  ordering = [0, 'desc'],
  pageTitle,
  hasPrintBtn = true,
  btnName = `${strText.admin_export_text}`
) => {
  let isLandScape = true
  let exportTitle = `<img src="${strText.app_logo}" alt="" height="90"/>`
  let exportOptionsIndex = exportRow
  let dom = hasPrintBtn
    ? "<'row'<'col-sm-12'B>>" + "<'row dt-row'<'col-sm-12'tr>>"
    : "<'row dt-row'<'col-sm-12'tr>>"

  let fromDate = ''
  let toDate = ''
  let total = 0
  let other_title = table_name ?? 'primetic'

  $(document).ready(function () {
    let idInit = $(`#${table_id}`)
    idInit.DataTable().clear().destroy()
    idInit.DataTable({
      data: data,
      info: true,
      dom: dom,
      paging: false,
      lengthChange: true,
      lengthMenu: [
        [5, 10, 15, -1],
        [5, 10, 15, 'All'],
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
      },
      columns: cols,
      responsivePriority: 1,
      responsive: true,
      // order: [ordering],
      ordering: false,
      pageLength: 10,
      buttons: [
        {
          extend: 'csv',
          text: btnName,
          exportOptions: {
            columns: exportOptionsIndex,
          },
          title: exportTitle,
          className: 'btn btn-primary btn-sm mx-1',
          header: true,
          messageTop: `<table style="border-bottom:1px solid black;width:100% !important;text-align:center;margin-bottom: 30px;margin-top: 30px;">
                                        <tr>
                                            <th colspan="3" style="align-content: center; align-items:center;font-size: 2rem;"> ${pageTitle}</th>
                                            <th>  </th>
                                            <th>   </th>
                                        </tr>
                                        
                                    </table>`,
          filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
        },
      ],
    })
  })
}
