import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery' // Import jQuery
import 'maphilight'
import parse from 'html-react-parser'
import { useDispatch } from 'react-redux'
import '../../../../assets/css/stadium_map_styles.css'
import {
  setEventValues,
  setSelectedMapSection,
} from '../../../../config/redux/slice/appSlice'

function StadiumMap({ stadiumObj }) {
  const dispatch = useDispatch()
  const imgRef = useRef()
  const [areas, setAreas] = useState([])
  const [cardContainer, setCardContainer] = useState('')
  const [subtraction] = useState(40)
  const timeoutId = useRef(null); //xdsd

  //   useEffect(() => {
  //     // Update area coordinates when image dimensions change
  //         if(cardContainer == ''){
  //             setCardContainer(document.getElementById("stadium-map-card"))
  //         }

  //         setSvgContainer(document.getElementById("stadium-svg"))

  //     if(areas.length == 0){
  //         setAreas(document.querySelectorAll("area"))

  //     }
  //     //  if(areas.length != 0 && cardContainer && cardContainer != ''  && svgContainer && imgHeight != 0){
  //     //     resizeImageMap();
  //     // }

  //     // Initialize maphilight when the component mounts
  //     // initializeMaphilight();
  //     return () => {
  //       $('.maparea').data('maphilight', false);
  //     };
  //   }, [svgContainer]);

  // useEffect(() => {
  //     if(cardContainer == ''){
  //         setCardContainer(document.getElementById("stadium-map-card"))
  //     }

  //     if(cardContainer){
  //         setSvgContainer(document.getElementById("stadium-svg"))
  //     }

  //     if(areas.length == 0){
  //         setAreas(document.querySelectorAll("area"))

  //     }

  //     // Attach click events to all areas
  //     if(svgContainer){
  //         svgContainer.addEventListener('load', (event) => {
  //             // setImgHeight(svgContainer.clientHeight)
  //      setTimeout(()=>{
  //         resizeImageMap();
  //             initializeMaphilight();
  //             dispatch(setEventValues({propertyKey: 'pageLoading', value: false}))
  //      }, [500])

  //         });
  //     }

  //     // Cleanup event listeners on component unmount
  // }, [svgContainer, cardContainer]);

  useEffect(() => {
    if (cardContainer === '') {
      setCardContainer(document.getElementById('stadium-map-card'))
    }

    // if(cardContainer){
    //     setSvgContainer(document.getElementById("stadium-svg"))
    // }

    if (areas.length === 0) {
      setAreas(document.querySelectorAll('area'))
    }

    // Attach click events to all areas
    if (imgRef.current) {
      imgRef.current.addEventListener('load', (event) => {
        // setImgHeight(svgContainer.clientHeight)
        setTimeout(() => {
          resizeImageMap()
          initializeMaphilight()
          dispatch(setEventValues({ propertyKey: 'pageLoading', value: false }))
        }, [500])
      })
    }

    // Cleanup event listeners on component unmount
  }, [imgRef, cardContainer])


  ////////////////////////////////////////////xdsd
  // useEffect(() => {
  //   // Attach click events to all areas
  //   if (areas) {
  //     areas.forEach((area) => {
  //       area.addEventListener('click', (event) => handleAreaClick(event, area))
  //     })
    
  //     areas.forEach((area) => {
  //       area.addEventListener('mouseover', (event) => handleAreaClick(event, area))
  //     })
  //   }

  //   // Cleanup event listeners on component unmount
  // }, [areas])

  useEffect(() => {
    if (areas) {
      areas.forEach((area) => {
        area.addEventListener('click', (event) => handleAreaClick(event, area));

        area.addEventListener('mouseover', (event) => {
          clearTimeout(timeoutId.current);

          timeoutId.current = setTimeout(() => {
            handleAreaClick(event, area);
          }, 1000);
        });

        area.addEventListener('mouseout', () => {
          clearTimeout(timeoutId.current);
        });
      });
    }

    return () => {
      areas.forEach((area) => {
        area.removeEventListener('click', (event) => handleAreaClick(event, area));
        area.removeEventListener('mouseover', (event) => {
          clearTimeout(timeoutId.current);
          setTimeout(() => {
            handleAreaClick(event, area);
          }, 1000);
        });
        area.removeEventListener('mouseout', () => {
          clearTimeout(timeoutId.current);
        });
      });
    };
  }, [areas]);
  /////////////////////xdsd

  useEffect(() => {
    // Attach click events to all area

    // Cleanup event listeners on component unmount
    return () => {
      $('.maparea').data('maphilight', false)
      if (imgRef.current) {
        imgRef.current.removeEventListener('load', (event) => {
          resizeImageMap()
        })
      }
      areas.forEach((area) => {
        area.removeEventListener('click', (event) =>
          handleAreaClick(event, area)
        )
      })
      imgRef.current = null
    }
  }, [])

  const initializeMaphilight = () => {
    $('.maparea').maphilight()
  }

  function resizeImageMap() {
    // Get the current SVG dimensions
    const newWidth = imgRef.current.clientWidth
    const newHeight = imgRef.current.clientHeight

    // Calculate the scaling factor
    const scaleX = newWidth / stadiumObj.svgWidth
    const scaleY = newHeight / stadiumObj.svgHeight

    // Update the coordinates of each <area> based on the scaling factor
    for (let i = 0; i < areas.length; i++) {
      const coords = areas[i].getAttribute('coords').split(',')

      // Apply the scaling factor to each coordinate pair
      const newCoords = coords.map((coord, index) => {
        return index % 2 === 0
          ? Math.round(coord * scaleX)
          : Math.round(coord * scaleY)
      })

      // Update the coordinates attribute with the new coordinates
      areas[i].setAttribute('coords', newCoords.join(','))
      // areas[i].setAttribute("id", "democlassName");
    }
    // setAreas(areas)
  }

  const handleAreaClick = (event, area) => {
    // Prevent the default behavior (e.g., scrolling)
    event.preventDefault()
    // Get the title attribute of the clicked area
    const areaTitle = area.getAttribute('title')
    dispatch(setSelectedMapSection(areaTitle.toUpperCase()))
  
    // Copy the areaTitle to clipboard
    // copyToClipboard(areaTitle.toUpperCase());
  }

  // function copyToClipboard(text) {
  //         navigator.clipboard.writeText(text)
  //             .then(() => {
  //                 alert('Text copied to clipboard: ' + text);
  //             })
  //             .catch(err => {
  //                 console.error('Unable to copy text: ', err);
  //             });
  //     }

  return (
    <div style={{ backgroundColor: '#FFF' }} className="card mb-4 border-0">
      <div id="stadium-map-card" className="card-body border-0 px-2 px-sm-3">
        {cardContainer !== '' && cardContainer && (
          <>
            <img
              width={cardContainer.clientWidth - subtraction}
              // width={1176}
              id="stadium-svg"
              ref={imgRef}
              src={stadiumObj.svg}
              alt="stadium map"
              useMap="#image-map"
              className="maparea"
            />
          </>
        )}
        {parse(stadiumObj.hoverCode)}
        {/* </> */}
        {/* } */}
      </div>
    </div>
  )
}

export default StadiumMap
