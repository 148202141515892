import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onAddListing, onAddTicket, onTicketUploadComplete, setCustomerErrorMessage, setCustomerLoading, setData, setLastVisitedPage } from '../../../../config/redux/slice/customerSlice'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'
import ListingPageLayout from './ListingPageLayout'
import { truncateMiddle } from '../../../../helpers/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import moment from 'moment'

export default function TicketUpload() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {register, handleSubmit, watch, clearErrors, resetField, getValues, setError, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [showUploadForm, setShowUploadForm] = useState(false)
    const [clickedTicket, setClickedTicket] = useState('')
    const [lastUploadedTicket, setLastUploadedTicket] = useState('')
    const [uploadedTicket, setUploadedTicket] = useState([])
    const [formData, setFormData] = useState(new FormData())
    const [errorMsg, setErrorMsg] = useState(null)
    const [info_list, set_info_list] = useState([])

    const {
        customerLoading,
      eventManagement:{eventDetails},
      ticketManagement: {ticketsData},
      listingManagement: {listingDetails}
    } = useSelector((state) => state.customer)

    const {
        token
    } = useSelector((state) => state.authData)

    useEffect(()=>{
        getTickets()
    },[])

    useEffect(()=>{
        set_info_list([{
            id: 1,
            title: 'Event',
            description: eventDetails?.name
        },
        {
            id: 2,
            title: 'Venue',
            description: eventDetails?.Venue?.name
        },
        {
            id: 3,
            title: 'Section',
            description: listingDetails?.section?.name
        },
        {
            id: 4,
            title: 'SubSection',
            description: listingDetails?.subSection?.name
        },
        {
            id: 5,
            title: 'Date',
            description: moment(eventDetails?.date).format('YYYY-MM-DD')
        },
        {
            id: 6,
            title: 'Time',
            description: ''
        }
    ])
    }, [eventDetails])

    const getTickets = async () =>{
      dispatch(clearAll({}))
      const res = await get_delete_request(
        'get',
        token,
        `${app_api.get_single_listing(listingDetails?.id)}`,
        {},
        dispatch,
        "",
        "",
        setCustomerErrorMessage
      )
      if(res !== undefined && res.status < 400){
        let x = (res?.data?.data?.Tickets).filter((opt)=>opt.file == null)
        dispatch(setData({data: x, type: 'ticket'}))
      }else if(res !== undefined && res.status == 401){
        let path = window.location.pathname
        dispatch(setLastVisitedPage(path))
      }
    }
    const validateFileSize = (file) => {
        const maxSizeInMB = 1; // Maximum file size in MB
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
        return file.size <= maxSizeInBytes;
    }

    const onFileChange = (e, idValue) => {
        const file = e.target.files[0];
    
        // Clear any previous error messages related to file validation
        clearErrors('ticket');
    
        if (file) {
            if (validateFileSize(file)) {
                setErrorMsg(null); // Clear any previous error messages
                addFileName(e, idValue);
            } else {
                // setErrorMsg('File Exceeds 1MB');
                e.target.value = ''; // Clear the file input
                document.getElementById(idValue).innerHTML = "No file Chosen";
                setError('ticket', {
                    type: 'manual',
                    message: 'File size exceeds 1MB', // Custom error message
                });
            }
        } else {
            document.getElementById(idValue).innerHTML = "No file Chosen";
        }
    };
    
     const onSubmit = async (data) => {
        dispatch(clearAll({}))
        setErrorMsg(null)
        let ticketId = clickedTicket

        for (let key in data){ 
          if(typeof data[key] == "object"){
            if(data[key].length != 0){
                formData.append(key, data[key][0])
            }
          } else{
              formData.append(key, data[key])
          }
        }

        const res = await post_put_patch_request(
            "patch",
            token,
            `${app_api.upload_ticket(clickedTicket)}`,
            formData,
            dispatch,
            setCustomerLoading,
            "",
            setCustomerErrorMessage,
            true
        )
    if(res !== undefined && res.status < 400){
        reset()
        resetFileInp()
        setUploadedTicket([...uploadedTicket, ticketId])
        setFormData(new FormData())
        setTimeout(()=>{
            setLastUploadedTicket(ticketId)
        },1000)
        setTimeout(()=>{
            let x = ticketsData.filter((opt)=>opt.id != ticketId)
            dispatch(onAddTicket(x))
            if(x.length == 0){
                dispatch(onTicketUploadComplete())
                navigate(app_urls.user_listings)
            }
        },1300)

    }else{
        setErrorMsg(res.data.message)
        setFormData(new FormData())
    }
    }

        function addFileName(e, idValue){
      let fileName = e.target.value;
      if(fileName !== ''){
        fileName = fileName.replace(/^.*[\\\/]/, '')
        document.getElementById(idValue).innerHTML = truncateMiddle(fileName, 30)
      }else{
        document.getElementById(idValue).innerHTML = "No file Chosen"
      }
    }

    useEffect(() => {
        if (clickedTicket) {
            setErrorMsg(null); // Clear error message when a new ticket is selected
        }
    }, [clickedTicket]);


    function resetFileInp(){
      document.querySelectorAll('.custom-file-label').forEach(()=>{
        resetFileInp.innerHTML = 'Choose file...'
      })
    }

  return (
    <ListingPageLayout  heading={'Listing Information'} info_list={info_list}>
        <h5 className="mb-3 fw-bold">Tickets Upload</h5>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <>
            {ticketsData.length != 0 ?
            <>
            {ticketsData.map((option)=>{
                return  <div key={option.id} className='position-relative ticket-card-parent'>
                <div  className={`px-4 py-4 shadow mb-3 ${lastUploadedTicket == option.id ? 'ticket-card-hide' : 'ticket-card'}`}>
                    <div className="d-flex justify-content-between mb-2">
                        <div className="d-flex flex-column gap-3">
                                <p className="ticket-text"><span className="fw-bold">Row: </span>
                                    {option.row}</p>
                            {/* <p className="ticket-text"><span className="fw-bold">Seat Number:</span> {option.seat}</p> */}
                        </div>
                        <div className="d-flex flex-column align-items-center gap-3">
                            {(clickedTicket != option.id ) &&
                                <button 
                                onClick={()=>{
                                    setErrorMsg(null)
                                    setClickedTicket(option.id)
                                    setShowUploadForm(true)
                                }}
                                    type='button' 
                                    className="btn ticket-btn">
                                    Upload Ticket
                                </button>}
                        </div>
                    </div>
                   {(showUploadForm && clickedTicket == option.id) &&
                   <>
                     <hr className="my-3"/>
                     {errorMsg &&
                     <div className='text-danger mb-1'>
                            <i style={{fontSize: '1.2rem'}} className={`mdi mdi-close-circle me-1 text-danger`}></i>
                            <span>{errorMsg}</span>
                        </div>}
                     {uploadedTicket.includes(option.id) ? 
                        <div className='text-success'>
                            <i style={{fontSize: '1.2rem'}} className={`mdi mdi-checkbox-marked-circle me-1 text-success`}></i>
                            <span>Ticket Uploaded</span>
                        </div>
                        :
                    <div className={`row ${errors?.ticket?.message ? 'align-items-center' : 'align-items-end'}`}>
                        <div className='form-group col-md-8'>
                            <label className='fw-bold' htmlFor="ticket">Upload Ticket</label>
                            <div>
                                <div className="custom-file">
                                <input 
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="ticket"
                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                    {...register("ticket", {
                                                        required: {
                                                            value: true,
                                                            message: '*this field is required',
                                                        },
                                                        validate: {
                                                            validSize: (fileList) => {
                                                                if (fileList.length === 0) {
                                                                    return 'This field is required';
                                                                }
                                                                if (fileList[0].size > 1024 * 1024) {
                                                                    setErrorMsg('File Exceeds 1MB');
                                                                    return 'File Exceeds 1MB';
                                                                }
                                                                return true;
                                                            }
                                                        }
                                                    })}
                                                    onChange={(e) => onFileChange(e, 'custom-certificate')}
                                                />
                                <label id="custom-certificate" className="custom-file-label">Choose file...</label>
                                {
                                    errors?.ticket?.message &&
                                    <div className="text-danger small mt-1">
                                        {errors?.ticket?.message}
                                    </div>
                                    }
                                </div>
                            </div>
                            </div>
                            <div className='col-md-4'>
                                {uploadedTicket.includes(option.id) == false &&
                                    <>
                                        {customerLoading ? 
                                            <Spinner isForm={true}/> :
                                            <button 
                                                className="w-100 btn btn-primary btn-lg" 
                                                type="submit">
                                                Upload
                                            </button>
                                        } </>
                                }
                                {/* {uploadedTicket.includes(option.id) && 
                                <div className='text-success'>
                                    <i style={{fontSize: '1.2rem'}} className={`mdi mdi-checkbox-marked-circle me-1 text-success`}></i>
                                    <span>Ticket Uploaded</span>
                                </div>
                                } */}
                                
                            </div>
                </div>}
                   </>}
                </div>
                </div>
            })} 
            <hr className="my-4"/>

            <button onClick={()=>{navigate(app_urls.user_listings)}} className="w-100 btn btn-primary btn-lg" type="button">Upload Later</button>
            </> : 
            <div className='d-flex align-items-center justify-content-center'>
                <p className='me-1'>You do not have any tickets to upload.</p><Link to={app_urls.user_listings}>View Listings</Link>
            </div>
            }
            </>
        {/* <>
            {customerLoading ? 
                <Spinner isForm={true}/> :
                <button className="w-100 btn btn-primary btn-lg" type="submit">Continue</button>
            }  
        </> */}
        </form>
    </ListingPageLayout>
  )
}
