import React, { useEffect } from 'react'
import AdminWrapper from '../../../layout/AdminWrapper'
import CountryTable from './CountryTable'
import { useDispatch, useSelector } from 'react-redux'
import AddCountry from './AddCountry'
import { clearAll } from '../../../../config/redux/slice/adminSlice'
import EditCountry from './EditCountry'
import DeleteCountry from './DeleteCountry'

export default function CountryManagement() {

  const dispatch = useDispatch()

  const {
      countryManagement:{showAddCountryForm, showCountryModal:{show, type}, countryDetails}
    } = useSelector((state) => state.admin)

    useEffect(() => {
      dispatch(clearAll({}))
    },[])

  return (
    <AdminWrapper>
      <div>
        {(show && type == 'edit') &&
          <EditCountry countryObj={countryDetails}/>}
        {(show && type == 'delete') &&  
          <DeleteCountry countryObj={countryDetails}/>}
        {showAddCountryForm &&
          <AddCountry/>
        }  
        <CountryTable/>
      </div>
    </AdminWrapper>
  )
}
