import React, { memo, useEffect, useState } from 'react'
import Spinner from '../../../components/Spinner'

function DashboardCards({dashboard_card_count_list}) {
  return (
    <section style={{rowGap: '1rem'}} className='d-flex flex-column'>
        <div style={{rowGap: '0.7rem'}} className="w-100 m-0 row row-sm py-3">
            {dashboard_card_count_list.map((option)=>{
                return <div key={option.id} className="col-sm-6 col-xl-3">
                        <div className={`card border-0 p-4 bg-white`}>
                            <div className="d-flex justify-content-between align-items-center">
                                  <span style={{height: '45px'}}>
                                        <span style={{fontSize: '30px'}} className={`${option.icon} ${option.icon_color}`}></span>
                                    </span>
                                
                                {/* <!-- card-header --> */}
                                <div className="d-flex flex-column align-items-end gap-3">
                                    {/* <Sparkline sparkline_data={option.sparkline_list}/> */}
                                    {option.loading == true ?
                                        <Spinner color={`${option.icon_color}`}/>
                                        :
                                        <h5 className={`${option.icon_color} mb-0`}>{option.total_count}</h5>
                                    }
                                    <h6 className="text-uppercase dashboard-card-heading mb-0 tx-spacing-1 text-dark">{option.heading}</h6>
                                </div>
                            </div>
                            {/* <!-- card-body --> */}
                            {/* <div className="d-flex align-items-center justify-content-between mt-3 border-top border-white pt-2">
                                <div>
                                    <span style={{fontSize: '0.7rem', color: 'rgba(255, 255, 255, 0.6)'}}>{option.bottom_text.left.text}</span>
                                    <h6 className="text-white mb-0">{option.bottom_text.left.value}</h6>
                                </div>
                                {option?.bottom_text.right &&
                                    <div>
                                        <span style={{fontSize: '0.7rem', color: 'rgba(255, 255, 255, 0.6)'}}>{option?.bottom_text.right.text}</span>
                                        <h6 className="text-white mb-0">{option?.bottom_text.right.value}</h6>
                                    </div>
                                }
                            </div> */}
                        </div>
                        {/* <!-- card --> */}
                    </div>
            })}
        </div>    
    </section>
  )
}

export default memo(DashboardCards)
