import React, { useEffect, useState } from 'react'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, clearResetBot, onGetTickets, setCustomerErrorMessage, setCustomerLoading, setDetails, showTicketModalFunc } from '../../../../config/redux/slice/customerSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import {ticketsAdminDatatable } from '../../../../helpers/customer_dataTables'
import { useNavigate, useParams } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import { get_delete_request } from '../../../../helpers/request'
import EditTicket from './EditTicket'
import ViewTicket from './ViewTicket'
import InformationModal from './InformationModal'

export default function TicketTable() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {listingId} = useParams()

    const [ticketType, setTicketType] = useState('')
    const [uploaded_no_of_tickets, set_uploaded_no_of_tickets] = useState(0)

  const {
        token
    } = useSelector((state) => state.authData)

    const {
        customerSuccessMsg,
        listingManagement: {listingDetails},
      ticketManagement:{newData, ticketsData, ticketDetails, showTicketModal: {show, type}}
      
    } = useSelector((state) => state.customer)

    useEffect(() => {
      dispatch(clearAll({}))
        ticketsAdminDatatable(
          ticketsData,
        'ticketsCustomerTable',
         setItem,
         ticketType
        )
    getAllTickets()
    },[])

    useEffect(()=>{
      if(newData){
        ticketsAdminDatatable(
          ticketsData,
        'ticketsCustomerTable',
         setItem,
         ticketType
        )
        dispatch(clearResetBot())
      }  
      if(customerSuccessMsg ==='Ticket Updated Successfully!'){
            getAllTickets()
      }      
    }, [newData, customerSuccessMsg])


    const setItem = (data, type) => {
      dispatch(setDetails({details: data, type: 'ticket'}))
      if(type == 'edit' || type == 'delete' || type == 'view'){
        dispatch(showTicketModalFunc({modal: true, type}))
      }
    }

    const getAllTickets = async () =>{
      dispatch(clearAll({}))
      const res = await get_delete_request(
        'get',
        token,
        `${app_api.get_single_listing(listingId)}`,
        {},
        dispatch,
        setCustomerLoading,
        "",
        setCustomerErrorMessage
      )
      if(res != undefined && res.status < 400){
        set_uploaded_no_of_tickets(res?.data?.data?.Tickets?.length)
        setTicketType(res?.data?.data?.ticketsType)
        dispatch(onGetTickets(res?.data?.data?.Tickets))
      }
    }

    // const setItem = (data, type) => {
    //   if(type == 'manage-subsections'){
    //     let subsection = currentNavDetails?.subs?.filter((option)=>app_urls.subsections_admin == option.url)
    //      dispatch(setDetails({details: data, type: 'section'}))
    //      dispatch(setBreadCrumbName([...breadCrumbName, {id:breadCrumbName.length + 1, name:subsection[0].name, url:subsection[0].url}]))
    //       navigate(app_urls.subsections_admin)
    //   }else if(type == 'delete' || type == 'edit'){
    //     dispatch(setDetails({details: data, type: 'section'}))
    //   dispatch(showSectionModalFunc({modal: true, type}))
    //   }
    // }
  return (
    <CustomerWrapper heading={'Tickets'}>
      {(show && type == 'edit') &&
      <EditTicket ticketObj={ticketDetails}/>
      }
      {(show && type == 'view') &&
      <ViewTicket ticketObj={ticketDetails}/>
      }
      {(show && type == 'information') &&
      <InformationModal />
      }
       <div className='mb-3'>
        <button
        onClick={()=>{navigate(app_urls.listing_event_dyn)}}
          style={{color: 'rgba(0,0,0,0.9)'}}
         className='btn btn-sm bg-accent fw-bold'
        >
          <i style={{fontSize: '1rem'}} className="mdi mdi-arrow-left me-1"></i>
          Go back to Details
        </button>
      </div>
       <DataTableWrapper
      table_id='ticketsCustomerTable'
      table_heading={''}
    >
        <thead>
            <tr>
                {/* <th className="wd-5p">S/N</th> */}
                <th className="wd-15p">Row</th>
                <th className="wd-10p">Seat Number</th>
                <th className="wd-10p">Status</th>
                <th className="wd-10p">Sold on</th>
                <th className="wd-15p">Actions</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </DataTableWrapper>
    </CustomerWrapper>
  )
}

