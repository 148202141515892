import React from 'react'
import primetic_logo from '../../assets/images/primetic_logo.png'
import '../../assets/css/authentication_styles.css'
import '../../assets/css/common_styles.css'
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../utils/urls/app_urls'
import CookieNotice from '../components/CookieNotice'

export default function AuthWrapper({children, formClasses='mainForm', onHandleSubmit, background_image}) {
    const navigate = useNavigate()

  return (
    <div 
        style={{
            minHeight: '100vh',  
            background: background_image ? `linear-gradient(270deg, rgba(20, 40, 98, 0.6), rgba(20, 40, 98, 0.6)), url(${background_image})` : 'none'
        }} 
        className='d-flex justify-content-center align-items-center auth-form'>
        <div className='my-5 px-2 px-sm-40 p-6'>
            {/* <div className='px-2 px-sm-40 p-6'> */}
                <div className={`card ${formClasses} px-2 pt-4 pb-5 px-sm-3`}>
                     <img className='d-block app-logo mx-auto cursor-pointer' onClick={()=>{navigate(app_urls.home)}} src={primetic_logo} alt='primetic logo' />
                    {/* <div className='formContainer'> */}
                        <form className='mt-2' onSubmit={onHandleSubmit}>
                        <div className="row w-100 m-0">
                            {children}
                        </div>
                        </form>
                    {/* </div>         */}
                </div>
            {/* </div>     */}
        </div>
        <CookieNotice/> 
    </div>
  )
}
