import React, { useEffect, useState } from 'react'
import loading_gif from '../../assets/images/logo-loading-gif.gif'
import { useLocation, useNavigate } from 'react-router-dom'
import { app_urls } from '../../utils/urls/app_urls'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onGetUser, onUserLogin, setErrorMessage } from '../../config/redux/slice/authSlice'
import { get_delete_request } from '../../helpers/request'
import { app_api } from '../../utils/endpoints/app_api'
import { system_user_roles } from '../../constants/english'
import ErrorPage from './ErrorPage'

export default function LoadingPage() {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [status, setStatus] = useState('')

    const {
        errorMsg
    } = useSelector((state) => state.authData)

    const {
        lastVisitedPage
    } = useSelector((state) => state.customer)

    useEffect(()=>{
        const params = new URLSearchParams(location.search);
        const jwtToken = params.get('jwt');
        get_auth_user(jwtToken)
    }, [])

     const get_auth_user  = async (token) => {
        // dispatch(setPageLoading({loading: true}))
        dispatch(clearAll({}))
        const res = await get_delete_request(
          'get',
            token,
            `${app_api.get_user}`,
            {},
            dispatch,
            "",
            "",
            setErrorMessage
        )
        if(res !== undefined && res.status < 400){
          dispatch(onUserLogin({jwtToken: token}))
          const timer = setTimeout(() => {
            //   dispatch(setPageLoading({loading: false}))
              dispatch(onGetUser(res.data.data))
               if((res.data.data.role).toLowerCase() == 'user'){
                if(lastVisitedPage){
                  navigate(lastVisitedPage)
                }else{
                  navigate(app_urls.dashboard)
                }
                }else if(system_user_roles.includes((res.data.data.role).toLowerCase())){
                  navigate(app_urls.admin_dashboard)
                }

          }, 1000);
          // if((res.data.data.role).toLowerCase() == 'user'){
          //   navigate(app_urls.profile)
          // }else if(system_user_roles.includes((res.data.data.role).toLowerCase())){
          //   navigate(app_urls.admin_dashboard)
          // }
        }else if(res!==undefined){
          setStatus(res.status)
        }
    }
  return (
    <>
    {errorMsg ? 
      <ErrorPage status={status} message={errorMsg}/>
      :
        <div style={{height: '100vh'}} className={`w-100 bg-white d-flex justify-content-center ${errorMsg ? '' : 'align-items-center'}`}>
        <img src={loading_gif} />
      </div>
    }</>
  )
}
