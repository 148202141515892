const base = '/auth'

export const onboard_urls = {
    'login': `${base}/login`,
    'register': `${base}/register`,
    'verifyEmail': `${base}/verify-email/:token_uuid`,
    'forgot_password': `${base}/forgot-password`,
    'reset_password': `${base}/reset-password`,
    'verification_link_sent': `${base}/email-verification-link/success`,
    'password_reset_success': '/auth/passsword-reset/success',
}