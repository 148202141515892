import React, { useEffect,useState } from 'react'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { payoutAdminDatatable } from '../../../../helpers/dataTables'
import { clearAll, clearResetBot, onGetPayouts, setAdminErrorMessageUser, setAdminLoadingUser, setDetails, setPayoutValues, showListingModalFunc,setPayoutValues1,setPayoutValues2, showPayoutModalFunc,showPayoutModalFunc1,showPayoutModalFunc2 } from '../../../../config/redux/slice/adminSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { get_delete_request } from '../../../../helpers/request'

export default function PayoutTable() {
   const dispatch = useDispatch()
      const navigate = useNavigate()

      const errorFunc = (msg) => {
        dispatch(setAdminErrorMessageUser({ message: msg }))
      }

    const {
      token,
    } = useSelector((state) => state.authData)
  

    const {
        payoutManagement:{newData, payoutData, payoutEventId,updatedInfo,changeInfo }
    } = useSelector((state) => state.admin)

    //const [statusType, setStatusType] = useState([]) ///////xdsd

    useEffect(() => {
      dispatch(clearAll({}))
      //getAllStatusTypes() //////xdsd
      dispatch(setPayoutValues({propertyKey: 'newData', value: true}))
    },[payoutEventId])

    // useEffect(() => {
    //   dispatch(clearAll({}))
    //     payoutAdminDatatable(
    //       payoutData,
    //     'payoutAdminTable',
    //     setItem,
    //     statusType,
    //     payoutEventId
    //     )
    // getAllPayout()
    // },[])
    
  useEffect(() => {
    payoutAdminDatatable(
      errorFunc,
      'payoutAdminTable',
      `${app_api.get_payouts_by_event(payoutEventId)}`,
      `${app_api.search_payout_eventid}`,
      token,
      setItem,
      //statusType,
      payoutEventId
    )

  }, [])

    // useEffect(()=>{
    //   if(newData){
    //     payoutAdminDatatable(
    //     payoutData,
    //     'payoutAdminTable',
    //     setItem,
    //     statusType,
    //     payoutEventId
    //     )
    //     dispatch(clearResetBot())
    //   }      
    // }, [newData])

    useEffect(() => {
      if (newData) {
        payoutAdminDatatable(
          errorFunc,
          'payoutAdminTable',
         `${app_api.get_payouts_by_event(payoutEventId)}`,
         `${app_api.search_payout_eventid(payoutEventId)}`,
          token,
          setItem,
          //statusType,
          payoutEventId
        )
        dispatch(clearResetBot())
      }
    }, [newData])

    // const getAllPayout = async () =>{
    //   dispatch(clearAll({}))
    //   const res = await get_delete_request(
    //     'get',
    //     token,
    //     `${app_api.get_payouts_by_event(payoutEventId)}`,
    //     {},
    //     dispatch,
    //     setAdminLoadingUser,
    //     onGetPayouts,
    //     setAdminErrorMessageUser
    //   )
    // }


    // const getAllStatusTypes = async () =>{
    //   dispatch(clearAll({}))
    //   const res = await get_delete_request(
    //     'get',
    //     token,
    //     `${app_api.get_auth_status_type}`,
    //     {},
    //     dispatch,
    //     "",
    //     "",
    //     ""
    //   )
    //   if(res != undefined && res.status < 400){
        
    //     setStatusType(res?.data?.data)
    //   }
    // }


   const setItem = (data, type) => {
      dispatch(setDetails({details: data, type: 'payout'}))
      //dispatch(showPayoutModalFunc({modal: true, type}))
      dispatch(setPayoutValues1({ propertyKey: 'updatedInfo', value: data }));
      dispatch(setPayoutValues2({ propertyKey: 'changeInfo', value: data }));
      dispatch(showPayoutModalFunc1({ modal: true, type: 'changstatustype' }));
     dispatch(showPayoutModalFunc2({ modal: true, type }));

    }
    

  return (
    <DataTableWrapper table_id='payoutAdminTable' table_heading='Payouts' table_buttons={<ActionButton/>}>
        <thead>
            <tr>
                {/* <th className="wd-5p">S/N</th> */}
                <th className="wd-25p">Name</th>
                <th className="wd-25p">Email</th>
                {/* <th className="wd-20p">Address</th> */}
                <th className="wd-15p">Amount</th>
                <th className="wd-15p">Final Amount</th>
                <th className="wd-15p">Payout Status</th>
                <th className="wd-15p">Action</th>

            </tr>
        </thead>
        <tbody>
        </tbody>
    </DataTableWrapper>
  )
}

function ActionButton(){
  const dispatch = useDispatch()

  const {
        payoutManagement:{payoutEventId}
    } = useSelector((state) => state.admin)

    const { userData: { user_role } } = useSelector((state) => state.authData);

    const isSuperAdmin = user_role === 'SUPER_ADMIN';

  return(
    <div style={{columnGap: '0.5rem', rowGap: '0.6rem'}} className='d-flex flex-wrap'>
      {payoutEventId && isSuperAdmin &&
      <button onClick={()=> dispatch(showPayoutModalFunc({modal:true, type: 'manage'}))} className='btn btn-sm btn-primary'>
        Initiate Payout
      </button>}
    </div>
  )
}
