import React from 'react'
import FilterWrapper from '../../../components/FilterWrapper'
import Spinner from '../../../components/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import SelectComponent from '../../../components/Form/SelectComponent'
import { app_api } from '../../../../utils/endpoints/app_api'
import { clearAll, setAdminErrorMessageUser, setAdminLoadingUser, setFilerQuery, setFilterQuery } from '../../../../config/redux/slice/adminSlice'
import { get_delete_request } from '../../../../helpers/request'

export default function FilterStadium() {

    const dispatch = useDispatch()
    const {register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})

    const {
        token
    } = useSelector((state) => state.authData)

    const {tableFilterQuery,
            adminLoading,
    } = useSelector((state) => state.admin)

    const submit = (data) => {
        data = {...data, cityId: undefined}
        // const query = `name=${data.name}&city=${data.city == 'Select City' ? '' : data.city}&date=${data.date}`
        let query;
        console.log(data)
        for(let key in data){
            if(data[key] && data[key] !== 'Select City'){
                query = query ? `${query}&${key}=${data[key]}` : `${key}=${data[key]}`
            }
        }
        if(query){dispatch(setFilterQuery(query))}
    }

    const resetForm = () => {
        reset()
        setValue('city', 'Select City')
        if(tableFilterQuery){
            dispatch(setFilterQuery(null))
        }
    }

  return (
    <FilterWrapper resetFunc={resetForm} heading_title={'Venue'} onSubmit={handleSubmit(submit)} loading={adminLoading}>
        <>

            <div className="col-md-6 col-lg-4">
            <div className="form-group">
                <label htmlFor='name'>Name</label>
                <input 
                type="text" 
                className="form-control input-lg" 
                id="name"
                {...register("name")
                } 
                />
                {
                errors?.name?.message &&
                <div className="text-danger small mt-1">
                    {errors?.name?.message}
                </div>
                }
            </div> 
            </div>

            <div className="col-md-6 col-lg-4">
            <div className="form-group">
                    <label htmlFor='city'>Town/City</label>
                    
                    <SelectComponent
                    // searchValue={search_cities}
                    register={register}
                    isReq={false}
                    fieldName={'cityId'}
                    fieldName2={'city'}
                    text='Select City'
                    setValue={setValue}
                    setErrorFunc={setError}
                    getValueFunc={getValues}
                    errors={errors?.city?.message}
                    clearErrorsFunc={clearErrors}
                    getEndpoint={app_api.get_cities}
                    searchEndpoint={app_api.search_cities}
                    />
                </div> 
            </div>

            <div className="col-md-6 col-lg-4">
            <div className="form-group">
                    <label htmlFor='createdAt'>Date</label>
                    <input 
                    type="date" 
                    className="form-control input-lg" 
                    id="createdAt"
                    {...register("createdAt")
                    } 
                    />
                    {
                    errors?.createdAt?.message &&
                    <div className="text-danger small mt-1">
                        {errors?.createdAt?.message}
                    </div>
                    }
                </div> 
            </div>

            </>
    </FilterWrapper>
  )
}
