import React, { useEffect, useState } from 'react'
// import '../../assets/plugins/font-awesome/css/font-awesome.css'
// import '../../assets/plugins/Ionicons/css/ionicons.css'
// import '../../assets/plugins/rickshaw/rickshaw.css'
import { ToastContainer, toast } from 'react-toastify'
import '../../assets/css/common_styles.css'
import '../../assets/css/admin_styles.css'
import SideBar from './admin/SideBar'
import TopPanel from './admin/TopPanel'
import BreadCrumb from './admin/BreadCrumb'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll as clearAllApp,
  logoutAppUser,
  onGetRoles,
  setActiveAdminSidebar,
} from '../../config/redux/slice/appSlice'
import { get_delete_request } from '../../helpers/request'
import { app_api } from '../../utils/endpoints/app_api'
import { clearAll, logoutAdminUser } from '../../config/redux/slice/adminSlice'
import { logoutUser } from '../../config/redux/slice/authSlice'
import { logoutCustomerUser } from '../../config/redux/slice/customerSlice'
import CookieNotice from '../components/CookieNotice'
import PhoneNumberVerificationSuccess from '../components/PhoneNumberVerificationSuccess'
import VerifyPhoneNumber from '../components/VerifyPhoneNumber'
import AlertPopup from '../components/alertpopup'
// import RightPanel from './admin/RightPanel'

export default function AdminWrapper({ children, heading,
}) {
  const dispatch = useDispatch()

  const {
    userData: { user_role },
    token,
    showPhoneNumberVerificationModal: { show, type, message },
  } = useSelector((state) => state.authData)

  const { adminErrorMsg, adminSuccessMsg } = useSelector((state) => state.admin)

  useEffect(() => {
    let mainPath = ''

    let path = window.location.pathname

    let fullPathArray = path.split('/')

    if (fullPathArray.length <= 2) {
      mainPath = path
    } else {
      mainPath = `/${fullPathArray[1]}/${fullPathArray[2]}`
    }
    dispatch(
      setActiveAdminSidebar({
        mainPath,
      })
    )
  }, [])

  // useEffect(()=>{
  //   get_roles()
  // }, [])

  useEffect(() => {
    document.body.classList.add('has-admin', 'admin-body')

    // Clean up the className on unmount (optional)
    return () => {
      if (document.querySelector('.has-admin')) {
        document.body.classList.remove('has-admin')
      }
      if (document.querySelector('.admin-body')) {
        document.body.classList.remove('admin-body')
      }
    }
  }, [])

  //  const get_roles  = async () => {
  //       dispatch(clearAllApp({}))
  //       const res = await get_delete_request(
  //         'get',
  //           token,
  //           `${app_api.get_roles}`,
  //           {},
  //           dispatch,
  //           "",
  //           onGetRoles,
  //           ""
  //       )
  //   }

  useEffect(() => {
    if (adminSuccessMsg) {
      let msg
      if (Array.isArray(adminSuccessMsg)) {
        msg =
          adminSuccessMsg[0].toLowerCase().charAt(0).toUpperCase() +
          adminSuccessMsg[0].slice(1)
      } else {
        msg =
          adminSuccessMsg.toLowerCase().charAt(0).toUpperCase() +
          adminSuccessMsg.slice(1)
      }

      dispatch(clearAll())

      showToastMessage(msg, false)
    }

    if (adminErrorMsg) {
      let err
      if (Array.isArray(adminErrorMsg)) {
        err =
          adminErrorMsg[0].toLowerCase().charAt(0).toUpperCase() +
          adminErrorMsg[0].slice(1)
      } else {
        err =
          adminErrorMsg.toLowerCase().charAt(0).toUpperCase() +
          adminErrorMsg.slice(1)
      }
      dispatch(clearAll())
      if (err?.toLowerCase() == 'unauthorized') {
        dispatch(logoutUser())
        dispatch(logoutAdminUser())
        dispatch(logoutAppUser())
        dispatch(logoutCustomerUser())
        err = null
      } else {
        showToastMessage(err, true)
      }

      // showToastMessage(err, true)

      // showToastMessage(err, true)
    }
  }, [adminSuccessMsg, adminErrorMsg])

  // const showToastMessage = (msg, isError) => {
  //   if (isError) {
  //     toast.error(msg, {
  //       position: toast.POSITION.TOP_CENTER,
  //     })
  //   } else {
  //     toast.success(msg, {
  //       position: toast.POSITION.TOP_CENTER,
  //     })
  //   }
  // }
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleShowPopup = () => {
    //debugger
  console.log('Button clicked, showing popup');
  setShowPopup(true);
};

const handleHidePopup = () => {
  console.log('Popup closed');
  setShowPopup(false);
};
const showToastMessage = (msg, isError) => {
  setIsError(isError);  // Set the error flag
  if (isError) {
    setPopupMessage(msg);
    handleShowPopup();
  } else {
      setPopupMessage(msg);
      handleShowPopup();
  }
  console.log('popupMessage', popupMessage);
};

  return (
    <div className="admin">
      {/* <ToastContainer /> */}

      <TopPanel />
      {show && type == 'success' && (
        <PhoneNumberVerificationSuccess successMsg={message} />
      )}
      {show && type == 'verify' && <VerifyPhoneNumber message={message} />}
      <div className="container-fluid">
        <div className="row">
          <SideBar />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-0">
          <AlertPopup show={showPopup} handleHide={handleHidePopup} message={popupMessage} isError={isError}/>

            <ToastContainer />
            <BreadCrumb breadcrumb_name={heading}/>
            <div className="my-4 px-2 px-lg-4">{children}</div>
          </main>
        </div>
      </div>
      <CookieNotice />
    </div>
  )
}
