import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { adminAdminDatatable } from '../../../../helpers/dataTables'
import {
  clearResetBot,
  setAdminErrorMessageUser,
  showAddAdminFormFunc,
} from '../../../../config/redux/slice/adminSlice'
import { app_api } from '../../../../utils/endpoints/app_api'

export default function AdminTable() {
  const dispatch = useDispatch()

  const {
    token,
    userData: { user_role },
  } = useSelector((state) => state.authData)

  const {
    adminManagement: { showAddAdminForm, newData },
  } = useSelector((state) => state.admin)

  useEffect(() => {
    adminAdminDatatable(
      errorFunc,
      'adminAdminTable',
      `${app_api.get_admins}`,
      `${app_api.search_user}`,
      token
    )
  }, [])

  useEffect(() => {
    if (newData) {
      adminAdminDatatable(
        errorFunc,
        'adminAdminTable',
        `${app_api.get_admins}`,
        `${app_api.search_user}`,
        token
      )
      dispatch(clearResetBot())
    }
  }, [newData])

  const errorFunc = (msg) => {
    dispatch(setAdminErrorMessageUser({ message: msg }))
  }

  return (
    <DataTableWrapper
      table_id="adminAdminTable"
      table_heading="Admins"
      table_buttons={
        showAddAdminForm && user_role.toLowerCase != 'super_admin' ? null : (
          <ActionButton user_role={user_role}/>
        )
      }
    >
      <thead>
        <tr>
          {/* <th className="wd-5p">S/N</th> */}
          <th className="wd-15p">Name</th>
          <th className="wd-25p">E-mail</th>
          <th className="wd-25p">Role</th>
          <th className="wd-20p">Address</th>
          <th className="wd-10p">Joined On</th>
          {/* <th className="wd-15p">Actions</th> */}
        </tr>
      </thead>
      <tbody></tbody>
    </DataTableWrapper>
  )
}

function ActionButton({user_role}) {
  const dispatch = useDispatch()
  if (user_role !== 'SUPER_ADMIN') {
    return null
  }
  return (
    <div>
      <button
        onClick={() => dispatch(showAddAdminFormFunc({ modal: true }))}
        className="btn btn-sm btn-outline-primary"
      >
        <i
          style={{ fontSize: '1rem' }}
          className="mdi mdi-plus-circle me-1"
        ></i>
        Add Admin
      </button>
    </div>
  )
}
