import React, { useState } from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import Spinner from '../../../components/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useForm } from 'react-hook-form'
import { clearAll, setCustomerErrorMessage, setCustomerLoading, showTicketModalFunc, onUpdate } from '../../../../config/redux/slice/customerSlice'
import { truncateMiddle } from '../../../../helpers/helpers'

export default function EditTicket({ ticketObj,getList }) {
  const dispatch = useDispatch()
  const { register, handleSubmit, watch, getValues, setValue, formState: { errors }, reset } = useForm({ mode: 'onTouched' })
  const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];

  const {
    customerLoading,
  } = useSelector((state) => state.customer)

  const {
    token
  } = useSelector((state) => state.authData)

  const [formData, setFormData] = useState(new FormData())

  const onSubmit = async (data) => {
    dispatch(clearAll({}))
    for (let key in data) {
      if (typeof data[key] == "object") {
        if (data[key].length != 0) {
          formData.append(key, data[key][0])
        }
      } else {
        formData.append(key, data[key])
      }
    }

    const res = await post_put_patch_request(
      "patch",
      token,
      `${app_api.upload_ticket(ticketObj?.id)}`,
      formData,
      dispatch,
      setCustomerLoading,
      "",
      setCustomerErrorMessage,
      true
    )

    if (res !== undefined && res.status < 400) {
      getList();
      setFormData(new FormData())
      dispatch(onUpdate({ message: 'Ticket Updated Successfully!', type: 'ticket' }))
    } else {
      setFormData(new FormData())
    }
   
  }

  const closeModal = () => {
    dispatch(showTicketModalFunc({ modal: false, type: null }))
  }

  function addFileName(e, idValue) {
    let fileName = e.target.value;
    if (fileName !== '') {
      fileName = fileName.replace(/^.*[\\\/]/, '')
      document.getElementById(idValue).innerHTML = truncateMiddle(fileName, 30)
      const fileExtension = fileName.split('.').pop().toLowerCase();

      // Validate file extension
      if (!allowedExtensions.includes(fileExtension)) {
        alert('Only JPG, JPEG, PNG, and PDF files are allowed.'); // Inform user about invalid file type
        fileName = ''; // Reset file input to clear invalid selection
        document.getElementById(idValue).textContent = "No file chosen";
      } 
    } else {
      document.getElementById(idValue).innerHTML = "No file Chosen"
    }
  }

  return (
    <ModalLayout classStyles={''} onClick={closeModal} heading={'Upload Ticket'}>
      {console.log(".....XDSDEditTIcket", ticketObj)}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="modal-body">
          <div className="px-4 px-md-7">
            <div style={{ rowGap: '1.2rem' }} className="row mb-2">

              <div className='form-group'>
                <label className='fw-bold' htmlFor="ticket">Upload Ticket</label>
                <div>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="ticket"
                      accept=".jpg,.jpeg,.png,.pdf"
                      {...register("ticket", {
                        required: {
                          value: true,
                          message: '*this field is required'
                        }
                      })
                      }
                      onChange={(e) => {
                        addFileName(e, 'custom-certificate')
                      }}

                    />
                    <label id="custom-certificate" className="custom-file-label">Choose file...</label>
                    {
                      errors?.ticket?.message &&
                      <div className="text-danger small mt-1">
                        {errors?.ticket?.message}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="modal-footer border-top-0 d-flex align-items-center">
          <div>
            {customerLoading ?
              <Spinner isForm={true} /> :
              <button type="submit" className="btn btn-primary me-3">Submit Form</button>
            }
          </div>
          <button disabled={customerLoading ? true : false} onClick={closeModal} type="button" className="btn btn-info">Cancel</button>
        </div>
      </form>
    </ModalLayout>
  )
}
