import React from 'react'
import PayoutDueTable from './PayoutDueTable'
import AdminWrapper from '../../../layout/AdminWrapper'

export default function PayoutDueManagement() {
  return (
    <AdminWrapper>
      <div>
        <PayoutDueTable/>
      </div>
    </AdminWrapper>
  )
}
