import React from 'react'
import trending_img4 from '../../../../assets/images/trending_img4.png'
import moment from 'moment/moment'

export default function EventHeaderInfo({classStyles, eventObj}) {
  return (
    <div>
      <div className={`d-flex flex-wrap pt-3 pt-lg-0 justify-content-lg-start justify-content-center`}>
      <div style={{columnGap: '1rem'}} className={`d-flex`}>
        <img src={trending_img4} className='h-14 h-14 rounded'/>
        <div>
            <h6 className='mb-0 fw-bold text-dark'>{eventObj?.name}</h6>
            <p className="text-dark text-ellipsis-event font-event">{eventObj?.description}</p>    
            <p style={{color: 'blue'}} class="font-event">{moment(eventObj?.date).format('MMM D · ddd · h:mma · YYYY')}</p>
            <p className="text-dark font-event">{eventObj?.Venue?.name}</p>    
        </div>
      </div>
      {/* <div >
        <button className='btn text-uppercase fw-bold'>Share to Favourites</button>
        <button className='btn text-uppercase fw-bold'>Share</button>
      </div> */}
    </div>
    </div>
  )
}
