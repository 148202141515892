import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddTeam from './AddTeam'
import AdminWrapper from '../../../layout/AdminWrapper'
import TeamTable from './TeamTable'
import { clearAll } from '../../../../config/redux/slice/adminSlice'
import DeleteTeam from './DeleteTeam'
import EditTeam from './EditTeam'

export default function TeamManagement() {

  const dispatch = useDispatch()
  const {
      teamManagement:{showAddTeamForm, teamDetails, showTeamModal: {show, type}}
    } = useSelector((state) => state.admin)

    useEffect(() => {
      dispatch(clearAll({}))
    },[])

  return (
    <AdminWrapper>
      <div>
        {(show && type == 'delete') &&
        <DeleteTeam teamObj={teamDetails}/>}
        {(show && type == 'edit') &&
        <EditTeam teamObj={teamDetails}/>}
        {showAddTeamForm &&
          <AddTeam/>
        }  
        <TeamTable/>
      </div>
    </AdminWrapper>
  )
}
