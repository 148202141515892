import React, { useEffect } from 'react'
import { InfoComponent, JumbotronComponent, MiddleSectionComponent, PostHighlightComponent } from '../../components'
import sellers_jumbotron_img from '../../../assets/images/sellers_jumbotron_img.jpg'
import sellers_jumbotron_img_small from '../../../assets/images/sellers_jumbotron_img_small.jpg'
import { FAQS_SELLING, sell_tickets, steps_to_sell_tickets, trending_events } from '../../../constants/english'
import faq_icon from '../../../assets/images/faq_icon.svg'
import step1_icon from '../../../assets/images/step1_icon.svg'
import step_line from '../../../assets/images/step_line.svg'
import GeneralWrapper from '../../layout/GeneralWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { showEventModalFunc } from '../../../config/redux/slice/appSlice'

export default function SellersPage() {
    const dispatch = useDispatch()
    const { userData, token } = useSelector((state) => state.authData)
   
    const eventData = useSelector((state) => state.app.eventManagement.eventData);
    const topEvents = eventData.slice(0,5);

    useEffect(()=>{
        window.scrollTo(0, 0);

      return () => {

        dispatch(showEventModalFunc({modal: false, type: null}))
      }
    }, []) 
  return (
    <GeneralWrapper>
        <div className='container-fluid p-0'>
            <JumbotronComponent placeholder='Search for your event' landingPage={'sell'} hasSearch={true} hero_text='Selling tickets on PrimeTic' img_src={sellers_jumbotron_img} img_src_small={sellers_jumbotron_img_small}/>
            <MiddleSectionComponent
                middle_text='A ticket is more than a transaction – it’s a moment someone will remember for the rest of their life. 
                So we’re making selling tickets a fairer, easier and more worthwhile process for fans. Welcome to a simple way to sell,
                with guaranteed payments, and a community of buyers who love their tickets as much as you do.'
                />
                <div className='mx-lg-200 mx-xl-312 mt-10 mb-5'>
                    <h2 className='text-center text-primary fw-bold'>5 simple steps to selling tickets</h2>
                    <div className='mt-3'>
                        {steps_to_sell_tickets.map((option, ind)=>{
                            return <div key={ind} className='row justify-content-center'>
                                        <div className='d-flex flex-column col-1'>
                                            <img className='flex-shrink-0' style={{width: '30px'}} src={step1_icon} alt=''/>
                                            {/* <img style={{flexBasis: '300px', width: '5px'}} src={step_line} alt=''/> */}
                                            {/* <div> */}
                                            {/* <div class="flex-custom center">
                                                <img src="https://picsum.photos/id/1/100/100"/>
                                            </div> */}
                                            {/* <div class="flex-custom center"> */}
                                                {/* <img src="https://picsum.photos/id/2/100/100"/>
                                                <div class="dots-h"></div> */}
                                                <div class="flex-custom col-custom">
                                                    <div class="dots-v"></div>
                                                    {/* <h4>Choose</h4>
                                                    <div class="grow"></div> */}
                                                </div>
                                                {/* <div class="dots-h"></div>
                                                <img src="https://picsum.photos/id/9/100/100"/> */}
                                            {/* </div> */}
                                        </div>
                                        {/* </div> */}
                                        <div className='col-8 test pb-4'>
                                            <p className='mb-0'>{option.action}</p>
                                        </div>
                                    </div>
                                })}
                    </div>
                </div>
                <InfoComponent heading_text='Sell tickets with confidence' dataList={sell_tickets} />
                
                {topEvents.length > 0 &&
                <PostHighlightComponent containerId={'myScrollContainer'} contentId={'myScrollContent'} page='sell' heading_text='Trending Events' dataList={topEvents} flexbasis='16.17rem'/>
                }


                {/*<PostHighlightComponent containerId={'myScrollContainer1'} contentId={'myScrollContent1'} heading_text='Hot Tickets' dataList={trending_events} flexbasis='21.17rem'/>*/}

                <div className='mx-lg-200 mx-xl-312 mt-56 mb-5'>
                    <h2 className='text-center fw-bold text-primary'>FAQS</h2>
                    <div>
                        {FAQS_SELLING.map((option, ind)=>{
                            return <div key={ind} className='mt-4 row align-items-start justify-content-center'>
                                        <img style={{flexBasis: '40px'}} className='col-4' src={faq_icon} alt=''/>
                                        <div className='col-8'>
                                            <p className='text-primary fw-bold mb-1'>{option.question}</p>
                                            <p className='mb-0'>{option.answer}</p>
                                        </div>
                                    </div>
                                })}
                    </div>
                </div>
            </div>
    </GeneralWrapper>
  )
}
