import guaranteed_icon from '../assets/images/guaranteed_icon.svg'
import support_icon from '../assets/images/support_icon.svg'
import transactions_icon from '../assets/images/transactions_icon.svg'
import community_icon from '../assets/images/community_icon.svg'
import security_icon from '../assets/images/security_icon.svg'
import ticketing_icon from '../assets/images/ticketing_icon.svg'
import hiddenfees_icon from '../assets/images/hiddenfees_icon.svg'
import getpaid_icon from '../assets/images/getpaid_icon.svg'
import pricing_icon from '../assets/images/pricing_icon.svg'
import search_ticket_icon from '../assets/images/search_ticket_icon.svg'

import trending_img1 from '../assets/images/trending_img1.png'
import trending_img2 from '../assets/images/trending_img2.png'
import trending_img3 from '../assets/images/trending_img3.png'
import trending_img4 from '../assets/images/trending_img4.png'

export const strText = {
    user_unique_id: 'primetic_user',
    app_logo: '',
    short_date: 'YYYY-MM-DD',
	full_date: 'YYYY-MM-DD, HH:MM:SS',
	full_date_for_download: 'YYYY-MM-DD_HH:MM:SS',
    admin_export_text: 'CSV Export',
    datatable_class: 'table table-striped',
    currency_symbol: '£'
}

export const system_user_roles = ['super_admin', 'admin', 'support','finance','customer_support'];

export const why_we_are_different = [
    {
        id: 1,
        icon: guaranteed_icon,
        text: 'Every ticket 100% guaranteed'
    },
    {
        id: 2,
        icon: support_icon,
        text: 'Full customer support'
    },
    {
        id: 3,
        icon: security_icon,
        text: 'Security and support you can count on'
    },
    {
        id: 4,
        icon: transactions_icon,
        text: 'Reliable, fast transactions'
    },
    {
        id: 5,
        icon: ticketing_icon,
        text: 'Easy-to-use ticketing platform'
    },
    {
        id: 6,
        icon: community_icon,
        text: 'A community of passionate fans'
    },
]

export const sell_tickets = [
    {
        id: 1,
        icon: community_icon,
        text: 'Connect with fans'
    },
    {
        id: 2,
        icon: hiddenfees_icon,
        text: 'No hidden fees'
    },
    {
        id: 3,
        icon: security_icon,
        text: 'Secure sales'
    },
    {
        id: 4,
        icon: support_icon,
        text: 'Support from listing to sale'
    },
    {
        id: 5,
        icon: getpaid_icon,
        text: 'Get paid directly'
    },
    {
        id: 6,
        icon: transactions_icon,
        text: 'Easy-to-use selling platform'
    },
]

export const buy_tickets = [
    {
        id: 1,
        icon: guaranteed_icon,
        text: 'All tickets 100% guaranteed'
    },
    {
        id: 2,
        icon: search_ticket_icon,
        text: 'Easy to search for and buy tickets'
    },
    {
        id: 3,
        icon: hiddenfees_icon,
        text: 'No hidden fees'
    },
    {
        id: 4,
        icon: support_icon,
        text: 'Excellent customer support'
    },
    {
        id: 5,
        icon: pricing_icon,
        text: 'Totally transparent pricing – what you see is what you pay'
    },
    {
        id: 6,
        icon: security_icon,
        text: 'Robust security'
    },
]

export const trending_events = [
    {
        id: 1,
        static: true,
        poster: trending_img1,
        name: 'Chelsea vs Brentford',
        date: '2023-10-28T12:30:00',
        Venue:{
            name: 'Stamford Bridge'
        }
    },

    {
        id: 2,
        static: true,
        poster: trending_img2,
        name: 'Liverpool vs Everton',
        date: '2023-10-28T12:30:00',
        Venue:{
            name: 'Anfield'
        }
    },

    {
        id: 3,
        static: true,
        poster: trending_img3,
        name: 'Sporting Lisbon vs Farense',
        date: '2023-10-28T12:30:00',
        Venue:{
            name: 'José Alvalade Stadium'
        }
    },
    
    {
        id: 4,
        static: true,
        poster: trending_img4,
        name: 'Dortmund vs Newcastle',
        date: 'T2023-10-28T12:30:00',
        Venue:{
            name: 'Westfalenstadion'
        }
    }
]

export const trending_events_reversed = [
     {
        id: 4,
        image: trending_img4,
        text1: 'Dortmund vs Newcastle',
        text2: 'Tue 7th Nov 2023 – 17:45pm',
        text3: 'Westfalenstadion'
    },

    {
        id: 3,
        image: trending_img3,
        text1: 'Sporting Lisbon vs Farense',
        text2: 'Thu 3rd Nov 2023 – 8:15pm',
        text3: 'José Alvalade Stadium'
    },
     {
        id: 2,
        image: trending_img2,
        text1: 'Liverpool vs Everton',
        text2: 'Sat 21st Oct 2023 - 12:30pm',
        text3: 'Anfield'
    },
    {
        id: 1,
        image: trending_img1,
        text1: 'Chelsea vs Brentford',
        text2: 'Sun 28th Oct 2023 - 12:30pm',
        text3: 'Stamford Bridge'
    },
]

export const sport_leagues = [
    {
        id: 1,
        text: 'Premier League',
    },

    {
        id: 2,
        text: 'UEFA Champions League'
    },

    {
        id: 3,
        text: 'EFL Championship'
    },
    
    {
        id: 4,
        text: 'Rugby World Cup'
    },

    {
        id: 5,
        text: 'NFL'
    },

    {
        id: 6,
        text: 'ATP Tour'
    },
    {
        id: 7,
        text: 'The Open'
    },
    {
        id: 8,
        text: 'PGA Tour'
    }
]

export const FAQS_SELLING = [
    {
        id: 1,
        question: 'Why is my event not listed?',
        answer: 'The event organiser may not have approved tickets for resale.'
    },
    {
        id: 2,
        question: 'Can I sell tickets if the event is not listed?',
        answer: 'Email customersupport@primetic.com and request that we feature your event. We will get back to you with confirmation as soon as possible.'
    },
    {
        id: 3,
        question: 'How do I send the tickets to the buyer?',
        answer: 'If you have a physical ticket, you’ll be given the seller’s address so you can post it directly to them. If you have an e-ticket, you’ll be given an email address or phone number to send the tickets to.'
    },
    {
        id: 4,
        question: 'When do I get paid after my tickets have sold?',
        answer: 'You’ll typically get paid 4-7 days after you have sold your ticket(s). You will receive an email when your ticket(s) have sold.'
    },
    {
        id: 5,
        question: 'How do I remove a listing?',
        answer: 'Login to your seller account. Select the listing. Click cancel. Confirm.'
    },
    {
        id: 6,
        question: 'How do I edit a listing?',
        answer: 'You just need to log into your account, click on the listing and start editing.'
    },
    {
        id: 7,
        question: 'How much does it cost to sell a ticket?',
        answer: 'We charge a commission on every ticket sold – both the buyer and seller pay a small percentage of the listed value. The amount you pay will clearly be shown.'
    }
]

export const steps_to_sell_tickets = [
    {
        id: 1,
        action: `Apply for a seller account by selecting "sell tickets " on the registration page`
    },
    {
        id: 2,
        action: `Log into your account and search for your event. Using the search function, type in the event you wish to list tickets for. If your event isn’t listed yet, you can request for it to be listed by emailing customersupport@primetic.com`
    },
    {
        id: 3,
        action: `List your tickets. Select the ticket format, the seat details, the number of tickets, and any restrictions.`
    },
    {
        id: 4,
        action: `Set your price. Enter the ticket price and your reserve price (minimum you are willing to accept)`
    },
    {
        id: 5,
        action: `The payment will arrive at your bank account or payment service account typically 4-7 days after the event. Don’t worry – your payment is always guaranteed. Terms & Conditions.`
    }
]

export const steps_to_buy_tickets = [
    {
        id: 1,
        icon: search_ticket_icon,
        heading_text: 'Search',
        action: `Find the event you’d like tickets for using the search bar.`
    },
    {
        id: 2,
        icon: ticketing_icon,
        heading_text: 'Select',
        action: `Filter the options to find the ticket that best matches your needs. 
        If your event is not there, or the prices aren’t right for you, set an alert and we’ll 
        notify you the moment your tickets go on sale.`
    },
    {
        id: 3,
        icon: getpaid_icon,
        heading_text: 'Buy',
        action: `Enter payment details and check how the ticket will be delivered.`
    },
    {
        id: 4,
        icon: guaranteed_icon,
        heading_text: 'Get Tickets',
        action: `The seller will contact you between 3-7 days before the event providing you 
        with the details for the ticket. We’ll monitor the status of your order while you wait.`
    },
]

export const FAQS_BUYING = [
    {
        id: 1,
        question: 'Do I need to register?',
        answer: 'Yes – you’ll need to sign up for an account with a valid email address or via an approved social platform. Once you buy a ticket, you can specify the email address, phone number or postal address for the ticket to go to – your billing address doesn’t have to be the same as the delivery address.'
    },
    {
        id: 2,
        question: 'How do I find tickets?',
        answer: 'Search for your event, then use the filter function to find the most suitable tickets.'
    },
    {
        id: 3,
        question: 'How much does PrimeTic charge?',
        answer: 'We charge a commission on each ticket sold. This will be clearly shown.'
    },
    {
        id: 4,
        question: 'How will I get my tickets?',
        answer: 'Depending on your ticket type, you’ll receive them by email, post or mobile transfer.'
    },
    {
        id: 5,
        question: 'What if my event is not listed - or the tickets cost too much for',
        answer: 'You can ask us to notify you as soon as the right tickets go on sale. Set your event, and the price you’d like to pay, and we’ll alert you as soon as we can.'
    },
    {
        id: 6,
        question: 'Tickets are cheaper on another platform; do you price match?',
        answer: 'Sellers set their prices at PrimeTic, so they can go up and down anytime. Set up a price alert to find the right ticket at the right price.'
    },
    {
        id: 7,
        question: 'Some tickets on PrimeTic are less than I paid. Why?', 
        answer: 'Sellers on PrimeTic set their prices. Some sellers may be happy to sell for less than others, depending on their circumstances. Set up price alerts to discover the best price.'
    },
    {
        id: 8,
        question: 'How can I trust the seller hasn’t already sold the tickets that are up for sale?', 
        answer: 'We’ve got some great processes in our platform. We guarantee that sellers follow through with their sales and deliver the tickets correctly. When you buy with PrimeTic, you can trust that your ticket will get you into the event.'
    },
    {
        id: 9,
        question: 'I need to contact the seller?',
        answer: 'We protect our buyers and sellers, so you won’t be able to contact the seller. For data security reasons, we will never disclose personal contact details. If you have a problem, we can help. Contact us on customersupport@primetic.com.'
    },
    {
        id: 10,
        question: 'Can I buy a ‘Senior’ or ‘Junior’ ticket if I don’t fit the criteria?',
        answer: 'If you don’t fit the criteria of the ticket you buy from PrimeTic, the venue has the right to refuse your entry. They will likely check ID, so it’s not worth the risk.'
    }
]

export const array_list = [
    {
        id: 1,
        heading: 'Month Listings',
        color: 'bg-primary',
        sparkline_list: [5,3,9,6,5,9,7,3,5,2],
        total_amount: '$6864.00',
        bottom_text: {
            left: {
                text: 'Volume',
                value: '9'
            }
        }
    },
    {
        id: 2,
        heading: 'Month Orders',
        color: 'bg-info',
        sparkline_list: [5,3,9,6,5,9,7,3,5,2],
        total_amount: '$4,625',
        bottom_text: {
            left: {
                text: 'Gross Sales',
                value: '$2,210'
            },
            right: {
                text: 'Tax Return',
                value: '$320'
            }
        }
    },
    {
        id: 3,
        heading: 'MONTH PAYOUTS',
        color: 'bg-purple',
        sparkline_list: [5,3,9,6,5,9,7,3,5,2],
        total_amount: '$6864.00',
        bottom_text: {
            left: {
                text: 'Volume',
                value: '9'
            },
            right: {
                text: 'Tax Return',
                value: '$320'
            }
        }
    },
    {
        id: 4,
        heading: 'MONTH PENDING',
        color: 'bg-dark',
        sparkline_list: [5,3,9,6,5,9,7,3,5,2],
        total_amount: '$6864.00',
        bottom_text: {
            left: {
                text: 'Volume',
                value: '9'
            },
            right: {
                text: 'Tax Return',
                value: '$320'
            }
        }
    },
]