import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import NavbarLayout from './NavbarLayout'
import { useDispatch, useSelector } from 'react-redux'
import loading_gif from '../../../../assets/images/logo-loading-gif.gif'
import loading_gifImg from '../../../../assets/images/logo-loading-gif-img.gif'

import {
  clearAll,
  clearOnReload,
  logoutAppUser,
  setCartValues,
  setEventValues,
  onGetCart,
  setSelectedMapSection,
  setData,
} from '../../../../config/redux/slice/appSlice'
import {
  formatNumber,
  setCartNo,
  transformTicketsList,
} from '../../../../helpers/helpers'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { setDetails } from '../../../../config/redux/slice/appSlice'
import {
  logoutCustomerUser,
  setCustomerErrorMessage,
  setLastVisitedPage,
} from '../../../../config/redux/slice/customerSlice'
import StadiumMap from './StadiumMap'
import EventFilter from './EventFilter'
import EventTicket from './EventTicket'
import spinner_loader from '../../../../assets/images/Spinner-loading.gif'
import ErrorPage from '../../../layout/ErrorPage'
import { logoutUser } from '../../../../config/redux/slice/authSlice'
import { logoutAdminUser } from '../../../../config/redux/slice/adminSlice'
import CartModal from './CartModal'
import CookieNotice from '../../../components/CookieNotice'

export default function EventPage() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { setPageLoading } = location.state || {}
  const { eventId } = useParams()
  const [isbadRequest, setIsbadRequest] = useState(false)
  const [numberOfTicket, setNumberOfTicket] = useState('')
  const [minPrice, setMinPrice] = useState('')
  const [maxPrice, setMaxPrice] = useState('')
  const [openSeat, setOpenSeat] = useState(false)
  const [initialMinPrice, setInitialMinPrice] = useState("")
  const [initialMaxPrice, setInitialMaxPrice] = useState("")
  const [listingList, setListingList] = useState([])
  const [path] = useState(window.location.pathname)
  const [filterIds, setFilterIds] = useState({})
  const [listLoading, setListLoading] = useState(false)
  const [isFetchAllListings, setIsFetchAllListings] = useState(true)
  const [totalValueRecords, setTotalValueRecords] = useState(0)
  const [eventDetails, setEventDetails] = useState({})

  const paginationData = {
    page: 1,
    pageSize: 10,
  }

  const {
    eventManagement: { pageLoading, selectedMapSection, sectionData },
    cartManagement: {
      showCartModal: { show, type },
      listingsInCart,
    },
  } = useSelector((state) => state.app)

  const { token, userData } = useSelector((state) => state.authData)

  const { customerErrorMsg, customerSuccessMsg } = useSelector(
    (state) => state.customer
  )
  useEffect(() => {
    if (listingList && listingList.length > 0) {
      //  debugger
      const prices = listingList.map(listingOption =>
        transformTicketsList(
          listingOption?.Tickets,
          listingOption, totalValueRecords, numberOfTicket, minPrice, maxPrice
        ).map(option => {

          const basePrice = option?.negotiatedPrice ?? listingOption?.pricePerTicket;
          return Number(basePrice);
        })
      );
      const flattenedPrices = prices.flat();
      const minPrice22 = Math.floor(Math.min(...flattenedPrices));
      const maxPrice22 = Math.ceil(Math.max(...flattenedPrices));
      //  initialPriceChange(minPrice22,maxPrice22)
      console.log("aaaaaaaaaaaaaaaaaaaa11111111122222222222222333333333333333333", flattenedPrices)
      if (flattenedPrices.length == 0) {
        setInitialMinPrice("");
        setInitialMaxPrice("");

        setOpenSeat(true)
      } else {
        setInitialMinPrice(minPrice22);
        setInitialMaxPrice(maxPrice22);
        setOpenSeat(false)
      }
      // setMinPrice(Math.min(...prices));
      // setMaxPrice(Math.max(...prices));
    }
  }, [listingList]);
  useEffect(() => {
    if (customerSuccessMsg) {
      let msg
      if (Array.isArray(customerSuccessMsg)) {
        msg =
          customerSuccessMsg[0].toLowerCase().charAt(0).toUpperCase() +
          customerSuccessMsg[0].slice(1)
      } else {
        msg =
          customerSuccessMsg.toLowerCase().charAt(0).toUpperCase() +
          customerSuccessMsg.slice(1)
      }

      dispatch(clearAll())

      showToastMessage(msg, false)
    }

    if (customerErrorMsg) {
      let err
      if (Array.isArray(customerErrorMsg)) {
        err =
          customerErrorMsg[0].toLowerCase().charAt(0).toUpperCase() +
          customerErrorMsg[0].slice(1)
      } else {
        err =
          customerErrorMsg.toLowerCase().charAt(0).toUpperCase() +
          customerErrorMsg.slice(1)
      }

      if (err?.toLowerCase() === 'unauthorized') {
        dispatch(logoutUser())
        dispatch(logoutAdminUser())
        dispatch(logoutAppUser())
        dispatch(logoutCustomerUser())
        err = null
      } else {
        showToastMessage(err, true)
      }
      dispatch(clearAll())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSuccessMsg, customerErrorMsg])

  useEffect(() => {


    // setIsFetchAllListings(true)
    removeCartItems()
    dispatch(clearOnReload())
    if (setPageLoading !== false) {
      dispatch(setEventValues({ propertyKey: 'pageLoading', value: true }))
    }
    getSingleEvent()
    dispatch(setLastVisitedPage(null))

    return () => {
      setListingList([])
      navigate(window.location.pathname, { replace: true })
      // dispatch(setShouldModifyCart(true))
      // dispatch(setDetails({details: {}, type: 'event'}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const removeCartItems = async () => {
    const res = await get_delete_request(
      'delete',
      token,
      `${app_api.remove_items_from_cart(userData.id)}`,
      {},
      dispatch,
      '',
      '',
      setCustomerErrorMessage
    )

    if (res !== undefined && res.status < 400) {
      setCartData();
      getFirstPageListings("")
    }
    else {
      getFirstPageListings("")
    }

  }
  const setCartData = async () => {
    dispatch(onGetCart([]))
    dispatch(setData({ data: [], type: 'cart' }))
    let cartNo = setCartNo([])
    dispatch(setCartValues({ propertyKey: 'cartNumber', value: cartNo }))
  }
  // useEffect(()=>{
  //   if(listingList.length !== 0 && cartData.length !== 0){
  //     console.log(listingList.length !== 0 && cartData.length !== 0)
  //     let cartTickets = [];
  //   let eventTickets = []
  //   let currentEventListings = cartData.filter((opt)=>opt?.event?.id === eventDetails?.id)
  //   currentEventListings.forEach((opt)=>{
  //     const ticketObj = opt?.Tickets ?? []
  //     cartTickets = [...cartTickets, ...ticketObj]
  //   })
  //   listingList.forEach((opt)=>{
  //     const ticketObj = opt?.Tickets ?? []
  //     eventTickets = [...eventTickets, ...ticketObj]
  //   })
  // if(cartTickets.length >= eventTickets.length){
  //     const checkIfSameObj = () => {
  //       const idMap = new Map();
  //       for (const obj of cartTickets) {
  //           idMap.set(obj.id, true);
  //       }
  //       console.log(idMap)
  //       for (const obj of eventTickets) {
  //         if (!idMap.has(obj.id)) {
  //             return false;
  //         }
  //       }
  //       return true
  //     }
  //     if(checkIfSameObj() === true){
  //       setListingList([])
  //     }
  // }
  //   }
  // }, [cartData])

  const onChangeSection = async (sectId) => {
    dispatch(clearAll({}))
    setInitialMaxPrice('')
    setInitialMinPrice('')
    setIsFetchAllListings(sectId === "" ? true : false)
    setFilterIds({
      sectId,
      subSectId: '',
    })
    dispatch(setCartValues({ propertyKey: 'searchingTickets', value: true }))
    if (sectId === "") {
      dispatch(setSelectedMapSection(""))
    }

    // getAllListings({sectionId: sectId})
    getFirstPageListings({ sectionId: sectId })
  }

  useEffect(() => {
    let singleSection = sectionData.filter(
      (option) => option.name.toLowerCase() === selectedMapSection.toLowerCase()
    )

    if (singleSection.length > 0) {
      onChangeSection(singleSection[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMapSection, sectionData])

  const getSingleEvent = async () => {
    dispatch(clearAll({}))
    setIsbadRequest(false)
    const res = await get_delete_request(
      'get',
      '',
      `${app_api.get_single_event(eventId)}`,
      {},
      dispatch,
      '',
      '',
      ''
    )
    if (res !== undefined && res.status < 400) {
      setEventDetails(res?.data?.data)
      dispatch(setDetails({ details: res?.data?.data, type: 'event' }))
    } else {
      setIsbadRequest(true)
      // dispatch(setDetails({details: {}, type: 'event'}))
      // dispatch(setDetailsCustomer({details: {}, type: 'event'}))
    }
  }

  // const getCart = async () => {
  //   dispatch(clearAll({}))
  //   const res = await get_delete_request(
  //     'get',
  //     token,
  //     `${app_api.get_cart}`,
  //     {},
  //     dispatch,
  //     '',
  //     onGetCart,
  //     setCustomerErrorMessage
  //   )
  //   if (res !== undefined && res.status < 400) {
  //     let cartNo = 0
  //     dispatch(setData({ data: res?.data?.data, type: 'cart' }))
  //     res?.data?.data.forEach((opt) => {
  //       if (opt.sellingOption == 'ANY') {
  //         opt.Tickets.forEach((ticketOpt) => {
  //           cartNo += 1
  //         })
  //       } else {
  //         let arr = opt.Tickets.map((option) => {
  //           return option.row
  //         })
  //         const uniqueSet = new Set(arr)

  //         // Convert the Set back to an array
  //         const uniqueArray = Array.from(uniqueSet)
  //         uniqueArray.forEach((ticketOpt) => {
  //           cartNo += 1
  //         })
  //       }
  //       // else{
  //       //   cartNo += 1
  //       // }
  //     })
  //     dispatch(setCartValues({ propertyKey: 'cartNumber', value: cartNo }))
  //   } else if (res !== undefined && res.status == 401) {
  //     dispatch(setLastVisitedPage(path))

  //     logout()
  //   }
  // }
  const handlePriceChange = (min, max) => {
    // debugger
    setMinPrice(min);
    setMaxPrice(max);
  };
  const handleInitialPriceChange = (min, max) => {
    setInitialMinPrice(min);
    setInitialMaxPrice(max);
  };
  const onChangeSubSection = async (subSectId, sectId) => {
    dispatch(clearAll({}))
    setFilterIds({
      sectId,
      subSectId,
    })
    getFirstPageListings({

      sectionId: sectId,
      subSectionId: subSectId,
    })

    // getAllListings({sectionId: sectId, subSectionId: subSectId})
  }

  const buildQueryParams = (data) => {
    let query = ''
    for (let key in data) {
      if (data[key]) {
        query = query ? `${query}&${key}=${data[key]}` : `?${key}=${data[key]}`
      }
    }
    return query
  }

  // const getAllListings = async (data) => {
  //   dispatch(clearAll({}))
  //   dispatch(setCartValues({propertyKey: 'searchingTickets', value: true}))
  //   let queryParams = buildQueryParams(data)
  //   const res = await get_delete_request(
  //     'get',
  //     "",
  //     `${app_api.get_listings_by_event_user(eventId, queryParams)}`,
  //     {},
  //     dispatch,
  //     "",
  //     "",
  //     ""
  //   )
  //   if(res !== undefined && res.status < 400){
  //     setListingList(res?.data?.data?.data)
  //     dispatch(setCartValues({propertyKey: 'searchingTickets', value: false}))
  //   }
  // }

  const getFirstPageListings = async (data) => {
    // debugger
    setInitialMaxPrice('')
    setInitialMinPrice('')
    setNumberOfTicket(data.numberOfTickets != undefined ? data.numberOfTickets : numberOfTicket)
    dispatch(clearAll({}))
    dispatch(setCartValues({ propertyKey: 'searchingTickets', value: true }))

    let queryParams = buildQueryParams(data)
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_listings_by_event_user(eventId)}${queryParams}`,
      {},
      dispatch,
      '',
      '',
      setCustomerErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      setListingList(res?.data?.data);
      // const filteredListings = res?.data?.data?.filter(listing => listing.userId !== userData.id);
      // setListingList(filteredListings)
      setTotalValueRecords(res?.data?.data?.meta?.total)
      dispatch(setCartValues({ propertyKey: 'searchingTickets', value: false }))
    }
  }

  const getAllListings = async (data) => {
    dispatch(clearAll({}))
    setListLoading(true)
    let queryParams = buildQueryParams(data)
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_listings_by_event_user(eventId)}${queryParams}`,
      {},
      dispatch,
      '',
      '',
      setCustomerErrorMessage
    )

    if (res !== undefined && res.status < 400) {
      const listings = res?.data?.data;
      if (Array.isArray(listings)) {
        setListingList(listings);
        setTotalValueRecords(res?.data?.data?.meta?.total)
        dispatch(setCartValues({ propertyKey: 'searchingTickets', value: false }))
        setListLoading(false)
      }
    } else {
      setListLoading(false)
    }
  }

  const modifyListingObj = (listingobj, listingObjId) => {
    let x = [...listingList]
    let ind = listingList.findIndex((opt) => opt.id === listingObjId)
    if (ind !== -1) {
      x.splice(ind, 1, listingobj)
      setTimeout(() => {
        setListingList(x)
      }, 1500)
    }
  }

  const filterTickets = async (negotiatedPrice) => {
    dispatch(clearAll({}))
    dispatch(setCartValues({ propertyKey: 'searchingTickets', value: true }))
    getAllListings({ sectionId: (filterIds?.sectId ? filterIds?.sectId : ""), subSectionId: (filterIds?.subSectId ? filterIds?.subSectId : ""), maxPrice: negotiatedPrice })
  }

  // const logout = () => {
  //   dispatch(logoutUser())
  //   dispatch(logoutCustomerUser())
  //   dispatch(logoutAppUser())
  //   dispatch(logoutAdminUser())
  //   navigate(app_urls.login)
  // }

  const showToastMessage = (msg, isError) => {
    if (isError) {
      toast.error(msg, {
        position: toast.POSITION.TOP_CENTER,
      })
    } else {
      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  return (
    <>
      {isbadRequest ? (
        <ErrorPage status={400} message={'Event Does Not Exist'} />
      ) : (
        <div className="relative">
          <ToastContainer />
          {show && type === 'cart' && <CartModal pagePath={path} />}
          <NavbarLayout eventObj={eventDetails} />
          <div className="event-stadium-map d-flex flex-column flex-lg-row flex-wrap">
            <div className="stadium-item first-stadium-item flex-lg-grow-1 px-3 pt-4 px-lg-2 px-xl-3">
              {eventDetails?.Venue?.name && (
                <EventFilter
                  onChangeSection={onChangeSection}
                  onChangeSubSection={onChangeSubSection}
                  paginationData={paginationData}
                  getRequestFirstPage={getFirstPageListings}
                  venueObj={eventDetails?.Venue}
                  onPriceChange={handlePriceChange}
                  initialMinPrice={initialMinPrice}
                  initialMaxPrice={initialMaxPrice}
                />
              )}
            </div>
            <div
              // style={{ backgroundColor: '#CBD5E1' }}
              style={{ backgroundColor: '#FFF' }}
              className="stadium-item second-stadium-item"
            >
              {eventDetails?.Venue?.name && (
                <StadiumMap stadiumObj={eventDetails?.Venue} />
              )}
            </div>
            <div
              // style={{ backgroundColor: '#F1F2F4' }}
              style={{ backgroundColor: '#FFF' }}
              className="stadium-item third-stadium-item pt-3 pb-4"
            >
              <EventTicket
                totalValueRecords={totalValueRecords}
                numberOfTickets={numberOfTicket}
                minPrice={minPrice}
                maxPrice={maxPrice}
                openSeat={openSeat}
                listLoading={listLoading}
                getRequest={getAllListings}
                paginationData={paginationData}
                isFetchAllListings={isFetchAllListings}
                pagePath={path}
                filterIds={filterIds}
                filterTickets={filterTickets}
                listingList={listingList}
                modifyListingObj={modifyListingObj}
                initialPriceChange={handleInitialPriceChange}

              />
            </div>
          </div>
          {pageLoading && (
            <div
              style={{
                height: '100vh',
                position: 'absolute',
                top: 0,
                zIndex: 1800,
                backgroundColor: 'rgba(0,0,0,0.9)',
                // backgroundColor:"#fff"
              }}
              className={`w-100 position-absolute d-flex justify-content-center align-items-center`}
            >
              {/* <img height={80} src={spinner_loader} alt="loader" /> */}
              {/* <img src={loading_gif} /> */}
              <div className="loading-main-wrapper">
                <div className="loading-topsec">
                  <h3 className="loading-main-text">We'll be with you in a Tic!</h3>
                  {/* <p className="loading-sub-text">Sub Text</p> */}
                </div>
                <div className="loading-btmsec">
                  <img src={loading_gifImg} height={40} alt="loader" />
                </div>
              </div>
            </div>
          )}
          <CookieNotice />
        </div>
      )}
    </>
  )
}
