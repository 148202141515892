import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  setEventValues,
} from '../../config/redux/slice/appSlice'
import { useForm } from 'react-hook-form'
import search_icon from '../../assets/images/search_icon.svg'
import { get_delete_request } from '../../helpers/request'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../utils/urls/app_urls'
import { app_api } from '../../utils/endpoints/app_api'

export default function SearchComponent({ landingPage }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { register, handleSubmit,watch} = useForm({ mode: 'onTouched' })
  const scrollContainerRef = useRef(null)
  const [searchVal, setSearchVal] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [topEvents, setTopEvents] = useState([]) // State to hold top events
  const [filteredTopEvents, setFilteredTopEvents] = useState([]) // State to hold filtered top events based on search
  const searchInput = watch('name');
  const {
    eventManagement: { searchResult },
  } = useSelector((state) => state.app)
  const { token, userData } = useSelector((state) => state.authData)

  const eventData = useSelector((state) => state.app.eventManagement.eventData);
    const topTrendingEvents = eventData;


  const dropdownRef = useRef(null)
  useEffect(() => {
    if (searchInput) {
      handleSearch({ name: searchInput });
    } else {
      setFilteredTopEvents(topTrendingEvents); // Reset to top events if search input is cleared
    }
  }, [searchInput]);
  useEffect(() => {
    // Fetch top events when component mounts
    // const fetchTopEvents = async () => {
    //   try {
    //     const res = await get_delete_request(
    //       'get',
    //       '',
    //       `${app_api.get_top_events}`,
    //       {},
    //       dispatch,
    //       "",
    //       "",
    //       ""
    //     );
    //     if (res && res.status < 400) {
    //       const events = res.data.data;
    //       setTopEvents(events);
    //       setFilteredTopEvents(events.slice(0, 10)); // Display only the first 10 events initially
    //     }
    //   } catch (error) {
    //     console.error('Error fetching top events:', error);
    //   }
    // };

    // fetchTopEvents();

    return () => {
      dispatch(setEventValues({ propertyKey: 'searchResult', value: [] }))
    };
  }, [dispatch, token, userData]);

  const handleTopEventsClick = (event) => {
    // Handle click on top event in dropdown
    const { id } = event;
    if (landingPage === 'sell') {
      navigate(app_urls.sell_ticket_dyn(id));
    } else {
      navigate(app_urls.single_event_dyn(id));
    }
  };

  const handleSearch = (data) => {
    if (data.name.trim() === '') {
      // If search term is empty, reset to display the initial 10 events
      setFilteredTopEvents(topTrendingEvents);
      setSearchVal('');
    } else {
      // Filter the top events based on the search term
      const filteredEvents = topTrendingEvents.filter((event) =>
        event.name.toLowerCase().includes(data.name.toLowerCase())
      );
      setFilteredTopEvents(filteredEvents);
      setSearchVal(data.name);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(true);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.classList.contains('form-inp')
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document
      .getElementById('body')
      .addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      if (document.getElementById('body')) {
        document
          .getElementById('body')
          .removeEventListener('click', handleClickOutside);
      }
    };
  }, []);

  return (
    <div
      className="form-inp position-relative"
      onClick={() => {
        toggleDropdown();
        if (!isOpen) {
          dispatch(setEventValues({ propertyKey: 'searchResult', value: [] }));
        }
      }}
    >
      <form
        onSubmit={handleSubmit((data) => {
          handleSearch(data);
        })}
      >
        <div className="input-group form-inp">
          <button
            className="btn bg-white rounded-start-pill form-inp"
            type="button"
            id="button-addon1"
          >
            <img src={search_icon} alt="" />
          </button>
          <input
          // onChange={(e) => {
          //   handleSubmit(({name:e}) => {
          //     handleSearch({name:e});
          //   })();
          // }}
            type="text"
            autoComplete="off"
            className="form-control rounded-end-pill form-inp"
            placeholder={
              landingPage === 'sell'
                ? 'Search for your event'
                : 'Search for tickets'
            }
            aria-label="Example text with button addon"
            aria-describedby="button-addon1"
            {...register('name')}
          />
        </div>
      </form>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="position-absolute searchbar w-100 bg-transparent border-0 form-inp"
          style={{maxWidth:"100%"}}
        >
          <div
            ref={scrollContainerRef}
            className={`form-inp ${
              filteredTopEvents.length > 0
                ? 'bg-white'
                : ''
            }`}
            style={{ overflowY: 'auto', maxHeight: '430px',width:"100%" }}
          >
            {filteredTopEvents.length > 0 ? (
              filteredTopEvents.slice(0,10).map((event) => (
                <div
                  key={event.id}
                  onClick={() => handleTopEventsClick(event)}
                  className="d-flex align-items-center bg-white searchbar-event border py-2 px-1 form-inp"
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    width={80}
                    height={80}
                    crossOrigin="anonymous"
                    src={event.poster}
                    alt="poster"
                  />
                  <p style={{ fontSize: '0.9rem', marginLeft:"15px" }} className="card-text">
                    <span className="text-accent fw-bold">
                      {event.name}
                    </span>
                    <br />
                    <span className="fw-bold">
                      {moment(event.date).format('ddd Do MMM YYYY - h:mma')}
                    </span>
                    <br />
                    <span>{event.Venue.name}</span>
                  </p>
                </div>
              ))
            ) : (
              <div className="text-center bg-white py-2">
                <span>No events found</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
