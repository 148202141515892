import React, { useEffect } from 'react'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { listingItemAdminDatatable } from '../../../../helpers/dataTables'


import {
  clearAll,
  clearResetBot,
  setAdminErrorMessageUser,
  setDetails,
  showListingModalFunc,
} from '../../../../config/redux/slice/adminSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


export default function SingleListingTable({ sectionObj }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const { token } = useSelector((state) => state.authData)

  const {breadCrumbName, currentNavDetails} = useSelector((state) => state.app)


  const {
    listingManagement: {listingDetails },
  } = useSelector((state) => state.admin)


  console.log("listingDetailsnew",listingDetails.listings)

 
  useEffect(() => {
    dispatch(clearAll({}))
    listingItemAdminDatatable(
        listingDetails.listings,
      //errorFunc,
      'listingItemAdminTable',
      //`${app_api.get_listings}`,
      //`${app_api.search_listing}`,
      //token,
      setItem
    )
    // getAllCountries()
  }, [])

//   useEffect(()=>{
//     // if(newData){
//         listingItemAdminDatatable(
//         listingDetails.listings,
//       'listingItemAdminTable',
//       setItem
//       )
//       // dispatch(clearResetBot())
//     // }   

//   }, [listingDetails.listings])

//   useEffect(() => {
//     if (newData) {
//       listingAdminDatatable(
//         errorFunc,
//         'listingAdminTable',
//         `${app_api.get_listings}`,
//         `${app_api.search_listing}`,
//         token,
//         setItem
//       )
//       dispatch(clearResetBot())
//     }
//   }, [newData])



 

  const setItem = (data, type) => {
    dispatch(setDetails({ details: data, type: 'listing' }))
    dispatch(showListingModalFunc({ modal: true, type }))
  }

//   const errorFunc = (msg) => {
//     dispatch(setAdminErrorMessageUser({ message: msg }))
//   }

  return (
    <DataTableWrapper table_id="listingItemAdminTable" table_heading="Listings">
      <thead>
        <tr>
          <th className="wd-5p">Listing Id</th>
          <th className="wd-15p">Event</th>
          <th className="wd-10p">No of Tickets Listed</th>
          <th className="wd-10p">Price Per Ticket</th> 
          <th className="wd-10p">Face Value</th>
          <th className="wd-10p">Reserved Price Per Ticket</th>
          <th>Status</th>
          <th className="wd-10p">Section</th>
          <th className="wd-10p">Block</th>
          <th className="wd-10p">Listed On</th>
          <th className="wd-10p">Listed By</th>
          <th className="wd-10p">Email</th>
          <th className="wd-10p">Phone No</th>
          <th className="wd-10p">Address</th>
          <th className="wd-15p">Actions</th>
        </tr>
      </thead>
      <tbody></tbody>
    </DataTableWrapper>
  )
}
