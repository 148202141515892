import React from 'react'
import GeneralWrapper from '../../../layout/GeneralWrapper'

export default function PrivacyPolicy() {
  const currentYear = new Date().getFullYear();
  return (
    <GeneralWrapper>
        <div className='px-3 px-lg-100 px-xl-200 mt-3 mb-3'>
      <>
  <div className="WordSection1">
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      User Privacy Notice
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      LAST UPDATED:&nbsp; 1 January, {currentYear}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "9.7pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      About us
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.65pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      What information do we collect?
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.65pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      How do we use your information?
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.65pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Third Party Advertising
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.65pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Who do we share your information with?
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.65pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Other uses and disclosures of information
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.65pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      How do we transfer your information?
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.55pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      How long do we keep your information?
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.65pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      What information do we collect?
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.55pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Security
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.65pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Sensitive information
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.55pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Third Party Payment Service
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.65pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Third Party Services
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.55pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Use of services by minors
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.65pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Your privacy rights and choices
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.55pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Updates to this privacy policy
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.65pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Contacting Us
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.55pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: ".25in",
        textIndent: ".25in"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      Additional information regarding the EEA
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "1.65pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.1pt",
        marginLeft: ".25in",
        textIndent: ".25in",
        lineHeight: "107%"
      }}
    >
      <span style={{ fontFamily: '"Times New Roman",serif' }}>
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>{" "}
      U.S. PRIVACY ADDENDUM FOR RESIDENTS OF CALIFORNIA, COLORADO, CONNECTICUT,
      UTAH, AND VIRGINIA&nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      About us
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Prime Events Limited( Trading as PrimeTic) registered in Ireland No
      753108&nbsp; respects your privacy. We want you to be informed and
      empowered with respect to your privacy when you use our services. Please
      read this Privacy Policy carefully. Together with&nbsp; the Cookies
      Policy, this Privacy Policy sets out our views and practices regarding
      your personal information and the rights you have in relation to your
      personal information. This Privacy Policy describes our practices in
      connection with information that we collect through:
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.05pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.95pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      Websites operated by us from which you are accessing this Privacy Policy
      (the “Websites”);
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Software applications made available by us for use on or through computers
      and mobile devices (the “Apps”);
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.95pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      Our social media pages that we control from which you are accessing this
      Privacy Policy (the “Social Media Pages”);
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      HTML-formatted email messages that we send to you that link to this
      Privacy Policy or other communications with you; and
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Offline business interactions you have with us.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.05pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Collectively, we refer to the Websites, Apps, Social Media Pages, emails,
      and offline business interactions as the “Services.”
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      <span style={{ color: "red" }}>&nbsp;</span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: "-.25pt"
      }}
    >
      The data controller of your personal information is Prime EventsLtd,
      Registered address Unit 3D
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      North Point House, North Point Business Park, New Mallow Road Cork
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.05pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      <i>
        If you are a resident of California, Colorado, Connecticut, Utah, or
        Virginia, please see our U.S. Privacy Addendum below for more
        information about how we handle your personal information and how to
        exercise your privacy rights.
      </i>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      What information do we collect?
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We may collect and process the following information about you:
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Information you give us. You may give us information when you register
      with our Services or by communicating with us by phone, email, social
      media or otherwise. This includes information you provide when you create
      a customer account, use our services, provide us with identification
      information required to verify your identity or in connection with the
      issue of your tickets, provide us with feedback, participate in surveys,
      when you report a problem with our Services, in the context of promotions
      (including prize draws), when you participate in a community board
      discussion chat, or when you otherwise correspond with us.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      The information you give us may include your name, address, email address,
      phone number, date of birth, and credit or debit card details.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      It may also include information you provide us when creating a profile on
      PrimeTic.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We may monitor or record telephone conversations or other communications
      between you and us.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Information we collect about you based on your use of our Services,
      including:
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      The Internet protocol (IP) address used to connect your device to the
      Internet, your login information, browser type and version, time zone
      setting, operating system, device information (such as the model and
      version of your device), platform, ID for advertising, and unique device
      token, Computer and connection information such as statistics on your page
      views, traffic to and from the sites, referral URL, ad data, your browsing
      history, and your web log information.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Information about your use of our Services, including products and events
      you viewed or searched for; page response times, download errors, length
      of visits to certain pages, page interaction information (such as
      scrolling, clicks, and mouse-overs), methods used to browse away from the
      page and any data entered into the page during your visit.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.05pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      <i>
        &nbsp;When you download and use the App, we and our service providers
        may track and collect App usage data, such as the date and time the App
        on your device accesses our servers and what information and files have
        been downloaded to the App based on your device number;
      </i>
      Events you will be attending or have attended based on your purchases on
      PrimeTic (“My Activity”).
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Your PrimeTic viewing history of events or artists.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Your favourite artists, shows, teams, venues, genres or any other
      event-related interests based on your scanned music library(ies) (if you
      choose to enable this service on your computer or device) and anything you
      identify as a favourite on PrimeTic (collectively, your
      “Favourites”).&nbsp; <b>&nbsp;</b>
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Contract data. This is general information that is collected from our
      contractual partners (if they are natural persons) or our contractual
      partners' employees and other contact persons. This includes information
      about the type of contract, date of conclusion of the contract, duration
      of the contract, contractual services, data that was collected during the
      period leading up to the conclusion of the contract, information required
      or used for processing (e.g. information regarding invoicing), financial
      data (e.g. about reminders and debt collection).
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.05pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      <i>
        &nbsp; Physical Location. We may use your device’s physical location to
        provide you with personalized location-based services and content. We
        may also share your device’s physical location, combined with
        information about what advertisements you viewed and other information
        we collect, with our marketing partners to enable them to provide you
        with more personalized content and to study the effectiveness of
        advertising campaigns. In some instances, you may be permitted to allow
        or deny such uses and/or sharing of your device’s location, but if you
        do, we and/or our marketing partners may not be able to provide you with
        the applicable personalized services and content.
      </i>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Information collected from cookies. We and our service providers collect
      information about your use of our Services using cookies. For information
      about our use of cookies and how to decline them or turn them off please
      read our Cookies Policy.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Information we receive from other sources. We work closely with third
      parties including, for example, <i>our fraud prevention providers</i>,
      payment service providers and email address / telephone number
      verification service providers. We may receive information about you from
      these third parties to verify information you provide to us which helps us
      to identify suspicious or unusual transactions.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.05pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      <i>
        We allow you to share information with social media sites, or use social
        media sites to create your account or to connect your account with the
        respective social media site. If you connect your social media account
        to your Services account, you will share certain Personal Information
        from your social media account with us, for example, your name, email
        address, photo, list of social media contacts, and any other information
        that may be or you make accessible to us when you connect your social
        media account to your Services account.
      </i>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.05pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      <i>
        If you agree to provide us with access to any social media site with
        video content, then we may reshare or otherwise engage with your posts
        and interactions on third-party social media sites for at least two
        years or until you withdraw authorization or connection to the social
        media site, to the extent permitted by law.
      </i>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.05pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      <i>
        We may also use plug-ins or other technologies from various social media
        sites. If you click on a link provided via a social media plug in, you
        are voluntarily establishing a connection with that respective social
        media site.
      </i>
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      You are generally not obliged to disclose data to us except in certain
      cases, for example because of legal obligations. We need to collect
      Personal Information in order to provide the requested Services to you. If
      you do not provide the information requested, we may not be able to
      provide the Services. If you disclose any Personal Information relating to
      other people to us or to our service providers in connection with the
      Services, you represent that you have the authority to do so and to permit
      us to use the information in accordance with this Privacy Policy.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      How do we use your information?
    </p>
    <p
      className="MsoNormal"
      style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We and our service providers use personal information for the following
      purposes:
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Providing the functionality of the Services and fulfilling your requests.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To provide the Services’ functionality to you, such as arranging access to
      your registered account, enabling you to purchase tickets, managing your
      relationship with us, and providing you with related benefits, special
      promotions, or customer service;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To respond to your inquiries and fulfil your requests, when you contact us
      via one of our online contact forms or otherwise, for example, when you
      send us questions, suggestions, compliments or complaints, or when you
      request a quote for or other information about our Services.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To complete your transactions, process returns and exchanges, verify your
      information, and provide you with related benefits, special promotions, or
      customer service.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To send administrative information to you, such as changes to our terms,
      conditions, and policies.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We will engage in these activities to manage our contractual relationship
      with you and/or to comply with a legal obligation.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Communicating with you, providing you with our newsletter and/or other
      marketing materials, and facilitating social sharing.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To send you marketing related emails, with information about our services,
      new products and other news about our company.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To poll your opinions through surveys or questionnaires.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To contact you about public policy matters, or other current events,
      related to your ability to use our Services. This could include an
      invitation to join a petition, letter writing, call or other sort of
      public policy related campaigns.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To communicate with you by telephone, mail, email, text (SMS) message,
      instant messaging or other electronic means, consistent with applicable
      law. We may use autodialed or pre-recorded calls and text messages and as
      authorized by applicable law. Message and data rates may apply.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To facilitate social sharing functionality that you choose to use.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We will engage in this activity with your consent or where we have a
      legitimate interest.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Initiation, administration and execution of contracts with suppliers,
      subcontractors or other contractual partners
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To open, manage and terminate the contractual relationship.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To provide and demand contractual services.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To enforce legal claims arising from contracts (debt collection, legal
      proceedings, etc.).
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      For accounting.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We engage in these activities to manage our contractual relationships, to
      comply with a legal obligation, and/or based on our legitimate interest.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Analyzing personal information for business reporting and providing
      personalized services.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.95pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      To analyze or predict our users’ preferences in order to prepare
      aggregated trend reports on how our digital content is used, so we can
      improve our Services.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To better understand your interests and preferences, so that we can
      personalize our interactions with you and provide you with information
      and/or offers, coupons, discounts and special promotions tailored to your
      interests.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To better understand your preferences so that we can deliver content via
      our Services that we believe will be relevant and interesting to you.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We will provide personalized services based on our legitimate interests,
      and with your consent to the extent required by applicable law.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Allowing you to participate in contests or other promotions.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We may offer you the opportunity to participate in a contest or other
      promotion.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Some of these promotions have additional rules containing information
      about how we will use and disclose your personal information. Please read
      those additional rules before choosing to participate.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We use this information to manage our contractual relationship with you.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Aggregating and/or anonymizing personal information.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We may aggregate and/or anonymize personal information so that it will no
      longer be considered personal information. We do so to generate other data
      for our use, which we may use and disclose for any purpose, as it no
      longer identifies you or any other individual.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Accomplishing our business purposes.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      For data analysis, for example, to improve the efficiency of our Services.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      For audits, to verify that our internal processes function as intended and
      to address legal, regulatory, or contractual requirements.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      For fraud prevention and detection and fraud security monitoring purposes,
      for example, to verify your identity as part of our identity
      authentication process, to comply with our legal or regulatory
      requirements, and to detect and prevent cyberattacks or attempts to commit
      identity theft.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      For training and quality purposes and for the purposes of investigating
      any complaint you may make, or as evidence in any dispute or anticipated
      disputes between you and us.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      For developing new products and services.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      For enhancing, improving, repairing, maintaining, or modifying our current
      products and services, as well as undertaking quality and safety assurance
      measures.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      For identifying usage trends, for example, understanding which parts of
      our Services are of most interest to users.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      For determining the effectiveness of our promotional campaigns, so that we
      can adapt our campaigns to the needs and interests of our users.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      For operating and expanding our business activities, for example,
      understanding which parts of our Services are of most interest to our
      users so we can focus our energies on meeting our users’ interests.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We engage in these activities to manage our contractual relationship with
      you, to comply with a legal obligation, and/or based on our legitimate
      interest.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We also use technology to help validate your transactions and keep our
      service safe
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We employ technology to analyse transactions you enter into against the
      information we collect from you or from third parties about you, such as
      from where you ordinarily use our service, at what times, and other
      characteristics specific to how you use our services. The technology is
      aimed to help us identify unusual or suspicious transactions in order to
      detect and prevent fraud. This means that an attempted transaction may be
      marked as suspicious, unusual or otherwise out of the ordinary, as a
      result of which your transaction may be declined. Where you are
      encountering that your validly executed transaction is declined, you can
      always contact customer service who will help you validate your current
      and futures transactions.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If you have any questions about our tool and how it relates to any of your
      personal information processed, you can contact us via
      support@primeevents.live.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Third Party Advertising
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We use third-party advertising companies to serve advertisements regarding
      goods and services that may be of interest to you when you access and use
      the Services and other websites or online services. You may receive
      advertisements based on information relating to your access to and use of
      the Services and other websites or online services on any of your devices,
      as well as on information received from third parties. These companies
      place or recognize a unique cookie on your browser (including through the
      use of pixel tags). For more information about this practice, and to learn
      how to opt out of it in desktop and mobile browsers on the particular
      device on which you are accessing this Privacy Policy, please visit
      http://optout.aboutads.info/#/ and
      http://optout.networkadvertising.org/#/.
    </p>
    <p
      className="MsoNormal"
      style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Who do we share your information with?
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We share your information with the following third parties:
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Our affiliates (including our subsidiaries), for the purposes described in
      this Privacy Policy.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Other users and ticket sellers in order to enable them to verify your
      identity and/or provide you with tickets you have bought through our
      Services (e.g. for the purposes of posting your tickets to you). Buyers
      may see information about the seller in certain cases, e.g. where included
      as a return address on the ticket shipment.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Our business partners, financial institutions, and credit agencies,
      consistent with applicable law.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Partner teams, venues, artists, leagues or other third parties involved in
      the event for which you sell or buy a ticket.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Our third party service providers, to facilitate services they provide to
      us, such as website hosting, data analysis, payment processing, order
      fulfilment, return authorization, fraud prevention, information technology
      and related infrastructure provision, customer service or related benefits
      (including special promotions), email delivery, auditing, and other
      services.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      By using the Services, you may elect to disclose your personal
      information. When you connect your Services account to your social media
      account, you will share information with your friends associated with your
      social media account, with other users, and with your social media account
      provider. By doing so, you authorize us to facilitate this sharing of
      information, and you understand that the use of shared information will be
      governed by the social media provider’s privacy policy.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Other uses and disclosures of information
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We also use and disclose your personal information as necessary or
      appropriate, in particular when we have a legal obligation or legitimate
      interest to do so:
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To comply with applicable law and regulations.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      This may include laws outside your country of residence.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To cooperate with public and government authorities.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To respond to a request or to provide information we believe is necessary
      or appropriate.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      These can include authorities outside your country of residence.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To cooperate with law enforcement.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      For example, when we respond to law enforcement requests and orders or
      provide information we believe is important.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      For other legal reasons.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To enforce our terms and conditions; and
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To protect our rights, privacy, safety or property, and/or that of our
      affiliates, you or others.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      In connection with a sale or business transaction.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We have a legitimate interest in disclosing or transferring your personal
      information to a third party in the event of any reorganization, merger,
      sale, joint venture, assignment, transfer, or other disposition of all or
      any portion of our business, assets, or stock (including in connection
      with any bankruptcy or similar proceedings).
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      How do we transfer your information?
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Your personal information may be stored and processed in any country where
      we have facilities or in which we engage service providers, and by using
      the Services you understand that your information will be transferred to
      countries outside of your country of residence, including the United
      States, which may have data protection rules that are different from those
      of your country. In certain circumstances, courts, law enforcement
      agencies, regulatory agencies or security authorities in those other
      countries may be entitled to access your personal information.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Some countries outside of the European Economic Area (the “EEA”), the UK
      and Switzerland are recognized by the European Commission, the UK
      government or the Federal Data Protection and Information Commissioner or
      the Federal Council (as applicable), as providing an adequate level of
      data protection according to EEA standards (the full list of these
      “specified countries” is available here (EEA) and here (UK); for
      Switzerland the list can be found in Annex 1 of the Ordinance on Data
      Protection). The UK and Switzerland recognize the EEA and the specified
      countries as providing an adequate level of data protection according to
      UK and Swiss standards and vice versa. For transfers from the EEA, the UK
      or Switzerland to countries not considered adequate by the European
      Commission, the Federal Data Protection and Information Commissioner or
      the Federal Council or the UK government (as applicable).&nbsp;&nbsp; For
      transfers to USA we will abide by the “Data Bridge” facility if passign
      data between EU/UK and USA.&nbsp; We have put in place adequate measures
      where necessary, such as the standard contractual clauses adopted by the
      relevant authority to protect your personal information, unless we can
      rely on a legal derogation. You may obtain a copy of these measures by
      contacting us in accordance with the “Contacting Us” section below.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      How long do we keep your information?
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We retain personal information for as long as needed or permitted in light
      of the purpose(s) for which it was obtained and consistent with applicable
      law. The criteria used to determine our retention periods include:
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      The length of time we have an ongoing relationship with you and provide
      the Services to you (for example, for as long as you have an account with
      us or keep using the Services);
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Whether there is a legal obligation to which we are subject (for example,
      certain laws require us to keep records of your transactions for a certain
      period of time before we can delete them); or
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Whether retention is advisable in light of our legal position (such as in
      regard to applicable statutes of limitations, litigation or regulatory
      investigations).
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Sensitive information
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Unless we request it, we ask that you not send us, and you not disclose,
      any sensitive personal information (e.g., social security numbers,
      information related to racial or ethnic origin, political opinions,
      religion or other beliefs, health, biometrics or genetic characteristics,
      criminal background, or trade union membership) on or through the Services
      or otherwise to us.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Security
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We seek to use reasonable organizational, technical and administrative
      measures to protect personal information within our organization.
      Unfortunately, no data transmission or storage system can be guaranteed to
      be 100% secure. If you have reason to believe that your interaction with
      us is no longer secure, please immediately notify us in accordance with
      the “Contacting Us” section below.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Third Party Payment Service
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      The Services may provide functionality allowing you to make payments to us
      using third-party payment services with which you have created your own
      account. When you use such a service to make a payment to us, your
      personal information will be collected by such third party and not by us,
      and will be subject to the third party’s privacy policy, rather than this
      Privacy Policy. We have no control over, and are not responsible for, this
      third party’s collection, use, and disclosure of your personal
      information.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Third Party Services
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      This Privacy Policy does not address, and we are not responsible for, the
      privacy, information, or other practices of any third parties, including
      any third party operating any website or service to which the Services
      link. The inclusion of a link on the Services does not imply endorsement
      of the linked site or service by us or by our affiliates.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      In addition, we are not responsible for the information collection, use,
      disclosure, or security policies or practices of other organizations, such
      as Facebook, Apple, Google, Microsoft, RIM, or any other app developer,
      app provider, social media platform provider, operating system provider,
      wireless service provider, or device manufacturer, including with respect
      to any personal information you disclose to other organizations through or
      in connection with the Apps or our Social Media Pages.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Use of services by minors
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      The Services are not directed to individuals under the age of sixteen
      (16), and we do not knowingly collect personal information from
      individuals under 16.&nbsp;&nbsp; PrimeTec adheres to the Children’s Code
      for Data Protection for any persons under 18.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Your privacy rights and choices
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Applicable data protection laws grant you the right to object to the
      processing of your data in some circumstances, in particular for direct
      marketing purposes, for profiling carried out for direct marketing
      purposes and for other legitimate interests in processing.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If you no longer want to receive marketing-related emails from us on a
      going-forward basis, you may opt-out by selecting the ‘unsubscribe’ option
      in the marketing communications we send or at any time by changing your
      account preferences. Please note that if you opt out of receiving
      marketingrelated emails from us, we may still send you important
      administrative messages, from which you cannot opt out.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If you no longer want to receive SMS/text messages from us on a
      going-forward basis, you may opt out by contacting
      support@primeevents.live.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If you would like to request to access, correct, update, suppress,
      restrict, or delete personal information, object to or opt out of the
      processing of personal information, or if you would like to request to
      receive a copy of your personal information for purposes of transmitting
      it to another company (to the extent these rights are provided to you by
      applicable law), you may contact us at privacyrequest@orders.PrimeTic.com.
      We will respond to your request consistent with applicable law.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If you are a resident of California, Colorado, Connecticut, Utah, or
      Virginia, please see our U.S. Privacy Addendum below for more information
      about how to exercise your privacy rights.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      In your request, please make clear what personal information you would
      like to have changed or whether you would like to have your personal
      information suppressed from our database. For your protection, we may only
      implement requests with respect to the personal information associated
      with the particular email address that you use to send us your request,
      and we may need to verify your identity before implementing your request.
      We will try to comply with your request as soon as reasonably practicable.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Please note that we may need to retain certain information for
      recordkeeping purposes and/or to complete any transactions that you began
      prior to requesting a change or deletion (e.g., when you make a purchase
      or enter a promotion, you may not be able to change or delete the personal
      information provided until after the completion of such purchase or
      promotion).
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If you would like to update personal information that you have provided to
      us, you may do so by updating your registered account with us.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Pursuant to California Business &amp; Professions Code section 22581, if
      you are a resident of California, under 18, and a registered user of the
      Services, you may ask us to remove content or information that you have
      posted to the Services by writing to privacyrequest@orders.PrimeTic.com.
      Please note that your request does not ensure complete or comprehensive
      removal of the content or information, as, for example, some of your
      content may have been reposted by another user.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Updates to this privacy policy
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      The “LAST UPDATED” legend at the top of this Privacy Policy indicates when
      this Privacy Policy was last revised. Any changes will become effective
      when we post the revised Privacy Policy on the Services.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Contacting Us
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If you have any questions about this Privacy Policy or our privacy
      practices, please contact us at&nbsp; support@primeevents.live
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Additional information regarding the EEA
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If you are based in the EEA and not satisfied with our response to your
      complaint or believe our processing of your information does not comply
      with data protection law, you can make a complaint to the Office of the
      Data Protection Commissioner at Canal House, Station Road, Portarlington,
      Co. Laois R32 AP23, Ireland. Tel: +353 (0761) 104 800 or on its website at
      www.dataprotection.ie.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      U.S. PRIVACY ADDENDUM FOR RESIDENTS OF CALIFORNIA, COLORADO, CONNECTICUT,
      UTAH, AND VIRGINIA
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      This U.S. Privacy Addendum supplements the above Privacy Notice and
      provides additional details regarding the categories of Personal
      Information that we collect, use, and disclose about residents of
      California, Colorado, Connecticut, Utah, and Virginia. This U.S. Privacy
      Addendum does not apply to our job applicants, employees, contractors,
      owners, directors, or officers where the Personal Information we collect
      about those individuals relates to their current, former, or potential
      role with us.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      No Sale of Personal Information
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.05pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We do not sell Personal Information, including sensitive personal
      information, in the preceding 12 months. Without limiting the foregoing,
      we do not sell or knowingly “share” for purposes of crosscontext
      behavioral advertising Personal Information, including sensitive personal
      information, of minors under age 16.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Collection, Disclosure, and Sharing of Personal Information
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      The following chart details which categories of Personal Information we
      plan to collect, as well as the categories of Personal Information we have
      collected and disclosed for our operational business purposes in the
      preceding 12 months. The chart also details the categories of personal
      information that we “share” for purposes of cross-context behavioral or
      targeted advertising, including in the preceding 12 months. We collect
      this Personal Information from you and your devices, as well as from our
      corporate affiliates, publicly available databases, other PrimeTic
      customers, credit agencies (as allowed by applicable laws), and social
      media sites.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.6pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "0in",
        marginLeft: "-.75pt",
        textIndent: "0in"
      }}
    >
      Categories of Personal Information
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Disclosed to
      Which Categories of Third Parties for
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Operational Business Purposes Shared with Which Categories of Third
      Parties for Cross-Context Behavioral or Targeted Advertising
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Processing Purposes (see
      section above titled “How do we use your information?” for a detailed
      description of each Processing Purpose)
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "7.3pt",
        marginBottom: ".1pt",
        marginLeft: "-.25pt",
        lineHeight: "166%"
      }}
    >
      Identifiers, such as name, contact information, IP address and other
      online identifiers &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Corporate
      affiliates
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Third-party service providers, who assist us for example with payment
      processing, marketing and advertising, shipping, fraud prevention, rewards
      programs and information technology support;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Business partners, such as partner teams, venues, artists, and sports
      leagues, to provide our event services
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Credit agencies, consistent with applicable law
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Legal authorities
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      PayPal, Inc., to provide our services, facilitate your purchases and
      prevent fraud
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Financial institutions with whom we partner to offer financial products to
      you
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Other users as authorized by you or your use of our services
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "-.75pt", textIndent: "0in" }}
    >
      Ad networks; social media platforms &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Providing the functionality of the Services and fulfilling your requests
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Communicating with you, providing you with our newsletter and/or other
      marketing materials, and facilitating social sharing
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Analyzing personal information for business reporting and providing
      personalized services
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Allowing you to participate in sweepstakes, contests or other promotions
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Aggregating and/or anonymizing personal information
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Accomplishing our business purposes
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Validating your transactions and keeping our Services safe, including
      complying with law and cooperating with legal and governmental authorities
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Personal information, as defined in the California customer records law,
      such as name, governmentissued ID number (e.g., your tax identification
      number when you register for an account with us), and financial
      information such as payment card number&nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Corporate affiliates
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "4.05pt",
        marginLeft: "-.25pt",
        lineHeight: "137%"
      }}
    >
      Third-party service providers, who assist us for example with payment
      processing, marketing and advertising, shipping, fraud prevention, rewards
      programs and information technology support; Credit agencies, consistent
      with applicable law
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Legal authorities
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "53.4pt",
        marginBottom: ".6pt",
        marginLeft: "-.25pt",
        lineHeight: "167%"
      }}
    >
      PayPal, Inc., to provide our services, facilitate your purchases and
      prevent fraud Financial institutions with whom we partner to offer
      financial products to you
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "-.75pt", textIndent: "0in" }}
    >
      None &nbsp;&nbsp;&nbsp; &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Providing the functionality of the Services and fulfilling your requests
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Communicating with you, providing you with our newsletter and/or other
      marketing materials, and facilitating social sharing
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Analyzing personal information for business reporting and providing
      personalized services
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Aggregating and/or anonymizing personal information
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Accomplishing our business purposes
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Validating your transactions and keeping our Services safe, including
      complying with law and cooperating with legal and governmental authorities
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "14.75pt",
        marginBottom: "7.75pt",
        marginLeft: "-.25pt"
      }}
    >
      Characteristics of protected classifications under California or federal
      law, such as age and gender&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Corporate affiliates
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Third-party service providers, who assist us for example with payment
      processing, marketing and advertising, shipping, fraud prevention, rewards
      programs and information technology support;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Business partners, such as partner teams, venues, artists, and sports
      leagues, to provide our event services
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Credit agencies, consistent with applicable law
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "-.75pt", textIndent: "0in" }}
    >
      None &nbsp;&nbsp;&nbsp; &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Providing the functionality of the Services and fulfilling your requests
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Communicating with you, providing you with our newsletter and/or other
      marketing materials, and facilitating social sharing
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Analyzing personal information for business reporting and providing
      personalized services
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Allowing you to participate in sweepstakes, contests or other promotions
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Aggregating and/or anonymizing personal information
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Accomplishing our business purposes
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Validating your transactions and keeping our Services safe, including
      complying with law and cooperating with legal and governmental authorities
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Commercial information, such as transaction information and purchase
      history&nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Corporate affiliates
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Third-party service providers, who assist us for example with payment
      processing, marketing and advertising, shipping, fraud prevention, rewards
      programs and information technology support;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Business partners, such as partner teams, venues, artists, and sports
      leagues, to provide our event services
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Credit agencies, consistent with applicable law
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Legal authorities
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      PayPal, Inc., to provide our services, facilitate your purchases and
      prevent fraud
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Financial institutions with whom we partner to offer financial products to
      you
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Other users as authorized by you or your use of our services
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "-.75pt", textIndent: "0in" }}
    >
      Ad networks; social media platforms &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Providing the functionality of the Services and fulfilling your requests
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Communicating with you, providing you with our newsletter and/or other
      marketing materials, and facilitating social sharing
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Analyzing personal information for business reporting and providing
      personalized services
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Allowing you to participate in sweepstakes, contests or other promotions
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Aggregating and/or anonymizing personal information
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Accomplishing our business purposes
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Validating your transactions and keeping our Services safe, including
      complying with law and cooperating with legal and governmental authorities
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: ".35pt",
        marginLeft: "-.25pt"
      }}
    >
      Internet or network activity information, such as browsing history and
      interactions with our website.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Corporate affiliates
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Third-party service providers, who assist us for example with payment
      processing, marketing and advertising, shipping, fraud prevention, rewards
      programs and information technology support;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Business partners, such as partner teams, venues, artists, and sports
      leagues, to provide our event services
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      PayPal, Inc., to provide our services, facilitate your purchases and
      prevent fraud
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "-.75pt", textIndent: "0in" }}
    >
      Ad networks; social media platforms &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Providing the functionality of the Services and fulfilling your requests
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Communicating with you, providing you with our newsletter and/or other
      marketing materials, and facilitating social sharing
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Analyzing personal information for business reporting and providing
      personalized services
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Allowing you to participate in sweepstakes, contests or other promotions
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Aggregating and/or anonymizing personal information
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Accomplishing our business purposes
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Validating your transactions and keeping our Services safe, including
      complying with law and cooperating with legal and governmental authorities
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "-.75pt", textIndent: "0in" }}
    >
      Geolocation data, such as device location and approximate location derived
      from IP address. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Corporate affiliates
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Third-party service providers, who assist us for example with payment
      processing, marketing and advertising, shipping, fraud prevention, rewards
      programs and information technology support;
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "-.75pt", textIndent: "0in" }}
    >
      Ad networks; social media platforms &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Providing the functionality of the Services and fulfilling your requests
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Communicating with you, providing you with our newsletter and/or other
      marketing materials, and facilitating social sharing
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Analyzing personal information for business reporting and providing
      personalized services
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Aggregating and/or anonymizing personal information
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Accomplishing our business purposes
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Validating your transactions and keeping our Services safe, including
      complying with law and cooperating with legal and governmental authorities
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "7.3pt",
        marginBottom: ".1pt",
        marginLeft: "-.25pt",
        lineHeight: "166%"
      }}
    >
      Audio, electronic, visual, similar information, such as photographs and
      call recordings &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Corporate
      affiliates
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "4.7pt",
        marginLeft: "-.25pt",
        lineHeight: "137%"
      }}
    >
      Third-party service providers, who assist us for example with payment
      processing, marketing and advertising, shipping, fraud prevention, rewards
      programs and information technology support; Other users as authorized by
      you or your use of our services
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "-.75pt", textIndent: "0in" }}
    >
      None &nbsp;&nbsp;&nbsp; &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Providing the functionality of the Services and fulfilling your requests
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Communicating with you, providing you with our newsletter and/or other
      marketing materials, and facilitating social sharing
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Analyzing personal information for business reporting and providing
      personalized services
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Allowing you to participate in sweepstakes, contests or other promotions
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Aggregating and/or anonymizing personal information
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Accomplishing our business purposes
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Validating your transactions and keeping our Services safe, including
      complying with law and cooperating with legal and governmental authorities
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Inferences drawn from any of the Personal Information listed above to
      create a profile about, for example, an individual’s preferences and
      characteristics&nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Corporate affiliates
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Third-party service providers, who assist us for example with payment
      processing, marketing and advertising, shipping, fraud prevention, rewards
      programs and information technology support;
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "-.75pt", textIndent: "0in" }}
    >
      Ad networks; social media platforms &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Providing the functionality of the Services and fulfilling your requests
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Communicating with you, providing you with our newsletter and/or other
      marketing materials, and facilitating social sharing.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Analyzing personal information for business reporting and providing
      personalized services
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Aggregating and/or anonymizing personal information
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Accomplishing our business purposes
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Validating your transactions and keeping our Services safe, including
      complying with law and cooperating with legal and governmental authorities
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Sensitive personal information
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.95pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      Personal information that reveals an individual’s social security,
      driver’s license, state identification card, or passport number; account
      log-in; precise geolocation
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Corporate affiliates
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Third-party service providers, who assist us for example with payment
      processing, marketing and advertising, shipping, fraud prevention, rewards
      programs and information technology support;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      None &nbsp;&nbsp;&nbsp; Subject to your consent where required by
      applicable law, we collect, use, and disclose sensitive personal
      information only for those purposes permitted under applicable law, such
      as for purposes of performing services for our business, providing goods
      or services as requested by you, ensuring security and integrity, short
      term transient use such as displaying first party, nonpersonalized
      advertising, order processing and fulfillment, servicing accounts,
      providing customer service, verifying customer information, processing
      payments, providing financing, and activities relating to quality and
      safety control or product improvement.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Purposes for the Collection, Use, Disclosure or Sharing of Personal
      Information
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We collect, use, disclose, or share Personal Information to operate,
      manage, and maintain our business, to provide our products and services,
      and to accomplish our business purposes and objectives, including, for
      example, to: develop, improve, repair, and maintain our products and
      services; personalize, advertise, and market our products and services;
      conduct research, analytics, and data analysis; maintain our facilities
      and infrastructure; undertake quality and safety assurance measures;
      conduct risk and security control and monitoring; detect and prevent
      fraud; perform identity verification; perform accounting, audit, and other
      internal functions, such as internal investigations; comply with law,
      legal process, and internal policies; maintain records; and exercise and
      defend legal claims. See the chart above for further detail.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Rights and Requests
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Subject to applicable law, you may make the following requests:
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      You may request to know whether we process your personal information, and
      to access such personal information.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If you are a California consumer, you may request that we disclose to you
      the following information
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      The categories of Personal Information we collected about you and the
      categories of sources from which we collected such Personal Information;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      The business or commercial purpose for collecting or sharing personal
      information about you;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      The categories of personal information about you that we shared (as
      defined under the applicable privacy law) and the categories of third
      parties to whom we shared such personal information; and
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      The categories of personal information about you that we otherwise
      disclosed, and the categories of third parties to whom we disclosed such
      personal information.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      You may request to correct inaccuracies in your personal information;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      You may request to have your personal information deleted;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      You may request to receive the specific pieces of your personal
      information, including a copy of your personal information in a portable
      format; and
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.95pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      You may request to opt out of targeted advertising, including the
      “sharing” of your personal information for purposes of cross-context
      behavioral advertising.
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To make a Request to know, access, correct, delete, or receive a copy of
      your personal information, you may contact us via 1.866.788.2482 or
      privacyrequest@orders.PrimeTic.com. Registered PrimeTic users may contact
      us through their account or by visiting our Help Center.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We will verify and respond to your request consistent with applicable law,
      taking into account the type and sensitivity of the Personal Information
      subject to the request. We may need to request additional Personal
      Information from you, such as email address, state of residency, or
      mailing address, in order to verify your identity and protect against
      fraudulent requests. If you maintain a password-protected account with us,
      we may verify your identity through our existing authentication practices
      for your account and require you to re-authenticate yourself before
      disclosing or deleting your Personal Information. If you make a Request to
      Delete, we may ask you to confirm your request before we delete your
      Personal Information.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      You have the right to be free from unlawful discrimination for exercising
      your privacy rights. You have the right to be informed, at or before the
      point of collection, of the categories of personal information to be
      collected from you and the purposes for which that personal information
      will be used.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Appeal Process
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If you are a Colorado, Virginia, or Connecticut consumer, and we refuse to
      take action on your request, you may appeal our refusal within a
      reasonable period after you have received notice of the refusal. You may
      file an appeal by contacting us via privacyrequest@orders.PrimeTic.com.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Authorized Agents
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "8.0pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If an agent would like to make a request on your behalf as permitted under
      applicable law, the agent may use the submission methods noted in the
      section entitled “Rights and Requests.” As part of our verification
      process, we may request that the agent provide, as applicable, proof
      concerning his or her or your status as an authorized agent. In addition,
      we may require that you verify your identity as described in the section
      entitled “Rights and Requests” or confirm with us that you the resident
      provided the agent permission to submit the request.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: "0in",
        marginBottom: "7.9pt",
        marginLeft: "0in",
        textIndent: "0in",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      De-Identified Information
    </p>
    <p
      className="MsoNormal"
      style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Where we maintain or use de-identified information, we will continue to
      maintain and use the deidentified information only in a de-identified
      fashion and will not attempt to re-identify the information.
    </p>
    <p
      className="MsoNormal"
      style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
    >
      &nbsp;
    </p>
  </div>
</>

    </div>
    </GeneralWrapper>
  )
}
