export const core_backend_url =
  `${process.env.REACT_APP_NODE_ENV}` === 'production'
    ? `${process.env.REACT_APP_BACKEND_PRODUCTION_URL}`
    : `${process.env.REACT_APP_NODE_ENV}` === 'staging'
    ? `${process.env.REACT_APP_BACKEND_STAGING_URL}`
    : `${process.env.REACT_APP_BACKEND_LOCAL_URL}`
export const stripe_public_key = `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`
export const google_analytics_measurement_id = `${process.env.REACT_APP_MEASUREMENT_ID}`
export const google_adsense_client_id = `${process.env.REACT_APP_ADSENSE_CLIENT}`

export const appConfig = {
  appEnv: `${process.env.REACT_APP_NODE_ENV ?? 'development'}`,
}

export const crypto_secret_key = `${process.env.REACT_APP_CRYPTO_SECRET_KEY}`
