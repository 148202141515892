import React from 'react'
import GeneralWrapper from '../../../layout/GeneralWrapper'

export default function CookiePolicy() {
  return (
    <GeneralWrapper>
        <div className='px-3 px-lg-100 px-xl-200 mt-3 mb-3'>
        <div className="WordSection1">
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "2.2pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            <b>
                <span style={{ fontSize: "18.0pt", lineHeight: "107%" }}>
                Cookie Notice
                </span>
            </b>
            </p>
            <p className="MsoNormal" style={{ marginBottom: "7.65pt" }}>
            This Cookies &amp; Similar Technologies Policy (<b>“Cookie Policy”</b>)
            describes the different types of cookies and similar technologies that are
            used on the website from which you are accessing this Cookie Policy (the{" "}
            <b>“Website”</b>). We may change this Cookies Policy at any time. Any
            changes in this Cookie Policy will become effective when we make the
            revised Cookie Policy available on or through this Website.
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "7.95pt",
                marginLeft: "-.25pt",
                lineHeight: "107%"
            }}
            >
            <b>What are cookies and similar technologies?</b>
            </p>
            <p className="MsoNormal">
            Cookies are text files containing small amounts of information which are
            downloaded to your device when you visit a website. Cookies are then sent
            back to the originating website on each subsequent visit, or to another
            website that recognises that cookie. Cookies are useful because they allow
            a website to recognise a user’s device and to remember your actions and
            preferences (such as login details, language, font size and other display
            preferences) over a period of time.
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: ".35pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <p className="MsoNormal">
            Cookies store information in your browser or device utilizing local shared
            objects or local storage, such as flash cookies, HTML 5 cookies, and other
            web application software methods. These technologies can operate across
            all of your browsers, and in some instances may not be fully managed by
            your browser and may require management directly through your installed
            applications or device. We do not use these technologies for storing
            information to target advertising to you on or off our Website.
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: ".35pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <p className="MsoNormal" style={{ marginBottom: "7.55pt" }}>
            Web beacons (also known as internet tags, pixel tags and clear GIFs) are a
            similar technology which typically uses transparent graphic images placed
            on a site to gather information about a user. Web beacons are used in
            combination with cookies to measure the actions of visitors on websites.
            We make use of cookies and similar technologies when you visit our
            Website, and refer to the technologies together as “cookies” throughout
            this policy.
            </p>
            <h1 style={{ marginLeft: "-.25pt" }}>First and Third Party Cookies</h1>
            <p className="MsoNormal" style={{ marginBottom: "7.65pt" }}>
            Cookies can be ‘first party’ or ‘third party’, depending on the website or
            domain placing the cookie. On our Website, first party cookies are cookies
            set by us and information is collected and used by us. Third party cookies
            are cookies that are set by a company other than ourselves, and the
            information collected is controlled by that other company. For more
            information on third party cookies used on the Website, see the{" "}
            <i>“Types of cookies used on our Website”</i> section below.
            </p>
            <h1 style={{ marginLeft: "-.25pt" }}>Session and Persistent Cookies</h1>
            <p className="MsoNormal">
            We use cookies that only remain on your device for as long as you keep
            your browser active
            </p>
            <p className="MsoNormal">
            (session cookies) and cookies that remain on your device for a longer
            period (persistent cookies).
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: ".35pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <p className="MsoNormal" style={{ marginBottom: "7.65pt" }}>
            You are free to block, delete, or disable these cookies in general by
            changing the settings on your browser or device, or by changing specific
            settings for our Website, although please be aware that if you do, some of
            the functionality of our Website will be lost.
            </p>
            <h1 style={{ marginLeft: "-.25pt" }}>How we use cookies</h1>
            <p
            className="MsoNormal"
            style={{ margin: "0in", textIndent: "0in", lineHeight: "110%" }}
            >
            We rely on your consent to use first and third party cookies (except
            “strictly necessary cookies”, as described below).
            </p>
            <p className="MsoNormal" style={{ marginBottom: "7.65pt" }}>
            Some of the third party providers mentioned below may be located outside
            Switzerland and the European Eco-nomic Area (EEA) or may process your
            personal data outside this area, in principle in any country in the world.
            These countries may not have laws that protect your personal data to the
            same extent as in Switzerland or the EEA. If we transfer your personal
            data to such a country, we will ensure the protection of your personal
            data in an appropriate manner. For more information on safeguards we
            implement in this regard, please see our Privacy Policy. You accept that
            your data may be transferred to a country that does not have adequate
            level of data protection and accept the risks that your data may
            potentially be subject to government lawful access in the recipient's
            country, despite the safeguards we put in place. You can withdraw your
            consent to cookies at any time, as ex-plained below.
            </p>
            <p className="MsoNormal" style={{ marginBottom: "7.65pt" }}>
            You can find more information about cookies, including how to see what
            cookies have been set on your device and how to manage and delete them, at
            www.aboutcookies.org and www.youronlinechoices.eu
            </p>
            <h1 style={{ marginLeft: "-.25pt" }}>
            Types of cookies used on our Website
            </h1>
            <p className="MsoNormal">
            There are different types of cookies that serve different purposes. We’ve
            set out below the categories of cookies we use on our Website. To the
            extent any personal information is collected through cookies, our Privacy
            Policy applies and complements this Cookie Policy. Please visit{" "}
            <a href="https://www.viagogo.co.uk/help/cookies-list" />
            <a href="https://www.viagogo.co.uk/help/cookies-list">
                <b>
                <span style={{ color: "#1EB0EE" }}>here</span>
                </b>
            </a>{" "}
            <a href="https://www.viagogo.co.uk/help/cookies-list" />
            for more information about the individual cookies we use, the purposes for
            which we use them and the duration of the cookies.
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: ".35pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <p className="MsoNormal">
            <b>Strictly necessary cookies.</b> These are cookies that are required for
            the operation of our
            </p>
            <p className="MsoNormal">
            Website. They include, for example, cookies that enable you to log into
            secure areas of our Website, add items to your basket or to make payments
            via our Website. We do not require your consent in order to place these
            cookies since they provide essential Website functionality and without
            them, you would be unable to use our Website and services.
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: ".35pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <p className="MsoNormal">
            <b>Preference cookies.</b> These are used to collect information about you
            and your choices to allow us to recognize you when you return to our
            Website. This enables us to enhance and personalise our content for you,
            greet you by name and remember your preferences (for example, your choice
            of language or region and your log-in information).
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: ".35pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <p className="MsoNormal">
            <b>Analytical/performance cookies.</b> These cookies collect information
            about your use of our Website. For example, analytical/performance cookies
            allow us to recognise and count the number of visitors and show us how
            visitors use and interact with our Website, the items that have been
            clicked on and the individual pages that have been viewed. This helps us
            to improve the way our Website works, for example, by ensuring that users
            are finding what they are looking for easily. Any information collected is
            only used by us for purposes related to improving our Website. When you
            first access our Website, we ask for your consent to place
            analytical/performance cookies on your device.
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: ".35pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <p className="MsoNormal">
            <b>Advertising cookies.</b> These cookies are set to display targeted
            promotions or advertisements based upon your interest on our Website or to
            manage our advertising. These cookies collect information about your
            activities on our Website and other websites to provide you with targeted
            advertising.
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: ".35pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <p className="MsoNormal">
            <b>Pixel tags.</b> Pixel tags (also known as web beacons and clear GIFs)
            are used to, among other things, track the actions of users of the
            Website, measure the success of our marketing campaigns, and compile
            statistics about usage of the Website and response rates.
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: ".5pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <p className="MsoNormal">
            <b>Browser or Device Information.</b> Certain information is collected by
            most browsers or automatically through your device, such as your Media
            Access Control (MAC) address, computer type (Windows or Mac), screen
            resolution, operating system name and version, device manufacturer and
            model, language, Internet browser type and version, and the name and
            version of the Website you are using. We use this information to ensure
            that the Website functions properly.
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: ".35pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <p className="MsoNormal">
            <b>Physical Location.</b> We collect the physical location of your device
            by, for example, using satellite, cell phone tower, or WiFi signals. We
            use your device’s physical location to provide you with personalized
            location-based services and content. We also share your device’s physical
            location, combined with information about what advertisements you viewed
            and other information we collect, with our marketing partners to enable
            them to provide you with more personalized content and to study the
            effectiveness of advertising campaigns. In some instances, you can allow
            or deny such uses and sharing of your device’s location, but if you do, we
            and our marketing partners may not be able to provide you with the
            applicable personalized services and content.
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: ".35pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "7.9pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <p className="MsoNormal" style={{ marginBottom: "15.1pt" }}>
            <b>Device Fingerprinting.</b> In order to be able to offer you content
            that we believe may interest you based on your activity on the Website,
            and for fraud prevention and authentication purposes, we may also use
            other technologies that are similar to cookies. For example, we may gather
            device attributes and combine information collected by most browsers or
            automatically through your device to create a unique ID that identifies
            your browser or device to us with reasonable certainty. In this context we
            may collect, use, and combine the following information:
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Number of visits
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Last visit
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            First visit
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            External IP address
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Internal IP address
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Screen resolution
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Geolocation (latitude/longitude based on IP address)
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Device performance benchmark
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Device bandwidth benchmark
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Device user-agent (browser type, version)
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Device capabilities (Modernizer string for browser capabilities)
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Device operating system
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Device plugins installed
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Browser language
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Flash enabled or disabled
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Device time on file
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Keywords used if coming from search engine
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Pages visited
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Length of visit
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Referral page
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Mouse cursor position
            </p>
            <p
            className="MsoNormal"
            style={{ marginLeft: "35.25pt", textIndent: "-6.7pt" }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Mouse clicks
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "13.95pt",
                marginLeft: "35.25pt",
                textIndent: "-6.7pt"
            }}
            >
            <span
                style={{
                fontSize: "10.0pt",
                lineHeight: "110%",
                fontFamily: '"Arial",sans-serif'
                }}
            >
                •<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>{" "}
            Page scroll position
            </p>
            <h1 style={{ marginLeft: "-.25pt" }}>How to control and delete cookies</h1>
            <p className="MsoNormal" style={{ marginBottom: "7.65pt" }}>
            You can change your web browser’s settings to refuse or accept cookies. In
            order to do this, follow the instructions provided by your browser
            (usually located within the “Help”, “Tools” or “Edit” facility).
            Alternatively, you can visit <a href="http://www.aboutcookies.org/" />
            <a href="http://www.aboutcookies.org/">
                <b>
                <span style={{ color: "#1EB0EE" }}>www.aboutcookies.org</span>
                </b>
            </a>{" "}
            <a href="http://www.aboutcookies.org/" />
            for further information on how to change cookie settings across various
            browsers (e.g. Mozilla Firefox, Google Chrome, Internet Explorer and
            Safari). However, please be aware that, if you refuse or disable cookies,
            some of the Website’s functionality may be lost.
            </p>
            <h1 style={{ marginLeft: "-.25pt" }}>Contact Information</h1>
            <p className="MsoNormal" style={{ lineHeight: "169%" }}>
            If you have any queries in relation to this notice or our use of cookies,
            please contact us at&nbsp;{" "}
            <b>
                <u>
                <span style={{ color: "#0563C1" }}>support@primeevents.live</span>
                </u>
            </b>
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "7.9pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            <b>&nbsp;</b>
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "10.7pt",
                marginLeft: "0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            <b>&nbsp;</b>
            </p>
            <h1 style={{ margin: "0in", textIndent: "0in" }}>
            <span style={{ fontSize: "14.0pt", lineHeight: "107%", color: "black" }}>
                List of cookies
            </span>
            </h1>
            <table
            className="TableGrid"
            border={0}
            cellSpacing={0}
            cellPadding={0}
            width={583}
            style={{
                width: "437.4pt",
                marginLeft: ".45pt",
                borderCollapse: "collapse"
            }}
            >
            <tbody>
                <tr style={{ height: "22.8pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.8pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Name
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.8pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Type
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.8pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Lifetime
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.8pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Purpose
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.45pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        bm_sz
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        4 hours
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party cookies set by Akamai
                        https://www.akamai.com/legal/privacy-andpolicies/manage-cookie-preferences
                        - Used for bot management and fraud prevention.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "65.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "65.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        ak_bmsc
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "65.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "65.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        2 Hours
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "65.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party cookies set by Akamai
                        https://www.akamai.com/legal/privacy-andpolicies/manage-cookie-preferences
                        - used by Akamai to optimize site security by distinguishing
                        between humans and bots
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "129.25pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "129.25pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        bm_sv
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "129.25pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "129.25pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        2 Hours
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "129.25pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party cookies set by Akamai
                        https://www.akamai.com/legal/privacy-andpolicies/manage-cookie-preferences
                        - This cookie is necessary for the Akamai cache function. A
                        cache is used by the website to optimize the response time
                        between the visitor and the website. The cache is usually stored
                        on the visitor’s browser. The users' bandwidth results are
                        stored within this cookie to help ensure the bandwidth test
                        isn't repeated for the same user repeatedly for the Akamai cache
                        functionality
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "65.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "65.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        forterToken
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "65.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "65.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        5 Years
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "65.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Forter -
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        https://www.forter.com/cookie-policy/ - This cookie helps
                        prevent identity and internet fraud by identifying the browser
                        between browsing sessions.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "33.5pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "33.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        ftr_ncd
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "33.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "33.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        5 Years
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "33.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Forter -
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        margin: "0in",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        https://www.forter.com/cookie-policy/ - the flag
                    </span>
                    </p>
                </td>
                </tr>
            </tbody>
            </table>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "13.35pt",
                marginBottom: "0in",
                marginLeft: "-1.0in",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <table
            className="TableGrid"
            border={0}
            cellSpacing={0}
            cellPadding={0}
            width={583}
            style={{
                width: "437.4pt",
                marginLeft: ".45pt",
                borderCollapse: "collapse"
            }}
            >
            <tbody>
                <tr style={{ height: "22.85pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Name
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Type
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Lifetime
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Purpose
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    &nbsp;
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    &nbsp;
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    &nbsp;
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        used to make sure data is not sent more than once per predefined
                        timeframe.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        lastRskxRun
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        2 Years
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party cookie set by Riskified -
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        margin: "0in",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        https://www.riskified.com/terms/ - Used for the prevention of
                        online payment fraud
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        rCookie
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        2 Years
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party cookie set by Riskified -
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        margin: "0in",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        https://www.riskified.com/terms/ - Used for the prevention of
                        online payment fraud
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        rskxRunCookie
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        2 Years
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party cookie set by Riskified -
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        margin: "0in",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        https://www.riskified.com/terms/ - Used for the prevention of
                        online payment fraud
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.45pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        akacd_rls
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party cookies set by Akamai
                        https://www.akamai.com/legal/privacy-andpolicies/manage-cookie-preferences
                        - This cookie is necessary for the Akamai release
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        u, wsu.2
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        2 Days
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        User ID - used for user authentication
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        abck
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Year
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Used to detect and defend against replay-cookieattacks - The
                        cookie is necessary for the security and integrity of the
                        website.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _rvt
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Request Verifiation Token (used for the antiforgery proctection)
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.35pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Al_buffer
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Used in context with the "Al_sentBuffer" in order to limit the
                        number of data-server-updates (Azure). This synergy also allows
                        the website to detect any duplicate data-server-updates.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        s
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Unique Session Identifier – used to persist state and provide a
                        connected experience to user for the durration of their browsing
                        session.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        ai_session
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        24 Hours
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Microsoft - Preserves users states
                        across page requests.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.45pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        ak_bmsc
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        24 Hours
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: ".15pt",
                        marginBottom: "0in",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        This cookie is used to distinguish between humans and bots. This
                        is beneficial for the website, in order to make valid reports on
                        the use of their website.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.45pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        akacd_#
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Used in connection with phased release which allows the website
                        owner to assign a certain number of users to a specific version
                        of the website.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        bm_sv
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        24 Hours
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Used in context with the website's BotManager. The BotManager
                        detects, categorizes and compiles reports on potential bots
                        trying to access the website.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "33.5pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "33.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        bm_sz
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "33.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "33.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        24 Hours
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "33.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Used in context with the website's BotManager.
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        The BotManager detects, categorizes and
                    </span>
                    </p>
                </td>
                </tr>
            </tbody>
            </table>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "13.35pt",
                marginBottom: "0in",
                marginLeft: "-1.0in",
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <table
            className="TableGrid"
            border={0}
            cellSpacing={0}
            cellPadding={0}
            width={583}
            style={{
                width: "437.4pt",
                marginLeft: ".45pt",
                borderCollapse: "collapse"
            }}
            >
            <tbody>
                <tr style={{ height: "22.85pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Name
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Type
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Lifetime
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Purpose
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    &nbsp;
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    &nbsp;
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    &nbsp;
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        compiles reports on potential bots trying to access the website.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        CookieConsent
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Year
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Stores the user's cookie consent state for the current domain
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.45pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        fvc
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Force View Cookie - used to validate cookies are working and
                        allow us to notify users that cookies are required for the
                        proper functioning of the site.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "87.05pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "87.05pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        img/image-1.gif
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "87.05pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "87.05pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "87.05pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by riskified - provides the website owner
                        with user reports based on the transaction process on the
                        website. These reports serve to detect if someone is trying to
                        attack the website integrity, but can also be used to provide
                        insights into why and when users drop out of the potential
                        purchase.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.45pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Al_sentBuffer
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: ".15pt",
                        marginBottom: "0in",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Used in context with the "Al_buffer" in order to limit the
                        number of data-server-updates (Azure). This synergy also allows
                        the website to detect any duplicate data-server-updates.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.45pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _abck
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Strictly
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Necessary
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Year
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party cookies set by Akamai
                        https://www.akamai.com/legal/privacy-andpolicies/manage-cookie-preferences
                        - Used for bot management and fraud prevention.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "65.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "65.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        test_cookie
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "65.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "65.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        15
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Minutes
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "65.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-party cookie set by Doubleclick.Net -
                        https://business.safety.google/adscookies/
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        AdSense, Campaign Manager, Google Ad
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Manager, Google Analytics, Display &amp; Video 360, Search Ads
                        360
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.45pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _CLIENT_PARTICIPATION_storedClientParticipationsHashed,
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _FEATURE_STORE_featureStore,
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _FEATURE_STORE_seenFeatureStore,
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        __PAGE_EVENT_storedPageEventsBatched
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Persistent
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Used to store details of feature variations that user has been
                        exposed to
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        maps/gen_204
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Google - used to enable maps API
                        functionality
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        d, x
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        2 Years
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: ".1pt",
                        marginBottom: "0in",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Anonymous ID – Unique identifier assigned to the user on first
                        visit from a given device. “d” used on website, “x” used in
                        seller tools
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "23.75pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        lv
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Year
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Last Visit Cookie
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "23.75pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        emoin
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Keep Me Updated feature support
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        es
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        For pre-registration pipeline data collection used to
                        prepopulate the forms.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        fv
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Year
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        First View Cookie - Lets the site know you are a brand new user
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "33.5pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "33.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        gdpr_acknowledgement
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "33.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "33.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Year
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .15pt 0in .7pt",
                    height: "33.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        When users acknowledge reading the GDPR terms
                    </span>
                    </p>
                </td>
                </tr>
            </tbody>
            </table>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "13.35pt",
                marginBottom: "0in",
                marginLeft: "-1.0in",
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <table
            className="TableGrid"
            border={0}
            cellSpacing={0}
            cellPadding={0}
            width={583}
            style={{
                width: "437.4pt",
                marginLeft: ".45pt",
                borderCollapse: "collapse"
            }}
            >
            <tbody>
                <tr style={{ height: "22.85pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Name
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Type
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Lifetime
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Purpose
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        largepricedropallowed
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Hour
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Allows sellers to discount prices heavily without warning
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        puidx
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Hour
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Identifies the listing to be promoted for Seller
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Power Up diagnostic test (Experimental)
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "23.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        rmt
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Request more time cookie (Experimental)
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "23.75pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        so, wsso
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        2 Years
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Site Options Cookie
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "23.75pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Soc
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session Options Cookie
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.35pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        twq, twqed, twqel, twqdel, twqbyb, twqr
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Identify users in various waiting queues on the site
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        sat
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Hour
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Access token cookie for users who have linked their Spotify
                        accounts (Experimental)
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "23.75pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        utp
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Year
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        User Ticket Preferences Cookie
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "23.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        viagogoCouponCookie
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Enables discounts to be applied to orders
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        z, wsp
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        2 Years
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        User Profile – Language, Region, Currency preferences
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "23.75pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        uav
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Preference
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Hour
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Used when Buy Pipeline is unavailable
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.35pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        feh--*, ftr_sf
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Persistent
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by forter - used to assist with fraud
                        detection on the website
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        ftr_blst_1h
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        24 Hours
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by forter - used to assist with fraud
                        detection on the website
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        logo_large.gif, logo_medium.gif, logo_small.gif
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        24 Hours
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Used to assist with fraud detection on the website
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        td
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Registers statistical data on users' behaviour on the website.
                        Used for internal analytics by the website operator.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.45pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        collect
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Google - used to send data to Google
                        Analytics about the visitor's device and behavior. Tracks the
                        visitor across devices and marketing channels.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _gid
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        24 Hours
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Google -
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        margin: "0in",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        https://policies.google.com/technologies/cookies
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        - Used by Google Analytics
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _gat
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "106%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "106%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Google -
                        https://policies.google.com/technologies/cookies
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        - Used by Google Analytics
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        pagead/1p*
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Google - used to conversion tracking
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "25.35pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "25.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        ai_user
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "25.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "25.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Year
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt .45pt 0in .7pt",
                    height: "25.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Microsoft Application
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Insights software - Used to collect statistical
                    </span>
                    </p>
                </td>
                </tr>
            </tbody>
            </table>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "13.35pt",
                marginBottom: "0in",
                marginLeft: "-1.0in",
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "0in",
                lineHeight: "107%"
            }}
            >
            &nbsp;
            </p>
            <table
            className="TableGrid"
            border={0}
            cellSpacing={0}
            cellPadding={0}
            width={583}
            style={{
                width: "437.4pt",
                marginLeft: ".45pt",
                borderCollapse: "collapse"
            }}
            >
            <tbody>
                <tr style={{ height: "22.85pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Name
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Type
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Lifetime
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "solid #9FA0A1 1.0pt",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Purpose
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "66.0pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "66.0pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    &nbsp;
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "66.0pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    &nbsp;
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "66.0pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    &nbsp;
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "66.0pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: ".4pt",
                        marginBottom: "0in",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        usage for components of the site built on the Azure cloud
                        platform. This is a unique user identifier cookie enabling
                        counting of the number of users accessing the application over
                        time.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "23.75pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        PCID
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Month
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Tracking of users coming from paid search
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _ga
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        2 Years
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Google -
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        margin: "0in",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        https://policies.google.com/technologies/cookies
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        - Used by Google Analytics
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "23.75pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Sel
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "23.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Indicates user has used sell tickets features
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.35pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        vTrack
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Analytical
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Month
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Affiliate ID tracking of users coming from paid search
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _gac_UA-*
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Advertising
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Persistent
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Google -
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        margin: "0in",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        https://policies.google.com/technologies/cookies
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        - Google Analytics and Advertising
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _gcl_au
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Advertising
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Persistent
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Google -
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{
                        margin: "0in",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        https://policies.google.com/technologies/cookies
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        - Google Analytics and Advertising
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.45pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        MUID
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Advertising
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Year
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Microsoft - used widely by Microsoft
                        as a unique user ID. The cookie enables user tracking by
                        synchronising the ID across man y Microsoft domains.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.35pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _uetsid
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Advertising
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Persistent
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.35pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Microsoft - used to track visitors on
                        multiple websites, in order to present relevant advertisement
                        based on the visitor's preferences.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "66.0pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "66.0pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        ads/ga-audiences
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "66.0pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Advertising
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "66.0pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Session
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "66.0pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Google - Used to detect whether the
                        user intends to leave the page via cursor movements. This allows
                        the website to trigger certain pop-ups to keep the user on the
                        website or convert them to customer.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.9pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _uetvid_exp
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Advertising
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Persistent
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.9pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Microsoft - contains the expiry-date
                        for the cookie with corresponding name
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "55.45pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _uetvid
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Advertising
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Year
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "55.45pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Microsoft - used to track visitors on
                        multiple websites, in order to present relevant advertisement
                        based on the visitor's preferences.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "44.75pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _uetsid_exp
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Advertising
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Persistent
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "44.75pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Microsoft - contains the expiry-date
                        for the cookie with corresponding name.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "46.55pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "46.55pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _uetsid
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "46.55pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Advertising
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "46.55pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        24 Hours
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "46.55pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: ".6pt",
                        marginBottom: "0in",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Microsoft - collects data on visitor
                        behaviour from multiple websites, in order to present more
                        relevant advertisement
                    </span>
                    </p>
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        - This al so allows the website to limit the
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "22.85pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Name
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Type
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Lifetime
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "22.85pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Purpose
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "34.3pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    &nbsp;
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    &nbsp;
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    &nbsp;
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "34.3pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        number of times that they are shown the same advertisement.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "76.55pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "76.55pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        IDE
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "76.55pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Advertising
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "76.55pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        1 Year
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "double #F9F9F9 6.75pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "76.55pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Party Cookie set by Google - used by Google DoubleClick to
                        register and report the website user's actions after viewing or
                        clicking one of the advertiser's ads with the purpose of
                        measuring the efficacy of an ad and to present targeted ads to
                        the user.
                    </span>
                    </p>
                </td>
                </tr>
                <tr style={{ height: "54.5pt" }}>
                <td
                    width={263}
                    valign="top"
                    style={{
                    width: "197.55pt",
                    border: "solid #9FA0A1 1.0pt",
                    borderTop: "none",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "54.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.45pt",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        _fbp
                    </span>
                    </p>
                </td>
                <td
                    width={55}
                    valign="top"
                    style={{
                    width: "41.1pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "54.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.55pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Advertising
                    </span>
                    </p>
                </td>
                <td
                    width={49}
                    valign="top"
                    style={{
                    width: "36.55pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "54.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "1.5pt",
                        textAlign: "justify",
                        textJustify: "inter-ideograph",
                        textIndent: "0in",
                        lineHeight: "107%"
                    }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        3 Months
                    </span>
                    </p>
                </td>
                <td
                    width={216}
                    valign="top"
                    style={{
                    width: "162.15pt",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid #9FA0A1 1.0pt",
                    borderRight: "solid #9FA0A1 1.0pt",
                    background: "#F9F9F9",
                    padding: "3.2pt 0in 0in .7pt",
                    height: "54.5pt"
                    }}
                >
                    <p
                    className="MsoNormal"
                    style={{ margin: "0in", textIndent: "0in", lineHeight: "107%" }}
                    >
                    <span
                        style={{
                        fontSize: "8.0pt",
                        lineHeight: "107%",
                        color: "black"
                        }}
                    >
                        Third-Part Pixel set by Facebook -
                        https://www.facebook.com/policies/cookies/ - identifies browsers
                        for the purposes of providing Facebook related advertising and
                        site analytics
                    </span>
                    </p>
                </td>
                </tr>
            </tbody>
            </table>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "448.0pt",
                marginBottom: ".1pt",
                marginLeft: "0in",
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "0in",
                lineHeight: "154%"
            }}
            >
            <span style={{ fontSize: "14.0pt", lineHeight: "154%", color: "black" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "448.0pt",
                marginBottom: ".1pt",
                marginLeft: "0in",
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "0in",
                lineHeight: "154%"
            }}
            >
            <span style={{ fontSize: "14.0pt", lineHeight: "154%", color: "black" }}>
                &nbsp;
            </span>
            </p>
            <p
            className="MsoNormal"
            style={{
                marginTop: "0in",
                marginRight: "448.0pt",
                marginBottom: "0in",
                marginLeft: "0in",
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "0in",
                lineHeight: "154%"
            }}
            >
            <span style={{ fontSize: "14.0pt", lineHeight: "154%", color: "black" }}>
                &nbsp;
            </span>
            </p>
        </div>
    </div>
    </GeneralWrapper>
  )
}
