import React from 'react'

export default function AddFormLayout({children, heading_title, sub_text, onClick}) {
  return (
    <div className="card py-4 mb-4">
        <div className="px-4 px-md-7 mb-3">
            <div className='d-flex justify-content-between align-items-center'>
                <h6 className="card-title fw-bold text-uppercase">{heading_title}</h6>
                <button 
                    type="button" 
                    className="btn btn-sm" 
                    onClick={onClick}
                >
                <i style={{fontSize: '1rem'}} className="mdi mdi-close"></i>
                </button>
            </div>
            <p >{sub_text}</p>
        </div>
        {children}
    </div>    
  )
}
