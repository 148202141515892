import React from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setAdminErrorMessageUser, setAdminLoadingUser, showCountryModalFunc, showEventModalFunc } from '../../../../config/redux/slice/adminSlice'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'

export default function DeleteEvent({ eventObj, getActiveList }) {
  const dispatch = useDispatch()

  const {
    adminLoading,
    adminErrorMsg,
    adminSuccessMsg
  } = useSelector((state) => state.admin)

  const {
    token
  } = useSelector((state) => state.authData)

  const onSubmit = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      "delete",
      token,
      `${app_api.delete_event(eventObj?.id)}`,
      {},
      dispatch,
      setAdminLoadingUser,
      "",
      setAdminErrorMessageUser
    )
    if (res !== undefined && res.status < 400) {
      getActiveList();
      dispatch(onUpdate({ message: res.data.message, type: 'event' }));
    }
  }

  const closeModal = () => {
    dispatch(showEventModalFunc({ modal: false, type: null }))
  }

  return (
    <ModalLayout onClick={closeModal} heading={'Delete Event'}>
      <div className="modal-body">
        <p>Are you sure you want to delete {eventObj?.name}?</p>
      </div>
      <div className="modal-footer border-top-0 d-flex align-items-center">
        <div>
          {adminLoading ?
            <Spinner isForm={true} /> :
            <button onClick={onSubmit} type="button" className="btn btn-primary me-3">Submit Form</button>
          }
        </div>
        <button disabled={adminLoading ? true : false} onClick={closeModal} type="button" className="btn btn-info">Cancel</button>
      </div>
    </ModalLayout>
  )
}
