import Dashboard from '../../app/pages/customer/dashboard/Dashboard'
import PageLayout from '../../app/pages/customer/buy_tickets/PageLayout'
import SuccessPage from '../../app/pages/customer/buy_tickets/SuccessPage'
import { SuccessPage as SuccessPageConnectedAccount } from '../../app/pages/customer/sell_events/SuccessPage'
import ListingDetails from '../../app/pages/customer/listing_management/ListingDetails'
import ListingTable from '../../app/pages/customer/listing_management/ListingTable'
import SellTicketPage from '../../app/pages/customer/sell_events/SellTicketPage'
import TicketUpload from '../../app/pages/customer/sell_events/TicketUpload'
import TicketTable from '../../app/pages/customer/ticket_management/TicketTable'
import { app_urls } from '../../utils/urls/app_urls'
import Profile from '../../app/pages/customer/profile/Profile'
import OrderTable from '../../app/pages/customer/order_management/OrderTable'
import SingleOrderTable from '../../app/pages/customer/order_management/SingleOrder'
import SalesTable from '../../app/pages/customer/sales_management/SalesTable'
import SingleSaleTable from '../../app/pages/customer/sales_management/SingleSale'
import SalesDetails from '../../app/pages/customer/sales_management/SalesDetails'
import ConnectedAccountError from '../../app/pages/customer/sell_events/ConnectedAccountError'
import PayoutTable from '../../app/pages/customer/payouts_management/PayoutTable'
import ListingExpandTable from '../../app/pages/customer/listing_management/ListingExpandTable'
import SingleEventSaleTable from '../../app/pages/customer/sales_management/SingleEventSaleTable'

export const user_routes = [
  {
    url: app_urls.dashboard,
    component: <Dashboard />,
    routeType: 'private',
  },
  {
    url: app_urls.orders,
    component: <OrderTable />,
    routeType: 'private',
  },
  {
    url: app_urls.sales,
    component: <SalesTable />,
    routeType: 'private',
  },
  {
    url: app_urls.user_payout,
    component: <PayoutTable />,
    routeType: 'private',
  },
  {
    url: app_urls.sell_ticket,
    component: <SellTicketPage />,
    routeType: 'private',
  },
  {
    url: app_urls.upload_ticket,
    component: <TicketUpload />,
    routeType: 'private',
  },
  {
    url: app_urls.user_listings,
    component: <ListingTable />,
    routeType: 'private',
  },
  {
    url: app_urls.user_listings_details,
    component: <ListingDetails />,
    routeType: 'private',
  },
  /////////////
  {
    url: app_urls.listing_event_dyn,
    component: <ListingExpandTable />,
    routeType: 'private',
  },
  ////////////
  {
    url: app_urls.user_tickets,
    component: <TicketTable />,
    routeType: 'private',
  },
  {
    url: app_urls.buy_ticket,
    component: <PageLayout />,
    routeType: 'private',
  },
  {
    url: app_urls.buy_ticket_tab,
    component: <PageLayout />,
    routeType: 'private',
  },
  {
    url: app_urls.payment_successful,
    component: <SuccessPage />,
    routeType: 'private',
  },
  {
    url: app_urls.profile,
    component: <Profile />,
    routeType: 'private',
  },
  {
    url: app_urls.order_items,
    component: <SingleOrderTable />,
    routeType: 'private',
  },
  {
    url: app_urls.event_order_items,
    component: <SingleEventSaleTable />,
    routeType: 'private',
  },
  {
    url: app_urls.sale_items,
    component: <SingleSaleTable />,
    routeType: 'private',
  },
  {
    url: app_urls.user_sales_details,
    component: <SalesDetails />,
    routeType: 'private',
  },
  {
    url: app_urls.connectedAcc,
    component: <ConnectedAccountError />,
    routeType: 'private',
  },
  {
    url: app_urls.connected_account_successful,
    component: <SuccessPageConnectedAccount />,
    routeType: 'private',
  },
]
