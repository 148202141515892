// import './App.css';
import 'react-toastify/dist/ReactToastify.css'
import './assets/css/main.min.css'
import './assets/css/custom.css'
import 'bootstrap/dist/js/bootstrap.js'
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { strText, system_user_roles } from './constants/english'
import { app_urls } from './utils/urls/app_urls'
import { onboard_routes } from './config/routes/onboard_routes'
import { user_routes } from './config/routes/user_routes'
import { admin_routes } from './config/routes/admin_routes'
import { guest_routes } from './config/routes/guest_routes'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { google_analytics_measurement_id } from './config'
import AdSenseComponent from './app/components/AdSenseComponent'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPage from './app/layout/ErrorPage'
import TagManager from 'react-gtm-module'

ReactGA.initialize(google_analytics_measurement_id)
const tagManagerArgs = {
  gtmId: 'GTM-W3ZB8LRT',
}
TagManager.initialize(tagManagerArgs)

function App() {
  const location = useLocation()
  const {
    cookieManagement: {
      acceptAllCookies,
      acceptMarketingCookies,
      acceptAnalyticsCookies,
    },
  } = useSelector((state) => state.app)

  useEffect(() => {
    if (acceptAllCookies || acceptAnalyticsCookies) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
      })
    }
  }, [acceptAllCookies, acceptAnalyticsCookies, location])

  return (
    // <Routes>
    //   {app_routes.map((appRoute, j = 0) => {
    // 				return <Route key={j} path={appRoute.url} element={appRoute.component} />
    // 			})}
    // </Routes>
    <>
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onReset={() => {
          window.location.reload()
        }}
      >
        <Routes>
          {guest_routes.map((appRoute, j = 0) => {
            return (
              <Route
                key={j}
                exact
                path={appRoute.url}
                element={appRoute.component}
              />
            )
          })}
          <Route element={<PublicRoute />}>
            {onboard_routes.map((appRoute, j = 0) => {
              return (
                <Route
                  key={j}
                  exact
                  path={appRoute.url}
                  element={appRoute.component}
                />
              )
            })}
          </Route>
          <Route exact path="" element={<PrivateRoute />}>
            <Route exact path="" element={<CustomerRoute />}>
              {user_routes.map((appRoute, j = 0) => {
                return (
                  <Route
                    key={j}
                    exact
                    path={appRoute.url}
                    element={appRoute.component}
                  />
                )
              })}
            </Route>
          </Route>
          <Route exact path="" element={<PrivateRoute />}>
            <Route exact path="" element={<SystemUserRoute />}>
              {admin_routes.map((appRoute, j = 0) => {
                return (
                  <Route
                    key={j}
                    exact
                    path={appRoute.url}
                    element={appRoute.component}
                  />
                )
              })}
            </Route>
          </Route>
          <Route path="*" element={<ErrorPage status="404" />} />
        </Routes>
      </ErrorBoundary>
      {(acceptAllCookies || acceptMarketingCookies) && <AdSenseComponent />}
    </>
  )
}

function PrivateRoute() {
  const isAuth = localStorage.getItem(`${strText.user_unique_id}`)
    ? true
    : false

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isAuth ? <Outlet /> : <Navigate to={app_urls.login} />
}

function PublicRoute() {
  const {
    userData: { user_role },
  } = useSelector((state) => state.authData)
  const isAuth = localStorage.getItem(`${strText.user_unique_id}`)
    ? true
    : false
  return !isAuth || user_role === '' ? (
    <Outlet />
  ) : user_role.toLowerCase() === 'user' ? (
    <Navigate to={app_urls.dashboard} />
  ) : (
    <Navigate to={app_urls.admin_dashboard} />
  )
}

function SystemUserRoute() {
  const {
    userData: { user_role },
  } = useSelector((state) => state.authData)
  const isSystemUser = system_user_roles.includes(user_role.toLowerCase())
  return isSystemUser ? (
    <Outlet />
  ) : user_role.toLowerCase() === 'user' ? (
    <Navigate to={app_urls.dashboard} />
  ) : (
    <Navigate to={app_urls.home} />
  )
}

function CustomerRoute() {
  const {
    userData: { user_role },
  } = useSelector((state) => state.authData)

  const isCustomer = user_role.toLowerCase() === 'user'
  return isCustomer ? (
    <Outlet />
  ) : system_user_roles.includes(user_role.toLowerCase()) ? (
    <Navigate to={app_urls.admin_dashboard} />
  ) : (
    <Navigate to={app_urls.home} />
  )
}

export default App
