import React, { useEffect } from 'react'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import trending_img1 from '../../../../assets/images/trending_img1.png'
import { stadiumAdminDatatable } from '../../../../helpers/dataTables'
import { useDispatch, useSelector } from 'react-redux'
import { clearResetBot, setAdminErrorMessageUser, setDetails, showAddVenueFormFunc, showVenueModalFunc } from '../../../../config/redux/slice/adminSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import { navigationStructure } from '../../../../utils/nav_structure'
import { setBreadCrumbName } from '../../../../config/redux/slice/appSlice'

export default function StadiumTable() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        token
    } = useSelector((state) => state.authData)

    const {breadCrumbName, currentNavDetails} = useSelector((state) => state.app)

    const {
      tableFilterQuery,
      venueManagement:{newData, showAddVenueForm}
    } = useSelector((state) => state.admin)

    useEffect(() => {
      let getVenuesEndpoint =  tableFilterQuery ? `${app_api.get_venues}?${tableFilterQuery}` : `${app_api.get_venues}`
      console.log(tableFilterQuery)
        stadiumAdminDatatable(
          errorFunc,
        'stadiumAdminTable',
        getVenuesEndpoint,
         `${app_api.search_venue}`,
         token,
         setItem
        )

    },[tableFilterQuery])

    useEffect(()=>{
      if(newData){
          stadiumAdminDatatable(
            errorFunc,
        'stadiumAdminTable',
         `${app_api.get_venues}`,
         `${app_api.search_venue}`,
         token,
         setItem
        )
          dispatch(clearResetBot())
      }     
    }, [newData])

    const setItem = (data, type) => {
      dispatch(setDetails({details: data, type: 'venue'}))
      if(type == 'manage-sections'){
        let section = currentNavDetails?.subs?.filter((option)=>app_urls.sections_admin == option.url)
         dispatch(setBreadCrumbName([...breadCrumbName, {id:breadCrumbName.length + 1, name:section[0].name, url:section[0].url}]))
          navigate(app_urls.sections_admin)
      }else if(type == 'edit'){
        dispatch(showVenueModalFunc({modal: true, type}))
      }else if(type == 'delete'){
        dispatch(showVenueModalFunc({modal: true, type}))
      }
    }

    const errorFunc = (msg) => {
      dispatch(setAdminErrorMessageUser({message: msg}))
    }

  return (
    <DataTableWrapper 
        table_id='stadiumAdminTable' 
        table_heading='Stadiums'
        table_buttons={showAddVenueForm ? null : <ActionButton/>}
    >
        <thead>
            <tr>
                {/* <th className="wd-5p">S/N</th> */}
                <th className="wd-15p">Name</th>
                 <th className="wd-25p">Address</th>
                <th className="wd-25p">City/Town</th>
                {/* <th className="wd-25p">Country</th> */}
                <th className="wd-20p">Picture</th>
                <th className="wd-20p">Created At</th>
                <th className="wd-15p">Actions</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </DataTableWrapper>
  )
}

function ActionButton(){
  const dispatch = useDispatch()
  return(
    <div>
      <button onClick={()=> dispatch(showAddVenueFormFunc({modal:true}))} className='btn btn-sm btn-outline-primary'>
        <i style={{fontSize: '1rem'}} className="mdi mdi-plus-circle me-1"></i>
        Add Venue
      </button>
    </div>
  )
}
