import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { competitionAdminDatatable } from '../../../../helpers/dataTables'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { clearAll, clearResetBot, onGetCountries, setAdminErrorMessageUser, setAdminLoadingUser, setDetails, showAdd, showAddCompetitionFormFunc, showCompetitionModalFunc } from '../../../../config/redux/slice/adminSlice'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'

export default function CompetitionTable() {

     const dispatch = useDispatch()

  const {
        token
    } = useSelector((state) => state.authData)

    const {
      competitionManagement:{newData, showAddCompetitionForm}
    } = useSelector((state) => state.admin)

    useEffect(() => {
      dispatch(clearAll({}))
        competitionAdminDatatable(
          errorFunc,
        'competitionAdminTable',
         `${app_api.get_competitions}`,
          `${app_api.search_competition}`,
         token,
         setItem
        )
        // getAllCountries()
    },[])


    useEffect(()=>{
      if(newData){
          competitionAdminDatatable(
          errorFunc,
        'competitionAdminTable',
         `${app_api.get_competitions}`,
         `${app_api.search_competition}`,
         token,
         setItem
        )
          dispatch(clearResetBot())
      }     
    }, [newData])

    const setItem = (data, type) => {
      dispatch(setDetails({details: data, type: 'competition'}))
      dispatch(showCompetitionModalFunc({modal: true, type}))
    }

    const errorFunc = (msg) => {
      dispatch(setAdminErrorMessageUser({message: msg}))
    }

  return (
    <DataTableWrapper 
      table_id='competitionAdminTable' 
      table_heading='Teams'
      table_buttons={showAddCompetitionForm ? null : <ActionButton/>}
    >
        <thead>
            <tr>
                {/* <th className="wd-5p">S/N</th> */}
                <th className="wd-15p">Name</th>
                <th className="wd-10p">Created At</th>
                <th className="wd-15p">Actions</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </DataTableWrapper>
  )
}

function ActionButton(){
  const dispatch = useDispatch()
  return(
    <div>
      <button onClick={()=> dispatch(showAddCompetitionFormFunc({modal:true}))} className='btn btn-sm btn-outline-primary'>
        <i style={{fontSize: '1rem'}} className="mdi mdi-plus-circle me-1"></i>
        Add Competition
      </button>
    </div>
  )
}
