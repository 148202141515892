import React, { useEffect, useState } from 'react'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onAddListing, setCustomerErrorMessage, setCustomerLoading, setDetails, setLastVisitedPage } from '../../../../config/redux/slice/customerSlice'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import moment from 'moment'
import Spinner from '../../../components/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorPage from '../../../layout/ErrorPage'
import { app_urls } from '../../../../utils/urls/app_urls'

export default function ListingPageLayout({children, heading, info_list}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {eventId} = useParams()

    const [isbadRequest, setIsbadRequest] = useState(false)

    useEffect(()=>{
        dispatch(clearAll())
        document.body.classList.add('has-diff-bg');
        getSingleEvent()
        dispatch(setLastVisitedPage(null))

        // Clean up the class on unmount (optional)
        return () => {
        if(document.querySelector('.has-diff-bg')){
            document.body.classList.remove('has-diff-bg');
        }
        dispatch(setDetails({details: {}, type: 'event'}))
        };
    },[])

    const getSingleEvent = async () =>{
      dispatch(clearAll({}))
      setIsbadRequest(false)
      const res = await get_delete_request(
        'get',
        "",
        `${app_api.get_single_event(eventId)}`,
        {},
        dispatch,
        "",
        "",
        ""
      )
      if(res !== undefined && res.status < 400){
        dispatch(setDetails({details: res?.data?.data, type: 'event'}))
      }else {
        setIsbadRequest(true)
        // dispatch(setDetails({details: {}, type: 'event'}))
        // dispatch(setDetailsCustomer({details: {}, type: 'event'}))
      }
    }

  return (
    <>
        {isbadRequest ?
            <ErrorPage status={400} message={'Event Does Not Exist'}/>
            :
            <CustomerWrapper isSidebarReq={false}>
                <div className='mb-3'>
                    <div className="sell-backround-img d-flex flex-wrap align-items-center justify-content-center"
                            style={{backgroundImage: 'url(https://images.pexels.com/photos/114296/pexels-photo-114296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)'}}>
                            <div className="mx-5 my-10 py-5">
                                <h1 className="text-white fw-bold text-center px-4">We need some info about your tickets</h1>
                            </div>
                        </div>
                    <div className="container sell bg-white text-dark">
                        <main>
                            {/* <div className="py-5 text-center">
                            <img className="d-block mx-auto mb-4" src="../assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"/>
                            <h2>Checkout form</h2>
                            <p className="lead">Below is an example form built entirely with Bootstrap’s form controls. Each / form group has a validation state that can be triggered by attempting to submit the form without completing it.</p>
                            </div> */}
                            {/* <div className="sell-backround-img d-flex flex-wrap"
                                style={{backgroundImage: 'url(https://images.pexels.com/photos/114296/pexels-photo-114296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)'}}>
                                <div className="bg-blue-500 mx-5 my-10 py-5">
                                    <h1 className="text-4xl font-bold text-center px-4">We need some info about your tickets</h1>
                                </div>
                            </div> */}

                            <div className="row g-5">
                            <div className="col-md-5 col-lg-4 order-md-last">
                                <h5 className="d-flex justify-content-between fw-bold align-items-center mb-1">
                            <button
                            onClick={()=>{navigate(app_urls.single_event_dyn(eventId), { state: { setPageLoading: false } })}}
                            style={{color: 'rgba(0,0,0,0.9)'}}
                            className='btn p-0 fw-bold'
                            >
                            <i style={{fontSize: '1rem'}} className="mdi mdi-arrow-left me-1"></i>
                            Go back to event
                            </button>
                            {/* <span className="text-dark">Your Event</span> */}
                            </h5>
                                <ul className="list-group mb-3">
                                    {info_list.map((opt)=>{
                                        return <li key={opt.id} className="list-group-item d-flex justify-content-between lh-base">
                                                    <div>
                                                    <h6 className="my-0 fw-bold">{opt.title}</h6>
                                                    <p className="text-muted">{opt.description}</p>
                                                    </div>
                                                    {/* <span className="text-muted">$12</span> */}
                                                </li>
                                    })}
                                </ul>

                                {/* <form className="card p-2">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Promo code"/>
                                    <button type="submit" className="btn btn-secondary">Redeem</button>
                                </div>
                                </form> */}
                            </div>
                            <div className="col-md-7 col-lg-8 pb-4">
                                {children}
                            </div>
                            </div>
                        
                        </main>
                    </div>
                </div>
            </CustomerWrapper>
        }
    </>
  )
}
