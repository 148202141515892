import React, { useEffect, useState } from 'react'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { listingAdminDatatable } from '../../../../helpers/dataTables'
import { setBreadCrumbName } from '../../../../config/redux/slice/appSlice'
import { app_urls } from '../../../../utils/urls/app_urls'
import { get_delete_request } from '../../../../helpers/request'
import AdminListingNewTableDesign from '../../customer/listing_management/AdminLIstingNewTableDesign'
import { clearAll, clearResetBot, setCustomerErrorMessage, setDetails, showListingModalFunc,onGetProfileSales,setCustomerLoading } from '../../../../config/redux/slice/customerSlice'
import ActiveListing from './activeListing'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CustomerWrapper from '../../../layout/CustomerWrapper'


import {
  // clearAll,
  // clearResetBot,
  setAdminErrorMessageUser,
  // setDetails,
  // showListingModalFunc,
} from '../../../../config/redux/slice/adminSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OutdatedListing from './OutdatedListing'


export default function ListingTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [listingData,setListingData] = useState()
  const [listingCount,setListingCount] = useState()


  const { token } = useSelector((state) => state.authData)

  const {breadCrumbName, currentNavDetails} = useSelector((state) => state.app)


  const {
    listingManagement: { newData,listingDetails },
  } = useSelector((state) => state.admin)


  useEffect(() => {
    // getList()
}, [])
// const getList = async () => {
//   // setLoading(true);
//   dispatch(clearAll({}))
//   const res = await get_delete_request(
//       'get',
//       token,
//       `${app_api.get_listings}?page=1&pageSize=10&draw=1&q=&_=${Date.now()}`,
//       {},
//       dispatch,
//       setCustomerLoading,
//       onGetProfileSales,
//       setCustomerErrorMessage,
//       false
//   )
//   console.log("..........................XDSDRESULT", res)
//   if (res !== undefined && res.status < 400) {

//       setListingData(res?.data?.data || [])
//       setListingCount(res?.data?.recordsTotal)
      
//   }
// }
  // useEffect(() => {
  //   dispatch(clearAll({}))
  //   listingAdminDatatable(
  //     errorFunc,
  //     'listingAdminTable',
  //     `${app_api.get_listings}`,
  //     `${app_api.search_listing}`,
  //     token,
  //     setItem
  //   )
  //   // getAllCountries()
  // }, [])

  // useEffect(() => {
  //   if (newData) {
  //     listingAdminDatatable(
  //       errorFunc,
  //       'listingAdminTable',
  //       `${app_api.get_listings}`,
  //       `${app_api.search_listing}`,
  //       token,
  //       setItem
  //     )
  //     dispatch(clearResetBot())
  //   }
  // }, [newData])

  const setItem = (data, type) => {
    dispatch(setDetails({ details: data, type: 'listing' }))
    dispatch(showListingModalFunc({ modal: true, type }))
    if(type == 'view-items'){
      dispatch(setBreadCrumbName([...breadCrumbName, {id:breadCrumbName.length + 1, name:'Items', url:`${app_urls.listings_admin}?items=true&listing=${listingDetails?.id}`}]))
      navigate(`${app_urls.listings_admin}?items=true&listing=${listingDetails?.id}`)
    }
  }

  const errorFunc = (msg) => {
    dispatch(setAdminErrorMessageUser({ message: msg }))
  }
 // <DataTableWrapper table_id="listingAdminTable" table_heading="Listings">
       {/* <AdminListingNewTableDesign listingDetails={listingData} listingCount={listingCount}/> */}
     
    // </DataTableWrapper>
  return (
   

    <DataTableWrapper heading={'Listing'}>
   
    <div className="payable-tblouter order-tbl tbl-bg-white">
      <Tabs>
        <TabList>
          <Tab>Active Listing</Tab>
          <Tab>Outdated Listing</Tab>
        </TabList>

        <TabPanel>
          <ActiveListing />
        </TabPanel>
        <TabPanel>
          <OutdatedListing />
        </TabPanel>
      </Tabs>
    </div>
  </DataTableWrapper>

  )
}
