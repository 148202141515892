import React, { memo, useEffect, useRef, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


function BarChart({sales_data}) {

    const options = {
      indexAxis: 'y',
      legend: {
          display: false,
          labels: {
              display: false
          }
      },
      scales: {
          y: {
          ticks: {
              beginAtZero:true,
              fontSize: 10
          }
          },
          x: {
          ticks: {
              beginAtZero:true,
              fontSize: 11,
              max: 80
          }
          }
      }
  };

  let labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  const [data, set_data] = useState({
                                        labels,
                                        datasets: [{
                                                      label: 'Revenue',
                                                      // data: [12, 39, 20, 10, 25, 18, 15, 13, 23, 24, 17, 14],
                                                      data: [0,0,0,0,0,0,0,0,0,0,0,0],
                                                      backgroundColor: [
                                                      '#324463',
                                                      '#5B93D3',
                                                      '#7CBDDF',
                                                      '#5B93D3',
                                                      '#324463'
                                                      ]
                                                  }],
                                      })
  useEffect(()=>{
    set_data({...data, datasets: [{...data.datasets[0], data: sales_data}]})
  }, [sales_data])

  return (
    <div className="card p-3 mt-4">
        <h6 className="card-body-title text-uppercase fw-bold tx-13">Sales revenue by month</h6>
        <Bar options={options} data={data}/>
    </div>
  )
}

export default memo(BarChart)
