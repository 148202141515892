import React, { memo } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);



// { label: "Series 1", data: [[1,10]], color: '#677489'},
//     { label: "Series 2", data: [[1,30]], color: '#218bc2'},
//     { label: "Series 3", data: [[1,90]], color: '#7CBDDF'},
//     { label: "Series 4", data: [[1,70]], color: '#5B93D3'},
//     { label: "Series 5", data: [[1,80]], color: '#324463'}

function PieChart({pie_chart_values}) {

  const data = {
  labels: ['Sold', 'Not Sold'],
  datasets: [
    {
      label: 'Tickets listed',
      data: [pie_chart_values.total_sold, (pie_chart_values.total_listed - pie_chart_values.total_sold)],
      backgroundColor: [
        '#677489',
        '#218bc2'
      ],
    //   borderColor: [
    //     'rgba(255, 99, 132, 1)',
    //     'rgba(54, 162, 235, 1)',
    //     'rgba(255, 206, 86, 1)',
    //     'rgba(75, 192, 192, 1)',
    //     'rgba(153, 102, 255, 1)',
    //     'rgba(255, 159, 64, 1)',
    //   ],
      borderWidth: 1,
    },
  ],
};

const options = {
    title: {
      display: false, // Set to false to hide the chart title
      text: 'Chart Title', // Set a custom title if needed
    },
    legend: {
      display: false,
    },
    plugins:{
        datalabels: {
            color:'red',
            anchor: "start",
            align:"end",

        }
    }
  };

  return (
    <div className="card p-4">
        <h6 className="card-body-title text-uppercase fw-bold">Ticket Listings</h6>
        <p className="mb-4 mb-sm-5">Showing percentage of tickets sold and not sold out of {pie_chart_values.total_listed} tickets listed</p>
        <Doughnut data={data} options={options}/>
        {/* <div id="flotPie2" className="ht-200 ht-sm-250"></div> */}
    </div>
  )
}

export default memo(PieChart)
