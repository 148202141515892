import React, { useEffect } from 'react'
import PasswordComponent from '../../../components/Form/PasswordComponent'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  clearAll,
  onAddAdmin,
  onAddUser,
  setAdminErrorMessageUser,
  setAdminLoadingUser,
  showAddAdminFormFunc,
} from '../../../../config/redux/slice/adminSlice'
import {
  get_delete_request,
  post_put_patch_request,
} from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'
import AddFormLayout from '../../../layout/admin/AddFormLayout'
import {
  clearAll as clearAllApp,
  onGetRoles,
} from '../../../../config/redux/slice/appSlice'
//import PhoneNumberComponent from '../../../components/Form/PhoneNumberComponent'

export default function CreateAdmin() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onTouched' })

  const { adminLoading, adminErrorMsg, adminSuccessMsg } = useSelector(
    (state) => state.admin
  )

  const { roles } = useSelector((state) => state.app)

  const { token } = useSelector((state) => state.authData)

  const passwordValue = watch('password')

  useEffect(() => {
    dispatch(clearAll({}))
    get_roles()
  }, [])

  const onSubmit = async (data) => {
    alert("hi")
    dispatch(clearAll({}))
    // if (data?.code == undefined) {
    //   setError('code', {
    //     type: 'manual',
    //     message: 'Please select country code',
    //   })
    // } else {
      //data.phone = data.code + data.mobile_no
      const res = await post_put_patch_request(
        'post',
        token,
        `${app_api.add_user}`,
        data,
        dispatch,
        setAdminLoadingUser,
        onAddAdmin,
        setAdminErrorMessageUser
      )
      if (res !== undefined && res.status < 400) {
        reset()
      }
    //}
  }

  const get_roles = async () => {
    dispatch(clearAllApp({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_roles}`,
      {},
      dispatch,
      '',
      onGetRoles,
      ''
    )
  }

  const closeForm = () => {
    dispatch(showAddAdminFormFunc({ modal: false }))
  }

  return (
    <AddFormLayout
      onClick={closeForm}
      heading_title="Add New Admin"
      sub_text="Fill in the fields below"
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="px-4 px-md-7">
          <div style={{ rowGap: '1.2rem' }} className="row mb-2">
            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor="firstName">
                  First Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="firstName"
                  {...register('firstName', {
                    required: {
                      value: true,
                      message: '*this field is required',
                    },
                  })}
                />
                {errors?.firstName?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.firstName?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor="lastName">
                  Last Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="lastName"
                  {...register('lastName', {
                    required: {
                      value: true,
                      message: '*this field is required',
                    },
                  })}
                />
                {errors?.lastName?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.lastName?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor="email">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control input-lg"
                  id="email"
                  {...register('email', {
                    required: {
                      value: true,
                      message: '*this field is required',
                    },
                    pattern: {
                      value: /^[\w-\.]+@primeevent\.live$/,
                      message: '*email must be a valid @primeevent.live domain',
                    },
                  })}
                />
                {errors?.email?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.email?.message}
                  </div>
                )}
              </div>
            </div>

            {/* <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <PhoneNumberComponent
                  fieldName={'mobile_no'}
                  fieldName2={'code'}
                  error_msg={errors.mobile_new?.message ?? errors.code?.message}
                  label={'Phone Number'}
                  setValueFunc={setValue}
                  setErrorFunc={setError}
                  clearErrorsFunc={clearErrors}
                  isReq={true}
                  register={register}
                />
              </div>
            </div> */}

            {/* <div className="col-md-6 col-lg-4">
                <div className="form-group">
                      <label htmlFor='address'>Address <span className="text-danger">*</span></label>
                      <input 
                      type="text" 
                    //   max={moment().format('YYYY-MM-DD')}
                      className="form-control input-lg" 
                      id="address"
                      {...register("address", {
                          required: {
                          value: true,
                          message: '*this field is required'
                          }
                      })
                      } 
                      />
                      {
                      errors?.address?.message &&
                      <div className="text-danger small mt-1">
                          {errors?.address?.message}
                      </div>
                      }
                  </div> 
              </div> */}

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor="role">
                  Role <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="role"
                  {...register('role', {
                    required: {
                      value: true,
                      message: '*this field is required',
                    },
                  })}
                >
                  <option value={''}>Select Role</option>
                  {roles.map((role, indx) => {
                    return (
                      <React.Fragment key={indx}>
                        {role.toLowerCase() != 'user' && (
                          <option
                            className="text-capitalize"
                            key={indx}
                            value={role}
                          >
                            {role === 'CUSTOMER_SUPPORT' ? 'CUSTOMER SUPPORT' : role}
                          </option>
                        )}
                      </React.Fragment>
                    )
                  })}
                </select>
                {errors?.role?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.role?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <PasswordComponent
                fieldName={'password'}
                error_msg={errors.password?.message}
                label={
                  <>
                    Password <span className="text-danger">*</span>
                  </>
                }
                isReq={true}
                register={register}
                patternRules={''}
                // hasHelperText={true}
              />
            </div>

            <div className="col-md-6 col-lg-4">
              <PasswordComponent
                fieldName={'confirm_password'}
                error_msg={errors.confirm_password?.message}
                label={
                  <>
                    Repeat Password <span className="text-danger">*</span>
                  </>
                }
                isReq={true}
                register={register}
                patternRules={''}
                isSameAs={passwordValue}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 px-4 d-flex align-items-center">
          <div>
            {adminLoading ? (
              <Spinner isForm={true} />
            ) : (
              <button type="submit" className="btn btn-primary me-3">
                Submit Form
              </button>
            )}
          </div>
          <button
            disabled={adminLoading ? true : false}
            onClick={closeForm}
            type="button"
            className="btn btn-info"
          >
            Cancel
          </button>
        </div>
      </form>
    </AddFormLayout>
  )
}
