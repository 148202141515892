import React from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setAdminErrorMessageUser, setAdminLoadingUser, showPromocodeModalFunc } from '../../../../config/redux/slice/adminSlice'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'

export default function ToggleStatusPromocode({ listingObj, getActiveList }) {
  const dispatch = useDispatch()
  const {
    adminLoading,
    adminErrorMsg,
    adminSuccessMsg
  } = useSelector((state) => state.admin)

  const {
    token
  } = useSelector((state) => state.authData)

  const onSubmit = async () => {
    dispatch(clearAll({}))
    const data = {
      ...listingObj,
      promoCodeTypeId: String(listingObj.promoCodeTypeId),
      percentage: parseFloat(listingObj.percentage),
      isActive: !listingObj.isActive,
      userApplied: 0,

    };
    const res = await post_put_patch_request(
      "patch",
      token,
      `${app_api.update_promocode(listingObj?.id)}`,
      data,
      dispatch,
      setAdminLoadingUser,
      "",
      setAdminErrorMessageUser
    )
    if (res !== undefined && res.status < 400) {
      getActiveList();
      dispatch(onUpdate({ message: res.data.message, type: 'promocode' }))
    }
  }

  const closeModal = () => {
    dispatch(showPromocodeModalFunc({ modal: false, type: null }))
  }

  return (
    <ModalLayout onClick={closeModal} heading={listingObj?.isActive ? 'Deactivate PromoCode' : 'Activate PromoCode'}>
      <div className="modal-body">
        <p>
          Are you sure you want to
          {listingObj?.isActive ? ' deactivate ' : ' activate '}
          this Promocode?
        </p>
      </div>
      <div className="modal-footer border-top-0 d-flex align-items-center">
        <div>
          {adminLoading ? (
            <Spinner isForm={true} />
          ) : (
            <button onClick={onSubmit} type="button" className="btn btn-primary me-3">
              Submit Form
            </button>
          )}
        </div>
        <button disabled={adminLoading} onClick={closeModal} type="button" className="btn btn-info">
          Cancel
        </button>
      </div>
    </ModalLayout>
  );
}


