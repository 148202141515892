import React, { useEffect } from 'react'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  clearResetBot,
  onGetProfileListings,
  setCustomerErrorMessage,
  setCustomerLoading,
  setDetails,
} from '../../../../config/redux/slice/customerSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { listingsProfileDatatable } from '../../../../helpers/customer_dataTables'
import { Link, useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import { get_delete_request } from '../../../../helpers/request'

export default function ListingTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { listingId } = useNavigate()

  const { token } = useSelector((state) => state.authData)

  const {
    customerSuccessMsg,
    profileListingManagement: {
      profileListingDetails,
      profileListingData,
      newData,
    },
  } = useSelector((state) => state.customer)

  useEffect(() => {
    dispatch(clearAll({}))
    listingsProfileDatatable(
      profileListingData,
      'listingsProfileTable',
      setItem
    )
    getListings()
  }, [])

  useEffect(() => {
    if (newData) {
      listingsProfileDatatable(
        profileListingData,
        'listingsProfileTable',
        setItem
      )
      dispatch(clearResetBot())
    }
  }, [newData])

  const setItem = (data, type) => {
    dispatch(setDetails({ details: data, type: 'listing' }))
     if(type == 'expand'){
      navigate(app_urls.listing_event_dyn)
    }
  }

  const getListings = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_auth_user_listings}?page=1&pageSize=5`,
      {},
      dispatch,
      setCustomerLoading,
      onGetProfileListings,
      setCustomerErrorMessage,
      true
    )
  }
  return (
    <DataTableWrapper
      table_id="listingsProfileTable"
      table_heading="Listings"
      table_buttons={
        <Link to={app_urls.user_listings} className="text-decoration-none">
          View all listings
        </Link>
      }
    >
      <thead>
        <tr>
          {/* <th className="wd-5p">Listing Id</th> */}
          <th className="wd-5p">No of Listing</th>
          <th className="wd-15p">Event</th>
          <th className="wd-10p">No of Tickets</th>
          {/* <th className="wd-10p">Price Per Ticket</th> */}
          {/* <th className="wd-10p">Face Value</th> */}
          {/* <th className="wd-10p">Reserved Price Per Ticket</th>
          <th className="wd-10p">Ticket Type</th> */}
          <th>Status</th>
          {/* <th className="wd-10p">Section</th>
          <th className="wd-10p">Block</th>
          <th className="wd-10p">Listed On</th> */}
          {/* <th className="wd-15p">Expand</th> */}
        </tr>
      </thead>
      <tbody></tbody>
    </DataTableWrapper>
  )
}
