import React, { useEffect, useRef, useState } from 'react'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, clearOnReload, setCustomerErrorMessage, setCustomerLoading, setPaymentValues } from '../../../../config/redux/slice/customerSlice'
import BillingPage from './BillingPage'
import ConfirmationPage from './ConfirmationPage'
import PaymentPage from './PaymentPage'
import moment from 'moment'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { setDetails } from '../../../../config/redux/slice/appSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { showPhoneNumberVerificationModalFunc } from '../../../../config/redux/slice/authSlice'
import { app_urls } from '../../../../utils/urls/app_urls'


export default function PageLayout() {
    const dispatch = useDispatch()
    const {tabNo} = useParams()
    const [info_list, set_info_list] = useState([])
    const [client_secret, set_client_secret] = useState('')
    const [trending_events, set_trending_events] = useState([])
    // const [activeTab, setActiveTab] = useState(1)
    const tabsRef = useRef();
    const navigate = useNavigate()

    const {
    token,
    userData: {phoneVerifiedAt}
    } = useSelector((state) => state.authData)

    const {
        customerLoading,
      sectionManagement: {sectionsData},
      paymentManagement: {activeTab}
    } = useSelector((state) => state.customer)

    const {
      eventManagement:{eventDetails},
    } = useSelector((state) => state.app)

    useEffect(()=>{
        // getClientSecretKey()
        // getSetupIntent()
        // top_selling_events()
        dispatch(clearOnReload({type: 'payment'}))
        if(tabNo && !isNaN(Number(tabNo))){
            dispatch(setPaymentValues({propertyKey: 'activeTab', value: Number(tabNo)}))
            // setActiveTab(Number(tabNo))
        }
        // return () => {
        //     dispatch(setDetails({details: {}, type: 'event'}))
        // }
    }, [])

    useEffect(()=>{
        if(phoneVerifiedAt == null){
            dispatch(showPhoneNumberVerificationModalFunc({modal: true, type: 'welcome'}))
        }
    }, []) 

     useEffect(()=>{
        dispatch(clearAll())
        document.body.classList.add('has-diff-bg');
        set_info_list([{
            id: 1,
            title: 'Event',
            description: eventDetails?.name
        },
        {
            id: 2,
            title: 'Description',
            description: eventDetails?.description
        },
        {
            id: 3,
            title: 'Venue',
            description: eventDetails?.Venue?.name
        },
        {
            id: 4,
            title: 'Address',
            description: eventDetails?.Venue?.address
        },
        {
            id: 5,
            title: 'Date',
            description: moment(eventDetails?.date).format('ddd Do MMM YYYY')
        },
        {
            id: 6,
            title: 'Time',
            description: moment(eventDetails?.date).format('h:mm a')
        }
    ])
        // Clean up the className on unmount (optional)
        return () => {
        if(document.querySelector('.has-diff-bg')){
            document.body.classList.remove('has-diff-bg');
        }
        };
    },[])

    const top_selling_events = async () => {
        const res = await get_delete_request(
          "get",
            token,
            `${app_api.top_selling_events}`,
            {},
            dispatch,
            "",
            "",
            setCustomerErrorMessage
        )
        if(res !== undefined && res.status < 400){
            set_trending_events(res?.data?.data)
        }
   }

    const goToPayment = () => {
    dispatch(setPaymentValues({propertyKey: 'activeTab', value: 2}))
  };

    // const getClientSecretKey = async () =>{
    //   dispatch(clearAll({}))
    //   const res = await get_delete_request(
    //     'get',
    //     token,
    //     `${app_api.create_payment_intent}`,
    //     {},
    //     dispatch,
    //     setCustomerLoading,
    //     "",
    //     ""
    //   )
    //   if(res !== undefined && res.status < 400){
    //     // set_client_secret(res?.data?.data?.client_secret)
    //   }
    // }

    // const getSetupIntent = async () =>{
    //   dispatch(clearAll({}))
    //   const res = await get_delete_request(
    //     'get',
    //     token,
    //     `${app_api.create_setup_intent}`,
    //     {},
    //     dispatch,
    //     setCustomerLoading,
    //     "",
    //     ""
    //   )
    //   if(res !== undefined && res.status < 400){
    //     set_client_secret(res?.data?.data?.client_secret)
    //   }
    // }
  return (
   <CustomerWrapper isSidebarReq={false}>
        <div className='mb-3'>
            {/* <div className="sell-backround-img d-flex flex-wrap align-items-center justify-content-center"
                    style={{backgroundImage: 'url(https://images.pexels.com/photos/114296/pexels-photo-114296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)'}}>
                    <div className="mx-5 my-10 py-5">
                        <h1 className="text-white fw-bold text-center px-4">We need some info about your tickets</h1>
                    </div>
                </div> */}  
            <h3 className='mt-5 text-center text-dark fw-bold'>Review And Buy</h3>      
            <div className="container mt-3 px-0 sell bg-white text-dark">
                <nav className="nav nav-pills flex-column flex-sm-row" ref={tabsRef}>
                    {/* <a className="flex-sm-fill text-sm-center nav-link active" aria-current="page" href="#">Shipping</a> */}
                    <a 
                        className={`flex-sm-fill text-sm-center nav-link ${activeTab == 1 ? 'active' : ''}`}
                        data-bs-toggle="tab" 
                        id='nav-confirmation-tab'
                        href="#nav-confirmation" 
                        aria-controls="nav-confirmation" 
                        disabled={activeTab == 2}
                    >Confirmation</a>
                    <a 
                        className={`flex-sm-fill text-sm-center nav-link ${activeTab == 2 ? 'active' : ''}`} 
                        data-bs-toggle="tab" 
                        id='nav-payment-tab'
                        href="#nav-payment" 
                        aria-controls="nav-payment" 
                        disabled={activeTab == 1}
                    >Payment</a>
                </nav>
                <main className='px-3'>

                    <div className="row g-3 pt-md-4">
                        <div className="col-md-5 col-lg-4 order-md-last">
                            <h5 className="d-flex justify-content-between fw-bold align-items-center mb-1">
                            <button
                            onClick={()=>{navigate(app_urls.single_event_dyn(eventDetails.id), { state: { setPageLoading: false } })}}
                            style={{color: 'rgba(0,0,0,0.9)'}}
                            className='btn p-0 fw-bold'
                            >
                            <i style={{fontSize: '1rem'}} className="mdi mdi-arrow-left me-1"></i>
                            Go back to event
                            </button>
                            {/* <span className="text-dark">Your Event</span> */}
                            </h5>
                            <ul className="list-group mb-3">
                                {info_list.map((opt)=>{
                                    return <li key={opt.id} className="list-group-item d-flex justify-content-between lh-base">
                                                <div>
                                                <h6 className="my-0 fw-bold">{opt.title}</h6>
                                                <p className="text-muted">{opt.description}</p>
                                                </div>
                                                {/* <span className="text-muted">$12</span> */}
                                            </li>
                                })}
                            </ul>
{/* 
                            <form className="card p-2">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Promo code"/>
                                <button type="submit" className="btn btn-secondary">Redeem</button>
                            </div>
                            </form> */}
                        </div>
                    <div style={{minHeight: '200px'}} className="col-md-7 col-lg-8 pb-4">
                        <div className="tab-content" id="nav-tabContent">
                            <div className={`tab-pane fade ${activeTab == 1 ? 'show active' : ''}`} id="nav-confirmation" role="tabpanel" aria-labelledby="nav-confirmation-tab">
                                <ConfirmationPage onClick={goToPayment}/>
                            </div>
                            <div className={`tab-pane fade ${activeTab == 2 ? 'show active' : ''}`} id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab">
                                <PaymentPage/>
                            </div>
                        </div>

                        {/* <ConfirmationPage/> */}
                        {/* <PaymentPage/> */}
                    </div>
                    </div>
                
                </main>
            </div>
        </div>
    </CustomerWrapper>
  )
}
