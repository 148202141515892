import React, { useEffect, useState, useRef } from 'react';
import { country_list } from '../../../helpers/countries';
import { useDebounce } from '../../../hooks/useDebounce';

export default function PhoneNumberComponent({
    fieldName,
    fieldName2,
    setValueFunc,
    register,
    label,
    error_msg,
    isReq,
    defaultValue,
    clearErrors
}) {
    const [country_code, set_country_code] = useState('');
    const [phone_number, set_phone_number] = useState(defaultValue || '');
    const [filtered_country_list, set_filtered_country_list] = useState(country_list);
    const [search_value, set_search_value] = useState('');
    const search_value_debounced = useDebounce(search_value, 200);
    const [localPhoneError, setLocalPhoneError] = useState('');
    const inputRef = useRef(null); // Create a ref for the input field

    useEffect(() => {
        if (defaultValue) {
            const phoneParts = defaultValue.match(/^(\+?\d{2})(\d{10})$/);
            if (phoneParts) {
                const [, code, phone] = phoneParts;
                set_country_code(code || '');
                set_phone_number(phone || '');
                setValueFunc(`${fieldName2}`, code || '');
                setValueFunc(`${fieldName}`, defaultValue);
            }
        }
    }, [defaultValue, setValueFunc, fieldName, fieldName2]);

    useEffect(() => {
        if (search_value_debounced) {
            set_filtered_country_list(
                country_list.filter((option) =>
                    option.name.toLowerCase().includes(search_value_debounced.toLowerCase()) ||
                    option.phoneCode.includes(search_value_debounced)
                )
            );
        } else {
            set_filtered_country_list(country_list);
        }
    }, [search_value_debounced]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                set_search_value(''); // Hide the list when clicking outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [inputRef]);

    const formatPhoneNumber = (phoneNumber) => {
        const cleanedNumber_ = phoneNumber.replace(/^0+/, '');
        // Remove any non-digit characters from the phone number
        const cleanedNumber = cleanedNumber_.replace(/\D/g, '');
        // Add spaces between groups of digits
        const formattedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
        setValueFunc('mobile_new', formattedNumber)
        let x = removeWhitespace(phoneNumber, 5)
        setValueFunc(`${fieldName}`, x)
    }

    const removeWhitespace = (str, count) => {
        const regex = new RegExp(`\\s{${count}}`, 'g');
        const cleanedStr = str.replace(regex, '');
        return cleanedStr;
    }

    const handlePhoneChange = (event) => {
        const value = event.target.value;
        set_phone_number(value);

        if (value.length > 20) {
            setLocalPhoneError('*Maximum length is 20 characters');
        } else {
            setLocalPhoneError('');
            if (typeof clearErrors === 'function') {
                clearErrors('mobile_new');
            }
            formatPhoneNumber(value);
        }
    };

    const handleCountryCodeChange = (event) => {

        const value = event.target.value;
        const key = event.nativeEvent.inputType;

        // Check if backspace was pressed and the input is empty
        if (key === 'deleteContentBackward') {
            set_country_code("");
            set_search_value("");

        } else {
            set_country_code(value);
            set_search_value(value);

            if (typeof clearErrors === 'function') {
                clearErrors('code');
            }
            setValueFunc(`${fieldName2}`, value);
            setValueFunc(`${fieldName}`, `${value} ${phone_number}`);
        }
    };

    const handleCountryCodeClick = () => {
        set_search_value('+');
    };

    const selectCountryCode = (code) => {
        set_country_code(code);
        set_search_value('');
        setValueFunc(`${fieldName2}`, code);
        setValueFunc(`${fieldName}`, `${code} ${phone_number}`);
    };

    return (
        <div ref={inputRef}>
            <label>{label}</label> :- <span style={{ fontWeight: 'bold' }}>{defaultValue}</span>
            <div className="input-group input-group-wrap position-relative">
                {/* Country Code Input */}
                <span className="reg-phcode-input">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Select Country"
                        value={country_code}
                        onChange={handleCountryCodeChange}
                        onClick={handleCountryCodeClick} // Pass the country_code string directly
                    />
                </span>

                {/* Phone Number Input */}
                <span className="reg-phNo-input">
                    <input
                        autocomplete="off"
                        type="text"
                        className="form-control"
                        placeholder='Change Number'
                        aria-label="Text input with dropdown button"
                        onKeyUp={(e) => formatPhoneNumber(e.target.value)}
                        {...register(`mobile_new`, {
                            required: {
                                value: isReq,
                                message: '*this field is required'
                            }
                        })}
                        onChange={handlePhoneChange}
                    />
                </span>
                <>

                    {filtered_country_list.length > 0 && search_value && (
                        <ul className="list-group  list-country-ul">
                            {filtered_country_list.map((option) => (
                                <li
                                    key={option.code}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => selectCountryCode(option.phoneCode)}
                                    className="list-group-item"
                                >
                                    <img className="me-2" width={25} src={option.flag} alt={option.name} />
                                    {option.name} ({option.phoneCode})
                                </li>
                            ))}
                        </ul>
                    )}
                </>
            </div>
            {localPhoneError && <div className="text-danger small mt-1">{localPhoneError}</div>}
            {error_msg && <div className="text-danger small mt-1">{error_msg}</div>}
        </div>
    );
}
