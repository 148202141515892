import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { itemsSalesCustomerDatatable } from '../../../../helpers/customer_dataTables'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { app_urls } from '../../../../utils/urls/app_urls'
import ConfirmDelivery from './ConfirmDelivery'
import {
  clearAll,
  setDetails,
  showSalesModalFunc,
} from '../../../../config/redux/slice/customerSlice'

export default function SingleSaleTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const {
  //   customerLoading,
  //   customerSuccessMsg,
  //   salesManagement: {
  //     salesItemsData,
  //     showSalesModal: { show, type },
  //     newData,
  //   },
  // } = useSelector((state) => state.customer)
  const {
    salesManagement:{salesItemsSubData, showSalesModal: {show, type}, newData}
  } = useSelector((state) => state.customer)

  const { token } = useSelector((state) => state.authData)

  console.log("salesItemsSubDatasalesItemsSubData",salesItemsSubData)

  useEffect(() => {
    dispatch(clearAll({}))
    itemsSalesCustomerDatatable(
      salesItemsSubData,
      'itemsSalesCustomerTable',
      setItem
    )
  }, [])

  useEffect(() => {
    // if(newData){
    itemsSalesCustomerDatatable(
      salesItemsSubData,
      'itemsSalesCustomerTable',
      setItem
    )
    // dispatch(clearResetBot())
    // }
  }, [salesItemsSubData])

  // useEffect(()=>{
  //   if(newData){
  //     itemsSalesCustomerDatatable(
  //       orderDetails?.OrderItems,
  //     'itemsSalesCustomerTable',
  //     setItem
  //     )
  //   }

  // }, [orderDetails?.OrderItems])

  const setItem = (data, type) => {
    console.log(data)
    dispatch(setDetails({ details: data, type: 'sales-items' }))
    if (type == 'confirm') {
      dispatch(showSalesModalFunc({ modal: true, type }))
    }
  }

  return (
    <CustomerWrapper heading={'Sales Items'}>
      <div className="mb-3">
        <button
          onClick={() => {
            navigate(app_urls.event_order_items)
          }}
          style={{ color: 'rgba(0,0,0,0.9)' }}
          className="btn btn-sm bg-accent fw-bold"
        >
          <i
            style={{ fontSize: '1rem' }}
            className="mdi mdi-arrow-left me-1"
          ></i>
          Go back Order
        </button>
      </div>

      {show && type == 'confirm' && <ConfirmDelivery />}

      <DataTableWrapper table_id="itemsSalesCustomerTable">
        <thead>
          <tr>
            {/* <th className="wd-5p">S/N</th> */}
            <th className="wd-10p">No of  Tickets</th>
            <th className="wd-10p">Ticket Price</th>
            <th className="wd-10p">Event Type</th>
            <th className="wd-10p">Commission Amount</th>
            <th className="wd-10p">Total Amount</th>
            <th className="wd-10p">Ticket Type</th>
            <th className="wd-10p">Delivery Status</th>
            <th className="wd-10p">Delivered At</th>
            <th className="wd-10p">Section</th>
            <th className="wd-10p">Block</th>
            <th className="wd-10p">row</th>
            <th className="wd-15p">Seller Name</th>
            <th className="wd-15p">Email</th>
            <th className="wd-15p">Address</th>
            <th className="wd-15p">Town</th>
            <th className="wd-15p">Post Code</th>
            <th className="wd-15p">Phone</th>
            <th className="wd-10p">Action</th>
          </tr>
        </thead>
        <tbody></tbody>
      </DataTableWrapper>
    </CustomerWrapper>
  )
}
