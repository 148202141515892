import React, { useEffect } from 'react'
import AdminWrapper from '../../../layout/AdminWrapper'
import AddCity from './AddCity'
import CityTable from './CityTable'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll } from '../../../../config/redux/slice/adminSlice'
import EditCity from './EditCity'
import DeleteCity from './DeleteCity'

export default function CityManagement() {

  const dispatch = useDispatch()

    const {
      cityManagement:{showAddCityForm, cityDetails, showCityModal: {show, type}}
    } = useSelector((state) => state.admin)

    useEffect(() => {
      dispatch(clearAll({}))
    },[])

  return (
    <AdminWrapper>
      <div>
        {(show && type == 'edit') &&
          <EditCity cityObj={cityDetails}/>}
        {(show && type == 'delete') &&  
          <DeleteCity cityObj={cityDetails}/>}
        {showAddCityForm &&
          <AddCity/>
        }  
        <CityTable/>
      </div>
    </AdminWrapper>
  )
}
