import React from 'react'
import { Link } from 'react-router-dom'
import AuthWrapper from '../../layout/AuthWrapper'
import { app_urls } from '../../../utils/urls/app_urls'

export default function LinkVerificationSuccessComponent({formTitle, activationMessage, background_image}) {

  return (
    <AuthWrapper background_image={background_image}>
        <div style={{columnGap: '0.8rem'}} className='greeting d-flex justify-content-center align-items-center'>
            <i style={{fontSize: '1.2rem'}} className={`mdi mdi-checkbox-marked-circle text-success`}></i>
            <p style={{fontSize: '1rem'}} className={`text-success text-capitalize`}>
              {formTitle}
            </p>
        </div>
        <div className='text-center mb-3' style={{color: '#31343D'}}>
            <p style={{lineHeight: '1.8rem'}}>{activationMessage}</p>
        </div>
      <div className="col-md-12">


        <button type="button" className="btn btn-outline-primary mb-4">
            <Link className='text-hover-white' to={app_urls.login}>Login</Link>
        </button>

      </div>
    </AuthWrapper>
  )
}
