import React, { useEffect, useState } from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import Spinner from '../../../components/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setAdminErrorMessageUser, setAdminLoadingUser, showVenueModalFunc } from '../../../../config/redux/slice/adminSlice'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useForm } from 'react-hook-form'
import { truncateMiddle } from '../../../../helpers/helpers'
import SelectComponent from '../../../components/Form/SelectComponent'

export default function EditVenue({venueObj}) {
    const dispatch = useDispatch()
    const {register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
  const [formData, setFormData] = useState(new FormData());
    const {
            adminLoading,
            adminErrorMsg,
            adminSuccessMsg
    } = useSelector((state) => state.admin)
    

    const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
    const allowedExtensionSvg = ['svg'];


    const {
        token
    } = useSelector((state) => state.authData)

    useEffect(()=>{
        setValue('city', venueObj.city.name)
        setValue('cityId', venueObj.city.id)
    }, [])

    const onSubmit = async (data) => {
        dispatch(clearAll({}))

        for (let key in data){ 
          if(typeof data[key] == "object"){
            if(data[key].length != 0){
                formData.append(key, data[key][0])
            }
          } else{
              formData.append(key, data[key])
          }
        }

        const res = await post_put_patch_request(
          "patch",
            token,
            `${app_api.update_venue(venueObj?.id)}`,
            formData,
            dispatch,
            setAdminLoadingUser,
            "",
            setAdminErrorMessageUser,
            true
        )
        if(res !== undefined && res.status < 400){
          dispatch(onUpdate({message: res.data.message, type: 'venue'}))
          setFormData(new FormData())
        }else{
          setFormData(new FormData())
        }
    }

    const closeModal = () => {
      dispatch(showVenueModalFunc({modal: false, type: null}))
    }

    function addFileName(e, idValue){
      let fileName = e.target.value;
      if(fileName !== ''){
        fileName = fileName.replace(/^.*[\\\/]/, '')
        document.getElementById(idValue).innerHTML = truncateMiddle(fileName, 30)

        const fileExtension = fileName.split('.').pop().toLowerCase();

        // Validate file extension
        if (!allowedExtensions.includes(fileExtension)) {
          alert('Only JPG, JPEG, PNG, and PDF files are allowed.'); // Inform user about invalid file type
          fileName = ''; // Reset file input to clear invalid selection
          document.getElementById(idValue).textContent = "No file chosen";
        } 

      }else{
        document.getElementById(idValue).innerHTML = "No file Chosen"
      }
    }

    function addFileNameSvg(e, idValue){
        let fileName = e.target.value;
        if(fileName !== ''){
          fileName = fileName.replace(/^.*[\\\/]/, '')
          document.getElementById(idValue).innerHTML = truncateMiddle(fileName, 30)
  
          const fileExtension = fileName.split('.').pop().toLowerCase();
  
          // Validate file extension
          if (!allowedExtensionSvg.includes(fileExtension)) {
            alert('Only SVG file are allowed.'); // Inform user about invalid file type
            fileName = ''; // Reset file input to clear invalid selection
            document.getElementById(idValue).textContent = "No file chosen";
          } 
          
        }else{
          document.getElementById(idValue).innerHTML = "No file Chosen"
        }
      }

  return (
    <ModalLayout classStyles={'modal-lg'} onClick={closeModal} heading={'Edit Venue'}>
        <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data' noValidate>
            <div className="modal-body">
                <div className="px-4 px-md-7">
                    <div style={{rowGap: '1.2rem'}} className="row mb-2">

                    <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                        <label htmlFor='name'>Name <span className="text-danger">*</span></label>
                        <input 
                        defaultValue={venueObj?.name}
                        type="text" 
                        className="form-control input-lg" 
                        id="name"
                        {...register("name", {
                            required: {
                            value: true,
                            message: '*this field is required'
                            }
                        })
                        } 
                        />
                        {
                        errors?.name?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.name?.message}
                        </div>
                        }
                    </div> 
                    </div>

                    <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                        <label htmlFor='address'>Address <span className="text-danger">*</span></label>
                        <input 
                        defaultValue={venueObj?.address}
                        type="text" 
                        className="form-control input-lg" 
                        id="address"
                        {...register("address", {
                            required: {
                            value: true,
                            message: '*this field is required'
                            }
                        })
                        } 
                        />
                        {
                        errors?.address?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.address?.message}
                        </div>
                        }
                        </div> 
                    </div>

                    <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                            <label htmlFor='city'>Town/City <span className="text-danger">*</span></label>
                            
                            <SelectComponent
                                // searchValue={search_cities}
                                register={register}
                                isReq={true}
                                fieldName={'cityId'}
                                fieldName2={'city'}
                                text='Select City'
                                setValue={setValue}
                                setErrorFunc={setError}
                                getValueFunc={getValues}
                                errors={errors?.city?.message}
                                clearErrorsFunc={clearErrors}
                                getEndpoint={app_api.get_cities}
                                searchEndpoint={app_api.search_cities}
                            />
                            {/* {
                                errors?.country?.message &&
                                <div className="text-danger small mt-1">
                                    {errors?.country?.message}
                                </div>
                                } */}
                        </div> 
                    </div>

                    <div className="col-md-6 col-lg-4">
                        <div className='form-group'>
                        <label htmlFor="image">Image</label>
                        <div>
                            <div className="custom-file mb-1">
                            <input 
                                type="file" 
                                className="custom-file-input" 
                                id="image"
                                accept=".jpg,.jpeg,.png,.pdf"
                                {...register("image")
                                } 
                                onChange={(e) => {
                                    addFileName(e, 'custom-certificate')
                                }}
                                
                            />
                            <label id="custom-certificate" className="custom-file-label">Choose file...</label>
                            {
                                errors?.image?.message &&
                                <div className="text-danger small mt-1">
                                    {errors?.image?.message}
                                </div>
                                }
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4">
                        <div className='form-group'>
                        <label htmlFor="svg">SVG</label>
                        <div>
                            <div className="custom-file mb-1">
                            <input 
                                type="file" 
                                className="custom-file-input" 
                                id="svg"
                                accept=".svg"
                                {...register("svg")
                                } 
                                onChange={(e) => {
                                    addFileName(e, 'custom-certificate2')
                                }}
                                
                            />
                            <label id="custom-certificate2" className="custom-file-label">Choose file...</label>
                            {
                                errors?.svg?.message &&
                                <div className="text-danger small mt-1">
                                    {errors?.svg?.message}
                                </div>
                                }
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                            <label htmlFor='svgHeight'>SVG Height <span className="text-danger">*</span></label>
                            <input 
                            defaultValue={venueObj?.svgHeight}
                            type="text" 
                            //   max={moment().format('YYYY-MM-DD')}
                            className="form-control input-lg" 
                            id="svgHeight"
                            {...register("svgHeight", {
                                required: {
                                value: true,
                                message: '*this field is required'
                                }
                            })
                            } 
                            />
                            {
                            errors?.svgHeight?.message &&
                            <div className="text-danger small mt-1">
                                {errors?.svgHeight?.message}
                            </div>
                            }
                        </div> 
                    </div>

                    <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                            <label htmlFor='svgWidth'>SVG Width <span className="text-danger">*</span></label>
                            <input 
                            defaultValue={venueObj?.svgWidth}
                            type="text" 
                            //   max={moment().format('YYYY-MM-DD')}
                            className="form-control input-lg" 
                            id="svgWidth"
                            {...register("svgWidth", {
                                required: {
                                value: true,
                                message: '*this field is required'
                                }
                            })
                            } 
                            />
                            {
                            errors?.svgWidth?.message &&
                            <div className="text-danger small mt-1">
                                {errors?.svgWidth?.message}
                            </div>
                            }
                        </div> 
                    </div>

                    <div className='col-lg-4'>
                        <div className="form-group">
                        <label htmlFor="hoverCode">Hover Codes <span className="text-danger">*</span></label>
                        <textarea 
                        defaultValue={venueObj?.hoverCode}
                        style={{resize: 'none'}}
                            className="form-control" 
                            id="hoverCode" 
                            rows="3"
                            {...register("hoverCode", {
                            required: {
                            value: true,
                            message: '*this field is required'
                            },
                            })} 
                        ></textarea>
                        {
                            errors?.hoverCode?.message &&
                            <div className="text-danger small mt-1">
                                {errors?.hoverCode?.message}
                            </div>
                            }
                    </div>
                    </div>
                    </div>

                </div>
            </div>
            <div className="modal-footer border-top-0 d-flex align-items-center">
                <div>
                {adminLoading ? 
                    <Spinner isForm={true}/> :
                    <button type="submit" className="btn btn-primary me-3">Submit Form</button>
                }
                </div>
                <button disabled={adminLoading ? true : false} type="button" onClick={closeModal} className="btn btn-info">Cancel</button>
            </div>
        </form>
    </ModalLayout>
  )
}
