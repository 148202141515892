import React, { useEffect, useState } from 'react'
import PasswordComponent from '../../../components/Form/PasswordComponent'
import $ from 'jquery';
// import 'bootstrap-select/dist/css/bootstrap-select.min.css';
// import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { clearAll, onAddStadium, setAdminErrorMessageUser, setAdminLoadingUser, setLoadingMoreSelectOptions, showAddVenueFormFunc } from '../../../../config/redux/slice/adminSlice'
import AddFormLayout from '../../../layout/admin/AddFormLayout'
import SelectComponent from '../../../components/Form/SelectComponent';
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request';
import { app_api } from '../../../../utils/endpoints/app_api';
import { truncateMiddle } from '../../../../helpers/helpers';
import Spinner from '../../../components/Spinner';

export default function AddStadium() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})

    const [cityList, setCityList] = useState([])
    const [isSearch, setIsSearch] = useState(false)
    const [searchVal, setSearchVal] = useState(null)
    const [totalCityRecords, setTotalCityRecords] = useState(0)
    const [pageNo, setPageNo] = useState(1)
    const [formData, setFormData] = useState(new FormData());

    const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
    const allowedExtensionSvg = ['svg'];

    const {
        token
    } = useSelector((state) => state.authData)

    const {
            adminLoading,
            adminErrorMsg,
            adminSuccessMsg
    } = useSelector((state) => state.admin)

    const closeForm = () => {
      dispatch(showAddVenueFormFunc({modal: false}))
    }

    const onSubmit = async (data) => {
        dispatch(clearAll({}))

        for (let key in data){ 
          if(typeof data[key] == "object"){
            if(data[key].length != 0){
                formData.append(key, data[key][0])
            }
          } else{
              formData.append(key, data[key])
          }
        }

        const res = await post_put_patch_request(
          "post",
            token,
            `${app_api.add_venue}`,
            formData,
            dispatch,
            setAdminLoadingUser,
            onAddStadium,
            setAdminErrorMessageUser,
            true
        )
        if(res !== undefined && res.status < 400){
          reset()
          resetFileInp()
          setFormData(new FormData())
        }else{
          setFormData(new FormData())
        }
    }

    function addFileName(e, idValue){
      let fileName = e.target.value;
      if(fileName !== ''){
        fileName = fileName.replace(/^.*[\\\/]/, '')
        document.getElementById(idValue).innerHTML = truncateMiddle(fileName, 30)

        const fileExtension = fileName.split('.').pop().toLowerCase();

        // Validate file extension
        if (!allowedExtensions.includes(fileExtension)) {
          alert('Only JPG, JPEG, PNG, and PDF files are allowed.'); // Inform user about invalid file type
          fileName = ''; // Reset file input to clear invalid selection
          document.getElementById(idValue).textContent = "No file chosen";
        } 

      }else{
        document.getElementById(idValue).innerHTML = "No file Chosen"
      }
    }

    function addFileNameSvg(e, idValue){
      let fileName = e.target.value;
      if(fileName !== ''){
        fileName = fileName.replace(/^.*[\\\/]/, '')
        document.getElementById(idValue).innerHTML = truncateMiddle(fileName, 30)

        const fileExtension = fileName.split('.').pop().toLowerCase();

        // Validate file extension
        if (!allowedExtensionSvg.includes(fileExtension)) {
          alert('Only SVG file are allowed.'); // Inform user about invalid file type
          fileName = ''; // Reset file input to clear invalid selection
          document.getElementById(idValue).textContent = "No file chosen";
        } 
        
      }else{
        document.getElementById(idValue).innerHTML = "No file Chosen"
      }
    }

    function resetFileInp(){
      document.querySelectorAll('.custom-file-label').forEach(()=>{
        resetFileInp.innerHTML = 'Choose file...'
      })
    }

  return (
 <AddFormLayout onClick={closeForm} heading_title='Add New Venue' sub_text='Fill in the fields below'>
        <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data' noValidate>
        <div className="px-4 px-md-7">
            <div style={{rowGap: '1.2rem'}} className="row mb-2">

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='name'>Name <span className="text-danger">*</span></label>
                  <input 
                  type="text" 
                  className="form-control input-lg" 
                  id="name"
                  {...register("name", {
                      required: {
                      value: true,
                      message: '*this field is required'
                      }
                  })
                  } 
                  />
                  {
                  errors?.name?.message &&
                  <div className="text-danger small mt-1">
                      {errors?.name?.message}
                  </div>
                  }
              </div> 
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='address'>Address <span className="text-danger">*</span></label>
                  <input 
                  type="text" 
                  className="form-control input-lg" 
                  id="address"
                  {...register("address", {
                      required: {
                      value: true,
                      message: '*this field is required'
                      }
                  })
                  } 
                  />
                  {
                  errors?.address?.message &&
                  <div className="text-danger small mt-1">
                      {errors?.address?.message}
                  </div>
                  }
                </div> 
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                      <label htmlFor='city'>Town/City <span className="text-danger">*</span></label>
                      
                      <SelectComponent
                        options={cityList}
                        // searchValue={search_cities}
                        register={register}
                        isReq={true}
                        fieldName={'cityId'}
                        fieldName2={'city'}
                        text='Select City'
                        setValue={setValue}
                        setErrorFunc={setError}
                        getValueFunc={getValues}
                        errors={errors?.city?.message}
                        clearErrorsFunc={clearErrors}
                        getEndpoint={app_api.get_cities}
                        searchEndpoint={app_api.search_cities}
                      />
                      {/* {
                        errors?.country?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.country?.message}
                        </div>
                        } */}
                  </div> 
              </div>

              <div className="col-md-6 col-lg-4">
                <div className='form-group'>
                  <label htmlFor="image">Image <span className="text-danger">*</span></label>
                  <div>
                    <div className="custom-file mb-1">
                      <input 
                          type="file" 
                          className="custom-file-input" 
                          id="image"
                          accept=".jpg,.jpeg,.png,.pdf"
                          {...register("image", {
                              required: {
                              value: true,
                              message: '*this field is required'
                              }
                          })
                          } 
                          onChange={(e) => {
                            addFileName(e, 'custom-certificate')
                          }}
                        
                      />
                      <label id="custom-certificate" className="custom-file-label">Choose file...</label>
                      {
                          errors?.image?.message &&
                          <div className="text-danger small mt-1">
                              {errors?.image?.message}
                          </div>
                          }
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className='form-group'>
                  <label htmlFor="svg">SVG <span className="text-danger">*</span></label>
                  <div>
                    <div className="custom-file mb-1">
                      <input 
                          type="file" 
                          className="custom-file-input" 
                          id="svg"
                          accept=".svg"
                          {...register("svg", {
                              required: {
                              value: true,
                              message: '*this field is required'
                              }
                          })
                          } 
                          onChange={(e) => {
                            addFileNameSvg(e, 'custom-certificate2')
                          }}
                        
                      />
                      <label id="custom-certificate2" className="custom-file-label">Choose file...</label>
                      {
                          errors?.svg?.message &&
                          <div className="text-danger small mt-1">
                              {errors?.svg?.message}
                          </div>
                          }
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                      <label htmlFor='svgHeight'>SVG Height <span className="text-danger">*</span></label>
                      <input 
                      type="text" 
                    //   max={moment().format('YYYY-MM-DD')}
                      className="form-control input-lg" 
                      id="svgHeight"
                      {...register("svgHeight", {
                          required: {
                          value: true,
                          message: '*this field is required'
                          }
                      })
                      } 
                      />
                      {
                      errors?.svgHeight?.message &&
                      <div className="text-danger small mt-1">
                          {errors?.svgHeight?.message}
                      </div>
                      }
                  </div> 
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                      <label htmlFor='svgWidth'>SVG Width <span className="text-danger">*</span></label>
                      <input 
                      type="text" 
                    //   max={moment().format('YYYY-MM-DD')}
                      className="form-control input-lg" 
                      id="svgWidth"
                      {...register("svgWidth", {
                          required: {
                          value: true,
                          message: '*this field is required'
                          }
                      })
                      } 
                      />
                      {
                      errors?.svgWidth?.message &&
                      <div className="text-danger small mt-1">
                          {errors?.svgWidth?.message}
                      </div>
                      }
                  </div> 
              </div>

              <div className='col-lg-4'>
                <div className="form-group">
                <label htmlFor="hoverCode">Hover Codes <span className="text-danger">*</span></label>
                <textarea 
                style={{resize: 'none'}}
                    className="form-control" 
                    id="hoverCode" 
                    rows="3"
                    {...register("hoverCode", {
                      required: {
                      value: true,
                      message: '*this field is required'
                      },
                    })} 
                ></textarea>
                 {
                      errors?.hoverCode?.message &&
                      <div className="text-danger small mt-1">
                          {errors?.hoverCode?.message}
                      </div>
                      }
            </div>
              </div>
            </div>

        </div>
        <div className="mt-4 px-4 d-flex align-items-center">
        <div>
          {adminLoading ? 
            <Spinner isForm={true}/> :
            <button type="submit" className="btn btn-primary me-3">Submit Form</button>
          }  
        </div>
          <button disabled={adminLoading ? true : false} onClick={closeForm} type="button" className="btn btn-info">Cancel</button>
        </div>
      </form>
   </AddFormLayout>
  )
}
