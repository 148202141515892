import axios from 'axios'
import { formatErrorMessage } from './helpers'
import { logoutUser } from '../config/redux/slice/authSlice'
import { logoutCustomerUser } from '../config/redux/slice/customerSlice'
import { logoutAppUser } from '../config/redux/slice/appSlice'
import { logoutAdminUser } from '../config/redux/slice/adminSlice'
// import JSZip from "jszip";
// import { saveAs } from 'file-saver';

export const post_put_patch_request = async (
  request_type,
  token,
  url,
  reqData,
  dispatch,
  loadingFunc,
  successFunc,
  errorFunc,
  includeFile = false
) => {
  let headers = includeFile
    ? {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
      'X-API-Version': 0,
    }
    : {
      Authorization: `Bearer ${token}`,
      'X-API-Version': 0,
    }
  const options = {
    headers,
  }
  //
  if (loadingFunc !== '') {
    dispatch(loadingFunc({ loading: true }))
  }
  try {
    let res
    if (request_type.toLowerCase() === 'post') {
      res = await axios.post(url, reqData, options)
    } else if (request_type.toLowerCase() === 'put') {
      res = await axios.put(url, reqData, options)
    } else if (request_type.toLowerCase() === 'patch') {
      res = await axios.patch(url, reqData, options)
    }
    if (res.status >= 200 && res.status <= 299) {
      if (loadingFunc !== '') {
        dispatch(loadingFunc({ loading: false }))
      }
      if (successFunc !== '') {
        dispatch(successFunc(res?.data?.data))
      }
      return { status: res?.status, data: res?.data }
    }
  } catch (error) {
    let msg = formatErrorMessage(error)
    if ([400, 401, 403].includes(error?.response?.status)) {
      if (error?.response?.status === 401) {
        // Handle 401 error by logging out the user
        dispatch(logoutUser());
        // dispatch(logoutCustomerUser());
        // dispatch(logoutAppUser());
        dispatch(logoutAdminUser());
      }
      if (errorFunc !== '') {
        dispatch(errorFunc({ message: msg }))
      }
      if (loadingFunc !== '') {
        dispatch(loadingFunc({ loading: false }))
      }
      return { status: error?.response?.status, data: error?.response?.data }
    } else {
      dispatch(
        errorFunc({ message: 'Something went wrong, please try again later' })
      )
    }
  }
  if (loadingFunc !== '') {
    dispatch(loadingFunc({ loading: false }))
  }
}

export const post_put_request_promoCode = async (
  request_type,
  token,
  url,
  reqData,
  dispatch,
  loadingFunc,
  successFunc,
  errorFunc,
  includeFile = false
) => {
  let headers = includeFile
    ? {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'X-API-Version': 0,
    }
    : {
      Authorization: `Bearer ${token}`,
      'X-API-Version': 0,
    }
  const options = {
    headers,
  }
  //
  if (loadingFunc !== '') {
    dispatch(loadingFunc({ loading: true }))
  }
  try {
    let res
    if (request_type.toLowerCase() === 'post') {
      res = await axios.post(url, reqData, options)
    } else if (request_type.toLowerCase() === 'put') {
      res = await axios.put(url, reqData, options)
    } else if (request_type.toLowerCase() === 'patch') {
      res = await axios.patch(url, reqData, options)
    }
    if (res.status >= 200 && res.status <= 299) {
      if (loadingFunc !== '') {
        dispatch(loadingFunc({ loading: false }))
      }
      if (successFunc !== '') {
        dispatch(successFunc(res?.data?.data))
      }
      return { status: res?.status, data: res?.data }
    }
  } catch (error) {
    let msg = formatErrorMessage(error)
    if ([400, 401, 403].includes(error?.response?.status)) {
      if (error?.response?.status === 401) {
        // Handle 401 error by logging out the user
        dispatch(logoutUser());
        // dispatch(logoutCustomerUser());
        // dispatch(logoutAppUser());
        dispatch(logoutAdminUser());
      }
      if (errorFunc !== '') {
        dispatch(errorFunc({ message: msg }))
      }
      if (loadingFunc !== '') {
        dispatch(loadingFunc({ loading: false }))
      }
      return { status: error?.response?.status, data: error?.response?.data }
    } else {
      dispatch(
        errorFunc({ message: 'Something went wrong, please try again later' })
      )
    }
  }
  if (loadingFunc !== '') {
    dispatch(loadingFunc({ loading: false }))
  }
}
export const get_delete_request = async (
  request_type,
  token,
  url,
  reqData,
  dispatch,
  loadingFunc,
  successFunc,
  errorFunc,
  isPaginated = false
) => {
  //
  const options = {
    params: reqData,
    headers: {
      'Content-type': 'application/json',
      'X-API-Version': 0,
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  if (loadingFunc !== '') {
    dispatch(loadingFunc({ loading: true }))
  }
  try {
    let res

    if (request_type.toLowerCase() === 'get') {
      res = await axios.get(`${url}`, options)
    } else if (request_type.toLowerCase() === 'delete') {
      res = await axios.delete(url, options)
    }

    if (res.status >= 200 && res.status <= 299) {
      if (loadingFunc !== '') {
        dispatch(loadingFunc({ loading: false }))
      }
      if (successFunc !== '') {
        if (isPaginated) {
          dispatch(successFunc(res?.data?.data?.data))
        } else {
          dispatch(successFunc(res?.data?.data))
        }
      }
      return { status: res?.status, data: res?.data }
    }
  } catch (error) {
    let msg = formatErrorMessage(error)
    if ([400, 401, 403, 500].includes(error?.response?.status)) {
      // if (error?.response?.status === 500) {
      //   // Handle 500 error by logging out the user
      //   dispatch(logoutUser());
      // }
      if (error?.response?.status === 401) {
        // Handle 401 error by logging out the user
        dispatch(logoutUser());
        // dispatch(logoutCustomerUser());
        // dispatch(logoutAppUser());
        dispatch(logoutAdminUser());
      }
      if (errorFunc !== '') {
        dispatch(errorFunc({ message: msg }))
      }
      if (loadingFunc !== '') {
        dispatch(loadingFunc({ loading: false }))
      }
      return { status: error?.response?.status, data: error?.response?.data }
    } else {
      console.log('Something went wrong, please try again later')
    }
  }
  if (loadingFunc !== '') {
    dispatch(loadingFunc({ loading: false }))
  }
}

export const get_request = async (
  request_type,
  token,
  url,
  reqData,
  dispatch,
  loadingFunc,
  successFunc,
  errorFunc,
  isPaginated = false
) => {
  //
  const options = {
    params: reqData,
    headers: {
      'Content-type': 'application/json',
      'X-API-Version': 0,
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  if (loadingFunc !== '') {
    dispatch(loadingFunc({ loading: true }))
  }
  try {
    let res

    if (request_type.toLowerCase() === 'get') {
      res = await axios.get(`${url}`, options)
    } else if (request_type.toLowerCase() === 'delete') {
      res = await axios.delete(url, reqData, options)
    }

    if (res.status >= 200 && res.status <= 299) {
      if (loadingFunc !== '') {
        dispatch(loadingFunc({ loading: false }))
      }
      if (successFunc !== '') {
        if (isPaginated) {
          dispatch(successFunc(res.data.data.data))
        } else {
          dispatch(successFunc(res.data.data))
        }
      }
      return { status: res.status, data: res.data }
    }
  } catch (error) {
    let msg = formatErrorMessage(error)
    if (errorFunc !== '') {
      dispatch(errorFunc({ message: msg }))
    }
    if (error?.response?.status === 400) {
      if (loadingFunc !== '') {
        dispatch(loadingFunc({ loading: false }))
      }
      return { status: error.response.status, data: error.response.data }
    }
    // if(error?.response?.status === 401){
    //     if(loadingFunc!==""){
    //         dispatch(loadingFunc({loading: false}))
    //     }
    //     return {status: error.response.status, data: []}
    // }
  }

  if (loadingFunc !== '') {
    dispatch(loadingFunc({ loading: false }))
  }
}
