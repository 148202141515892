import React, { useEffect, useState } from 'react'
import CardWrapper from '../../../layout/CardWrapper'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import { buildAddress } from '../../../../helpers/helpers'

export default function SalesDetails() {
    const navigate = useNavigate()
    const {
      salesManagement: {salesDetails}
    } = useSelector((state) => state.customer)

    const [details_list, set_details_list] = useState([])

    useEffect(()=>{
        const {houseName, firstLineAddress, secondLineAddress, town, postCode, country} = salesDetails?.order?.user
        let details = [
            {
                id: 1,
                name: 'Buyer',
                value: salesDetails?.order?.user?.name
            },
            {
                id: 13,
                name: 'Email',
                value: salesDetails?.order?.user?.email
            },
            {
                id: 14,
                name: 'Address',
                value: buildAddress({houseName, firstLineAddress, secondLineAddress, town, postCode, country: country?.name})
            },
            {
                id: 2,
                name: 'Event',
                value: salesDetails?.ticket?.listing?.event?.name
            },
            // {
            //     id: 3,
            //     name: 'Venue',
            //     value: salesDetails?.Venue?.name
            // },
            {
                id: 4,
                name: 'Type of Tickets',
                value: salesDetails?.ticket?.listing?.ticketsType
            },
            // {
            //     id: 5,
            //     name: 'No of Tickets',
            //     value: salesDetails?.ticket?.listing?.numberOfTickets
            // },
            // {
            //     id: 12,
            //     name: 'Sell Togetther',
            //     value: salesDetails?.sellTogether ? 'Yes' : 'No'
            // },
            // {
            //     id: 6,
            //     name: 'Section',
            //     value: salesDetails?.section?.name
            // },
            // {
            //     id: 7,
            //     name: 'Subsection',
            //     value: salesDetails?.subSection?.name
            // },
            {
                id: 8,
                name: 'Ticket Price',
                value: salesDetails?.price ? `$${salesDetails?.price}` : ''
            },
            {
                id: 9,
                name: 'Amount to receive',
                value: salesDetails?.commissionAmount ? `$${salesDetails?.price - salesDetails?.commissionAmount}` : ''
            },
            {
                id: 10,
                name: 'Delivery Status',
                value: salesDetails?.deliveryStatus
            },
            {
                id: 11,
                name: 'Sold On',
                value:salesDetails?.createdAt ?  moment(salesDetails?.createdAt).format('YYYY-MM-DD') : ''
            },
        ]
        set_details_list(details)
    }, [])
  return (
    <CustomerWrapper heading={'Sales'}>
          <div className='mb-3'>
        <button
        onClick={()=>{navigate(app_urls.sales)}}
          style={{color: 'rgba(0,0,0,0.9)'}}
         className='btn btn-sm bg-accent fw-bold'
        >
          <i style={{fontSize: '1rem'}} className="mdi mdi-arrow-left me-1"></i>
          Go back to sales
        </button>
      </div>
        <CardWrapper cardTitle={'Sale Details'}>
            <div style={{rowGap: '1.2rem'}} className='row mt-2'>
                {details_list.map((option)=>{
                    return <div className='col-md-6 col-lg-4'>
                                <span className='fw-bold'>{option.name}: </span>
                                <span>{option.value}</span>
                            </div>
                })}
            </div>
        </CardWrapper>
    </CustomerWrapper>
  )
}

