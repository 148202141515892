import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll } from '../../../../config/redux/slice/adminSlice'
import AdminWrapper from '../../../layout/AdminWrapper'
import AddCompetition from './AddCompetition'
import CompetitionTable from './CompetitionTable'
import EditCompetition from './EditCompetition'
import DeleteCompetition from './DeleteCompetition'

export default function CompetitionManagement() {

  const dispatch = useDispatch()
  const {
      competitionManagement:{showAddCompetitionForm, competitionDetails, showCompetitionModal: {show, type}}
    } = useSelector((state) => state.admin)

    useEffect(() => {
      dispatch(clearAll({}))
    },[])

  return (
    <AdminWrapper>
      <div>
        {(show && type == 'edit') &&
          <EditCompetition competitionObj={competitionDetails}/>}
        {(show && type == 'delete') &&  
          <DeleteCompetition competitionObj={competitionDetails}/>}
        {showAddCompetitionForm &&
          <AddCompetition/>
        }  
        <CompetitionTable/>
      </div>
    </AdminWrapper>
  )
}
