import { admin_urls } from "./admin_urls"
import { guest_urls } from "./guest_urls"
import { onboard_urls } from "./onboard_urls"
import { user_urls } from "./user_urls"

export const app_urls = {
    ...admin_urls,
    ...guest_urls,
    ...onboard_urls,
    ...user_urls
}