import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import PasswordComponent from '../../../components/Form/PasswordComponent'
import Spinner from '../../../components/Spinner'
import {
  clearAll,
  onUpdate,
  setCustomerErrorMessage,
  setCustomerLoading,
} from '../../../../config/redux/slice/customerSlice'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'

export default function ChangePassword() {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onTouched' })
  const passwordValue = watch('newPassword')
  const {
    customerLoading,

    customerSuccessMsg,
    listingManagement: { listingFormData },
    eventManagement: { eventDetails },
  } = useSelector((state) => state.customer)

  const { token } = useSelector((state) => state.authData)

  const onSubmit = async (data) => {
    dispatch(clearAll({}))

    const res = await post_put_patch_request(
      'patch',
      token,
      `${app_api.update_password}`,
      data,
      dispatch,
      setCustomerLoading,
      '',
      setCustomerErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      dispatch(onUpdate({ message: res.data.message }))
      reset()
    }
  }

  return (
    <div className="border-top py-3">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="px-4 px-md-7">
          <div style={{ rowGap: '1.2rem' }} className="row mb-2">
            <div className="col-xl-6">
              <PasswordComponent
                fieldName={'oldPassword'}
                error_msg={errors?.oldPassword?.message}
                label={
                  <>
                    Old Password <span className="text-danger">*</span>
                  </>
                }
                isReq={true}
                register={register}
                patternRules={''}
                // hasHelperText={true}
              />
            </div>

            <div className="col-xl-6">
              <PasswordComponent
                fieldName={'newPassword'}
                error_msg={errors?.newPassword?.message}
                label={
                  <>
                    New Password <span className="text-danger">*</span>
                  </>
                }
                isReq={true}
                register={register}
                patternRules={'password'}
                hasHelperText={true}
                //   isSameAs={passwordValue}
              />
            </div>

            <div className="col-xl-6">
              <PasswordComponent
                fieldName={'confirm_password'}
                error_msg={errors?.confirm_password?.message}
                label={
                  <>
                    Repeat Password <span className="text-danger">*</span>
                  </>
                }
                isReq={true}
                register={register}
                patternRules={'password'}
                isSameAs={passwordValue}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 px-4 d-flex align-items-center">
          <div>
            {customerLoading ? (
              <Spinner isForm={true} />
            ) : (
              <button type="submit" className="btn btn-accent me-3">
                Submit Form
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
