import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { clearAll, setErrorMessage, setLoading } from '../../../config/redux/slice/authSlice'
import { post_put_patch_request } from '../../../helpers/request'
import { app_api } from '../../../utils/endpoints/app_api'
import { app_urls } from '../../../utils/urls/app_urls'
import AuthAlert from '../../components/AuthAlert'
import PasswordComponent from '../../components/Form/PasswordComponent'
import Spinner from '../../components/Spinner'
import AuthWrapper from '../../layout/AuthWrapper'
import account_activated_background from '../../../assets/images/background_images/account_activated_background.jpg'

export default function PasswordResetSuccess() {
  const dispatch = useDispatch()
 
  useEffect(()=>{
      dispatch(clearAll({}))
    }, [])  


  return (
    <AuthWrapper background_image={account_activated_background}>
      <div style={{columnGap: '0.8rem'}} className='greeting d-flex justify-content-center align-items-center'>
            <i style={{fontSize: '1.2rem'}} className={`mdi mdi-checkbox-marked-circle text-success`}></i>
            <p style={{fontSize: '1rem'}} className={`text-success text-capitalize`}>
              Password Reset Successful
            </p>
        </div>
       <div className='text-center mb-3' style={{color: '#31343D'}}>
            <p style={{lineHeight: '1.8rem'}}>You have successfully reset your password. 
            You can now login with your new password.</p>
        </div>
      <div className="col-md-12">


        <button type="button" className="btn btn-outline-primary mb-4">
            <i className=" mdi mdi-arrow-left-thick mr-1"></i>
            <Link className='text-hover-white' to={app_urls.login}>Proceed to Login</Link>
        </button>
        {/* <Spinner/> */}
      </div>
    </AuthWrapper>
  )
}
