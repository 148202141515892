import React, { useEffect } from 'react'
import { InfoComponent, JumbotronComponent, MiddleSectionComponent, PostHighlightComponent } from '../../components'
import buyers_jumbotron_img from '../../../assets/images/buyers_jumbotron_img.jpeg'
import buyers_jumbotron_img_small from '../../../assets/images/buyers_jumbotron_img_small.jpeg'
import { FAQS_BUYING, buy_tickets, sell_tickets, steps_to_buy_tickets, trending_events } from '../../../constants/english'
import faq_icon from '../../../assets/images/faq_icon.svg'
import step1_icon from '../../../assets/images/step1_icon.svg'
import step_line from '../../../assets/images/step_line.svg'
import GeneralWrapper from '../../layout/GeneralWrapper'
import ticketing_icon from '../../../assets/images/ticketing_icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { showEventModalFunc } from '../../../config/redux/slice/appSlice'

export default function BuyersPage() {

    const dispatch = useDispatch()
   
    const eventData = useSelector((state) => state.app.eventManagement.eventData);
    const topEvents = eventData.slice(0,5);

    useEffect(()=>{
      window.scrollTo(0, 0);

      return () => {
        dispatch(showEventModalFunc({modal: false, type: null}))
      }
    }, [])
  return (
    <GeneralWrapper>
        <div className='container-fluid p-0'>
            <JumbotronComponent page={'buy'} hasSearch={true} hero_text='Buying tickets on PrimeTic' img_src={buyers_jumbotron_img} img_src_small={buyers_jumbotron_img_small}/>
            <MiddleSectionComponent
                middle_text={<>The excitement of live events make memories that last a lifetime, and friendships that enrich 
                every day of your life. We’re a simpler, fairer and faster ticketing platform that brings people together, 
                whether you’re planning your season in advance or looking for last-minute seats.<br/><span style={{fontWeight: 700}}>So let’s cheer. Together.</span></>}
                />
                <div className='px-3 px-lg-100 px-xl-200 mt-56 mb-5'>
                    <h2 className='text-center text-primary fw-bold'>Tickets in 4 simple steps</h2>
                    <div className='row mt-3 flex-wrap'>
                        {steps_to_buy_tickets.map((option, ind)=>{
                            return <div key={ind} className='col-md-3 text-center'>
                                        <div className="ticket-img-height"><img src={option.icon} alt='' /></div>
                                        <h4 className='mt-4 text-primary fw-bold'>{option.heading_text}</h4>
                                        <p>{option.action}</p>
                                    </div>
                                })}
                    </div>
                    <div className='text-center px-md-200 px-xxl-156 mx-xxl-156'>
                      <h4 className='mt-4 text-primary fw-bold'>Can’t find your ticket?</h4>
                      <p>Set an alert to find your ticket and match with sellers within your budget when tickets become available.</p>
                    </div>
                </div>
                <InfoComponent heading_text='Make every game great' dataList={buy_tickets} />
                {topEvents.length > 0 &&
                <PostHighlightComponent containerId={'myScrollContainer'} contentId={'myScrollContent'} heading_text='Trending Events' dataList={topEvents} flexbasis='16.17rem'/>
                }
                {/* <PostHighlightComponent containerId={'myScrollContainer1'} contentId={'myScrollContent1'} heading_text='Hot Tickets' dataList={trending_events} flexbasis='21.17rem'/> */}

                <div className='mx-lg-200 mx-xl-312 mt-56 mb-5'>
                    <h1 className='text-center text-primary fw-bold'>FAQS</h1>
                    <div>
                        {FAQS_BUYING.map((option, ind)=>{
                            return <div key={ind} className='mt-4 row align-items-start justify-content-center'>
                                        <img style={{flexBasis: '40px'}} className='col-4' src={faq_icon} alt=''/>
                                        <div className='col-8'>
                                            <p className='text-primary fw-bold mb-1'>{option.question}</p>
                                            <p className='mb-0'>{option.answer}</p>
                                        </div>
                                    </div>
                                })}
                    </div>
                </div>
            </div>
    </GeneralWrapper>
  )
}
