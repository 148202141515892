import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { app_urls } from '../../utils/urls/app_urls'
import ModalLayout from '../layout/ModalLayout'
import { useForm } from 'react-hook-form'
import { setCookiesValue } from '../../config/redux/slice/appSlice'
import { useDispatch } from 'react-redux'

export default function CookieNotice() {
  const dispatch = useDispatch()
  const [showCookieNotice, setShowCookieNotice] = useState(false)
  const [customizeCookies, setCustomizeCookies] = useState(false)
  const { register, handleSubmit } = useForm({ mode: 'onTouched' })

  useEffect(() => {
    if (showCookieNotice) {
      document.body.classList.add('modal-open')
    } else {
      if (document.querySelector('.modal-open')) {
        document.body.classList.remove('modal-open')
      }
    }

    // Clean up the class on unmount (optional)
    return () => {
      if (document.querySelector('.modal-open')) {
        document.body.classList.remove('modal-open')
      }
    }
  }, [showCookieNotice])

  useEffect(() => {
    if (document.cookie.includes('primeTic') === false) {
      setShowCookieNotice(true)
      // Call the function to clear Google Analytics and Google AdSense cookies
      clearGoogleCookies()
      clearWebsiteCookies()
    } else {
      if (document.cookie.includes('marketingCookies')) {
        dispatch(
          setCookiesValue({
            propertyKey: 'acceptMarketingCookies',
            value: true,
          })
        )
      }
      if (document.cookie.includes('analyticsCookies')) {
        dispatch(
          setCookiesValue({
            propertyKey: 'acceptAnalyticsCookies',
            value: true,
          })
        )
      }
      if (document.cookie.includes('functionalCookies')) {
        dispatch(
          setCookiesValue({
            propertyKey: 'acceptFunctionalCookies',
            value: true,
          })
        )
      }
      if (document.cookie.includes('allCookies')) {
        dispatch(
          setCookiesValue({ propertyKey: 'acceptAllCookies', value: true })
        )
      }
      if (document.cookie.includes('essentialCookies')) {
        dispatch(
          setCookiesValue({
            propertyKey: 'acceptEssentialCookies',
            value: true,
          })
        )
      }
    }
  }, [])

  const acceptCookie = (type) => {
    document.cookie = 'cookieBy= primeTic; max-age=' + 60 * 60 * 24 * 30
    if (type === 'all') {
      setCookie('allCookies', 'true', 30)
      dispatch(
        setCookiesValue({ propertyKey: 'acceptAllCookies', value: true })
      )
    } else if (type === 'essential') {
      setCookie('essentialCookies', 'true', 30)
      dispatch(
        setCookiesValue({ propertyKey: 'acceptEssentialCookies', value: true })
      )
    }
    setShowCookieNotice(false)
  }

  const onSubmit = async (data) => {
    document.cookie = 'cookieBy= primeTic; max-age=' + 60 * 60 * 24 * 30

    setCookie('essentialCookies', 'true', 30)
    dispatch(
      setCookiesValue({ propertyKey: 'acceptEssentialCookies', value: true })
    )

    const sortedArr1 = data?.websiteCookies?.slice().sort()
    const sortedArr2 = ['functional', 'marketing', 'analytics'].slice().sort()

    if (sortedArr1.toString() === sortedArr2.toString()) {
      setCookie('allCookies', 'true', 30)
      dispatch(
        setCookiesValue({ propertyKey: 'acceptAllCookies', value: true })
      )
    } else {
      for (let type of data?.websiteCookies) {
        if (type === 'marketing') {
          setCookie('marketingCookies', 'true', 30)
          dispatch(
            setCookiesValue({
              propertyKey: 'acceptMarketingCookies',
              value: true,
            })
          )
        } else if (type === 'analytics') {
          setCookie('analyticsCookies', 'true', 30)
          dispatch(
            setCookiesValue({
              propertyKey: 'acceptAnalyticsCookies',
              value: true,
            })
          )
        } else if (type === 'functional') {
          setCookie('functionalCookies', 'true', 30)
          dispatch(
            setCookiesValue({
              propertyKey: 'acceptFunctionalCookies',
              value: true,
            })
          )
        }
      }
    }
    setShowCookieNotice(false)
  }

  function setCookie(name, value, days) {
    var expires = ''
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + value + expires + '; path=/'
  }

  function clearGoogleCookies() {
    // Clear Google Analytics cookies
    document.cookie = '_ga=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    document.cookie = '_gid=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    document.cookie = '_gat=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    document.cookie = '_gac=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'

    // Clear Google AdSense cookies
    document.cookie = 'DSID=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    document.cookie = 'FLC=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    document.cookie = 'AID=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    document.cookie = 'TAID=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    document.cookie =
      'exchange_uid=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'

    // Add more Google AdSense cookies if needed

    // Note: The actual cookie names may vary, and it's important to check the current cookie names used by these services.

    // You may also want to clear localStorage and sessionStorage if these are used by Google Analytics or AdSense
    localStorage.clear()
    sessionStorage.clear()
  }

  function clearWebsiteCookies() {
    // Clear Website cookies
    document.cookie = 'allCookies=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    document.cookie =
      'analyticsCookies=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    document.cookie =
      'marketingCookies=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    document.cookie =
      'functionalCookies=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    document.cookie =
      'essentialCookies=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
  }

  return (
    <>
      {showCookieNotice && (
        <ModalLayout
          backgroundColor="rgba(0,0,0,0.95)"
          className={`${showCookieNotice ? '' : 'd-none'}`}
          keepHeading={false}
          classStyles={`modal-dialog-centered ${
            customizeCookies ? 'modal-lg' : ''
          }`}
        >
          {customizeCookies === false ? (
            <div
              className={`${
                showCookieNotice ? '' : 'd-none'
              } cookie-notice bg-white py-4 px-3`}
            >
              <h4 className="text-center fw-bold">Welcome to PrimeTic</h4>
              <p>
                This website uses cookies to enhance your browsing experience
                and provide personalized content. By continuing to use this
                site, you acknowledge and consent to our use of cookies. To
                learn more about how we use cookies, please review our{' '}
                <Link to={app_urls.cookie_policy}>Cookie Policy</Link>.
              </p>
              <div
                style={{ rowGap: '0.6rem' }}
                className="mt-3 d-flex flex-column align-items-center"
              >
                <button
                  style={{ width: '80%' }}
                  onClick={() => acceptCookie('all')}
                  className="btn btn-lg rounded-0 btn-primary text-white fw-bold"
                >
                  Agree to all cookies
                </button>
                <button
                  style={{ width: '80%' }}
                  onClick={() => setCustomizeCookies(true)}
                  className="btn btn-lg rounded-0 btn-outline-primary fw-bold"
                >
                  Customize
                </button>
                <button
                  style={{ width: '80%' }}
                  onClick={() => acceptCookie('essential')}
                  className="btn btn-lg rounded-0 btn-primary text-white fw-bold"
                >
                  Agree to essential cookies only
                </button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className="py-4" noValidate>
              <div className="modal-body">
                <div className="px-4 px-md-7">
                  <h4 className="text-center fw-bold mb-2">
                    Choose your cookie preferences
                  </h4>
                  <div style={{ rowGap: '1.2rem' }} className="row mb-2">
                    <div className="col-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="essential"
                          disabled
                          id="essentialCookie"
                          checked
                          {...register('websiteCookies')}
                        />
                        <label className="fs-5" htmlFor="essentialCookie">
                          Essential
                        </label>
                        <p className="form-check-label">
                          Our website can’t operate properly without essential
                          cookies. We only collect the minimum personal
                          information needed to provide services on
                          primetic.co.uk. You can disable these cookies in your
                          browser but parts of primetic.co.uk may not work.
                        </p>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="analytics"
                          id="analyticsCookie"
                          {...register('websiteCookies')}
                        />
                        <label className="fs-5" htmlFor="analyticsCookie">
                          Analytics
                        </label>
                        <p className="form-check-label">
                          We use analytical cookies to measure how you use our
                          website and help improve primetic.co.uk.
                        </p>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="marketing"
                          id="marketingCookie"
                          {...register('websiteCookies')}
                        />
                        <label className="fs-5" htmlFor="marketingCookie">
                          Marketing
                        </label>
                        <p className="form-check-label">
                          We use marketing cookies to display personalised
                          advertisements on other sites you may visit. We work
                          with approved partners to deliver relevant content and
                          measure the effectiveness of these advertisements.
                        </p>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="functional"
                          id="functionalCookie"
                          {...register('websiteCookies')}
                        />
                        <label className="fs-5" htmlFor="functionalCookie">
                          Functional
                        </label>
                        <p className="form-check-label">
                          These cookies let us save the choices you make and
                          some of the information you provide when browsing
                          primetic.co.uk. They don’t track your browsing
                          activity on other websites. Without functional
                          cookies, primetic.co.uk may not work reliably.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ columnGap: '0.6rem', fontSize: '1.2rem' }}
                className="mt-3 d-flex flex-wrap align-items-center justify-content-center"
              >
                <button className="btn btn-lg fs-6 rounded-0 btn-primary text-white px-5">
                  Accept all cookies
                </button>
                <button className="btn btn-lg fs-6 rounded-0 btn-outline-primary px-5">
                  Confirm your choices
                </button>
              </div>
              {/* <div className="modal-footer border-top-0 d-flex align-items-center">
                <div>
                {adminLoading ? 
                    <Spinner isForm={true}/> :
                    <button type="submit" className="btn btn-primary me-3">Submit Form</button>
                }
                </div>
                <button disabled={adminLoading ? true : false} type="button" className="btn btn-info">Cancel</button>
            </div> */}
            </form>
          )}
        </ModalLayout>
      )}
    </>
  )
}
