import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { itemsEventSalesCustomerDatatable } from '../../../../helpers/customer_dataTables'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { app_urls } from '../../../../utils/urls/app_urls'
import ConfirmDelivery from './ConfirmDelivery'
import {
  clearAll,
  setDetails,
  showSalesModalFunc,
  setSalesValues,
  clearResetBot,
  setCustomerErrorMessage,
  setCustomerLoading,
} from '../../../../config/redux/slice/customerSlice'

export default function SingleEventSaleTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    customerLoading,
    customerSuccessMsg,
    salesManagement: {
      salesItemsData,
      showSalesModal: { show, type },
      newData,
    },
  } = useSelector((state) => state.customer)

  const { token } = useSelector((state) => state.authData)

  console.log("salesItemsDatasalesItemsData",salesItemsData);


  useEffect(() => {
    dispatch(clearAll({}))
    itemsEventSalesCustomerDatatable(
      salesItemsData,
      'itemsEventSalesCustomerTable',
      setItem
    )
  }, [])

  useEffect(() => {
    // if(newData){
        itemsEventSalesCustomerDatatable(
      salesItemsData,
      'itemsEventSalesCustomerTable',
      setItem
    )
    // dispatch(clearResetBot())
    // }
  }, [salesItemsData])

  // useEffect(()=>{
  //   if(newData){
  //     itemsSalesCustomerDatatable(
  //       orderDetails?.OrderItems,
  //     'itemsSalesCustomerTable',
  //     setItem
  //     )
  //   }

  // }, [orderDetails?.OrderItems])

//   const setItem = (data, type) => {
//     console.log(data)
//     dispatch(setDetails({ details: data, type: 'sales-items' }))
//     if (type == 'confirm') {
//       dispatch(showSalesModalFunc({ modal: true, type }))
//     }
//   }
  const setItem = (data, type) => {
    dispatch(setDetails({ details: data, type: 'sales' }))
    dispatch(setSalesValues({propertyKey: 'salesItemsSubData', value: data?.OrderItems ?? []}))

    

    // dispatch(
    //   setSalesValues({ propertyKey: 'salesItemsData', value: data?.data ?? [] })
    // )
    if (type == 'details') {
      navigate(app_urls.user_sales_details)
    } else if (type == 'view-items') {
      navigate(app_urls.sale_items)
    } else if (type == 'confirm') {
      dispatch(showSalesModalFunc({ modal: true, type }))
    }
    // else if(type == 'view-event-items'){
    //   navigate(app_urls.event_order_items)
    // }
  }

  const errorFunc = (msg) => {
    dispatch(setCustomerErrorMessage({ message: msg }))
  }

  return (
    <CustomerWrapper heading={'Sales'}>
      <div className="mb-3">
        <button
          onClick={() => {
            navigate(app_urls.sales)
          }}
          style={{ color: 'rgba(0,0,0,0.9)' }}
          className="btn btn-sm bg-accent fw-bold"
        >
          <i
            style={{ fontSize: '1rem' }}
            className="mdi mdi-arrow-left me-1"
          ></i>
          Go back to sales
        </button>
      </div>

      {show && type == 'confirm' && <ConfirmDelivery />}

      <DataTableWrapper table_id="itemsEventSalesCustomerTable">
        <thead>
          <tr>
          <th className="wd-15p">Order Id</th>
            <th className="wd-15p">No. Of Orders</th>
            {/* <th className="wd-15p">commissionAmount</th> */}
            <th className="wd-10p">Ticket Price</th>
            {/* <th className="wd-10p">Total Amount</th> */}
            <th className="wd-10p">Sale Date</th>
            <th className="wd-10p">Delivery Status</th>
            <th className="wd-15p">Name</th>
            <th className="wd-15p">Email</th>
            <th className="wd-15p">Address</th>
            <th className="wd-15p">Town</th>
            <th className="wd-15p">Post Code</th>
            <th className="wd-15p">Phone</th>
             <th className="wd-15p">Actions</th>
          </tr>
        </thead>
        <tbody></tbody>
      </DataTableWrapper>
    </CustomerWrapper>
  )
}
