import React from 'react';

function Popup({ show, handleHide, isError, message }) {
    if (!show) return null;

    return (
        <>
            <div className="popupOverlay" onClick={handleHide}></div>
            <div className="popupalert" id="popupalertSec">
                <div className="centered-div-alert">
                    <div className={`btn-${isError ? 'error' : 'success'}-tic`}>
                        {isError ? (
                            <div id="overallStatus-red" className='overallStatus-red'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="70px"
                                    height="70px"
                                    viewBox="0 0 45 45"
                                    version="1.1"
                                    className="animated"
                                >
                                    <title>red_status</title>
                                    <defs>
                                        <path
                                            d="M8.43205769 38.1906149C2.90905516 38.17423 0.630739568 34.2579195 3.34303632 29.4437542L16.0266682 6.93106212C18.7390819 2.11668929 23.1497045 2.11171534 25.8729434 6.91091845L38.7464812 29.5981394C41.4720152 34.4013872 39.1981662 38.2818882 33.6812163 38.2655212L8.43205769 38.1906149Z"
                                            id="path-1"
                                        />
                                        <filter
                                            x="-50%"
                                            y="-50%"
                                            width="200%"
                                            height="200%"
                                            filterUnits="objectBoundingBox"
                                            id="filter-2"
                                        >
                                            <feOffset dx="-2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                                            <feGaussianBlur
                                                stdDeviation="1"
                                                in="shadowOffsetOuter1"
                                                result="shadowBlurOuter1"
                                            />
                                            <feComposite
                                                in="shadowBlurOuter1"
                                                in2="SourceAlpha"
                                                operator="out"
                                                result="shadowBlurOuter1"
                                            />
                                            <feColorMatrix
                                                values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.245216259 0"
                                                type="matrix"
                                                in="shadowBlurOuter1"
                                            />
                                        </filter>
                                        <filter
                                            x="-50%"
                                            y="-50%"
                                            width="200%"
                                            height="200%"
                                            filterUnits="objectBoundingBox"
                                            id="filter-3"
                                        >
                                            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetInner1" />
                                            <feComposite
                                                in="shadowOffsetInner1"
                                                in2="SourceAlpha"
                                                operator="arithmetic"
                                                k2="-1"
                                                k3="1"
                                                result="shadowInnerInner1"
                                            />
                                            <feColorMatrix
                                                values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.4 0"
                                                type="matrix"
                                                in="shadowInnerInner1"
                                            />
                                        </filter>
                                    </defs>
                                    <g
                                        id="red-exclamation"
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                        transform="translate(0.000000, -1.000000)"
                                    >
                                        <g id="Rectangle-134">
                                            <use
                                                fill="black"
                                                fillOpacity="1"
                                                filter="url(#filter-2)"
                                                xlinkHref="#path-1"
                                            />
                                            <use fill="#F1342F" fillRule="evenodd" xlinkHref="#path-1" />
                                            <use
                                                fill="black"
                                                fillOpacity="1"
                                                filter="url(#filter-3)"
                                                xlinkHref="#path-1"
                                            />
                                            <use xlinkHref="#path-1" />
                                        </g>
                                        <line
                                            className="exclamation"
                                            x1="21"
                                            y1="11"
                                            x2="21"
                                            y2="25"
                                            strokeLinecap="round"
                                            stroke="#fff"
                                            strokeWidth="4"
                                        />
                                        <circle className="dot" cx="21" cy="32" r="2" fill="#fff" />
                                    </g>
                                </svg>
                            </div>
                        ) : (
                            <div id="overallStatus-green" className='overallStatus-green'>
                                <svg id="successAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
                                    <path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z" />
                                    <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" strokeWidth="2" strokeLinecap="round" fill="transparent" />
                                    <polyline id="successAnimationCheck" stroke="#979797" strokeWidth="2" points="23 34 34 43 47 27" fill="transparent" />
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
                <div className="alert-success-m-top">
                    <h3 className="alert-success-msg alert-success-msg-font">
                        {isError ? 'Error' : 'Success'}
                    </h3>
                    <p className='alert-success-msg'>{message}</p>
                </div>

                <div className="btn-alert-sec">
                    <button className={`btn-ok-alert ${isError ? 'red-alert-btn' : 'green-alert-btn'}`} onClick={handleHide}>
                        Ok
                    </button>
                </div>
            </div>
        </>
    );
}

export default Popup;
