import React from 'react'
import { useSelector } from 'react-redux'
import AdminWrapper from '../../../layout/AdminWrapper'
import RefundTable from './RefundTable'
import ApproveRefundModal from './ApproveRefundModal'
import DeclineRefundModal from './DeclineRefundModal'

export default function RefundManagement() {
  const {
      refundManagement:{refundDetails, showRefundModal: {show, type}}
    } = useSelector((state) => state.admin)

  return (
    <AdminWrapper>
      <div>
        {(show && type == 'approve') &&
        <ApproveRefundModal refundObj={refundDetails}/>}
        {(show && type == 'decline') &&
        <DeclineRefundModal refundObj={refundDetails}/>}
        <RefundTable/>
      </div>
    </AdminWrapper>
  )
}
