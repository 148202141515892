import React, { memo, useEffect, useState } from 'react'
import Sparkline from '../../../components/jquery/Sparkline'
import { array_list, strText } from '../../../../constants/english'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, setAdminErrorMessageUser } from '../../../../config/redux/slice/adminSlice'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'
import { formatNumber } from '../../../../helpers/helpers'

function DashboardCards({dashboard_card_count_list, dashboard_card_revenue_list}) {

  return (
    <section style={{rowGap: '1rem'}} className='d-flex flex-column'>
    <div style={{rowGap: '0.7rem'}} className="w-100 m-0 row row-sm py-3 bg-white">
        <h5>Usage Summary</h5>
        {dashboard_card_count_list.map((option)=>{
            return <div key={option.id} className="col-sm-6 col-xl-3">
                    <div className={`card rounded-4 border-0 p-4 bg-primary ${option.color}`}>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h6 className="text-uppercase dashboard-card-heading mb-0 tx-spacing-1 text-white">{option.heading}</h6>
                        </div>
                        {/* <!-- card-header --> */}
                        <div className="d-flex align-items-center justify-content-between">
                            {/* <Sparkline sparkline_data={option.sparkline_list}/> */}
                            <span style={{height: '45px'}}>
                                <span style={{fontSize: '30px'}} className={`${option.icon} ${option.icon_color}`}></span>
                            </span>
                            {option.loading == true ?
                                <Spinner color={'text-white'}/>
                                :
                                <h5 className="mb-0 text-white tx-lato tx-bold">{option.total_count}</h5>
                            }
                        </div>
                        {/* <!-- card-body --> */}
                        {/* <div className="d-flex align-items-center justify-content-between mt-3 border-top border-white pt-2">
                            <div>
                                <span style={{fontSize: '0.7rem', color: 'rgba(255, 255, 255, 0.6)'}}>{option.bottom_text.left.text}</span>
                                <h6 className="text-white mb-0">{option.bottom_text.left.value}</h6>
                            </div>
                            {option?.bottom_text.right &&
                                <div>
                                    <span style={{fontSize: '0.7rem', color: 'rgba(255, 255, 255, 0.6)'}}>{option?.bottom_text.right.text}</span>
                                    <h6 className="text-white mb-0">{option?.bottom_text.right.value}</h6>
                                </div>
                            }
                        </div> */}
                    </div>
                    {/* <!-- card --> */}
                </div>
        })}
        {/* <!-- col-3 --> */}
    </div>
    <div style={{rowGap: '0.7rem'}} className="w-100 m-0 row row-sm py-3 bg-white">
        <h5>Revenue Summary</h5>
        {dashboard_card_revenue_list.map((option)=>{
            return <div key={option.id} className="col-sm-6 col-xl-3">
                    <div className={`card rounded-4 border-0 p-4 bg-primary ${option.color}`}>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h6 className="text-uppercase dashboard-card-heading mb-0 tx-spacing-1 text-white">{option.heading}</h6>
                        </div>
                        {/* <!-- card-header --> */}
                        <div className="d-flex align-items-center justify-content-between">
                            {/* <Sparkline sparkline_data={option.sparkline_list}/> */}
                          <span style={{height: '45px'}}>
                                <span style={{fontSize: '30px'}} className={`${option.icon} ${option.icon_color}`}></span>
                            </span>
                            {option.loading == true ?
                                <Spinner color={'text-white'}/>
                                :
                                <h5 className="mb-0 text-white tx-lato tx-bold">{strText.currency_symbol}{formatNumber(option.total_amount)}</h5>
                            }
                        </div>
                        {/* <!-- card-body --> */}
                        {/* <div className="d-flex align-items-center justify-content-between mt-3 border-top border-white pt-2">
                            <div>
                                <span style={{fontSize: '0.7rem', color: 'rgba(255, 255, 255, 0.6)'}}>{option.bottom_text.left.text}</span>
                                <h6 className="text-white mb-0">{option.bottom_text.left.value}</h6>
                            </div>
                            {option?.bottom_text.right &&
                                <div>
                                    <span style={{fontSize: '0.7rem', color: 'rgba(255, 255, 255, 0.6)'}}>{option?.bottom_text.right.text}</span>
                                    <h6 className="text-white mb-0">{option?.bottom_text.right.value}</h6>
                                </div>
                            }
                        </div> */}
                    </div>
                    {/* <!-- card --> */}
                </div>
        })}
        {/* <!-- col-3 --> */}
    </div>
    </section>
  )
}

export default memo(DashboardCards)
