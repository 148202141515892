import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { eventAdminDatatable } from '../../../../helpers/dataTables'
import { clearAll, clearResetBot, onGetCountries, setAdminErrorMessageUser, setAdminLoadingUser, setDetails, showAddEventFormFunc, showEventModalFunc } from '../../../../config/redux/slice/adminSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ActiveEvents from "./userTableCopy"
import SellerTable from './SellerTable'

export default function UserManagementTable({userTypes}) {
//   const dispatch = useDispatch()
//   const {
//     token
//   } = useSelector((state) => state.authData)

//   const {
//     tableFilterQuery,
//     eventManagement: { newData, showAddEventForm }
//   } = useSelector((state) => state.admin)

  useEffect(() => {
console.log("kkkkkkkkkkkkkkkkkkkkkkk",userTypes)
  }, [userTypes])
//   useEffect(() => {
//     let getEndpoint = tableFilterQuery ? `${app_api.get_events}?${tableFilterQuery}` : `${app_api.get_events}`
//     console.log(tableFilterQuery)
//     eventAdminDatatable(
//       errorFunc,
//       'eventAdminTable',
//       getEndpoint,
//       `${app_api.search_events}`,
//       token,
//       setItem
//     )
//   }, [tableFilterQuery])

//   useEffect(() => {
//     if (newData) {
//       eventAdminDatatable(
//         errorFunc,
//         'eventAdminTable',
//         `${app_api.get_events}`,
//         `${app_api.search_events}`,
//         token,
//         setItem
//       )
//       dispatch(clearResetBot())
//     }
//   }, [newData])

//   const setItem = (data, type) => {
//     dispatch(setDetails({ details: data, type: 'event' }))
//     if (type == 'edit') {
//       dispatch(showEventModalFunc({ modal: true, type }))
//     } else if (type == 'delete') {
//       dispatch(showEventModalFunc({ modal: true, type }))
//     }
//   }

//   const errorFunc = (msg) => {
//     dispatch(setAdminErrorMessageUser({ message: msg }))
//   }

  return (
    <>
      <div className="payable-tblouter order-tbl tbl-bg-white">
      {/* <ActionButton/> */}
        <Tabs>
          <TabList>
            <Tab>Buyer</Tab>
            <Tab>Seller</Tab>
          </TabList>
          <TabPanel>
            <ActiveEvents userTypes={userTypes}/>
          </TabPanel>
          <TabPanel>
            <SellerTable userTypes={userTypes} />
          </TabPanel>
        </Tabs>
      </div>
    </>
  )
}
// function ActionButton() {
//   const dispatch = useDispatch()
//   return (
//     <div style={{"text-align": "right"}}>
//       <button onClick={() => dispatch(showAddEventFormFunc({ modal: true }))} className='btn btn-sm btn-outline-primary'>
//         <i style={{ fontSize: '1rem' }} className="mdi mdi-plus-circle me-1"></i>
//         Add Event
//       </button>
//     </div>
//   )
// }
