import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearAll, clearResetBot, onGetSubSections, setAdminErrorMessageUser, setAdminLoadingUser, setDetails, showAddSubSectionFormFunc, showSubStadiumMapFunc, showSubsectionModalFunc } from '../../../../config/redux/slice/adminSlice'
import { subsectionAdminDatatable } from '../../../../helpers/dataTables'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import DataTableWrapper from '../../../layout/DataTableWrapper'

export default function SubsectionTable({sectionObj}) {
   const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
            adminSuccessMsg
    } = useSelector((state) => state.admin)

    const {
        token
    } = useSelector((state) => state.authData)

    const {
      subSectionManagement:{newData, showAddSubSectionForm, subSectionsData}
    } = useSelector((state) => state.admin)

    useEffect(() => {
      dispatch(clearAll({}))
        subsectionAdminDatatable(
          subSectionsData,
        'subSectionAdminTable',
        setItem
        )
    getAllSubsections()
    },[])

    useEffect(()=>{
      if(newData){
        subsectionAdminDatatable(
          subSectionsData,
        'subSectionAdminTable',
        setItem
        )
        dispatch(clearResetBot())
      }   
      if(adminSuccessMsg ==='SubSection Added Successfully!'){
            getAllSubsections()
      }    
    }, [newData, adminSuccessMsg])

    const getAllSubsections = async () =>{
      dispatch(clearAll({}))
      const res = await get_delete_request(
        'get',
        token,
        `${app_api.get_subsections(sectionObj.id)}`,
        {},
        dispatch,
        setAdminLoadingUser,
        onGetSubSections,
        setAdminErrorMessageUser
      )
    }

    const setItem = (data, type) => {
      dispatch(setDetails({details: data, type: 'subsection'}))
      dispatch(showSubsectionModalFunc({modal: true, type}))
    }

  return (
    <DataTableWrapper 
        table_id='subSectionAdminTable' 
        table_heading='Blocks'
        table_buttons={<ActionButton/>}
    >
        <thead>
            <tr>
                {/* <th className="wd-5p">S/N</th> */}
                <th className="wd-15p">Name</th>
                <th className="wd-20p">Created At</th>
                <th className="wd-15p">Actions</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </DataTableWrapper>
  )
}

function ActionButton(){
  const dispatch = useDispatch()

  const {
      subSectionManagement:{showAddSubSectionForm, showSubStadiumMap}
    } = useSelector((state) => state.admin)

  return(
    <div style={{columnGap: '0.5rem', rowGap: '0.6rem'}} className='d-flex flex-wrap'>
    {!showAddSubSectionForm &&
      <button onClick={()=> dispatch(showAddSubSectionFormFunc({modal:true}))} className='btn btn-sm btn-outline-primary'>
        <i style={{fontSize: '1rem'}} className="mdi mdi-plus-circle me-1"></i>
        Add Block
      </button>}
      {!showSubStadiumMap &&
      <button onClick={()=> dispatch(showSubStadiumMapFunc({modal:true}))} className='btn btn-sm btn-outline-primary'>
        <i style={{fontSize: '1rem'}} className="mdi mdi-eye me-1"></i>
        View Stadium Map
      </button>}
    </div>
  )
}
