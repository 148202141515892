import React, { useEffect, useState } from 'react'
import AdminWrapper from '../../../layout/AdminWrapper'
import ListingTable from './ListingTable'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import SingleListingTable from './SingleListingTable'


export default function ListingManagement() {
  const location = useLocation()

  const [currentTab, setCurrentTab] = useState('')
  const params = new URLSearchParams(location.search);


  const dispatch = useDispatch()
  const {
    listingManagement: { listingDetails, showListingModal: { show, type } }
  } = useSelector((state) => state.admin)


  useEffect(()=>{
    const items = params.get('items');
    setCurrentTab(items)
}, [params.get('items')])

  return (
    <AdminWrapper>
      <div>

        {currentTab == 'true' ?
          <SingleListingTable />
          :
          <ListingTable />
        }


        
      </div>
    </AdminWrapper>
  )
}
