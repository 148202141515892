import React from 'react'
import google from '../../../assets/images/google.svg';
import facebook from '../../../assets/images/facebook.svg';
import apple from '../../../assets/images/apple-logo.png';
import { get_delete_request, get_request } from '../../../helpers/request';
import { useDispatch } from 'react-redux';
import { app_api } from '../../../utils/endpoints/app_api';
import { core_backend_url } from '../../../config';

export default function SocialAuthComponent() {
    
    const dispatch = useDispatch()

     const authenticate_user  = () => {
        window.location.href = `${app_api.google_auth}`
    }
    const authenticateWithFacebook = () => {
        window.location.href = `${app_api.facebook_auth}`;
      };
  return (
    <>
        <div className="alternate-reg">
                <span>Or instant log in with</span>
        </div>

        <div className="social-icon-sec-wrapr" role="group" aria-label="Vertical button group">
            <button type="button" onClick={authenticate_user} className="btn btn-outline-gray py-2 btn-outer-socialIcon">
                <img className='' src={google} width={25} height={25}/>
                {/* Continue with Google */}
            </button>
            <button type="button" onClick={authenticateWithFacebook} className="btn btn-outline-gray py-2 btn-outer-socialIcon">
                <img className='' src={facebook} width={25} height={25}/>
                {/* Continue with Facebook */}
            </button>
            <button type="button" className="btn btn-outline-gray py-2 btn-outer-socialIcon">
                <img className='' src={apple} width={25} height={25}/>
                {/* Continue with Facebook */}
            </button>

            {/* <button type="button" onClick={authenticate_user} className="btn btn-outline-gray py-2">
                <img className='me-3' src={google}/>
                Continue with Google
            </button>
            <button type="button" onClick={authenticateWithFacebook} className="btn btn-outline-gray py-2">
                <img className='me-3' src={facebook}/>
                Continue with Facebook
            </button> */}
            {/* <button type="button" class="btn btn-outline-primary">Button</button>
            <button type="button" class="btn btn-outline-primary">Button</button> */}
        </div>
    </>
  )
}
