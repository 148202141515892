import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setAdminErrorMessageUser, setAdminLoadingUser, showTeamModalFunc } from '../../../../config/redux/slice/adminSlice'
import { truncateMiddle } from '../../../../helpers/helpers'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import SelectComponent from '../../../components/Form/SelectComponent'
import Spinner from '../../../components/Spinner'
import ModalLayout from '../../../layout/ModalLayout'

export default function EditTeam({ teamObj }) {
  const dispatch = useDispatch()
  const { register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: { errors }, reset } = useForm({ mode: 'onTouched' })
  const [formData, setFormData] = useState(new FormData());
  const {
    adminLoading,
    adminErrorMsg,
    adminSuccessMsg
  } = useSelector((state) => state.admin)

  const {
    token
  } = useSelector((state) => state.authData)

  const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];

  useEffect(() => {
    setValue('venueId', teamObj.venueId)
  }, [])

  const onSubmit = async (data) => {
    dispatch(clearAll({}))

    for (let key in data) {
      if (typeof data[key] == "object") {
        if (data[key].length != 0) {
          formData.append(key, data[key][0])
        }
      } else {
        formData.append(key, data[key])
      }
    }

    const res = await post_put_patch_request(
      "patch",
      token,
      `${app_api.update_team(teamObj?.id)}`,
      formData,
      dispatch,
      setAdminLoadingUser,
      "",
      setAdminErrorMessageUser,
      true
    )
    if (res !== undefined && res.status < 400) {
      dispatch(onUpdate({ message: res.data.message, type: 'team' }))
      setFormData(new FormData())
    } else {
      setFormData(new FormData())
    }
  }

  const closeModal = () => {
    dispatch(showTeamModalFunc({ modal: false, type: null }))
  }

  function addFileName(e, idValue) {
    let fileName = e.target.value;
    if (fileName !== '') {
      fileName = fileName.replace(/^.*[\\\/]/, '')
      document.getElementById(idValue).innerHTML = truncateMiddle(fileName, 30)

      const fileExtension = fileName.split('.').pop().toLowerCase();

      // Validate file extension
      if (!allowedExtensions.includes(fileExtension)) {
        alert('Only JPG, JPEG, PNG, and PDF files are allowed.'); // Inform user about invalid file type
        fileName = ''; // Reset file input to clear invalid selection
        document.getElementById(idValue).textContent = "No file chosen";
      }
    } else {
      document.getElementById(idValue).innerHTML = "No file Chosen"
    }
  }

  return (
    <ModalLayout classStyles={'modal-lg'} onClick={closeModal} heading={'Edit Team'}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="modal-body">
          <div className="px-4 px-md-7">
            <div style={{ rowGap: '1.2rem' }} className="row mb-2">

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='name'>Name <span className="text-danger">*</span></label>
                  <input
                    defaultValue={teamObj?.name}
                    type="text"
                    className="form-control input-lg"
                    id="name"
                    {...register("name", {
                      required: {
                        value: true,
                        message: '*this field is required'
                      }
                    })
                    }
                  />
                  {
                    errors?.name?.message &&
                    <div className="text-danger small mt-1">
                      {errors?.name?.message}
                    </div>
                  }
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className='form-group'>
                  <label htmlFor="logo">Logo</label>
                  <div>
                    <div className="custom-file mb-1">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="logo"
                        accept=".jpg,.jpeg,.png,.pdf"
                        {...register("logo", {
                          required: {
                            value: true,
                            message: '*this field is required'
                          }
                        })
                        }
                        onChange={(e) => {
                          addFileName(e, 'custom-certificate')
                        }}

                      />
                      <label id="custom-certificate" className="custom-file-label">Choose file...</label>
                      {
                        errors?.logo?.message &&
                        <div className="text-danger small mt-1">
                          {errors?.logo?.message}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='city'>Venue <span className="text-danger">*</span></label>

                  <SelectComponent
                    // searchValue={search_cities}
                    loadReq={false}
                    register={register}
                    isReq={true}
                    fieldName={'venueId'}
                    fieldName2={'venue'}
                    text='Select Venue'
                    setValue={setValue}
                    setErrorFunc={setError}
                    getValueFunc={getValues}
                    errors={errors?.venue?.message}
                    clearErrorsFunc={clearErrors}
                    getEndpoint={app_api.get_venues}
                    // isNotPaginated={true}
                    searchEndpoint={app_api.search_venue}
                  />
                  {/* {
                        errors?.country?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.country?.message}
                        </div>
                        } */}
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="modal-footer border-top-0 d-flex align-items-center">
          <div>
            {adminLoading ?
              <Spinner isForm={true} /> :
              <button type="submit" className="btn btn-primary me-3">Submit Form</button>
            }
          </div>
          <button disabled={adminLoading ? true : false} onClick={closeModal} type="button" className="btn btn-info">Cancel</button>
        </div>
      </form>
    </ModalLayout>
  )
}
