import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, setCustomerLoading, onGetProfileSales, setCustomerErrorMessage } from '../../../../config/redux/slice/customerSlice'
import { formatNumber } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
import moment from 'moment'
import Pagination from "react-js-pagination";
import ViewTicket from '../../customer/ticket_management/ViewTicket';
import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request } from '../../../../helpers/request'
import ToggleStatusListing from '../../admin/listing_management/ToggleStatusListing';
import SellerDeatils from '../../admin/listing_management/SellerDetails'
import {
    setDetails,
    showListingModalFunc,
} from '../../../../config/redux/slice/adminSlice';
import DeleteListing from './DeleteListing';

// import { format } from 'date-fns';
export default function AdminListingNewTableDesign(props) {
    const dispatch = useDispatch()
    const { token } = useSelector((state) => state.authData)
    const [listingAllDetails, setListingDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [nestedExpandedRows, setNestedExpandedRows] = useState({});
    const [nestedExpandedRowsList, setNestedExpandedRowsList] = useState({});
    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [ticketData, setTicketData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDbPopup, setShowDbPopup] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        getListingDetails()
    }, [])


    const getListingDetails = async (query = '') => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_listings}?eventStatus=ActiveEvents&page=1&pageSize=10&draw=1&q=${query}&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal

            setListingDetails(pending_list);
            setRecordsTotal(totalCount);
            setSearchTerm('')
        } else {
            setListingDetails([]);
        }
        setLoading(false);
    }


    const {
        listingManagement: { listingDetails, showListingModal: { show, type } }
    } = useSelector((state) => state.admin)

    const toggleRow = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows(prevExpandedRows => ({
            ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
            [index]: !prevExpandedRows[index]
        }));
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
    };

    const setItem = (data, type) => {
        if (type == 'delete' || type == 'toggle_status') {
            dispatch(setDetails({ details: data, type: 'listing' }))
            setTicketData(data)
            dispatch(showListingModalFunc({ modal: true, type }))
        }

    }
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };


    const handleSearchChange = async (event) => {
        setLoading(true);
        setListingDetails([]);

        dispatch(clearAll({}));

        const pageSize = 10;

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_listings}?eventStatus=ActiveEvents&page=1&pageSize=10&draw=1&q=${searchTerm}&_=1719545988610`,

            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        if (res !== undefined && res.status < 400) {
            setListingDetails(res.data.data)
            setRecordsTotal(res.data.recordsFiltered)
        }
        setLoading(false);
        setActivePage(1);
    }
    const handlePageChange = async (pageNumber) => {
        setLoading(true);
        dispatch(clearAll({}));

        const pageSize = 10;
        setActivePage(pageNumber);

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_listings}?eventStatus=ActiveEvents&page=${pageNumber}&pageSize=${pageSize}&draw=1&q=&_=1719545988610`,

            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        if (res !== undefined && res.status < 400) {
            setListingDetails(res.data.data);
            setRecordsTotal(res.data.recordsFiltered);

        }
        setLoading(false);
    }
    const handleDeliveryaddressClick = (item) => {
        debugger
        setShowDbPopup(true)
        setSelectedItem(item);
    }
    return (
        <>
            <div className="payable-tblouter order-tbl">
                <div className="form-group search-input-top">
                    <input type="text" className="form-control" id="searchValue" placeholder="Search..." onChange={handleInputChange} />
                    <button className="btn btn-primary me-3 ml-2" style={{ marginLeft: "10px" }} onClick={() => handleSearchChange()}>Search</button>

                </div>
                {(show && type == 'toggle_status') &&
                    <ToggleStatusListing listingObj={ticketData} />}
                {(show && type == 'view') &&
                    <ViewTicket ticketObj={ticketData} />
                }
                <div className="table-responsive web-show-tbl">
                    <table className="table table-striped mt-3 tbl-pending">
                        <thead>
                            <tr>
                                <th scope="col" className='no-wrap'>No of Listing</th>
                                <th scope="col" className='no-wrap'>Event</th>
                                <th scope="col" className='no-wrap'>Event Date</th>
                                <th scope="col" className='no-wrap'>No of Tickets</th>
                                <th scope="col" className='no-wrap'>Expand</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="8" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                                </tr>
                            )
                                : listingAllDetails ? (
                                    listingAllDetails?.map((item, index) => (
                                        <>
                                            <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                                                <td>{item?.listings?.length || 0}</td>
                                                <td>{item?.name ? item?.name : item?.eventName}</td>
                                                <td>{moment(item?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                                <td>{item?.totalNoTickets}</td>
                                                <td> <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                                    {expandedRows[index] ? (
                                                        <i className="fa fa-angle-up fa-sizeset"></i>
                                                    ) : (
                                                        <i className="fa fa-angle-down fa-sizeset"></i>
                                                    )} </a>
                                                </td>
                                            </tr>
                                            {(expandedRows[index] && item?.listings?.length > 0) && (
                                                <tr>
                                                    <td colSpan="8">
                                                        <table className="table table-bordered tbl-inner-width">
                                                            <thead>
                                                                <tr>
                                                                    <th className='no-wrap'>Listing Id</th>
                                                                    <th className='no-wrap'>Seller</th>
                                                                    <th className='no-wrap'>Section & Block</th>
                                                                    <th className='no-wrap'>No of Tickets</th>
                                                                    <th className='no-wrap'>Price/Ticket</th>
                                                                    <th className='no-wrap'>Reserved Price </th>
                                                                    <th className='no-wrap'>Face Value </th>
                                                                    <th className='no-wrap'>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            {item?.listings?.map((items, orderIndex) => (
                                                                <tbody>
                                                                    <tr key={orderIndex}>
                                                                        <td>{items?.listingRef}</td>
                                                                        <td><div class="txt-ellipsis txt-capitalize">{items?.user?.name}</div>
                                                                       <div className="pt-2"><span className='delivery-add-link' onClick={() => handleDeliveryaddressClick(items)}>Seller Details</span></div> 
                                                                        </td>
                                                                        <td><span title={items?.section?.name} class="text-ellipsis">{items?.section?.name}</span><br></br>{items?.subSection?.name}
                                                                      </td>
                                                                        {/* <td>{items?.Tickets?.filter(ticket => ticket?.soldAt === null).length}</td> */}
                                                                        <td>
                                                                            <div>Tickets Listed: {items?.Tickets.length}</div>
                                                                            <div>Available Tickets: {items?.Tickets?.filter(ticket => ticket?.soldAt === null)?.length}</div>
                                                                        </td>
                                                                        <td>{items?.pricePerTicket ? `${strText.currency_symbol}${formatNumber(items?.pricePerTicket)}` : 'N/A'}</td>
                                                                        <td>{items?.reservedPricePerTicket ? `${strText.currency_symbol}${formatNumber(items?.reservedPricePerTicket)}` : 'N/A'}</td>
                                                                        <td>{items?.facevaluePerTicket ? `${strText.currency_symbol}${formatNumber(items?.facevaluePerTicket)}` : 'N/A'}</td>
                                                                        <td>

                                                                            <a onClick={() => setItem(items, 'toggle_status')} title={items?.deactivatedAt == null ? 'Deactivate Listing' : 'Activate Listing'}
                                                                                class={items?.deactivatedAt == null ? "btn btn-sm bg-primary text-white btn-icon toggle-listing btn-m-right" : "btn btn-sm bg-success text-white btn-icon toggle-listing btn-m-right"}>
                                                                                <i class={items?.deactivatedAt == null ? 'fa fa-pause' : 'fa fa-play'}></i>
                                                                            </a>
                                                                            <a title="Delete Listing"
                                                                                onClick={() => setItem(items, 'delete')}
                                                                                data-id={items?.id}
                                                                                class="btn btn-sm bg-danger btn-icon text-white delete-listing btn-m-right">
                                                                                <i class="fa fa-trash"></i>
                                                                            </a>
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            ))}
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}

                                        </>
                                    ))

                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center py-4">No Data Found</td>
                                    </tr>
                                )}
                        </tbody>
                    </table>

                </div>


                <div className="mob-table-section">
                    {loading ? (
                        <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>

                    ) : listingAllDetails ? (
                        listingAllDetails?.map((item, index) => (
                            <>

                                <div className={`mob-table-sec-outer ${expandedRows[index] ? 'fontWeight' : ''}`} key={index}>
                                    <div className="position-relative">
                                        <div className="mob-table-sec">
                                            <h3 className="mob-tbl-head">No of Listing</h3>
                                            <div className="mob-tbl-value">{item?.listings?.length || 0}</div>
                                        </div>
                                        <div className="mob-table-sec">
                                            <h3 className="mob-tbl-head">Event</h3>
                                            <div className="mob-tbl-value" title={item?.name ? item?.name : item?.eventName}>{item?.name ? item?.name : item?.eventName}</div>
                                        </div>
                                        <div className="mob-table-sec">
                                            <h3 className="mob-tbl-head">Event Date</h3>
                                            <div className="mob-tbl-value">{moment(item?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                        </div>
                                        <div className="mob-table-sec">
                                            <h3 className="mob-tbl-head">No of Tickets</h3>
                                            <div className="mob-tbl-value">{item?.totalNoTickets}</div>
                                        </div>
                                        <div className="mob-table-sec-expand">
                                            <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                                {expandedRows[index] ? (
                                                    <i className="fa fa-angle-up fa-sizeset"></i>
                                                ) : (
                                                    <i className="fa fa-angle-down fa-sizeset"></i>
                                                )} </a>
                                        </div>

                                    </div>


                                    {(expandedRows[index] && item?.listings?.length > 0) && (
                                        <>

                                            {item?.listings?.map((items, orderIndex) => (
                                                <div className="mob-tbl-inner-sec" key={orderIndex}>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Listing Id</h3>
                                                        <div className={`mob-tbl-value ${nestedExpandedRows[index]?.[orderIndex] ? 'fontWeight' : ''}`}>{items?.listingRef}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Seller</h3>
                                                        <div className="mob-tbl-value text-capitalize txt-ellipsis">{items?.user?.name}</div>
                                                       <div className="pt-2"> <span className='delivery-add-link' onClick={() => handleDeliveryaddressClick(items)}>Seller Details</span></div> 

                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Section & Block</h3>
                                                        <div className="mob-tbl-value"><span title={items?.section?.name} class="text-ellipsis">{items?.section?.name}</span><br></br>{items?.subSection?.name}</div>
                                                        
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">No of Tickets</h3>
                                                        <div className="mob-tbl-value "> <div>Tickets Listed: {items?.Tickets.length}</div>
                                                            <div>Available Tickets: {items?.Tickets?.filter(ticket => ticket?.soldAt === null)?.length}</div></div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Price/Ticket</h3>
                                                        <div className="mob-tbl-value ">{items?.pricePerTicket ? `${strText.currency_symbol}${formatNumber(items?.pricePerTicket)}` : 'N/A'}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Reserved Price</h3>
                                                        <div className="mob-tbl-value ">{items?.reservedPricePerTicket ? `${strText.currency_symbol}${formatNumber(items?.reservedPricePerTicket)}` : 'N/A'}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Face Value</h3>
                                                        <div className="mob-tbl-value ">{items?.facevaluePerTicket ? `${strText.currency_symbol}${formatNumber(items?.facevaluePerTicket)}` : 'N/A'}</div>
                                                    </div>
                                                    <div className="mob-table-sec-action">
                                                        <a onClick={() => setItem(items, 'toggle_status')} title={items?.deactivatedAt == null ? 'Deactivate Listing' : 'Activate Listing'}
                                                            class={items?.deactivatedAt == null ? "btn btn-sm bg-primary text-white btn-icon toggle-listing btn-m-right btn-i-padd" : "btn btn-sm bg-success text-white btn-icon toggle-listing btn-m-right btn-i-padd"}>
                                                            <i class={items?.deactivatedAt == null ? 'fa fa-pause' : 'fa fa-play'}></i>
                                                        </a>
                                                        <a title="Delete Listing"
                                                            onClick={() => setItem(items, 'delete')}
                                                            data-id={items?.id}
                                                            class="btn btn-sm bg-danger btn-icon text-white delete-listing btn-m-right btn-i-padd">
                                                            <i class="fa fa-trash"></i>
                                                        </a>

                                                    </div>
                                                </div>
                                            ))}
                                        </>)}
                                </div>


                            </>

                        ))

                    ) : (
                        <div className="text-center py-4">No Data Found</div>

                    )}

                </div>

            </div>
            {showDbPopup == true &&
                <SellerDeatils setShowDbPopup={setShowDbPopup} showDbPopup={showDbPopup} listing_details={listingAllDetails} selectedItem={selectedItem} />}
            {recordsTotal > 0 ?
                <div className='pagination-wrapper'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={recordsTotal}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
                : ""}

            <div>
                {(show && type == 'delete') &&
                    <DeleteListing listingObj={listingDetails} getListingDetails={getListingDetails} />}
                {(show && type == 'toggle_status') &&
                    <ToggleStatusListing listingObj={listingDetails} getListingDetails={getListingDetails} />}
            </div>
        </>
    );
}
