import React, { useEffect } from 'react'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, clearResetBot, onGetProfileListings, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading, setDetails } from '../../../../config/redux/slice/customerSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { salesProfileDatatable } from '../../../../helpers/customer_dataTables'
import { Link, useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import { get_delete_request } from '../../../../helpers/request'

export default function SalesTable() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {listingId} = useNavigate()

  const {
        token
    } = useSelector((state) => state.authData)

    const {
        customerSuccessMsg,
        profileSaleManagement: {
        newData,
        profileSaleDetails,
        profileSaleData
    },     
    } = useSelector((state) => state.customer)

    useEffect(() => {
      dispatch(clearAll({}))
        // salesProfileDatatable(
        //   profileSaleData,
        // 'salesProfileTable',
        //  setItem
        // )
      getSales()
    },[])

    useEffect(()=>{
      if(newData){
        if(profileSaleData !== "" && profileSaleData !== null && profileSaleData !== "null" && typeof profileSaleData !== "undefined"){
          if(profileSaleData.length > 0){
            if(Object.keys(profileSaleData[0]).includes("orders")){
              console.log('newData.....$$%$%$%......',profileSaleData);
              salesProfileDatatable(
                profileSaleData,
              'salesProfileTable',
              setItem
              )
              dispatch(clearResetBot())
            }
          }
        }    
        
      }      
    }, [newData])


    const setItem = (data, type) => {
      dispatch(setDetails({details: data, type: 'sales'}))
      if(type == 'view-event-items'){
        navigate(app_urls.event_order_items)
      }
    }

    const getSales = async () =>{
      dispatch(clearAll({}))
      const res = await get_delete_request(
        'get',
        token,
        `${app_api.get_user_sales}?page=1&pageSize=5`,
        {},
        dispatch,
        setCustomerLoading,
        onGetProfileSales,
        setCustomerErrorMessage,
        true
      )
    }
  return (
       <DataTableWrapper
      table_id='salesProfileTable' 
      table_heading='Sales'
      table_buttons={<Link to={app_urls.sales} className='text-decoration-none'>View all sales</Link>}
    >
        <thead>
            <tr>
            <th className="wd-15p">No of Listing</th>
               {/* <th className="wd-15p">Order Id</th> */}
                <th className="wd-15p">Event</th>
                  {/* <th className="wd-10p">Ticket No.</th> */}
                <th className="wd-10p">Total Amount</th>
                {/* <th className="wd-10p">Amount to receive</th> */}
                <th className="wd-10p">Delivery Status</th>
                {/* <th className="wd-10p">Sold On</th>
                <th className="wd-10p">Delivered At</th> */}
                {/* <th className="wd-15p">Expand</th> */}
            </tr>
        </thead>
        <tbody>
        </tbody>
    </DataTableWrapper>
  )
}
