import React from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import Spinner from '../../../components/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setAdminErrorMessageUser, setAdminLoadingUser, showSectionModalFunc } from '../../../../config/redux/slice/adminSlice'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useForm } from 'react-hook-form'

export default function EditSection({sectionObj}) {
    const dispatch = useDispatch()
    const {register, handleSubmit, watch, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
  
    const {
            adminLoading,
            adminErrorMsg,
            adminSuccessMsg
    } = useSelector((state) => state.admin)

    const {
        token
    } = useSelector((state) => state.authData)

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        const res = await post_put_patch_request(
          "patch",
            token,
            `${app_api.update_section(sectionObj?.id)}`,
            data,
            dispatch,
            setAdminLoadingUser,
            "",
            setAdminErrorMessageUser
        )
        if(res !== undefined && res.status < 400){
          dispatch(onUpdate({message: res.data.message, type: 'section'}))
        }
    }

    const closeModal = () => {
      dispatch(showSectionModalFunc({modal: false, type: null}))
    }

  return (
    <ModalLayout classStyles={'modal-lg'} onClick={closeModal} heading={'Edit Section'}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="modal-body">
                <div className="px-4 px-md-7">
            <div style={{rowGap: '1.2rem'}} className="row mb-2">

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='name'>Name <span className="text-danger">*</span></label>
                  <input 
                  defaultValue={sectionObj?.name}
                  type="text" 
                  className="form-control input-lg" 
                  id="name"
                  {...register("name", {
                      required: {
                      value: true,
                      message: '*this field is required'
                      }
                  })
                  } 
                  />
                  {
                  errors?.name?.message &&
                  <div className="text-danger small mt-1">
                      {errors?.name?.message}
                  </div>
                  }
              </div> 
              </div>
            </div>

        </div>
            </div>
            <div className="modal-footer border-top-0 d-flex align-items-center">
                <div>
                {adminLoading ? 
                    <Spinner isForm={true}/> :
                    <button type="submit" className="btn btn-primary me-3">Submit Form</button>
                }
                </div>
                <button disabled={adminLoading ? true : false} type="button" onClick={closeModal} className="btn btn-info">Cancel</button>
            </div>
        </form>
    </ModalLayout>
  )
}
