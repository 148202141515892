import React, { useEffect } from 'react'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { refundAdminDatatable } from '../../../../helpers/dataTables'
import { clearAll, clearResetBot, setAdminErrorMessageUser, setDetails, showListingModalFunc, showRefundModalFunc } from '../../../../config/redux/slice/adminSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useDispatch, useSelector } from 'react-redux'
import { get_delete_request } from '../../../../helpers/request'

export default function RefundTable() {
      const dispatch = useDispatch()

  const {
        token
    } = useSelector((state) => state.authData)

    const {
        refundManagement:{newData}
    } = useSelector((state) => state.admin)

    useEffect(() => {
      dispatch(clearAll({}))
        refundAdminDatatable(
          errorFunc,
        'refundAdminTable',
         `${app_api.get_refunds}`,
         null,
         token,
         setItem
        )
        // getAllCountries()
    },[])


    useEffect(()=>{
      if(newData){
          refundAdminDatatable(
          errorFunc,
        'refundAdminTable',
         `${app_api.get_refunds}`,
         null,
         token,
         setItem
        )
          dispatch(clearResetBot())
      }     
    }, [newData])

    const setItem = (data, type) => {
      dispatch(setDetails({details: data, type: 'refund'}))
      dispatch(showRefundModalFunc({modal: true, type}))
    }

    const errorFunc = (msg) => {
      dispatch(setAdminErrorMessageUser({message: msg}))
    }

  return (
    <DataTableWrapper table_id='refundAdminTable' table_heading='Refunds'>
        <thead>
            <tr>
                {/* <th className="wd-5p">S/N</th> */}
                <th className="wd-15p">Order Ref</th>
                <th className="wd-10p">Reason</th>
                <th className="wd-10p">Status</th>
                <th className="wd-10p">Refunded At</th>
                {/* <th className="wd-10p">Requested by</th>
                <th>Email</th>
                <th className="wd-10p">Address</th> */}
                <th className="wd-15p">Actions</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </DataTableWrapper>
  )
}
