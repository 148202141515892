import React from 'react'

export default function InfoComponent({ heading_text, dataList }) {
  return (
    <div className='position-relative bg-secondary py-10 px-md-56'>
      <h2 className='text-center text-primary fw-bold' style={{ fontSize: "22px" }}>{heading_text}</h2>



      <div className="d-flex align-items-center justify-content-center mt-3">
        <ul className="two-column-list">
          {dataList.map((option) => (
            <li key={option.id}>
              <img src={option.icon} alt="" />
              <p>{option.text}</p>
            </li>
          ))}
        </ul>
      </div>


      {/* <div style={{rowGap: '1.7rem'}} className='row mt-4 content-text-center'>
        {dataList.map((option)=>{
        return <div key={option.id} className='col-12 col-md-6 row align-items-center justify-content-center'>
            <img style={{flexBasis: '50px'}} className='col-4' src={option.icon} alt=''/>
            <p className='mb-0 col-8'>{option.text}</p>
        </div>
        })}
        </div> */}
    </div>
  )
}
