import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
//
import appReducer from './slice/appSlice'
import authReducer from './slice/authSlice'
import customerReducer from './slice/customerSlice'
import adminReducer from './slice/adminSlice'
import supportReducer from './slice/supportSlice'
// import bankUserReducer from './slice/bankUserSlice';
// import notapUserReducer from './slice/notapUserSlice';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { appConfig } from '../../config'
import PayoutManagement from '../../app/pages/admin/payouts_management/PayoutManagement'

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [],
}

const appDataPersistConfig = {
  key: 'appData',
  storage: storage,
  whitelist: [
    'breadCrumbName',
    'currentNavDetails',
    'cartManagement',
    'eventManagement',
  ],
  stateReconciler: autoMergeLevel2,
}

const authPersistConfig = {
  key: 'authData',
  storage: storage,
  whitelist: ['token', 'user_role', 'userData', 'email'],
  stateReconciler: autoMergeLevel2,
}

const customerDataPersistConfig = {
  key: 'customerData',
  storage: storage,
  whitelist: [
    'eventManagement',
    'listingManagement',
    'sectionManagement',
    'orderManagement',
    'salesManagement',
    'paymentManagement',
    'lastVisitedPage',
  ],
  stateReconciler: autoMergeLevel2,
}

const adminDataPersistConfig = {
  key: 'adminData',
  storage: storage,
  whitelist: [
    'venueManagement',
    'sectionManagement',
    'subSectionManagement',
    'orderManagement',
    'payoutManagement',
  ],
  stateReconciler: autoMergeLevel2,
}

const supportDataPersistConfig = {
  key: 'supportData',
  storage: storage,
  whitelist: [],
  stateReconciler: autoMergeLevel2,
}

const rootReducer = combineReducers({
  app: persistReducer(appDataPersistConfig, appReducer),
  authData: persistReducer(authPersistConfig, authReducer),
  support: persistReducer(supportDataPersistConfig, supportReducer),
  customer: persistReducer(customerDataPersistConfig, customerReducer),
  admin: persistReducer(adminDataPersistConfig, adminReducer),
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: appConfig.appEnv !== 'production',
  middleware: [thunk],
})

export const persist = persistStore(store)
