import React, { useState , useEffect} from 'react';
import { app_api } from '../../../../utils/endpoints/app_api'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';

export default function ViewComplaints({ showViewComplaintPopup, setShowViewComplaintPopup, selectedViewItem }) {
  const { token } = useSelector((state) => state.authData)
  const dispatch = useDispatch()

  // useEffect(async () => {
  //   console.log(selectedItem);
  //   // const orderId = selectedItem.id;


  // });
  const closeDbPopup = () => {
    setShowViewComplaintPopup(false);
  };

 
console.log(selectedViewItem);
console.log(typeof selectedViewItem);
// console.log('showViewComplaintPopup', showViewComplaintPopup);
//   // Ensure selectedItem is an array
  // const complaints = Array.isArray(selectedViewItem) ? selectedViewItem : [];

  // if (complaints.length === 0) {
  //   return null; // Or handle this case differently based on your UI requirements
  // }

return (
  <div id="demo-modal" className="modal delivery-addresssec" style={{ display: showViewComplaintPopup ? 'flex' : 'none' }}>
    <div className="modal__content">
      <div className="modalFields">
        <div className='model-sec model-sec-scroll' style={{ background: "transparent" }}>
        <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Reported By</th>
                  <th>Reported Date</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {selectedViewItem?.data?.length > 0 ? (
                  selectedViewItem.data.map((complaint, index) => (
                    <tr key={index}>
                      <td style={{ color: complaint.hasBuyer ? 'blue' : 'red' }}>
                        {complaint.hasBuyer ? 'Buyer' : 'Seller'}
                      </td>
                      <td>{moment(complaint.date).format('DD MMM YYYY')}</td>
                      <td><div className="text-ellipse-comments" title={complaint.description}>{complaint.description}</div></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" style={{ textAlign: 'center' }}>No data found</td>
                  </tr>
                )}
              </tbody>
            </table>
        </div>
      </div>
      <a onClick={closeDbPopup} className="modal__close">
        <i className="fa fa-close"></i>
      </a>
    </div>
  </div>
);
}
