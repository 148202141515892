import React, { useEffect, useState } from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logoutAppUser, onAddToCart, onGetCart, onRemoveFromCart, setAppErrorMessage, setAppLoading, setCartValues, setData, setShouldModifyCart, showCartModalFunc } from '../../../../config/redux/slice/appSlice'
import { app_urls } from '../../../../utils/urls/app_urls'
import { clearAll, logoutCustomerUser, setCustomerErrorMessage, setCustomerLoading, setLastVisitedPage } from '../../../../config/redux/slice/customerSlice'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'
import { logoutUser } from '../../../../config/redux/slice/authSlice'
import { logoutAdminUser } from '../../../../config/redux/slice/adminSlice'
import { formatNumber, setCartNo, transformTicketsList } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'

export default function CartModal({pagePath}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

     const [openSeat, setOpenSeat] = useState(false)
      const [listingId, setListingId] = useState('')
      const [isRemovingFromCart, setIsRemovingFromCart] = useState({})

    const {
      appLoading,
      cartManagement:{cartData, cartNumber, listingsInCart}
    } = useSelector((state) => state.app)
    const {
      customerLoading
    } = useSelector((state) => state.customer)

    const {
        token
    } = useSelector((state) => state.authData)

    useEffect(()=>{

      getCart()
    }, [])

    const closeModal = ()=>{
         dispatch(showCartModalFunc({modal: false, type: null}))
    }

    // const removeFromCart = (id, sellTogether) => {

    //   dispatch(onRemoveFromCart({id, sellTogether}))
    // }

    const proceed = ()=>{
      navigate(app_urls.buy_ticket)
    }

    const getCart = async () =>{
      dispatch(clearAll({}))
      const res = await get_delete_request(
        'get',
        token,
        `${app_api.get_cart}`,
        {},
        dispatch,
        "",
        onGetCart,
        setCustomerErrorMessage
      )
      if(res !== undefined && res.status < 400){
        let cartNo = 0
        dispatch(setData({data: res?.data?.data, type: 'cart'}))
        res?.data?.data.forEach((opt)=>{
          if(opt.sellingOption == 'ANY'){
            opt.Tickets.forEach((ticketOpt)=>{
              cartNo += 1
            })
          }
          else{
            let arr =  opt.Tickets.map((option)=>{
              return option.row
            })
            const uniqueSet = new Set(arr);

            // Convert the Set back to an array
            const uniqueArray = Array.from(uniqueSet);
            uniqueArray.forEach((ticketOpt)=>{
              cartNo += 1
            })
          }
          // else{
          //   cartNo += 1
          // }
        })
        dispatch(setCartValues({propertyKey: 'cartNumber', value: cartNo}))
      }else if(res !== undefined && res.status == 401){
        dispatch(setLastVisitedPage(pagePath))

        logout()
      }
    }

      const setCartData = async (data) =>{
          dispatch(onGetCart(data))
          dispatch(setData({data: data, type: 'cart'}))
          let cartNo = setCartNo(data)
          dispatch(setCartValues({propertyKey: 'cartNumber', value: cartNo}))
      }

    const removeFromCart = async (ticketDetails, sellTogether, index) => {
        dispatch(clearAll({}))
        setIsRemovingFromCart({
          [index]: true
        })
          let objId = ticketDetails.custom_id
          // dispatch(setCartValues({propertyKey: 'cartNumber', value: cartNumber - 1}))
          let ticketIds = []
            ticketDetails.Tickets.forEach((ticketOption)=>{
              ticketIds = [...ticketIds, ticketOption.id]
            })
          // dispatch(onRemoveFromCart({id:objId, sellTogether, listing: ticketDetails, ticketIds}))
            let  data = {ticketIds}
              // dispatch(setFormData({data, type: 'listing'}))
                const res = await post_put_patch_request(
                    "patch",
                      token,
                      `${app_api.remove_from_cart}`,
                      data,
                      dispatch,
                      setAppLoading,
                      "",
                      setAppErrorMessage
                  )
              if(res !== undefined && res.status < 400){
                setCartData(res?.data?.data)
                setIsRemovingFromCart({
                    [index]: false
                  })
                // dispatch(onGetCart(res?.data?.data))
                // dispatch(onRemoveFromCart({id:objId, sellTogether, listing: ticketDetails, ticketIds}))
                // dispatch(setCartValues({propertyKey: 'cartNumber', value: cartNumber - 1}))
              }else if(res !== undefined && res.status == 401){
                // dispatch(setCartValues({propertyKey: 'cartNumber', value: cartNumber}))
                  // dispatch(onAddToCart({ticketDetails, sellTogether}))
                  dispatch(setLastVisitedPage(pagePath))

                logout()
              }else {
                setIsRemovingFromCart({
                  [index]: false
                })
              }
    }

    const logout = () => {
        dispatch(logoutUser())
        dispatch(logoutCustomerUser())
        dispatch(logoutAppUser())
        dispatch(logoutAdminUser())
        navigate(app_urls.login)
    }
    const passingData = "cart";
  return (
    
    <ModalLayout onClick={closeModal} heading={<><span className="fw-bold text-dark me-2">Cart</span> <span className="badge bg-warning rounded-pill">{cartNumber}</span></>}>
        <div className="modal-body">
          <>
          {cartData?.length != 0 ?
            <>
            {console.log("...............xdsd",cartData)}
            
              {cartData.map((listingOption, ind)=>{
                return <React.Fragment key={ind}>


                  {transformTicketsList(listingOption?.Tickets, listingOption,passingData).map((option, index)=>{
                    return <React.Fragment key={option.id}>
                    <>
                    <div className='mb-4'>
                      <span className={`badge text-white me-2 mb-1 ${option?.ticketIds.length > 1 ? 'bg-success' : 'bg-danger'}`}>
                        {option?.ticketIds.length > 1 ? 'Group' : 'Single'}
                      </span>
                     
                      <div className='pb-4 px-4 pt-4 shadow position-relative'>
                        <p className="ticket-text mb-2"><span className="fw-bold">Event: </span>
                                          {listingOption?.event?.name}</p>
                        <p className="ticket-text mb-2"><span className="fw-bold">Section: </span>
                                          {listingOption?.section?.name}</p>
                        {option?.ticketIds.length > 1 ?
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-column gap-2">
                                  <p className="ticket-text mb-2"><span className="fw-bold">Block </span>
                                      {listingOption?.subSection?.name}</p>
                              <p className="ticket-text mb-2"><span className="fw-bold">No. of Tickets </span>
                                      {option?.numberOfTickets}</p> 
                              <div className='d-flex gap-2 align-items-center'>
                                <p className="ticket-text fw-bold">Seats</p>
                                <button 
                                  onClick={()=>{
                                    setOpenSeat(!openSeat)
                                    setListingId(option.id)
                                  }} 
                                  className="btn btn-sm ticket-btn py-0"
                                >
                                  View
                                  <i
                                      className={`mdi mdi-chevron-down ms-1 fs-6`}></i>
                                </button>
                              </div>        
                          </div>
                          <div className="d-flex flex-column align-items-center gap-2">
                            <div className="d-flex flex-column gap-2">
                          {listingOption?.pricePerTicket == option.price ?
                             <>
                            <p className="ticket-text mb-2"><span className="fw-bold">Price </span>
                                    {strText.currency_symbol}{formatNumber(listingOption?.pricePerTicket)}</p>
                              <p className="ticket-text mb-2"><span className="fw-bold">Total Price </span>
                                    {strText.currency_symbol}{formatNumber(Number(option?.numberOfTickets) * Number(listingOption?.pricePerTicket))}</p>
                              </> 
                              :
                            <>
                            <p className="ticket-text mb-2 position-relative"><span className="fw-bold">Price </span>
                            <span className='bottom-50 mb-1 position-absolute'>{strText.currency_symbol}{formatNumber(option?.price)}</span>
                            <span className='text-decoration-line-through'>{strText.currency_symbol}{formatNumber(listingOption?.pricePerTicket)}</span></p>
                              <p className="ticket-text mb-2 position-relative"><span className="fw-bold">Total Price </span>
                                  <span className='bottom-50 mb-1 position-absolute'>{strText.currency_symbol}{formatNumber(Number(option?.numberOfTickets) * Number(option?.price))}</span>
                                    <span className='text-decoration-line-through'>{strText.currency_symbol}{formatNumber(Number(option?.numberOfTickets) * Number(listingOption?.pricePerTicket))}</span>
                            </p>
                              </> 
                          } 
                          </div>
                              <button 
                              style={{minWidth: '70px'}}
                                onClick={()=>{
                                  removeFromCart(
                                    {...listingOption, custom_id: option.id, Tickets: option.detailsList}, 
                                    'neither', index)
                                }} 
                                className={`btn ticket-btn bg-danger text-white`}
                                disabled={appLoading}
                              >
                                {isRemovingFromCart?.[index] ? <Spinner color='text-white' spinner_styles={{width: '1rem', height: '1rem'}}/> :
                                    'Remove'}
                              </button>
                          </div>
                      </div>
                      :
                      <div className="d-flex justify-content-between">
                              <div className="d-flex flex-column gap-2">
                                      <p className="ticket-text mb-2"><span className="fw-bold">Block </span>
                                          {listingOption?.subSection?.name}</p>
                                  <p className="ticket-text"><span className="fw-bold">Seat</span> {option?.seatNos[0]}</p>
                              </div>
                              <div className="d-flex flex-column align-items-center gap-2">
                                {listingOption?.pricePerTicket == option.price ?
                                <p className="ticket-text mb-2"><span className="fw-bold">Price </span>
                                          {strText.currency_symbol}{formatNumber(listingOption?.pricePerTicket)}</p>
                                 :
                                 <p className="ticket-text mb-2 position-relative"><span className="fw-bold">Price </span>
                                  <span className='bottom-50 mb-1 position-absolute'>{strText.currency_symbol}{formatNumber(option.price)}</span>
                                  <span className='text-decoration-line-through'>{strText.currency_symbol}{formatNumber(listingOption?.pricePerTicket)}</span></p>         
                                }
                                     <button 
                                     style={{minWidth: '70px'}}
                                onClick={()=>{
                                  removeFromCart(
                                    {...listingOption, custom_id: option.id, Tickets: option.detailsList}, 
                                    'neither', index)
                                }} 
                                className={`btn ticket-btn bg-danger text-white`}
                                disabled={appLoading}
                              >
                                {isRemovingFromCart?.[index] ? <Spinner color='text-white' spinner_styles={{width: '1rem', height: '1rem'}}/> :
                                    'Remove'}
                              </button>
                              </div>
                          </div>
                        }
                      {(openSeat && listingId == option.id) &&
                        <>
                          <hr className="my-4"/>
                            <h6 className='fw-bold mx-4'>Seat Numbers</h6>
                            <div className='px-4'>
                              {option?.seatNos?.map((singleItem, idx)=>{
                                return <span key={idx} className="badge text-white bg-accent me-2">{singleItem}</span>
                              })}
                            </div>
                        </>
                      }
                      </div>
                    </div>
                    </>
                  </React.Fragment>
                  })}
              
                  </React.Fragment>
              })}
            </>
            :
            <p className='text-muted'>No Items In Cart</p>
          }
          </>
        </div>
        {cartData.length != 0 &&
          <div className="modal-footer pt-0 border-top-0 d-flex align-items-center">
              <div>
                <button onClick={proceed} type="button" className="btn btn-primary me-3">Proceed to checkout</button>
              </div>
              <button onClick={closeModal} type="button" className="btn btn-info">Cancel</button>
          </div>
        }
    </ModalLayout>
  )
}
