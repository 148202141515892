import moment from 'moment'
import { strText } from '../constants/english'
import { buildAddress, formatNumber } from './helpers'
// import Popper from "popper.js";

const $ = require('jquery') // 1
$.DataTable = require('datatables.net-dt') //2
$.DataTable = require('datatables.net-responsive-dt') //3
$.Database = require('datatables.net-responsive-dt/css/responsive.dataTables.css') //3
//
$.DataTable = require('datatables.net-buttons/js/buttons.html5.js') // 4:: loads the copy,pdf,csv buttons and makes it works
$.DataTable = require('datatables.net-buttons/js/buttons.print.js') // 4::loads the print btn and makes it work
//
$.DataTable = require('datatables.net-bs5') //5

export const userAdminDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItems,
  userTypes // New parameter for user types
) => {

  const cols = [
    {
      data: 'name',
    },

    { data: 'email' },
    {
      data: (a) => {
        return a?.phone;
      },
    },
    {
      data: (a) => {
        return a?.isSeller ? 'Seller' : 'Buyer';
      },
    },

    {
      data: (a) => {
        const preUserType = a?.userType;
        const options = userTypes.map(type =>
          `<option value="${type}" ${type === preUserType ? 'selected' : ''}>${type}</option>`
        ).join('');

        return `
          <div>
            <select data-id=${a?.id} class="btn btn-sm select-setuserType btn-icon change-user-type">
              ${options}
            </select>
          </div>
        `;
      },
    },

    {
      data: (a) => {
        return moment(a?.createdAt).format('ddd DD MMM YYYY').toUpperCase()
      },
    },
    {
      data: (a) => {
        return `
                    <div class='text-wrap table-column-dimension'>
                    ${buildAddress({
          houseName: a?.houseName,
          firstLineAddress: a?.firstLineAddress,
          secondLineAddress: a?.secondLineAddress,
          town: a?.town,
          postCode: a?.postCode,
          country: a?.country?.name,
        })}
                    </div>`
      },
    },
    {
      data: (a) => {
        return a?.BankDetails && a.BankDetails?.length > 0 ? a.BankDetails[0]?.bankName : '';
      },
    },
    {
      data: (a) => {
        return a?.BankDetails && a.BankDetails?.length > 0 ? a.BankDetails[0]?.accountName : '';
      },
    },
    {
      data: (a) => {
        return a?.BankDetails && a.BankDetails?.length > 0 ? a.BankDetails[0]?.IBAN : '';
      },
    },
    {
      data: (a) => {
        return a?.BankDetails && a.BankDetails?.length > 0 ? a.BankDetails[0]?.accountNo : '';
      },
    },       
    {
      data: (a) => {
        return a?.BankDetails && a.BankDetails?.length > 0 ? a.BankDetails[0]?.sortCode : '';
      },
    },
    
    {
      data: (a) => {
        return `
                     <a title="Suspend User" data-id=${a?.id} class="btn btn-sm bg-dark text-white btn-icon suspend-user">
                                <i class="fa fa-times"></i>
                            </a>
                        <a title="Delete User" data-id="${a?.id}"
                                class="btn btn-sm bg-danger btn-icon text-white delete-user">
                                <i class="fa fa-trash"></i>
                            </a>
                        `
      },
      responsivePriority: 2,
    },
  ]

  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Users',
    [0, 1, 2, 3],
    [0, 'desc'],
    'Users',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    $(document).on('click', '.delete-user', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'delete')
      }
    })

    $(document).on('click', '.suspend-user', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'suspend')
      }
    })

    // $(document).on('change', '.change-user-type', function () {
    //   const r = $(this).attr('data-id')
    //   const newType = $(this).val();
    //   let x = data.filter((item) => item?.id === r)
    //   console.log("consilexx",x);
    //   if (x.length > 0) {
    //     setItems(x[0], 'changeusertype')
    //   }
    // })

    $(document).on('change', '.change-user-type', function () {
      const userId = $(this).attr('data-id');
      const newType = $(this).val();
      const user = data.find(item => item.id === userId);
      if (user) {
        const updatedUser = { ...user, userType: newType };
        setItems(updatedUser, 'changeusertype');
        // Optionally, send updated user info to the server here
        console.log('Updated user:', updatedUser);
      }
    });

  }
}

export const adminAdminDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItems
) => {
  const cols = [
    {
      data: 'name',
    },

    { data: 'email' },

    { data: 'role' },

    { data: 'address' },
    {
      data: (a) => {
        return moment(a?.createdAt).format('ddd DD MMM YYYY').toUpperCase()
      },
    },
    // {
    //     data: (a) => {
    //         return (
    //              `
    //              <a class="btn btn-sm btn-dark btn-icon">
    //                         <i class="fa fa-times"></i>
    //                     </a>
    //                 <a
    //                         class="btn btn-sm bg-warning hover:bg-yellow-500 hover:bg-opacity-80 text-white btn-icon border-none">
    //                         <i class="fa fa-pencil"></i>
    //                     </a>
    //                     <a class="btn btn-sm btn-dark btn-icon">
    //                         <i class="fa fa-cog"></i>
    //                     </a>
    //                     <a class="btn btn-sm btn-warning btn-icon">
    //                         <i class="fa fa-feed"></i>
    //                     </a>
    //                     <a class="btn btn-sm btn-success btn-icon">
    //                         <i class="fa fa-share-alt"></i>
    //                     </a>
    //                 `
    //             )
    //     },
    //     responsivePriority: 2
    // }
  ]
  let callbackFunc = null
  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Admins',
    [0, 1, 2, 3],
    [0, 'desc'],
    'Admins',
    endpoint,
    searchEndPoint,
    token
  )

  // $(document).on("click", ".certificate-edit-eval", function () {
  //     const r = $(this).attr("data-id")
  //     let x = data.filter((item) => Number(item?.ID) === Number(r))
  //     if (x.length > 0) {
  //         setItems(x[0], 'edit-eval')
  //     }
  // })

  // $(document).on("click", ".certificate-send-eval", function () {
  //     const r = $(this).attr("data-id")
  //     let x = data.filter((item) => Number(item?.ID) === Number(r))
  //     if (x.length > 0) {
  //         setItems(x[0], 'send-eval')
  //     }
  // })

  // $(document).on("click", ".evaluation-view-details", function () {
  //     const r = $(this).attr("data-id")
  //     let x = data.filter((item) => Number(item?.ID) === Number(r))
  //     if (x.length > 0) {
  //         setItems(x[0], 'eval-details')
  //     }
  // })
}

export const countryAdminDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItems
) => {
  const cols = [
    {
      data: 'name',
    },

    // {data: 'flag'},

    {
      data: (a) => {
        return `<img src=${a?.flag} width='60' height='40'/>`
      },
    },

    {
      data: (a) => {
        return moment(a?.createdAt).format('ddd DD MMM YYYY').toUpperCase()
      },
    },
    {
      data: (a) => {
        return `
                        <a
                                title="Edit Country"
                                data-id="${a?.id}"
                                class="btn btn-sm bg-grey btn-icon text-white edit-country">
                                <i class="fa fa-pencil"></i>
                            </a>
                        <a title="Delete Country" data-id="${a?.id}"
                                class="btn btn-sm bg-danger btn-icon text-white delete-country">
                                <i class="fa fa-trash"></i>
                            </a>    
                        `
      },
      responsivePriority: 2,
    },
  ]

  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Countries',
    [0, 2],
    [0, 'desc'],
    'Countries',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    $(document).on('click', '.delete-country', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'delete')
      }
    })

    $(document).on('click', '.edit-country', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'edit')
      }
    })
  }
}

export const teamAdminDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItems
) => {
  const cols = [
    {
      data: 'name',
    },

    // {data: 'flag'},

    {
      data: (a) => {
        return `<img src='${a?.logo}' crossorigin=anonymous width='60' height='40'/>`
      },
    },

    // {
    //     data: (a) => {
    //         return `<img src=${a?.logo} width='60' height='40'/>`
    //     }
    // },

    {
      data: (a) => {
        return moment(a?.createdAt).format('ddd DD MMM YYYY').toUpperCase()
      },
    },
    {
      data: (a) => {
        return `
                     <a
                                title="Edit Team"
                                data-id="${a?.id}"
                                class="btn btn-sm bg-grey btn-icon text-white edit-team">
                                <i class="fa fa-pencil"></i>
                            </a>
                        <a title="Delete Team" data-id="${a?.id}"
                                class="btn btn-sm bg-danger btn-icon text-white delete-team">
                                <i class="fa fa-trash"></i>
                            </a> 
                        `
      },
      responsivePriority: 2,
    },
  ]
  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Teams',
    [0, 2],
    [0, 'desc'],
    'Teams',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    $(document).on('click', '.delete-team', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'delete')
      }
    })

    $(document).on('click', '.edit-team', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'edit')
      }
    })
  }
}

export const competitionAdminDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItems
) => {
  const cols = [
    {
      data: 'name',
    },

    {
      data: (a) => {
        return moment(a?.createdAt).format('ddd DD MMM YYYY').toUpperCase()
      },
    },
    {
      data: (a) => {
        return `
                      <a
                            title="Edit Competition"
                            data-id="${a?.id}"
                            class="btn btn-sm bg-grey btn-icon text-white edit-competition">
                            <i class="fa fa-pencil"></i>
                        </a>
                    <a title="Delete Competition" data-id="${a?.id}"
                            class="btn btn-sm bg-danger btn-icon text-white delete-competition">
                            <i class="fa fa-trash"></i>
                        </a> 
                        `
      },
      responsivePriority: 2,
    },
  ]

  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Competitions',
    [0, 1],
    [0, 'desc'],
    'Competitions',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    $(document).on('click', '.delete-competition', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'delete')
      }
    })

    $(document).on('click', '.edit-competition', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'edit')
      }
    })
  }
}

export const cityAdminDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItems
) => {
  const cols = [
    {
      data: 'name',
    },

    // {data: 'flag'},

    {
      data: (a) => {
        return a?.country.name
      },
    },

    {
      data: (a) => {
        return `<img src=${a?.country.flag} width='60' height='40'/>`
      },
    },

    {
      data: (a) => {
        return moment(a?.createdAt).format('ddd DD MMM YYYY').toUpperCase()
      },
    },
    {
      data: (a) => {
        return `
                    <a
                            title="Edit City"
                            data-id="${a?.id}"
                            class="btn btn-sm bg-grey btn-icon text-white edit-city">
                            <i class="fa fa-pencil"></i>
                        </a>
                    <a title="Delete City" data-id="${a?.id}"
                            class="btn btn-sm bg-danger btn-icon text-white delete-city">
                            <i class="fa fa-trash"></i>
                        </a>
                        `
      },
      responsivePriority: 2,
    },
  ]

  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Cities',
    [0, 1, 3],
    [0, 'desc'],
    'Cities',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    $(document).on('click', '.delete-city', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'delete')
      }
    })

    $(document).on('click', '.edit-city', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'edit')
      }
    })
  }
}

export const stadiumAdminDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItems
) => {
  const cols = [
    {
      data: 'name',
    },
    {
      data: (a) => {
        if (a?.address && a?.address !== '') {
          return `
                    <div class='text-wrap table-column-dimension'>
                    ${a?.address}
                    </div>`
        } else {
          return 'N/A'
        }
      },
    },

    {
      data: (a) => {
        return a?.city.name
      },
    },

    // {data: 'country'},
    {
      data: (a) => {
        return `<img src='${a?.image}' crossorigin='anonymous' width='80' height='80'/>`
      },
    },
    {
      data: (a) => {
        return moment(a?.createdAt).format('ddd DD MMM YYYY').toUpperCase()
      },
    },
    {
      data: (a) => {
        return `
                     <a
                                title="Edit Venue"
                                data-id="${a?.id}"
                                
                                class="btn btn-sm bg-grey btn-icon text-white edit-venue">
                                <i class="fa fa-pencil"></i>
                            </a>
                        <a title="Delete Venue" data-id="${a?.id}"
                                class="btn btn-sm bg-danger btn-icon text-white delete-venue">
                                <i class="fa fa-trash"></i>
                            </a> 
                            <a title='Manage Sections' data-id=${a?.id} class="btn btn-sm bg-primary text-white btn-icon stadium-manage-sections">
                                <i class="fa fa-bars"></i>
                            </a>
                        `
      },
      responsivePriority: 2,
    },
  ]

  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Venues',
    [0, 1, 2, 4],
    [0, 'desc'],
    'Venues',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    $(document).on('click', '.stadium-manage-sections', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'manage-sections')
      }
    })
    $(document).on('click', '.delete-venue', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'delete')
      }
    })

    $(document).on('click', '.edit-venue', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'edit')
      }
    })
  }
}

export const sectionAdminDatatable = (data, table_id, setItems) => {
  const cols = [
    {
      data: 'name',
    },
    {
      data: (a) => {
        return moment(a?.createdAt).format(strText.full_date)
      },
    },
    {
      data: (a) => {
        return `
                     <a
                            title="Edit Section"
                            data-id="${a?.id}"
                            class="btn btn-sm bg-grey btn-icon text-white edit-section">
                            <i class="fa fa-pencil"></i>
                        </a>
                    <a title="Delete Section" data-id="${a?.id}"
                            class="btn btn-sm bg-danger btn-icon text-white delete-section">
                            <i class="fa fa-trash"></i>
                        </a>
                            <a title='Manage Subsections' data-id=${a?.id} class="btn btn-sm bg-primary text-white btn-icon section-manage-subsections">
                                <i class="fa fa-bars"></i>
                            </a>
                        `
      },
      responsivePriority: 2,
    },
  ]

  generalDatatableConfig(
    data,
    table_id,
    cols,
    'Sections',
    [0, 1],
    [0, 'desc'],
    'Sections'
  )

  $(document).on('click', '.section-manage-subsections', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'manage-subsections')
    }
  })
  $(document).on('click', '.edit-section', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'edit')
    }
  })
  $(document).on('click', '.delete-section', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'delete')
    }
  })
}

export const subsectionAdminDatatable = (data, table_id, setItems) => {
  const cols = [
    {
      data: 'name',
    },
    {
      data: (a) => {
        return moment(a?.createdAt).format(strText.full_date)
      },
    },
    {
      data: (a) => {
        return `
                     <a
                            title="Edit Section"
                            data-id="${a?.id}"
                            class="btn btn-sm bg-grey btn-icon text-white edit-subsection">
                            <i class="fa fa-pencil"></i>
                        </a>
                    <a title="Delete Section" data-id="${a?.id}"
                            class="btn btn-sm bg-danger btn-icon text-white delete-subsection">
                            <i class="fa fa-trash"></i>
                        </a>
                        `
      },
      responsivePriority: 2,
    },
  ]

  generalDatatableConfig(
    data,
    table_id,
    cols,
    'Subsection',
    [0, 1],
    [0, 'desc'],
    'Subsection'
  )

  $(document).on('click', '.edit-subsection', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'edit')
    }
  })
  $(document).on('click', '.delete-subsection', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItems(x[0], 'delete')
    }
  })
}

export const eventAdminDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItems
) => {
  const cols = [
    {
      data: 'name',
    },

    {
      data: (a) => {
        return `
                    <div class='text-wrap'>
                    ${a?.description}
                    </div>`
      },
    },

    {
      data: (a) => {
        return a?.Venue.name
      },
    },
    {
      data: (a) => {
        return `<img src='${a?.poster}' crossorigin='anonymous' width='80' height='80'/>`
      },
    },
    {
      data: 'listingCount',
    },
    {
      data: 'orderCount',
    },
    {
      data: (a) => {
        return a?.userType
      },
    },
    {
      data: (a) => {
        return `
                         <a
                                title="Edit Event"
                                data-id="${a?.id}"
                                class="btn btn-sm bg-warning btn-icon text-white edit-event">
                                <i class="fa fa-pencil"></i>
                            </a>
                        <a title="Delete Event" data-id="${a?.id}"
                                class="btn btn-sm bg-danger btn-icon text-white delete-event">
                                <i class="fa fa-trash"></i>
                            </a> 
                            ${a?.addTrending === true ?
            ` <div class="tag">Trending</div>`
            : ""}

                        `
      },
      responsivePriority: 2,
    },
  ]
  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Events',
    [0, 1, 2, 4],
    [0, 'desc'],
    'Events',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    $(document).on('click', '.delete-event', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'delete')
      }
    })

    $(document).on('click', '.edit-event', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'edit')
      }
    })
  }
}

export const payoutDueAdminDatatable = (
  //data, table_id, setItems
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItems,
) => {
  const cols = [
    {
      data: (a) => {
        return moment(a?.date).format('ddd DD MMM YYYY').toUpperCase()
      },
    },
    {
      data: (a) => {
        return `<img src='${a?.poster}' crossorigin='anonymous' width='40' height='40'/>`
      },
    },
    {
      data: 'name',
    },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(a?.amount?.dueAmount)}`
      },
    },

    {
      data: (a) => {
        return a?.amount?.payoutInitiated
      },
    },


    // {data: 'time'},
    {
      data: (a) => {
        return `
                         <a
                                title="Manage Payouts"
                                data-id="${a?.id}"
                                class="btn btn-sm bg-primary btn-icon text-white manage-payout">
                                <i class="fa fa-bars"></i>
                            </a>
                        `
      },
      responsivePriority: 2,
    },
  ]

  // generalDatatableConfig(
  //   data,
  //   table_id,
  //   cols,
  //   'Payouts Due',
  //   [0, 1, 3],
  //   [0, 'desc'],
  //   'Payouts Due'
  // )
  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Payouts Due',
    [0, 1, 2, 3],
    [0, 'desc'],
    'Payouts Due',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    $(document).on('click', '.manage-payout', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'manage')
      }
    })
  }
}

export const payoutAdminDatatable = (
  // data, table_id, setItems, statusType, payoutEventId
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItems,
  //statusType,
  payoutEventId
) => {
  const cols = [

    {
      data: (a) => {
        return a?.user?.name
      },
    },
    {
      data: (a) => {
        return `<span class="text-ellipsis" title="${a?.user?.email}">${a?.user?.email} </span>`
      },
    },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(a?.amount)}`
      },
    },
    {
      data: (a) => {
        const dueAmountRes = (a?.finalAmount == 0 || a?.finalAmount == "") ? a?.amount : a?.finalAmount;
        let content = `<div class="input-group mb-4">`;
    
        if (a?.status !== "PAID") {
          content += `
            <div class="input-group-prepend d-flex align-items-center">
              <span class="">${strText.currency_symbol} </span>
              <input data-id="${payoutEventId}" class="form-control input-formamount change-amount" data-name="${a?.userId}" data-fname="${a?.user?.name}" type="text" value="${formatNumber(dueAmountRes)}" placeholder="Amount">
              <div class="input-group-append save-btnamount">
                <button class="btn btn-primary save-amount" data-id="${payoutEventId}" data-name="${a?.userId}" data-fname="${a?.user?.name}">Save</button>
              </div>
            </div>`;
        } else {
          content += `
             <label>${strText.currency_symbol} ${formatNumber(a?.finalAmount)}</label>
          `;
        }
    
        content += `</div>`;
        return content;
      },
    },
    
    // {
    //   data: (a) => {
    //     return `
    //                 <div class='text-wrap table-column-dimension'>
    //                 ${buildAddress({
    //       houseName: a?.user?.houseName,
    //       firstLineAddress: a?.user?.firstLineAddress,
    //       secondLineAddress: a?.user?.secondLineAddress,
    //       town: a?.user?.town,
    //       postCode: a?.user?.postCode,
    //       country: a?.user?.country?.name,
    //     })}
    //                 </div>`
    //   },
    // },
    {
      data: (a) => {
        if (a?.status == "NOT_INITIATED") {
        return "NOT INITIATED"
        } else {
          return a?.status ?? ''
        }
      },
    },
    {
      data: (a) => {
        if (a?.status !== "PAID") {
          //const statusTypes = a?.statusTypes;
          const statusTypes = ["PROCESS", "HOLD", "SUSPEND"];
          const preStatusType = a?.statusType;
          // const options = a?.statusTypes.map(type =>
          //   `<option value="${type}" ${type === preStatusType ? 'selected' : ''}>${type}</option>`
          // ).join('');
          const options = statusTypes.map(type =>
            `<option value="${type}" ${type === preStatusType ? 'selected' : ''}>${type}</option>`
          ).join('');

          return `
              <div>
                <select data-id=${payoutEventId} data-name=${a?.userId} data-fname=${a?.user?.name} class="btn btn-sm select-setuserType btn-icon change-status-type">
                  ${options}
                </select>
              </div>
            `;

        } else {
          return ``;

        }

      },
    },

  ]
  // generalDatatableConfig(
  //   data,
  //   table_id,
  //   cols,
  //   'Payouts',
  //   [0, 1, 2, 3],
  //   [0, 'desc'],
  //   'Payouts'
  // )
  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Payouts',
    [0, 1, 2, 3],
    [0, 'desc'],
    'Payouts',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {

    $(document).on('change', '.change-status-type', function () {
      const eventId = $(this).attr('data-id');
      const userId = $(this).attr('data-name');
      const sellerName = $(this).attr('data-fname');
      const newType = $(this).val();
      if (eventId) {
        const updatedInfo = { sellerName: sellerName, userId: userId, eventId: eventId, statusType: newType };
        setItems(updatedInfo, 'changstatustype');
      }
    });

    // Handle save button click event
    $(document).on('click', '.save-amount', function () {
      const eventId = $(this).attr('data-id');
      const userId = $(this).attr('data-name');
      const sellerName = $(this).attr('data-fname');
      const newAmount = $(this).closest('.input-group').find('.change-amount').val();

      // Ensure only numbers are allowed
      const isNumeric = /^[0-9,]*\.?[0-9]+$/.test(newAmount);

      if (eventId && isNumeric) {
        const changeInfo = { sellerName: sellerName, userId: userId, eventId: eventId, newAmount: newAmount };
        setItems(changeInfo, 'change_amount');
      } else {
        alert('Please enter a valid number.');
      }
    });
  }
}

export const listingAdminDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItems
) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    return formatter.format(date);
  };
  const cols = [
    {
      data: (a) => {
        return a?.listings?.length || 0;
      },
    },
    {
      data: (a) => {
        //return a?.event?.name
        return a?.name
      },
    },
    {
      data: (a) => {
        //return a?.event?.name
        return formatDate(a?.date)
      },
    },
    {
      data: (a) => {
        //return a?.event?.name
        return a?.totalNoTickets
      },
    },
    {
      data: (a) => {
        //return a?.event?.name
        return formatNumber(a?.totalPricePerTicketWithoutCommission)
      },
    },
    {
      data: (a) => {
        //return a?.event?.name
        return formatNumber(a?.totalPricePerTicket)
      },
    },
    {
      data: (a) => {
        //return a?.event?.name
        return formatNumber(a?.totalCommissionAmount)
      },
    },

    // { data: 'numberOfTickets' },
    // { data: 'pricePerTicket' },
    // { data: 'reservedPricePerTicket' },
    {
      data: (a) => {
        return a?.deactivatedAt === null
          ? `
                            <span class="badge bg-success">active</span>
                        `
          : `
                            <span class="badge bg-danger">inactive</span>
                        `
      },
    },
    // {
    //   data: (a) => {
    //     return a?.section?.name
    //   },
    // },
    // {
    //   data: (a) => {
    //     return a?.subSection?.name ?? 'N/A'
    //   },
    // },
    // {
    //   data: (a) => {
    //     return moment(a?.createdAt).format(strText.full_date)
    //   },
    // },
    // {
    //   data: (a) => {
    //     return a?.user?.name
    //   },
    // },
    // {
    //   data: (a) => {
    //     return a?.user?.email
    //   },
    // },
    // {
    //   data: (a) => {
    //     return a?.user?.phone
    //   },
    // },
    // {
    //   data: (a) => {
    //     return `
    //                 <div class='text-wrap table-column-dimension'>
    //                 ${buildAddress({
    //       houseName: a?.user?.houseName,
    //       firstLineAddress: a?.user?.firstLineAddress,
    //       secondLineAddress: a?.user?.secondLineAddress,
    //       town: a?.user?.town,
    //       postCode: a?.user?.postCode,
    //       country: a?.user?.country?.name,
    //     })}
    //                 </div>`
    //   },
    // },
    {
      data: (a) => {
        return `<a title='View Items' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon view-items">
                        <i class="fa fa-bars"></i>
                    </a>
                        `
      },
      responsivePriority: 2,
    },
  ]

  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Listings',
    [0, 1, 2, 3],
    [0, 'desc'],
    'Listings',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    // $(document).on('click', '.delete-listing', function () {
    //   const r = $(this).attr('data-id')
    //   let x = data.filter((item) => item?.id === r)
    //   if (x.length > 0) {
    //     setItems(x[0], 'delete')
    //   }
    // })
    // $(document).on('click', '.toggle-listing', function () {
    //   const r = $(this).attr('data-id')
    //   let x = data.filter((item) => item?.id === r)
    //   if (x.length > 0) {
    //     setItems(x[0], 'toggle_status')
    //   }
    // })
    $(document).on('click', '.view-items', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'view-items')
      }
    })
  }
}

export const listingItemAdminDatatable = (
  // errorFunc,
  //table_id,
  //endpoint,
  // searchEndPoint,
  // token,
  //setItems
  data, table_id, setItem
) => {
  const cols = [
    {
      data: (a) => {
        return a?.listingRef
      },
    },
    {
      data: (a) => {
        return a?.event?.name
      },
    },
    { data: 'numberOfTickets' },
    { data: 'pricePerTicket' },
    { data: 'reservedPricePerTicket' },
    {
      data: (a) => {
        return a?.deactivatedAt === null
          ? `
                            <span class="badge bg-success">active</span>
                        `
          : `
                            <span class="badge bg-danger">inactive</span>
                        `
      },
    },
    {
      data: (a) => {
        return a?.section?.name
      },
    },
    {
      data: (a) => {
        return a?.subSection?.name ?? 'N/A'
      },
    },
    {
      data: (a) => {
        return moment(a?.createdAt).format(strText.full_date)
      },
    },
    {
      data: (a) => {
        return a?.user?.name
      },
    },
    {
      data: (a) => {
        return a?.user?.email
      },
    },
    {
      data: (a) => {
        return a?.user?.phone
      },
    },
    {
      data: (a) => {
        return `
                    <div class='text-wrap table-column-dimension'>
                    ${buildAddress({
          houseName: a?.user?.houseName,
          firstLineAddress: a?.user?.firstLineAddress,
          secondLineAddress: a?.user?.secondLineAddress,
          town: a?.user?.town,
          postCode: a?.user?.postCode,
          country: a?.user?.country?.name,
        })}
                    </div>`
      },
    },
    {
      data: (a) => {
        return `
                     <a title=${a?.deactivatedAt == null
            ? 'Deactivate Listing'
            : 'Activate Listing'
          } data-id=${a?.id} class="btn btn-sm ${a?.deactivatedAt == null ? 'bg-warning' : 'bg-success'
          } text-white btn-icon toggle-listing">
                                <i class="fa ${a?.deactivatedAt == null
            ? 'fa-pause'
            : 'fa-play'
          }"></i>
                            </a>
                     <a title="Delete Listing" data-id=${a?.id
          } class="btn btn-sm bg-danger text-white btn-icon delete-listing">
                                <i class="fa fa-trash"></i>
                            </a>`
      },
      responsivePriority: 2,
    },
  ]

  // serverSideDatatableConfig(
  //   callbackFunc,
  //   errorFunc,
  //   table_id,
  //   cols,
  //   'Listings',
  //   [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  //   [0, 'desc'],
  //   'Listings',
  //   endpoint,
  //   searchEndPoint,
  //   token
  // )
  generalDatatableConfig(
    data,
    table_id,
    cols,
    'Listings',
    [0, 1],
    [0, 'desc'],
    'Listings'
  )


  //function callbackFunc(data) {
  $(document).on('click', '.delete-listing', function () {
    const r = $(this).attr('data-id')
    let x = data.filter((item) => item?.id === r)
    if (x.length > 0) {
      setItem(x[0], 'delete')
    }
  })
  $(document).on('click', '.toggle-listing', function () {
    const r = $(this).attr('data-id')
    console.log("rrrrrrrr", r);
    console.log("data", data);
    let x = data.filter((item) => item?.id === r)
    console.log("xxxxxxxxxxxxx", x);
    if (x.length > 0) {
      setItem(x[0], 'toggle_status')
    }
  })
  // $(document).on('click', '.view-items', function () {
  //   const r = $(this).attr('data-id')
  //   let x = data.filter((item) => item?.id === r)
  //   if (x.length > 0) {
  //     setItems(x[0], 'view-items')
  //   }
  // })
  //}
}

export const refundAdminDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItems
) => {
  const cols = [
    {
      data: (a) => {
        return a?.order?.orderRef
      },
    },

    { data: 'reason' },
    { data: 'status' },
    // {
    //     data: (a) => {
    //         return (
    //             a?.deactivatedAt == null ?
    //                 `
    //                     <span class="badge bg-success">active</span>
    //                 `
    //                 :
    //                 `
    //                     <span class="badge bg-danger">inactive</span>
    //                 `
    //             )
    //     }
    // },
    {
      data: (a) => {
        return a?.refundedAt
          ? moment(a?.refundedAt).format('ddd DD MMM YYYY').toUpperCase()
          : 'N/A'
      },
    },
    // {
    //     data: (a) => {
    //         return a?.user?.name
    //     }
    // },
    // {
    //     data: (a) => {
    //         return a?.user?.email
    //     }
    // },
    // {
    //     data: (a) => {
    //         return a?.user?.phone
    //     }
    // },
    // {
    //     data: (a) => {
    //         return a?.user?.address
    //     }
    // },
    {
      data: (a) => {
        return ['APPROVED', 'DECLINED'].includes(a?.status)
          ? 'N/A'
          : `
                     <a title='Approve Refund' data-id=${a?.id} class="btn btn-sm bg-success text-white btn-icon approve-refund">
                                <i class="fa fa-check"></i>
                            </a>
                     <a title="Decline Refund" data-id=${a?.id} class="btn btn-sm bg-danger text-white btn-icon decline-refund">
                                <i class="fa fa-close"></i>
                            </a>
                        `
      },
      responsivePriority: 2,
    },
  ]

  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Refund Requests',
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    [0, 'desc'],
    'Refund Requests',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    $(document).on('click', '.approve-refund', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'approve')
      }
    })
    $(document).on('click', '.decline-refund', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItems(x[0], 'decline')
      }
    })
  }
}

export const ordersAdminDatatable = (
  errorFunc,
  table_id,
  endpoint,
  searchEndPoint,
  token,
  setItem
) => {
  const cols = [
    { data: 'orderRef' },
    {
      data: (a) => {
        return a?.OrderItems.length === 0 ? 'N/A' : a?.OrderItems.length
      },
    },
    {
      data: (a) => {
        return a?.commissionAmount
          ? `${strText.currency_symbol}${formatNumber(
            a?.price - a?.commissionAmount
          )}`
          : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.commissionAmount
          ? `${strText.currency_symbol}${formatNumber(a?.commissionAmount)}`
          : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.price
          ? `${strText.currency_symbol}${formatNumber(a?.price)}`
          : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.paidAt ? moment(a?.paidAt).format('ddd DD MMM YYYY').toUpperCase() : 'N/A'
      },
    },
    {
      data: (a) => {
        return a?.user?.name
      },
    },
    {
      data: (a) => {
        return a?.user?.email
      },
    },
    {
      data: (a) => {
        return a?.user?.phone
      },
    },
    {
      data: (a) => {
        return `
                    <div class='text-wrap table-column-dimension'>
                    ${buildAddress({
          houseName: a?.user?.houseName,
          firstLineAddress: a?.user?.firstLineAddress,
          secondLineAddress: a?.user?.secondLineAddress,
          town: a?.user?.town,
          postCode: a?.user?.postCode,
          country: a?.user?.country?.name,
        })}
                    </div>`
      },
    },
    {
      data: (a) => {
        return a?.OrderItems.length === 0
          ? 'N/A'
          : `
                     <a title='View Items' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon view-items">
                        <i class="fa fa-bars"></i>
                    </a>
                `
      },
      responsivePriority: 2,
    },
  ]
  serverSideDatatableConfig(
    callbackFunc,
    errorFunc,
    table_id,
    cols,
    'Orders',
    [0, 1, 2, 3, 4, 5],
    [0, 'desc'],
    'Orders',
    endpoint,
    searchEndPoint,
    token
  )

  function callbackFunc(data) {
    $(document).on('click', '.view-items', function () {
      const r = $(this).attr('data-id')
      let x = data.filter((item) => item?.id === r)
      if (x.length > 0) {
        setItem(x[0], 'view-items')
      }
    })
  }
}

export const itemsAdminDatatable = (data, table_id, setItems) => {
  const cols = [
    {
      data: (a) => {
        return a?.ticket?.listing?.event?.name
      },
    },
    {
      data: (a) => {
        return a?.ticket?.listing?.user?.name
      },
    },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(
          a?.price - a?.commissionAmount
        )}`
      },
    },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(a?.commissionAmount)}`
      },
    },
    {
      data: (a) => {
        return `${strText.currency_symbol}${formatNumber(a?.price)}`
      },
    },
    {
      data: (a) => {
        return a?.ticket?.listing?.event?.type
      },
    },
    {
      data: (a) => {
        return a?.ticket?.listing?.ticketsType
      },
    },
    {
      data: (a) => {
        return a?.deliveryStatus
      },
    },
    {
      data: (a) => {
        return a?.ticket?.listing?.id
      },
    },
    // {
    //     data: (a) => {
    //             return moment(a?.createdAt).format(strText.full_date)
    //     }
    // },
    // {
    //     data: (a) => {
    //         return (
    //              `
    //              <a
    //                     title="Edit Section"
    //                     data-id="${a?.id}"
    //                     class="btn btn-sm bg-warning btn-icon text-white edit-section">
    //                     <i class="fa fa-pencil"></i>
    //                 </a>
    //             <a title="Delete Section" data-id="${a?.id}"
    //                     class="btn btn-sm bg-danger btn-icon text-white delete-section">
    //                     <i class="fa fa-trash"></i>
    //                 </a>
    //                     <a title='Manage Subsections' data-id=${a?.id} class="btn btn-sm bg-warning text-white btn-icon section-manage-subsections">
    //                         <i class="fa fa-bars"></i>
    //                     </a>
    //                 `
    //             )
    //     },
    //     responsivePriority: 2
    // }
  ]

  generalDatatableConfig(
    data,
    table_id,
    cols,
    'Order Items',
    [0, 1, 2, 3, 4, 5, 6],
    [0, 'desc'],
    'Order Items'
  )

  // $(document).on("click", ".section-manage-subsections", function () {
  //     const r = $(this).attr("data-id")
  //     let x = data.filter((item) => item?.id === r)
  //     if (x.length > 0) {
  //         setItems(x[0], 'manage-subsections')
  //     }
  // })
  // $(document).on("click", ".edit-section", function () {
  //     const r = $(this).attr("data-id")
  //     let x = data.filter((item) => item?.id === r)
  //     if (x.length > 0) {
  //         setItems(x[0], 'edit')
  //     }
  // })
  // $(document).on("click", ".delete-section", function () {
  //     const r = $(this).attr("data-id")
  //     let x = data.filter((item) => item?.id === r)
  //     if (x.length > 0) {
  //         setItems(x[0], 'delete')
  //     }
  // })
}

const generalDatatableConfig = (
  data,
  table_id,
  cols,
  table_name,
  exportRow = [0, 1, 2],
  ordering = [0, 'desc'],
  pageTitle,
  hasPrintBtn = true,
  btnName = `${strText.admin_export_text}`
) => {
  let exportTitle = `<img src="${strText.app_logo}" alt="" height="90"/>`
  let exportOptionsIndex = exportRow
  let dom = hasPrintBtn
    ? "<'row'<'col-sm-12'B>>" +
    "<'row align-items-center'<'col-12 col-lg-6'l><'pt-2 pt-md-0 col-12 col-lg-6'f>>" +
    "<'row dt-row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"
    : "<'row align-items-center'<'col-12 col-lg-6'l><'pt-2 pt-md-0 col-12 col-lg-6'f>>" +
    "<'row dt-row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"

  let fromDate = ''
  let toDate = ''
  let other_title = table_name ?? 'primetic'

  $(document).ready(function () {
    let idInit = $(`#${table_id}`)
    idInit.DataTable().clear().destroy()
    idInit.DataTable({
      data: data,
      info: true,
      dom: dom,
      lengthChange: true,
      lengthMenu: [
        [5, 10, 15, -1],
        [5, 10, 15, 'All'],
      ],
      language: {
        paginate: {
          previous: `
                                <span aria-hidden="true" class="mdi mdi-chevron-left mr-1"></span> Prev
                                <span class="sr-only">Previous</span>`,
          next: `Next
                            <span aria-hidden="true" class="mdi mdi-chevron-right ml-1"></span>
                            <span class="sr-only">Next</span>`,
        },
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
      },
      columns: cols,
      responsivePriority: 1,
      responsive: true,
      // order: [ordering],
      ordering: false,
      pageLength: 10,
      buttons: [
        {
          extend: 'csv',
          text: btnName,
          exportOptions: {
            columns: exportOptionsIndex,
          },
          title: exportTitle,
          className: 'btn btn-primary btn-sm mx-1',
          header: true,
          messageTop: `<table style="border-bottom:1px solid black;width:100% !important;text-align:center;margin-bottom: 30px;margin-top: 30px;">
                                        <tr>
                                            <th colspan="3" style="align-content: center; align-items:center;font-size: 2rem;"> ${pageTitle}</th>
                                            <th>  </th>
                                            <th>   </th>
                                        </tr>
                                        
                                    </table>`,
          filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
        },
      ],
    })
  })
}

const serverSideDatatableConfig = (
  callback,
  errorFunc,
  table_id,
  cols,
  table_name,
  exportRow = [0, 1, 2],
  ordering = [0, 'desc'],
  pageTitle,
  endpoint,
  searchEndPoint,
  token,
  hasPrintBtn = true,
  btnName = `${strText.admin_export_text}`
) => {
  let exportTitle = `<img src="${strText.app_logo}" alt="" height="90"/>`
  let exportOptionsIndex = exportRow
  let dom = hasPrintBtn
    ? "<'row'<'col-sm-12'B>>" +
    "<'row align-items-center'<'col-12 col-lg-6'l><'pt-2 pt-md-0 col-12 col-lg-6'f>>" +
    "<'row dt-row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"
    : "<'row align-items-center'<'col-12 col-lg-6'l><'pt-2 pt-md-0 col-12 col-lg-6'f>>" +
    "<'row dt-row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"

  let fromDate = ''
  let toDate = ''
  let other_title = table_name ?? 'primetic'

  $(document).ready(function () {
    let idInit = $(`#${table_id}`)
    idInit.DataTable().clear().destroy()
    let dataTable = idInit.DataTable({
      serverSide: true,
      processing: true,
      ajax: {
        url: endpoint,
        type: 'GET',
        headers: {
          'Content-type': 'application/json',
          'X-API-Version': 0,
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: function (d) {
          d.page = Math.floor(d.start / d.length) + 1 // Calculate current page
          d.pageSize = d.length // Records per page
          return $.param({
            page: d.page,
            pageSize: d.pageSize,
            draw: d.draw,
            q: d.search.value,
          })
          // Add any additional parameters you need to send to the server
        },
        dataSrc: function (response) {
          if (callback && typeof callback === 'function') {
            callback(response.data) // Pass the data to the callback function
          }
          return response.data
        },
        error: function (xhr, error, thrown) {
          // Handle the error here
          if (thrown && thrown?.toLowerCase() === 'unauthorized') {
            errorFunc(thrown)
          }
        },
      },
      searching: searchEndPoint ? true : false,
      info: true,
      dom: dom,
      lengthChange: true,
      lengthMenu: [
        [5, 10, -1],
        [5, 10, 'All'],
      ],
      language: {
        paginate: {
          previous: `
                                <span aria-hidden="true" class="mdi mdi-chevron-left mr-1"></span> Prev
                                <span class="sr-only">Previous</span>`,
          next: `Next
                            <span aria-hidden="true" class="mdi mdi-chevron-right ml-1"></span>
                            <span class="sr-only">Next</span>`,
        },
        processing: "<i class='fa fa-refresh fa-spin text-center'></i>",
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
      },
      columns: cols,
      responsivePriority: 1,
      responsive: true,
      ordering: false,
      pageLength: 10,
      buttons: [
        {
          extend: 'csv',
          text: btnName,
          exportOptions: {
            columns: exportOptionsIndex,
          },
          title: exportTitle,
          className: 'btn btn-primary btn-sm mx-1',
          header: true,
          messageTop: `<table style="border-bottom:1px solid black;width:100% !important;text-align:center;margin-bottom: 30px;margin-top: 30px;">
                                        <tr>
                                            <th colspan="3" style="align-content: center; align-items:center;font-size: 2rem;"> ${pageTitle}</th>
                                            <th>  </th>
                                            <th>   </th>
                                        </tr>
                                        
                                    </table>`,
          filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
        },
      ],
    })

    if (searchEndPoint) {
      let searchInput = $('div.dataTables_filter input')

      searchInput.off()
      // Bind keypress event
      searchInput.on('keydown', function (e) {
        // Check if the pressed key is Enter (key code 13)
        if (e.which === 13) {
          // Prevent the default search behavior
          e.preventDefault()

          dataTable.ajax.url(searchEndPoint)

          // Perform the search manually
          dataTable.search($(this).val()).draw()
        }
      })
    }
  })
}

// const serverSideDatatableConfig = (data, table_id, cols, table_name, exportRow = [0, 1, 2], ordering = [0, 'desc'], pageTitle, endpoint, searchEndPoint, token, hasPrintBtn = true, btnName = `${strText.admin_export_text}`) => {
//     let isLandScape = true;
//     let exportTitle = `<img src="${strText.app_logo}" alt="" height="90"/>`;
//     let exportOptionsIndex = exportRow;
//     let dom = hasPrintBtn ? "<'row'<'col-sm-12'B>>" +
//         "<'row align-items-center'<'col-12 col-lg-6'l><'pt-2 pt-md-0 col-12 col-lg-6'f>>" +
//         "<'row dt-row'<'col-sm-12'tr>>" +
//         "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>" :
//         "<'row align-items-center'<'col-12 col-lg-6'l><'pt-2 pt-md-0 col-12 col-lg-6'f>>" +
//         "<'row dt-row'<'col-sm-12'tr>>" +
//         "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>";

//     let fromDate = '';
//     let toDate = '';
//     let total = 0;
//     let other_title = table_name ?? 'primetic';

//     $(document).ready(function () {
//         let idInit = $(`#${table_id}`);
//         idInit.DataTable().clear().destroy();
//         let dataTable = idInit.DataTable({
//             serverSide: true,
//             processing: true,
//             ajax: {
//                 url: endpoint,
//                 type: 'GET',
//                 headers: {
//                     'Content-type': 'application/json',
//                     'X-API-Version': 0,
//                     Accept: 'application/json',
//                     Authorization: `Bearer ${token}`
//                 },
//                 data: function (d) {
//                     d.page = Math.floor((d.start + d.length) / d.length); // Calculate current page
//                     d.pageSize = d.length; // Records per page
//                     return $.param({
//                         page: d.page,
//                         pageSize: d.pageSize,
//                         draw: d.draw,
//                         q: d.search.value
//                     })
//                     // Add any additional parameters you need to send to the server
//                 },
//                 // dataSrc: function (json) {
//                 //     // Assuming your server returns data in a property named "data"
//                 //     // let totalRecords = json.data.meta.totalPages
//                 //     // $.fn.DataTable.ext.pager.numbers_length  = totalRecords;
//                 //     // dataTable.draw();
//                 //     return json.data.data;
//                 // },
//             //     xhr: function (settings, json) {
//             //     // Update the DataTables API with the correct recordsTotal value
//             //     settings.json.recordsTotal = json.data.totalRecords;

//             //     // Trigger a redraw of the DataTable
//             //     dataTable.draw();

//             //     // Return the modified JSON response
//             //     return JSON.stringify(json);
//             // },
//             //     success: function (json) {
//             //     // Access the full JSON response here
//             //     let totalRecords = json.data.meta.total
//             //     let settings = this.s;

//             //     json.recordsTotal = totalRecords;
//             // }
//             },
//             searching: false,
//             info: true,
//             dom: dom,
//             lengthChange: true,
//             lengthMenu: [
//                 [5, 10, -1],
//                 [5, 10, "All"],
//             ],
//             language: {
//                 paginate: {
//                     previous: `
//                                 <span aria-hidden="true" class="mdi mdi-chevron-left mr-1"></span> Prev
//                                 <span class="sr-only">Previous</span>`,
//                     next: `Next
//                             <span aria-hidden="true" class="mdi mdi-chevron-right ml-1"></span>
//                             <span class="sr-only">Next</span>`,
//                 },
//                 processing: "<i class='fa fa-refresh fa-spin text-center'></i>",
//                 search: "_INPUT_",
//                 searchPlaceholder: "Search...",
//             },
//             columns: cols,
//             responsivePriority: 1,
//             responsive: true,
//             ordering: false,
//             pageLength: 10,
//             buttons: [
//                 {
//                     extend: "print",
//                     text: btnName,
//                     footer: false,
//                     orientation: isLandScape ? "landscape" : "portrait",
//                     pageSize: isLandScape ? "LEGAL" : "A4",
//                     exportOptions: {
//                         columns: exportOptionsIndex,
//                     },
//                     title: exportTitle,
//                     className: "btn btn-primary btn-sm mx-1",
//                     header: true,
//                     messageTop: `<table style="border-bottom:1px solid black;width:100% !important;text-align:center;margin-bottom: 30px;margin-top: 30px;">
//                                         <tr>
//                                             <th colspan="3" style="align-content: center; align-items:center;font-size: 2rem;"> ${pageTitle}</th>
//                                             <th>  </th>
//                                             <th>   </th>
//                                         </tr>

//                                     </table>`,
//                     messageBottom: ``,
//                     filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
//                 },
//             ]
//         });

//         if(searchEndPoint){

//             let searchInput = $('div.dataTables_filter input');

//             searchInput.off()
//             // Bind keypress event
//             searchInput.on("keydown",function(e) {
//                 // Check if the pressed key is Enter (key code 13)
//                 if (e.which === 13) {
//                     // Prevent the default search behavior
//                     e.preventDefault();

//                         dataTable.ajax.url(searchEndPoint)

//                     // Perform the search manually
//                     dataTable.search($(this).val()).draw();
//                 }
//             });
//         }

//         // dataTable.on('draw.dt', function () {
//         //     var info = dataTable.page.info();
//         //     dataTable.column(0, {search: 'applied', order: 'applied'}).nodes().each(function (cell, i) {
//         //         cell.innerHTML = i + 1 + info.start;
//         //     });
//         // });

//     });
// };
