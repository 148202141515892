import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setAdminErrorMessageUser, setAdminLoadingUser, showListingModalFunc, showRefundModalFunc } from '../../../../config/redux/slice/adminSlice'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'
import ModalLayout from '../../../layout/ModalLayout'

export default function DeclineRefundModal({refundObj}) {
    const dispatch = useDispatch()
  
    const {
            adminLoading,
            adminErrorMsg,
            adminSuccessMsg
    } = useSelector((state) => state.admin)

    const {
        token
    } = useSelector((state) => state.authData)

    const onSubmit = async () => {
        dispatch(clearAll({}))
        const res = await post_put_patch_request(
          "patch",
            token,
            `${app_api.decline_refund(refundObj?.id)}`,
            {},
            dispatch,
            setAdminLoadingUser,
            "",
            setAdminErrorMessageUser
        )
        if(res !== undefined && res.status < 400){
          dispatch(onUpdate({message: res.data.message, type: 'refund'}))
        }
    }

    const closeModal = () => {
      dispatch(showRefundModalFunc({modal: false, type: null}))
    }

  return (
    <ModalLayout onClick={closeModal} heading={'Decline Refund'}>
        <div className="modal-body">
            <p>Are you sure you want to decline this refund?</p>
        </div>
        <div className="modal-footer border-top-0 d-flex align-items-center">
            <div>
            {adminLoading ? 
                <Spinner isForm={true}/> :
                <button onClick={onSubmit} type="button" className="btn btn-danger me-3">Decline</button>
            }
            </div>
            <button disabled={adminLoading ? true : false} type="button" onClick={closeModal} className="btn btn-info">Cancel</button>
        </div>
    </ModalLayout>
  )
}
