import React from 'react'

export default function Spinner({spinner_styles, color, isForm, class_styles}) {
  return (
        <div className={`${isForm ? 'me-3' : ''} ${class_styles}`}>
          <div style={{width: '1.5rem', height: '1.5rem', ...spinner_styles}} className={`spinner-border ${color ?? 'text-primary'}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
  )
}
