import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const SellerDetails = ({ show, handleClose, event, seller_details }) => {

    useEffect(() => {

    }, [seller_details]);


    return (
        <Modal show={show} onHide={handleClose} className='modal-popup-commission'>
            <Modal.Header closeButton>
                <Modal.Title>Sales By Sellers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card widget-messages mt-4">
                    <div className="card-body-scroll">
                        <div className="list-group list-group-flush">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" className='no-wrap'>Event Name</th>
                                        <th scope="col" className='no-wrap'>Tickets Listed</th>
                                        <th scope="col" className='no-wrap'>Tickets Sold</th>
                                        <th scope="col" className='no-wrap'>Total Sales</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {seller_details && seller_details.details && seller_details.details.length > 0 ? (
                                        seller_details.details.map((item) => (
                                            <tr>
                                                <td>
                                                    <div className="text-ellipse-single" title={item.eventName}><b>{item.eventName}</b></div>
                                                </td>
                                                <td>{item.totalTickets}</td>
                                                <td>{item.soldTickets}</td>
                                                <td>{item.totalSales}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4">No event details available</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SellerDetails;
