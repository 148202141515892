import React from 'react'
import { useDispatch } from 'react-redux'
import { showTicketModalFunc } from '../../../../config/redux/slice/customerSlice'
import ModalLayout from '../../../layout/ModalLayout'

export default function InformationModal() {
    const dispatch = useDispatch()

    const closeModal = () => {
      dispatch(showTicketModalFunc({modal: false, type: null}))
    }

  return (
    <ModalLayout classStyles={''} onClick={closeModal} heading={'Upload Ticket'}>
            <div className="modal-body">
                <div className="px-4 px-md-7">
                    <p>Upload your tickets for your listings to show up to buyers.</p>

                </div>
            </div>
            <div className="modal-footer border-top-0 d-flex justify-content-end align-items-center">
                <button onClick={closeModal} type="button" className="btn btn-info">Close</button>
            </div>
    </ModalLayout>
  )
}
