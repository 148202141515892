import React, { useEffect, useRef, useState } from 'react'
import primetic_logo from '../../../assets/images/logos/PrimeTic-Logo-White-LRG.png'
import default_img from '../../../assets/images/default-img.jpeg'
import { logoutUser } from '../../../config/redux/slice/authSlice';
import { logoutCustomerUser } from '../../../config/redux/slice/customerSlice';
import { logoutAppUser, setBreadCrumbName } from '../../../config/redux/slice/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAdminUser } from '../../../config/redux/slice/adminSlice';
import { Link } from 'react-router-dom';
import { app_urls } from '../../../utils/urls/app_urls';
export default function TopPanel() {
  const dispatch = useDispatch()
      const menuRef = useRef(null);
  const [isCollapsed, setCollapsed] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  const {
        userData
    } = useSelector((state) => state.authData)
  

  const logout = (e) => {
        // e.preventDefault()
        dispatch(logoutUser())
        dispatch(logoutCustomerUser())
        dispatch(logoutAppUser())
        dispatch(logoutAdminUser())
    }

  return (
    <header className="navbar navbar-primary sticky-top bg-primary flex-md-nowrap p-0 shadow">
      <Link className="navbar-brand col-md-3 col-lg-2 me-0 px-3" to={app_urls.home}>
        <img src={primetic_logo} alt='primetic logo' width="130" />
      </Link>
      <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      {/* <input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search"></input> */}
      <div className="w-100" type="text" placeholder="Search" aria-label="Search"></div>
      <div className="d-none d-md-block">
        <div className="nav-item text-nowrap d-flex align-items-center">
          {/* <img src={default_img} className="user-image rounded-circle" alt="User" /> */}
          <ul className='p-0 m-0'>
      
                    <li className="dropstart">
                      <button className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                        <img src={(!userData?.avatar || userData.avatar == '') ? default_img : userData?.avatar} className="user-image rounded-circle" alt="User" />            
                      </button>
                      <ul style={{fontSize: '0.8rem'}} className="dropdown-menu">
                         <li>
                          <Link
                            onClick={()=>{
                              dispatch(setBreadCrumbName([{id: 1, name:'Profile', url:app_urls.profile_admin}]))
                            }}
                            className="dropdown-item" to={app_urls.profile_admin}>
                            <i className="mdi mdi-account-outline"></i>
                            <span className="nav-text">My Profile</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
          <a onClick={(e)=>{logout(e)}} className="nav-link px-3 text-white" href="#">Sign out</a>
        </div>
      </div>
      <div className="form-control-dark w-100 px-3 d-block d-md-none py-1">
        <div className="nav-item text-nowrap d-flex align-items-center">
          {/* <img src={(!userData?.avatar || userData.avatar == '') ? default_img : userData?.avatar} className="user-image rounded-circle" alt="User" /> */}
           <ul style={{width: '60px'}} className='p-0 m-0'>
      
                    <li className="dropend user-menu">
                      <button className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                        <img crossOrigin='anonymous' src={(!userData?.avatar || userData.avatar == '') ? default_img : userData?.avatar} className="user-image rounded-circle" alt="User" />            
                      </button>
                      <ul style={{fontSize: '0.8rem'}} className="dropdown-menu">
                         <li>
                          <Link
                            onClick={()=>{
                              dispatch(setBreadCrumbName([{id: 1, name:'Profile', url:app_urls.profile_admin}]))
                            }} 
                            className="dropdown-item" to={app_urls.profile_admin}>
                            <i className="mdi mdi-account-outline"></i>
                            <span className="nav-text">My Profile</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
          <a onClick={(e)=>{logout(e)}} className="nav-link px-3 text-white" href="#">Sign out</a>
        </div>
      </div>
    </header>
  )
}
