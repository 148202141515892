import React, { useEffect, useState } from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import { useDispatch, useSelector } from 'react-redux'
import { showCityModalFunc } from '../../../../config/redux/slice/adminSlice'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'
import { clearAll, onUpdate, setCustomerErrorMessage, setCustomerLoading, setListingValues, setSalesValues, showListingModalFunc, showSalesModalFunc } from '../../../../config/redux/slice/customerSlice'

export default function ConfirmDelivery({ getPendingList, setExpandedRows,ordersLength,paymentSummary}) {
  const dispatch = useDispatch()
  //const [confirmLoading, setConfirmLoading] = useState(false);
  const {
    customerLoading,
    salesManagement: { singleSalesDetails, salesItemsData },
  } = useSelector((state) => state.customer)

  const {
    token
  } = useSelector((state) => state.authData)
  const [trackId, setTrackId] = useState('') // State for the checkbox
  const [errorMessage, setErrorMessage] = useState('')

  const handleInputChange = (e) => {
    setTrackId(e.target.value) // Update trackId state
    if (errorMessage) {
      setErrorMessage('') // Clear error message when input changes
    }
  }

  const orderItemIds = singleSalesDetails?.OrderItems?.map(item => item.id) || [];

  const requestData = {
    ...(singleSalesDetails?.ticketType === "PHYSICAL" && { trackId }), // Only add trackId if ticketsType is "PHYSICAL"
    orderItemIds
  }
  let url = `${app_api.confirm_delivery(singleSalesDetails?.id, 'SELLER')}`;
  if (trackId) {
    url += `?trackId=${encodeURIComponent(trackId)}`;
  }
  const onSubmit = async () => {
    if (singleSalesDetails?.ticketType === "PHYSICAL" && !trackId) {
      setErrorMessage('This field is required')
      return
    }
    dispatch(clearAll({}))
    console.log(singleSalesDetails?.id)
    const res = await post_put_patch_request(
      "patch",
      token,
      url,
      requestData,
      dispatch,
      setCustomerLoading,
      "",
      setCustomerErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      // let newSalesDetails = [...salesItemsData];
      // let filteredSalesDetails = newSalesDetails.map((opt) => {
      //   if (opt.id === singleSalesDetails.id) {
      //     return { ...opt, ...res?.data?.data }
      //   } else {
      //     return opt
      //   }
      // })

      // Call the getPendingList function after a successful response
      getPendingList();

      dispatch(onUpdate({ message: res?.data?.message, type: 'sales' }))
      //dispatch(setSalesValues({ propertyKey: 'salesItemsData', value: filteredSalesDetails }))


      if (ordersLength <= 1) {
        setExpandedRows({});
      }

      paymentSummary()

    }
  }

  const closeModal = () => {
    dispatch(showSalesModalFunc({ modal: false, type: null }))
  }
  const showTrackIdInput = singleSalesDetails?.ticketType === "PHYSICAL"

  return (
    <ModalLayout onClick={closeModal} heading={'Confirm Delivery'}>
      <div className="modal-body">
        <p>Confirming delivery means you have successfully delivered the order to the buyer.</p>
        {showTrackIdInput && (
          <div className="form-group mt-2">
            <label htmlFor="trackIdInput">Enter Track Id</label>
            <input
              className="form-control"
              type="text"
              value={trackId}
              onChange={handleInputChange} // Update trackId state
              id="trackIdInput"
            />
            {errorMessage && (
              <div className="text-danger mt-1">
                {errorMessage}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="modal-footer border-top-0 d-flex align-items-center">
        <div>
          {customerLoading ?
            <Spinner isForm={true} /> :
            <button onClick={onSubmit} type="button" className="btn btn-success me-3">Confirm</button>
          }
        </div>
        <button disabled={customerLoading ? true : false} onClick={closeModal} type="button" className="btn btn-info">Cancel</button>
      </div>
    </ModalLayout>
  )
}
