import { admin_api } from "./admin_api"
import { onboard_api } from "./onboard_api"
import { user_api } from "./user_api"
import { core_backend_url } from "../../config"

export const app_api = {
    'get_user': `${core_backend_url}auth/authenticated-user`,
    'get_user_types': `${core_backend_url}user/usertypes`, //xskr
    'get_roles': `${core_backend_url}auth/roles`,
    'get_countries': `${core_backend_url}country`,
    'get_cities': `${core_backend_url}city`,
    'search_countries': `${core_backend_url}country/search`,
    'search_cities': `${core_backend_url}city/search`,
    'search_events': `${core_backend_url}event/search`,
    'search_events_auth': `${core_backend_url}event/search/authenticated`,
    'search_venue': `${core_backend_url}venue/search`,
    'search_team': `${core_backend_url}team/search`,
    'search_competition': `${core_backend_url}competition/search`,
    'search_listing': `${core_backend_url}listing/search`,
    'search_payouts': `${core_backend_url}payout/search`,
    'search_payout_eventid': (event_id) => `${core_backend_url}payout/search_eventid/${event_id}`,
    'search_order': `${core_backend_url}order/search`,
    'search_sale': `${core_backend_url}sale/search`,
    'search_user': `${core_backend_url}user/search`,
    'get_venues': `${core_backend_url}venue`,
    'get_teams': `${core_backend_url}team`,
    'get_events': `${core_backend_url}event`,
    'get_promocode': `${core_backend_url}promoCode/getPromoCode`,
    'get_single_event': (event_id) => `${core_backend_url}event/${event_id}`,
    'get_competitions': `${core_backend_url}competition`,
    'get_sections': (venue_id) => `${core_backend_url}venue/${venue_id}/sections`,
    'get_subsections': (section_id) => `${core_backend_url}venue/section/${section_id}/subsections`,
    'get_trending_events': `${core_backend_url}event/trending`,
    'get_top_events': `${core_backend_url}event/top-events`,
    'get_trending_events_auth': `${core_backend_url}event/trending/authenticated`,
    'update_user_info': `${core_backend_url}user/update-profile`,
    'update_password': `${core_backend_url}auth/update-password`,
    'events_count': `${core_backend_url}analytics/event/count`,
    'users_count': (role) => `${core_backend_url}analytics/user/count/${role}`,
    'total_tickets_sold': `${core_backend_url}analytics/tickets-sold`,
    'total_tickets_listed': `${core_backend_url}analytics/tickets-listed`,
    'total_active_listings': `${core_backend_url}analytics/listings/active`,
    'buyers_sellers_count': `${core_backend_url}analytics/buyers-sellers`,
    'registered_users_count': `${core_backend_url}analytics/registered-users`,
    'sales_revenue_yearly': `${core_backend_url}analytics/`,
    'sales_revenue_monthly': `${core_backend_url}analytics/tickets-sold/per-month`,
    // 'revenue_tickets_sold': (period, month, year, event_id) => {
    //     const url = `${core_backend_url}analytics/revenue-tickets-sold/${period}?month=${month}&year=${year}`;
    //     return event_id != 'null' ? `${url}&event=${event_id}` : `${core_backend_url}analytics/revenue-tickets-sold/${period}?month=${month}&year=${year}&event`;
    // },

    'revenue_tickets_sold': (period, month, year, event_id, fromDate, toDate, revenuType) => {
        // Construct the base URL
        let url = `${core_backend_url}analytics/revenue-tickets-${revenuType}/${period}?`;
        // Append month if period is 'month'
        if (period === 'month') {
            url += `month=${month}&year=${year}`;
        }
       else if (period === '0') {
            url = `${core_backend_url}analytics/revenue-tickets-${revenuType}/between?month=&year=&start=${fromDate}&end=${toDate}`;

        }else{
            url += `year=${year}`;
        }
        // Append year
        // 
        // Append event_id if it is not 'null'
        if (event_id !== 'null' && event_id !== null && event_id !== undefined) {
            url += `&event=${event_id}`;
        }
        return url;
    },

    'top_selling_events': `${core_backend_url}analytics/top-selling-events?limit=6`,

    'top_commision_events': `${core_backend_url}analytics/top-commision-events?limit=6`,
    'top_commision_event_details': (event_id) => `${core_backend_url}analytics/commision-events-details/${event_id}`,
    'top_sales_event_details': (event_id) => `${core_backend_url}analytics/sales-events-details/${event_id}`,
    'top_event_details': (event_id) => `${core_backend_url}analytics/listing-events-details/${event_id}`,
    'top_seller_details': (user_id) => `${core_backend_url}analytics/sales-seller-events-details/${user_id}`,

    'top_sales_events': `${core_backend_url}analytics/top-sales-events?limit=6`,
    'top_listing_events': `${core_backend_url}analytics/top-listing-events?limit=6`,
    'top_sales_seller': `${core_backend_url}analytics/top-sales-seller?limit=6`,

    'sales_trend': `${core_backend_url}analytics/sales-trend`,
    'send_phone_number_verification': `${core_backend_url}auth/send-phone-verification-code`,
    'verify_phone_number_otp': `${core_backend_url}auth/verify-phone`,
    'update_bank_info': (bankDetailId) => `${core_backend_url}payment/bank-detail/${bankDetailId}/update`, // XD_J
    'create_bank_info': () => `${core_backend_url}payment/bank-detail`, // XD_J
    'get_bank_info': `${core_backend_url}payment/bank-detail`,
    'delete_bank_info': (bankDetailId) => `${core_backend_url}payment/bank-detail/${bankDetailId}/remove`,
    ...admin_api,
    ...onboard_api,
    ...user_api
}