import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, setCustomerLoading, setPaymentValues } from '../../../../config/redux/slice/customerSlice'
import PageLayout from './PageLayout'
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Spinner from '../../../components/Spinner';
import spinner_loader from '../../../../assets/images/Spinner-loading.gif';
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request';
import { app_api } from '../../../../utils/endpoints/app_api';
import CheckoutForm from './CheckoutForm';
import { stripe_public_key } from '../../../../config';

// const stripePromise = loadStripe(stripe_public_key);

export default function PaymentPage() {
//     const stripe = useStripe();
//   const elements = useElements();
    const dispatch = useDispatch()
    const [info_list, set_info_list] = useState([])
    const [stripePromise, setStripePromise] = useState(()=>loadStripe(stripe_public_key))
  //   const [clientSetup, setClientSetup] = useState(null);
  // const [clientPayment, setClientPayment] = useState(null);
  const [options, setOptions] = useState({})

    const {
      cartManagement:{cartData, cartNumber}
    } = useSelector((state) => state.app)

     const {
    token
    } = useSelector((state) => state.authData)

   const {
          paymentManagement: {saveCardDetails, clientSetup, clientPayment, useExistingDetails}
    } = useSelector((state) => state.customer)
    

    const setupOptions = {
        // passing the client secret obtained from the server
        clientSecret: clientSetup?.client_secret,
    };

    const paymentOptions = {
        // passing the client secret obtained from the server
        clientSecret: clientPayment?.client_secret,
    };

     useEffect(()=>{
        dispatch(clearAll())
        // setStripePromise(loadStripe('pk_test_51OJahnIBhxOGHFtBrHyTc35yyCCpMCWuMyEirnxCqBviYb7aaBb5fUpqb7cr7wstrMeOY050VvyiSUPs882tlSov001A8Yprcf'))
        getSetupIntent()
        getPaymentMethods()
        // getPaymentIntent()
    },[])

    // useEffect(()=>{
    //   setStripePromise(loadStripe(stripe_public_key))
    // }, [saveCardDetails])

            const getPaymentIntent = async () =>{
      dispatch(clearAll({}))
      const res = await get_delete_request(
        'get',
        token,
        `${app_api.create_payment_intent}`,
        {},
        dispatch,
        setCustomerLoading,
        "",
        ""
      )
      if(res !== undefined && res.status < 400){
        dispatch(setPaymentValues({propertyKey: 'clientPayment', value:res?.data?.data}))
      }
    }

        const getPaymentMethods = async () =>{
      dispatch(clearAll({}))
      const res = await get_delete_request(
        'get',
        token,
        `${app_api.get_payment_method}`,
        {},
        dispatch,
        "",
        "",
        ""
      )
      if(res !== undefined && res.status < 400){
        dispatch(setPaymentValues({propertyKey: 'paymentMethods', value: res?.data?.data?.data}))
      }
    }
    

    const getSetupIntent = async () =>{
      dispatch(clearAll({}))
      const res = await get_delete_request(
        'get',
        token,
        `${app_api.create_setup_intent}`,
        {},
        dispatch,
        setCustomerLoading,
        "",
        ""
      )
      if(res !== undefined && res.status < 400){
        dispatch(setPaymentValues({propertyKey: 'clientSetup', value:res?.data?.data}))
      }
    }

    // const confirmSetupIntent = async () =>{
    //   dispatch(clearAll({}))
    //   const res = await post_put_patch_request(
    //     'post',
    //     token,
    //     `${app_api.confirm_setup_intent}`,
    //     {
    //         "setUpIntentId": clientSetup.id,
    //         "paymentMethod": elements.getElement(PaymentElement)
    //     },
    //     dispatch,
    //     setCustomerLoading,
    //     "",
    //     ""
    //   )
    //   if(res !== undefined && res.status < 400){
    //     getPaymentIntent()
    //   }
    // }

  return (
    <>
    <div className='position-relative h-100 w-100'>
        <h6 className="mb-3 fw-bold">{useExistingDetails ?  'Select Payment Method' : 'Add Payment Method'}</h6>
        <button
          onClick={()=>{
            dispatch(setPaymentValues({propertyKey: 'activeTab', value: 1}))
          }}
          style={{backgroundColor: 'rgba(0,0,0,0.1)', color: 'rgba(0,0,0,0.9)'}}
         className='btn btn-sm fw-bold'
        >
          <i style={{fontSize: '1rem'}} className="mdi mdi-arrow-left me-1"></i>
          Go back to confirmation
        </button>
          {(stripePromise && clientSetup && clientSetup?.id)&&
            <Elements stripe={stripePromise} options={setupOptions}>
                <CheckoutForm/>
            </Elements>
          }
        {/* {(stripePromise && clientSetup && clientSetup?.id && saveCardDetails)&&
            <Elements stripe={stripePromise} options={setupOptions}>
                <CheckoutForm/>
            </Elements>
} */}
           {/* { (stripePromise && clientPayment && clientPayment?.id && !saveCardDetails)&&
            <Elements stripe={stripePromise} options={paymentOptions}>
                <CheckoutForm/>
                <div>hybuy</div>
            </Elements>} */}
            {/* { (stripePromise && clientSetup && clientSetup?.id && !saveCardDetails)&&
            <Elements stripe={stripePromise} options={paymentOptions}>
                <CheckoutForm/>
                <div>hybuy</div>
            </Elements>} */}
            
            {(!stripePromise || !clientSetup) &&
             <div style={{height: '100%', position: 'absolute', top: 0, zIndex: 1800, backgroundColor: 'white'}} className={`w-100 position-absolute d-flex justify-content-center align-items-center`}>
                    <img height={50} src={spinner_loader} alt='loader' />
            </div>}
            
            {/* <Elements stripe={stripePromise} options={options}>
                <form>
                    <PaymentElement />
                    {customerLoading ? 
                    <Spinner isForm={true}/> :
                    <button className="w-100 btn btn-primary btn-lg mt-4" type="submit">Continue</button>
                }  
                </form>
            </Elements> */}
        </div>
    </>
  )
}
