import React from 'react'
import ModalLayout from '../layout/ModalLayout'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './Spinner'
import {
  clearAll,
  setErrorMessage,
  setLoading,
  showPhoneNumberVerificationModalFunc,
} from '../../config/redux/slice/authSlice'
import { get_delete_request } from '../../helpers/request'
import { app_api } from '../../utils/endpoints/app_api'
import AuthAlert from './AuthAlert'

export default function WelcomeMessage() {
  const dispatch = useDispatch()

  const { loading, errorMsg, token } = useSelector((state) => state.authData)

  const sendVerificationCode = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.send_phone_number_verification}`,
      {},
      dispatch,
      setLoading,
      '',
      setErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      dispatch(
        showPhoneNumberVerificationModalFunc({ modal: true, type: 'verify' })
      )
    }
  }

  const closeAlert = () => {
    dispatch(setErrorMessage({ message: null }))
  }

  // return (
  //   <ModalLayout keepHeading={false}>
  //     <div className="modal-body">
  //       <h5 className="text-dark fw-bold">Phone Number Verification</h5>
  //       {errorMsg && (
  //         <AuthAlert
  //           onClick={closeAlert}
  //           alertStyles={{ width: '95%', margin: '0 auto' }}
  //           status="error"
  //         >
  //           {errorMsg}
  //         </AuthAlert>
  //       )}
  //       <p style={{ fontSize: '1rem', lineHeight: '1.8rem' }}>
  //         Please we'll need you to verify your phone number. Click the button
  //         below to verify.
  //       </p>
  //       <div className="mt-3">
  //         {loading ? (
  //           <Spinner class_styles={'mb-1'} />
  //         ) : (
  //           <button
  //             onClick={sendVerificationCode}
  //             className="btn btn-primary mb-1"
  //           >
  //             Send Verification Code
  //           </button>
  //         )}
  //       </div>
  //     </div>
  //   </ModalLayout>
  // )
}
