import React, { useState,useEffect } from 'react';

export default function SellerDeatils({ showDbPopup, setShowDbPopup, selectedItem , fromPage}) {

  const closeDbPopup = () => {
    setShowDbPopup(false);
  };
  useEffect(() => {
 //debugger

}, [])
if ((!selectedItem || !selectedItem.OrderItems || selectedItem.OrderItems.length === 0) && fromPage === "AdminOrderManagement") {
    return null; // Or handle this case differently based on your UI requirements
  }
 let user = ""
 if(fromPage === "AdminOrderManagement"){
     user = selectedItem.OrderItems[0].ticket.listing.user
 }else{
    user = selectedItem.user
}
//   const ticket = orderItem.ticket;
//   const deliveryAddress = orderItem.deliveryAddress;

  return (
    <div id="demo-modal" className="modal delivery-addresssec" style={{ display: showDbPopup ? 'flex' : 'none' }}>
      {console.log("............hiiiiii.........111111111....................",selectedItem)}
      <div className="modal__content">
        <div className="modalFields">
          <div className='model-sec' style={{ background: "transparent" }}>
            <ul className="list-group">
              <li className="list-group-item d-flex txt-capitalize"><div className='lbl-delivey-width'>Name</div><span className='mx-2'>:</span> <div> {user.name} </div></li>
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>Email</div><span className='mx-2'>:</span> <div>{user.email} </div></li>
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>Phone</div>  <span className='mx-2'>:</span>  <div>{user.phone}</div></li>
            </ul> 
          </div>
        </div>
        <a onClick={closeDbPopup} className="modal__close">
          <i className="fa fa-close"></i>
        </a>
      </div>
    </div>
  );
}
