import React, { useEffect,useState } from 'react'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  clearResetBot,
  setCustomerErrorMessage,
  setCustomerLoading,
  setDetails,
  setSalesValues,
  showSalesModalFunc,
} from '../../../../config/redux/slice/customerSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { salesCustomerDatatable } from '../../../../helpers/customer_dataTables'
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import ConfirmDelivery from './ConfirmDelivery'
import PaymentSummaryCards from './PaymentSummaryCards'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PendingTable from './pendingTable'
import CompletedTable from './completedTable'
import FailedTable from './failedTable'
import { formatNumber } from '../../../../helpers/helpers'
import { get_delete_request } from '../../../../helpers/request'



export default function SalesTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { token } = useSelector((state) => state.authData)

  const {
    salesManagement: {
      showSalesModal: { show, type },
      newData,
    },
  } = useSelector((state) => state.customer)

  useEffect(() => {
    dispatch(clearAll({}))
    salesCustomerDatatable(
      errorFunc,
      'salesCustomerTable',
      `${app_api.get_user_sales}`,
      `${app_api.search_sale}`,
      token,
      setItem
    )
    paymentSummary()
  }, [])

  useEffect(() => {
    if (newData) {
      salesCustomerDatatable(
        errorFunc,
        'salesCustomerTable',
        `${app_api.get_user_sales}`,
        `${app_api.search_sale}`,
        token,
        setItem
      )
      dispatch(clearResetBot())
    }
  }, [newData])

  const setItem = (data, type) => {
    dispatch(setDetails({ details: data, type: 'sales' }))
    dispatch(
      setSalesValues({ propertyKey: 'salesItemsData', value: data?.orders ?? [] })
    )
    // if (type == 'details') {
    //   navigate(app_urls.user_sales_details)
    // } else if (type == 'view-items') {
    //   navigate(app_urls.sale_items)
    // } else if (type == 'confirm') {
    //   dispatch(showSalesModalFunc({ modal: true, type }))
    // }
    if (type == 'view-event-items') {
      navigate(app_urls.event_order_items)
    }
  }

  const errorFunc = (msg) => {
    dispatch(setCustomerErrorMessage({ message: msg }))
  }

  const [cardValueList, setCardValueList] = useState([
    {
      id: 1,
      heading: 'Completed Sales',
      color: 'bg-success',
      icon: 'fa fa-check',
      icon_color: 'text-success',
      loading: true,
      sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
      total_amount: 0.00,
      total_count: 0.00,
    },
    {
      id: 2,
      heading: 'Pending Sales',
      color: 'bg-warning',
      icon: 'fa fa-hourglass-half',
      icon_color: 'text-warning',
      loading: true,
      sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
      total_amount: 0.00,
      total_count: 0.00,
    },
    {
      id: 3,
      heading: 'Failed Sales',
      color: 'bg-danger',
      icon: 'fa fa-close',
      icon_color: 'text-danger',
      loading: true,
      sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
      total_amount: 0.00,
      total_count: 0.00,
    },
  ])

  const paymentSummary = async () => {
    console.log(token)
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.payout_summary}`,
      {},
      dispatch,
      '',
      '',
      setCustomerErrorMessage
    )
    {console.log("responseeeeeeeeee",res)}
    if (res !== undefined && res.status < 400) {
      let newArray = [
        {
          id: 1,
          heading: 'Completed Sales',
          color: 'bg-success',
          icon: 'fa fa-check',
          icon_color: 'text-success',
          loading: false,
          sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
          total_amount: formatNumber(res?.data?.data?.completed.totalAmount ?? 0),
          total_count: (res?.data?.data?.completed.totalOrders ?? 0),
        },
        {
          id: 2,
          heading: 'Pending Sales',
          color: 'bg-warning',
          icon: 'fa fa-hourglass-half',
          icon_color: 'text-warning',
          loading: false,
          sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
          total_amount: formatNumber(res?.data?.data?.pending.totalAmount ?? 0),
          total_count: (res?.data?.data?.pending.totalOrders ?? 0),
        },
        {
          id: 3,
          heading: 'Failed Sales',
          color: 'bg-danger',
          icon: 'fa fa-close',
          icon_color: 'text-danger',
          loading: false,
          sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
          total_amount: formatNumber(res?.data?.data?.failed.totalAmount ?? 0),
          total_count: (res?.data?.data?.failed.totalOrders ?? 0),
        },
      ]
      setCardValueList(newArray)
    }
  }


  return (
    <CustomerWrapper heading={'Sales'}>
      <PaymentSummaryCards cardValueList ={cardValueList} />
        <div className="payable-tblouter order-tbl tbl-bg-white">
          <Tabs>
            <TabList>
              <Tab>Pending</Tab>
              <Tab>Completed</Tab>
              <Tab>Failed</Tab>
            </TabList>

            <TabPanel>
              <PendingTable paymentSummary ={paymentSummary} />
            </TabPanel>
            <TabPanel>
              <CompletedTable />
            </TabPanel>
            <TabPanel>
              <FailedTable />
            </TabPanel>
          </Tabs>
        </div>
    </CustomerWrapper>
  )
}
