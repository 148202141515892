import React, { useEffect } from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import Spinner from '../../../components/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setAdminErrorMessageUser, setAdminLoadingUser, showCityModalFunc } from '../../../../config/redux/slice/adminSlice'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useForm } from 'react-hook-form'
import SelectComponent from '../../../components/Form/SelectComponent'

export default function EditCity({cityObj}) {
    const dispatch = useDispatch()
    const {register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
  
    const {
            adminLoading,
            adminErrorMsg,
            adminSuccessMsg
    } = useSelector((state) => state.admin)

    console.log("cityObj",cityObj.country.name)

    const {
        token
    } = useSelector((state) => state.authData)

    useEffect(()=>{
      setValue('country', cityObj.country.name)
      setValue('countryId', cityObj.country.id)
    }, [])

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        const res = await post_put_patch_request(
          "patch",
            token,
            `${app_api.update_city(cityObj?.id)}`,
            data,
            dispatch,
            setAdminLoadingUser,
            "",
            setAdminErrorMessageUser
        )
        if(res !== undefined && res.status < 400){
          dispatch(onUpdate({message: res.data.message, type: 'city'}))
        }
    }

    const closeModal = () => {
      dispatch(showCityModalFunc({modal: false, type: null}))
    }

  return (
    <ModalLayout classStyles={'modal-lg'} onClick={closeModal} heading={'Edit City'}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="modal-body">
                <div className="px-4 px-md-7">
            <div style={{rowGap: '1.2rem'}} className="row mb-2">

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor='name'>Name <span className="text-danger">*</span></label>
                  <input 
                  defaultValue={cityObj?.name}
                  type="text" 
                  className="form-control input-lg" 
                  id="name"
                  {...register("name", {
                      required: {
                      value: true,
                      message: '*this field is required'
                      }
                  })
                  } 
                  />
                  {
                  errors?.name?.message &&
                  <div className="text-danger small mt-1">
                      {errors?.name?.message}
                  </div>
                  }
              </div> 
              </div>

            <div className="col-md-6">
                <div className="form-group">
                      <label htmlFor='city'>Country <span className="text-danger">*</span></label>
                      
                      <SelectComponent
                        // searchValue={search_cities}
                        loadReq={false}
                        register={register}
                        isReq={true}
                        fieldName={'countryId'}
                        fieldName2={'country'}
                        text='Select Country'
                        setValue={setValue}
                        setErrorFunc={setError}
                        getValueFunc={getValues}
                        errors={errors?.city?.message}
                        clearErrorsFunc={clearErrors}
                        getEndpoint={app_api.get_countries}
                        searchEndpoint={app_api.search_countries}
                      />
                      {/* {
                        errors?.country?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.country?.message}
                        </div>
                        } */}
                  </div> 
              </div>
            </div>

        </div>
            </div>
            <div className="modal-footer border-top-0 d-flex align-items-center">
                <div>
                {adminLoading ? 
                    <Spinner isForm={true}/> :
                    <button type="submit" className="btn btn-primary me-3">Submit Form</button>
                }
                </div>
                <button disabled={adminLoading ? true : false} onClick={closeModal} type="button" className="btn btn-info">Cancel</button>
            </div>
        </form>
    </ModalLayout>
  )
}
