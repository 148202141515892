import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  clearAll,
  onOnboardUser,
  onUserLogin,
  setErrorMessage,
  setLoading,
} from '../../../config/redux/slice/authSlice'
import { post_put_patch_request } from '../../../helpers/request'
import { app_api } from '../../../utils/endpoints/app_api'
import { app_urls } from '../../../utils/urls/app_urls'
import AuthAlert from '../../components/AuthAlert'
import PasswordComponent from '../../components/Form/PasswordComponent'
import Spinner from '../../components/Spinner'
import AuthWrapper from '../../layout/AuthWrapper'
import SocialAuthComponent from '../../components/Form/SocialAuthComponent'
import PhoneNumberComponent from '../../components/Form/PhoneNumberComponent'
import registration_background from '../../../assets/images/background_images/registration_background.jpeg'
import CountryComponent from '../../components/Form/CountryComponent'

export default function Registration() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onTouched' })
  const [showBankDetails, setShowBankDetails] = useState(false);

  const { loading, errorMsg } = useSelector((state) => state.authData)

  const passwordValue = watch('password')

  useEffect(() => {
    return () => {
      dispatch(clearAll({}));
    };
  }, [dispatch]);

  useEffect(() => {
    // Reset bank details fields when showBankDetails changes to false
    if (!showBankDetails) {
      setValue('bankName', null);
      setValue('accountName', null);
      setValue('accountNo', null);
      setValue('sortCode', null);
      setValue('IBAN', null);
      clearErrors(['bankName', 'accountName', 'accountNo', 'sortCode', 'IBAN']);
    }
  }, [showBankDetails, setValue, clearErrors]);

  const onSubmit = async (data) => {
    dispatch(clearAll({}))
    if (data?.code == undefined) {
      setError('code', {
        type: 'manual',
        message: 'Please select country code',
      })
    } else {
      //data.phone = data.code + data.mobile_no
      let trimmedMobileNo = data.mobile_no;
      // Escape the '+' character in the country code using a backslash
      const escapedCode = data.code.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // Escapes special characters
      const countryCodeRegex = new RegExp(`^${escapedCode}\\s?`);
      // Remove the country code from mobile_no if it starts with it
      trimmedMobileNo = data.mobile_no.replace(countryCodeRegex, '').trim();
      // Set the final phone number without duplicating the country code
      data.phone = data.code + ' ' + trimmedMobileNo;
      if (!showBankDetails) {
        data.bankName = null;
        data.accountName = null;
        data.accountNo = null;
        data.sortCode = null;
        data.IBAN = null;
      }
      if (data.bankName != null) {
        data.isSeller = true;
      }
      else {
        data.isSeller = false;
      }
      const res = await post_put_patch_request(
        'post',
        "",
        `${app_api.register}`,
        data,
        dispatch,
        setLoading,
        "",
        setErrorMessage
      )
      if (res !== undefined && res.status < 400) {

        dispatch(onOnboardUser({ email: data.email }))
        navigate(app_urls.verification_link_sent)
      }
    }
  }

  const [localFirstNameError, setLocalFirstNameError] = useState('');
  const [localLastNameError, setLocalLastNameError] = useState('');
  const [localEmailError, setLocalEmailError] = useState('');
  const [localAddr1Error, setLocalAddr1Error] = useState('');
  const [localAddr2Error, setLocalAddr2Error] = useState('');
  const [localHouseNameError, setLocalHouseNameError] = useState('');
  const [localTownError, setLocalTownError] = useState('');
  const [localPostalCodeError, setLocalPostalCodeError] = useState('');

  const handleTextChange = (fieldName, value) => {
    if (fieldName === 'firstName') {
      if (value.length > 30) {
        setLocalFirstNameError('*Maximum length is 30 characters');
      } else {
        setLocalFirstNameError('');
        clearErrors('firstName');
      }
    } else if (fieldName === 'lastName') {
      if (value.length > 30) {
        setLocalLastNameError('*Maximum length is 30 characters');
      } else {
        setLocalLastNameError('');
        clearErrors('lastName');
      }
    } else if (fieldName === 'email') {
      if (value.length > 30) {
        setLocalEmailError('*Maximum length is 30');
      } else {
        setLocalEmailError('');
        clearErrors('email');
      }
    } else if (fieldName === 'address1') {
      if (value.length > 50) {
        setLocalAddr1Error('*Maximum length is 50');
      } else {
        setLocalAddr1Error('');
        clearErrors('firstLineAddress');
      }
    } else if (fieldName === 'address2') {
      if (value.length > 50) {
        setLocalAddr2Error('*Maximum length is 50');
      } else {
        setLocalAddr2Error('');
        clearErrors('secondLineAddress');
      }
    } else if (fieldName === 'houseName') {
      if (value.length > 10) {
        setLocalHouseNameError('*Maximum length is 10');
      } else {
        setLocalHouseNameError('');
        clearErrors('houseName');
      }
    } else if (fieldName === 'town') {
      if (value.length > 20) {
        setLocalTownError('*Maximum length is 20');
      } else {
        setLocalTownError('');
        clearErrors('town');
      }
    } else if (fieldName === 'postCode') {
      if (value.length > 10) {
        setLocalPostalCodeError('*Maximum length is 10');
      } else {
        setLocalPostalCodeError('');
        clearErrors('postCode');
      }
    }
  };

  const closeAlert = () => {
    dispatch(setErrorMessage({ message: null }))
  }

  return (
    <AuthWrapper
      formClasses={'mainFormRegistration'}
      autoComplete='off'
      background_image={registration_background}
      onHandleSubmit={handleSubmit(onSubmit)}
    >
      {errorMsg && (
        <AuthAlert
          onClick={closeAlert}
          alertStyles={{ width: '95%', margin: '0 auto' }}
          status="error"
        >
          {errorMsg}
        </AuthAlert>
      )}
      <div className="form-group col-md-6 mb-4">
        <label htmlFor="name">
          First Name <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control input-lg"
          id="name"
          autoComplete="off"
          {...register('firstName', {
            required: {
              value: true,
              message: '*this field is required',
            },
          })}
          onChange={(e) => handleTextChange('firstName', e.target.value)}
        />
        {errors?.firstName?.message && (
          <div className="text-danger small mt-1">
            {errors?.firstName?.message}
          </div>
        )}
        {localFirstNameError && <div className="text-danger small mt-1">{localFirstNameError}</div>}
      </div>
      <div className="form-group col-md-6 mb-4">
        <label htmlFor="lastName">
          Last Name <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control input-lg"
          id="lastName"
          autoComplete="off"
          {...register('lastName', {
            required: {
              value: true,
              message: '*this field is required',
            },
          })}
          onChange={(e) => handleTextChange('lastName', e.target.value)}
        />
        {errors?.lastName?.message && (
          <div className="text-danger small mt-1">
            {errors?.lastName?.message}
          </div>
        )}
        {localLastNameError && <div className="text-danger small mt-1">{localLastNameError}</div>}
      </div>
      {/* <div className="form-group col-md-6 mb-4">
        <label htmlFor='last_name'>Last Name</label>
        <input 
          type="text" 
          className="form-control input-lg" 
          id="last_name"
          {...register("last_name", {
            required: {
              value: true,
              message: '*this field is required'
            }
          })
          } 
        />
        {
          errors?.last_name?.message &&
          <div className="text-danger small mt-1">
            {errors?.last_name?.message}
          </div>
        }
      </div>                 */}
      <div className="form-group col-md-6 mb-4">
        <label htmlFor="email">
          Email <span className="text-danger">*</span>
        </label>
        <input
          type="email"
          className="form-control input-lg"
          id="email"
          autoComplete="off"
          aria-describedby="emailHelp"
          {...register('email', {
            required: {
              value: true,
              message: '*this field is required',
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: '*invalid email pattern',
            },
          })}
          onChange={(e) => handleTextChange('email', e.target.value)}
        />
        {errors?.email?.message && (
          <div className="text-danger small mt-1">{errors?.email?.message}</div>
        )}
        {localEmailError && <div className="text-danger small mt-1">{localEmailError}</div>}
      </div>
      {/* <div class="input-group mb-3">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Dropdown</button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" href="#">Action</a></li>
          <li><a class="dropdown-item" href="#">Another action</a></li>
          <li><a class="dropdown-item" href="#">Something else here</a></li>
          <li><hr class="dropdown-divider"/></li>
          <li><a class="dropdown-item" href="#">Separated link</a></li>
        </ul>
        <input type="text" class="form-control" aria-label="Text input with dropdown button"/>
      </div> */}
      <div className="form-group col-md-6 mb-4">
        <PhoneNumberComponent
          fieldName={'mobile_no'}
          fieldName2={'code'}
          error_msg={errors.mobile_new?.message ?? errors.code?.message}
          label={'Phone Number'}
          setValueFunc={setValue}
          setErrorFunc={setError}
          clearErrors={clearErrors}
          isReq={true}
          register={register}
        />
      </div>
      <div className="form-group col-md-6 mb-4">
        <label htmlFor="firstLineAddress">
          First Line Address <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control input-lg"
          id="firstLineAddress"
          autoComplete="off"
          {...register('firstLineAddress', {
            required: {
              value: true,
              message: '*this field is required',
            },
          })}
          onChange={(e) => handleTextChange('address1', e.target.value)}
        />
        {localAddr1Error && <div className="text-danger small mt-1">{localAddr1Error}</div>}

        {errors?.firstLineAddress?.message && (
          <div className="text-danger small mt-1">
            {errors?.firstLineAddress?.message}
          </div>
        )}
      </div>
      <div className="form-group col-md-6 mb-4">
        <label htmlFor="firstLineAddress">
          Second Line Address
        </label>
        <input
          type="text"
          className="form-control input-lg"
          id="secondLineAddress"
          autoComplete="off"
          {...register('secondLineAddress', {
          })}
          onChange={(e) => handleTextChange('address2', e.target.value)}
        />
        {localAddr2Error && <div className="text-danger small mt-1">{localAddr2Error}</div>}
        {errors?.secondLineAddress?.message && (
          <div className="text-danger small mt-1">
            {errors?.secondLineAddress?.message}
          </div>
        )}
      </div>
      {/* <div className="form-group col-md-6 mb-4">
        <label htmlFor="houseName">
          House Number <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control input-lg"
          id="houseName"
          autoComplete="off"
          {...register('houseName', {
            required: {
              value: true,
              message: '*this field is required',
            },
          })}
          onChange={(e) => handleTextChange('houseName', e.target.value)}
        />
        {localHouseNameError && <div className="text-danger small mt-1">{localHouseNameError}</div>}
        {errors?.houseName?.message && (
          <div className="text-danger small mt-1">
            {errors?.houseName?.message}
          </div>
        )}
      </div> */}
      <div className="form-group col-md-6 mb-4">
        <label htmlFor="town">
          City <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control input-lg"
          id="town"
          autoComplete="off"
          {...register('town', {
            required: {
              value: true,
              message: '*this field is required',
            },
          })}
          onChange={(e) => handleTextChange('town', e.target.value)}
        />
        {localTownError && <div className="text-danger small mt-1">{localTownError}</div>}
        {errors?.town?.message && (
          <div className="text-danger small mt-1">{errors?.town?.message}</div>
        )}
      </div>
      <div className="form-group col-md-6 mb-4">
        <label htmlFor="city">
          Country <span className="text-danger">*</span>
        </label>

        <CountryComponent
          // searchValue={search_cities}
          register={register}
          isReq={true}
          fieldName={'countryId'}
          fieldName2={'country'}
          text="Select Country"
          setValue={setValue}
          setErrorFunc={setError}
          getValueFunc={getValues}
          errors={errors?.city?.message}
          clearErrors={clearErrors}
          getEndpoint={app_api.get_countries}
          searchEndpoint={app_api.search_countries}
        />
        {errors?.country?.message && (
          <div className="text-danger small mt-1">
            {errors?.country?.message}
          </div>
        )}
      </div>

      <div className="form-group col-md-6 mb-4">
        <label htmlFor="postCode">
          Postal Code <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control input-lg"
          id="postCode"
          autoComplete="off"
          {...register('postCode', {
            required: {
              value: true,
              message: '*this field is required',
            },

          })}
          onChange={(e) => handleTextChange('postCode', e.target.value)}
        />
        {localPostalCodeError && <div className="text-danger small mt-1">{localPostalCodeError}</div>}

        {errors?.postCode?.message && (
          <div className="text-danger small mt-1">
            {errors?.postCode?.message}
          </div>
        )}
      </div>
      <PasswordComponent
        fieldName={'password'}
        classStyles={'col-md-6'}
        error_msg={errors.password?.message}
        label={
          <>
            Password <span className="text-danger">*</span>
          </>
        }
        isReq={true}
        register={register}
        patternRules={'password'}
        hasHelperText={true}
        clearErrors={clearErrors}
      />
      <PasswordComponent
        fieldName={'confirm_password'}
        classStyles={'col-md-6'}
        error_msg={errors.confirm_password?.message}
        label={
          <>
            Confirm Password <span className="text-danger">*</span>
          </>
        }
        isReq={true}
        register={register}
        patternRules={'password'}
        isSameAs={passwordValue}
        clearErrors={clearErrors}
      />

      {/* XD_J */}

      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="showBankDetails"
          autoComplete="off"
          checked={showBankDetails}
          onChange={(e) => setShowBankDetails(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="showBankDetailsCheckbox">
          Would you be interested in selling tickets?
        </label>
      </div>
      {showBankDetails && (
        <>

          <div className="col-md-12 mt-3 mb-3 d-flex flex-column align-items-center justify-content-center">
            <p>Bank Account Details</p>
          </div>
          <div className="form-group col-md-6 mb-4">
            <label htmlFor="bankName">
              Bank Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control input-lg"
              id="bankName"
              autoComplete="off"
              {...register('bankName', {
                required: showBankDetails && {
                  value: true,
                  message: '*this field is required',
                },
              })}
            />
            {errors?.bankName?.message && (
              <div className="text-danger small mt-1">{errors?.bankName?.message}</div>
            )}
          </div>
          <div className="form-group col-md-6 mb-4">
            <label htmlFor="accountName">
              Account Holder Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control input-lg"
              id="accountName"
              autoComplete="off"
              {...register('accountName', {
                required: showBankDetails && {
                  value: true,
                  message: '*this field is required',
                },
              })}
            />
            {errors?.accountName?.message && (
              <div className="text-danger small mt-1">{errors?.accountName?.message}</div>
            )}
          </div>
          <div className="form-group col-md-6 mb-4">
            <label htmlFor="IBAN">
              IBAN <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control input-lg"
              id="IBAN"
              autoComplete="off"
              {...register('IBAN', {
                required: showBankDetails && {
                  value: true,
                  message: '*this field is required',
                },
              })}
            />
            {errors?.IBAN?.message && (
              <div className="text-danger small mt-1">{errors?.IBAN?.message}</div>
            )}
          </div>
          <div className="form-group col-md-6 mb-4">
            <label htmlFor="accountNo">
              Swift Code
            </label>
            <input
              type="text"
              className="form-control input-lg"
              id="accountNo"
              autoComplete="off"
              {...register('accountNo')}
            />
            {errors?.accountNo?.message && (
              <div className="text-danger small mt-1">{errors?.accountNo?.message}</div>
            )}
          </div>

          <div className="form-group col-md-6 mb-4">
            <label htmlFor="sortCode">
              BIC
            </label>
            <input
              type="text"
              className="form-control input-lg"
              id="sortCode"
              autoComplete="off"
              {...register('sortCode')}
            />
            {errors?.sortCode?.message && (
              <div className="text-danger small mt-1">{errors?.sortCode?.message}</div>
            )}
          </div>

        </>
      )}
      {/* XD_J */}
      <div className="col-md-12 mt-3 d-flex flex-column align-items-center justify-content-center">
        <button
          type="submit"
          disabled={loading}
          className={`btn btn-primary py-2 auth-form-btn mb-3 text-uppercase ${loading && 'd-flex justify-content-center'
            }`}
        >
          Register
          {loading && (
            <Spinner
              class_styles={'ms-2'}
              spinner_styles={{ width: '1rem', height: '1rem' }}
              color={'text-white'}
            />
          )}
        </button>

        <p>
          Have an account already?
          <Link className="text-blue ms-1" to={app_urls.login}>
            Login Here
          </Link>
          {/* <a className="text-blue" href="sign-up.html">Login Here</a> */}
        </p>
      </div>

      <div className="d-flex flex-column align-items-center justify-content-center">
        <SocialAuthComponent />
      </div>
    </AuthWrapper>
  )
}
