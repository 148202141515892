import AdminWrapper from "../../../layout/AdminWrapper";
import React, { useState, useEffect } from 'react';
import { clearAll, onUpdate } from "../../../../config/redux/slice/adminSlice";
import { app_api } from '../../../../utils/endpoints/app_api';
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";

export default function ManualEmail() {
  const [selectedOption, setSelectedOption] = useState('');
  const [recipientType, setRecipientType] = useState(''); // Default to 'everyone'
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [sellerUserData, setSellerUserData] = useState([]);
  const [buyerUserData, setBuyerUserData] = useState([]);
  const [errors, setErrors] = useState({ step1: '', step2: '', name: '', email: '' }); // To hold error messages
  const [executivesList, setExecutivesList] = useState([]);
  const { token } = useSelector((state) => state.authData);
  const dispatch = useDispatch();
  const [selectedUserType, setSelectedUserType] = useState('');
  const [userTypes, setUserTypes] = useState([]) ///////xdsd

  const [externalEmail, setExternalEmail] = useState('');
  const [externalEmailName, setExternalEmailName] = useState('');
  const [externalReceipts, setExternalReceipts] = useState([]);

  // Updated options array with ids
  const options = [
    { value: '1', label: 'Reminders with offers' },
    { value: '2', label: 'Cold introduction (Buyers)' },
    { value: '3', label: 'Cold introduction (Sellers)' },
    { value: '4', label: 'Coming soon (Before launch)' },
  ];


  useEffect(() => {
    getSellerUserDetails();
    getBuyerUserDetails();
    getAllUserTypes();
  }, []);

  const getSellerUserDetails = async () => {
    dispatch(clearAll({}));
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_users}?isSeller=true`,
      {},
      dispatch,
      "",
      "",
      ""
    );
    if (res && res.status < 400) {
      setSellerUserData(res?.data?.data);
      // Set all sellers to executivesList initially
      const allSellersList = res.data.data.map(user => ({
        value: user.id,
        label: `${user.name} | ${user.email} | ${user.phone}`,
      }));
      setExecutivesList(allSellersList);
    }
  };
  const getBuyerUserDetails = async () => {
    dispatch(clearAll({}));
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_users}?isSeller=false`,
      {},
      dispatch,
      "",
      "",
      ""
    );
    if (res && res.status < 400) {
      setBuyerUserData(res?.data?.data);
      // Set all buyers to executivesList initially
      const allBuyersList = res.data.data.map(user => ({
        value: user.id,
        label: `${user.name} | ${user.email} | ${user.phone}`,
      }));
      setExecutivesList(prevList => [...prevList, ...allBuyersList]);
    }
  };
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // const handleRecipientChange = (event) => {
  //   setRecipientType(event.target.value);
  //   setSelectedUserType('');
  //   setSelectedUsers([]);

  //   if (event.target.value === 'everyone') {
  //     setSelectedUsers([]);
  //   }
  // };

  const handleRecipientChange = async (event) => {
    const value = event.target.value;
    setRecipientType(value);
    setSelectedUserType('');
    setSelectedUsers([]);
    //setRecipientType(event.target.value);

    // Clear the Select dropdown when changing recipient type
    setExecutivesList([]);

    if (value === 'seller') {
      await getSellerUserDetails();
    } else if (value === 'buyer') {
      await getBuyerUserDetails();
    }
  };


  const handleUserSelection = (selectedOptions) => {
    setSelectedUsers(selectedOptions.map(option => option.value));
  };

  // Modify the executivesList to display Name | Email | Phone


  const formatOptionLabel = ({ label }) => {
    const [name, email, phone] = label.split(" | ");
    return (
      <div>
        <b>{name}</b> | {email} | {phone}
      </div>
    );
  };

  const validateForm = () => {
    let isValid = true;
    let errors = { step1: '', step2: '' };

    if (!selectedOption) {
      errors.step1 = 'Please select a subject for the email.';
      isValid = false;
    }

    if (!recipientType) {
      errors.step2 = 'Please select a recipient type.';
      isValid = false;
    } else if (recipientType === 'selectMembers' && selectedUsers.length === 0) {
      errors.step2 = 'Please select at least one member.';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const resetForm = () => {
    setSelectedOption('');
    setRecipientType('');
    setSelectedUsers([]);
    setSellerUserData([]);
    setBuyerUserData([]);
    setErrors({ step1: '', step2: '' });
    setExecutivesList([]);
    setSelectedUserType('');
    setExternalEmail('');
    setExternalReceipts('');
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      let selectedData;
      let url = `${app_api.manual_email}`;

      if (recipientType === 'everyone') {
        selectedData = { selectedOption: selectedOption, selectedUsers: [] };
        url += `?isEveryOne=true`;
      } else if (recipientType === 'external_receipt') {
        // Handle external emails
        // const emails = externalEmail.split(',').map(email => email.trim());
        // const invalidEmails = emails.filter(email => !validateEmail(email));

        // if (invalidEmails.length > 0) {
        //   dispatch(onUpdate({ message: `Invalid email(s): ${invalidEmails.join(', ')}` }));
        //   return;
        // }

        // selectedData = { selectedOption: selectedOption, externalEmails: emails };
        // url += `?isExternalReceipt=true&isEveryOne=false`;

        if (externalReceipts.length === 0) {
          //dispatch(onUpdate({ message: 'Please add at least one external receipt.' }));
          let newErrors = { name: '', email: '' };
          newErrors.name = true;
          newErrors.email = true;
          setErrors(newErrors);
          return;
        }

        // Prepare data with external recipients (name and email)
        selectedData = { selectedOption: selectedOption, externalRecipients: externalReceipts };
        url += `?isExternalReceipt=true&isEveryOne=false`;

        // Prepare data with external emails

      } else {
        selectedData = { selectedOption: selectedOption, selectedUsers: selectedUsers };
        if (recipientType === 'seller') {

          if (selectedUserType) {
            url += `?isSeller=true&usertype=${selectedUserType}&isEveryOne=false`;
          }
          else {
            url += `?isSeller=true&isEveryOne=false`;
          }
        } else if (recipientType === 'buyer') {
          if (selectedUserType) {
            url += `?isSeller=false&usertype=${selectedUserType}&isEveryOne=false`;
          }
          else {
            url += `?isSeller=false&isEveryOne=false`;

          }
        }
      }

      // Uncomment and adjust according to your implementation
      const res = await post_put_patch_request(
        "post",
        token,
        url,
        { selectedData },
        dispatch,
        "",
        "",
      );
      if (res !== undefined && res.status < 400) {
        dispatch(onUpdate({ message: res.data.message }))
        resetForm();
      }
    }
  };

  // const onChangeUserType = async (data) => {

  //   setSelectedUserType(data);
  //   const filteredUsers = sellerUserData.filter(user => user.userType === data && user.isSeller == true);
  //   const updatedExecutivesList = filteredUsers.map(user => ({
  //     value: user.id,
  //     label: `${user.name} | ${user.email} | ${user.phone}`,
  //   }));
  //   setSelectedUsers([]);

  //   setExecutivesList(updatedExecutivesList);
  // }

  const onChangeUserType = async (data) => {
    setSelectedUserType(data);

    let filteredUsers;

    if (data) {
      // If userType is selected, filter by userType and seller status
      filteredUsers = sellerUserData.filter(user => user.userType === data && user.isSeller === true);
    } else {
      // If no userType is selected, list all sellers
      filteredUsers = sellerUserData.filter(user => user.isSeller === true);
    }

    const updatedExecutivesList = filteredUsers.map(user => ({
      value: user.id,
      label: `${user.name} | ${user.email} | ${user.phone}`,
    }));

    setExecutivesList(updatedExecutivesList);
  };


  // const onChangeBuyerUserType = async (data) => {
  //   console.log(data);
  //   setSelectedUserType(data);
  //   const filteredUsers = buyerUserData.filter(user => user.userType === data && user.isSeller == false);
  //   const updatedExecutivesList = filteredUsers.map(user => ({
  //     value: user.id,
  //     label: `${user.name} | ${user.email} | ${user.phone}`,
  //   }));
  //   setSelectedUsers([]);

  //   setExecutivesList(updatedExecutivesList);
  // }

  const onChangeBuyerUserType = async (data) => {
    setSelectedUserType(data);

    let filteredUsers;

    if (data) {
      // If userType is selected, filter by userType and buyer status
      filteredUsers = buyerUserData.filter(user => user.userType === data && user.isSeller === false);
    } else {
      // If no userType is selected, list all buyers
      filteredUsers = buyerUserData.filter(user => user.isSeller === false);
    }

    const updatedExecutivesList = filteredUsers.map(user => ({
      value: user.id,
      label: `${user.name} | ${user.email} | ${user.phone}`,
    }));

    setExecutivesList(updatedExecutivesList);
  };


  const getAllUserTypes = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_auth_user_type}`,
      {},
      dispatch,
      "",
      "",
      ""
    )
    if (res != undefined && res.status < 400) {

      setUserTypes(res?.data?.data)
      console.log('userTypes', userTypes);
    }
  }
  const handleAddExternalReceipt = () => {
    let newErrors = { name: '', email: '' };

    // Validate the fields
    if (!externalEmailName) {
      newErrors.name = 'Name is required.';
    }

    if (!externalEmail) {
      newErrors.email = 'Email is required.';
    } else {
      const emails = externalEmail.split(',').map(email => email.trim());
      const invalidEmails = emails.filter(email => !validateEmail(email));
      if (invalidEmails.length > 0) {
        newErrors.email = `Invalid email(s): ${invalidEmails.join(', ')}`;
      }
    }

    if (newErrors.name || newErrors.email) {
      setErrors(newErrors);
      return;
    }

    // Add valid emails to the list
    const emails = externalEmail.split(',').map(email => email.trim());
    const newReceipts = emails.map(email => ({ name: externalEmailName, email }));

    setExternalReceipts([...externalReceipts, ...newReceipts]);

    console.log("externalReceipts", externalReceipts)
    console.log("newReceipts", newReceipts)

    // Clear the input fields
    setExternalEmail('');
    setExternalEmailName('');
    setErrors({ name: '', email: '' });
  };

  const handleDelete = (indexToDelete) => {
    setExternalReceipts(prevReceipts => prevReceipts.filter((_, index) => index !== indexToDelete));
  };

  return (
    <AdminWrapper>
      <div className="email-bg-white">
        <div>
          <h5 className="email-mainhead">Step 1</h5>
          <h6 className="email-subtext">Email Type</h6>
          <select className="form-select form-select-email" value={selectedOption} onChange={handleChange}>
            <option value="" disabled></option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors.step1 && <p className="text-danger">{errors.step1}</p>}
        </div>
        <div className="mt-5 ml-3">
          <h5 className="email-mainhead">Step 2</h5>
          <h6 className="email-subtext">Who do you want to send this email to?</h6>
          <div className="">
            <div className="mr-3 d-flex align-items-center">
              <label>
                <input
                  type="radio"
                  name="recipient"
                  value="everyone"
                  checked={recipientType === 'everyone'}
                  onChange={handleRecipientChange}
                />
                <span className="labl-manualemail">Everyone</span>
              </label>
            </div>

            <div className="mr-3 d-flex align-items-center">
              <label>
                <input
                  type="radio"
                  name="recipient"
                  value="seller"
                  checked={recipientType === 'seller'}
                  onChange={handleRecipientChange}
                />
                <span className="labl-manualemail">Seller</span>
              </label>
            </div>




            {recipientType === 'seller' && (
              <>
                <div className="row align-items-center mb-3">
                  <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                    <span className="labl-manualemail">User Type</span>
                  </label>
                  <div className="col-md-6">
                    <select
                      name="userType"
                      id="userType"
                      className="form-select"
                      value={selectedUserType}
                      onChange={(e) => onChangeUserType(e.target.value)}
                    >
                      <option value="">Select All User</option>
                      {userTypes.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row align-items-center mb-3">
                  <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                    <span className="labl-manualemail">Select Members</span>
                  </label>{console.log('abc', executivesList)}
                  <div className="col-md-6">
                    <Select
                      isMulti
                      id="Member"
                      placeholder="Select Members"
                      options={executivesList}
                      value={executivesList.filter(option => selectedUsers.includes(option.value))}
                      formatOptionLabel={formatOptionLabel}
                      onChange={handleUserSelection}
                      isDisabled={recipientType === 'everyone'}
                    />
                    {errors.step2 && <p className="text-danger">{errors.step2}</p>}
                  </div>
                </div>
              </>
            )}



            <div className="mr-3 d-flex align-items-center">
              <label>
                <input
                  type="radio"
                  name="recipient"
                  value="buyer"
                  checked={recipientType === 'buyer'}
                  onChange={handleRecipientChange}
                />
                <span className="labl-manualemail">Buyer</span>
              </label>
            </div>


            {recipientType === 'buyer' && (
              <>
                <div className="row align-items-center mb-3">
                  <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                    <span className="labl-manualemail">User Type</span>
                  </label>
                  <div className="col-md-6">
                    <select
                      name="userType"
                      id="userType"
                      className="form-select"
                      value={selectedUserType}
                      onChange={(e) => onChangeBuyerUserType(e.target.value)}
                    >
                      <option value="">Select User Type</option>
                      {userTypes.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row align-items-center mb-3">
                  <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                    <span className="labl-manualemail">Select Members</span>
                  </label>
                  <div className="col-md-6">
                    <Select
                      isMulti
                      id="Member"
                      placeholder="Select Members"
                      options={executivesList}
                      value={executivesList.filter(option => selectedUsers.includes(option.value))}
                      formatOptionLabel={formatOptionLabel}
                      onChange={handleUserSelection}
                      isDisabled={recipientType === 'everyone'}
                    />
                    {errors.step2 && <p className="text-danger">{errors.step2}</p>}
                  </div>
                </div>
              </>
            )}


            <div className="mr-3 d-flex align-items-center">
              <label>
                <input
                  type="radio"
                  name="recipient"
                  value="external_receipt"
                  checked={recipientType === 'external_receipt'}
                  onChange={handleRecipientChange} // Make sure to have an onChange handler
                />
                <span className="labl-manualemail"> External Recipient</span>
              </label>
            </div>

            {recipientType === 'external_receipt' && (
              <>
                <div className="row align-items-center mb-3">
                  <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                    <span className="labl-manualemail">Add External Recipient</span>
                  </label>
                  <div className="col-md-3">
                    <input
                      type="text"
                      id="external_receipt_name"
                      placeholder="Name"
                      className={`form-control ${errors.name && 'is-invalid'}`} // Apply error style
                      value={externalEmailName}
                      onChange={(e) => setExternalEmailName(e.target.value)}
                    />
                    {errors.name && <p className="text-danger">{errors.name}</p>}
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      id="external_receipt"
                      placeholder="Email"
                      disabled={recipientType === 'everyone'} // Changed `isDisabled` to `disabled`
                      className={`form-control ${errors.email && 'is-invalid'}`} // Apply error style
                      value={externalEmail} // Assuming you're tracking external email in the state
                      onChange={(e) => setExternalEmail(e.target.value)} // Update state for email input
                    />
                    {errors.email && <p className="text-danger">{errors.email}</p>}
                  </div>
                  <div className="col-md-1">
                    <button type="button" className="form-control btn btn-info" onClick={handleAddExternalReceipt}>Add</button>
                  </div>
                </div>
                {/* List of added external receipts */}
                {externalReceipts.length > 0 && (
                  <div className="row">
                    <div className="col-md-5">
                      <ul className="list-group">
                        {externalReceipts.map((receipt, index) => (
                          <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                            <strong>{receipt.name}</strong>: {receipt.email}
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => handleDelete(index)}
                            >
                              X
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </>
            )}




            {/* <div className="d-flex">
              <div className="mr-3 select-member-drop">
                <label>
                  <input
                    type="radio"
                    name="recipient"
                    value="selectMembers"
                    checked={recipientType === 'selectMembers'}
                    onChange={handleRecipientChange}
                  />
                </label>
              </div>
              <div className="ml-3 flex-grow-1 select-memberdiv">
                <Select
                  isMulti
                  id="Member"
                  placeholder="Select Members"
                  options={executivesList}
                  value={executivesList.filter(option => selectedUsers.includes(option.value))}
                  formatOptionLabel={formatOptionLabel}
                  onChange={handleUserSelection}
                  onFocus={() => setRecipientType('selectMembers')} // Automatically selects the radio button
                  isDisabled={recipientType === 'everyone'} // Disable dropdown if "Everyone" is selected
                />
                {errors.step2 && <p className="text-danger">{errors.step2}</p>}
              </div>
            </div> */}


          </div>
        </div>
        <div className="mt-5 ml-3 btn-right-align">
          <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </AdminWrapper>
  );
}
