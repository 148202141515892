import React, { useEffect } from 'react'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, clearResetBot, setCustomerErrorMessage, setCustomerLoading, setDetails, setOrderValues, showOrderModalFunc } from '../../../../config/redux/slice/customerSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { ordersCustomerDatatable } from '../../../../helpers/customer_dataTables'
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import RefundForm from './RefundForm'
import ConfirmDelivery from './ConfirmDelivery'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PendingTable from './pendingTable'
import CompletedOrderTable from './completedOrderTable'

export default function OrderTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    token
  } = useSelector((state) => state.authData)

  const {
    orderManagement: { showOrderModal: { show, type }, newData }

  } = useSelector((state) => state.customer)

  useEffect(() => {
    dispatch(clearAll({}))
    ordersCustomerDatatable(
      errorFunc,
      'ordersCustomerTable',
      `${app_api.get_user_orders}`,
      `${app_api.search_order}`,
      token,
      setItem
    )
    // getAllCountries()
    // return () => {
    //   dispatch(setDetails({details: {}, type: 'listing'}))
    // };
  }, [])

  useEffect(() => {
    if (newData) {
      ordersCustomerDatatable(
        errorFunc,
        'ordersCustomerTable',
        `${app_api.get_user_orders}`,
        `${app_api.search_order}`,
        token,
        setItem
      )
      dispatch(clearResetBot())
    }
  }, [newData])
  { console.log("newadataaaaaaaaaaa", newData) }

  const setItem = (data, type) => {
    dispatch(setDetails({ details: data, type: 'order' }))
    dispatch(setOrderValues({ propertyKey: 'orderItemsData', value: data?.OrderItems ?? [] }))
    if (type == 'details') {
      navigate(app_urls.user_listings_details)
    } else if (type == 'view-items') {
      navigate(app_urls.order_items)
    }
    else if (type == 'refund') {
      dispatch(showOrderModalFunc({ modal: true, type }))
    }
  }

  const errorFunc = (msg) => {
    dispatch(setCustomerErrorMessage({ message: msg }))
  }
  return (
    <CustomerWrapper heading={'Orders'}>
      {(show && type == 'refund') &&
        <RefundForm />
      }
      <div className="payable-tblouter order-tbl tbl-bg-white">
        <Tabs>
          <TabList>
            <Tab>Pending Orders</Tab>
            <Tab>Completed Orders</Tab>
          </TabList>

          <TabPanel>
            <PendingTable />
          </TabPanel>
          <TabPanel>
            <CompletedOrderTable />
          </TabPanel>
        </Tabs>
      </div>
    </CustomerWrapper>
  )
}
