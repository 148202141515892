import React, { useEffect } from 'react'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  clearResetBot,
  setCustomerErrorMessage,
  setDetails,
  setPayoutValues,
} from '../../../../config/redux/slice/customerSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { payoutsCustomerDatatable } from '../../../../helpers/customer_dataTables'
import { useNavigate } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PendingTable from './pendingTable'
import InprogressTable from './inprogressTable'
// import FailedTable from './failedTable'
import PaidPayoutTable from './PaidPayoutTable'
import PayOutPaymentSummaryCards from './payoutPaymentSummaryCards'
export default function OrderTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { token } = useSelector((state) => state.authData)

  const {
    payoutManagement: { newData },
  } = useSelector((state) => state.customer)

  useEffect(() => {
    dispatch(clearAll({}))
    payoutsCustomerDatatable(
      errorFunc,
      'payoutsCustomerTable',
      `${app_api.get_user_payouts}`,
      null,
      token,
      setItem
    )
  }, [])

  useEffect(() => {
    if (newData) {
      payoutsCustomerDatatable(
        errorFunc,
        'payoutsCustomerTable',
        `${app_api.get_user_payouts}`,
        null,
        token,
        setItem
      )
      dispatch(clearResetBot())
    }
  }, [newData])

  const setItem = (data, type) => {
    dispatch(setDetails({ details: data, type: 'payout' }))
    dispatch(
      setPayoutValues({
        propertyKey: 'payoutItemsData',
        value: data?.PayoutItems ?? [],
      })
    )
    // if (type === 'details') {
    //   navigate(app_urls.user_listings_details)
    // } else if (type == 'view-items') {
    //   navigate(app_urls.order_items)
    // }
  }

  const errorFunc = (msg) => {
    dispatch(setCustomerErrorMessage({ message: msg }))
  }
  return (
    <CustomerWrapper heading={'Payouts'}>
      <PayOutPaymentSummaryCards />
        <div className="payable-tblouter tbl-bg-white">
            <Tabs>
              <TabList>
                <Tab>Pending</Tab>
                <Tab>InProgress</Tab>
                {/* <Tab>Failed</Tab> */}
                <Tab>Payout</Tab>
              </TabList>

              <TabPanel>
                <PendingTable />
              </TabPanel>
              <TabPanel>
                <InprogressTable />
              </TabPanel>
              {/* <TabPanel>
                <FailedTable />
              </TabPanel> */}
              <TabPanel>
                <PaidPayoutTable />
              </TabPanel>
            </Tabs>
        </div>

    </CustomerWrapper>
  )
}
