import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { countryAdminDatatable, userAdminDatatable } from '../../../../helpers/dataTables'
import '../../../../assets/css/dataTable.css'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { clearAll, clearResetBot, onGetCountries, setAdminErrorMessageUser, setAdminLoadingUser, setDetails, showAddCountryFormFunc, showCountryModalFunc } from '../../../../config/redux/slice/adminSlice'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'

export default function CountryTable() {
  const dispatch = useDispatch()

  const {
        token
    } = useSelector((state) => state.authData)

    const {
      countryManagement:{countriesData, newData, showAddCountryForm}
    } = useSelector((state) => state.admin)

    useEffect(() => {
      dispatch(clearAll({}))
        countryAdminDatatable(
          errorFunc,
        'countryAdminTable',
         `${app_api.get_countries}`,
         `${app_api.search_countries}`,
         token,
         setItem
        )
    },[])


    useEffect(()=>{
      if(newData){
          countryAdminDatatable(
            errorFunc,
        'countryAdminTable',
         `${app_api.get_countries}`,
         `${app_api.search_countries}`,
         token,
         setItem
        )
          dispatch(clearResetBot())
      }     
    }, [newData])

    const setItem = (data, type) => {
      dispatch(setDetails({details: data, type: 'country'}))
      dispatch(showCountryModalFunc({modal: true, type}))
      // if(type == 'delete'){
      //    dispatch(showUpdateUserFormFunc({modal: true, type}))
      // }else if(type == 'delete'){
      //   dispatch(showUpdateUserFormFunc({modal: true, type}))
      // }
    }

    const errorFunc = (msg) => {
      dispatch(setAdminErrorMessageUser({message: msg}))
    }
    
  return (
    <DataTableWrapper 
      table_id='countryAdminTable' 
      table_heading='Countries'
      table_buttons={showAddCountryForm ? null : <ActionButton/>}
    >
        <thead>
            <tr>
                {/* <th className="wd-5p">S/N</th> */}
                <th className="wd-15p">Name</th>
                <th className="wd-25p">Flag</th>
                <th className="wd-10p">Created At</th>
                <th className="wd-15p">Actions</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </DataTableWrapper>
  )
}

function ActionButton(){
  const dispatch = useDispatch()
  return(
    <div>
      <button onClick={()=> dispatch(showAddCountryFormFunc({modal:true}))} className='btn btn-sm btn-outline-primary'>
        <i style={{fontSize: '1rem'}} className="mdi mdi-plus-circle me-1"></i>
        Add Country
      </button>
    </div>
  )
}

