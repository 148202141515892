import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../components/Spinner'
import { setCookiesValue } from '../../../../config/redux/slice/appSlice'
import { clearAll, setCustomerLoading, setCustomerSuccessMessage } from '../../../../config/redux/slice/customerSlice'

export default function Permission() {
    const dispatch = useDispatch()
    const {register, handleSubmit, watch, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})

    const [showCookieNotice, setShowCookieNotice] = useState(false)

    const {
      customerLoading,
    } = useSelector((state) => state.customer)

    const {
        cookieManagement: {
            acceptAllCookies,
            acceptMarketingCookies,
            acceptFunctionalCookies,
            acceptAnalyticsCookies,
        }
    } = useSelector((state) => state.app)


    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        dispatch(setCustomerLoading({loading:true}))
        clearWebsiteCookies()

        setCookie("essentialCookies", "true", 30);
        dispatch(setCookiesValue({propertyKey: 'acceptEssentialCookies', value: true}))

        const sortedArr1 = data?.websiteCookies?.slice().sort();
        const sortedArr2 = ['functional', 'marketing', 'analytics'].slice().sort();

        if(sortedArr1.toString() == sortedArr2.toString()){
            setCookie("allCookies", "true", 30);
            dispatch(setCookiesValue({propertyKey: 'acceptAllCookies', value: true}))

        }else{
            for(let type of  data?.websiteCookies){
                if(type == 'marketing'){
                setCookie("marketingCookies", "true", 30);
                dispatch(setCookiesValue({propertyKey: 'acceptMarketingCookies', value: true}))
                }
                else if(type == 'analytics'){
                setCookie("analyticsCookies", "true", 30);
                dispatch(setCookiesValue({propertyKey: 'acceptAnalyticsCookies', value: true}))
                }
                else if(type == 'functional'){
                setCookie("functionalCookies", "true", 30);
                dispatch(setCookiesValue({propertyKey: 'acceptFunctionalCookies', value: true}))
                }
            }
        }
        setTimeout(()=>{
            dispatch(setCustomerLoading({loading:false}))
            dispatch(setCustomerSuccessMessage({message: 'Permissions Saved Successfully!'}))
        }, 1500)
    }

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function clearWebsiteCookies() {
    // Clear Website cookies
    document.cookie = "allCookies=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    document.cookie = "analyticsCookies=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    document.cookie = "marketingCookies=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    document.cookie = "functionalCookies=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    document.cookie = "essentialCookies=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    
}

  return (
    <div className='border-top py-3'>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="px-4 px-md-7">
            <div style={{rowGap: '1.2rem'}} className="row mb-2">

                    <div className="col-12">
                      <div className="form-check">
                        <input 
                          className="form-check-input" type="checkbox" value="essential" disabled id="essentialCookie" checked
                          {...register("websiteCookies")} 
                        />
                        <label className='fs-6' htmlFor='essentialCookie'>Essential</label>
                        <p className="form-check-label">
                          Our website can’t operate properly without essential cookies. We only collect the minimum personal information 
                          needed to provide services on primetic.co.uk. You can disable these cookies in your browser but parts of primetic.co.uk may not work.
                        </p>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        <input 
                            defaultChecked={acceptAllCookies || acceptAnalyticsCookies}
                          className="form-check-input" type="checkbox" value="analytics" id="analyticsCookie"
                          {...register("websiteCookies")} 
                        />
                        <label className='fs-6' htmlFor='analyticsCookie'>Analytics</label>
                        <p className="form-check-label">
                          We use analytical cookies to measure how you use our website and help improve primetic.co.uk.
                        </p>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        <input 
                            defaultChecked={acceptAllCookies || acceptMarketingCookies}
                          className="form-check-input" type="checkbox" value="marketing" id="marketingCookie"
                          {...register("websiteCookies")} 
                        />
                        <label className='fs-6' htmlFor='marketingCookie'>Marketing</label>
                        <p className="form-check-label">
                          We use marketing cookies to display personalised advertisements on other sites you may visit. We work with approved partners to deliver 
                          relevant content and measure the effectiveness of these advertisements.
                        </p>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        <input 
                            defaultChecked={acceptAllCookies || acceptFunctionalCookies}
                          className="form-check-input" type="checkbox" value="functional" id="functionalCookie"
                          {...register("websiteCookies")} 
                        />
                        <label className='fs-6' htmlFor='functionalCookie'>Functional</label>
                        <p className="form-check-label">
                          These cookies let us save the choices you make and some of the information you provide when browsing primetic.co.uk. 
                          They don’t track your browsing activity on other websites. Without functional cookies, primetic.co.uk may not work reliably.
                        </p>
                      </div>
                    </div>

            </div>

        </div>
       <div className="mt-4 px-4 d-flex align-items-center">
        <div>
          {customerLoading ? 
            <Spinner isForm={true}/> :
            <button type="submit" className="btn btn-accent me-3">Save</button>
          }
        </div>
        </div> 
      </form>
    </div>
  )
}
