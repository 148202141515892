import React, { useEffect } from 'react'
import AdminWrapper from '../../../layout/AdminWrapper'
import StadiumMap from '../../../components/StadiumMap'
import { useDispatch, useSelector } from 'react-redux'
import AddSection from './AddSection'
import SectionTable from './SectionTable'
import { showSectionStadiumMapFunc } from '../../../../config/redux/slice/adminSlice'
import EditSection from './EditSection'
import DeleteSection from './DeleteSection'

export default function SectionManagement() {

  const dispatch = useDispatch()
    const {
      venueManagement:{venueDetails},
      sectionManagement:{showAddSectionForm, showSectionStadiumMap, sectionDetails, showSectionModal: {show, type}}
    } = useSelector((state) => state.admin)


    const closeMap = () => {
      dispatch(showSectionStadiumMapFunc({modal: false}))
    }  

  return (
    <AdminWrapper>
      <div>
        {(show && type == 'edit') &&
          <EditSection sectionObj={sectionDetails}/>}
        {(show && type == 'delete') &&  
          <DeleteSection sectionObj={sectionDetails}/>}
        {showSectionStadiumMap && <StadiumMap onClick={closeMap} stadiumObj={venueDetails}/>}
        {/* <StadiumMap stadiumObj={venueDetails}/> */}
        {showAddSectionForm &&
        <AddSection stadiumObj={venueDetails}/>
        }
        <SectionTable stadiumObj={venueDetails}/>
      </div>
    </AdminWrapper>
  )
}
