import React, { useEffect, useRef, useState } from 'react'
import { clearAll, setisAtBottom, setlistLoading } from '../../../config/redux/slice/adminSlice';
import { useDispatch, useSelector } from 'react-redux';
import { app_api } from '../../../utils/endpoints/app_api';
import { debounce } from '../../../helpers/helpers';
import { get_delete_request } from '../../../helpers/request';
export default function SelectComponent({
    options, 
    getEndpoint,
    searchEndpoint,
    searchValue, 
    register, 
    text,
    fieldName,
    fieldName2,
    getValueFunc,
    setValue,
    isReq,
    errors,
    isNotPaginated,
    loadReq=true,
    defaultValue
    }) {
     const dispatch = useDispatch()
     const {
        token
    } = useSelector((state) => state.authData)
     const dropdownRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false);
    const [valueList, setValueList] = useState([])
    const [isSearch, setIsSearch] = useState(false)
    const [searchVal, setSearchVal] = useState(null)
    const [totalValueRecords, setTotalValueRecords] = useState(0)
    const [pageNo, setPageNo] = useState(1)
    const scrollContainerRef = useRef(null);
    
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [isOpenedFirstTime, setIsOpenedFirstTime] = useState(false)
    useEffect(()=>{
      if(loadReq == false){
        if(isOpenedFirstTime){
          get_values()
        }
      }else{
        get_values()
      }
    }, [isOpenedFirstTime])
    useEffect(() => {
      if (defaultValue) {
        const defaultOption = valueList.find((option) => option.id === defaultValue);
        if (defaultOption) {
          setValue(fieldName2, defaultOption.name);
          setValue(fieldName, defaultOption.id);
        }
      }
    }, [defaultValue, valueList]);
   
    const toggleDropdown = () => {
        // clearErrorsFunc('idList')
        if(loadReq == false){
          if(isOpenedFirstTime == false){
            setIsOpenedFirstTime(true)
          }
        }
        setIsOpen(!isOpen);
    };
  const handleClickOutside = (event) => {
    if (dropdownRef.current 
        && !dropdownRef.current.contains(event.target)
        && !event.target.classList.contains('select-inp')
        ){
        setIsOpen(false)
    }
  };
    const selectOption = (textValue, textId) => {
        setValue(fieldName2, textValue)
        setValue(fieldName, textId)
        setIsOpen(false)
    };
const handleScroll = () => {
      const element = scrollContainerRef.current;
      // Calculate the distance between the bottom of the content and the current scroll position
      const distanceToBottom = element.scrollHeight - element.scrollTop - element.clientHeight;
      // Check if the distance to the bottom is very small (considering potential rounding errors)
      const atBottom = distanceToBottom < 1;
      setIsAtBottom(atBottom);
    };
  useEffect(() => {
    // Only set the value if it's not already set
    if (!get_values(fieldName2)) {
      setValue(fieldName2, text);
    }
    document.getElementById('body').addEventListener('click', handleClickOutside);
    
    return () => {
      document.getElementById('body')?.removeEventListener('click', handleClickOutside);
    };
  }, []);
  // const searchParams = searchEndpoint ? '' : (watch('search') ?? '')
  useEffect(()=>{
      if(isAtBottom && (Number(totalValueRecords) > valueList.length)){
        if(!isSearch){
          get_values()
        }
        if(isSearch){
          search_values()
        }
      }
    }, [isAtBottom])
    const get_values  = async () => {
    //   if(isSearch){
    //     setValueList([])
    //     setPageNo(0)
    //   }
    //     dispatch(setlistLoading(true))
    //     dispatch(clearAll({}))
    //     setIsSearch(false)
    let url = isNotPaginated ? `${getEndpoint}` : `${getEndpoint}?page=${pageNo}&pageSize=10`
        const res = await get_delete_request(
          'get',
            token,
            url,
            {},
            dispatch,
            "",
            "",
            ""
        )
        if(res !== undefined && res.status < 400){
          if(isNotPaginated){
            setValueList([...valueList, ...res?.data?.data])
          }else{
            setValueList([...valueList, ...res?.data?.data?.data])
            setTotalValueRecords(res?.data?.data?.meta.total)
            setPageNo(pageNo + 1)
            setListLoading(false)
          }
        }
    }
    const search_values = async (e) => {
      if(searchEndpoint){
        let q;
        if(!isSearch){
          setValueList([])
        }
        if(e){
          setValueList([])
          q = e.target.value
          setSearchVal(e.target.value)
        }else{
          q = searchVal
        }
          setListLoading(true)
          dispatch(clearAll({}))
          const res = await get_delete_request(
            'get',
              token,
              `${searchEndpoint}?q=${q}&page=${!isSearch || e ? 1 : pageNo}&pageSize=10`,
              {},
              dispatch,
              "",
              "",
              ""
          )
          if(res !== undefined && res.status < 400){
            if(!isSearch || e){
              setValueList(res?.data?.data?.data)
              setPageNo(2)
            }else{
              setValueList((prevState)=>{
                return [...valueList, ...res?.data?.data?.data]})
              setPageNo((prevState)=>{
                return pageNo + 1
              })
            }
            setTotalValueRecords(res?.data?.data?.meta.total)
            setListLoading(false)
            setIsSearch(true)
          }else{
            setIsSearch(true)
          }
        }else{
          let filteredList = valueList.filter((item)=>(item.text.toLowerCase()).includes((e.target.value).toLowerCase()))
          setValueList(filteredList)
        }    
    }
  return (
    <div className='position-relative'>
        <div className="input-group select-inp">
            <input 
            onClick={toggleDropdown}
            style={{borderRight: '0px solid transparent'}}
                type='text'
                className="form-control select-inp"
                id={fieldName2}
                readOnly
                {...register(`${fieldName2}`, {
                      required: {
                      value: isReq,
                      message: '*this field is required'
                      },
                      validate: (fieldValue)=> fieldValue !== text || !isReq || "*this field is required"
                  })}
            />
            <span
              onClick={toggleDropdown}
              className={`input-group-text bg-transparent mdi mdi-chevron-down select-inp`}></span>
        </div>
        {
            errors &&
            <div className="text-danger small mt-1">
                {errors}
            </div>
        }
        {isOpen &&
            <div ref={dropdownRef} style={{width:'100%', zIndex: '20'}} className='select-inp position-absolute mt-2 px-0 card border-0 col-12 col-md-10 col-xl-8'>
                <input
                className='form-control py-1 select-inp'
                placeholder="Search"
                type="text"
                onChange={search_values}
                // {...register(`search`)}
                // onKeyDown={(e)=>{
                //     if(e.key == 'Enter'){
                //         e.preventDefault()
                //         search_values(e)
                //     }
                // }}
                />
                {(!isNotPaginated && searchEndpoint) ? 
                <div ref={scrollContainerRef} onScroll={handleScroll} style={{overflowY: 'auto', maxHeight: '250px'}} className='bg-white mt-1 fs-12 border custom-list-group list-group'>
                        <a className="list-group-item custom-list list-group-item-action disabled p-1 ps-3 overflow-visible">
                            {text}
                        </a>
                        {valueList.map((option)=>{
                            return <a 
                                        key={option.id}
                                        style={{cursor: 'pointer'}}
                                        onClick={(e)=>{
                                          e.preventDefault()
                                          selectOption(option.name, option.id)
                                        }} 
                                        className="list-group-item select-inp custom-list list-group-item-action p-1 ps-3 overflow-visible"
                                    >
                                        {option.name}
                                    </a>
                        })}
                        {listLoading &&
                            <div style={{fontSize: '1rem'}} className='text-center'><i className='fa fa-refresh fa-spin'></i></div>
                         }
                </div>
                :
                <div style={{overflowY: 'auto', maxHeight: '250px'}} className='bg-white mt-1 fs-12 border custom-list-group list-group'>
                        <a className="list-group-item custom-list list-group-item-action disabled p-1 ps-3 overflow-visible">
                            {text}
                        </a>
                        {valueList.map((option)=>{
                            return <a 
                                        key={option.id}
                                        style={{cursor: 'pointer'}}
                                        onClick={(e)=>{
                                          e.preventDefault()
                                          selectOption(option.name, option.id)
                                        }
                                        }  
                                        className="list-group-item select-inp custom-list list-group-item-action p-1 ps-3 overflow-visible"
                                    >
                                        {option.name}
                                    </a>
                        })}
                        {listLoading &&
                            <div style={{fontSize: '1rem'}} className='text-center'><i className='fa fa-refresh fa-spin'></i></div>
                         }
                </div>
                }
            </div>
        }
    </div>
  )
}
