import React, { useState } from 'react'
import { useForm } from 'react-hook-form'


export default function PasswordComponent({
  fieldName,
  classStyles = 'col-md-12',
  hasHelperText,
  isSameAs,
  register,
  patternRules,
  label,
  error_msg,
  isReq,
  clearErrors
}) {
  const [showPassword, setShowPassword] = useState()
  const [localError, setLocalError] = useState('');
 
  const handleChange = (event) => {
    console.log(event);
    const { value } = event.target;
    if (value.length > 20) {
      setLocalError('*Maximum length is 20 characters');
    } else {
      setLocalError('');
      if (typeof clearErrors === 'function') {
        clearErrors(fieldName);
      }
    }
 

  };
  return (
    <div className={`form-group mb-3 ${classStyles}`}>
      <label htmlFor="password">{label}</label>
      <div className="input-group">
        <input
          style={{ borderRight: '0px solid transparent' }}
          type={showPassword ? 'text' : 'password'}
          autoComplete='new-password'
          className="form-control"
          // maxLength={21}
          {...register(`${fieldName}`, {
            required: {
              value: isReq,
              message: '*this field is required',
            },
            pattern: {
              value:
                patternRules === 'password'
                 ? /^(?=.*[A-Z])(?=.*\d)(?=.*[@^$!%*?&()])[A-Za-z\d@^$!%*?&()]{8,20}$/
                  : '',
              message:
                'Invalid input. Password must be at least 8 character long and include at least 1 Upper case, 1 Digit, and 1 special character (@^$!%*?&)',
            },

            validate: (fieldValue) => {
              if (isSameAs) {
                return fieldValue === isSameAs || 'Passwords does not match'
              }
            },
          })}
          onChange={handleChange}

        />
        <span
          onClick={() => setShowPassword(!showPassword)}
          className={`input-group-text bg-transparent ${!showPassword ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'
            }`}
        ></span>
      </div>
      {localError && <div className="text-danger small mt-1">{localError}</div>}
      {error_msg && <div className="text-danger small mt-1">{error_msg}</div>}
      {!error_msg && hasHelperText && (
        <span style={{ fontSize: '0.7rem' }} className="mt-2 d-block">
          Note: Password must be at least 8 character long and include at least
          1 Upper case, 1 Digit, and 1 special character (@^$!%*?&)
        </span>
      )}
    </div>
  )
}
