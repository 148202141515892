import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    supportLoading: false,
    supportErrorMsg: null,
    supportSuccessMsg: null,
    isSidebarOpen: false,
    hasSelectListReachedBottom: false,
    loadingMoreSelectOptions: false,
    adminManagement: {
        showAddAdminForm: false,
        newData: false,
    },
    userManagement: {
        showAddUserForm: false,
        newData: false,
        userDetails: {},
        showUserModal:{
            show: false,
            type: null 
        } 
    },
    countryManagement: {
        countriesData: [],
        newData: false,
        showAddCountryForm: false,
        countryDetails: {},
        showCountryModal: {
            show: false,
            type: null 
        } 
    },
    teamManagement: {
        newData: false,
        showAddTeamForm: false,
        teamDetails: {},
        showTeamModal: {
            show: false,
            type: null 
        }
    },
    listingManagement: {
        newData: false,
        listingDetails: {},
        showListingModal: {
            show: false,
            type: null 
        }
    },
    payoutManagement: {
        dueNewData: false,
        newData: false,
        payoutDueData: [],
        payoutData: [],
        payoutDueDetails: {},
        payoutDetails: {},
        updatedInfo:{},
        payoutEventId: '',
        showPayoutModal: {
            show: false,
            type: null 
        }
    },
    competitionManagement: {
        newData: false,
        showAddCompetitionForm: false,
        competitionDetails: {},
        showCompetitionModal: {
            show: false,
            type: null 
        }
    },
    cityManagement: {
        newData: false,
        showAddCityForm: false,
        cityDetails: {},
        showCityModal: {
            show: false,
            type: null 
        }
    },
    venueManagement: {
        newData: false,
        showAddVenueForm: false,
        venueDetails: {},
        showVenueModal: {
            show: false,
            type: null 
        }
    },
    sectionManagement: {
        newData: false,
        showAddSectionForm: false,
        showSectionStadiumMap: false,
        sectionDetails: {},
        sectionsData: [],
        showSectionModal: {
            show: false,
            type: null 
        }
    },
    subSectionManagement: {
        newData: false,
        showSubStadiumMap: false,
        showAddSubSectionForm: false,
        subSectionDetails: {},
        subSectionsData: [],
        showSubsectionModal: {
            show: false,
            type: null 
        }
    },
    eventManagement: {
        newData: false,
        showAddEventForm: false,
        eventDetails: {},
        selectedMapSection: '',
        showEventModal: {
            show: false,
            type: null 
        }
    },
    orderManagement: {
        newData: false,
        orderDetails: {}
    },
}

export const supportSlice = createSlice({
    name: 'supportApp',
    initialState,
    reducers: {
        logoutSupportUser: (state, action) => {
              return initialState
        },
        clearAll: (state, action) => {
            state.supportLoading = false
            state.supportErrorMsg = null
            state.supportSuccessMsg = null
        },
        setSupportLoadingUser: (state, action) => {
            state.supportLoading = action?.payload?.loading
        },
        setSupportErrorMessageUser: (state, action) => {
            let msg = action.payload.message
            state.supportLoading = false
            state.supportErrorMsg = msg
        },
        setSupportSuccessMessageUser: (state, action) => {
            let msg = action.payload.message
            state.supportLoading = false
            state.supportSuccessMsg = msg
        },
    }

})

// Action creators are generated for each case reducer function
export const {
    clearAll,
    setSupportErrorMessageUser,
    setSupportLoadingUser,
    setSupportSuccessMessageUser,
    logoutSupportUser,
} = supportSlice.actions

export default supportSlice.reducer