import React, { useState } from 'react';

export default function DeliveryAddress({ showDbPopup, setShowDbPopup, selectedItem }) {

  const closeDbPopup = () => {
    setShowDbPopup(false);
  };

  if (!selectedItem || !selectedItem.OrderItems || selectedItem.OrderItems.length === 0) {
    return null; // Or handle this case differently based on your UI requirements
  }

  const orderItem = selectedItem.OrderItems[0];
  const user = orderItem.order.user;
  const ticket = orderItem.ticket;
  const deliveryAddress = orderItem.deliveryAddress;

  return (
    <div id="demo-modal" className="modal delivery-addresssec" style={{ display: showDbPopup ? 'flex' : 'none' }}>
      {console.log(".........................................",selectedItem)}
      <div className="modal__content">
        <div className="modalFields">
          <div className='model-sec' style={{ background: "transparent" }}>
            <ul className="list-group">
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>Name</div><span className='mx-2'>:</span> <div> {user.name} </div></li>
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>Email</div><span className='mx-2'>:</span> <div>{user.email} </div></li>
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>Town </div> <span className='mx-2'>:</span>  <div> {ticket.listing.ticketsType === "PHYSICAL" ? (deliveryAddress ? deliveryAddress.town :  user.town) : user.town}  </div></li>
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>Address </div><span className='mx-2'>:</span> <div> {ticket.listing.ticketsType === "PHYSICAL" ? (deliveryAddress ? deliveryAddress.address : user.firstLineAddress) : user.firstLineAddress} </div></li>
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>Postcode</div><span className='mx-2'>:</span> <div>{ticket.listing.ticketsType === "PHYSICAL" ? (deliveryAddress ? deliveryAddress.postCode : user.postCode) : user.postCode}</div></li>
              <li className="list-group-item d-flex"><div className='lbl-delivey-width'>Phone</div>  <span className='mx-2'>:</span>  <div>{ticket.listing.ticketsType === "PHYSICAL" ? (deliveryAddress ? deliveryAddress.phoneNumber : user.phone) : user.phone}</div></li>
            </ul> 
          </div>
        </div>
        <a onClick={closeDbPopup} className="modal__close">
          <i className="fa fa-close"></i>
        </a>
      </div>
    </div>
  );
}
