import React from 'react'

export default function DataTableWrapper({children, table_id, table_heading, table_buttons}) {
  return (
    <div className="card">
      {(table_heading || table_buttons) &&
        <div style={{rowGap: '0.9rem'}} className="py-3 card-header border-bottom d-flex align-items-center justify-content-between flex-wrap">
            <h6 className="fw-bold mb-0">{table_heading}</h6>
            {table_buttons && <>{table_buttons}</>}
        </div>}
        <div className="card-body">
            <table id={table_id} className="table table-striped table-product">
                {children}
            </table>
        </div>
    </div>
  )
}
