import React, { useEffect } from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import { useDispatch, useSelector } from 'react-redux'
import { showCityModalFunc } from '../../../../config/redux/slice/adminSlice'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'
import { clearAll, onUpdate, setCustomerErrorMessage, setCustomerLoading, setListingValues, showListingModalFunc } from '../../../../config/redux/slice/customerSlice'

export default function DeleteListing({ getList }) {
    const dispatch = useDispatch()

    // const {
    //     customerLoading,
    //   listingManagement: {listingDetails}
    // } = useSelector((state) => state.customer)
    const {
      customerLoading,
    listingManagement: {singleListingDetails, listingDetails}
  } = useSelector((state) => state.customer)

    const {
        token
    } = useSelector((state) => state.authData)

    const onSubmit = async () => {
        dispatch(clearAll({}))
        const res = await get_delete_request(
          "delete",
            token,
            `${app_api.delete_listing(singleListingDetails?.id)}`,
            {},
            dispatch,
            setCustomerLoading,
            "",
            setCustomerErrorMessage
        )
        if(res !== undefined && res.status < 400){
          getList()
          dispatch(onUpdate({message: res?.data?.message, type: 'listing'}))
          dispatch(setListingValues({propertyKey: 'newData', value: true}))
        }
    }

    const closeModal = () => {
      dispatch(showListingModalFunc({modal: false, type: null}))
    }

    return (
    <ModalLayout onClick={closeModal} heading={'Delete Listing'}>
        <div className="modal-body">
            <p>Are you sure you want to delete this listing?</p>
        </div>
        <div className="modal-footer border-top-0 d-flex align-items-center">
            <div>
            {customerLoading ? 
                <Spinner isForm={true}/> :
                <button onClick={onSubmit} type="button" className="btn btn-danger me-3">Delete</button>
            }
            </div>
            <button disabled={customerLoading ? true : false} onClick={closeModal} type="button" className="btn btn-info">Cancel</button>
        </div>
    </ModalLayout>
  )
}
