import React from 'react'

export default function MiddleSectionComponent({
  children,
  heading_text,
  middle_text,
  bottom_text,
}) {
  return (
    <div
      style={{ zIndex: -1 }}
      className="position-relative text-center mt-3 mb-2 mb-md-3 mb-lg-0 py-3 py-md-3 px-3 px-sm-5 px-md-100 px-lg-312"
    >
      {heading_text && (
        <>
          <h2 className="text-primary fw-bold d-block d-sm-none fs-5">
            {heading_text}
          </h2>
          <h2 className="text-primary fw-bold d-none d-sm-block d-md-none fs-4">
            {heading_text}
          </h2>
          <h2 className="text-primary fw-bold d-none d-md-block d-lg-none fs-3">
            {heading_text}
          </h2>
          <h2 className="text-primary fw-bold d-none d-lg-block fs-2">
            {heading_text}
          </h2>
        </>
      )}
      {middle_text && (
        <>
          <p className="d-block d-sm-none fs-6 my-2 my-md-4">{middle_text}</p>
          <p className="d-none d-sm-block d-md-none fs-5 my-2 my-md-4">
            {middle_text}
          </p>
          <p className="d-none d-md-block d-lg-none fs-5 my-2 my-md-4">
            {middle_text}
          </p>
          <p className="d-none d-lg-block fs-4 my-2 my-md-4">{middle_text}</p>
        </>
      )}
      {bottom_text && (
        <>
          <p className="d-block d-sm-none fs-5 text-primary fw-bold">
            {bottom_text}
          </p>
          <p className="d-none d-sm-block d-md-none fs-4 text-primary fw-bold">
            {bottom_text}
          </p>
          <p className="d-none d-md-block d-lg-none fs-3 text-primary fw-bold">
            {bottom_text}
          </p>
          <p className="d-none d-lg-block fs-2 text-primary fw-bold">
            {bottom_text}
          </p>
        </>
      )}
      {children}
    </div>
  )
}
