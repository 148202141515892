import React, { useEffect } from 'react'
import AdminWrapper from '../../../layout/AdminWrapper'
import AdminTable from './AdminTable'
import CreateAdmin from './CreateAdmin'
import { useDispatch, useSelector } from 'react-redux'
import { dispatch } from 'd3'
import { clearAll } from '../../../../config/redux/slice/adminSlice'
import { get_delete_request } from '../../../../helpers/request'

export default function AdminManagement() {

  const dispatch = useDispatch()

  const {
    adminManagement: {showAddAdminForm}
    } = useSelector((state) => state.admin)

    const {
        userData: {user_role}
    } = useSelector((state) => state.authData)

    useEffect(() => {
      dispatch(clearAll({}))
    },[])
    
  return (
    <AdminWrapper>
      <div>
        {(showAddAdminForm && user_role.toLowerCase() == 'super_admin') &&
          <CreateAdmin/>
        }  
        <AdminTable/>
      </div>
    </AdminWrapper>
  )
}
