import React from 'react'
import { useSelector } from 'react-redux'

export default function ProfileView() {
  const {
        userData
    } = useSelector((state) => state.authData)
  return (
    <ul className="list-group rounded-0">
        <li className="list-group-item  border-start-0">
            <span className='fw-bold'>Name: </span> 
            <span>{userData?.fullname}</span>
        </li>
        <li className="list-group-item border-start-0">
            <span className='fw-bold'>Email Address: </span> 
            <span>{userData?.email}</span>
        </li>
        <li className="list-group-item border-start-0">
            <span className='fw-bold'>Phone: </span> 
            <span>{userData?.phone ?? 'N/A' }</span>
        </li>
        <li className="list-group-item border-start-0">
            <span className='fw-bold'>Address: </span> 
            <span>{userData?.address ?? 'N/A' }</span>
        </li>
    </ul>
  )
}
