import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearAll, onUpdate, setCustomerErrorMessage, setCustomerLoading, setLastVisitedPage, setDeliveryDetails } from '../../../../config/redux/slice/customerSlice'
import { onAddToCart, onGetCart, onRemoveFromCart, setCartValues, setData } from '../../../../config/redux/slice/appSlice'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { formatNumber, setCartNo, transformTicketsList1 } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
import Spinner from '../../../components/Spinner'
import { app_urls } from '../../../../utils/urls/app_urls'
import { useDebounce } from '../../../../hooks/useDebounce'
import { country_list } from '../../../../helpers/countries'
import RestrictionsPage from './RestrictionsPage'




export default function ConfirmationPage({ onClick }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openSeat, setOpenSeat] = useState(false)
  const [listingId, setListingId] = useState('')
  const [sum, setSum] = useState(0)
  const [cartAmountData, setCartAmountData] = useState({})
  const [isRemovingFromCart, setIsRemovingFromCart] = useState({})
  const [address, setAddress] = useState(''); //XD_J
  const [postCode, setPostCode] = useState(''); //XD_J
  const [phoneNumber, setPhoneNumber] = useState(''); //XD_J
  const [town, setTown] = useState('');  //XD_J
  const [errors, setErrors] = useState({}); //XD_J

  const [country_code, set_country_code] = useState('Select Country')
  const [filtered_country_list, set_filtered_country_list] = useState(country_list)
  const [search_value, set_search_value] = useState('')
  const search_value_debounced = useDebounce(search_value, 200)
  const [promoCode, setPromoCode] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [discount, setDiscount] = useState('0.00');
  const [finalAmount, setFinalAmount] = useState('0.00');



  useEffect(() => {
    if (search_value_debounced) {
      set_filtered_country_list(country_list.filter((option) => (option.name.toLowerCase()).includes((search_value_debounced.toLowerCase()))))
    } else {
      set_filtered_country_list(country_list)
    }
  }, [search_value_debounced])

  const {
    customerLoading,
    paymentManagement: { clientPayment }
  } = useSelector((state) => state.customer)

  const {
    token
  } = useSelector((state) => state.authData)

  const {
    cartManagement: { cartData, cartNumber }
  } = useSelector((state) => state.app)

  useEffect(() => {
    getCartTotal()
  }, [cartData])

  useEffect(() => {
    let x = 0
    cartData.forEach((listing) => {
      listing.Tickets.forEach((opt) => {
        x = Number(opt.price) + x
      })
    })
    setSum(x)
  }, [cartData])

  const getCartTotal = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_cart_total}`,
      {},
      dispatch,
      "",
      "",
      ""
    )
    if (res !== undefined && res.status < 400) {
      setCartAmountData(res?.data?.data);
      setFinalAmount(res?.data?.data?.totalPrice);
      console.log('aaa', finalAmount);
    }
  
  }

  const setCartData = async (data) => {
    dispatch(onGetCart(data))
    dispatch(setData({ data: data, type: 'cart' }))
    let cartNo = setCartNo(data)
    dispatch(setCartValues({ propertyKey: 'cartNumber', value: cartNo }))
  }
  //XD_J

  const handleApplyClick = async () => {
    console.log('Promo Code:', promoCode);
    // let promoCode = promoCode;
    const res = await post_put_patch_request(
      "post",
      token,
      app_api.apply_promoCode,
      {promoCode},
      dispatch,
      setCustomerLoading,
      "",
      setCustomerErrorMessage
    )
    if (res !== undefined && res.status < 400) { 
      console.log('cart', cartAmountData);
      if (res !== undefined && res.status < 400) {
        console.log('res', res);
        dispatch(onUpdate({ message: res?.data.message, type: 'sales' }))

        const discountPercentage = res?.data?.data?.percentage; // Extract the discount percentage from the response
    
        if (discountPercentage && cartAmountData?.totalPrice) {
          const totalPrice = parseFloat(cartAmountData?.totalPrice);
          const discountAmount = (cartAmountData.totalPrice * discountPercentage) / 100;
          const reducedPrice = cartAmountData.totalPrice - discountAmount;
          setTotalPrice(totalPrice);
          setDiscount(discountAmount);
          setFinalAmount(reducedPrice);
          let data = await post_put_patch_request(
            "post",
            token,
            app_api.save_promoCode,
            { promoCode,            // Promo code to be applied
              discount: discountAmount,   // Calculated discount amount
              reducedPrice,
              totalPrice},
            dispatch,
            setCustomerLoading,
            "",
            setCustomerErrorMessage
          )
        }
      }

     };
  }

  const select_country_code = (code) => {
    //setValueFunc(`${fieldName2}`,code)
    set_country_code(code)

    //clearErrorsFunc('code')
    set_search_value('')
  }
  const validateForm = () => {
    const errors = {};

    if (!address.trim()) {
      errors.address = 'Address is required';
    }

    if (!postCode.trim()) {
      errors.postCode = 'Post Code is required';
    }

    if (!phoneNumber.trim()) {
      errors.phoneNumber = 'Phone Number is required';
    }

    if (!town.trim()) {
      errors.town = 'Town is required';
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;

  };
  //XD_J

  const removeFromCart = async (ticketDetails, sellTogether, index) => {
    dispatch(clearAll({}))
    setIsRemovingFromCart({
      [index]: true
    })
    let objId = ticketDetails.custom_id
    // dispatch(setCartValues({propertyKey: 'cartNumber', value: cartNumber - 1}))
    let ticketIds = []
    ticketDetails.Tickets.forEach((ticketOption) => {
      ticketIds = [...ticketIds, ticketOption.id]
    })
    // dispatch(onRemoveFromCart({id:objId, sellTogether, listing: ticketDetails, ticketIds}))
    let data = { ticketIds }
    // dispatch(setFormData({data, type: 'listing'}))
    const res = await post_put_patch_request(
      "patch",
      token,
      `${app_api.remove_from_cart}`,
      data,
      dispatch,
      setCustomerLoading,
      "",
      setCustomerErrorMessage
    )
    // if(res !== undefined && res.status >= 400){
    //   dispatch(setCartValues({propertyKey: 'cartNumber', value: cartNumber + 1}))
    //     dispatch(onAddToCart({ticketDetails, sellTogether}))
    // } 
    if (res !== undefined && res.status < 400) {
      setCartData(res?.data?.data)
      setIsRemovingFromCart({
        [index]: false
      })
      // dispatch(onRemoveFromCart({id:objId, sellTogether, listing: ticketDetails, ticketIds}))
      // dispatch(setCartValues({propertyKey: 'cartNumber', value: cartNumber - 1}))
    } else {
      setIsRemovingFromCart({
        [index]: false
      })
    }

  }

  const [errorAddress, setAddressError] = useState('');
  const [postCodeError, setPostCodeError] = useState('');
  const [townError, setTownError] = useState('');
  const [localPhoneError, setLocalPhoneError] = useState('');
  

  const handleAddressChange = (e) => {
    const { value } = e.target;
    if (value.length > 50) {
      setAddressError('*Maximum length is 50 characters');
    } else {
      setAddressError('');
      setAddress(value);
    }
  };
  const handleTownChange = (e) => {
    const { value } = e.target;
    if (value.length > 30) {
      setTownError('*Maximum length is 30 characters');
    } else {
      setTownError('');
      setTown(value);
    }
  };

  const handlePostCodeChange = (e) => {
    const { value } = e.target;
    if (value.length > 10) {
      setPostCodeError('*Maximum length is 10 characters');
    } else {
      setPostCodeError('');
      setPostCode(value);
    }
  };

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    if (value.length > 20) {
      setLocalPhoneError('*Maximum length is 20 characters');
    } else {
      setLocalPhoneError('');
      setPhoneNumber(formatPhoneNumber(value));
    }
  };



  const formatPhoneNumber = (phoneNumber) => {
    // Remove leading zeros
    const cleanedNumber_ = phoneNumber.replace(/^0+/, '');
    // Remove any non-digit characters from the phone number
    const cleanedNumber = cleanedNumber_.replace(/\D/g, '');
    // Format the number with spaces between groups of digits
    const formattedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
    return `${country_code} ${formattedNumber}`;
    //return formattedNumber;
  };


  return (
    <>
      <h5 className="mb-3 fw-bold">Order Confirmation</h5>
      <div className='confirmation-card'>
        {/* <div className='mb-4'>
            <h6 className='fw-bold'>Shipping</h6>
            <ul className="list-group mb-3 mt-3">
              <li className="list-group-item lh-base">
                  <div className='row'>
                  <p className="text-dark col-md-6"><span className='fw-bold'>Name:</span> Idumeka Oritogun</p>
                  <p className="text-dark col-md-6"><span className='fw-bold'>Email:</span> Idumeka Oritogun</p>
                  </div>
              </li>
              <li className="list-group-item lh-base">
                  <div className='row'>
                  <p className="text-dark col-md-6"><span className='fw-bold'>Address:</span> Kabusa Gardens Estate</p>
                  </div>
              </li>
            </ul>
        </div> */}
        {/* XD_J */}
        {(cartData.length > 0 && cartData[0].ticketsType === "PHYSICAL") &&
          <>
            <h6 className='fw-bold'>Delivery Details</h6>
            <div className="row w-100">
              <div className="col-md-6 mb-3">
                <label htmlFor="address" className="form-label">Address</label>
                <input type="text" className="form-control" id="address" value={address}
                  onChange={handleAddressChange} />
                {errorAddress && <div className="text-danger small mt-1">{errorAddress}</div>}
                {errors.address && <div className="text-danger">{errors.address}</div>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="postCode" className="form-label">Post Code</label>
                <input type="text" className="form-control" id="postCode" value={postCode} onChange={handlePostCodeChange} />
                {postCodeError && <div className="text-danger small mt-1">{postCodeError}</div>}
                {errors.postCode && <div className="text-danger">{errors.postCode}</div>}
              </div>
            </div>
            <div className="row w-100">

              <div className="col-md-6 mb-3">
                <label htmlFor="town" className="form-label">Town</label>
                <input type="text" className="form-control" id="town" value={town} onChange={handleTownChange} />
                {townError && <div className="text-danger small mt-1">{townError}</div>}
                {errors.town && <div className="text-danger">{errors.town}</div>}
              </div>
              {/* <div className="col-md-6 mb-3">
                <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                <div className="phone-wrapper-sec">
                  <div className="">
                    <button className="btn btn-outline-gray dropdown-toggle btn-padd-country" type="button" data-bs-toggle="dropdown" aria-expanded="false">{country_code}</button>
                    <ul style={{ maxHeight: '350px', overflowY: 'auto' }} className="dropdown-menu">
                      <li className='px-2 py-2'>
                        <span className="fs-12">
                          <input value={search_value} onChange={({ target: { value } }) => set_search_value(value)} type='search' className='form-control py-2' placeholder='Search country' />
                        </span>
                      </li>
                      {filtered_country_list.map((option) => {
                        return <li key={option.code} style={{ cursor: 'pointer' }} onClick={() => { select_country_code(option.phoneCode) }}>
                          <span className="dropdown-item fs-12">
                            <img className='me-2' width={25} src={option.flag} />
                            {option.name}
                          </span>
                        </li>
                      })}
                    </ul>
                  </div>

                  <input type="text" className="form-control" id="phoneNumber" value={phoneNumber} onChange={handlePhoneChange} />
                </div>
                {localPhoneError && <div className="text-danger small mt-1">{localPhoneError}</div>}
                {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
              </div> */}
              <div className="col-md-6 mb-3">
          <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
          <input type="text" className="form-control" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
          {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
          </div>
     
            </div>
          </>}
        {/* XD_J */}
        <div className='mb-4'>
          <h6 className='fw-bold'>
            <span className="text-dark me-2">Cart</span>
            <span className="badge bg-warning rounded-pill">{cartNumber}</span>
          </h6>
          <>
            {cartData.length != 0 ?
              <>
                {cartData.map((listingOption, ind) => {
                  return <React.Fragment key={ind}>
                    {transformTicketsList1(listingOption?.Tickets, listingOption).map((option, index) => {
                      return <React.Fragment key={option.id}>
                        <>
                          <div className='mb-4'>
                            <span className={`badge text-white me-2 mb-1 ${option?.ticketIds.length > 1 ? 'bg-success' : 'bg-danger'}`}>
                              {option?.ticketIds.length > 1 ? 'Group' : 'Single'}
                            </span>
                            {/* <h6 className='fw-bold'>Tickets sold separately</h6> */}
                            <div className='pb-4 px-4 pt-4 border position-relative'>
                              <p className="ticket-text mb-2"><span className="fw-bold">Event: </span>
                                {listingOption?.event?.name}</p>
                              <p className="ticket-text mb-2"><span className="fw-bold">Section: </span>
                                {listingOption?.section?.name}</p>
                             
                              {option?.ticketIds.length > 1 ?
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-column gap-2">
                                    <p className="ticket-text mb-2"><span className="fw-bold">Block </span>
                                      {listingOption?.subSection?.name}</p>
                                      <p className="ticket-text mb-2">
                                      <span className="fw-bold">Ticket Type: </span>
                                      {listingOption?.ticketsType === 'ELECTRONIC' && listingOption?.Tickets.some(ticket => ticket.file !== null)
                                        ? 'ELECTRONIC (Instant)'
                                        : listingOption?.ticketsType}
                                    </p>
                                    <p className="ticket-text mb-2"><span className="fw-bold">No. of Tickets </span>
                                      {option?.numberOfTickets}</p>

                                     

                                   
                                    {/* <div className='d-flex gap-2 align-items-center'>
                                      <p className="ticket-text fw-bold">Seats</p>
                                      <button
                                        onClick={() => {
                                          setOpenSeat(!openSeat)
                                          setListingId(option.id)
                                        }}
                                        className="btn btn-sm ticket-btn py-0"
                                      >
                                        View
                                        <i
                                          className={`mdi mdi-chevron-down ms-1 fs-6`}></i>
                                      </button>
                                    </div> */}
                                  </div>
                                  <div className="d-flex flex-column align-items-center gap-2">
                                    <div className="d-flex flex-column gap-2">
                                      {listingOption?.pricePerTicket == option.price ?
                                        <>
                                          <p className="ticket-text mb-2"><span className="fw-bold">Price </span>
                                            {strText.currency_symbol}{formatNumber(listingOption?.pricePerTicket)}</p>
                                          <p className="ticket-text mb-2"><span className="fw-bold">Total Price </span>
                                            {strText.currency_symbol}{formatNumber(Number(option?.numberOfTickets) * Number(listingOption?.pricePerTicket))}</p>
                                        </>
                                        :
                                        <>
                                          <p className="ticket-text mb-2 position-relative"><span className="fw-bold">Price </span>
                                            <span className='bottom-50 mb-1 position-absolute'>{strText.currency_symbol}{formatNumber(option?.price)}</span>
                                            <span className='text-decoration-line-through'>{strText.currency_symbol}{formatNumber(listingOption?.pricePerTicket)}</span></p>
                                          <p className="ticket-text mb-2 position-relative"><span className="fw-bold">Total Price </span>
                                            <span className='bottom-50 mb-1 position-absolute'>{strText.currency_symbol}{formatNumber(Number(option?.numberOfTickets) * Number(option?.price))}</span>
                                            <span className='text-decoration-line-through'>{strText.currency_symbol}{formatNumber(Number(option?.numberOfTickets) * Number(listingOption?.pricePerTicket))}</span>
                                          </p>
                                        </>
                                      }
                                    </div>
                                    <button
                                      style={{ minWidth: '70px' }}
                                      onClick={() => {
                                        removeFromCart(
                                          { ...listingOption, custom_id: option.id, Tickets: option.detailsList },
                                          'neither', index)
                                      }}
                                      className={`btn ticket-btn bg-danger text-white`}
                                      disabled={customerLoading}
                                    >
                                      {isRemovingFromCart?.[index] ? <Spinner color='text-white' spinner_styles={{ width: '1rem', height: '1rem' }} /> :
                                        'Remove'}
                                    </button>
                                  </div>
                                </div>
                                :
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-column gap-2">
                                    <p className="ticket-text mb-2"><span className="fw-bold">Block </span>
                                      {listingOption?.subSection?.name}</p>
                                      <p className="ticket-text mb-2">
                                      <span className="fw-bold">Ticket Type: </span>
                                      {listingOption?.ticketsType === 'ELECTRONIC' && listingOption?.Tickets.some(ticket => ticket.file !== null)
                                        ? 'ELECTRONIC (Instant)'
                                        : listingOption?.ticketsType}
                                    </p>
                                    

                                    {/* <p className="ticket-text"><span className="fw-bold">Seat</span> {option?.seatNos[0]}</p> */}
                                  </div>
                                  <div className="d-flex flex-column align-items-center gap-2">
                                    {listingOption?.pricePerTicket == option.price ?
                                      <p className="ticket-text mb-2"><span className="fw-bold">Price </span>
                                        {strText.currency_symbol}{formatNumber(listingOption?.pricePerTicket)}</p>
                                      :
                                      <p className="ticket-text mb-2 position-relative"><span className="fw-bold">Price </span>
                                        <span className='bottom-50 mb-1 position-absolute'>{strText.currency_symbol}{formatNumber(option.price)}</span>
                                        <span className='text-decoration-line-through'>{strText.currency_symbol}{formatNumber(listingOption?.pricePerTicket)}</span></p>
                                    }
                                    <button
                                      style={{ minWidth: '70px' }}
                                      onClick={() => {
                                        removeFromCart(
                                          { ...listingOption, custom_id: option.id, Tickets: option.detailsList },
                                          'neither', index)
                                      }}
                                      className={`btn ticket-btn bg-danger text-white`}
                                      disabled={customerLoading}
                                    >
                                      {isRemovingFromCart?.[index] ? <Spinner color='text-white' spinner_styles={{ width: '1rem', height: '1rem' }} /> :
                                        'Remove'}
                                    </button>
                                  </div>
                                </div>
                              }
{/*                                  
                              {(openSeat && listingId == option.id) &&
                                <>
                                  <hr className="my-4" />
                                  <h6 className='fw-bold mx-4'>Seat Numbers</h6>
                                  <div className='px-4'>
                                    {option?.seatNos?.map((singleItem, idx) => {
                                      return <span key={idx} className="badge text-white bg-accent me-2">{singleItem}</span>
                                    })}
                                  </div>
                                </>
                              } */}

                            </div>
                           
                           </div>
                          {listingOption?.restrictionsAndFeatures && listingOption.restrictionsAndFeatures.trim() !== '' && (
                            <div className='pb-4 px-4 pt-4 border position-relative'>
                              <div>
                                <h6 className="fw-bold me-3 mb-0" style={{ color: "#000", fontSize: "16px" }}>
                                  Features & Restrictions
                                </h6>
                                <div className="mt-3 p-3 border tickets-listsec">
                                  <div className="grid-container">
                                    {listingOption.restrictionsAndFeatures
                                      .split('|')
                                      .map((feature, index) => (
                                        <div className="form-check" key={index}>

                                          <i className="fa fa-arrow-right" style={{ marginRight: "5px", fontSize: "12px" }}></i>
                                          <label className="mb-0" htmlFor={`checkbox-${index}`}>
                                            {feature.trim()}
                                          </label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      </React.Fragment>
                    })}
                  </React.Fragment>
                })}
              </>
              :
              <p className='text-muted'>No Items In Cart</p>
            }
          </>
        </div>
        <div
  style={{ columnGap: '0.6rem' }}
  className="border py-2 px-2 d-flex flex-column"
>
          <div className="d-flex align-items-center">
            <span className="fw-bold">Use Promo Code</span>
            <div>
              <input
                type="text"
                placeholder="Enter promo code"
                style={{ marginRight: '10px' }}
                value={promoCode}
                className='promocode-input'
            onChange={(e) => setPromoCode(e.target.value)}
              />
              <button className="btn btn-primary" onClick={handleApplyClick}>Apply</button>
            </div>
          </div>
          <div className="d-flex flex-column mt-3">
            <div className="d-flex justify-content-between mb-2">
              <span className="fw-bold">Total Price</span>
              <span className="fw-bold">
                {strText.currency_symbol}
                {formatNumber(cartAmountData?.totalPrice)}
              </span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span className="fw-bold">Discount Price</span>
              <span className="fw-bold">
                {strText.currency_symbol}
                {formatNumber(discount)}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="fw-bold">Final Price</span>
              <span className="fw-bold">
                {strText.currency_symbol}
                {formatNumber(finalAmount)}
              </span>
            </div>
          </div>

        </div>


        <hr className="my-4" />
        <>
          {
            cartData.length > 0 &&
            <button
              onClick={() => {
                const containsPhysicalTicket = cartData.some(item => item.ticketsType === "PHYSICAL");

                if (containsPhysicalTicket) {
                  const isValid = validateForm();
                  if (isValid) {
                    dispatch(
                      setDeliveryDetails({
                        address,
                        postCode,
                        phoneNumber,
                        town,
                      })
                    );
                    onClick();
                  }
                } else {
                  dispatch(
                    setDeliveryDetails({
                      address,
                      postCode,
                      phoneNumber,
                      town,
                    })
                  );
                  onClick();
                }
              }} className="w-100 btn btn-primary btn-lg" type="button">Continue to Payment</button>
            // <button onClick={handleContinueToPayment} className="w-100 btn btn-primary btn-lg" type="button">Continue to Payment</button>
          }
        </>

      </div>
      {/* <RestrictionsPage setShowDbPopup={setShowDbPopup} showDbPopup={showDbPopup}  selectedItem={selectedItem} /> */}

    </>
  )
}
