import React, { useEffect, useRef, useState } from 'react'
import ModalLayout from '../layout/ModalLayout'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  setAppLoading,
  setEventValues,
  showEventModalFunc,
} from '../../config/redux/slice/appSlice'
import { useForm } from 'react-hook-form'
import { app_api } from '../../utils/endpoints/app_api'
import { get_delete_request } from '../../helpers/request'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../utils/urls/app_urls'

export default function SearchModal() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm({ mode: 'onTouched' })
  const scrollContainerRef = useRef(null)
  const [totalValueRecords, setTotalValueRecords] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [searchVal, setSearchVal] = useState('')
  const [isAtBottom, setIsAtBottom] = useState(false)
  const [listLoading, setListLoading] = useState(false)

  const {
    eventManagement: {
      searchResult,
      showEventModal: { page },
    },
  } = useSelector((state) => state.app)
  const { token } = useSelector((state) => state.authData)

  const closeModal = () => {
    dispatch(showEventModalFunc({ modal: false, type: null }))
  }

  useEffect(() => {
    return () => {
      dispatch(setEventValues({ propertyKey: 'searchResult', value: [] }))
      // dispatch(showEventModalFunc({modal: false, type: null}))
    }
  }, [])

  useEffect(() => {
    if (isAtBottom && Number(totalValueRecords) > searchResult.length) {
      onSubmit({ name: searchVal })
    }
  }, [isAtBottom])

  const handleScroll = () => {
    const element = scrollContainerRef.current

    // Calculate the distance between the bottom of the content and the current scroll position
    const distanceToBottom =
      element.scrollHeight - element.scrollTop - element.clientHeight

    // Check if the distance to the bottom is very small (considering potential rounding errors)
    const atBottom = distanceToBottom < 1
    setIsAtBottom(atBottom)
  }

  const onSubmit = async (data) => {
    dispatch(clearAll({}))
    setSearchVal(data.name)
    setListLoading(true)
    let page = searchVal === data.name ? pageNo : 1
    if (searchVal === data.name) {
      page = pageNo
    } else {
      page = 1
      dispatch(setEventValues({ propertyKey: 'searchResult', value: [] }))
    }
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.search_events}?q=${data.name}&page=${page}&pageSize=10`,
      {},
      dispatch,
      setAppLoading,
      '',
      ''
    )
    if (res !== undefined && res.status < 400) {
      dispatch(
        setEventValues({
          propertyKey: 'searchResult',
          value: [...searchResult, ...res?.data?.data?.data],
        })
      )
      setPageNo(pageNo + 1)
      setTotalValueRecords(res?.data?.data?.meta.total)
      setListLoading(false)
    } else {
      setListLoading(false)
    }
  }

  return (
    <ModalLayout heading={'Search'} onClick={closeModal}>
      <div className="modal-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-group">
            <button
              className="btn bg-white border border-end-0 pe-0"
              type="button"
              id="button-addon1"
            >
              <i style={{ fontSize: '1.3rem' }} className="mdi mdi-magnify"></i>
            </button>
            <input
              type="text"
              className="form-control"
              placeholder="Search For tickets"
              aria-label="Example text with button addon"
              aria-describedby="button-addon1"
              {...register('name')}
            />
          </div>
        </form>
        <div
          className="mt-3"
          ref={scrollContainerRef}
          onScroll={handleScroll}
          style={{ overflowY: 'auto', maxHeight: '430px' }}
        >
          {searchResult.map((option) => {
            return (
              <div
                onClick={() => {
                  if (page === 'sell') {
                    navigate(app_urls.sell_ticket_dyn(option.id))
                  } else {
                    navigate(app_urls.single_event_dyn(option.id))
                  }
                }}
                key={option.id}
                style={{ columnGap: '1rem', cursor: 'pointer' }}
                className="d-flex align-items-center event-list border py-2 px-1"
              >
                <img
                  width={80}
                  height={80}
                  crossOrigin="anonymous"
                  src={option.poster}
                  alt="poster"
                />
                <p style={{ fontSize: '0.9rem', marginLeft:"15px" }} className="card-text">
                  <span className="text-accent fw-bold">{option.name}</span>
                  <br />
                  <span className="fw-bold">
                    {moment(option.date).format('ddd Do MMM YYYY - h:mma')}
                  </span>
                  <br />
                  <span>{option.Venue.name}</span>
                </p>
              </div>
            )
          })}
          {listLoading && (
            <div style={{ fontSize: '1rem' }} className="text-center">
              <i className="fa fa-refresh fa-spin"></i>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer"></div>
    </ModalLayout>
  )
}

// function Search() {
//   return (
//     <div className="input-group">
//       <button
//         className="btn bg-white border border-end-0 pe-0"
//         type="button"
//         id="button-addon1"
//       >
//         <i style={{ fontSize: '1.3rem' }} className="mdi mdi-magnify"></i>
//       </button>
//       <input
//         type="text"
//         className="form-control"
//         placeholder="Search For tickets"
//         aria-label="Example text with button addon"
//         aria-describedby="button-addon1"
//       />
//     </div>
//   )
// }
