import React, { useEffect } from 'react'
import DataTableWrapper from '../../../layout/DataTableWrapper'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  clearResetBot,
  onGetProfilePayouts,
  setCustomerErrorMessage,
  setCustomerLoading,
  setDetails,
} from '../../../../config/redux/slice/customerSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { payoutsProfileDatatable } from '../../../../helpers/customer_dataTables'
import { Link, useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import { get_delete_request } from '../../../../helpers/request'

export default function ListingTable() {
  const dispatch = useDispatch()

  const { token } = useSelector((state) => state.authData)

  const { newData, profilePayoutData } = useSelector(
    (state) => state.customer.profilePayoutManagement
  )

  useEffect(() => {
    dispatch(clearAll({}))
    payoutsProfileDatatable(profilePayoutData, 'payoutsProfileTable', setItem)
    getPayouts()
  }, [])

  useEffect(() => {
    if (newData) {
      payoutsProfileDatatable(profilePayoutData, 'payoutsProfileTable', setItem)
      dispatch(clearResetBot())
    }
  }, [newData])

  const setItem = (data, type) => {
    dispatch(setDetails({ details: data, type: 'payout' }))
    // dispatch(
    //   setPayoutValues({
    //     propertyKey: 'payoutItemsData',
    //     value: data?.PayoutItems ?? [],
    //   })
    // )
    // if (type === 'details') {
    //   navigate(app_urls.user_listings_details)
    // } else if (type == 'view-items') {
    //   navigate(app_urls.order_items)
    // }
  }
  const getPayouts = async () => {
    dispatch(clearAll({}))
    await get_delete_request(
      'get',
      token,
      `${app_api.get_user_payouts}?page=1&pageSize=5`,
      {},
      dispatch,
      setCustomerLoading,
      onGetProfilePayouts,
      setCustomerErrorMessage,
      true
    )
  }

  return (
    <DataTableWrapper
      table_id="payoutsProfileTable"
      table_heading="Payouts"
      table_buttons={
        <Link to={app_urls.user_payout} className="text-decoration-none">
          View all payouts
        </Link>
      }
    >
      <thead>
        <tr>
          <th className="wd-15p">Payout Id</th>
          <th className="wd-10p">Amount</th>
          <th className="wd-10p">Event Name</th>
          <th className="wd-10p">Event type</th>
          <th className="wd-10p">Date</th>
          <th className="wd-10p">Status</th>
          {/* <th className="wd-15p">Actions</th> */}
        </tr>
      </thead>
      <tbody></tbody>
    </DataTableWrapper>
  )
}
