import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { clearAll, onOnboardUser, onUserLogin, setErrorMessage, setLoading } from '../../../config/redux/slice/authSlice'
import { get_delete_request, post_put_patch_request } from '../../../helpers/request'
import { app_api } from '../../../utils/endpoints/app_api'
import { app_urls } from '../../../utils/urls/app_urls'
import AuthAlert from '../../components/AuthAlert'
import PasswordComponent from '../../components/Form/PasswordComponent'
import Spinner from '../../components/Spinner'
import AuthWrapper from '../../layout/AuthWrapper'
import LinkVerificationSuccessComponent from './LinkVerificationSuccessComponent'
import LinkVerificationErrorComponent from './LinkVerificationErrorComponent'
import account_activated_background from '../../../assets/images/background_images/account_activated_background.jpg'

export default function AccountActivation() {
  const dispatch = useDispatch()
  const {token_uuid} = useParams()
  const {register, handleSubmit, formState: {errors}} = useForm({mode: 'onTouched'})

  const [successMessage, setSuccessMessage] = useState(false)

  const {
        loading,
        errorMsg,
    } = useSelector((state) => state.authData)

    useEffect(()=>{
      dispatch(clearAll({}))
      verifyEmail()
    }, [])

    const verifyEmail = async () => {
        dispatch(clearAll({}))
        setSuccessMessage(false)
        const res = await get_delete_request(
          'get',
            "",
            `${app_api.verify_email(token_uuid)}`,
            {},
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )
        if(res !== undefined && res.status < 400){
            setSuccessMessage(true)
        }
        // dispatch(onOnboardUser({email}))
    }

  return (
    <>
      {
        successMessage && 
        <LinkVerificationSuccessComponent
        background_image={account_activated_background}
          formTitle='Account Successfully Activated!'
          activationMessage='Congratulations! Your email address has been verified and 
              your account has been 
             successfully activated. You can now proceed to login'
        /> }
        {/* { errorMsg && <LinkVerificationErrorComponent
          formTitle='Account Activation Error'
          activationMessage={errorMsg}
        />
      } */}
              { errorMsg && <div>Error</div>
      }
    </>
  )
}
