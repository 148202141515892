import React from 'react'
import PasswordComponent from '../../../components/Form/PasswordComponent'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { clearAll, onAddUser, setAdminErrorMessageUser, setAdminLoadingUser, showAddUserFormFunc } from '../../../../config/redux/slice/adminSlice'
import Spinner from '../../../components/Spinner'
import AddFormLayout from '../../../layout/admin/AddFormLayout'
import PhoneNumberComponent from '../../../components/Form/PhoneNumberComponent'
import CountryComponent from '../../../components/Form/CountryComponent'

export default function CreateUser({ userTypes,getListingDetails}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { register, handleSubmit, watch, getValues, setError, clearErrors, setValue, formState: { errors }, reset } = useForm({ mode: 'onTouched' })

  const {
    adminLoading,
    adminErrorMsg,
    adminSuccessMsg
  } = useSelector((state) => state.admin)

  const {
    token
  } = useSelector((state) => state.authData)

  const passwordValue = watch('password')

  const onSubmit = async (data) => {
    dispatch(clearAll({}))
    if (data?.code == undefined) {
      setError('code', {
        type: "manual",
        message: "Please select country code",
      })
    } else {
      //data.phone = data.code + data.mobile_no

      let trimmedMobileNo = data.mobile_no;
      // Escape the '+' character in the country code using a backslash
      const escapedCode = data.code.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // Escapes special characters
      const countryCodeRegex = new RegExp(`^${escapedCode}\\s?`);
      // Remove the country code from mobile_no if it starts with it
      trimmedMobileNo = data.mobile_no.replace(countryCodeRegex, '').trim();
      // Set the final phone number without duplicating the country code
      data.phone = data.code + ' ' + trimmedMobileNo;

      const res = await post_put_patch_request(
        "post",
        token,
        `${app_api.add_user}`,
        { ...data, role: 'USER' },
        dispatch,
        setAdminLoadingUser,
        onAddUser,
        setAdminErrorMessageUser
      )
      if (res !== undefined && res.status < 400) {
        reset()
        getListingDetails()
      }
    }
  }

  const closeForm = () => {
    dispatch(showAddUserFormFunc({ modal: false }))
  }

  return (
    <AddFormLayout onSubmit={handleSubmit(onSubmit)} onClick={closeForm} heading_title='Add New User' sub_text='Fill in the fields below'>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="px-4 px-md-7">
          <div style={{ rowGap: '1.2rem' }} className="row mb-2">

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='name'>First Name <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="name"
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    }
                  })
                  }
                />
                {
                  errors?.firstName?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.firstName?.message}
                  </div>
                }
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='lastName'>Last Name <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="lastName"
                  {...register("lastName", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    }
                  })
                  }
                />
                {
                  errors?.lastName?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.lastName?.message}
                  </div>
                }
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='email'>Email <span className="text-danger">*</span></label>
                <input
                  type="email"
                  className="form-control input-lg"
                  id="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    }
                  })
                  }
                />
                {
                  errors?.email?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.email?.message}
                  </div>
                }
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <PhoneNumberComponent
                  fieldName={'mobile_no'}
                  fieldName2={'code'}
                  error_msg={errors.mobile_new?.message ?? errors.code?.message}
                  label={'Phone Number'}
                  setValueFunc={setValue}
                  setErrorFunc={setError}
                  clearErrorsFunc={clearErrors}
                  isReq={true}
                  register={register}
                />
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='firstLineAddress'>First Line Address <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="firstLineAddress"
                  {...register("firstLineAddress", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    },
                  })
                  }
                />
                {
                  errors?.firstLineAddress?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.firstLineAddress?.message}
                  </div>
                }
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='firstLineAddress'>Second Line Address</label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="secondLineAddress"
                  {...register("secondLineAddress", {
                  })
                  }
                />
                {
                  errors?.secondLineAddress?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.secondLineAddress?.message}
                  </div>
                }
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='houseName'>House Number <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="houseName"
                  {...register("houseName", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    },
                  })
                  }
                />
                {
                  errors?.houseName?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.houseName?.message}
                  </div>
                }
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='city'>Country <span className="text-danger">*</span></label>
                <CountryComponent
                  // searchValue={search_cities}
                  register={register}
                  isReq={true}
                  fieldName={'countryId'}
                  fieldName2={'country'}
                  text='Select Country'
                  setValue={setValue}
                  setErrorFunc={setError}
                  getValueFunc={getValues}
                  errors={errors?.city?.message}
                  clearErrorsFunc={clearErrors}
                  getEndpoint={app_api.get_countries}
                  searchEndpoint={app_api.search_countries}
                />
                {
                  errors?.country?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.country?.message}
                  </div>
                }
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='town'>Town <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="town"
                  {...register("town", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    },
                  })
                  }
                />
                {
                  errors?.town?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.town?.message}
                  </div>
                }
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='postCode'>Postal Code <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="postCode"
                  {...register("postCode", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    },
                  })
                  }
                />
                {
                  errors?.postCode?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.postCode?.message}
                  </div>
                }
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <PasswordComponent
                fieldName={'password'}
                error_msg={errors.password?.message}
                label={<>Password <span className="text-danger">*</span></>}
                isReq={true}
                register={register}
                patternRules={''}
              // hasHelperText={true}
              />
            </div>

            <div className="col-md-6 col-lg-4">
              <PasswordComponent
                fieldName={'confirm_password'}
                error_msg={errors.confirm_password?.message}
                label={<>Repeat Password <span className="text-danger">*</span></>}
                isReq={true}
                register={register}
                patternRules={''}
                isSameAs={passwordValue}
              />
            </div>

            {/* xskr */}
            <div className='col-md-6 col-lg-4'>
              <div className="form-group">
                <label htmlFor='userType'>User Type <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  id="userType"
                  {...register("userType", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    }
                  })
                  }
                >
                  <option value={''}>Select User type</option>
                  {userTypes.map((userType, indx) => {
                    return <React.Fragment key={indx}>
                      {userType.toLowerCase() != 'user' &&
                        <option className='text-capitalize' key={indx} value={userType}>{userType}</option>
                      }
                    </React.Fragment>
                  })}
                </select>
                {
                  errors?.userType?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.userType?.message}
                  </div>
                }
              </div>
            </div>
            {/* xskr */}

          </div>

        </div>
        <div className="mt-4 px-4 d-flex align-items-center">
          <div>
            {adminLoading ?
              <Spinner isForm={true} /> :
              <button type="submit" className="btn btn-primary me-3">Submit Form</button>
            }
          </div>
          <button disabled={adminLoading ? true : false} onClick={closeForm} type="button" className="btn btn-info">Cancel</button>
        </div>
      </form>
    </AddFormLayout>
  )
}
