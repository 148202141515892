import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'

export default function Sidebar({onClick}) {
  const location = useLocation()
  const [currentTab, setCurrentTab] = useState('')

  const params = new URLSearchParams(location.search);
  
  useEffect(()=>{
        const tab = params.get('tab');
        setCurrentTab(tab)
    }, [params.get('tab')])

  return (
    <>
        <div className='p-2'>
            <h6 className='fw-bold'>Settings</h6>
        </div>
        <div className="list-group rounded-0">
            <Link onClick={onClick} to={app_urls.profile} href="#" className={`${(currentTab == '' || !currentTab) ? 'active' : ''} list-group-item list-group-item-action border-end-0 d-flex align-items-center justify-content-between`} aria-current="true">
                <span>View Profile</span>
                <span><i style={{fontSize: '1.3rem'}} className='mdi mdi-chevron-right'></i></span>
            </Link>
            <Link onClick={onClick} to={`${app_urls.profile}?tab=edit-profile`} href="#" className={`${currentTab == 'edit-profile' ? 'active' : ''} list-group-item list-group-item-action border-end-0 d-flex align-items-center justify-content-between`} aria-current="true">
                <span>Edit Profile</span>
                <span><i style={{fontSize: '1.3rem'}} className='mdi mdi-chevron-right'></i></span>
            </Link>
            <Link onClick={onClick} to={`${app_urls.profile}?tab=change-password`} href="#" className={`${currentTab == 'change-password' ? 'active' : ''} list-group-item list-group-item-action border-end-0 d-flex align-items-center justify-content-between`} aria-current="true">
                <span>Change Password</span>
                <span><i style={{fontSize: '1.3rem'}} className='mdi mdi-chevron-right'></i></span>
            </Link>
           <Link onClick={onClick} to={`${app_urls.profile}?tab=bank-information`} href="#" className={`${currentTab == 'bank-information' ? 'active' : ''} list-group-item list-group-item-action border-end-0 d-flex align-items-center justify-content-between`} aria-current="true">
                <span>Bank Information</span>
                <span><i style={{fontSize: '1.3rem'}} className='mdi mdi-chevron-right'></i></span>
            </Link>
            <Link onClick={onClick} to={`${app_urls.profile}?tab=permissions`} href="#" className={`${currentTab == 'permissions' ? 'active' : ''} list-group-item list-group-item-action border-end-0 d-flex align-items-center justify-content-between`} aria-current="true">
                <span>Permissions</span>
                <span><i style={{fontSize: '1.3rem'}} className='mdi mdi-chevron-right'></i></span>
            </Link>
        </div>
    </>
  )
}
 