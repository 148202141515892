import React, { useEffect } from 'react'
import GeneralWrapper from '../../../layout/GeneralWrapper'

export default function RefundPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <GeneralWrapper>
        <div className='px-3 px-lg-100 px-xl-200 mt-3 mb-3'>
  <div className="WordSection1">
    <h1 style={{ marginLeft: "-.25pt", textIndent: "0in" }}>
    Refund Policy
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0in",
        marginRight: ".2pt",
        marginBottom: ".15pt",
        marginLeft: "-.25pt"
      }}
    >
            <div class='mb-3'> 1.1 <b>Cancellation:</b> If an event is cancelled and not rescheduled, we will remove the event and any listings related to the event from our Site and inform both Buyer and Seller about the cancellation with further instructions. If the Seller requires his Ticket(s) back to obtain a refund of the original purchase price from the promoter or organizer, he must contact Customer Service immediately after the cancellation. The Buyer is required to retain the Ticket(s) and upon request from PrimeTic to send them back to either the Seller or to PrimeTic. The Buyer will receive a full refund or credit for use on a future purchase, as determined by PrimeTic’s sole discretion (unless a refund is required by law) once he has sent back the Ticket(s) (if applicable) and the Seller will not be paid. If the Seller has already been paid, the payment for the sale may be recovered by charging the Seller's Payment Method or by setting off this amount against pending payments for other Tickets the Seller has sold or will sell in the future.</div>
              <div class='mb-3'>1.2 <b>Postponement:</b> If an event is postponed, PrimeTic will work with Buyers and Sellers on a case-by-case basis attempting to resolve any Ticket issues. Sellers are prohibited from reselling, invalidating or changing Tickets for postponed events. Refunds will not be issued for postponed events, unless they are ultimately cancelled as stated above.</div>
              <div class='mb-3'>1.3 <b>Other event changes:</b> PrimeTic is not responsible for partial performances, venue, line-up or time changes. No refunds will be issued in these instances.</div>
          </p>
 </div>
 </div>
  </GeneralWrapper>
  )
}
