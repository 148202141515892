import React, { memo, useEffect, useRef, useState } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getListOfYears } from '../../../../helpers/helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function AreaChart({sales_data}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  const areaData = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'Revenue',
        data: labels.map(() => Math.floor(Math.random() * 10)),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const list_of_years = getListOfYears()

  const [selected_year, set_selected_year] = useState(list_of_years[0])

  const [data, set_data] = useState(areaData)

  useEffect(()=>{
    set_data({...data, datasets: [{...data.datasets[0], data: sales_data}]})
  }, [sales_data])

  return (
    <div className="card overflow-hidden">
        <div className="card-header bg-transparent py-4 d-sm-flex align-items-center justify-content-between">
            <div className="mb-4 mg-sm-b-0">
                <h6 className="fs-6 mb-1 text-uppercase fw-bold tx-spacing-1">Sales Revenue Trend</h6>
            </div>
            <div class="dropdown">
                <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {selected_year}
                </a>

                <ul class="dropdown-menu">
                  {getListOfYears().map((year, idx)=>{
                  return <li key={idx} onClick={()=>{
                    if(year != selected_year){
                      set_selected_year(year)
                    }
                  }}><a class="dropdown-item">{year}</a></li>
                  })}
                </ul>
              </div>
            {/* <div className="btn-group" role="group" aria-label="Basic example">
                <a href="#" className="btn btn-secondary fs-12 active">Today</a>
                <a href="#" className="btn btn-secondary fs-12">This Week</a>
                <a href="#" className="btn btn-secondary fs-12">This Month</a>
            </div> */}
        </div>
        {/* <!-- card-header --> */}
        {/* <div className="card-body pd-0 bd-color-gray-lighter">
            <div className="row no-gutters tx-center">
                <div className="col-12 col-sm-4 py-4 tx-left">
                    <p className="pd-l-20 fs-12 lh-8 mb-0">Note: Lorem ipsum dolor sit amet, consectetuer
                        adipiscing elit. Aenean commodo ligula...</p>
                </div>

                <div className="col-6 col-sm-2 py-4 border-end fs-12">
                    <h4 className="tx-inverse fs-6 tx-lato fw-bold mb-1">09</h4>
                    <p className="tx-11 mb-0 text-uppercase">Listings</p>
                </div>

                <div className="col-6 col-sm-2 py-4 border-end fs-12">
                    <h4 className="tx-inverse fs-6 tx-lato fw-bold mb-1">102</h4>
                    <p className="tx-11 mb-0 text-uppercase">Orders</p>
                </div>

                <div className="col-6 col-sm-2 py-4 border-end fs-12">
                    <h4 className="tx-inverse fs-6 tx-lato fw-bold mb-1">343</h4>
                    <p className="tx-11 mb-0 text-uppercase">Payouts</p>
                </div>

                <div className="col-6 col-sm-2 py-4 border-end fs-12">
                    <h4 className="tx-inverse fs-6 tx-lato fw-bold mb-1">960</h4>
                    <p className="tx-11 mb-0 text-uppercase">Pending</p>
                </div>

            </div>

        </div> */}

        <div className="card-body p-0">
            <Line options={options} data={data}/>
        </div>
    </div>
  )
}

export default memo(AreaChart)
