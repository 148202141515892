import React from 'react'

export default function ModalLayout({onClick, backgroundColor='rgba(0,0,0,0.7)', children, keepHeading=true, heading, classStyles, includeCloseButton=true, clickBody}) {
  return (
    <div 
        className="modal fade show" 
        id="exampleModal" 
        tabIndex="-1" 
        aria-labelledby="exampleModalLabel" 
        aria-hidden="true"
        style={{
          display: "block",
          backgroundColor: backgroundColor,
          overflowY: 'auto',
          zIndex: 1500
        }}
    >
        <div className={`modal-dialog ${classStyles}`}>
            <div className="modal-content">
              {keepHeading &&
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">{heading}</h5>
                    {includeCloseButton &&                  
                    <button onClick={onClick} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    }
                </div>}
                {children}
            </div>
        </div>
    </div>
  )
}
