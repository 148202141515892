import React, { useEffect, useState } from 'react'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { app_urls } from '../../../../utils/urls/app_urls'
import { Link } from 'react-router-dom'
import { clearAll, clearOnReload } from '../../../../config/redux/slice/customerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'

export default function ConnectedAccountError() {
const dispatch = useDispatch()
const [stripeLink, setStripeLink] = useState('#')

const {
        token
    } = useSelector((state) => state.authData)

    useEffect(()=>{
        dispatch(clearOnReload({type: 'payment', clearAll: true}))
        createConnectedAccount()
    }, [])

    const createConnectedAccount = async () => {
        dispatch(clearAll({}))
        const res = await post_put_patch_request(
            'post',
            token,
            `${app_api.create_connected_account}`,
            {},
            dispatch,
            "",
            "",
            ""
        )
        if(res != undefined && res.status < 400){
            setStripeLink(res?.data?.data?.url)
        }
    }

  return (
    <CustomerWrapper isSidebarReq={false}>
        <div style={{height: '100vh'}} className='d-flex justify-content-center align-items-center'>
            <div style={{minWidth: '270px', width: '400px', maxWidth: '400px'}} className='card pt-3'>
                <div style={{rowGap: '1rem'}} className='card-body greeting flex-column d-flex justify-content-center mb-2 align-items-center'>
                {/* <i style={{fontSize: '2rem'}} className={`mdi mdi-checkbox-marked-circle text-success`}></i> */}
                    <div className='text-center mb-2'>
                        <p style={{fontSize: '1.2rem', color: 'rgba(0,0,0,0.9)'}} className={`text-capitalize fw-bold`}>
                            Create a connected account
                        </p>
                        <p style={{fontSize: '1rem', lineHeight: '1.8rem', color: '#31343D'}} className='text-capitalize'>
                            To be able to list a ticket, you need to have a connected account so that you can receive payments
                        </p>
                    </div>
                    {stripeLink != '#' &&
                        <button className='btn btn-success'>
                            <a style={{textDecoration: 'none', color: 'white'}} href={stripeLink} target='_blank' rel='noreferrer'>Create Account</a>
                        </button>
                    }
                </div>
            </div>
        </div>
    </CustomerWrapper>
  )
}
